export const WEB_APP_PATH_DISCOVER = '/'
export const WEB_APP_PATH_REGION = '/l/:geonameId'
export const WEB_APP_PATH_IMPORT = '/import'
export const WEB_APP_PATH_ROUTE_PLANNER = '/plan'
export const WEB_APP_PATH_ROUTE_DETAILS = '/r/:routeId'
export const WEB_APP_PATH_ROUTE_EDIT = '/r/:routeId/edit'
export const WEB_APP_PATH_ROUTE_PRINT = '/r/:routeId/print'
export const WEB_APP_PATH_ROUTE_COLLECTION = '/c/:routeCollectionId'
export const WEB_APP_PATH_COLLECTION_ROUTE_DETAILS = '/c/:routeCollectionId/r/:routeId'

export const WIDGETS_BASE_ROUTE = '/r/:routeId'
