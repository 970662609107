import { useLocale } from 'shared/util-intl'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { NavigationLinkProps } from './types'
import { Button } from 'shared/ui-components'
import { WEB_APP_PATH_ROUTE_EDIT } from 'shared/util-navigation'
import { useNavigate } from 'react-router-dom'
import React, { ReactNode } from 'react'

interface RouteEditLinkProps extends NavigationLinkProps {
  routeId?: number
  children?: string
  icon?: ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const RouteEditLink = ({ primary, compact, routeId, children, icon, onClick }: RouteEditLinkProps) => {
  const navigate = useNavigate()
  const { intl } = useLocale()

  const editUrl = routeId ? WEB_APP_PATH_ROUTE_EDIT.replace(':routeId', routeId.toString()) : undefined

  return (
    <Button
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      icon={icon || <EditRoundedIcon />}
      href={editUrl}
      onClick={
        editUrl
          ? (e) => {
              onClick && onClick(e)
              e.preventDefault()
              navigate(editUrl)
            }
          : onClick
      }
      disabled={!editUrl}
    >
      {children ||
        intl.formatMessage({
          id: 'route_edit_link_label',
          defaultMessage: 'Edit this route',
        })}
    </Button>
  )
}
