import { WebAppLayout } from 'web-app/ui-layout'
import { DefaultFooter } from 'web-app/feature-navigation'
import { useRouteById, useRouteTitle } from 'web-app/feature-route'
import { RoutePrintMedia } from './route-print-media'
import { RoutePrintControls } from './route-print-controls'
import { RoutePrintSheet } from './route-print-sheet'
import { useEffect } from 'react'
import { initializedForRouteId, useRoutePrintState } from './state'
import { useDispatch } from 'react-redux'
import { RoutePrintHeader } from './route-print-header'
import { RoutePrintMainActions } from './route-print-main-actions'

interface RoutePrintProps {
  routeId: number
}

export const RoutePrint = ({ routeId }: RoutePrintProps) => {
  const dispatch = useDispatch()
  useRouteById(routeId)
  useRouteTitle()
  const { forRouteId } = useRoutePrintState()

  useEffect(() => {
    dispatch(initializedForRouteId(routeId))
  }, [dispatch, forRouteId, routeId])

  return (
    <WebAppLayout parallax={false}>
      <RoutePrintHeader routeId={routeId} />
      <RoutePrintSheet />
      <RoutePrintMainActions />
      <RoutePrintMedia />
      <RoutePrintControls />
      <DefaultFooter colored />
    </WebAppLayout>
  )
}
