import { has } from 'lodash'
import { LineString, MultiLineString, Polygon, Position } from 'geojson'
import { LngLat, LngLatBoundsArray, LngLatElevation, Position2d, Position3d, Waypoint } from './types'
import { decodePath2d } from './decode-path'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { LngLatBounds } from 'maplibre-gl'
import { RichPosition } from './rich-geometry'

/** Get `LngLat` (with optional elevation) from a 2- or 3-dimensional `Position` array */
export function positionToLngLat(coordinates: Position | RichPosition): LngLat {
  const lngLat: LngLat = {
    lng: coordinates[0],
    lat: coordinates[1],
  }
  if (coordinates.length > 2) {
    lngLat.elevation = coordinates[2]
  }
  return lngLat
}

/** Get `LngLatElevation` from a 3-dimensional `Position` array */
export function positionToLngLatElevation(coordinates: Position3d | RichPosition): LngLatElevation {
  return {
    lng: coordinates[0],
    lat: coordinates[1],
    elevation: coordinates[2],
  }
}

/** Get 2- or 3-dimensional `Position` array from `LngLat` (with optional elevation) */
export function lngLatToPosition(point: LngLat): Position {
  return point.elevation ? [point.lng, point.lat, point.elevation] : [point.lng, point.lat]
}

/** Get 2-dimensional `Position` array from `LngLat` */
export function lngLatToPosition2d(point: LngLat): Position2d {
  return [point.lng, point.lat]
}

/** Get 3-dimensional `Position` array from `LngLatElevation` */
export function lngLatToPosition3d(point: LngLatElevation): Position3d {
  return [point.lng, point.lat, point.elevation]
}

export function isPosition3d(position: Position): position is Position3d {
  return typeof position[2] === 'number'
}

/**
 * Returns an array of the LngLats that could be parsed from a given polyline string.
 */
export function decodeLngLatsPath(encoded: string): LngLat[] {
  return decodePath2d(encoded).map(positionToLngLat)
}

/**
 * Convert geometry to LineString taking the first route segment.
 */
export function convertToLineString(geometry: MultiLineString): LineString {
  return {
    ...geometry,
    type: 'LineString',
    // Take first route segment
    coordinates: geometry.coordinates[0],
  }
}

/**
 * Check if received geometry has coordinates.
 */
export function isValidGeometry(geometry: MultiLineString | null): boolean {
  return !!(geometry && geometry.coordinates && geometry.coordinates[0] && geometry.coordinates[0].length > 0)
}

/**
 * Check for the `Waypoint` type (by `controlPointIndex`)
 */
export function isWaypoint(waypoint: null | LngLat | Waypoint): waypoint is Waypoint {
  return !!(waypoint && has(waypoint, 'controlPointIndex'))
}

/**
 * Check if two individual `LngLat` objects represent the same position.
 */
export function areLngLatsEqual(a: LngLat, b: LngLat): boolean {
  return a.lng === b.lng && a.lat === b.lat
}

export function isPointInBounds(point: LngLat, bounds: LngLatBoundsArray): boolean {
  const { lng, lat } = point
  const polygon = getPolygonFromBounds(bounds)
  return booleanPointInPolygon([lng, lat], polygon)
}

export function getPolygonFromBounds(bounds: LngLatBoundsArray): Polygon {
  const boundsObj = new LngLatBounds(bounds)
  return {
    type: 'Polygon',
    coordinates: [
      [
        [boundsObj.getNorthWest().lng, boundsObj.getNorthWest().lat],
        [boundsObj.getNorthEast().lng, boundsObj.getNorthEast().lat],
        [boundsObj.getSouthEast().lng, boundsObj.getSouthEast().lat],
        [boundsObj.getSouthWest().lng, boundsObj.getSouthWest().lat],
        [boundsObj.getNorthWest().lng, boundsObj.getNorthWest().lat],
      ],
    ],
  }
}

/**
 * Round a value to the number of decimals commonly used in positions.
 */
export function roundPositionValue(value: number): number {
  return Math.round(value * 100000) / 100000
}
