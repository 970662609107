import React, { ReactElement } from 'react'
import { logError } from './log-error'

interface ErrorBoundaryProps {
  children: ReactElement
  fallback: ReactElement
}

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  override state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return {
      hasError: true,
    }
  }

  override componentDidCatch(error: Error) {
    logError('Caught in error boundary', error)
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback
    }
    return this.props.children
  }
}

export default ErrorBoundary
