import clsx from 'clsx'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { ToolButton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useRoute } from 'web-app/feature-route'
import { useFragmentRouting } from '../../use-fragment-routing'
import { IMAGE_FRAGMENT_HASH } from '../../config'

import styles from './images-lightbox.module.css'

interface ImagesLightboxProps {
  openImageIndex: number
}

export const ImagesLightbox = ({ openImageIndex }: ImagesLightboxProps) => {
  const { intl } = useLocale()
  const route = useRoute()
  const { getFragmentLinkProps } = useFragmentRouting()

  const previousLabel = intl.formatMessage({
    id: 'route_details_images_lightbox_previous',
    defaultMessage: 'Previous image',
  })
  const nextLabel = intl.formatMessage({
    id: 'route_details_images_lightbox_next',
    defaultMessage: 'Next image',
  })

  return route && openImageIndex !== null ? (
    <div className={styles['container']}>
      <img
        className={styles['open-image']}
        src={route.images[openImageIndex].original}
        alt={intl.formatMessage({
          id: 'route_images_lightbox_open_alt_text',
          defaultMessage: 'Route image opened in lightbox',
        })}
      />
      <div className={styles['images-overview-container']}>
        <div className={styles['images-overview']}>
          {route.images.map((image, i) => (
            <a
              key={i}
              {...getFragmentLinkProps(IMAGE_FRAGMENT_HASH, i)}
              className={clsx(styles['thumbnail'], {
                [styles['thumbnail-active']]: i === openImageIndex,
              })}
            >
              <img
                src={image.item}
                className={styles['thumbnail-image']}
                alt={intl.formatMessage(
                  {
                    id: 'route_images_lightbox_thumbnail_alt_text',
                    defaultMessage: 'Route image {number}',
                  },
                  {
                    number: i + 1,
                  },
                )}
              />
            </a>
          ))}
        </div>
      </div>
      <div className={styles['previous-next-buttons-container']}>
        <ToolButton
          icon={<ArrowBackIosNewRoundedIcon />}
          ariaLabel={previousLabel}
          variant={'onColor-ghost'}
          {...getFragmentLinkProps(IMAGE_FRAGMENT_HASH, openImageIndex - 1)}
          disabled={openImageIndex === 0}
        />
        <ToolButton
          icon={<ArrowForwardIosRoundedIcon />}
          ariaLabel={nextLabel}
          variant={'onColor-ghost'}
          {...getFragmentLinkProps(IMAGE_FRAGMENT_HASH, openImageIndex + 1)}
          disabled={openImageIndex === route.images.length - 1}
        />
      </div>
    </div>
  ) : null
}
