import { WEB_APP_PATH_ROUTE_PLANNER } from './paths'

const WEBSITE_BASE = 'https://www.bikemap.net'

export const APP_ONLY_DEEPLINKS = [
  'premium',
  'myroutes',
  'sharelocation', // share object as base64 in second path segment
  'saved',
  'planned',
  'recorded',
  'offline',
  'startfreeride',
  'navigationsettings', // route ID in second path segment
  'search',
  'settings',
  'bike-computer-settings',
  'invite-friends',
  'u', // user slug in second path segment
  'cycle-path-option',
  'sharedpoi', // search params: `title`, `latitude`, `longitude`, `mapstyle`
  'loop-planner',
  'integrations',
  'recap', // recap token in second path segment
  'sethomeaddress',
  'setworkaddress',
]

const APP_ONLY_DEEPLINKS_WITH_SECOND_SEGMENT = [
  'sharelocation', // share object as base64 in second path segment
  'navigationsettings', // route ID in second path segment
  'u', // user slug in second path segment
  'recap', // recap token in second path segment
]

export function getAppOnlyDeeplink(deeplink?: string): string | undefined {
  if (!deeplink) return undefined

  const [path, searchParams] = deeplink.split('?')
  const pathSegments = path
    .split('/')
    .filter((segment) => !!segment.length && !segment.includes('http') && !segment.includes('bikemap'))
  const [mainSegment, parameterSegment] = pathSegments

  if (!APP_ONLY_DEEPLINKS.includes(mainSegment)) return undefined

  if (APP_ONLY_DEEPLINKS_WITH_SECOND_SEGMENT.includes(mainSegment)) {
    if (parameterSegment) {
      return `${WEBSITE_BASE}/${mainSegment}/${parameterSegment}`
    }
    return undefined
  }

  if (mainSegment === 'sharedpoi') {
    return `${WEBSITE_BASE}/${mainSegment}?${searchParams}`
  }

  return `${WEBSITE_BASE}/${mainSegment}`
}

export const CROSS_PLATFORM_DEEPLINKS = [
  'r', // route ID in second path segment
  'routeplanner', // waypoints as polyline string in `?waypoints` param
  'discover',
]

export function getDeeplinkFromWebAppPath(webAppPath: string, search: string): string | undefined {
  if (webAppPath === '/' || !webAppPath.length) {
    return '/discover'
  }

  if (webAppPath === WEB_APP_PATH_ROUTE_PLANNER) {
    const searchParams = new URLSearchParams(search)
    const waypoints = searchParams.get('waypoints')
    return '/routeplanner' + (waypoints ? `?waypoints=${waypoints}` : '')
  }

  const pathSegments = webAppPath.split('/').filter((segment) => !!segment)

  if (pathSegments[0] === 'r') {
    const routeId = Number.parseInt(pathSegments[1])
    if (routeId) {
      return `/r/${routeId}`
    }
  }

  if (pathSegments[2] === 'r') {
    const routeId = Number.parseInt(pathSegments[3])
    if (routeId) {
      return `/r/${routeId}`
    }
  }

  return undefined
}
