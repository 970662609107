import { PopoverProps, Skeleton, Typography } from '@mui/material'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import { Avatar, AvatarSkeleton, Button } from 'shared/ui-components'
import { RouteCollectionIcon } from 'shared/ui-design-system/icons/route-collection-icon'
import { GPXUploadIcon } from 'shared/ui-design-system/icons/gpx-upload-icon'
import { ProfileSettingsIcon } from 'shared/ui-design-system/icons/profile-settings-icon'
import { DropdownMenu, DropdownMenuGroup, DropdownMenuItem, DropdownMenuOptionsItem } from 'shared/ui-navigation'
import { languageUpdated, useLocale } from 'shared/util-intl'
import {
  WEB_APP_PATH_DISCOVER,
  WEB_APP_PATH_IMPORT,
  WEB_APP_PATH_ROUTE_PLANNER,
  getAdminUrl,
  getLogoutUrl,
  getPoiBoyUrl,
  getProfileSettingsUrl,
  getUserProfileUrl,
} from 'shared/util-navigation'
import { useSessionInfo } from './hooks'
import { PremiumLink } from './links'
import DirectionsRoundedIcon from '@mui/icons-material/Directions'
import ExploreIcon from '@mui/icons-material/Explore'
import { useInternalLink } from './use-internal-link'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded'
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded'
import EditLocationAltRoundedIcon from '@mui/icons-material/EditLocationAltRounded'
import { unitPreferenceUpdated, useUser, useUserState } from 'web-app/feature-user'
import { useDispatch } from 'react-redux'

import styles from './navigation-menu.module.scss'

export const NavigationMenu = (popoverProps: PopoverProps) => {
  const dispatch = useDispatch()
  const { intl, language } = useLocale()
  const [user, isUserLoaded] = useUser()
  const sessionInfo = useSessionInfo()
  const { unitPreference } = useUserState()
  const getInternalLinkProps = useInternalLink()

  const imperialLabel = intl.formatMessage({
    id: 'unit_select_option_imperial',
    defaultMessage: 'Imperial (ft)',
  })

  const metricLabel = intl.formatMessage({
    id: 'unit_select_option_metric',
    defaultMessage: 'Metric (m)',
  })

  return (
    <DropdownMenu {...popoverProps} data-testid="user-menu">
      {isUserLoaded ? (
        user ? (
          <DropdownMenuGroup className={styles['user-container']}>
            <Avatar name={user.name} image={user.avatar?.small} isPremium={user.isPremium} />
            <Typography variant="h4" component="h2" className={styles['user-name']} marginBottom={0}>
              {user.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" className={styles['user-label']}>
              {user.email}
            </Typography>
            <Button
              variant="secondary"
              icon={<ProfileSettingsIcon />}
              ariaLabel={intl.formatMessage({
                id: 'user_menu_button_profile_settings',
                defaultMessage: 'Profile settings',
              })}
              href={getProfileSettingsUrl({
                ...sessionInfo,
                slug: user.slug,
              })}
            />
          </DropdownMenuGroup>
        ) : (
          <PremiumLink primary />
        )
      ) : (
        <DropdownMenuGroup className={styles['user-container']}>
          <AvatarSkeleton />
          <Typography variant="h4" component="h2" className={styles['user-name']} marginBottom={0}>
            <Skeleton width="80%" />
          </Typography>
          <Typography variant="body2" className={styles['user-label']}>
            <Skeleton width="60%" />
          </Typography>
          <Button variant="secondary" icon={<ProfileSettingsIcon />} disabled />
        </DropdownMenuGroup>
      )}
      {user && !user.isPremium && <PremiumLink primary />}
      <DropdownMenuGroup
        heading={intl.formatMessage({
          id: 'navigation_menu_group_quick_actions_heading',
          defaultMessage: 'Quick actions',
        })}
      >
        {user && (
          <DropdownMenuItem
            icon={<RouteCollectionIcon />}
            href={getUserProfileUrl({
              ...sessionInfo,
              slug: user.slug,
            })}
          >
            {intl.formatMessage({
              id: 'menu_item_my_routes_label',
              defaultMessage: 'My routes',
            })}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          icon={<DirectionsRoundedIcon />}
          {...getInternalLinkProps(WEB_APP_PATH_ROUTE_PLANNER)}
          active={window.location.href.includes(WEB_APP_PATH_ROUTE_PLANNER)}
        >
          {intl.formatMessage({
            id: 'menu_item_route_planner_label',
            defaultMessage: 'Plan a route',
          })}
        </DropdownMenuItem>
        <DropdownMenuItem
          icon={<ExploreIcon />}
          {...getInternalLinkProps(WEB_APP_PATH_DISCOVER)}
          active={window.location.pathname === WEB_APP_PATH_DISCOVER}
        >
          {intl.formatMessage({
            id: 'menu_item_discover_label',
            defaultMessage: 'Discover routes',
          })}
        </DropdownMenuItem>
        <DropdownMenuItem
          icon={<GPXUploadIcon />}
          {...getInternalLinkProps(WEB_APP_PATH_IMPORT)}
          active={window.location.href.includes(WEB_APP_PATH_IMPORT)}
        >
          {intl.formatMessage({
            id: 'menu_item_route_import_label',
            defaultMessage: 'Import a route',
          })}
        </DropdownMenuItem>
        {user?.isPremium && (
          <DropdownMenuItem icon={<EmailRoundedIcon />} href="mailto:support@bikemap.net">
            {intl.formatMessage({
              id: 'menu_item_support_label',
              defaultMessage: 'Send us a message',
            })}
          </DropdownMenuItem>
        )}
        {user?.isStaff && (
          <>
            <DropdownMenuItem icon={<AdminPanelSettingsRoundedIcon />} href={getAdminUrl(sessionInfo)}>
              {intl.formatMessage({
                id: 'menu_item_admin_label',
                defaultMessage: 'Admin interface',
              })}
            </DropdownMenuItem>
            <DropdownMenuItem icon={<EditLocationAltRoundedIcon />} href={getPoiBoyUrl(sessionInfo)}>
              {intl.formatMessage({
                id: 'menu_item_poi_boy_label',
                defaultMessage: 'PoiBoy',
              })}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuGroup>
      <DropdownMenuGroup
        heading={intl.formatMessage({
          id: 'navigation_menu_group_preferences_heading',
          defaultMessage: 'Preferences',
        })}
      >
        <DropdownMenuOptionsItem
          icon={<LanguageRoundedIcon />}
          alternative={language === 'de' ? 'English' : 'Deutsch'}
          onSwitch={() => dispatch(languageUpdated(language === 'de' ? 'en' : 'de'))}
        >
          {language === 'de' ? 'Deutsch' : 'English'}
        </DropdownMenuOptionsItem>
        <DropdownMenuOptionsItem
          icon={<StraightenRoundedIcon />}
          alternative={unitPreference === 'imperial' ? metricLabel : imperialLabel}
          onSwitch={() => dispatch(unitPreferenceUpdated(unitPreference === 'imperial' ? 'metric' : 'imperial'))}
        >
          {unitPreference === 'imperial' ? imperialLabel : metricLabel}
        </DropdownMenuOptionsItem>
      </DropdownMenuGroup>
      {user && (
        <DropdownMenuGroup>
          <DropdownMenuItem
            variant="danger"
            href={getLogoutUrl({
              ...sessionInfo,
              path: window.location.pathname,
            })}
          >
            {intl.formatMessage({
              id: 'menu_item_log_out_label',
              defaultMessage: 'Log out',
            })}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      )}
    </DropdownMenu>
  )
}
