import { useLocation, useParams } from 'react-router-dom'
import { Region } from 'web-app/view-region'
import { ViewNotFound } from 'web-app/utils-error-handling'

const RegionView = () => {
  const params = useParams()
  const location = useLocation()
  const geonameId = params['geonameId'] ? Number.parseInt(params['geonameId']) : undefined

  const { regionPreview } = location.state || {}

  return geonameId ? <Region geonameId={geonameId} initialState={{ regionPreview }} /> : <ViewNotFound />
}

export default RegionView
