import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocale } from 'shared/util-intl'
import { ClickAwayListener } from '@mui/base'
import { LocationList, useInputValue } from 'shared/ui-components'
import { useDispatch } from 'react-redux'
import { resultSelected } from './state'
import { QUERY_DEBOUNCE_TIME } from './definitions'
import { useBreakpoints } from 'web-app/ui-layout'
import { SearchResultsList } from './search-results-list'
import { SuggestionCurrentLocation } from './suggestion-current-location'
import { SearchHistoryList } from './search-history-list'
import { SearchSuggestionsOverlay } from './search-suggestions-overlay'
import { SearchSuggestionsDropdown } from './search-suggestions-dropdown'
import { SearchInput } from './search-input'
import clsx from 'clsx'
import { useSearchRequest } from './use-search-request'
import { SearchResult } from './types'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { useUserGeolocation } from 'web-app/feature-user'
import { RegionEntity, RegionEntityMapData, RouteEntity } from 'shared/data-access-core'

import styles from './search.module.scss'

interface SearchProps {
  /** Value shown as long as there is no user input */
  defaultValue?: string

  /** CSS block padding on the container */
  paddingBlock?: string

  /** CSS inline padding on the container */
  paddingInline?: string

  /** Custom icon inside the input */
  icon?: ReactNode | null

  /** Label, visually shown as placeholder */
  label?: string

  /** Optional component to show before the input */
  before?: ReactNode

  /** Optional component to show after the input */
  after?: ReactNode

  /** When this changes to `true`, the search input gets focus (suggestions are shown) */
  autoFocus?: boolean

  /** Called when a place result is selected */
  onPlaceSelect: (place: GeocoderLocation) => void

  /** Called when a region result is selected */
  onRegionSelect?: (region: RegionEntity & RegionEntityMapData) => void

  /** Called when a route result is selected */
  onRouteSelect?: (route: RouteEntity) => void

  /** Shows a reset button while the search input is not focused and is called, when that button is clicked */
  onDefaultReset?: () => void

  /** Called when the search input is focused (suggestions are shown) */
  onFocus?: () => void

  /** Called when the search input is unfocused (suggestions disappear) */
  onBlur?: () => void

  /** Optionally renders additional main items into the default sheet */
  renderSuggestions?: (onSelect: (result: SearchResult) => void) => ReactNode
}

/**
 * Fully functional search with suggestions.
 */
export const Search = ({
  defaultValue = '',
  paddingBlock,
  paddingInline,
  icon,
  label,
  before,
  after,
  autoFocus,
  onDefaultReset,
  onPlaceSelect,
  onRegionSelect,
  onRouteSelect,
  onFocus,
  onBlur,
  renderSuggestions,
}: SearchProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const { layoutBreakpoint, heightBreakpoint } = useBreakpoints()
  const { isLoading, results, resultsPromise, onSearch, resetResults } = useSearchRequest({
    queryRegions: !!onRegionSelect,
    queryRoutes: !!onRouteSelect,
  })
  const { isGeolocationDenied, geolocate } = useUserGeolocation()

  const [hasFocus, setHasFocus] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const unfocus = () => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
    setHasFocus(false)
  }

  useEffect(() => {
    if (hasFocus) {
      onFocus && onFocus()
      if (!isGeolocationDenied) {
        geolocate()
      }
    } else {
      onBlur && onBlur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus])

  useEffect(() => {
    if (autoFocus && inputRef.current && !hasFocus) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus])

  useEffect(() => {
    if (!hasFocus) return
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        unfocus()
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => document.removeEventListener('keydown', onKeyDown, false)
  }, [hasFocus])

  const { inputProps, setValue } = useInputValue(defaultValue, onSearch, QUERY_DEBOUNCE_TIME)

  useEffect(() => {
    if (!hasFocus && inputProps.value !== defaultValue) {
      setValue(defaultValue)
    }
    if (hasFocus) {
      onSearch(inputProps.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus])

  const handleFocus = useCallback(() => {
    if (!inputProps.value) {
      // Lazy reset of results (to avoid flash of default suggestions on defocus)
      resetResults()
    }
    setHasFocus(true)
  }, [inputProps.value, resetResults])

  const handleResultSelect = useCallback(
    (result: SearchResult) => {
      dispatch(resultSelected(result))
      setValue('')
      unfocus()
      if (result.type === 'place') {
        onPlaceSelect(result.data)
      } else if (onRegionSelect && result.type === 'region') {
        onRegionSelect(result.data)
      } else if (onRouteSelect && result.type === 'route') {
        onRouteSelect(result.data)
      }
    },
    [dispatch, onPlaceSelect, onRegionSelect, onRouteSelect, setValue],
  )

  const handleSubmit = useCallback(() => {
    setTimeout(async () => {
      const promise = resultsPromise.current
      if (promise) {
        const results = await promise
        if (results?.length) {
          handleResultSelect(results[0])
        }
      } else if (results?.length) {
        handleResultSelect(results[0])
      }
    }, QUERY_DEBOUNCE_TIME)
  }, [handleResultSelect, results, resultsPromise])

  const inputLabel =
    label ||
    intl.formatMessage({
      id: 'search_label',
      defaultMessage: 'Find locations, routes...',
    })

  const suggestions = useMemo(
    () => (
      <LocationList.Holder>
        {results || (inputProps.value && isLoading) ? (
          <SearchResultsList results={isLoading ? null : results} onSelect={handleResultSelect} />
        ) : (
          <>
            <SuggestionCurrentLocation onSelect={handleResultSelect} />
            {renderSuggestions && renderSuggestions(handleResultSelect)}
            <SearchHistoryList onSelect={handleResultSelect} includeRegions={!!onRegionSelect} />
          </>
        )}
      </LocationList.Holder>
    ),
    [results, inputProps.value, isLoading, handleResultSelect, renderSuggestions, onRegionSelect],
  )

  const isOverlay = !(layoutBreakpoint && heightBreakpoint)

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!isOverlay) {
          unfocus()
        }
      }}
    >
      <div
        className={clsx(styles['container'], { [styles['has-before']]: before, [styles['has-after']]: after })}
        style={paddingBlock || paddingInline ? { paddingBlock, paddingInline } : undefined}
      >
        {before}
        <SearchInput
          ref={inputRef}
          className={styles['input']}
          {...inputProps}
          label={inputLabel}
          permanentClear={!!(onDefaultReset && defaultValue)}
          icon={icon}
          onSubmit={handleSubmit}
          onFocus={handleFocus}
          onReset={
            defaultValue && onDefaultReset && !hasFocus
              ? onDefaultReset
              : () => {
                  resetResults()
                  inputProps.onReset()
                }
          }
        />
        {after}
        {isOverlay ? (
          <SearchSuggestionsOverlay
            open={hasFocus}
            label={inputLabel}
            onClose={() => unfocus()}
            {...inputProps}
            onSubmit={handleSubmit}
          >
            {suggestions}
          </SearchSuggestionsOverlay>
        ) : (
          <SearchSuggestionsDropdown open={hasFocus}>{suggestions}</SearchSuggestionsDropdown>
        )}
      </div>
    </ClickAwayListener>
  )
}
