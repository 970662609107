import { LineString, Position } from 'geojson'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import {
  AlongTheRouteAttribute,
  Position2d,
  Position3d,
  RichLineString,
  composeRichSegment,
  decodePath3d,
} from 'shared/util-geo'
import { API_PATH_MATCHING } from '../definitions'
import { postToRoutingApi } from '../network'

type Response = {
  paths: {
    distance: number
    points: string // encoded
    time: number
    distance_details: {
      bm_surface: AlongTheRouteAttribute<string> | null
      bm_way_type: AlongTheRouteAttribute<string> | null
      bike_network: AlongTheRouteAttribute<string> | null
    }
    extended_details: {
      distance: number[] | null
      time: number[] | null
    }
  }[]
}

/**
 * Call the map matching service to get the map matched version of given route geometry.
 */
export async function fetchMapMatchedGeometry(geometry: LineString): ApiResult<{
  geometry: RichLineString
  distanceMeters: number
  durationSeconds: number
}> {
  try {
    const response: Response = await postToRoutingApi(API_PATH_MATCHING, {
      body: {
        points: geometry,
        instructions: false,
        elevation: true,
        points_encoded: true,
        distance_details: ['bm_surface', 'bm_way_type', 'bike_network'],
        extended_details: ['distance', 'time'],
      },
      type: 'json',
    })

    try {
      const path = response.paths[0]
      if (!path) {
        return createFailureResult({ unexpectedResponse: true })
      }

      return createSuccessResult({
        geometry: {
          type: 'RichLineString',
          coordinates: composeRichSegment(decodePath3d(path.points), {
            distancesMeters: path.extended_details.distance || undefined,
            timesMilliseconds: path.extended_details.time || undefined,
            surfaces: path.distance_details.bm_surface || undefined,
            wayTypes: path.distance_details.bm_way_type || undefined,
            bikeNetworks: path.distance_details.bike_network || undefined,
          }),
        },
        distanceMeters: path.distance,
        durationSeconds: path.time,
      })
    } catch {
      return createFailureResult({ unexpectedResponse: true })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

/**
 * Call the map matching service and map result elevation along the given original geometry
 * (assuming equidistant points to keep it simple).
 */
export async function getApproximateElevation(coordinates: Position2d[]): ApiResult<Position3d[]> {
  try {
    const response: Response = await postToRoutingApi(API_PATH_MATCHING, {
      body: {
        points: {
          type: 'LineString',
          coordinates,
        },
      },
      type: 'json',
    })

    try {
      const path = response.paths[0]
      if (!path) {
        return createFailureResult({ unexpectedResponse: true })
      }
      const matchedCoordinates = decodePath3d(path.points)

      return createSuccessResult(
        coordinates.map(([lng, lat]: Position, i: number): Position3d => {
          const referenceIndex = ((i + 1) / coordinates.length) * matchedCoordinates.length - 1
          return [lng, lat, matchedCoordinates[Math.round(referenceIndex)][2]]
        }),
      )
    } catch {
      return createFailureResult({ unexpectedResponse: true })
    }
  } catch (error) {
    return createFailureResult({ unexpectedError: true })
  }
}
