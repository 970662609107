import { useEffect, useState } from 'react'
import { RegionEntity, RegionEntityDetails, RegionEntityMapData, RegionEntityParents } from 'shared/data-access-core'
import { areLngLatsEqual } from 'shared/util-geo'
import { DiscoverMapRoutes, RouteSelection, useDiscover } from 'web-app/feature-discover'
import { MapLocationSelection, SelectedMapLocation, useMoveMapAway } from 'web-app/feature-map'
import { RouteDetailsPreviousView, useNavigateToDiscover } from 'web-app/feature-navigation'
import { useRouteById } from 'web-app/feature-route'

type RegionEntityWithOptionalDetails =
  | (RegionEntity & RegionEntityMapData & RegionEntityParents)
  | (RegionEntity & RegionEntityMapData & RegionEntityParents & RegionEntityDetails)

interface RegionMapFeaturesProps {
  region: RegionEntityWithOptionalDetails | null
  routeDetailsPreviousView?: RouteDetailsPreviousView
  geonameId: number
}

export const RegionMapFeatures = ({ region, routeDetailsPreviousView, geonameId }: RegionMapFeaturesProps) => {
  const navigateToDiscover = useNavigateToDiscover()

  const [selectedLocation, setSelectedLocation] = useState<SelectedMapLocation | null>(
    deriveSelectedLocationFromRegion(region),
  )
  const [selectedRoute, setSelectedRoute] = useState<RouteSelection | null>(null)

  useDiscover(geonameId)

  useRouteById(selectedRoute?.id)

  /** Update selected location whenever the region changes */
  useEffect(() => {
    setSelectedLocation(deriveSelectedLocationFromRegion(region))
  }, [region])

  /** Clear region when map is moved away from the location completely */
  useMoveMapAway(region?.center || null, () => {
    navigateToDiscover({ keepMapViewport: true })
  })

  const handleLocationSelect = (location: SelectedMapLocation) => {
    setSelectedLocation(location)
  }

  const handleLocationGeocoded = (location: SelectedMapLocation) => {
    setSelectedLocation((current) =>
      current && areLngLatsEqual(current.position, location.position) ? location : current,
    )
  }

  return (
    <>
      <DiscoverMapRoutes
        selectedRoute={selectedRoute}
        interactive={!selectedLocation}
        routeDetailsPreviousView={routeDetailsPreviousView}
        onRouteSelect={(id, start) => setSelectedRoute({ id, ...start })}
        onSelectionCancel={() => setSelectedRoute(null)}
      />
      <MapLocationSelection
        selectedLocation={selectedLocation}
        interactive={!selectedRoute}
        onSelect={handleLocationSelect}
        onGeocoded={handleLocationGeocoded}
        onCancel={() => setSelectedLocation(null)}
      />
    </>
  )
}

function deriveSelectedLocationFromRegion(region: RegionEntityWithOptionalDetails | null): SelectedMapLocation | null {
  if (region?.kind === 'admin_2') {
    return {
      position: region.center,
      address: region.name,
      country: region.country.name,
      admin1: region.admin1.name,
    }
  }
  return null
}
