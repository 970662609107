import { DefaultFooter } from 'web-app/feature-navigation'
import { useAttributions } from './use-attributions'

export const WebAppMapFooter = () => {
  const attributions = useAttributions()

  return (
    <DefaultFooter
      secondaryItems={attributions.map((a) => (
        <span dangerouslySetInnerHTML={{ __html: a }} />
      ))}
    />
  )
}
