import { useLocale } from 'shared/util-intl'
import { useRouteCollectionOwner, useRouteCollection } from 'web-app/feature-route-collection'
import { UserContentReporting } from 'web-app/feature-reporting'
import { getRouteCollectionUrl } from 'shared/util-navigation'

export const OwnerFlagActions = () => {
  const { intl } = useLocale()
  const routeCollection = useRouteCollection()
  const owner = useRouteCollectionOwner()

  return (
    routeCollection && (
      <UserContentReporting
        creator={owner || null}
        entityId={routeCollection.id}
        entityUrl={getRouteCollectionUrl(routeCollection.id)}
        reportLabel={intl.formatMessage({
          id: 'route_collection_report_collection',
          defaultMessage: 'Report collection',
        })}
        entityIdentifier="route collection"
      />
    )
  )
}
