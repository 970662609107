import { useLocation, useParams } from 'react-router-dom'
import { RouteDetails } from 'web-app/view-route-details'
import { ViewNotFound } from 'web-app/utils-error-handling'

const RouteDetailsView = () => {
  const params = useParams()
  const location = useLocation()

  const routeId = params['routeId'] ? Number.parseInt(params['routeId']) : undefined

  const { previousView } = location.state || {}

  return routeId ? <RouteDetails routeId={routeId} initialState={{ previousView }} /> : <ViewNotFound />
}

export default RouteDetailsView
