import { LngLat } from 'shared/util-geo'
import { CompleteWaypoints, IncompleteWaypoints } from '../../state'

export function getSegmentsAffectedByControlPoint(
  controlPoints: LngLat[] | null,
  controlPointIndex: number,
): number[] | null {
  return (
    controlPoints &&
    (controlPointIndex === 0
      ? [0] // only first segment
      : controlPointIndex === controlPoints.length - 1
        ? [controlPoints.length - 2] // only last segment
        : [controlPointIndex - 1, controlPointIndex]) // segments before and after
  )
}

export function getSegmentsAffectedByWaypoint(
  waypoints: IncompleteWaypoints | CompleteWaypoints,
  controlPoints: LngLat[] | null,
  waypointIndex: number,
): number[] | null {
  const waypoint = waypoints[waypointIndex]
  return typeof waypoint?.controlPointIndex === 'number'
    ? getSegmentsAffectedByControlPoint(controlPoints, waypoint.controlPointIndex)
    : null
}
