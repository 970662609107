import { useCallback, useMemo } from 'react'
import { ROUTE_SEARCH_SORTING_OPTIONS, RouteSearchSorting } from 'shared/data-access-core'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_SORTING, SORTING_KEY } from './definitions'

export type DiscoverSortingOptions<ExtraSortingOptionType> = {
  extraSortingOptions?: ExtraSortingOptionType[]
  defaultSorting?: RouteSearchSorting | ExtraSortingOptionType
}

export function useDiscoverSorting<ExtraSortingOptionType extends string = RouteSearchSorting>(
  options: DiscoverSortingOptions<ExtraSortingOptionType> = {},
): [
  sorting: RouteSearchSorting | ExtraSortingOptionType,
  setSorting: (value: RouteSearchSorting | ExtraSortingOptionType) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams()

  const sortingOptions = useMemo<(RouteSearchSorting | ExtraSortingOptionType)[]>(
    () => [...ROUTE_SEARCH_SORTING_OPTIONS, ...(options.extraSortingOptions || [])],
    [options.extraSortingOptions],
  )

  const defaultSorting = useMemo<RouteSearchSorting | ExtraSortingOptionType>(
    () => options.defaultSorting || DEFAULT_SORTING,
    [options.defaultSorting],
  )

  const sorting = useMemo<RouteSearchSorting | ExtraSortingOptionType>(() => {
    const value = searchParams.get(SORTING_KEY)
    return sortingOptions.includes(value as RouteSearchSorting) ? (value as RouteSearchSorting) : defaultSorting
  }, [defaultSorting, searchParams, sortingOptions])

  const setSorting = useCallback(
    (value: RouteSearchSorting | ExtraSortingOptionType) => {
      if (value === defaultSorting) {
        searchParams.delete(SORTING_KEY)
      } else {
        searchParams.set(SORTING_KEY, value)
      }
      setSearchParams(searchParams)
    },
    [defaultSorting, searchParams, setSearchParams],
  )

  return [sorting, setSorting]
}
