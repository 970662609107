import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { WebAppContent } from 'web-app/ui-layout'
import { DiscoverRoutesList, DiscoverPresets } from 'web-app/feature-discover'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'

interface DiscoverRoutesSectionProps {
  withHeading?: boolean
  routeDetailsPreviousView?: RouteDetailsPreviousView
  onRouteClick: () => void
}

export const DiscoverRoutesSection = ({
  withHeading = true,
  routeDetailsPreviousView,
  onRouteClick,
}: DiscoverRoutesSectionProps) => {
  const { intl } = useLocale()

  return (
    <>
      {withHeading && (
        <WebAppContent noPadding>
          <Typography variant="h3" component="h2" margin="0.75rem 1rem 0.5rem">
            {intl.formatMessage({
              id: 'home_discover_routes_heading',
              defaultMessage: 'Routes in this area',
            })}
          </Typography>
        </WebAppContent>
      )}
      <WebAppContent stickyOnTop stickyOffset="4.75rem" noPadding>
        <DiscoverPresets />
      </WebAppContent>
      <WebAppContent>
        <DiscoverRoutesList routeDetailsPreviousView={routeDetailsPreviousView} onRouteClick={onRouteClick} />
      </WebAppContent>
    </>
  )
}
