import { RouteCollectionEntity } from 'shared/data-access-core'
import { RouteCollectionItem } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

interface CollectionItemProps {
  collection: RouteCollectionEntity
}

export const CollectionItem = ({ collection }: CollectionItemProps) => {
  const { intl } = useLocale()

  return (
    <RouteCollectionItem
      title={collection.title}
      info={intl.formatMessage(
        {
          id: 'collection_item_info',
          defaultMessage: '{routesCount} saved routes',
        },
        {
          routesCount: collection.routesCount,
        },
      )}
      image={collection.image?.item || null}
    />
  )
}
