import { useLocale } from 'shared/util-intl'
import { RouteEntity, RouteEntityDetails } from 'shared/data-access-core'
import { useRouteContentEditing } from '../use-route-content-editing'
import { EditableText } from 'web-app/feature-editable-content'

interface EditableRouteDescriptionProps {
  heading: string
  route?: RouteEntity & RouteEntityDetails
}

export const EditableRouteDescription = ({ heading, route }: EditableRouteDescriptionProps) => {
  const { intl } = useLocale()
  const contentEditingProps = useRouteContentEditing('description')

  return (
    <EditableText
      currentValue={route ? route.description || '' : undefined}
      heading={heading}
      emptyPlaceholder={intl.formatMessage({
        id: 'route_description_empty_placeholder',
        defaultMessage: 'No description yet.',
      })}
      label={intl.formatMessage({
        id: 'route_description_form_label',
        defaultMessage: 'Description',
      })}
      placeholder={intl.formatMessage({
        id: 'route_description_form_placeholder',
        defaultMessage: 'Add a description',
      })}
      {...contentEditingProps}
    />
  )
}
