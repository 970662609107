import clsx from 'clsx'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'
import { Button, SortableList } from 'shared/ui-components'
import {
  Waypoint,
  useWaypoints,
  reverseRoute,
  RoutePlannerSliceDispatch,
  updateWaypointsOrder,
  CompleteWaypoints,
  useRoutePlannerState,
} from '../../state'
import { WaypointsListItem } from './waypoints-list-item'
import { WebAppContent, useBreakpoints } from 'web-app/ui-layout'

import styles from './index.module.scss'

type DefinedWaypoint = Waypoint & {
  index: number
}

interface LocationsListProps {
  onWaypointItemFocus: (waypointRef: React.RefObject<HTMLDivElement>) => void
}

export const LocationsList = ({ onWaypointItemFocus }: LocationsListProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { isInitialized, isCalculatingRoute } = useRoutePlannerState()
  const { isFullRoute, waypoints, start, end } = useWaypoints()
  const { layoutBreakpoint, heightBreakpoint } = useBreakpoints()
  const isFullScreenOverlay = !layoutBreakpoint || !heightBreakpoint

  /** Empty waypoints list items represented by the index of the defined waypoint they are inserted before  */
  const [insertedItems, setInsertedItems] = useState<number[]>([])

  /** Index of the item in the waypoints list that is currently searched on */
  const [searchItem, setSearchItem] = useState<number | null>(null)

  /** Combined list of existing and inserted (empty) waypoints in order */
  const waypointsList: (DefinedWaypoint | number)[] = useMemo(() => {
    if (!isFullRoute) {
      return [start ? { ...start, index: 0 } : 0, end ? { ...end, index: 1 } : 1]
    }

    const list: (DefinedWaypoint | number)[] = waypoints.map((waypoint, index) => ({ ...waypoint, index }))
    for (const beforeIndex of insertedItems) {
      const definedItemIndex = list.findIndex((w) => typeof w === 'object' && w.index === beforeIndex)
      if (definedItemIndex >= 0) {
        list.splice(definedItemIndex, 0, beforeIndex)
      } else {
        list.push(beforeIndex)
      }
    }
    return list
  }, [end, insertedItems, isFullRoute, start, waypoints])

  const handleFocus = useCallback(
    (waypointsListIndex: number, ref: React.RefObject<HTMLDivElement>) => {
      // If there is previously active waypoint, there are too many conflicting animations so scrolled position is wrong
      const noActiveSearch = typeof searchItem !== 'number'
      if (!isFullScreenOverlay && noActiveSearch && waypointsList.length > 2) {
        if (ref.current) {
          onWaypointItemFocus(ref)
        }
      }
      setSearchItem(waypointsListIndex)
    },
    [searchItem, isFullScreenOverlay, waypointsList.length, onWaypointItemFocus],
  )

  const handleBlur = (waypointsListIndex: number) => {
    setSearchItem((searchItem) => (waypointsListIndex === searchItem ? null : searchItem))
  }

  const handleSort = useCallback(
    (sortedWaypointsList: { value: DefinedWaypoint | number }[]) => {
      if (isCalculatingRoute || waypointsList.length < 3) return
      const definedWaypoints: Waypoint[] = []
      const insertedItems: number[] = []
      let nextIndex = 0
      for (const item of sortedWaypointsList) {
        if (typeof item.value === 'object') {
          definedWaypoints.push(item.value)
          nextIndex++
        } else {
          insertedItems.push(nextIndex)
        }
      }
      for (const waypointIndex in definedWaypoints) {
        // dispatch action if any item changed
        const original = waypoints[waypointIndex]
        const sorted = definedWaypoints[waypointIndex]
        if (!(original?.lng === sorted.lng && original.lat === sorted.lat)) {
          dispatch(updateWaypointsOrder(definedWaypoints as CompleteWaypoints))
          break
        }
      }
      setInsertedItems(insertedItems)
    },
    [dispatch, isCalculatingRoute, waypoints, waypointsList.length],
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (layoutBreakpoint && isInitialized) {
        if (!start && !end) {
          setSearchItem(1) // Focus destination item initially
        }
        if (!start && end) {
          setSearchItem(0) // Focus origin item if destination has already been selected
        }
      }
    }, 300)

    return () => clearTimeout(timeout)
  }, [end, isInitialized, layoutBreakpoint, start])

  const handleInsertedItemObsolete = useCallback(
    (waypointsListIndex: number, willTurnIntoWaypoint: boolean) => {
      if (searchItem === waypointsListIndex) {
        setSearchItem(null)
      }
      if (isFullRoute) {
        const insertedItems: number[] = []
        let nextIndex = 0
        waypointsList.forEach((item, i) => {
          if (typeof item === 'object' || (willTurnIntoWaypoint && i === waypointsListIndex)) {
            nextIndex++
          } else if (i !== waypointsListIndex) {
            insertedItems.push(nextIndex)
          }
        })
        setInsertedItems(insertedItems)
      }
    },
    [isFullRoute, searchItem, waypointsList],
  )

  return (
    <WebAppContent noPadding>
      <div
        className={clsx({
          [styles['list-small-viewport']]: !layoutBreakpoint,
          [styles['list-large-viewport']]: layoutBreakpoint,
          [styles['list-large-viewport-initial-with-results']]:
            layoutBreakpoint && !isFullRoute && searchItem === waypointsList.length - 1,
        })}
      >
        <SortableList<{ value: DefinedWaypoint | number; key: string }>
          items={waypointsList.map((value, i) => ({
            value,
            key: typeof value === 'number' ? value.toString() : '' + i + value.lng + value.lat,
          }))}
          renderItem={(item, handleProps, contextData, itemData) => (
            <WaypointsListItem
              waypointsListIndex={itemData.index}
              waypointIndex={typeof item.value === 'object' ? item.value.index : null}
              beforeIndex={typeof item.value === 'number' ? item.value : null}
              isLast={itemData.index === waypointsList.length - 1}
              item={typeof item.value === 'object' ? item.value : null}
              isSearchActive={itemData.index === searchItem}
              isSorting={!!contextData.active}
              sortableHandleProps={!isCalculatingRoute && waypointsList.length > 2 ? handleProps : undefined}
              onInsertedItemObsolete={handleInsertedItemObsolete}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
          onSort={handleSort}
        />
      </div>
      {isFullRoute && (
        <div className={styles['buttons']}>
          <Button
            size="small"
            variant="secondary"
            icon={<AddRoundedIcon />}
            onClick={(e) => {
              // Skip ClickAwayListener's onClickAway
              e.stopPropagation()
              setSearchItem(null)
              setInsertedItems([...insertedItems, waypoints.length])
            }}
          >
            {intl.formatMessage({
              id: 'route_planner_add_waypoint_title',
              defaultMessage: 'Add waypoint',
            })}
          </Button>
          <Button
            size="small"
            variant="secondary"
            icon={<SwapVertRoundedIcon />}
            onClick={() => dispatch(reverseRoute())}
            disabled={isCalculatingRoute}
          >
            {intl.formatMessage({
              id: 'route_planner_reverse_order_title',
              defaultMessage: 'Reverse order',
            })}
          </Button>
        </div>
      )}
    </WebAppContent>
  )
}

export default LocationsList
