import { useLocale } from 'shared/util-intl'
import ExploreIcon from '@mui/icons-material/Explore'
import { NavigationLinkProps } from './types'
import { Button } from 'shared/ui-components'
import { useInternalLink } from '../use-internal-link'
import { WEB_APP_PATH_DISCOVER } from 'shared/util-navigation'

export const DiscoverLink = ({ primary, compact }: NavigationLinkProps) => {
  const { intl } = useLocale()
  const getInternalLinkProps = useInternalLink()

  return (
    <Button
      block
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      icon={<ExploreIcon />}
      {...getInternalLinkProps(WEB_APP_PATH_DISCOVER)}
      disabled={window.location.pathname === WEB_APP_PATH_DISCOVER}
    >
      {intl.formatMessage({
        id: 'discover_link_label',
        defaultMessage: 'Discover routes',
      })}
    </Button>
  )
}
