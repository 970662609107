import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#map-marker-accommodation-filter)">
      <path
        fill="#000"
        fillOpacity=".15"
        d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"
      />
    </g>
    <path
      fill="url(#map-marker-accommodation-gradient)"
      stroke="#fff"
      strokeWidth="1.5"
      d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"
    />
    <path
      fill="#fff"
      d="M9.667 21.833a.806.806 0 0 1-.594-.24.807.807 0 0 1-.24-.593V10.167c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.24.358.24.594v7.5h6.666v-5c0-.459.163-.851.49-1.177.326-.327.718-.49 1.177-.49h5a3.21 3.21 0 0 1 2.354.98 3.21 3.21 0 0 1 .979 2.353V21c0 .236-.08.434-.24.594a.806.806 0 0 1-.593.24.807.807 0 0 1-.594-.24.807.807 0 0 1-.24-.594v-1.667h-15V21c0 .236-.08.434-.24.594a.806.806 0 0 1-.593.24Zm4.167-5a2.41 2.41 0 0 1-1.771-.729 2.411 2.411 0 0 1-.73-1.77c0-.695.244-1.285.73-1.771a2.411 2.411 0 0 1 1.77-.73c.695 0 1.285.243 1.771.73.486.486.73 1.076.73 1.77 0 .695-.244 1.285-.73 1.771a2.41 2.41 0 0 1-1.77.73Z"
    />
    <defs>
      <linearGradient
        id="map-marker-accommodation-gradient"
        x1="27.44"
        x2="-1.476"
        y1="6.126"
        y2="29.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#51B64E" />
        <stop offset="1" stopColor="#336732" />
      </linearGradient>
      <filter
        id="map-marker-accommodation-filter"
        width="34.666"
        height="18.667"
        x=".667"
        y="38.667"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2540_33191" stdDeviation="2" />
      </filter>
    </defs>
  </svg>
)

export default svg
