import { createAction } from '@reduxjs/toolkit'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import {
  initialState,
  waypointRemoved,
  routeReversed,
  waypointsListSorted,
  routingProfileChanged,
  routingLevelChanged,
  routePlannerReducer,
  waypointInserted,
  controlPointRemoved,
  firstSegmentRemoved,
  lastSegmentRemoved,
  implicitWaypointDrawn,
  selectedLocationAddedAlongRoute,
  segmentDivided,
  controlPointInserted,
  waypointMoved,
  waypointUpdated,
  routingAppliedToWholeRoute,
  controlPointMoved,
} from './state'
import undoable, { UndoableOptions } from './undo-redo'
import { PLANNER_SLICE_KEY, RoutePlannerState } from './types'

export const undo = createAction(`${PLANNER_SLICE_KEY}/undo`)
export const redo = createAction(`${PLANNER_SLICE_KEY}/redo`)

type PersistedRoutePlannerState = RoutePlannerState & PersistPartial

const undoableOptions: UndoableOptions<PersistedRoutePlannerState> = {
  initialState: initialState as PersistedRoutePlannerState,
  actions: {
    undo,
    redo,
  },
  whitelist: ['waypoints', 'routingProfile', 'routingLevel', 'isWholeRouteRouted', 'geometry'],
  includedActions: [
    waypointRemoved,
    controlPointRemoved,
    firstSegmentRemoved,
    lastSegmentRemoved,
    waypointInserted,
    implicitWaypointDrawn,
    selectedLocationAddedAlongRoute,
    segmentDivided,
    controlPointInserted,
    waypointMoved,
    controlPointMoved,
    waypointUpdated,
    routeReversed,
    waypointsListSorted,
    routingProfileChanged,
    routingLevelChanged,
    routingAppliedToWholeRoute,
  ].map((action) => action.type),
  maxHistory: 100,
  allowUnchangedHistoryEntry: true, // synchronous undoable action might not include route changes yet
}

export const undoablePlannerReducer = undoable(routePlannerReducer, undoableOptions)
