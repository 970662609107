import { Input, useInputValue } from 'shared/ui-components'
import { FILTERS_CHANGE_DEBOUNCE } from 'shared/util-discover'
import { useLocale } from 'shared/util-intl'
import { useDiscoverFilters } from '../use-discover-filters'
import { useCallback } from 'react'

export const TitleField = () => {
  const { intl } = useLocale()
  const [filters, setFilters] = useDiscoverFilters()

  const handleChange = useCallback((title: string) => setFilters({ title }), [setFilters])

  const { inputProps } = useInputValue(filters.title, handleChange, FILTERS_CHANGE_DEBOUNCE)

  return (
    <Input
      name="discover-title"
      label={intl.formatMessage({
        id: 'discover_filter_title_label',
        defaultMessage: 'Title includes',
      })}
      placeholder={intl.formatMessage({
        id: 'discover_filter_title_placeholder',
        defaultMessage: 'Filter routes by title keywords',
      })}
      {...inputProps}
    />
  )
}
