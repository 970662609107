import { RouteEntity } from 'shared/data-access-core'
import React from 'react'
import { getRouteDetailsPath } from 'shared/util-navigation'
import { RouteEntityMiniItem } from 'shared/feature-routes'
import {
  RouteDetailsPreviousView,
  useNavigateToCollectionRouteDetails,
  useNavigateToRouteDetails,
} from 'web-app/feature-navigation'

interface RouteEntityMiniItemProps {
  route: RouteEntity
  imperial?: boolean
  routeDetailsPreviousView?: RouteDetailsPreviousView
  routeCollectionId?: number
  onClick?: (e: React.MouseEvent) => void
}

/**
 * Renders a route mini item based on a given route entity.
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const WebAppRouteEntityMiniItem = ({
  route,
  imperial,
  routeDetailsPreviousView,
  routeCollectionId,
  onClick,
}: RouteEntityMiniItemProps) => {
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const navigateToCollectionRouteDetails = useNavigateToCollectionRouteDetails()

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
    e.preventDefault()
    if (routeCollectionId) {
      navigateToCollectionRouteDetails(route.id, routeCollectionId)
    } else {
      navigateToRouteDetails(route.id, { state: { previousView: routeDetailsPreviousView } })
    }
  }

  return (
    <RouteEntityMiniItem route={route} imperial={imperial} href={getRouteDetailsPath(route.id)} onClick={handleClick} />
  )
}
