import { Skeleton, Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { WebAppContent } from 'web-app/ui-layout'
import { DiscoverPresets, DiscoverRoutesList } from 'web-app/feature-discover'
import { RegionKind } from 'shared/data-access-core'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'

interface DiscoverRoutesSectionProps {
  geonameId: number
  regionName?: string
  regionKind?: RegionKind
  routeDetailsPreviousView?: RouteDetailsPreviousView
}

export const DiscoverRoutesSection = ({
  geonameId,
  regionName,
  regionKind,
  routeDetailsPreviousView,
}: DiscoverRoutesSectionProps) => {
  const { intl } = useLocale()

  return (
    <>
      <WebAppContent noPadding>
        <Typography variant="h3" component="h2" margin="0.75rem 1rem 0.5rem">
          {regionName && regionKind ? (
            regionKind === 'admin_2' ? (
              intl.formatMessage(
                {
                  id: 'region_discover_routes_heading_admin_2',
                  defaultMessage: 'Routes in and around {region}',
                },
                {
                  region: regionName,
                },
              )
            ) : (
              intl.formatMessage(
                {
                  id: 'region_discover_routes_heading',
                  defaultMessage: 'Routes in {region}',
                },
                {
                  region: regionName,
                },
              )
            )
          ) : (
            <Skeleton width="70%" />
          )}
        </Typography>
      </WebAppContent>
      <WebAppContent stickyOnTop stickyOffset="4.75rem" noPadding>
        <DiscoverPresets />
      </WebAppContent>
      <WebAppContent>
        <DiscoverRoutesList routeDetailsPreviousView={routeDetailsPreviousView} geonameId={geonameId} />
      </WebAppContent>
    </>
  )
}
