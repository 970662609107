import { ExternalErrorLoggerType, LogErrorType } from './types'

/**
 * Log errors.
 */
export class Logger {
  public externalLogger: ExternalErrorLoggerType | undefined

  public setExternalLogger = (externalLogger: ExternalErrorLoggerType): this => {
    this.externalLogger = externalLogger
    return this
  }

  private messagePrefix: string | undefined

  public setMessagePrefix = (prefix: string): this => {
    this.messagePrefix = prefix
    return this
  }

  public logError: LogErrorType = (message, error, details, level): string => {
    const hostname = typeof window !== 'undefined' ? window.location.hostname : 'server-side'
    // If error not passed, create a new one to get stack trace and other useful info
    const getError = error && error instanceof Error ? error : new Error(hostname + ' unknown')

    const prefix = `[${hostname}]${this.messagePrefix ? `[${this.messagePrefix}]` : ''}`

    console.error(`${prefix}[Error] ${message}\n`, getError)
    if (details) {
      console.error(`${prefix}[ErrorDetails] `, details)
    }

    if (typeof this.externalLogger === 'function') {
      this.externalLogger(getError, details || {}, level || 10)
    }

    return message
  }
}

export default Logger
