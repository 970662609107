import { useDispatch } from 'react-redux'
import {
  RouteCollectionSliceDispatch,
  saveGlobalRouteCollectionChanges,
  useIsOwnRouteCollection,
  useRouteCollection,
} from 'web-app/feature-route-collection'

export function useRouteCollectionContentEditing<ValueType>(name: string): {
  name: string
  canEdit?: boolean
  onSubmit: (value: ValueType) => Promise<void>
} {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const routeCollection = useRouteCollection()
  const isOwnRouteCollection = useIsOwnRouteCollection()

  return {
    name,
    canEdit: isOwnRouteCollection,
    onSubmit: async (value: ValueType) => {
      if (routeCollection) {
        const form = { [name]: value }
        await dispatch(saveGlobalRouteCollectionChanges(form)).unwrap()
      }
    },
  }
}
