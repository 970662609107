import { Popover, PopoverProps, Typography } from '@mui/material'
import React, { ForwardedRef, ReactNode, forwardRef, useRef, useState } from 'react'
import clsx from 'clsx'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Button } from 'shared/ui-components'

import styles from './dropdown-menu.module.scss'

export const DropdownMenu = (popoverProps: PopoverProps) => {
  return (
    <Popover
      marginThreshold={16}
      {...popoverProps}
      slotProps={{
        paper: {
          className: styles['popover'],
        },
      }}
      data-testid="user-menu"
    />
  )
}

interface DropdownMenuGroupProps {
  children: ReactNode
  heading?: string
  className?: string
}

export const DropdownMenuGroup = ({ children, heading, className }: DropdownMenuGroupProps) => (
  <div className={clsx(styles['group'], className)}>
    {heading && (
      <Typography variant="h4" color="textSecondary" margin="0.25rem 0.5rem">
        {heading}
      </Typography>
    )}
    {children}
  </div>
)

interface DropdownMenuItemProps {
  variant?: 'default' | 'danger'
  children: ReactNode
  icon?: ReactNode
  href: string
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const DropdownMenuItem = forwardRef(
  (
    { variant = 'default', children, icon, href, active, onClick }: DropdownMenuItemProps,
    ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>,
  ) =>
    active ? (
      <div
        ref={ref as ForwardedRef<HTMLDivElement>}
        className={clsx(styles['item'], styles['item-active'], {
          [styles['item-danger']]: variant === 'danger',
          [styles['item-with-icon']]: !!icon,
        })}
      >
        {icon}
        {children}
      </div>
    ) : (
      <a
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={clsx(styles['item'], styles['item-link'], {
          [styles['item-danger']]: variant === 'danger',
          [styles['item-with-icon']]: !!icon,
        })}
        href={href}
        onClick={onClick}
      >
        {icon}
        {children}
        <ChevronRightRoundedIcon className={styles['item-chevron']} />
      </a>
    ),
)

interface DropdownMenuOptionsItemProps {
  children: ReactNode
  icon?: ReactNode
  alternative: string
  onSwitch: () => void
}

export const DropdownMenuOptionsItem = ({ children, icon, alternative, onSwitch }: DropdownMenuOptionsItemProps) => {
  const ref = useRef(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <button
        ref={ref}
        className={clsx(styles['options-item'], {
          [styles['item-with-icon']]: !!icon,
        })}
        onClick={() => setIsOpen(true)}
      >
        {icon}
        {children}
        <ExpandMoreRoundedIcon className={styles['item-chevron']} />
      </button>
      <Popover
        anchorEl={ref.current}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        slotProps={{
          paper: {
            className: styles['option-alternative'],
          },
        }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            onSwitch()
            setIsOpen(false)
          }}
        >
          {alternative}
        </Button>
      </Popover>
    </>
  )
}
