import { LineString } from 'geojson'
import { postToCoreApi } from '../network'
import { API_PATH_GEO_ROUTE_SIMPLIFICATION } from '../config'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { RichLineString, reduceToLineString } from 'shared/util-geo'
import { enrichGeometry } from './helpers'

type Response = {
  points_simplified: LineString

  /**
   * Seems wrong...
   * @see https://bikemap.slack.com/archives/C0113ULLRV3/p1710511694587419
   * @see https://bikemap.atlassian.net/browse/WEB-1420
   * @deprecated
   */
  distance?: number
}

export type GeometrySimplificationResult = {
  geometry: RichLineString

  /**
   * Seems wrong...
   * @see https://bikemap.slack.com/archives/C0113ULLRV3/p1710511694587419
   * @see https://bikemap.atlassian.net/browse/WEB-1420
   * @deprecated
   */
  distance: number | null
}

/**
 * Send original route geometry and get its simplified version.
 * @link https://development.bikemap.net/api/swagger-ui/#/georoutesimplification/georoutesimplification_create
 */
export async function simplifyRouteGeometry(
  geometry: RichLineString,
  tolerance = 2,
): ApiResult<GeometrySimplificationResult> {
  try {
    const res: Response = await postToCoreApi(API_PATH_GEO_ROUTE_SIMPLIFICATION, {
      body: {
        tolerance: tolerance.toString(),
        get_distance: true,
        points_unsimplified: reduceToLineString(geometry),
      },
      type: 'json',
    })

    try {
      const parser = new ResponseParser(res)
      return createSuccessResult({
        geometry: await enrichGeometry(parser.requireLineString('points_simplified')),
        distance: parser.takeNumber('distance'),
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
