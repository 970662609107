import { ROUTE_TITLE_MAX_LENGTH, ROUTE_TITLE_MIN_LENGTH } from 'shared/data-access-core'

export function isRouteTitleTooShort(title: string): boolean {
  const letters = title.match(/\p{L}/gu)
  const countedCharacters = letters ? letters.length : 0
  return countedCharacters < ROUTE_TITLE_MIN_LENGTH
}

export function isRouteTitleTooLong(title: string): boolean {
  return title.length > ROUTE_TITLE_MAX_LENGTH
}
