import { useDispatch } from 'react-redux'
import { ErrorBoundary, ErrorBoundaryFallback } from 'web-app/utils-error-handling'
import { reset } from '../state'
import { RoutePlannerCreate } from './create'
import { RoutePlannerEdit } from './edit'
import { RoutePlannerInitializationState } from 'web-app/feature-navigation'

interface RoutePlannerProps {
  routeId?: number
  waypoints?: string
  initializationState?: RoutePlannerInitializationState
}

export const RoutePlanner = ({ routeId, waypoints, initializationState }: RoutePlannerProps) => {
  const dispatch = useDispatch()

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          beforeReload={async () => {
            dispatch(reset())
          }}
        />
      }
    >
      {routeId ? (
        <RoutePlannerEdit routeId={routeId} />
      ) : (
        <RoutePlannerCreate waypoints={waypoints} initializationState={initializationState} />
      )}
    </ErrorBoundary>
  )
}
