import { plannedRouteStatsSelector, routePlannerSliceSelector, waypointsSelector } from '../selectors'
import {
  initialControlPointsInserted,
  initializedBlank,
  initializedFromRoute,
  initializedWithState,
  initializedWithWaypoints,
  reset,
} from '../state'
import { RoutePlannerSliceDispatch, Waypoint, StateWithRoutePlannerSlice } from '../types'
import { logError } from 'web-app/utils-error-handling'
import { isEqual } from 'lodash'
import {
  DEFAULT_VIEWPORT,
  MAP_SLICE_KEY,
  StateWithMapSlice,
  getIpLocationViewport,
  viewportDesired,
} from 'web-app/feature-map'
import { StateWithRouteSlice, initRouteById, isOwnRouteSelector, routeSelector } from 'web-app/feature-route'
import { reverseGeocodeWaypoints } from './geocoding'
import { calculateRoute } from './route-calculation'
import { decodeLngLatsPath, lngLatToPosition2d, positionToLngLat } from 'shared/util-geo'
import { simplifyRouteGeometry } from 'shared/data-access-core'
import { StateWithUserSlice } from 'web-app/feature-user'
import { RoutePlannerInitializationState } from 'web-app/feature-navigation'
import { fitMapToGeometryBounds } from './thunks'

/**
 * Initialize a blank route planner session.
 */
export function initBlank() {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithMapSlice,
  ) => {
    const state = getState()
    const { start, end } = waypointsSelector(state)
    const { viewport } = state[MAP_SLICE_KEY]
    const { geometry } = routePlannerSliceSelector(state)

    dispatch(initializedBlank())

    if (start && end) {
      // a route is already being planned
      if (geometry) {
        dispatch(fitMapToGeometryBounds())
      } else {
        await dispatch(calculateRoute())
        dispatch(fitMapToGeometryBounds())
        if (routePlannerSliceSelector(getState()).routingErrors) {
          dispatch(reset()) // otherwise there would be no way to get out of this state (nothing to undo)
        }
      }
      dispatch(reverseGeocodeWaypoints())
    } else if (isEqual(viewport, DEFAULT_VIEWPORT)) {
      // no custom map viewport from user actions yet
      const locationViewport = await getIpLocationViewport()
      if (locationViewport) {
        dispatch(viewportDesired(locationViewport))
      }
    }
  }
}

/**
 * Initialize the route planner based on an encoded waypoints string.
 */
export function initWithWaypoints(waypointsParam: string) {
  return async (dispatch: RoutePlannerSliceDispatch) => {
    const waypointsFromUrl = decodeLngLatsPath(waypointsParam)
    if (waypointsFromUrl.length) {
      dispatch(initializedWithWaypoints(waypointsFromUrl as [Waypoint, ...Waypoint[]]))
      await dispatch(calculateRoute())
      if (waypointsFromUrl.length > 1) {
        dispatch(fitMapToGeometryBounds())
      } else {
        dispatch(
          viewportDesired({
            center: lngLatToPosition2d(waypointsFromUrl[0]),
            zoom: 12,
          }),
        )
      }
      dispatch(reverseGeocodeWaypoints())
    } else {
      logError('Could not initialize waypoints from URL param.')
      dispatch(initBlank())
    }
  }
}

/**
 * Initialize the route planner with a certain initial state, eg for entry points from other views.
 */
export function initWithState(initializationState: RoutePlannerInitializationState) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    dispatch(initializedWithState(initializationState))
    if (waypointsSelector(getState()).isFullRoute) {
      await dispatch(calculateRoute())
      dispatch(fitMapToGeometryBounds())
      dispatch(reverseGeocodeWaypoints())
    }
  }
}

/**
 * Initialize the route planner based on an existing route for editing or creating a copy.
 */
export function initFromRoute(routeId: number, signal: AbortSignal) {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithRouteSlice & StateWithUserSlice,
  ) => {
    const { basedOnRouteId } = routePlannerSliceSelector(getState())

    if (basedOnRouteId !== routeId) {
      dispatch(reset())
    }

    await dispatch(initRouteById(routeId))
    if (signal.aborted) return
    const route = routeSelector(getState())
    if (!route) return

    if (basedOnRouteId !== route.id) {
      dispatch(initializedFromRoute(route))

      const isOwnRoute = isOwnRouteSelector(getState())
      if (!isOwnRoute || route.controlPointIndexes.length < 3) {
        insertInitialControlPoints(dispatch, getState, signal)
      }
    }

    dispatch(reverseGeocodeWaypoints())
    dispatch(fitMapToGeometryBounds())
  }
}

async function insertInitialControlPoints(
  dispatch: RoutePlannerSliceDispatch,
  getState: () => StateWithRoutePlannerSlice,
  signal: AbortSignal,
) {
  const state = getState()
  const { geometry } = routePlannerSliceSelector(state)
  const stats = plannedRouteStatsSelector(state)
  if (!geometry || !stats) return
  let offset = 0
  for (let segmentIndex = 0; segmentIndex < geometry.coordinates.length; segmentIndex++) {
    const res = await simplifyRouteGeometry(
      {
        type: 'RichLineString',
        coordinates: geometry.coordinates[segmentIndex],
      },
      Math.max(stats.distanceMeters * 0.04, 500),
    )
    if (signal.aborted) return
    if (res.success) {
      const splitLocations = res.data.geometry.coordinates.slice(1, -1).map(positionToLngLat)
      if (splitLocations.length) {
        dispatch(
          initialControlPointsInserted({
            segmentIndex: segmentIndex + offset,
            locations: splitLocations,
          }),
        )
        offset += splitLocations.length
      }
    }
  }
}
