import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" {...props}>
    <g filter="url(#map-marker-interaction-filter)">
      <circle cx="13" cy="13" r="8.5" fill="#fff" stroke="#0071E8" strokeWidth="2" />
      <path
        fill="#0873E7"
        d="M8.333 12.333a.646.646 0 0 1-.475-.192.644.644 0 0 1-.191-.474c0-.19.063-.348.19-.476A.647.647 0 0 1 8.334 11h9.333c.19 0 .348.064.475.191a.646.646 0 0 1 .192.476.643.643 0 0 1-.192.474.644.644 0 0 1-.474.192H8.332Zm0 2.667a.646.646 0 0 1-.475-.192.645.645 0 0 1-.191-.475c0-.189.063-.347.19-.475a.647.647 0 0 1 .476-.191h9.333c.19 0 .348.063.475.191a.645.645 0 0 1 .192.475.644.644 0 0 1-.192.475.644.644 0 0 1-.474.192H8.332Z"
      />
    </g>
    <defs>
      <filter
        id="map-marker-interaction-filter"
        width="30"
        height="48"
        x="-2"
        y="-10"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2870_22233" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation=".5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="effect1_dropShadow_2870_22233" result="effect2_dropShadow_2870_22233" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation=".5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
        <feBlend in2="effect2_dropShadow_2870_22233" result="effect3_dropShadow_2870_22233" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_2870_22233" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default svg
