import React, { ReactNode } from 'react'
import { BaseButton } from '../../base/BaseButton'

import styles from './illustrated-button.module.css'

export interface IllustratedButtonProps {
  children: string
  image: {
    png: string
    webp: string
    avif: string
  }
  icon?: ReactNode
  href?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

export const IllustratedButton = ({ children, image, icon, href, onClick }: IllustratedButtonProps) => (
  <BaseButton variant="primary" className={styles['button']} href={href} onClick={onClick}>
    <picture>
      <source srcSet={image.avif} type="image/avif" />
      <source srcSet={image.webp} type="image/webp" />
      <source srcSet={image.png} type="image/png" />
      <img className={styles['image']} src={image.png} alt="" width="368" height="152" />
    </picture>
    <span className={styles['label']}>
      {icon && <span className={styles['icon']}>{icon}</span>}
      {children}
    </span>
  </BaseButton>
)
