import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useLocale } from 'shared/util-intl'
import { Modal, ToolButton, useMessages } from 'shared/ui-components'
import { useBreakpoints } from './use-breakpoints'

import styles from './web-app-additional-content.module.scss'

export interface WebAppAdditionalContentProps {
  children: React.ReactNode
  open: boolean
  onClose?: () => void
  closeButtonOnColor?: boolean
}

const AdditionalContentCard = ({ children, open, onClose, closeButtonOnColor }: WebAppAdditionalContentProps) => {
  const { closeLabel } = useMessages()

  return open ? (
    <div className={styles['large']}>
      {children}
      {onClose && (
        <span className={styles['close-button']}>
          <ToolButton
            icon={<CloseIcon />}
            ariaLabel={closeLabel}
            variant={closeButtonOnColor ? 'onColor-ghost' : 'ghost-secondary'}
            onClick={onClose}
          />
        </span>
      )}
    </div>
  ) : null
}

const AdditionalContentModal = ({ children, open, onClose, closeButtonOnColor }: WebAppAdditionalContentProps) => {
  const { intl } = useLocale()

  const label = intl.formatMessage({
    id: 'web_app_additional_content_modal_label',
    defaultMessage: 'Additional content',
  })

  return (
    <Modal fullScreen open={open} aria-label={label} onClose={onClose} closeButtonOnColor={closeButtonOnColor}>
      <div>{children}</div>
    </Modal>
  )
}

/**
 * Additional content that renders next to the main content and covers the media.
 */
export const WebAppAdditionalContent = (props: WebAppAdditionalContentProps) => {
  const { layoutBreakpoint } = useBreakpoints()

  return layoutBreakpoint ? <AdditionalContentCard {...props} /> : <AdditionalContentModal {...props} />
}
