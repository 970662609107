import { Fade, Modal } from '@mui/material'
import { GetAppInterstitial } from 'shared/feature-mobile-apps'
import { Button } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { HEADER_HEIGHT_REM } from 'web-app/ui-layout'
import { getDeeplinkFromWebAppPath } from 'shared/util-navigation'

import styles from './get-app-overlay.module.scss'

interface GetAppOverlayProps {
  isOpen: boolean
  onClose: () => void
}

export const GetAppOverlay = ({ isOpen, onClose }: GetAppOverlayProps) => {
  const { intl } = useLocale()

  return (
    <Modal
      open={isOpen}
      aria-label={intl.formatMessage({
        id: 'get_app_overlay_label',
        defaultMessage: 'Continue with the Bikemap App for iOS or Android',
      })}
      closeAfterTransition
      hideBackdrop
      style={{ top: HEADER_HEIGHT_REM + 'rem' }}
    >
      <Fade in={isOpen}>
        <div className={styles['container']}>
          <GetAppInterstitial
            intl={intl}
            deeplink={getDeeplinkFromWebAppPath(window.location.pathname, window.location.search)}
          />
          <Button variant="ghost-primary" onClick={onClose}>
            {intl.formatMessage({
              id: 'get_app_overlay_cancel',
              defaultMessage: 'Continue on web',
            })}
          </Button>
        </div>
      </Fade>
    </Modal>
  )
}
