import { API_PATH_ROUTE_GEOMETRY } from '../../config'
import { getFromCoreApi } from '../../network'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { MultiLineString } from 'geojson'
import { RichLineString, composeRichLineString } from 'shared/util-geo'

type Response = {
  points: MultiLineString
}

/**
 * Fetch only the geometry of a route.
 */
export async function fetchRouteGeometry(routeId: number): ApiResult<RichLineString> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_ROUTE_GEOMETRY, {
      params: { routeId },
    })

    try {
      const parser = new ResponseParser(res)
      return createSuccessResult(composeRichLineString(parser.requireLineString('points')))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}
