import { Fragment, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import {
  YAxis,
  XAxis,
  Tooltip,
  ReferenceArea,
  ReferenceDot,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Line,
} from 'recharts'
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import { colors } from 'shared/ui-design-system'
import { XAxisTick, YAxisTick } from './axis-tick'
import { SectionTooltip, Tooltip as CustomTooltip } from './tooltip'
import { roundToNearest } from './helpers'
import RouteDot from './route-dot'
import { useSegmentedElevationData } from './hooks'

import styles from './elevation-curve.module.scss'

const COLOR_KEYS = [
  'polyline1',
  'polyline2',
  'polyline3',
  'polyline4',
  'polyline5',
  'polyline6',
  'polyline7',
  'polyline8',
  'polyline9',
  'polyline10',
] as const

const MAX_RESOLUTION = 1000

export type ElevationData = {
  distance: number
  elevation: number
}

type ChartData = ElevationData & { [segmentElevationKey: string]: number; segmentIndex: number }

export interface ElevationCurveProps {
  height: string
  elevationData: ElevationData[][]
  selectionIndexes?: [number] | [number, number]
  ascent?: string
  descent?: string
  tooltipLabelFormatter?: (label: string | number) => string
  yAxisFormatter?: (label: string | number) => string
  xAxisFormatter?: (label: string | number) => string
  onClick?: (index: number) => void
  onHover?: (index: number) => void
  onHoverOut?: () => void
  interactive?: boolean
}

export const ElevationCurve = ({
  height,
  elevationData,
  selectionIndexes,
  onClick,
  onHover,
  onHoverOut,
  ascent,
  descent,
  tooltipLabelFormatter,
  yAxisFormatter,
  xAxisFormatter,
  interactive = true,
}: ElevationCurveProps) => {
  const [refAreaEndHovered, setRefAreaEndHovered] = useState<number | undefined>(undefined)
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number } | undefined>(undefined)
  const [fixTooltipPosition, setFixTooltipPosition] = useState<number[]>([])

  const { getElevationDataByIndex } = useSegmentedElevationData(elevationData)

  const subsamplingRate = useMemo<number>(
    () => Math.ceil(elevationData.flat().length / MAX_RESOLUTION),
    [elevationData],
  )

  const hasSelection = selectionIndexes?.length === 2

  useEffect(() => {
    if (!selectionIndexes) {
      setFixTooltipPosition([])
    }
  }, [selectionIndexes])

  const onClickChart: CategoricalChartFunc = (event) => {
    if (!event) return
    if (typeof event.activeTooltipIndex === 'number' && onClick) {
      onClick(event.activeTooltipIndex * subsamplingRate)
    }
    if (fixTooltipPosition.length < 2 && event.activeCoordinate?.x) {
      setFixTooltipPosition([...fixTooltipPosition, event.activeCoordinate.x])
    } else {
      setFixTooltipPosition([])
      setTooltipPosition(event.activeCoordinate)
    }
    setRefAreaEndHovered(undefined)
  }

  const selectionStart = useMemo<ElevationData | undefined>(
    () => selectionIndexes && getElevationDataByIndex(selectionIndexes[0]),
    [getElevationDataByIndex, selectionIndexes],
  )
  const selectionEnd = useMemo<ElevationData | undefined>(
    () => (selectionIndexes?.length === 2 ? getElevationDataByIndex(selectionIndexes[1]) : undefined),
    [getElevationDataByIndex, selectionIndexes],
  )

  const onHoverChart: CategoricalChartFunc = (event) => {
    if (hasSelection) {
      return
    }
    if (selectionIndexes) {
      setRefAreaEndHovered(Number(event.activeLabel))
    }
    setTooltipPosition(event.activeCoordinate)
    if (onHover && event.activeTooltipIndex !== undefined) {
      onHover(event.activeTooltipIndex * subsamplingRate)
    }
  }

  const onHoverOutChart = () => {
    setRefAreaEndHovered(undefined)
    setTooltipPosition(undefined)
  }

  useEffect(() => {
    if (!tooltipPosition && onHoverOut) {
      onHoverOut()
    }
  }, [tooltipPosition, onHoverOut])

  const data = useMemo<ChartData[]>(() => {
    const baseData: ChartData[] = elevationData.reduce(
      (data: ChartData[], segment, segmentIndex) => [
        ...data,
        ...segment.map((item) => ({ ...item, [`elevation-${segmentIndex}`]: item.elevation, segmentIndex })),
      ],
      [] as ChartData[],
    )
    return subsamplingRate > 1 || hasSelection
      ? baseData.reduce((data, item, i) => {
          if (i % subsamplingRate === 0) {
            data.push(
              hasSelection && i >= selectionIndexes[0] && i <= selectionIndexes[1]
                ? {
                    ...item,
                    [`elevation-selection-${item.segmentIndex}`]: item.elevation,
                  }
                : item,
            )
          }
          return data
        }, [] as ChartData[])
      : baseData
  }, [elevationData, hasSelection, selectionIndexes, subsamplingRate])

  return (
    <Box height={height} className={styles['chart-container']}>
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            top: 24,
            left: 64,
            right: 64,
            bottom: -8,
          }}
          onClick={interactive ? onClickChart : undefined}
          onMouseMove={interactive ? onHoverChart : undefined}
          onMouseLeave={interactive ? onHoverOutChart : undefined}
        >
          <Area
            yAxisId="elevation"
            type="monotone"
            dataKey="elevation"
            fill={colors.neutral.secondary}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
            opacity={1}
          />
          <CartesianGrid stroke={colors.neutral.quartery} vertical={false} />
          <XAxis
            allowDataOverflow
            dataKey="distance"
            type="number"
            tickLine={false}
            stroke={colors.neutral.quartery}
            padding={{ left: 4, right: 4 }}
            tick={<XAxisTick />}
            tickFormatter={xAxisFormatter}
            axisLine={false}
            domain={[0, 'dataMax']}
            tickCount={5}
          />
          <YAxis
            allowDataOverflow
            type="number"
            dataKey="elevation"
            yAxisId="elevation"
            tickLine={false}
            mirror
            axisLine={false}
            tick={<YAxisTick />}
            tickFormatter={yAxisFormatter}
            domain={([dataMin, dataMax]) => {
              const absMax = roundToNearest(dataMax * 1.5, 100)
              return [0, absMax]
            }}
          />
          {/* hovered section background */}
          {selectionStart && refAreaEndHovered !== undefined && refAreaEndHovered >= 0 ? (
            <ReferenceArea
              yAxisId="elevation"
              x1={selectionStart.distance}
              x2={refAreaEndHovered}
              fill={colors.actionColor.primary}
              fillOpacity={0.2}
            />
          ) : null}
          {/* selected section background */}
          {selectionStart && selectionEnd && (
            <ReferenceArea
              yAxisId="elevation"
              x1={selectionStart.distance}
              x2={selectionEnd.distance}
              fill={colors.actionColor.primary}
              fillOpacity={0.2}
            />
          )}
          {/* default tooltip */}
          {!hasSelection && interactive && (
            <Tooltip
              content={<CustomTooltip />}
              labelFormatter={tooltipLabelFormatter}
              cursor={{ stroke: colors.onNeutral.tertiary, strokeWidth: 1 }}
              position={{
                x: tooltipPosition?.x ?? 0,
                y: 28,
              }}
              isAnimationActive={false}
            />
          )}
          {/* elevation lines */}
          {elevationData.map((_, i) => {
            const alternatingColorIndex = i % 10
            const colorSet = colors['polylineRandom'][COLOR_KEYS[alternatingColorIndex]] as {
              foreground: string
              background: string
            }
            return (
              <Fragment key={i}>
                <Line
                  yAxisId="elevation"
                  type="monotone"
                  dataKey={`elevation-${i}`}
                  strokeWidth={6}
                  strokeLinecap="round"
                  stroke={elevationData.length === 1 ? colors.polylineComp.background : colorSet.background}
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
                <Line
                  yAxisId="elevation"
                  type="monotone"
                  dataKey={`elevation-${i}`}
                  strokeWidth={3}
                  strokeLinecap="round"
                  stroke={elevationData.length === 1 ? colors.polylineComp.foreground : colorSet.foreground}
                  dot={false}
                  activeDot={!hasSelection && interactive ? <RouteDot /> : false}
                  isAnimationActive={false}
                />
                {interactive && (
                  <>
                    <Line
                      yAxisId="elevation"
                      type="monotone"
                      dataKey={`elevation-selection-${i}`}
                      strokeWidth={6}
                      strokeLinecap="round"
                      stroke={colors.polylineComp.alternate.background}
                      dot={false}
                      activeDot={false}
                      isAnimationActive={false}
                    />
                    <Line
                      yAxisId="elevation"
                      type="monotone"
                      dataKey={`elevation-selection-${i}`}
                      strokeWidth={3}
                      strokeLinecap="round"
                      stroke={colors.polylineComp.alternate.foreground}
                      dot={false}
                      activeDot={false}
                      isAnimationActive={false}
                    />
                  </>
                )}
              </Fragment>
            )
          })}
          {/* selected point line and marker */}
          {selectionStart && (
            <>
              <ReferenceLine yAxisId="elevation" x={selectionStart.distance} stroke={colors.onNeutral.tertiary} />
              <ReferenceDot
                yAxisId="elevation"
                x={selectionStart.distance}
                y={selectionStart.elevation}
                shape={<RouteDot />}
              />
            </>
          )}
          {/* selected section tooltip, line and marker */}
          {selectionEnd && (
            <>
              <Tooltip
                content={<SectionTooltip ascent={ascent} descent={descent} />}
                cursor={false}
                position={{
                  x: Math.abs((fixTooltipPosition[0] + fixTooltipPosition[1]) / 2),
                  y: 28,
                }}
                wrapperStyle={{
                  visibility: 'visible',
                }}
                isAnimationActive={false}
              />
              <ReferenceLine yAxisId="elevation" x={selectionEnd.distance} stroke={colors.onNeutral.tertiary} />
              <ReferenceDot
                yAxisId="elevation"
                x={selectionEnd.distance}
                y={selectionEnd.elevation}
                shape={<RouteDot />}
              />
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}
