import { LineString, MultiLineString, MultiPoint, Point, Polygon } from 'geojson'
import {
  ROUTE_TITLE_MAX_LENGTH,
  RouteBikeType,
  RouteEntity,
  RouteEntityAnalyticsData,
  RouteEntityDetails,
  RouteEntityMapData,
  UserEntity,
} from '../../entities'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { isEmpty } from 'lodash'
import { postToCoreApi } from '../../network'
import { API_PATH_ROUTES } from '../../config'
import { RichLineString, Waypoints, getTimes, reduceToPosition } from 'shared/util-geo'
import { formatDateToISO } from 'shared/util-formatting'
import { getWaypointsAndControlPointIndexes, parseUserEntity } from './helpers'
import { enrichGeometry, mapToBikeType, mapToSurface } from '../helpers'

export type RouteAppVersion = 'Import' | 'Create' | 'Copy'

export type RouteForm = {
  geometry: RichLineString
  title: string
  appVersion: RouteAppVersion
  description?: string
  bikeTypes?: RouteBikeType[]
  distanceMeters: number
  durationSeconds?: number
  isPrivate?: boolean
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]] | null
  copiedFrom?: number
}

/** Route create endpoint seems to expect a LineString with type="MultiLineString". */
type RouteCreateRequestBodyPoints = {
  type: 'MultiLineString'
  coordinates: LineString['coordinates']
}

type RequestBody = {
  app_type: 'Web'
  app_version: RouteAppVersion
  category: number[]
  control_point_indexes?: number[]
  copied_from?: number
  created: string
  description?: string
  distance: number
  duration?: number
  is_private: boolean
  planned: true
  points: RouteCreateRequestBodyPoints
  time?: number[]
  title: string
  waypoints: MultiPoint
}

type Response = {
  altitude_difference_back: number | null
  altitude_difference: number | null
  average_speed: null // seems to be always null
  bounding_box: Polygon
  category: number[] | null
  control_point_indexes: number[] | null
  copied_from: number | null
  created: string
  description: string | null
  distance: number
  duration: number | null
  external_id?: string
  gpx: string
  ground: number[] | null
  id: number
  is_favorite: boolean
  is_loop: boolean
  is_private: boolean
  kml: string
  location: null
  points: MultiLineString
  route_favorite_count: number
  start: Point
  staticmaps: {
    bmx_big: string | null
    preview: string | null
    small: string | null
  } | null
  title: string
  user: {
    displayname: string
    id: number
    image: {
      fallback: string
      small: string | null
    } | null
    is_subscribed: boolean
    slug: string
  }
  /** 3rd dimension is index of control point */
  waypoints: MultiPoint | null
}

export type CreateRouteData = {
  route: RouteEntity & RouteEntityDetails & RouteEntityMapData & RouteEntityAnalyticsData
  creator: UserEntity | null
}

export async function createRoute(form: RouteForm): ApiResult<CreateRouteData> {
  try {
    const body: RequestBody = {
      app_type: 'Web',
      app_version: form.appVersion,
      category: form.bikeTypes && !isEmpty(form.bikeTypes) ? form.bikeTypes : [0],
      copied_from: form.copiedFrom,
      created: formatDateToISO(new Date()),
      description: form.description,
      distance: form.distanceMeters,
      duration: form.durationSeconds,
      is_private: form.isPrivate ?? true,
      planned: true,
      points: {
        type: 'MultiLineString',
        coordinates: form.geometry.coordinates.map(reduceToPosition),
      },
      time: getTimes(form.geometry),
      title: form.title.substring(0, ROUTE_TITLE_MAX_LENGTH),
      waypoints: {
        type: 'MultiPoint',
        coordinates: form.waypoints.map(({ lng, lat, controlPointIndex }) => [lng, lat, controlPointIndex]),
      },
    }

    if (form.controlPointIndexes) {
      body.control_point_indexes = form.controlPointIndexes
    }

    const res: Response = await postToCoreApi(API_PATH_ROUTES, {
      body,
      type: 'json',
    })

    try {
      const parser = new ResponseParser(res)
      const geometry = parser.requireLineString('points')
      const creator = res.user ? parseUserEntity(res.user) : null
      const distanceMeters = parser.requireNumber('distance')
      const durationSeconds = parser.takeNumber('duration')
      return createSuccessResult({
        route: {
          id: parser.requireNumber('id'),
          title: parser.requireString('title'),
          description: parser.takeString('description'),
          geometry: await enrichGeometry(geometry),
          distanceMeters,
          ascentMeters: parser.takeNumber('altitude_difference'),
          descentMeters: parser.takeNumber('altitude_difference_back'),
          durationSeconds,
          averageSpeedMetersPerSecond: durationSeconds ? distanceMeters / durationSeconds : null,
          maximumElevationMeters: null,
          isPrivate: parser.requireBoolean('is_private'),
          externalId: parser.takeString('external_id'),
          location: null,
          favoriteCount: parser.requireNumber('route_favorite_count'),
          created: parser.requireTimestamp('created'),
          surfaces: parser.takeArray('ground', mapToSurface),
          bikeTypes: parser.takeArray('category', mapToBikeType),
          images: [],
          isFavorite: parser.requireBoolean('is_favorite'),
          gpxUrl: parser.requireString('gpx'),
          kmlUrl: parser.requireString('kml'),
          hasPois: false,
          isLoop: parser.requireBoolean('is_loop'),
          bounds: parser.requireBounds('bounding_box'),
          copiedFrom: parser.takeNumber('copied_from'),
          staticMap: parser.takeImageSizesWithoutFallback('staticmaps', {
            item: 'preview',
            tile: 'bmx_big',
            tileSecondary: 'small',
            overview: 'bmx_big',
            medium: 'bmx_big',
          }),
          start: parser.requireLngLat('start'),
          creatorId: creator?.id ?? null,
          ...getWaypointsAndControlPointIndexes(geometry, res.waypoints, res.control_point_indexes),
        },
        creator,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { form })
  }
}
