import { ForwardedRef, ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

import styles from './web-app-navigation.module.scss'
import additionalContentStyles from './web-app-additional-content.module.scss'

interface WebAppNavigationProps {
  children: ReactNode
  itemsSmall?: ReactNode
  itemsLarge?: ReactNode
}

export const WebAppNavigation = forwardRef(
  ({ children, itemsSmall, itemsLarge }: WebAppNavigationProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div ref={ref} className={clsx(styles['navigation'], additionalContentStyles['hidden-if-open'])}>
      {itemsSmall && <div className={styles['items-small']}>{itemsSmall}</div>}
      {itemsLarge && <div className={styles['items-large']}>{itemsLarge}</div>}
      {children}
    </div>
  ),
)
