import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { getFromCoreApi } from '../../network'
import { API_PATH_AVAILABLE_CURRENCIES } from '../../config'

export type Currency = {
  code: string
  symbol: string
}

export async function fetchAvailableCurrencies(): ApiResult<Currency[]> {
  try {
    const res: Currency[] = await getFromCoreApi(API_PATH_AVAILABLE_CURRENCIES)
    try {
      return createSuccessResult(
        res.map((resItem) => {
          const parser = new ResponseParser(resItem)
          return {
            code: parser.requireString('code'),
            symbol: parser.requireString('symbol'),
          }
        }),
      )
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
