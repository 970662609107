import { LineString } from 'geojson'
import {
  AlongTheRouteAttribute,
  LngLat,
  RichLineString,
  composeRichLineString,
  decodePath,
  lngLatToPosition2d,
  positionToLngLat,
} from 'shared/util-geo'
import { API_PATH_ROUTING, RoutingProfile, RoutingProfileCyclingPathsLevel } from '../definitions'
import { ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'
import { postToRoutingApi } from '../network'

type Response = {
  hints?: {
    details?: string
    point_index?: number
    max_visited_nodes?: number
  }[]
  paths: {
    ascend: number
    bbox: [number, number, number, number]
    descend: number
    distance_details: {
      bm_surface: AlongTheRouteAttribute<string> | null
      bm_way_type: AlongTheRouteAttribute<string> | null
      bike_network: AlongTheRouteAttribute<string> | null
    }
    extended_details: {
      distance: number[] | null
      time: number[] | null
    }
    distance: number
    points: string
    points_encoded: boolean
    snapped_waypoints: string
    time: number
  }[]
}

export type CalculatedRoute = {
  distance: number
  time: number
  ascent: number
  descent: number
  snappedWaypoints: LngLat[]
  geometry: RichLineString
}

export type RouteCalculationErrors = MinimalEndpointErrors & {
  pointNotFoundException?: number
  connectionNotFoundException?: true
  maximumNodesExceededException?: number
  invalidGeometryResponse?: true
}

export async function fetchCalculatedRoute(
  waypoints: LngLat[],
  locale: string,
  profile: RoutingProfile = 'bike_networks',
  cyclingPath?: RoutingProfileCyclingPathsLevel,
): ApiResult<CalculatedRoute, RouteCalculationErrors> {
  try {
    const res: Response = await postToRoutingApi(API_PATH_ROUTING, {
      body: {
        points: waypoints.map(lngLatToPosition2d),
        locale,
        instructions: false,
        elevation: true,
        points_encoded: true,
        distance_details: ['bm_surface', 'bm_way_type', 'bike_network'],
        extended_details: ['distance', 'time'],
        ...getRoutingProfileConfig(profile, cyclingPath),
      },
      type: 'json',
      skipResponseValidation: true,
    })

    if (res.paths && res.paths[0].points && res.paths[0].distance_details) {
      const path = res.paths[0]

      const geometry: LineString = {
        type: 'LineString',
        coordinates: decodePath(path.points, true),
      }

      if (isValidGeometry(geometry)) {
        return createSuccessResult({
          geometry: composeRichLineString(geometry, {
            distancesMeters: path.extended_details.distance || undefined,
            timesMilliseconds: path.extended_details.time || undefined,
            surfaces: path.distance_details.bm_surface || undefined,
            wayTypes: path.distance_details.bm_way_type || undefined,
            bikeNetworks: path.distance_details.bike_network || undefined,
          }),
          distance: path.distance,
          time: path.time,
          ascent: path.ascend,
          descent: path.descend,
          snappedWaypoints: formatWaypoints(path.snapped_waypoints, waypoints.length),
        })
      }

      return createFailureResult({ invalidGeometryResponse: true })
    }

    if (res.hints && res.hints[0] && res.hints[0].details) {
      if (res.hints[0].details === 'com.graphhopper.util.exceptions.PointNotFoundException') {
        return createFailureResult({ pointNotFoundException: res.hints[0].point_index })
      }
      if (res.hints[0].details === 'com.graphhopper.util.exceptions.ConnectionNotFoundException') {
        return createFailureResult({ connectionNotFoundException: true })
      }
      if (res.hints[0].details === 'com.graphhopper.util.exceptions.MaximumNodesExceededException') {
        return createFailureResult({ maximumNodesExceededException: res.hints[0].max_visited_nodes })
      }
    }

    return createFailureResult({ unexpectedResponse: true })
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

/**
 * Formats API request params needed for custom routing profile.
 */
function getRoutingProfileConfig(profile: RoutingProfile, cyclingPath?: RoutingProfileCyclingPathsLevel) {
  if (profile === 'cycling_paths') {
    return {
      profile: 'bike_networks',
      custom_model: {
        priority: [
          {
            if: 'road_class != CYCLEWAY',
            multiply_by: getCyclingPathsModelMultiplier(cyclingPath),
          },
        ],
      },
      'ch.disable': true,
    }
  }

  return { profile }
}

function getCyclingPathsModelMultiplier(cyclingPath?: RoutingProfileCyclingPathsLevel): number {
  if (cyclingPath === 'high') {
    return 0.01
  }
  if (cyclingPath === 'low') {
    return 0.85
  }
  return 0.5
}
/**
 * Checks if received geometry has at least 2 coordinates.
 */
function isValidGeometry(geometry: LineString | null): boolean {
  return !!(geometry && Array.isArray(geometry.coordinates) && geometry.coordinates.length >= 2)
}

/**
 * Decode and extract waypoints to our lng/lat format.
 */
function formatWaypoints(waypoints: string | undefined, expectedLength: number): LngLat[] {
  if (waypoints) {
    const decoded = decodePath(waypoints, true).map(positionToLngLat)
    if (decoded.length === expectedLength) {
      return decoded
    }
  }
  return []
}

export type AlongTheRouteAttributesResponse = {
  bm_surface: AlongTheRouteAttribute<string> | null
  bm_way_type: AlongTheRouteAttribute<string> | null
  bike_network: AlongTheRouteAttribute<string> | null
}
