import { Collapse, useTheme } from '@mui/material'
import { ReactNode } from 'react'

import styles from './search-suggestions-dropdown.module.css'

interface SearchSuggestionsDropdownProps {
  children: ReactNode
  open: boolean
}

export const SearchSuggestionsDropdown = ({ children, open }: SearchSuggestionsDropdownProps) => {
  const { transitions } = useTheme()

  return (
    <div className={styles['container']}>
      <Collapse in={open} timeout={transitions.duration.enteringScreen}>
        <div className={styles['results']}>
          <div className={styles['results-separator']} />
          {children}
        </div>
      </Collapse>
    </div>
  )
}
