import bbox from '@turf/bbox'
import { ViewState, MapRef } from 'react-map-gl/maplibre'
import { LineString, MultiPoint } from 'geojson'
import { logError } from 'shared/util-error-handling'
import { DecomposedWaypoints, LngLat, MapViewport, Waypoints } from './types'
import { LngLatBoundsArray, RichLineString, lngLatToPosition2d, reduceToLineString } from 'shared/util-geo'

export function getViewportFromViewState({ longitude, latitude, padding, ...viewStateRest }: ViewState): MapViewport {
  return {
    center: [longitude, latitude],
    ...viewStateRest,
  }
}

export function getViewStateFromViewport({ center, ...viewStateRest }: MapViewport): Omit<ViewState, 'padding'> {
  return {
    longitude: center[0],
    latitude: center[1],
    ...viewStateRest,
  }
}

function isValidLng(lng: number): boolean {
  return lng >= -180 && lng < 180
}

function isValidLat(lat: number): boolean {
  return lat >= -90 && lat < 90
}

function isValidGeometry(geometry: LineString | RichLineString | MultiPoint): boolean {
  if (geometry.coordinates.length < 2) {
    return false
  }

  const firstPoint = geometry.coordinates[0]
  const lastPoint = geometry.coordinates[geometry.coordinates.length - 1]

  return isValidLng(firstPoint[0]) && isValidLat(firstPoint[1]) && isValidLng(lastPoint[0]) && isValidLat(lastPoint[1])
}

/**
 * Takes geometry (of route/polyline) and tries to return bounding box.
 * Useful for repositioning map to that box.
 */
export function getBoundsFromGeometry(geometry: LineString | RichLineString | MultiPoint): LngLatBoundsArray | null {
  try {
    if (isValidGeometry(geometry)) {
      const bboxResponse = bbox(geometry.type === 'RichLineString' ? reduceToLineString(geometry) : geometry)
      const [minLng, minLat, maxLng, maxLat] = bboxResponse
      if (isValidLng(minLng) && isValidLat(minLat) && isValidLng(maxLng) && isValidLat(maxLat)) {
        return [minLng, minLat, maxLng, maxLat]
      }

      logError('bbox did not return valid bounds, using first and last point', null, { bboxResponse })

      const firstPoint = geometry.coordinates[0]
      const lastPoint = geometry.coordinates[geometry.coordinates.length - 1]

      return [firstPoint[0], firstPoint[1], lastPoint[0], lastPoint[1]]
    }

    logError('Invalid geometry received', null, { geometry })
    return null
  } catch (e) {
    logError('Could not get bounds from geometry', e)
    return null
  }
}

export const decomposeWaypoints = (waypoints: Waypoints): DecomposedWaypoints => ({
  start: waypoints[0],
  viaPoints: waypoints.slice(1, -1) as LngLat[],
  end: waypoints.length > 1 ? waypoints[waypoints.length - 1] : null,
})

export function getLetterFromViaPointIndex(index: number): string {
  const alphabetIndex = index % 26
  return (alphabetIndex + 10).toString(36).toUpperCase()
}

export const findBeforeIdBehindSymbols = (map: MapRef, beforeId?: string): string | undefined => {
  if (!map.isStyleLoaded()) return undefined
  const layers = map.getStyle().layers
  // eslint-disable-next-line no-undef-init
  let standard: string | undefined = undefined
  for (const layer of layers) {
    if (layer.id === beforeId) {
      return layer.id
    }
    if (!standard && layer.type === 'symbol') {
      standard = layer.id
    }
  }
  return standard
}

export const findBeforeIdOnTop = (map: MapRef, beforeId?: string): string | undefined => {
  if (!map.isStyleLoaded()) return undefined
  const layers = map.getStyle().layers
  // eslint-disable-next-line no-undef-init
  for (const layer of layers) {
    if (layer.id === beforeId) {
      return layer.id
    }
  }
  return undefined
}

export function getMapViewport(map: MapRef): MapViewport {
  return {
    center: lngLatToPosition2d(map.getCenter()),
    zoom: map.getZoom(),
    bearing: map.getBearing(),
    pitch: map.getPitch(),
  }
}

export function getMapBounds(map: MapRef): LngLatBoundsArray {
  const [sw, ne] = map.getMap().getBounds().toArray()
  return [...sw, ...ne] as LngLatBoundsArray
}
