import { RefObject, useCallback, useRef, useState } from 'react'
import {
  RouteEntity,
  searchRegions,
  fetchRoutes,
  RegionEntity,
  RegionEntityMapData,
  RegionEntityParents,
} from 'shared/data-access-core'
import { searchForPlaces } from 'shared/data-access-geocoding'
import { useLocale } from 'shared/util-intl'
import { RESULTS_LIST_LENGTH } from './definitions'
import { SearchResult } from './types'
import { useWebAppMap } from 'web-app/feature-map'

export const useSearchRequest = (options: {
  queryRegions: boolean
  queryRoutes: boolean
}): {
  isLoading: boolean
  results: SearchResult[] | null
  resultsPromise: RefObject<Promise<SearchResult[] | null> | null>
  onSearch: (query: string) => void
  resetResults: () => void
} => {
  const { language } = useLocale()
  const map = useWebAppMap()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [results, setResults] = useState<SearchResult[] | null>(null)

  const resultsPromise = useRef<Promise<SearchResult[] | null> | null>(null)

  const mergeInRegionResults = useCallback(
    (results: SearchResult[], regions: (RegionEntity & RegionEntityMapData & RegionEntityParents)[], query: string) => {
      const remainingRegions = [...regions]

      if (remainingRegions[0] && remainingRegions[0].name.toLowerCase() === query.toLowerCase()) {
        results.unshift({ type: 'region', data: remainingRegions.splice(0, 1)[0] })
        results.slice(0, RESULTS_LIST_LENGTH)
      }

      results.forEach((result, i) => {
        if (result.type === 'place' && result.data.type === 'region') {
          const matchingRegionIndex = remainingRegions.findIndex((region) => region.name === result.data.address)
          if (matchingRegionIndex >= 0) {
            results[i] = { type: 'region', data: remainingRegions.splice(matchingRegionIndex, 1)[0] }
          } else {
            results.splice(i, 1)
          }
        }
      })

      if (results.length < RESULTS_LIST_LENGTH) {
        results.push(
          ...remainingRegions
            .slice(0, RESULTS_LIST_LENGTH - results.length)
            .map((data) => ({ type: 'region', data }) as SearchResult),
        )
      }
    },
    [],
  )

  const mergeInRouteResults = useCallback((results: SearchResult[], routes: RouteEntity[]) => {
    if (routes.length) {
      if (results.length < RESULTS_LIST_LENGTH) {
        results.push(
          ...routes
            .slice(0, RESULTS_LIST_LENGTH - results.length)
            .map((data) => ({ type: 'route', data }) as SearchResult),
        )
      } else {
        results[RESULTS_LIST_LENGTH - 1] = { type: 'route', data: routes[0] }
      }
    }
  }, [])

  const search = useCallback(
    async (query: string): Promise<SearchResult[] | null> => {
      if (!map) return null

      const center = map.getCenter()
      const [placesResult, regionsResult, routesResult] = await Promise.all([
        searchForPlaces(query, language, center, RESULTS_LIST_LENGTH),
        options.queryRegions && searchRegions(query, center, language),
        options.queryRoutes &&
          fetchRoutes(
            { lang: language, sorting: 'relevance-desc', title: query, center },
            { pageSize: RESULTS_LIST_LENGTH },
          ),
      ])

      const results: SearchResult[] = placesResult.success
        ? placesResult.data.map((data) => ({ type: 'place', data }))
        : []
      if (regionsResult && regionsResult.success) {
        mergeInRegionResults(results, regionsResult.data, query)
      }
      if (routesResult && routesResult.success) {
        mergeInRouteResults(
          results,
          routesResult.data.results.map((id) => routesResult.data.routes[id]),
        )
      }
      return results
    },
    [language, map, mergeInRegionResults, mergeInRouteResults, options.queryRegions, options.queryRoutes],
  )

  const onSearch = useCallback(
    async (query: string) => {
      if (query.length >= 3) {
        setIsLoading(true)
        const promise = search(query)
        resultsPromise.current = promise
        const result = await promise
        if (resultsPromise.current === promise) {
          setResults(result)
          resultsPromise.current = null
          setIsLoading(false)
        }
      } else {
        setResults(null)
      }
    },
    [search],
  )

  const resetResults = useCallback(() => setResults(null), [])

  return { isLoading, results, resultsPromise, onSearch, resetResults }
}
