import { MapStyleControl } from 'web-app/feature-map'
import { WebAppControls, useBreakpoints } from 'web-app/ui-layout'

export const RoutePrintControls = () => {
  const { layoutBreakpoint } = useBreakpoints()

  return (
    <WebAppControls>
      <MapStyleControl small={!layoutBreakpoint} />
    </WebAppControls>
  )
}
