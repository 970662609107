import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { reset, useBaseRoute } from '../state'
import { useCancel, useNavigateToRouteDetails } from 'web-app/feature-navigation'

export const useRoutePlannerCancel = () => {
  const dispatch = useDispatch()
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const cancel = useCancel()
  const baseRoute = useBaseRoute()

  return useCallback(() => {
    dispatch(reset())
    if (baseRoute) {
      navigateToRouteDetails(baseRoute.id)
    } else {
      cancel()
    }
  }, [baseRoute, cancel, dispatch, navigateToRouteDetails])
}
