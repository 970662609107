import { useRef, createContext, RefObject, ReactNode, useContext, useMemo, useCallback } from 'react'
import { useBreakpoints } from './use-breakpoints'
import { BASE_SIZE_PX, HEADER_HEIGHT_REM, SHEET_DEFAULT_FOLD_REM, SHEET_MINIMAL_FOLD_REM } from './definitions'

export type InitialFold = 'minimal' | 'default' | 'full'

export type LayoutProviderValues = {
  mainActionsRef: RefObject<HTMLDivElement>
  scrollContainerRef: RefObject<HTMLDivElement>
  snapScrollContainerRef: RefObject<HTMLDivElement>
  scrollToInitialFold: () => void
  expandBottomSheet: () => void
}

export const LayoutContext = createContext<LayoutProviderValues | undefined>(undefined)

interface LayoutProviderProps {
  children: ReactNode
  initialFold: InitialFold
}

export const LayoutProvider = ({ children, initialFold }: LayoutProviderProps) => {
  const { layoutBreakpoint } = useBreakpoints()

  const mainActionsRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const snapScrollContainerRef = useRef<HTMLDivElement>(null)

  const fullFoldPx = useMemo<number>(
    () => window.innerHeight - HEADER_HEIGHT_REM * BASE_SIZE_PX - SHEET_MINIMAL_FOLD_REM * BASE_SIZE_PX,
    [],
  )

  const initialFoldPx = useMemo<number>(() => {
    if (layoutBreakpoint) return 0
    if (initialFold === 'default') {
      return SHEET_DEFAULT_FOLD_REM * BASE_SIZE_PX
    }
    if (initialFold === 'full') {
      return fullFoldPx
    }
    return 0
  }, [fullFoldPx, initialFold, layoutBreakpoint])

  const scrollToInitialFold = useCallback(() => {
    snapScrollContainerRef.current?.scroll({ top: initialFoldPx, behavior: 'smooth' })
  }, [initialFoldPx])

  const expandBottomSheet = useCallback(() => {
    snapScrollContainerRef.current?.scroll({ top: fullFoldPx, behavior: 'smooth' })
  }, [fullFoldPx])

  return (
    <LayoutContext.Provider
      value={{
        mainActionsRef,
        scrollContainerRef,
        snapScrollContainerRef,
        scrollToInitialFold,
        expandBottomSheet,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export function useLayoutContext(): LayoutProviderValues {
  const contextValues = useContext<LayoutProviderValues | undefined>(LayoutContext)
  if (!contextValues) {
    throw new Error('Layout components must be inside WebAppLayout')
  }
  return contextValues
}
