import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { WebAppContent, WebAppScrollTooltip } from 'web-app/ui-layout'
import {
  RouteCollectionRoutesList,
  RouteCollectionRoutesManager,
  RouteCollectionSliceDispatch,
  fetchGlobalRouteCollection,
  fetchGlobalRouteCollectionRoutes,
  useCollectionRoutesParams,
  useIsOwnRouteCollection,
  useRouteCollectionState,
} from 'web-app/feature-route-collection'
import { Alert, Button, Link, useMessages } from 'shared/ui-components'
import { CollectionRoutesSorting, RouteCollectionCategory, changeRouteCollectionRoutes } from 'shared/data-access-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ContentFormButtons,
  EditContentButton,
  useEditableContentContext,
  useIsEditingFlags,
} from 'web-app/feature-editable-content'
import { useDispatch } from 'react-redux'
import { DiscoverFilters, useDiscoverReset } from 'web-app/feature-discover'
import { getHelpCenterToursUrl } from 'shared/util-navigation'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { TourIcon } from 'shared/ui-design-system/icons/tour-icon'
import { useAreParamsDefault } from './use-are-params-default'
import { isEqual } from 'lodash'

import styles from './collection-routes-section.module.css'

export const CollectionRoutesSection = () => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { intl, language } = useLocale()
  const { learnMoreLabel } = useMessages()
  const { routeCollection, filteredRoutesCount, assignedRouteIds, isLoadingMoreRoutes, routeIdsInTourOrder } =
    useRouteCollectionState()
  const isOwnCollection = useIsOwnRouteCollection()
  const params = useCollectionRoutesParams()
  const handleResetFilters = useDiscoverReset()
  const showCommonErrorNotification = useCommonErrorNotification()
  const areParamsDefault = useAreParamsDefault()

  const name = 'routes'
  const { onEditingDone } = useEditableContentContext()
  const { isEditing, isEditingAnotherField } = useIsEditingFlags(name)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [updatedList, setUpdatedList] = useState<number[] | undefined>(assignedRouteIds)

  useEffect(() => {
    if (assignedRouteIds) {
      setUpdatedList(assignedRouteIds)
    }
  }, [assignedRouteIds])

  const scrollHintRef = useRef(null)

  const heading = routeCollection?.routesCount
    ? filteredRoutesCount && routeCollection.routesCount > filteredRoutesCount
      ? intl.formatMessage(
          {
            id: 'collection_routes_heading_filtered',
            defaultMessage: '{filteredRoutesCount} of {routesCount} routes',
          },
          {
            filteredRoutesCount,
            routesCount: routeCollection.routesCount,
          },
        )
      : intl.formatMessage(
          {
            id: 'collection_routes_heading',
            defaultMessage:
              '{routesCount, plural, one {{routesCount, number} route} other {{routesCount, number} routes}}',
          },
          {
            routesCount: routeCollection.routesCount,
          },
        )
    : intl.formatMessage({
        id: 'collection_routes_heading_empty',
        defaultMessage: 'Routes',
      })

  const handleCancel = useCallback(() => {
    if (!routeCollection) return
    dispatch(fetchGlobalRouteCollectionRoutes({ routeCollectionId: routeCollection.id, params }))
    onEditingDone()
  }, [dispatch, onEditingDone, params, routeCollection])

  const handleSubmit = useCallback(async () => {
    if (!routeCollection || !updatedList || !assignedRouteIds?.length) return
    setIsSaving(true)
    const res = await changeRouteCollectionRoutes(routeCollection.id, updatedList, assignedRouteIds.length)
    if (res.success) {
      onEditingDone()
    } else {
      showCommonErrorNotification(
        intl.formatMessage({
          id: 'route_collection_routes_save_changes_error',
          defaultMessage: 'Your changes to the routes list could not be saved.',
        }),
      )
    }
    dispatch(fetchGlobalRouteCollection(routeCollection.id))
    await dispatch(fetchGlobalRouteCollectionRoutes({ routeCollectionId: routeCollection.id, params })).unwrap()
    setIsSaving(false)
  }, [
    assignedRouteIds,
    dispatch,
    intl,
    onEditingDone,
    params,
    routeCollection,
    showCommonErrorNotification,
    updatedList,
  ])

  useEffect(() => {
    if (isEditing && !areParamsDefault) {
      handleCancel()
    }
  }, [isEditing, handleCancel, areParamsDefault])

  const customSortingOption = useMemo(
    () => ({
      value: 'custom-asc' as CollectionRoutesSorting,
      label: intl.formatMessage({
        id: 'route_collection_custom_sorting',
        defaultMessage: 'Custom',
      }),
    }),
    [intl],
  )

  return (
    <>
      <WebAppContent stickyOnTop zIndex={3}>
        {!isOwnCollection && routeCollection?.hasPrivateRoutes && (
          <Alert style={{ marginBottom: '1rem' }}>
            {intl.formatMessage({
              id: 'route_collection_private_routes_alert',
              defaultMessage:
                'Some routes in this collection are private and will remain hidden until the owner makes them public.',
            })}
          </Alert>
        )}
        <div className={styles['heading']}>
          <Typography variant="h3" component="h2" marginBottom={0}>
            {heading}
          </Typography>
          {!isSaving &&
            (isOwnCollection && isEditing ? (
              <ContentFormButtons
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isSubmitDisabled={!assignedRouteIds || isLoadingMoreRoutes}
              />
            ) : (
              <div className={styles['actions']}>
                {isOwnCollection && !isEditingAnotherField && areParamsDefault && <EditContentButton name={name} />}
                <DiscoverFilters<CollectionRoutesSorting>
                  size="small"
                  count={filteredRoutesCount ?? null}
                  isSearching={!assignedRouteIds}
                  extraSortingOptions={[customSortingOption]}
                  defaultSorting="custom-asc"
                >
                  {intl.formatMessage({
                    id: 'route_collection_routes_filter',
                    defaultMessage: 'Filter & sort',
                  })}
                </DiscoverFilters>
                {!areParamsDefault && (
                  <Button size="small" variant="secondary" onClick={handleResetFilters}>
                    {intl.formatMessage({
                      id: 'route_collection_reset_filters_button',
                      defaultMessage: 'Clear',
                    })}
                  </Button>
                )}
              </div>
            ))}
        </div>
      </WebAppContent>
      <WebAppContent ref={scrollHintRef}>
        {isEditing ? (
          <>
            {routeCollection?.category === RouteCollectionCategory.Tour && (
              <>
                <Alert style={{ marginBottom: '1rem' }}>
                  {intl.formatMessage({
                    id: 'route_collection_routes_tour_alert',
                    defaultMessage:
                      'Removing routes from this tour collection may convert it to a standard collection.',
                  })}{' '}
                  <Link href={getHelpCenterToursUrl(language)} target="_blank">
                    {learnMoreLabel}
                  </Link>
                </Alert>
                {routeIdsInTourOrder &&
                  routeIdsInTourOrder.length === updatedList?.length &&
                  !isEqual(updatedList, routeIdsInTourOrder) && (
                    <Button
                      variant="secondary"
                      size="medium"
                      style={{ marginBottom: '1rem' }}
                      icon={<TourIcon />}
                      onClick={() => setUpdatedList([...routeIdsInTourOrder])}
                    >
                      {intl.formatMessage({
                        id: 'route_collection_routes_tour_automatic_sorting',
                        defaultMessage: 'Match order on map',
                      })}
                    </Button>
                  )}
              </>
            )}
            <RouteCollectionRoutesManager
              updatedList={isSaving ? undefined : updatedList}
              onListUpdate={setUpdatedList}
            />
          </>
        ) : (
          <RouteCollectionRoutesList params={params} />
        )}
      </WebAppContent>
      {!!filteredRoutesCount && <WebAppScrollTooltip scrollToRef={scrollHintRef}>{heading}</WebAppScrollTooltip>}
    </>
  )
}
