import { MapLocationSelection, SelectedMapLocation, useMoveMapAway } from 'web-app/feature-map'
import { LngLat } from 'shared/util-geo'
import { useState } from 'react'
import { DiscoverMapRoutes, RouteSelection, useDiscover, useDiscoverMapParams } from 'web-app/feature-discover'
import { useRouteById } from 'web-app/feature-route'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'
import { GeocoderLocation } from 'shared/data-access-geocoding'

interface HomeMapFeaturesProps {
  selectedLocation: GeocoderLocation | null
  selectedMapLocation: SelectedMapLocation | null
  routeDetailsPreviousView?: RouteDetailsPreviousView
  onMapLocationSelect: (location: SelectedMapLocation) => void
  onMapLocationGeocoded: (location: SelectedMapLocation) => void
  onMapLocationSelectionCancel: () => void
  onLocationOutOfView: () => void
}

export const HomeMapFeatures = ({
  selectedLocation,
  selectedMapLocation,
  routeDetailsPreviousView,
  onMapLocationSelect,
  onMapLocationGeocoded,
  onMapLocationSelectionCancel,
  onLocationOutOfView,
}: HomeMapFeaturesProps) => {
  const [selectedRoute, setSelectedRoute] = useState<RouteSelection | null>(null)

  useDiscover()
  useDiscoverMapParams()

  useRouteById(selectedRoute?.id)

  /** Clear search selection when map is moved away from the location completely */
  useMoveMapAway(selectedLocation?.position || null, onLocationOutOfView)

  const handleRouteSelect = (id: number, start: LngLat) => {
    onMapLocationSelectionCancel()
    setSelectedRoute({ id, ...start })
  }

  return (
    <>
      <DiscoverMapRoutes
        selectedRoute={selectedRoute}
        interactive={!selectedMapLocation}
        routeDetailsPreviousView={routeDetailsPreviousView}
        onRouteSelect={handleRouteSelect}
        onSelectionCancel={() => setSelectedRoute(null)}
      />
      <MapLocationSelection
        selectedLocation={selectedMapLocation}
        interactive={!selectedRoute}
        onSelect={onMapLocationSelect}
        onGeocoded={onMapLocationGeocoded}
        onCancel={onMapLocationSelectionCancel}
      />
    </>
  )
}
