import { Typography } from '@mui/material'
import { RouteTileSkeleton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'
import { WebAppRouteEntityTile } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { PaginatedList } from 'web-app/ui-layout'
import { DiscoverLink } from 'web-app/feature-navigation'
import {
  CollectionRoutesParams,
  RouteCollectionSliceDispatch,
  fetchMoreGlobalRouteCollectionRoutes,
  useAssignedRoutes,
  useIsOwnRouteCollection,
  useRouteCollectionState,
} from '../state'
import NoRoutesIllustration from './no-routes.svg'
import { useCallback } from 'react'

interface RouteCollectionRoutesListProps {
  params: CollectionRoutesParams
}

export const RouteCollectionRoutesList = ({ params }: RouteCollectionRoutesListProps) => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { intl } = useLocale()
  const { unitPreference } = useUserState()
  const assignedRoutes = useAssignedRoutes()
  const { filteredRoutesCount, routeCollection } = useRouteCollectionState()
  const isOwnCollection = useIsOwnRouteCollection()

  const loadMore = useCallback(async () => {
    await dispatch(fetchMoreGlobalRouteCollectionRoutes(params)).unwrap()
  }, [dispatch, params])

  return (
    <PaginatedList
      loading={!assignedRoutes}
      results={assignedRoutes}
      count={filteredRoutesCount}
      skeleton={<RouteTileSkeleton />}
      renderItem={({ route, creator }) => (
        <WebAppRouteEntityTile
          route={route}
          creator={creator ?? null}
          imperial={unitPreference === 'imperial'}
          routeCollectionId={routeCollection?.id}
        />
      )}
      onLoadMore={loadMore}
    >
      <div style={{ maxWidth: '8rem', marginInline: 'auto' }}>
        <NoRoutesIllustration />
      </div>
      <Typography variant="h4" component="h3" textAlign="center">
        {intl.formatMessage({
          id: 'route_collection_routes_list_no_results',
          defaultMessage: 'No routes added yet',
        })}
      </Typography>
      {isOwnCollection && (
        <>
          <Typography paragraph textAlign="center">
            {intl.formatMessage(
              {
                id: 'route_collection_routes_list_instructions',
                defaultMessage:
                  'You can add any route to this collection. Just open a route, click "Add to collection" and select "{collectionTitle}" in the list.',
              },
              {
                collectionTitle: routeCollection?.title,
              },
            )}
          </Typography>
          <DiscoverLink />
        </>
      )}
    </PaginatedList>
  )
}
