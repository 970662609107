import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  RegionEntity,
  RegionEntityDetails,
  RegionEntityMapData,
  RegionEntityParents,
  fetchRegion,
} from 'shared/data-access-core'
import { useLocale, useTitle } from 'shared/util-intl'
import {
  WebAppMap,
  WebAppMapControls,
  WebAppMapFooter,
  WebAppMapLayout,
  boundsDesired,
  useMapState,
  viewportDesired,
} from 'web-app/feature-map'
import { DefaultHeader, RouteDetailsPreviousView } from 'web-app/feature-navigation'
import { WebAppMapHint, WebAppSheet, WebAppTopControl } from 'web-app/ui-layout'
import { ErrorBoundary, ErrorBoundaryFallback, ViewNotFound } from 'web-app/utils-error-handling'
import { RegionContentSection } from './region-content-section'
import { SearchSection } from './search-section'
import { DiscoverRoutesSection } from './discover-routes-section'
import { RegionMapFeatures } from './region-map-features'
import { useDispatch } from 'react-redux'
import { lngLatToPosition2d } from 'shared/util-geo'
import { REGION_DEFAULT_ZOOM_LEVELS } from 'web-app/feature-search'
import { useLocation } from 'react-router-dom'
import { SearchHereButton } from 'web-app/feature-discover'

export interface RegionProps {
  geonameId: number
  initialState?: {
    regionPreview?: RegionEntity & RegionEntityMapData & RegionEntityParents
  }
}

export const Region = ({ geonameId, initialState = {} }: RegionProps) => {
  const location = useLocation()
  const { intl, language } = useLocale()
  const dispatch = useDispatch()
  const { desiredBounds, desiredViewport } = useMapState()

  const [regionPreview, setRegionPreview] = useState<(RegionEntity & RegionEntityMapData & RegionEntityParents) | null>(
    initialState.regionPreview || null,
  )
  const [isRegionLoading, setIsRegionLoading] = useState<boolean>(true)
  const [region, setRegion] = useState<
    (RegionEntity & RegionEntityMapData & RegionEntityParents & RegionEntityDetails) | null
  >(null)

  const routeDetailsPreviousView = useMemo<RouteDetailsPreviousView | undefined>(() => {
    const regionName = region?.name || regionPreview?.name
    return regionName
      ? {
          path: location.pathname + location.search,
          label: regionName,
        }
      : undefined
  }, [location.pathname, location.search, region?.name, regionPreview?.name])

  useTitle(
    region || regionPreview
      ? intl.formatMessage(
          {
            id: 'region_document_title',
            defaultMessage: '{regionName} - Bikemap',
          },
          {
            regionName: region?.name || regionPreview?.name,
          },
        )
      : intl.formatMessage({
          id: 'region_document_title_loading',
          defaultMessage: 'Discover cycling routes - Bikemap',
        }),
  )

  const geonameIdRef = useRef<number | null>(geonameId)

  /** Init state from props */
  useEffect(() => {
    if (initialState.regionPreview) {
      setRegionPreview(initialState.regionPreview)
    }
  }, [initialState.regionPreview])

  /** Fetch region details whenever ID or language changes */
  useEffect(() => {
    setIsRegionLoading(true)
    setRegion(null)
    geonameIdRef.current = geonameId
    fetchRegion(geonameId, language).then((res) => {
      if (res.success && geonameIdRef.current === geonameId) {
        setRegion(res.data)
      }
      setIsRegionLoading(false)
    })
  }, [geonameId, language])

  const initMapViewport = useCallback(
    (region: RegionEntity & RegionEntityMapData) => {
      dispatch(
        viewportDesired({
          center: lngLatToPosition2d(region.center),
          zoom: REGION_DEFAULT_ZOOM_LEVELS[region.kind],
        }),
      )
      if (region.bounds) {
        dispatch(boundsDesired(region.bounds))
      }
    },
    [dispatch],
  )

  /** Adjust map viewport to the region when it changes (or to a region preview that can be avialable immediately) */
  useEffect(() => {
    if (desiredBounds || desiredViewport) return
    if (region && region.id === geonameId) {
      initMapViewport(region)
    } else if (regionPreview && regionPreview.id === geonameId) {
      initMapViewport(regionPreview)
    }
    // do not run when desiredBounds or desiredViewport change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, geonameId, initMapViewport, region, regionPreview])

  return !isRegionLoading && !region ? (
    <ViewNotFound />
  ) : (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <WebAppMapLayout>
        <DefaultHeader />
        <WebAppSheet>
          <SearchSection regionName={region?.name || regionPreview?.name || ''} />
          <RegionContentSection region={region} />
          <DiscoverRoutesSection
            geonameId={geonameId}
            regionName={region?.name}
            regionKind={region?.kind}
            routeDetailsPreviousView={routeDetailsPreviousView}
          />
        </WebAppSheet>
        <WebAppMap>
          <RegionMapFeatures
            region={region || regionPreview || null}
            routeDetailsPreviousView={routeDetailsPreviousView}
            geonameId={geonameId}
          />
        </WebAppMap>
        <WebAppMapControls />
        <WebAppMapFooter />
        <WebAppMapHint />
        <WebAppTopControl>
          <SearchHereButton />
        </WebAppTopControl>
      </WebAppMapLayout>
    </ErrorBoundary>
  )
}
