import { logError } from 'web-app/utils-error-handling'
import {
  controlPointsSelector,
  routePlannerSliceSelector,
  selectedWaypointSelector,
  waypointsSelector,
} from '../selectors'
import { controlPointRemoved, firstSegmentRemoved, lastSegmentRemoved, waypointRemoved } from '../state'
import { RoutePlannerSliceDispatch, StateWithRoutePlannerSlice } from '../types'
import { reverseGeocode } from './geocoding'
import { calculateSegments } from './route-calculation'
import { fitMapToGeometryBounds } from './thunks'

export function removeWaypoint(waypointIndex: number) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const state = getState()

    if (routePlannerSliceSelector(state).isCalculatingRoute) {
      logError('Trying to remove waypoint while route is still being calculated')
      return
    }

    const { isFullRoute, waypoints } = waypointsSelector(state)
    const controlPoints = controlPointsSelector(state)
    const waypoint = waypoints[waypointIndex]

    if (!waypoint) return

    dispatch(waypointRemoved(waypointIndex))

    if (
      isFullRoute &&
      waypointIndex > 0 &&
      waypointIndex < waypoints.length - 1 &&
      controlPoints &&
      waypoint.controlPointIndex
    ) {
      // was via waypoint
      const previousControlPointIndex = waypoint.controlPointIndex - 1
      const previousControlPoint = controlPoints[previousControlPointIndex]
      const nextControlPoint = controlPoints[waypoint.controlPointIndex + 1]
      await dispatch(calculateSegments([previousControlPoint, nextControlPoint], previousControlPointIndex, 2))
      dispatch(fitMapToGeometryBounds())
    }
  }
}

export function removeSelectedWaypoint() {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const selectedWaypointState = selectedWaypointSelector(getState())
    if (selectedWaypointState) {
      const controlPointIndex = selectedWaypointState.waypoint.controlPointIndex
      if (controlPointIndex !== undefined) {
        await dispatch(removeControlPoint(controlPointIndex))
      } else {
        dispatch(waypointRemoved(selectedWaypointState.index))
      }
    }
  }
}

export function removeControlPoint(controlPointIndex: number) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const state = getState()

    if (routePlannerSliceSelector(state).isCalculatingRoute) {
      logError('Trying to remove control point while route is still being calculated')
      return
    }

    const controlPoints = controlPointsSelector(state)
    if (!controlPoints) return
    if (controlPointIndex < 1) {
      dispatch(firstSegmentRemoved())
      const newOrigin = waypointsSelector(getState()).start
      if (newOrigin && !newOrigin.address) {
        dispatch(reverseGeocode(newOrigin))
      }
    } else if (controlPointIndex > controlPoints.length - 2) {
      dispatch(lastSegmentRemoved())
      const newDestination = waypointsSelector(getState()).end
      if (newDestination && !newDestination.address) {
        dispatch(reverseGeocode(newDestination))
      }
    } else {
      dispatch(controlPointRemoved({ controlPointIndex }))
      const requestWaypoints = [controlPoints[controlPointIndex - 1], controlPoints[controlPointIndex + 1]]
      await dispatch(calculateSegments(requestWaypoints, controlPointIndex - 1, 2))
    }
  }
}
