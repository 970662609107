import { GeocoderLocation } from 'shared/data-access-geocoding'
import { LocationList } from 'shared/ui-components'
import { formatLocationDisplayValues } from 'shared/util-formatting'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { SearchResult } from './types'

interface PlaceResultItemProps {
  place: GeocoderLocation
  onSelect: (result: SearchResult) => void
}

export const PlaceResultItem = ({ place, onSelect }: PlaceResultItemProps) => {
  const { displayValueTitle, displayValueSubtitle } = formatLocationDisplayValues(place)

  return (
    <LocationList.Item
      title={displayValueTitle}
      subtitle={displayValueSubtitle || ''}
      icon={place.type === 'poi' ? <StarRoundedIcon /> : <FmdGoodIcon />}
      onClick={() => onSelect({ type: 'place', data: place })}
    />
  )
}
