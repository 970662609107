import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { logError } from 'shared/util-error-handling'
import { ElevationData, SelectionIndexes, UnitPreference } from '../types'
import { calculateElevationCurve } from './elevation-curve-calculation'
import { isEqual } from 'lodash'
import { RichLineString, RichMultiLineString } from 'shared/util-geo'

type ElevationCurveContextValues = {
  elevation?: ElevationData[][] | null
  unitPreference: UnitPreference
  elevationPointIndex?: number
  selectionIndexes?: SelectionIndexes
  onElevationPointIndexChange: (value?: number) => void
  onSelectionIndexesChange: (value?: SelectionIndexes) => void
}

const ElevationCurveContext = createContext<ElevationCurveContextValues>({
  elevation: undefined,
  unitPreference: 'metric',
  elevationPointIndex: undefined,
  selectionIndexes: undefined,
  onElevationPointIndexChange: () => logError('Elevation curve context handlers not yet available'),
  onSelectionIndexesChange: () => logError('Elevation curve context handlers not yet available'),
})

interface ElevationCurveProviderProps {
  /** LineString for single segment (eg. one route), MultiLineString for multiple segments */
  geometry?: RichLineString | RichMultiLineString
  unitPreference: UnitPreference
  children: React.ReactNode
}

/**
 * Provides context for the elevation curve and the corresponding map.
 */
export const ElevationCurveProvider = ({ geometry, unitPreference, children }: ElevationCurveProviderProps) => {
  const [elevationPointIndex, setElevationPointIndex] = useState<number | undefined>()
  const [selectionIndexes, setSelectionIndexes] = useState<SelectionIndexes | undefined>()

  const [versionedGeometry, setVersionedGeometry] = useState<RichLineString | RichMultiLineString | undefined>(geometry)

  useEffect(() => {
    if (!isEqual(geometry, versionedGeometry)) {
      setVersionedGeometry(geometry)
    }
  }, [geometry, versionedGeometry])

  const elevation = useMemo<undefined | ElevationData[][] | null>(() => {
    if (!versionedGeometry) return undefined

    try {
      if (versionedGeometry.type === 'RichLineString') {
        return [calculateElevationCurve(versionedGeometry.coordinates, unitPreference)]
      }

      if (versionedGeometry.type === 'RichMultiLineString') {
        let distanceOffset = 0
        return versionedGeometry.coordinates.map((segment, index) => {
          const segmentElevation = calculateElevationCurve(segment, unitPreference, {
            index,
            distanceOffset,
          })
          distanceOffset = segmentElevation[segmentElevation.length - 1].distance
          return segmentElevation
        })
      }

      logError('Elevation Curve Error', 'Geometry and distance types do not match')
    } catch (error) {
      logError('Elevation Curve Error', error)
    }
    return null
  }, [versionedGeometry, unitPreference])

  return (
    <ElevationCurveContext.Provider
      value={{
        elevation,
        unitPreference,
        elevationPointIndex,
        selectionIndexes,
        onElevationPointIndexChange: setElevationPointIndex,
        onSelectionIndexesChange: setSelectionIndexes,
      }}
    >
      {children}
    </ElevationCurveContext.Provider>
  )
}

/**
 * Get context for the elevation curve.
 */
export const useElevationCurveContext = (): ElevationCurveContextValues => {
  const context = useContext(ElevationCurveContext)
  if (!context) {
    logError('useElevationCurveContext must be used inside ElevationCurveProvider')
  }
  return context
}
