import { Typography } from '@mui/material'
import { IntlShape } from 'shared/util-intl'
import { AppStoreBadge, GooglePlayBadge } from './app-badges'
import QRCode from 'react-qr-code'
import { STORE_URL_IOS, getPlayStoreUrl } from 'shared/util-navigation'

import styles from './get-app-interstitial.module.scss'

import imgAvif from './img/get-app-img.avif'
import imgWebp from './img/get-app-img.webp'
import imgJpg from './img/get-app-img.jpg'
import imgLargeAvif from './img/get-app-img-large.avif'
import imgLargeWebp from './img/get-app-img-large.webp'
import imgLargeJpg from './img/get-app-img-large.jpg'

type NextImage = { src: string }

interface GetAppInterstitialProps {
  intl: IntlShape
  deeplink?: string
}

export function GetAppInterstitial({ intl, deeplink }: GetAppInterstitialProps) {
  const imageAlt = intl.formatMessage({
    id: 'get_app_interstitial_image_alt',
    defaultMessage: 'Bikemap - GPS, navigation and offline maps for cyclists',
  })

  return (
    <div className={styles['container']}>
      <picture>
        <source srcSet={typeof imgAvif === 'string' ? imgAvif : (imgAvif as NextImage).src} type="image/avif" />
        <source srcSet={typeof imgWebp === 'string' ? imgWebp : (imgWebp as NextImage).src} type="image/webp" />
        <img
          className={styles['image']}
          src={typeof imgJpg === 'string' ? imgJpg : (imgJpg as NextImage).src}
          alt={imageAlt}
          width="425"
          height="208"
        />
      </picture>
      <div className={styles['content']}>
        <Typography variant="h2" component="h1" marginBottom="2rem">
          {intl.formatMessage({
            id: 'get_app_interstitial_heading',
            defaultMessage: 'Continue with our app for iOS or Android',
          })}
        </Typography>
        <ul className={styles['link-list']}>
          <li>
            <QrCode url={STORE_URL_IOS} />
            <AppStoreBadge className={styles['badge']} intl={intl} analyticsId="get_app_interstitial_badge_ios" />
          </li>
          <li>
            <QrCode url={getPlayStoreUrl(deeplink)} />
            <GooglePlayBadge
              className={styles['badge']}
              intl={intl}
              analyticsId="get_app_interstitial_badge_android"
              deferredDeeplink={deeplink}
            />
          </li>
        </ul>
      </div>
      <picture>
        <source
          srcSet={typeof imgLargeAvif === 'string' ? imgLargeAvif : (imgLargeAvif as NextImage).src}
          type="image/avif"
        />
        <source
          srcSet={typeof imgLargeWebp === 'string' ? imgLargeWebp : (imgLargeWebp as NextImage).src}
          type="image/webp"
        />
        <img
          className={styles['image-large']}
          src={typeof imgLargeJpg === 'string' ? imgLargeJpg : (imgLargeJpg as NextImage).src}
          alt={imageAlt}
          width="600"
          height="604"
        />
      </picture>
    </div>
  )
}

const QrCode = ({ url }: { url: string }) => (
  <div className={styles['qr-code']}>
    <QRCode
      value={url}
      style={{ width: '100%', height: 'auto', display: 'block' }}
      bgColor="var(--neutral-secondary)"
      fgColor="currentColor"
    />
  </div>
)
