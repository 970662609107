import { FloatingButton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import MapRoundedIcon from '@mui/icons-material/MapRounded'
import { useBreakpoints } from './use-breakpoints'
import { useLayoutContext } from './layout-provider'
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import clsx from 'clsx'

import styles from './web-app-map-hint.module.css'

export const WebAppMapHint = () => {
  const { intl } = useLocale()
  const { layoutBreakpoint } = useBreakpoints()
  const { scrollContainerRef, snapScrollContainerRef } = useLayoutContext()

  const [shouldScrollUp, setShouldScrollUp] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    if (shouldScrollUp) {
      const scrollContainer = scrollContainerRef.current
      const snapScrollContainer = snapScrollContainerRef.current
      const scrollUp = () => {
        if (!scrollContainer || !snapScrollContainer) return
        scrollContainer.scrollTop = 0
        snapScrollContainer.scroll({ top: 0, behavior: 'smooth' })
        setShouldScrollUp(false)
      }
      const timeout = setTimeout(scrollUp, 100)
      const debouncedScrollHandler = debounce(scrollUp, 100)
      const interruptOnScroll = () => {
        clearTimeout(timeout)
        scrollContainer?.addEventListener('scroll', debouncedScrollHandler)
      }
      scrollContainer?.addEventListener('scroll', interruptOnScroll, { once: true })
      return () => {
        clearTimeout(timeout)
        debouncedScrollHandler.cancel()
        scrollContainer?.removeEventListener('scroll', interruptOnScroll)
        scrollContainer?.removeEventListener('scroll', debouncedScrollHandler)
      }
    }
    return () => {}
  }, [scrollContainerRef, shouldScrollUp, snapScrollContainerRef])

  useEffect(() => {
    const snapScrollContainer = snapScrollContainerRef.current
    if (!snapScrollContainer) return
    const handler = debounce(
      () => {
        const scrollTop = snapScrollContainer.scrollTop
        const maxScroll = snapScrollContainer.scrollHeight - snapScrollContainer.clientHeight
        setIsVisible(scrollTop > maxScroll - 1)
      },
      100,
      { leading: true },
    )
    snapScrollContainer.addEventListener('scroll', handler)
    return () => snapScrollContainer.removeEventListener('scroll', handler)
  }, [snapScrollContainerRef])

  return (
    !layoutBreakpoint && (
      <FloatingButton
        className={clsx(styles['button'], { [styles['visible']]: isVisible })}
        variant="primary"
        icon={<MapRoundedIcon />}
        onClick={() => setShouldScrollUp(true)}
      >
        {intl.formatMessage({
          id: 'web_app_map_hint',
          defaultMessage: 'Map',
        })}
      </FloatingButton>
    )
  )
}
