import { MapRoutePreview } from 'shared/ui-map'
import { routeSelectionCleared, useRouteCollection, useSelectedRoutePreview } from '../state'
import { WebAppMapPopup } from 'web-app/feature-map'
import { WebAppRouteEntityItem } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { UserSliceDispatch, toggleFavoriteRoute, useUserState } from 'web-app/feature-user'

export const SelectedMapRoute = () => {
  const dispatch = useDispatch() as UserSliceDispatch
  const { unitPreference } = useUserState()
  const selectedRoute = useSelectedRoutePreview()
  const routeCollection = useRouteCollection()

  return (
    selectedRoute && (
      <>
        {selectedRoute.geometry && <MapRoutePreview id="selected-collection-route" geometry={selectedRoute.geometry} />}
        <WebAppMapPopup
          longitude={selectedRoute.start.lng}
          latitude={selectedRoute.start.lat}
          markerHeight={selectedRoute && 40}
          maxWidth="23.5rem"
        >
          <WebAppRouteEntityItem
            route={selectedRoute}
            onFavoriteToggle={() => dispatch(toggleFavoriteRoute(selectedRoute.id))}
            routeCollectionId={routeCollection?.id}
            imperial={unitPreference === 'imperial'}
            onClick={() => dispatch(routeSelectionCleared())}
          />
        </WebAppMapPopup>
      </>
    )
  )
}
