import { Position } from 'geojson'
import { has } from 'lodash'
import { MapGeoJSONFeature } from 'react-map-gl/maplibre'

type SelectableFeature = MapGeoJSONFeature & {
  properties: {
    class: string
    name?: string
    address?: string
  }
  geometry: {
    type: 'Point'
    coordinates: Position
  }
}

export const isSelectableFeature = (feature: MapGeoJSONFeature): feature is SelectableFeature =>
  !!(
    feature.layer.type === 'symbol' &&
    feature.geometry.type === 'Point' &&
    has(feature.properties, 'class') &&
    feature.properties &&
    (has(feature.properties, 'name') || feature.properties['class'] === 'toilets')
  )
