import { RoutePoiCreation } from './editable/route-poi-creation'
import { RoutePoiDetailsEditable } from './editable/route-poi-details-editable'
import { RoutePoiEditing } from './editable/route-poi-editing'
import { useRoutePois } from './route-pois-context'

interface RoutePoiContentEditableProps {
  onError: (message: string) => void
}

/**
 * Shows editable content of the currently relevant route POI.
 */
export const RoutePoiContentEditable = ({ onError }: RoutePoiContentEditableProps) => {
  const { routeId, selectedRoutePoi, editingPosition } = useRoutePois()

  return editingPosition && selectedRoutePoi ? (
    <RoutePoiEditing routePoi={selectedRoutePoi} position={editingPosition} onError={onError} />
  ) : editingPosition && routeId ? (
    <RoutePoiCreation routeId={routeId} position={editingPosition} onError={onError} />
  ) : selectedRoutePoi ? (
    <RoutePoiDetailsEditable routePoi={selectedRoutePoi} onError={onError} />
  ) : null
}
