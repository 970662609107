import { API_PATH_ROUTE_POI } from '../../config'
import { deleteFromCoreApi } from '../../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export async function deleteRoutePoi(routePoiId: number): ApiResult<null> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE_POI, {
      params: { routePoiId },
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routePoiId })
  }
}
