import { useParams } from 'react-router-dom'
import { CollectionRouteDetails } from 'web-app/view-route-details'
import { ViewNotFound } from 'web-app/utils-error-handling'

const CollectionRouteDetailsView = () => {
  const params = useParams()

  const routeCollectionId = params['routeCollectionId'] ? Number.parseInt(params['routeCollectionId']) : undefined
  const routeId = params['routeId'] ? Number.parseInt(params['routeId']) : undefined

  return routeCollectionId && routeId ? (
    <CollectionRouteDetails routeId={routeId} routeCollectionId={routeCollectionId} />
  ) : (
    <ViewNotFound />
  )
}

export default CollectionRouteDetailsView
