import { ROUTING_API_BASE_URL } from './definitions'
import { GetRequestOptions, PostRequestOptions, addAuthHeader, getRequest, postRequest } from 'shared/util-network'

export async function getFromRoutingApi(path: string, options: GetRequestOptions = {}) {
  return getRequest(ROUTING_API_BASE_URL + path, {
    ...options,
    headers: { ...(await addAuthHeader()), ...options.headers },
  })
}

export async function postToRoutingApi(path: string, options: PostRequestOptions = {}) {
  return postRequest(ROUTING_API_BASE_URL + path, {
    ...options,
    headers: { ...(await addAuthHeader()), ...options.headers },
  })
}
