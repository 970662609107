import clsx from 'clsx'
import { MouseEvent, ReactNode } from 'react'
import { HeartButton } from '../HeartButton'
import { ArrowDescentIcon } from 'shared/ui-design-system/icons/arrow-descent-icon'
import { ArrowDistanceIcon } from 'shared/ui-design-system/icons/arrow-distance-icon'
import { BikemapBikeIcon } from 'shared/ui-design-system/icons/bikemap-bike-icon'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { Skeleton } from '@mui/material'
import { Link } from '../Link'

import styles from './route-item.module.scss'

type RenderImageProps = {
  className: string
  src: string
  width: number
  height: number
  alt: string
  loading: 'lazy' | 'eager'
}

export interface RouteItemProps {
  title: string
  distance: string
  ascent: string
  descent: string
  location?: string
  favoriteCount: number
  image?: string
  staticMap?: string
  isFavorite: boolean
  href: string
  eagerLoading?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  onHeartButtonClick?: () => void
  messages: {
    distanceLabel: string
    ascentLabel: string
    descentLabel: string
    locationLabel: string
  }
  renderImage?: (props: RenderImageProps) => ReactNode
  analyticsId?: string
}

export const RouteItem = ({
  title,
  distance,
  ascent,
  descent,
  location,
  favoriteCount,
  image,
  staticMap,
  isFavorite,
  href,
  eagerLoading,
  onClick,
  onHeartButtonClick,
  messages,
  renderImage,
  analyticsId,
}: RouteItemProps) => {
  const getImage = (): ReactNode => {
    const src = image || staticMap
    if (src) {
      const imageProps: RenderImageProps = {
        className: styles['image'],
        src,
        alt: title,
        loading: eagerLoading ? 'eager' : 'lazy',
        width: 104,
        height: 104,
      }
      return renderImage ? renderImage(imageProps) : <img {...imageProps} alt={imageProps.alt} />
    }
    return null
  }

  return (
    <div className={styles['container']}>
      <div className={styles['media']}>{getImage() || <BikemapBikeIcon className={styles['placeholder-icon']} />}</div>
      <div className={styles['content']}>
        <div className={clsx(styles['heart-button'], { [styles['heart-button-interactive']]: !!onHeartButtonClick })}>
          <HeartButton active={isFavorite} amount={favoriteCount} onClick={onHeartButtonClick} />
        </div>
        <Link secondary className={styles['title']} href={href} onClick={onClick} data-aid={analyticsId}>
          {title}
        </Link>
        <dl className={styles['attributes']}>
          <div className={styles['attribute-item']}>
            <dt>
              <span>{messages.distanceLabel}</span>
              <ArrowDistanceIcon fontSize="small" />
            </dt>
            <dd>{distance}</dd>
          </div>
          <div className={styles['attribute-item']}>
            <dt>
              <span>{messages.ascentLabel}</span>
              <ArrowOutwardRoundedIcon fontSize="small" />
            </dt>
            <dd>{ascent}</dd>
          </div>
          <div className={styles['attribute-item']}>
            <dt>
              <span>{messages.descentLabel}</span>
              <ArrowDescentIcon fontSize="small" />
            </dt>
            <dd>{descent}</dd>
          </div>
          {location && (
            <div className={clsx(styles['attribute-item'], styles['location-attribute'])}>
              <dt>
                <span>{messages.locationLabel}</span>
                <LocationOnRoundedIcon fontSize="small" />
              </dt>
              <dd>{location}</dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  )
}

export const RouteItemSkeleton = () => (
  <div className={styles['container']} style={{ paddingRight: '3.5rem' }}>
    <div className={styles['media']}>
      <Skeleton variant="rectangular" height="100%" />
    </div>
    <div className={styles['content']}>
      <div className={styles['title']}>
        <Skeleton />
      </div>
      <dl className={styles['attributes']}>
        <div className={styles['attribute-item']}>
          <Skeleton width="3.5rem" />
        </div>
        <div className={styles['attribute-item']}>
          <Skeleton width="3.5rem" />
        </div>
        <div className={styles['attribute-item']}>
          <Skeleton width="3.5rem" />
        </div>
      </dl>
    </div>
  </div>
)
