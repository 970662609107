import { useBaseRoute } from '../state'
import { WebAppMainActions } from 'web-app/ui-layout'
import { ButtonGroup } from 'shared/ui-components'
import { CopyRouteAction, CreateRouteAction, UpdateRouteAction } from '../components/actions'
import { useIsOwnRoute } from 'web-app/feature-route'

export const RoutePlannerMainActions = () => {
  const isOwnRoute = useIsOwnRoute()
  const baseRoute = useBaseRoute()

  return (
    <WebAppMainActions>
      <ButtonGroup>
        {!baseRoute && <CreateRouteAction primary />}
        {baseRoute && isOwnRoute && <UpdateRouteAction primary />}
        {baseRoute && <CopyRouteAction primary={!isOwnRoute} />}
      </ButtonGroup>
    </WebAppMainActions>
  )
}
