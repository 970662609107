import { EntityIndex, RouteCollectionEntity, RouteEntity, UserEntity } from 'shared/data-access-core'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'

export const ENTITIES_SLICE_KEY = 'entities'

export type EntitiesState = {
  routeCollections: EntityIndex<RouteCollectionEntity>
  routes: EntityIndex<RouteEntity>
  users: EntityIndex<UserEntity>
}

export interface StateWithEntitiesSlice {
  [ENTITIES_SLICE_KEY]: EntitiesState
}

export type EntitiesSliceDispatch = ThunkDispatch<StateWithEntitiesSlice, undefined, UnknownAction>
