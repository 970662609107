import { RouteEntity, UserEntity } from 'shared/data-access-core'
import React from 'react'
import { getLoginUrl, getRouteDetailsPath, getUserProfileUrl } from 'shared/util-navigation'
import { RouteEntityTile } from 'shared/feature-routes'
import { toggleFavoriteRoute, useUser } from 'web-app/feature-user'
import {
  RouteDetailsPreviousView,
  useNavigateToCollectionRouteDetails,
  useNavigateToRouteDetails,
  useSessionInfo,
} from 'web-app/feature-navigation'
import { useDispatch } from 'react-redux'
import { RouteSliceDispatch } from '../state'

interface RouteEntityTileProps {
  route: RouteEntity
  creator: UserEntity | null
  imperial?: boolean
  routeDetailsPreviousView?: RouteDetailsPreviousView
  routeCollectionId?: number
  onFavoriteToggle?: () => void
  onClick?: (e: React.MouseEvent) => void
}

/**
 * Renders a route tile based on a given route entity (and its optional creator entity).
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const WebAppRouteEntityTile = ({
  route,
  creator,
  imperial,
  routeDetailsPreviousView,
  routeCollectionId,
  onFavoriteToggle,
  onClick,
}: RouteEntityTileProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const navigateToCollectionRouteDetails = useNavigateToCollectionRouteDetails()
  const [user, isUserLoaded] = useUser()
  const sessionInfo = useSessionInfo()

  const handleHeartButtonClick = async () => {
    if (isUserLoaded && !user) {
      window.location.href = getLoginUrl({
        webAppPath: window.location.pathname,
        ...sessionInfo,
      })
    } else {
      if (onFavoriteToggle) {
        onFavoriteToggle()
      } else {
        dispatch(toggleFavoriteRoute(route.id))
      }
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
    e.preventDefault()
    if (routeCollectionId) {
      navigateToCollectionRouteDetails(route.id, routeCollectionId)
    } else {
      navigateToRouteDetails(route.id, { state: { previousView: routeDetailsPreviousView } })
    }
  }

  return (
    <RouteEntityTile
      route={route}
      creator={creator}
      imperial={imperial}
      href={getRouteDetailsPath(route.id)}
      creatorHref={creator ? getUserProfileUrl({ ...sessionInfo, slug: creator.slug }) : undefined}
      onClick={handleClick}
      onHeartButtonClick={handleHeartButtonClick}
    />
  )
}
