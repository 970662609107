import { createSelector } from 'reselect'
import {
  RouteCollectionEntity,
  RouteEntity,
  RouteEntityAnalyticsData,
  RouteEntityDetails,
  RouteEntityMapData,
  UserEntity,
  getFromIndex,
} from 'shared/data-access-core'
import { ROUTE_SLICE_KEY, RouteState, StateWithRouteSlice } from './types'
import { StateWithUserSlice, USER_SLICE_KEY } from 'web-app/feature-user'
import { ENTITIES_SLICE_KEY, StateWithEntitiesSlice } from 'web-app/data-access-entities'

export const routeSliceSelector = (state: StateWithRouteSlice): RouteState => state[ROUTE_SLICE_KEY]

export const routeSelector: (
  state: StateWithRouteSlice,
) => (RouteEntity & RouteEntityDetails & RouteEntityMapData & RouteEntityAnalyticsData) | undefined = createSelector(
  routeSliceSelector,
  (slice) => slice.route,
)

export const creatorSelector: (state: StateWithRouteSlice) => UserEntity | null = createSelector(
  routeSliceSelector,
  (slice) => slice.creator ?? null,
)

export const assignedRouteCollectionIdsSelector: (state: StateWithRouteSlice) => undefined | number[] = createSelector(
  routeSliceSelector,
  (slice) => slice.assignedRouteCollectionIds,
)

export const isOwnRouteSelector: (state: StateWithRouteSlice & StateWithUserSlice) => boolean | undefined =
  createSelector(
    (state: StateWithRouteSlice) => state[ROUTE_SLICE_KEY].creator,
    (state: StateWithUserSlice) => state[USER_SLICE_KEY].user,
    (state: StateWithUserSlice) => state[USER_SLICE_KEY].isUserLoaded,
    (creator, user, isUserLoaded) => {
      if (user && isUserLoaded) {
        return !!(user.isStaff || creator?.id === user.id)
      }
      return undefined
    },
  )

export const assignedRouteCollectionsSelector: (
  state: StateWithRouteSlice & StateWithEntitiesSlice,
) => RouteCollectionEntity[] | undefined = createSelector(
  (state: StateWithRouteSlice) => state[ROUTE_SLICE_KEY].assignedRouteCollectionIds,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routeCollections,
  (routeCollectionIds, routeCollections) => routeCollectionIds && getFromIndex(routeCollectionIds, routeCollections),
)
