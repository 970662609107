import clsx from 'clsx'
import { useLocale } from 'shared/util-intl'
import { useRoute } from 'web-app/feature-route'
import { BasePage } from './base-page'
import { useRoutePrintState } from '../state'

import styles from './images-page.module.scss'

interface ImagesPageProps {
  pageNumber: number
  secondPage?: boolean
}

export const ImagesPage = ({ pageNumber, secondPage }: ImagesPageProps) => {
  const { intl } = useLocale()
  const route = useRoute()
  const { selectedImages } = useRoutePrintState()

  const imageIndexesSlice = secondPage ? selectedImages.slice(5) : selectedImages.slice(0, 5)

  return (
    <BasePage
      number={pageNumber}
      title={intl.formatMessage({
        id: 'route_print_images_page_title',
        defaultMessage: 'Route photos',
      })}
    >
      <div className={styles['container']}>
        <div className={clsx(styles['images-container'], { [styles['second-page']]: secondPage })}>
          {imageIndexesSlice.map((imageIndex) => (
            <img key={imageIndex} src={route?.images[imageIndex].original} alt="" className={styles['image']} />
          ))}
        </div>
      </div>
    </BasePage>
  )
}
