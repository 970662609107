import { useState } from 'react'
import { Button } from 'shared/ui-components'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { useAuthenticationWall } from 'web-app/feature-navigation'
import { useLocale } from 'shared/util-intl'
import { CollectionsPopover } from './collections-popover'

interface AddToCollectionActionProps {
  sheetEl: HTMLDivElement | null
}

export const AddToCollectionAction = ({ sheetEl }: AddToCollectionActionProps) => {
  const { intl } = useLocale()
  const { doForAuthenticatedUser } = useAuthenticationWall()

  const [isCollectionsPopoverShown, setIsCollectionsPopoverShown] = useState<boolean>(false)

  const handleSaveClick = () => {
    doForAuthenticatedUser(() => setIsCollectionsPopoverShown(true))
  }

  return (
    <>
      <Button block variant="secondary" icon={<AddCircleRoundedIcon />} onClick={handleSaveClick}>
        {intl.formatMessage({
          id: 'route_details_footer_button_save',
          defaultMessage: 'Add to collection',
        })}
      </Button>
      <CollectionsPopover
        open={isCollectionsPopoverShown}
        anchorEl={sheetEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => setIsCollectionsPopoverShown(false)}
      />
    </>
  )
}
