import { CSSProperties, ForwardedRef, ReactNode, forwardRef } from 'react'
import clsx from 'clsx'
import { CircularProgress } from '@mui/material'
import { useBreakpoints } from './use-breakpoints'

import styles from './web-app-content.module.scss'

interface WebAppContentProps {
  children?: ReactNode
  className?: string
  variant?: 'default' | 'secondary'
  noPadding?: boolean
  stickyOnTop?: boolean
  stickyOffset?: string
  zIndex?: number
  renderLarge?: () => ReactNode
  renderSmall?: () => ReactNode
}

export const WebAppContent = forwardRef(
  (
    {
      children,
      className,
      variant = 'default',
      noPadding,
      stickyOnTop,
      stickyOffset,
      zIndex,
      renderLarge,
      renderSmall,
    }: WebAppContentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { layoutBreakpoint } = useBreakpoints()

    return (
      <div
        ref={ref}
        className={clsx(
          styles['container'],
          {
            [styles['secondary']]: variant === 'secondary',
            [styles['padding']]: !noPadding,
            [styles['sticky-on-top']]: stickyOnTop,
          },
          className,
        )}
        style={{ '--sticky-offset': stickyOffset || 0, zIndex } as CSSProperties}
      >
        <div className={styles['wrapper']}>
          {renderLarge && layoutBreakpoint
            ? renderLarge()
            : renderSmall && !layoutBreakpoint
              ? renderSmall()
              : children}
        </div>
      </div>
    )
  },
)

export const WebAppContentLoading = () => (
  <div className={clsx(styles['container'], styles['loading-wrapper'])}>
    <CircularProgress />
  </div>
)

export const WebAppContentPaddinglessChild = ({ children }: { children: ReactNode }) => (
  <div className={styles['paddingless-child']}>{children}</div>
)
