import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Button, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { logError } from 'web-app/utils-error-handling'
import { copyToClipboard, getRouteShareUrl } from 'shared/util-navigation'
import { useUrlCopiedNotification } from '../hooks/notifications'
import { useRoute } from 'web-app/feature-route'

export const ShareAction = () => {
  const { intl } = useLocale()
  const { shareLabel } = useMessages()
  const route = useRoute()
  const showUrlCopiedNotification = useUrlCopiedNotification()

  const handleShare = async () => {
    if (!route) return

    const url = getRouteShareUrl({
      routeId: route.id,
      trackingParameters: {
        utmMedium: 'route_details',
        utmCampaign: 'share_route',
      },
    })

    if (navigator.share) {
      try {
        await navigator.share({
          title: route.title,
          text: intl.formatMessage({
            id: 'route_details_sharing_share_text',
            defaultMessage: 'Check out this route on Bikemap!',
          }),
          url,
        })
      } catch (error) {
        logError('Could not share route', error)
      }
    } else {
      copyToClipboard(url)
      showUrlCopiedNotification(url)
    }
  }

  return (
    <Button variant="secondary" icon={<ShareRoundedIcon />} disabled={!route || route.isPrivate} onClick={handleShare}>
      {shareLabel}
    </Button>
  )
}
