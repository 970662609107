import { LngLat, MapMarkerEdit } from 'shared/ui-map'
import { useRoutePois } from '../route-pois-context'

interface RoutePoiMapMarkerEditingProps {
  position: LngLat
}

export const RoutePoiMapMarkerEditing = ({ position }: RoutePoiMapMarkerEditingProps) => {
  const { onDragStart, onDragEnd } = useRoutePois()

  return (
    <MapMarkerEdit longitude={position.lng} latitude={position.lat} onDragStart={onDragStart} onDragEnd={onDragEnd} />
  )
}
