import { useEffect, useState } from 'react'
import { PremiumModalGeneral } from './premium-modal'
import { useDispatch } from 'react-redux'
import { premiumTriggerShown, useUserState } from './state'
import campaigns from './campaign'
import { Campaign } from './campaign/types'
import { useBreakpoints } from 'web-app/ui-layout'
import { GetAppOverlay } from './get-app-overlay'

const GENERAL_MODAL_INTERVAL_DAYS = 7
const GENERAL_MODAL_INTERVAL_MILLISECONDS = GENERAL_MODAL_INTERVAL_DAYS * 86400000

const CAMPAIGN_MODAL_INTERVAL_DAYS = 2
const CAMPAIGN_MODAL_INTERVAL_MILLISECONDS = CAMPAIGN_MODAL_INTERVAL_DAYS * 86400000

/**
 * Integrate this globally to enable user-specific triggers such as modals.
 */
export const PersonalTriggers = () => {
  const { user, isUserLoaded } = useUserState()
  const { layoutBreakpoint } = useBreakpoints()

  return isUserLoaded ? !user && !layoutBreakpoint ? <MobileAppFunnelTriggers /> : <PremiumUpsellTriggers /> : null
}

const MobileAppFunnelTriggers = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  return <GetAppOverlay isOpen={isOpen} onClose={() => setIsOpen(false)} />
}

const PremiumUpsellTriggers = () => {
  const dispatch = useDispatch()
  const { premiumTriggerLastShown, user, isUserLoaded } = useUserState()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const campaign: Campaign | null =
    (isUserLoaded && campaigns.find((c) => c.isUserTarget(!!user, !!user?.isPremium))) || null
  const PremiumModalCampaign = campaign && campaign?.popup

  useEffect(() => {
    if (
      (campaign &&
        (!premiumTriggerLastShown ||
          new Date().getTime() - premiumTriggerLastShown > CAMPAIGN_MODAL_INTERVAL_MILLISECONDS)) ||
      (isUserLoaded &&
        !!user &&
        !user.isPremium &&
        (!premiumTriggerLastShown ||
          new Date().getTime() - premiumTriggerLastShown > GENERAL_MODAL_INTERVAL_MILLISECONDS))
    ) {
      setIsOpen(true)
    }
  }, [campaign, isUserLoaded, premiumTriggerLastShown, user])

  const handleClose = () => {
    setIsOpen(false)
    dispatch(premiumTriggerShown())
  }

  return PremiumModalCampaign ? (
    <PremiumModalCampaign isOpen={isOpen} onClose={handleClose} />
  ) : (
    <PremiumModalGeneral isOpen={isOpen} onClose={handleClose} />
  )
}
