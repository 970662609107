const MAX_WIDTH_PX = 2800
const MAX_HEIGHT_PX = 2800

export function resizeImageForUpload(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    // Create image object
    const img = new Image()
    const reader = new FileReader()

    reader.onload = function (e) {
      if (typeof e.target?.result !== 'string') {
        return reject(Error('Unexpected image source.'))
      }
      img.src = e.target.result

      img.onload = function () {
        // Create canvas for resizing
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) {
          return reject(Error('Canvas context not available.'))
        }

        // Calculate new dimensions
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH_PX) {
            height *= MAX_WIDTH_PX / width
            width = MAX_WIDTH_PX
          }
        } else {
          if (height > MAX_HEIGHT_PX) {
            width *= MAX_HEIGHT_PX / height
            height = MAX_HEIGHT_PX
          }
        }

        // Resize image
        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)

        // Convert to blob and resolve promise
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(
              new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              }),
            )
          }
        }, file.type)
      }

      img.onerror = reject
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
