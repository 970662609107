import clsx from 'clsx'
import { Skeleton } from '@mui/material'
import { ReactNode } from 'react'

import styles from './chip.module.scss'

export interface ChipProps {
  children: string
  active?: boolean
  className?: string
  icon?: ReactNode
  href?: string
  analyticsId?: string
  onClick?: () => void
}

export const Chip = ({ children, active, className, icon, href, analyticsId, onClick }: ChipProps) => {
  const props = {
    className: clsx(className, styles['chip'], { [styles['active']]: active, [styles['with-icon']]: icon }),
    onClick,
  }

  const content = (
    <>
      {icon && <span className={styles['icon-holder']}>{icon}</span>}
      {children}
    </>
  )

  return href ? (
    <a href={href} {...props} data-aid={analyticsId}>
      {content}
    </a>
  ) : onClick ? (
    <button type="button" {...props} data-aid={analyticsId}>
      {content}
    </button>
  ) : (
    <span {...props} data-aid={analyticsId}>
      {content}
    </span>
  )
}

export const ChipSkeleton = () => <Skeleton variant="rectangular" className={styles['skeleton']} />
