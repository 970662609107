import { Position } from 'geojson'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { CompleteWaypoints, IncompleteWaypoints, RoutedWaypoint, Waypoint } from './types'
import calculateDistance from '@turf/distance'
import {
  LngLat,
  LngLatElevation,
  Position3d,
  RichLineString,
  RichMultiLineString,
  RichPosition,
  copyRichPosition,
  estimateDuration,
  lngLatToPosition2d,
  positionToLngLatElevation,
  reduceToPosition,
  roundPositionValue,
} from 'shared/util-geo'

/**
 * Split a line at given indexes. Indexes must be unique, ascending and should contain the first and last index.
 */
export function splitLineAtIndexes(line: RichLineString, indexes: [number, number, ...number[]]): RichMultiLineString {
  const coordinates: RichPosition[][] = [line.coordinates.slice(0, indexes[1] + 1)]
  for (let i = 2; i < indexes.length; i++) {
    const segment = line.coordinates.slice(indexes[i - 1], indexes[i] + 1).map(copyRichPosition)
    segment[0][3] = 0
    segment[0][4] = 0
    coordinates.push(segment)
  }
  return {
    type: 'RichMultiLineString',
    coordinates,
  }
}

/**
 * Split a line according to split points, which can be part of the line coordinates, on the line or near the line.
 */
export function splitLineAtPoints(line: RichLineString, splitPoints: LngLat[]): RichMultiLineString {
  return {
    type: 'RichMultiLineString',
    coordinates:
      splitPoints.length > 2
        ? splitLineNearMultipleLocations(line.coordinates, splitPoints.slice(1, -1))
        : [[...line.coordinates]],
  }
}

/**
 * Split line coordinates near the given locations.
 */
export function splitLineNearMultipleLocations(coordinates: RichPosition[], splitPoints: LngLat[]): RichPosition[][] {
  /** Coordinates for the final MultiLineString */
  const splitCoordinates: RichPosition[][] = []
  /** Line without the parts that have already been sliced (to minimize conflicts) */
  let remainingCoordinates: RichPosition[] = coordinates

  for (const splitPoint of splitPoints) {
    const splitLine = splitLineNearLocation(remainingCoordinates, splitPoint)
    if (splitLine[0].length >= 2) {
      splitCoordinates.push(splitLine[0])
    }
    remainingCoordinates = splitLine[1] || []
  }

  if (remainingCoordinates.length >= 2) {
    // Add the last segment (until the end) to coordinates
    splitCoordinates.push(remainingCoordinates)
  }

  return splitCoordinates
}

/**
 * Split line coordinates at the point that is nearest to a given location.
 */
export function splitLineNearLocation(
  coordinates: RichPosition[],
  location: LngLat,
): [RichPosition[]] | [RichPosition[], RichPosition[]] {
  const { lng, lat } = location

  // First check if exact point is already in coordinates
  const pointIndex = coordinates.findIndex(([pointLng, pointLat]) => lng === pointLng && lat === pointLat)
  if (pointIndex === 0 || pointIndex === coordinates.length - 1) {
    return [coordinates]
  } else if (pointIndex > 0) {
    return [coordinates.slice(0, pointIndex + 1), coordinates.slice(pointIndex)]
  }

  // Find nearest point on the line and split into segments at that point
  const { geometry, properties } = nearestPointOnLine({ type: 'LineString', coordinates: coordinates as Position[] }, [
    lng,
    lat,
  ])
  const linePartIndex = properties.index ? properties.index : 0
  const longitude = roundPositionValue(geometry.coordinates[0])
  const latitude = roundPositionValue(geometry.coordinates[1])
  const elevation = roundPositionValue((coordinates[linePartIndex][2] + coordinates[linePartIndex + 1][2]) / 2)
  const distance = roundPositionValue(
    calculateDistance(reduceToPosition(coordinates[linePartIndex]), geometry.coordinates, {
      units: 'meters',
    }),
  )
  const shareOfPart = distance / coordinates[linePartIndex + 1][3]
  const time = Math.round(coordinates[linePartIndex + 1][4] * shareOfPart)

  const nextPosition: RichPosition = [...coordinates[linePartIndex + 1]]
  nextPosition[3] = coordinates[linePartIndex + 1][3] - distance
  nextPosition[4] = coordinates[linePartIndex + 1][4] - time

  return [
    [
      ...coordinates.slice(0, linePartIndex + 1), // positions up until the split part
      [longitude, latitude, elevation, distance, time, nextPosition[5], nextPosition[6], nextPosition[7]], // exact split position
    ],
    [
      [longitude, latitude, elevation, 0, 0, nextPosition[5], nextPosition[6], nextPosition[7]], // split position repeated
      nextPosition,
      ...coordinates.slice(linePartIndex + 2),
    ],
  ]
}

/**
 * Combine line segments to one line without duplicate split points.
 */
export function combineSegmentsToLine(segments: RichMultiLineString): RichLineString {
  const line: RichLineString = {
    type: 'RichLineString',
    coordinates: [...segments.coordinates[0]],
  }
  for (let i = 1; i < segments.coordinates.length; i++) {
    // Add all coordinates of the next segment except the first one (duplicate)
    for (let j = 1; j < segments.coordinates[i].length; j++) {
      line.coordinates.push(segments.coordinates[i][j])
    }
  }
  return line
}

/**
 * Get the start point, split points and end point of the segmented route line.
 */
export function deriveControlPointsFromSegments(geometry: RichMultiLineString): LngLatElevation[] {
  if (!geometry.coordinates.length) return []
  const controlPoints: LngLatElevation[] = [positionToLngLatElevation(geometry.coordinates[0][0])]
  for (const segment of geometry.coordinates) {
    controlPoints.push(
      segment.length ? positionToLngLatElevation(segment[segment.length - 1]) : controlPoints[controlPoints.length - 1],
    )
  }
  return controlPoints
}

/**
 * Get indexes of the positions in a combined geometry that would represent control points.
 */
export function deriveControlPointIndexesFromSegments(
  segments: RichMultiLineString,
): [number, number, ...number[]] | null {
  if (!segments.coordinates.length) return null
  const indexes: [number, number, ...number[]] = [0, segments.coordinates[0].length - 1]
  for (let i = 1; i < segments.coordinates.length; i++) {
    indexes.push(indexes[indexes.length - 1] + segments.coordinates[i].length - 1)
  }
  return indexes
}

/**
 * Create geometry coordinates with straight segments and estimated stats from a list of locations.
 * @param locations 3D LngLats that define the freehand sections
 */
export function calculateFreehandSegments(locations: LngLatElevation[]): RichMultiLineString {
  const coordinates: RichPosition[][] = []
  for (let i = 1; i < locations.length; i++) {
    const start = locations[i - 1]
    const end = locations[i]
    const distanceMeters = calculateDistance(lngLatToPosition2d(start), lngLatToPosition2d(end), { units: 'meters' })
    const timeMilliseconds = i === 0 ? 0 : estimateDuration(distanceMeters, end.elevation - start.elevation)
    coordinates.push([
      [start.lng, start.lat, start.elevation, 0, 0, null, null, null],
      [end.lng, end.lat, end.elevation, distanceMeters, timeMilliseconds, null, null, null],
    ])
  }
  return {
    type: 'RichMultiLineString',
    coordinates,
  }
}

/**
 * Calculate a freehand segment from a newly defined location to a known control point, based on estimates.
 */
export function calculateFreehandStartSegment(
  newLocation: LngLat,
  nextControlPoint: LngLatElevation,
): [RichPosition, RichPosition] {
  const elevation = nextControlPoint.elevation // assuming same elevation as next control point
  const distance = roundPositionValue(
    calculateDistance(lngLatToPosition2d(newLocation), lngLatToPosition2d(nextControlPoint), {
      units: 'meters',
    }),
  )
  const time = estimateDuration(distance, 0)
  return [
    [newLocation.lng, newLocation.lat, elevation, 0, 0, null, null, null],
    [nextControlPoint.lng, nextControlPoint.lat, elevation, distance, time, null, null, null],
  ]
}

/**
 * Calculate a freehand segment from a known control point to a newly defined location, based on estimates.
 */
export function calculateFreehandEndSegment(
  previousControlPoint: LngLatElevation,
  newLocation: LngLat,
): [RichPosition, RichPosition] {
  const elevation = previousControlPoint.elevation // assuming same elevation as previous control point
  const distance = roundPositionValue(
    calculateDistance(lngLatToPosition2d(previousControlPoint), lngLatToPosition2d(newLocation), {
      units: 'meters',
    }),
  )
  const time = estimateDuration(distance, 0)
  return [
    [previousControlPoint.lng, previousControlPoint.lat, previousControlPoint.elevation, 0, 0, null, null, null],
    [newLocation.lng, newLocation.lat, elevation, distance, time, null, null, null],
  ]
}

/**
 * Calculate a freehand segment from a known control point via a newly defined location to a known control
 * point, based on estimates.
 */
export function calculateFreehandMiddleSegments(
  previousControlPoint: LngLatElevation,
  newLocation: LngLat,
  nextControlPoint: LngLatElevation,
): [[RichPosition, RichPosition], [RichPosition, RichPosition]] {
  const elevation = roundPositionValue(previousControlPoint.elevation + nextControlPoint.elevation / 2)
  const distance = roundPositionValue(
    calculateDistance(lngLatToPosition2d(previousControlPoint), lngLatToPosition2d(newLocation), {
      units: 'meters',
    }),
  )
  const time = estimateDuration(distance, elevation - previousControlPoint.elevation)
  const elevationAfter = nextControlPoint.elevation
  const distanceAfter = roundPositionValue(
    calculateDistance(lngLatToPosition2d(newLocation), lngLatToPosition2d(nextControlPoint), {
      units: 'meters',
    }),
  )
  const timeAfter = estimateDuration(distanceAfter, elevationAfter - elevation)
  return [
    [
      [previousControlPoint.lng, previousControlPoint.lat, previousControlPoint.elevation, 0, 0, null, null, null],
      [newLocation.lng, newLocation.lat, elevation, distance, time, null, null, null],
    ],
    [
      [newLocation.lng, newLocation.lat, elevation, 0, 0, null, null, null],
      [nextControlPoint.lng, nextControlPoint.lat, elevationAfter, distanceAfter, timeAfter, null, null, null],
    ],
  ]
}

/**
 * Get a rough estimation of the route stats based on 3d geometry.
 */
export function getStatsEstimation(
  from: Position3d,
  to: Position3d,
): { distanceMeters: number; durationSeconds: number } {
  const distanceMeters = calculateDistance(from, to, { units: 'meters' })
  if (!distanceMeters) {
    return { distanceMeters: 0, durationSeconds: 0 }
  }
  const elevationDifference = to[2] - from[2]
  return {
    distanceMeters,
    durationSeconds: estimateDuration(distanceMeters, elevationDifference) / 1000,
  }
}

/**
 * Takes initial, complete waypoints assuming there is the same number of control points and returns a correctly
 * typed list of waypoints with control point relations.
 */
export function getInitialCompleteWaypoints(waypoints: [Waypoint, Waypoint, ...Waypoint[]]): CompleteWaypoints {
  return [
    { ...waypoints[0], controlPointIndex: 0 },
    { ...waypoints[1], controlPointIndex: 1 },
    ...waypoints.slice(2).map((waypoint, i) => ({ ...waypoint, controlPointIndex: i + 2 })),
  ]
}

export function updateControlPointRelations(
  waypoints: (Waypoint | null)[],
  firstAffectedControlPointIndex: number,
  delta = 1,
) {
  waypoints.forEach((waypoint) => {
    if (
      typeof waypoint?.controlPointIndex === 'number' &&
      waypoint.controlPointIndex >= firstAffectedControlPointIndex
    ) {
      waypoint.controlPointIndex += delta
    }
  })
}

export function areWaypointsComplete(
  waypoints: IncompleteWaypoints | CompleteWaypoints,
): waypoints is CompleteWaypoints {
  return !!(waypoints.length > 2 || (waypoints[0] && waypoints[1]))
}

export function areMoreWaypoints(
  waypoints: IncompleteWaypoints | CompleteWaypoints,
): waypoints is [RoutedWaypoint, RoutedWaypoint, RoutedWaypoint, ...RoutedWaypoint[]] {
  return waypoints.length > 2
}

export function generateSimpleId(): string {
  return (Math.random() * 100000).toFixed()
}
