import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Button, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { logError } from 'web-app/utils-error-handling'
import { copyToClipboard, getRouteCollectionShareUrl } from 'shared/util-navigation'
import { showNotification } from 'web-app/feature-notifications'
import { useRouteCollectionState } from 'web-app/feature-route-collection'

export const ShareRouteCollectionAction = () => {
  const { intl } = useLocale()
  const { shareLabel } = useMessages()
  const { routeCollection, isRouteCollectionLoaded } = useRouteCollectionState()

  const handleShare = async () => {
    if (!routeCollection) return

    const url = getRouteCollectionShareUrl({
      routeCollectionId: routeCollection.id,
      trackingParameters: {
        utmMedium: 'route_collection_details',
        utmCampaign: 'share_route_collection',
      },
    })

    if (navigator.share) {
      try {
        await navigator.share({
          title: routeCollection.title,
          text: intl.formatMessage({
            id: 'route_collection_sharing_share_text',
            defaultMessage: 'Check out this route collection on Bikemap!',
          }),
          url,
        })
      } catch (error) {
        logError('Could not share route collection', error)
      }
    } else {
      copyToClipboard(url)
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_collection_sharing_copied_to_clipboard',
            defaultMessage: 'Link copied to clipboard',
          }),
          description: intl.formatMessage(
            {
              id: 'route_collection_sharing_copied_to_clipboard_description',
              defaultMessage: `
            You can now share the route collection with your friends by pasting the link in a message or post: {url}
          `,
            },
            {
              url: (
                <a href={url} style={{ lineBreak: 'anywhere' }}>
                  {url}
                </a>
              ),
            },
          ),
          variant: 'success',
        },
        'success_route_collection_url_copied',
      )
    }
  }

  return (
    <Button
      variant="secondary"
      icon={<ShareRoundedIcon />}
      disabled={!isRouteCollectionLoaded || !routeCollection || routeCollection.isPrivate}
      onClick={handleShare}
    >
      {shareLabel}
    </Button>
  )
}
