import { MapRoute } from 'shared/ui-map'
import { useRoute } from './state'
import { RichMultiLineString } from 'shared/util-geo'

interface MainMapRouteProps {
  inactiveGeometry?: RichMultiLineString
}

/**
 * Shows the current main route from global state on the map.
 */
export const MainMapRoute = ({ inactiveGeometry }: MainMapRouteProps) => {
  const route = useRoute()

  return route ? (
    <MapRoute
      id="main-route"
      geometry={route.geometry}
      inactiveGeometry={inactiveGeometry}
      waypoints={route.waypoints}
      controlPointIndexes={route.controlPointIndexes || undefined}
    />
  ) : null
}
