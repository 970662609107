import clsx from 'clsx'
import { useLocale } from 'shared/util-intl'
import ImageIcon from '@mui/icons-material/Image'
import { Skeleton } from '@mui/material'
import { IMAGE_FRAGMENT_HASH } from '../../config'
import { useFragmentRouting } from '../../use-fragment-routing'

import styles from './images-grid.module.css'

interface ImagesGridProps {
  images: { tile: string }[]
  numMoreImages: number
}

export const ImagesGrid = ({ images, numMoreImages }: ImagesGridProps) => {
  const { intl } = useLocale()
  const { getFragmentLinkProps } = useFragmentRouting()

  return (
    <div className={clsx(styles['container'], { [styles['container-full']]: images.length > 3 })}>
      {images.slice(0, 4).map((image, i) => {
        const alt = intl.formatMessage(
          {
            id: 'route_images_alt_text',
            defaultMessage: 'Route image {number}',
          },
          {
            number: i + 1,
          },
        )
        const img = <img src={image.tile} alt={alt} className={styles['image']} />
        return (
          <a key={i} className={styles['image-item']} {...getFragmentLinkProps(IMAGE_FRAGMENT_HASH, i)}>
            {i === 3 && numMoreImages ? (
              <div className={styles['dimmed-item']}>
                {img}
                <div className={styles['overlay-label']}>
                  +{numMoreImages} <ImageIcon />
                </div>
              </div>
            ) : (
              img
            )}
          </a>
        )
      })}
    </div>
  )
}

export const ImagesGridSkeleton = () => (
  <div className={clsx(styles['container'], styles['container-full'])}>
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
  </div>
)
