import { API_PATH_ROUTE_IMAGE, API_PATH_ROUTE_IMAGES, API_PATH_ROUTE_UPLOAD_IMAGE } from '../../config'
import { RouteImageEntity } from '../../entities'
import { deleteFromCoreApi, getFromCoreApi, postToCoreApi } from '../../network'
import {
  ApiResult,
  MinimalEndpointErrors,
  ResponseParser,
  ResponseParserError,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'

export async function addRouteImage(
  routeId: number,
  image: File,
): ApiResult<
  File,
  MinimalEndpointErrors & {
    imageNotCreated?: true
  }
> {
  try {
    const res: { route_image_created: boolean } = await postToCoreApi(API_PATH_ROUTE_UPLOAD_IMAGE, {
      params: { routeId },
      body: { image },
    })

    if (res.route_image_created) {
      return createSuccessResult(image)
    }
    return createFailureResult({ imageNotCreated: true })
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

type RouteImageResponse = {
  id: number
  route: number
  image_data: {
    fallback: string
    item: string | null
    tile: string | null
  }
  url: string
}

export async function getRouteImages(routeId: number): ApiResult<RouteImageEntity[]> {
  try {
    const res: RouteImageResponse[] = await getFromCoreApi(API_PATH_ROUTE_IMAGES, {
      params: { routeId },
    })
    try {
      return createSuccessResult(res.map(convertToRouteImageEntity))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error, routeId })
    }
  } catch {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

export async function deleteRouteImage(routeId: number, routeImageId: number): ApiResult<null> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE_IMAGE, {
      params: { routeId, routeImageId },
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId, routeImageId })
  }
}

function convertToRouteImageEntity(res: RouteImageResponse): RouteImageEntity {
  const parser = new ResponseParser(res)
  const image = parser.takeImageSizes('image_data', {
    item: 'item',
    tile: 'tile',
    original: 'fallback',
  })
  if (!image) throw ResponseParserError
  return {
    id: parser.requireNumber('id'),
    routeId: parser.requireNumber('route'),
    image,
  }
}
