import { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './web-app-controls.module.scss'
import additionalContentStyles from './web-app-additional-content.module.scss'

export interface WebAppControlsProps {
  children: ReactNode
}

/**
 * Controls for the media, such as map controls for a map.
 */
export const WebAppControls = ({ children }: WebAppControlsProps) => (
  <div className={clsx(styles['container'], additionalContentStyles['hidden-if-open'])}>{children}</div>
)
