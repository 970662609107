import { API_PATH_ROUTE_ROUTE_POIS } from '../../config'
import { RoutePoiEntity } from '../../entities'
import { getFromCoreApi } from '../../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import { RoutePoiResponse, convertToRoutePoiEntity } from './shared-response'

type Response = RoutePoiResponse[]

export async function fetchRoutePoisForRoute(routeId: number): ApiResult<RoutePoiEntity[]> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_ROUTE_ROUTE_POIS, {
      params: { routeId },
    })
    try {
      return createSuccessResult(res.map(convertToRoutePoiEntity))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}
