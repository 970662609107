import { useRef, useState } from 'react'
import { useUser } from 'web-app/feature-user'
import { WebAppNavigation } from 'web-app/ui-layout'
import { PremiumLink, SignupLoginLink } from './links'
import { NavigationMenuToggle } from './navigation-menu-toggle'
import { NavigationMenu } from './navigation-menu'

interface NavigationProps {
  largeViewportOnly?: boolean
}

export const Navigation = ({ largeViewportOnly }: NavigationProps) => {
  const [user, isUserLoaded] = useUser()

  const navigationRef = useRef(null)

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  return (
    <WebAppNavigation
      ref={navigationRef}
      itemsSmall={
        !largeViewportOnly && (
          <>
            {isUserLoaded &&
              (user ? !user.isPremium && <PremiumLink primary compact /> : <SignupLoginLink primary compact />)}
            <NavigationMenuToggle isOpen={isMenuOpen} onToggle={setIsMenuOpen} compact />
          </>
        )
      }
      itemsLarge={
        <>
          {isUserLoaded && (user ? !user.isPremium && <PremiumLink primary /> : <SignupLoginLink primary />)}
          <NavigationMenuToggle isOpen={isMenuOpen} onToggle={setIsMenuOpen} />
        </>
      }
    >
      <NavigationMenu
        anchorEl={navigationRef.current}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => {
          setIsMenuOpen(false)
        }}
      />
    </WebAppNavigation>
  )
}
