import React from 'react'
import { Skeleton, Typography } from '@mui/material'
import { RouteCollectionIcon } from 'shared/ui-design-system/icons/route-collection-icon'

import styles from './route-collection-item.module.scss'

export interface RouteCollectionItemProps {
  title: string
  info: string
  image: string | null
  icon?: React.ReactNode
}

export const RouteCollectionItem = ({
  title,
  info,
  image,
  icon = <RouteCollectionIcon />,
}: RouteCollectionItemProps) => (
  <div className={styles['root']}>
    <div className={styles['media']}>
      {image ? (
        <img src={image} alt={title} loading="lazy" className={styles['image']} />
      ) : (
        <div className={styles['icon']} data-testid="route-collection-icon">
          {icon}
        </div>
      )}
    </div>
    <div className={styles['text']}>
      <Typography className={styles['title']} fontWeight={500}>
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {info}
      </Typography>
    </div>
  </div>
)

export const RouteCollectionItemSkeleton = () => (
  <div className={styles['root']}>
    <div className={styles['media']}>
      <Skeleton variant="rectangular" className={styles['media-skeleton']} />
    </div>
    <div className={styles['text']}>
      <Typography className={styles['title']} fontWeight={500}>
        <Skeleton width="70%" />
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <Skeleton width="50%" />
      </Typography>
    </div>
  </div>
)
