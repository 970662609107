import React, { useCallback, useEffect } from 'react'
import BaseMap, { MapProps as BaseMapProps, ViewStateChangeEvent, useMap } from 'react-map-gl/maplibre'
import { MapViewport, MapViewportChanges, VisibleMapPadding } from '../types'
import { useTransformRequest } from './use-transform-request'
import { getViewStateFromViewport, getViewportFromViewState } from '../helpers'
import { useGlobalHeatmapLayer } from './use-global-heatmap-layer'
import { LngLatBoundsArray } from 'shared/util-geo'

import styles from './Map.module.scss'

export interface MapProps {
  id: string
  children?: React.ReactNode
  initialViewport: MapViewport
  padding?: VisibleMapPadding
  desiredViewport?: MapViewportChanges | null
  onMatchViewport?: (desiredViewportString: string) => void
  desiredBounds?: LngLatBoundsArray | null
  onFitBounds?: (desiredBoundsString: string) => void
  onViewportChanged?: (viewport: MapViewport) => void
  isGlobalHeatmapEnabled?: boolean
  onGlobalHeatmapSupportDetection?: (isSupported: boolean) => void
  mapStyle: string
  onClick?: BaseMapProps['onClick']
  preserveDrawingBuffer?: BaseMapProps['preserveDrawingBuffer']
  onRender?: BaseMapProps['onRender']
  interactive?: BaseMapProps['interactive']
  reuseMaps?: BaseMapProps['reuseMaps']
}

export const Map = ({
  id,
  children,
  initialViewport,
  desiredViewport,
  onMatchViewport,
  desiredBounds,
  onFitBounds,
  onViewportChanged,
  isGlobalHeatmapEnabled,
  onGlobalHeatmapSupportDetection,
  reuseMaps = true,
  ...mapProps
}: MapProps) => {
  const maps = useMap()
  const map = maps[id]

  const [transformRequest, isAuthenticated] = useTransformRequest()

  const updateGlobalHeatmapLayer = useGlobalHeatmapLayer(map, isGlobalHeatmapEnabled, onGlobalHeatmapSupportDetection)

  useEffect(() => {
    if (map && desiredViewport) {
      map.resize() // Make sure map canvas has correct dimensions right now
      map.flyTo({ ...desiredViewport, duration: 1000 }, { originalViewportToFit: JSON.stringify(desiredViewport) })
    }
  }, [map, desiredViewport])

  useEffect(() => {
    if (map && desiredBounds) {
      map.resize() // Make sure map canvas has correct dimensions right now
      map.fitBounds(desiredBounds, { duration: 1000 }, { originalBoundsToFit: desiredBounds.toString() })
    }
  }, [desiredBounds, map])

  const handleMoveEnd = useCallback(
    (event: ViewStateChangeEvent) => {
      const originalViewportToFit = (event as { originalViewportToFit?: string }).originalViewportToFit
      const originalBoundsToFit = (event as { originalBoundsToFit?: string }).originalBoundsToFit
      if (originalViewportToFit) {
        onMatchViewport && onMatchViewport(originalViewportToFit)
      } else if (originalBoundsToFit) {
        onFitBounds && onFitBounds(originalBoundsToFit)
      }
      if (map) {
        onViewportChanged && onViewportChanged(getViewportFromViewState(event.viewState))
      }
    },
    [map, onFitBounds, onMatchViewport, onViewportChanged],
  )

  if (isAuthenticated) {
    return (
      <div className={styles['root']}>
        <BaseMap
          id={id}
          initialViewState={getViewStateFromViewport(initialViewport)}
          onMoveEnd={handleMoveEnd}
          transformRequest={transformRequest}
          attributionControl={false}
          reuseMaps={reuseMaps}
          styleDiffing={false}
          maxZoom={21}
          onData={updateGlobalHeatmapLayer}
          {...mapProps}
        >
          {children}
        </BaseMap>
      </div>
    )
  }
  return null
}

export default Map
