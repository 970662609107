import { API_PATH_ROUTE } from '../../config'
import { patchToCoreApi } from '../../network'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { MultiLineString, MultiPoint, Point, Polygon, Position } from 'geojson'
import { RichLineString, Waypoints, getTimes, reduceToPosition } from 'shared/util-geo'
import {
  ROUTE_TITLE_MAX_LENGTH,
  RouteBikeType,
  RouteEntity,
  RouteEntityAnalyticsData,
  RouteEntityDetails,
  RouteEntityMapData,
  UserEntity,
} from '../../entities'
import { getWaypointsAndControlPointIndexes, parseUserEntity } from './helpers'
import { enrichGeometry, mapToBikeType, mapToSurface } from '../helpers'

export type RouteChangeForm = {
  title?: string
  description?: string
  bikeTypes?: RouteBikeType[]
  isPrivate?: boolean
}

export type RouteGeometryChangeForm = {
  geometry: RichLineString
  distanceMeters: number
  durationSeconds?: number
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
}

type RoutePatchBody = {
  category?: number[]
  control_point_indexes?: number[]
  description?: string
  distance?: number
  duration?: number
  is_private?: boolean
  /** Route endpoint seems to expect a LineString with type="MultiLineString". */
  points?: {
    type: 'MultiLineString'
    coordinates: Position[]
  }
  time?: number[]
  title?: string
  waypoints?: MultiPoint
}

type Response = {
  admin_two_id?: number
  altitude_difference_back: number | null
  altitude_difference: number | null
  average_speed: null // seems to be always null
  bounding_box: Polygon
  category: number[] | null
  control_point_indexes: number[] | null
  copied_from: number | null
  created: string
  description: string | null
  distance: number
  duration: number | null
  external_id?: string
  gpx: string
  ground: number[] | null
  id: number
  is_favorite: boolean
  is_loop: boolean
  is_private: boolean
  kml: string
  location: null
  max_elevation: number | null
  points: MultiLineString
  route_favorite_count: number
  images: {
    fallback: string
    item: string | null
    tile: string | null
  }[]
  start: Point
  staticmaps: {
    bmx_big: string | null
    preview: string | null
    small: string | null
  } | null
  title: string
  user: {
    displayname: string
    id: number
    image: {
      fallback: string
      small: string | null
    } | null
    is_subscribed: boolean
    slug: string
  }
  /** 3rd dimension is index of control point */
  waypoints: MultiPoint | null
}

export type ChangeRouteData = {
  route: RouteEntity & RouteEntityDetails & RouteEntityMapData & RouteEntityAnalyticsData
  creator: UserEntity | null
}

/**
 * Set one or more attributes of a route.
 */
export async function changeRoute(
  routeId: number,
  form: RouteChangeForm,
  geometryForm?: RouteGeometryChangeForm,
): ApiResult<ChangeRouteData> {
  const { bikeTypes, isPrivate, title, ...changes } = form
  try {
    const body: RoutePatchBody = { ...changes }
    if (bikeTypes) {
      body.category = bikeTypes.length ? bikeTypes : [0]
    }
    if (typeof isPrivate === 'boolean') {
      body.is_private = isPrivate
    }
    if (title) {
      body.title = title.substring(0, ROUTE_TITLE_MAX_LENGTH)
    }

    if (geometryForm) {
      const { geometry, distanceMeters, durationSeconds, waypoints, controlPointIndexes, ...geometryChanges } =
        geometryForm
      Object.assign(body, {
        ...geometryChanges,
        control_point_indexes: controlPointIndexes,
        distance: distanceMeters,
        duration: durationSeconds,
        points: {
          type: 'MultiLineString',
          coordinates: geometry.coordinates.map(reduceToPosition),
        },
        time: getTimes(geometry),
        waypoints: {
          type: 'MultiPoint',
          coordinates: waypoints.map(({ lng, lat, controlPointIndex }) => [lng, lat, controlPointIndex]),
        },
      } as RoutePatchBody)
    }

    const res: Response = await patchToCoreApi(API_PATH_ROUTE, {
      params: { routeId },
      body,
      type: 'json',
    })

    try {
      const parser = new ResponseParser(res)
      const geometry = parser.requireLineString('points')
      const creator = res.user ? parseUserEntity(res.user) : null
      const locationName = parser.takeString('location')
      const geonameId = parser.takeNumber('admin_two_id')
      const distanceMeters = parser.requireNumber('distance')
      const durationSeconds = parser.takeNumber('duration')
      return createSuccessResult({
        route: {
          id: parser.requireNumber('id'),
          title: parser.requireString('title'),
          description: parser.takeString('description'),
          geometry: await enrichGeometry(geometry),
          distanceMeters,
          ascentMeters: parser.takeNumber('altitude_difference'),
          descentMeters: parser.takeNumber('altitude_difference_back'),
          durationSeconds,
          averageSpeedMetersPerSecond: durationSeconds ? distanceMeters / durationSeconds : null,
          maximumElevationMeters: parser.takeNumber('max_elevation'),
          isPrivate: parser.requireBoolean('is_private'),
          externalId: parser.takeString('external_id'),
          location: geonameId && locationName ? { geonameId, name: locationName } : null,
          favoriteCount: parser.requireNumber('route_favorite_count'),
          created: parser.requireTimestamp('created'),
          surfaces: parser.takeArray('ground', mapToSurface),
          bikeTypes: parser.takeArray('category', mapToBikeType),
          images: parser.takeAsImageSizesList('images', {
            item: 'item',
            tile: 'tile',
            original: 'fallback',
          }),
          isFavorite: parser.requireBoolean('is_favorite'),
          gpxUrl: parser.requireString('gpx'),
          kmlUrl: parser.requireString('kml'),
          hasPois: false,
          isLoop: parser.requireBoolean('is_loop'),
          bounds: parser.requireBounds('bounding_box'),
          copiedFrom: parser.takeNumber('copied_from'),
          staticMap: parser.takeImageSizesWithoutFallback('staticmaps', {
            item: 'preview',
            tile: 'bmx_big',
            tileSecondary: 'small',
            overview: 'bmx_big',
            medium: 'bmx_big',
          }),
          start: parser.requireLngLat('start'),
          creatorId: creator?.id ?? null,
          ...getWaypointsAndControlPointIndexes(geometry, res.waypoints, res.control_point_indexes),
        },
        creator,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}
