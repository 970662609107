import { WebAppContent, WebAppSheet } from 'web-app/ui-layout'
import { RouteCollectionImage } from './image/route-collection-image'
import { EditableContentProvider } from 'web-app/feature-editable-content'
import { EditableRouteCollectionHeader } from './header/route-collection-header'
import {
  MainRouteCollectionStats,
  useIsOwnRouteCollection,
  useRouteCollectionOwner,
  useRouteCollectionState,
} from 'web-app/feature-route-collection'
import { useLocale } from 'shared/util-intl'
import { EditableRouteCollectionDescription } from './description/route-collection-description'
import { EditableRouteCollectionPrivacy } from './route-collection-privacy'
import { Typography } from '@mui/material'
import { OwnerActions } from './owner/owner-actions'
import { ButtonGroup } from 'shared/ui-components'
import { DeleteRouteCollectionAction } from './delete-route-collection-action'
import { CollectionRoutesSection } from './collection-routes-section'
import { ShareRouteCollectionAction } from './share-route-collection-action'
import { RouteCollectionCategory } from 'shared/data-access-core'
import { ElevationCurveWithSkeleton } from 'shared/feature-elevation-curve'

export const RouteCollectionSheet = () => {
  const { intl } = useLocale()
  const isOwnRouteCollection = useIsOwnRouteCollection()
  const owner = useRouteCollectionOwner()
  const { routeCollection, filteredRoutesCount } = useRouteCollectionState()

  return (
    <WebAppSheet>
      <EditableContentProvider>
        <RouteCollectionImage routeCollection={routeCollection} />
        <WebAppContent>
          <EditableRouteCollectionHeader title={routeCollection?.title} />
        </WebAppContent>
        <WebAppContent>
          <MainRouteCollectionStats />
        </WebAppContent>
        {routeCollection?.category === RouteCollectionCategory.Tour && !!filteredRoutesCount && (
          <WebAppContent>
            <ElevationCurveWithSkeleton height="8rem" />
          </WebAppContent>
        )}
        {(routeCollection?.description || isOwnRouteCollection) && (
          <WebAppContent>
            <EditableRouteCollectionDescription
              heading={intl.formatMessage({
                id: 'route_collection_main_content_description_heading',
                defaultMessage: 'About this collection',
              })}
              routeCollection={routeCollection}
            />
          </WebAppContent>
        )}
        {isOwnRouteCollection && routeCollection && (
          <EditableRouteCollectionPrivacy
            heading={intl.formatMessage({
              id: 'route_collection_main_content_privacy_heading',
              defaultMessage: 'Privacy',
            })}
            routeCollection={routeCollection}
          />
        )}
        <WebAppContent>
          <ButtonGroup>
            {isOwnRouteCollection && <DeleteRouteCollectionAction />}
            <ShareRouteCollectionAction />
          </ButtonGroup>
        </WebAppContent>
        {routeCollection && (
          <WebAppContent>
            {owner && (
              <Typography variant="h4" component="h2">
                {intl.formatMessage({
                  id: 'route_collection_creator_heading',
                  defaultMessage: 'Created by',
                })}
              </Typography>
            )}
            <OwnerActions />
          </WebAppContent>
        )}
        <CollectionRoutesSection />
      </EditableContentProvider>
    </WebAppSheet>
  )
}
