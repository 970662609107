import { fuzzySearch, reverseGeocode as tomtomReverseGeocode } from '@tomtom-international/web-sdk-services/esm'
import { FuzzySearchResult } from '@tomtom-international/web-sdk-services'
import {
  ApiResult,
  ApiSuccessResult,
  MinimalEndpointErrors,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'
import { LngLat } from 'shared/util-geo'
import { logError } from 'shared/util-error-handling'

const TOMTOM_API_KEY = process.env['NX_PUBLIC_TOMTOM_API_KEY']

export type GeocoderLocation = {
  position: LngLat
  address: string
  poiName?: string
  localName?: string
  country?: string
  admin1?: string
  type: 'poi' | 'address' | 'region'
}

type CommonGeocodingErrors = MinimalEndpointErrors & {
  missingConfigError?: true
  unexpectedResponse?: true
}

/**
 * Get address details from provided point.
 */
export async function reverseGeocode(
  position: LngLat,
  language: 'en' | 'de',
): ApiResult<GeocoderLocation, CommonGeocodingErrors> {
  if (!TOMTOM_API_KEY) {
    return createFailureResult({ missingConfigError: true })
  }

  try {
    const res = await tomtomReverseGeocode({
      key: TOMTOM_API_KEY,
      language: language === 'de' ? 'de-DE' : 'en-US',
      position,
    })

    if (res['addresses']?.length) {
      const { position, address, type } = res['addresses'][0]
      return createSuccessResult({
        position,
        address: address.freeformAddress,
        localName: address.localName,
        country: address.country,
        admin1: address.countrySubdivision,
        type: type === 'POI' ? 'poi' : type === 'Geography' ? 'region' : 'address',
      })
    }

    return createFailureResult({ unexpectedResponse: true }, { position, res })
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { position })
  }
}

// TODO WEB-1017 consider universal caching
const searchForPlacesCache: Map<string, ApiSuccessResult<GeocoderLocation[]>> = new Map()

/**
 * Search for places (addresses, places, etc.).
 */
export async function searchForPlaces(
  query: string,
  language: string,
  center?: LngLat,
  limit = 4,
): ApiResult<GeocoderLocation[], CommonGeocodingErrors> {
  if (!TOMTOM_API_KEY) {
    return createFailureResult({ missingConfigError: true })
  }

  const centerString = center ? [center.lng, center.lat].join(',') : 'null'
  const key = [query, language, centerString, limit].join(';')
  const cached = searchForPlacesCache.get(key)
  if (cached) {
    return cached
  }
  try {
    const res = await fuzzySearch({
      key: TOMTOM_API_KEY,
      query,
      center,
      language,
      limit,
      typeahead: true,
    })

    if (Array.isArray(res.results)) {
      const locations = []
      for (const result of res.results) {
        const location = formatLocation(result)
        if (location) {
          locations.push(location)
        }
      }
      const result = createSuccessResult(locations)
      searchForPlacesCache.set(key, result)
      return result
    }

    return createFailureResult({ unexpectedResponse: true }, { query, res })
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { query })
  }
}

export function formatLocation(result: FuzzySearchResult): GeocoderLocation | null {
  const { position, address, poi } = result
  if (!position) {
    logError('Fuzzy search result does not contain position.', null, result)
    return null
  }

  const { lng, lat } = position
  if (lng === undefined || lat === undefined) {
    logError('Fuzzy search result position coordinates are invalid.', null, result)
    return null
  }

  if (!address) {
    logError('Fuzzy search result does not contain address object.', null, result)
    return null
  }

  const { freeformAddress, municipality, country, countrySubdivision } = address
  if (!freeformAddress && !municipality && !country) {
    logError('Fuzzy search result does not contain address info.', null, result)
    return null
  }

  const location: GeocoderLocation = {
    position: { lng, lat },
    address: freeformAddress || municipality || country || '',
    poiName: poi?.name,
    localName: address.localName,
    country,
    admin1: countrySubdivision,
    type: result.type === 'POI' ? 'poi' : result.type === 'Geography' ? 'region' : 'address',
  }
  return location
}
