import { Button, Modal } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { Typography } from '@mui/material'
import { FiltersForm } from './filters-form'
import { useDiscoverReset } from './use-discover-reset'
import { RouteSearchSorting } from 'shared/data-access-core'

import styles from './filters-modal.module.scss'

interface FiltersModalProps<ExtraSortingOptionType> {
  open: boolean
  count: number | null
  isSearching: boolean
  extraSortingOptions?: { value: ExtraSortingOptionType; label: string }[]
  defaultSorting?: RouteSearchSorting | ExtraSortingOptionType
  onClose: () => void
}

// WEB-1486 clean up scrollable modal
export function FiltersModal<ExtraSortingOptionType extends string = RouteSearchSorting>({
  open,
  count,
  isSearching,
  extraSortingOptions,
  defaultSorting,
  onClose,
}: FiltersModalProps<ExtraSortingOptionType>) {
  const { intl } = useLocale()
  const handleReset = useDiscoverReset()

  const heading = intl.formatMessage({
    id: 'discover_filters_modal_heading',
    defaultMessage: 'Sort & filter',
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-label={heading}
      width="38.75rem"
      buttons={
        <div className={styles['buttons']}>
          <Button variant="secondary" onClick={handleReset}>
            {intl.formatMessage({
              id: 'discover_filters_button_reset',
              defaultMessage: 'Clear',
            })}
          </Button>
          <Button variant="primary" onClick={onClose} disabled={!count}>
            {count
              ? intl.formatMessage(
                  {
                    id: 'discover_filters_button_submit',
                    defaultMessage: 'Show {count} routes',
                  },
                  {
                    count:
                      !isSearching &&
                      (count > 1000000
                        ? `${Math.floor(count / 1000000)}M+`
                        : count > 1000
                          ? `${Math.floor(count / 1000)}k+`
                          : count),
                  },
                )
              : intl.formatMessage({
                  id: 'discover_filters_button_submit_no_results',
                  defaultMessage: 'No routes available',
                })}
          </Button>
        </div>
      }
    >
      <header className={styles['header']}>
        <Typography variant="h3" component="h2">
          {heading}
        </Typography>
      </header>
      <div className={styles['modal']}>
        <FiltersForm extraSortingOptions={extraSortingOptions} defaultSorting={defaultSorting} onSubmit={onClose} />
      </div>
    </Modal>
  )
}
