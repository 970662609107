import { ReactNode } from 'react'
import { ToolButton, useMessages } from 'shared/ui-components'
import { BikemapBikeIcon } from 'shared/ui-design-system/icons/bikemap-bike-icon'
import { WebAppHeader, WebAppHeaderItems } from 'web-app/ui-layout'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import { Navigation } from './navigation'

export interface ToolHeaderProps {
  title: string
  children?: ReactNode
  onCancel: () => void
  onBack?: () => void
}

export const ToolHeader = ({ title, children, onCancel, onBack }: ToolHeaderProps) => {
  const { backLabel, closeLabel } = useMessages()

  return (
    <WebAppHeader>
      <WebAppHeaderItems>
        {onBack ? (
          <ToolButton
            variant="ghost-primary"
            icon={<ArrowBackIcon />}
            onClick={() => {
              onBack()
            }}
            ariaLabel={backLabel}
          />
        ) : (
          <BikemapBikeIcon color="primary" style={{ fontSize: '2rem' }} />
        )}
        <Typography variant="h3" component="h1" margin={0}>
          {title}
        </Typography>
      </WebAppHeaderItems>
      <WebAppHeaderItems>
        {children}
        <ToolButton
          variant="ghost-secondary"
          icon={<CloseIcon />}
          onClick={() => {
            onCancel()
          }}
          ariaLabel={closeLabel}
        />
      </WebAppHeaderItems>
      <Navigation largeViewportOnly />
    </WebAppHeader>
  )
}
