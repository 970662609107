import { API_PATH_ROUTE_COLLECTIONS } from '../../config'
import { RouteCollectionEntity, RouteCollectionEntityDetails, UserEntity } from '../../entities'
import { postToCoreApi } from '../../network'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'

export type RouteCollectionForm = {
  title: string
  isPrivate: boolean
}

type Response = {
  created: string
  description: string
  id: number
  should_seo_index: boolean
  title: string
  user: {
    id: number
    displayname: string
    is_subscribed: boolean
    slug: string
    image: {
      fallback: string
      small: string | null
    } | null
  }
  visibility: boolean
}

export async function createRouteCollection(
  form: RouteCollectionForm,
): ApiResult<{ collection: RouteCollectionEntity & RouteCollectionEntityDetails; owner: UserEntity }> {
  try {
    const { title, isPrivate } = form
    const res: Response = await postToCoreApi(API_PATH_ROUTE_COLLECTIONS, {
      body: {
        title,
        visibility: !isPrivate,
      },
    })

    try {
      const parser = new ResponseParser(res)

      const owner: UserEntity = {
        id: parser.in('user').requireNumber('id'),
        slug: parser.in('user').requireString('slug'),
        name: parser.in('user').requireString('displayname'),
        avatar: parser.in('user').takeImageSizes('image', {
          small: 'small',
        }),
        isPremium: parser.in('user').requireBoolean('is_subscribed'),
      }

      return createSuccessResult({
        collection: {
          category: null,
          created: parser.requireTimestamp('created'),
          description: null,
          hasPrivateRoutes: false,
          id: parser.requireNumber('id'),
          image: null,
          isPrivate: !parser.requireBoolean('visibility'),
          maximumElevationMeters: null,
          owner: owner.id,
          routesCount: 0,
          shouldBeIndexed: parser.requireBoolean('should_seo_index'),
          title: parser.requireString('title'),
          totalAscentMeters: 0,
          totalDescentMeters: 0,
          totalDistanceMeters: 0,
          totalDurationSeconds: 0,
        },
        owner,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    return createFailureResult(
      {
        unexpectedError: true,
      },
      { form },
    )
  }
}
