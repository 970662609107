import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { UserEntity } from 'shared/data-access-core'
import { Avatar } from 'shared/ui-components'
import { getUserProfileUrl } from 'shared/util-navigation'
import { useLocale } from 'shared/util-intl'
import { useSessionInfo } from 'web-app/feature-navigation'

import styles from './owner-link.module.scss'

interface RouteCollectionOwnerProps {
  creator: UserEntity
  createdTimeAgo?: string
}

export const OwnerLink = ({ creator, createdTimeAgo }: RouteCollectionOwnerProps) => {
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  const userProfileUrl = useMemo<string>(
    () =>
      getUserProfileUrl({
        slug: creator.slug,
        ...sessionInfo,
      }),
    [creator.slug, sessionInfo],
  )

  return (
    <a href={userProfileUrl} className={styles['container']}>
      <Avatar name={creator.name} image={creator.avatar?.small} isPremium={creator.isPremium} />
      <Typography fontWeight="500" className={styles['name']}>
        {creator.name}
      </Typography>
      {createdTimeAgo && (
        <Typography variant="body2" color="textSecondary">
          {intl.formatMessage(
            {
              id: 'route_details_created_time_ago',
              defaultMessage: 'uploaded {timeAgo}',
            },
            {
              timeAgo: createdTimeAgo,
            },
          )}
        </Typography>
      )}
    </a>
  )
}
