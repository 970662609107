import { RouteCollectionCategory, RouteCollectionEntity } from 'shared/data-access-core'
import { Skeleton } from '@mui/material'
import { WebAppContent } from 'web-app/ui-layout'
import { EditContentButton, useIsEditingFlags } from 'web-app/feature-editable-content'
import { useIsOwnRouteCollection } from 'web-app/feature-route-collection'
import { RouteCollectionImageUploader } from './route-collection-image-uploader'
import { TourTag } from './tour-tag'

import fallbackImageJpg from './collection-image-fallback.jpg'
import fallbackImageWebp from './collection-image-fallback.webp'
import fallbackImageAvif from './collection-image-fallback.avif'

import styles from './route-collection-image.module.css'

interface RouteCollectionImageProps {
  routeCollection?: RouteCollectionEntity
}

export const RouteCollectionImage = ({ routeCollection }: RouteCollectionImageProps) => {
  const name = 'image'
  const isOwnRouteCollection = useIsOwnRouteCollection()
  const { isEditing, isEditingAnotherField } = useIsEditingFlags(name)

  const isUploaderShown = isOwnRouteCollection && isEditing

  return (
    <WebAppContent noPadding={!isUploaderShown}>
      {!routeCollection ? (
        <Skeleton variant="rectangular" className={styles['image']} />
      ) : (
        <div className={styles['container']}>
          {routeCollection.image && !isEditing && (
            <img
              className={styles['image']}
              src={routeCollection.image.tile}
              alt={routeCollection.title}
              width={376}
              height={200}
            />
          )}
          {!routeCollection.image && !isEditing && (
            <picture>
              <source srcSet={fallbackImageAvif} type="image/avif" />
              <source srcSet={fallbackImageWebp} type="image/webp" />
              <source srcSet={fallbackImageJpg} type="image/jpeg" />
              <img className={styles['image']} src={fallbackImageJpg} alt={routeCollection.title} />
            </picture>
          )}
          {isUploaderShown && <RouteCollectionImageUploader currentImage={routeCollection.image} />}
          {isOwnRouteCollection && !isEditing && !isEditingAnotherField && (
            <div className={styles['edit-button-holder']}>
              <EditContentButton name={name} />
            </div>
          )}
          {routeCollection.category === RouteCollectionCategory.Tour && !isUploaderShown && (
            <TourTag className={styles['tour-tag']} />
          )}
        </div>
      )}
    </WebAppContent>
  )
}
