import { MapTourRoutes } from 'shared/ui-map'
import { MAP_ID } from 'web-app/feature-map'
import {
  RouteCollectionSliceDispatch,
  routeSelectionCleared,
  selectRoute,
  useRouteCollectionGeometries,
  useRouteCollectionState,
} from '../state'
import { useDispatch } from 'react-redux'

export const TourMapRoutes = () => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { selectedRouteId } = useRouteCollectionState()
  const geometries = useRouteCollectionGeometries()

  return (
    geometries && (
      <MapTourRoutes
        id="tour-routes"
        mapId={MAP_ID}
        routes={geometries}
        selectedRouteId={selectedRouteId}
        onClick={(routeId) => dispatch(selectRoute(routeId))}
        onClickOutside={() => selectedRouteId !== null && dispatch(routeSelectionCleared())}
      />
    )
  )
}
