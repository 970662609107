import { LANGUAGE_URL_PARAM, UNIT_PREFERENCE_URL_PARAM, addTrackingParameters } from './params'
import {
  WEB_APP_PATH_COLLECTION_ROUTE_DETAILS,
  WEB_APP_PATH_REGION,
  WEB_APP_PATH_ROUTE_COLLECTION,
  WEB_APP_PATH_ROUTE_DETAILS,
  WEB_APP_PATH_ROUTE_EDIT,
} from './paths'
import { BaseUrlArgs } from './types'

const WEB_APP_BASE_URL = process.env['NX_PUBLIC_WEB_APP_URL']

export function getWebAppParams({
  language,
  unitPreference,
  cookieConsentStatistics,
  trackingParameters,
}: BaseUrlArgs): string {
  const params = new URLSearchParams({
    [LANGUAGE_URL_PARAM]: language,
    [UNIT_PREFERENCE_URL_PARAM]: unitPreference,
  })

  if (cookieConsentStatistics !== null) {
    params.set('cookieConsentStatistics', cookieConsentStatistics ? '1' : '0')
  }

  if (trackingParameters) {
    addTrackingParameters(trackingParameters, params)
  }

  return params.toString()
}

export function getRouteDetailsPath(routeId: number): string {
  return WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', routeId.toString())
}

export function getRouteDetailsUrl(routeId: number): string {
  return WEB_APP_BASE_URL + getRouteDetailsPath(routeId)
}

export function getRouteEditPath(routeId: number): string {
  return WEB_APP_PATH_ROUTE_EDIT.replace(':routeId', routeId.toString())
}

export function getRegionPath(geonameId: number): string {
  return WEB_APP_PATH_REGION.replace(':geonameId', geonameId.toString())
}

export function getRouteCollectionPath(routeCollectionId: number): string {
  return WEB_APP_PATH_ROUTE_COLLECTION.replace(':routeCollectionId', routeCollectionId.toString())
}

export function getRouteCollectionUrl(routeCollectionId: number): string {
  return WEB_APP_BASE_URL + getRouteCollectionPath(routeCollectionId)
}

export function getCollectionRouteDetailsPath(routeId: number, routeCollectionId: number): string {
  return WEB_APP_PATH_COLLECTION_ROUTE_DETAILS.replace(':routeCollectionId', routeCollectionId.toString()).replace(
    ':routeId',
    routeId.toString(),
  )
}
