/**
 * Provides conversion functions between a normal scale that can be used as slider value attribute and the actual
 * value, which increases faster above a certain threshold.
 * @param normalScaleMax Threshold until which scale and value are the same
 * @param increasedScaleMax Maximum value on the normal scale
 * @param maxValue Maximum actual value (represented by `increasedScaleMax` on the scale)
 */
export const useRangeScaleConversion = (
  normalScaleMax: number,
  increasedScaleMax: number,
  maxValue: number,
): {
  convertValueToScale: (value: number) => number
  convertScaleToValue: (scale: number) => number
} => {
  const increasedScaleFactor = (maxValue - normalScaleMax) / (increasedScaleMax - normalScaleMax)
  return {
    convertValueToScale: (value: number) =>
      value > normalScaleMax
        ? value < maxValue
          ? normalScaleMax + (value - normalScaleMax) / increasedScaleFactor
          : increasedScaleMax
        : value,
    convertScaleToValue: (scale: number) =>
      scale > normalScaleMax
        ? scale < increasedScaleMax
          ? normalScaleMax + (scale - normalScaleMax) * increasedScaleFactor
          : maxValue
        : scale,
  }
}
