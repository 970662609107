import { ChangeEvent, ReactElement } from 'react'
import { Input, InputProps } from 'shared/ui-components'
import { EditableContent, EditableContentProps } from './editable-content'
import { useValidation } from './use-validation'

interface InputFormProps extends Omit<InputProps, 'onChange'> {
  label: string
  placeholder?: string
  isRequired?: boolean
  buttons: ReactElement
  onChange: (value: string, isValid: boolean) => void
  onValidation?: (value: string) => string | null
}

const InputForm = ({
  label,
  placeholder,
  isRequired,
  buttons,
  onChange,
  onValidation,
  ...inputProps
}: InputFormProps) => {
  const { error, validate } = useValidation(label, onValidation, { isRequired })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange(value, validate(value))
  }

  const handleReset = () => {
    const value = ''
    onChange(value, validate(value))
  }

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Input
        {...inputProps}
        label={label}
        autoFocus
        isRequired={isRequired}
        placeholder={placeholder}
        error={error || undefined}
        onChange={handleChange}
        onReset={handleReset}
      />
      {buttons}
    </form>
  )
}

interface EditableStringProps extends Omit<EditableContentProps<string>, 'renderForm'> {
  label: string
  placeholder?: string
  isRequired?: boolean
  onValidation?: (value: string) => string | null
}

export const EditableString = ({ label, placeholder, isRequired, onValidation, ...props }: EditableStringProps) => (
  <EditableContent
    {...props}
    renderForm={(props) => (
      <InputForm
        {...props}
        label={label}
        placeholder={placeholder}
        isRequired={isRequired}
        onValidation={onValidation}
      />
    )}
  />
)
