import {
  CollectionRoutesSorting,
  EntityIndex,
  FilterRange,
  RouteBikeType,
  RouteCollectionEntity,
  RouteCollectionEntityDetails,
  RouteEntity,
  RouteSurface,
  UserEntity,
} from 'shared/data-access-core'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { StateWithUserSlice } from 'web-app/feature-user'
import { StateWithIntlSlice } from 'shared/util-intl'
import { StateWithEntitiesSlice } from 'web-app/data-access-entities'
import { RichLineString } from 'shared/util-geo'

export const ROUTE_COLLECTION_SLICE_KEY = 'routeCollection'

export type RouteCollectionState = {
  /** When this is false, the route collection state is either empty or cached and can be outdated / inconsistent */
  isRouteCollectionLoaded: boolean

  /** Whether the route collection is either private or can't be accessed for another reason */
  isRouteCollectionUnavailable: boolean

  /** The main route collection entity of the app's global state */
  routeCollection?: RouteCollectionEntity & RouteCollectionEntityDetails

  /** IDs of routes assigned to the main route collection entity of the app's global state */
  assignedRouteIds?: number[]

  /** IDs of routes assigned to the main route collection, sorted in the detected tour order (if available) */
  routeIdsInTourOrder?: number[] | null

  /** Number of assigned routes that match the current filters */
  filteredRoutesCount?: number

  /** True while a higher page of route results is being fetched */
  isLoadingMoreRoutes: boolean

  /** Geometries of assigned routes to preview */
  geometries: EntityIndex<RichLineString>

  /** ID of a selected route to preview */
  selectedRouteId: number | null
}

export type AssignedRoutesListItemState = {
  key: string
  route: RouteEntity
  creator?: UserEntity
}

export interface StateWithRouteCollectionSlice {
  [ROUTE_COLLECTION_SLICE_KEY]: RouteCollectionState
}

export type RouteCollectionSliceDispatch = ThunkDispatch<
  StateWithRouteCollectionSlice & StateWithUserSlice & StateWithIntlSlice & StateWithEntitiesSlice,
  undefined,
  UnknownAction
>

export type CollectionRoutesParams = {
  sorting: CollectionRoutesSorting
  onlyLoops: boolean
  distanceKilometers: FilterRange
  ascentMeters: FilterRange
  title: string
  bikeTypes: RouteBikeType[]
  surfaces: RouteSurface[]
}
