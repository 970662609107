import QRCode from 'react-qr-code'
import { useRoutePrintState } from '../state'
import { useLocale } from 'shared/util-intl'
import { getNavigationDynamicLink } from 'shared/util-navigation'

import styles from './print-app-section.module.css'

export const PrintAppSection = () => {
  const { intl } = useLocale()
  const { forRouteId } = useRoutePrintState()

  return (
    <div className={styles['container']}>
      <p>
        {intl.formatMessage({
          id: 'route_print_app_label',
          defaultMessage: 'Navigate with Bikemap',
        })}
      </p>
      {forRouteId && (
        <QRCode
          value={getNavigationDynamicLink(forRouteId, {
            utmSource: 'print',
            utmMedium: 'route',
            utmCampaign: 'navigate_qr',
          })}
          style={{ width: '100%', height: 'auto', display: 'block' }}
        />
      )}
    </div>
  )
}
