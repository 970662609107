import { Button } from 'shared/ui-components'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import { useLocale } from 'shared/util-intl'
import { useState } from 'react'
import { FiltersModal } from './filters-modal'
import { useDiscoverFiltersCount } from './use-discover-filters-count'
import { RouteSearchSorting } from 'shared/data-access-core'

interface DiscoverFiltersProps<ExtraSortingOptionType> {
  size?: 'default' | 'medium' | 'small'
  children?: string
  count: number | null
  isSearching: boolean
  extraSortingOptions?: { value: ExtraSortingOptionType; label: string }[]
  defaultSorting?: RouteSearchSorting | ExtraSortingOptionType
}

export function DiscoverFilters<ExtraSortingOptionType extends string = RouteSearchSorting>({
  size,
  children,
  count,
  isSearching,
  extraSortingOptions,
  defaultSorting,
}: DiscoverFiltersProps<ExtraSortingOptionType>) {
  const { intl } = useLocale()
  const filtersCount = useDiscoverFiltersCount(defaultSorting)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant="secondary"
        size={size}
        icon={<TuneRoundedIcon />}
        ariaLabel={intl.formatMessage({
          id: 'discover_filters_button',
          defaultMessage: 'Filter routes',
        })}
        onClick={() => setIsModalOpen(true)}
      >
        {filtersCount > 0 ? filtersCount.toString() : children}
      </Button>
      <FiltersModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        count={count}
        isSearching={isSearching}
        extraSortingOptions={extraSortingOptions}
        defaultSorting={defaultSorting}
      />
    </>
  )
}
