import { RouteStatistics, RouteStatisticsItem } from 'shared/ui-components'
import { SpeedIcon } from 'shared/ui-design-system/icons/speed-icon'
import { RoutesIcon } from 'shared/ui-design-system/icons/routes-icon'
import { DurationIcon } from 'shared/ui-design-system/icons/duration-icon'
import { DescentIcon } from 'shared/ui-design-system/icons/descent-icon'
import { DistanceIcon } from 'shared/ui-design-system/icons/distance-icon'
import { useUserState } from 'web-app/feature-user'
import { useRouteMessages, useRouteStatsFormatters } from 'shared/feature-routes'
import { AscentIcon } from 'shared/ui-design-system/icons/ascent-icon'
import { useLocale } from 'shared/util-intl'
import { AltitudeIcon } from 'shared/ui-design-system/icons/altitude-icon'

export interface RouteCollectionStatsProps {
  routesCount?: number
  durationSeconds?: number | null
  distanceMeters?: number | null
  ascentMeters?: number | null
  descentMeters?: number | null
  averageSpeedMetersPerSecond?: number | null
  maxAltitudeMeters?: number | null
  isTour: boolean
}

/**
 * Shows detailed route collection stats with fallbacks.
 */
export const RouteCollectionStats = ({
  routesCount,
  durationSeconds,
  distanceMeters,
  ascentMeters,
  descentMeters,
  averageSpeedMetersPerSecond,
  maxAltitudeMeters,
  isTour,
}: RouteCollectionStatsProps) => {
  const { intl } = useLocale()
  const { durationLabel, distanceLabel, averageSpeedLabel, ascentLabel, descentLabel, maxAltitudeLabel } =
    useRouteMessages()
  const { unitPreference } = useUserState()
  const { formatRouteDuration, formatRouteDistance, formatRouteSpeed, formatRouteElevation } = useRouteStatsFormatters(
    unitPreference === 'imperial',
  )

  return (
    <RouteStatistics columns={3}>
      <RouteStatisticsItem
        icon={<RoutesIcon />}
        value={routesCount !== undefined ? routesCount.toString() : undefined}
        label={intl.formatMessage({
          id: 'route_collection_stats_label_routes_count',
          defaultMessage: 'Routes',
        })}
      />
      <RouteStatisticsItem
        icon={<DurationIcon />}
        value={durationSeconds !== undefined ? formatRouteDuration(durationSeconds) : undefined}
        label={durationLabel}
      />
      <RouteStatisticsItem
        icon={<DistanceIcon />}
        value={distanceMeters !== undefined ? formatRouteDistance(distanceMeters) : undefined}
        label={distanceLabel}
      />
      {isTour && (
        <RouteStatisticsItem
          icon={<SpeedIcon />}
          value={averageSpeedMetersPerSecond !== undefined ? formatRouteSpeed(averageSpeedMetersPerSecond) : undefined}
          label={averageSpeedLabel}
        />
      )}
      <RouteStatisticsItem
        icon={<AscentIcon />}
        value={ascentMeters !== undefined ? formatRouteElevation(ascentMeters) : undefined}
        label={ascentLabel}
      />
      <RouteStatisticsItem
        icon={<DescentIcon />}
        value={descentMeters !== undefined ? formatRouteElevation(descentMeters) : undefined}
        label={descentLabel}
      />
      {!isTour && (
        <RouteStatisticsItem
          icon={<AltitudeIcon />}
          value={maxAltitudeMeters !== undefined ? formatRouteElevation(maxAltitudeMeters) : undefined}
          label={maxAltitudeLabel}
        />
      )}
    </RouteStatistics>
  )
}
