import { ENTITIES_SLICE_KEY, StateWithEntitiesSlice } from 'web-app/data-access-entities'
import { StateWithUserSlice, USER_SLICE_KEY, UserState } from './state'
import { RouteCollectionEntity, getFromIndex } from 'shared/data-access-core'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

export const userSliceSelector = (state: StateWithUserSlice): UserState => state[USER_SLICE_KEY]

export const useUserState = () => useSelector(userSliceSelector)

export const userRouteCollectionsSelector: (
  state: StateWithUserSlice & StateWithEntitiesSlice,
) => RouteCollectionEntity[] | undefined = createSelector(
  (state: StateWithUserSlice) => state[USER_SLICE_KEY].routeCollectionIds,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routeCollections,
  (routeCollectionIds, routeCollections) => routeCollectionIds && getFromIndex(routeCollectionIds, routeCollections),
)

export const useUserRouteCollections = () => useSelector(userRouteCollectionsSelector)
