import { LocationList } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { PlaceResultItem } from './place-result-item'
import { Typography } from '@mui/material'
import { RESULTS_LIST_LENGTH } from './definitions'
import { SearchResult } from './types'
import { RegionResultItem } from './region-result-item'
import { RouteResultItem } from './route-result-item'

interface SearchResultsListProps {
  results: SearchResult[] | null
  loading?: boolean
  onSelect: (result: SearchResult) => void
}

export const SearchResultsList = ({ results, onSelect }: SearchResultsListProps) => {
  const { intl } = useLocale()

  return (
    <LocationList.List
      title={intl.formatMessage({
        id: 'search_results_list_title',
        defaultMessage: 'Suggestions',
      })}
      secondary
    >
      {results ? (
        results.length > 0 ? (
          results.map((result, index) =>
            result.type === 'place' ? (
              <PlaceResultItem key={`search-result-item-${index}`} place={result.data} onSelect={onSelect} />
            ) : result.type === 'region' ? (
              <RegionResultItem key={`search-result-item-${index}`} region={result.data} onSelect={onSelect} />
            ) : (
              <RouteResultItem key={`search-result-item-${index}`} route={result.data} onSelect={onSelect} />
            ),
          )
        ) : (
          <Typography>
            {intl.formatMessage({
              id: 'search_results_list_no_results',
              defaultMessage: 'No results found.',
            })}
          </Typography>
        )
      ) : (
        [...Array(RESULTS_LIST_LENGTH)].map((_, i) => <LocationList.LoadingItem key={i} />)
      )}
    </LocationList.List>
  )
}
