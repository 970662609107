import { Checkbox, FieldSet } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useDiscoverOnlyLoops } from '../use-discover-only-loops'
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'

import styles from './only-loops-field.module.scss'

export const OnlyLoopsField = () => {
  const { intl } = useLocale()
  const [onlyLoops, setOnlyLoops] = useDiscoverOnlyLoops()

  return (
    <FieldSet
      bordered
      label={intl.formatMessage({
        id: 'discover_filter_set_route_type',
        defaultMessage: 'Route type',
      })}
    >
      <Checkbox name="discover-only-loops" checked={onlyLoops} onChange={(e) => setOnlyLoops(e.target.checked)}>
        {intl.formatMessage({
          id: 'discover_filter_only_loops_label',
          defaultMessage: 'Only round trips',
        })}
        <SettingsBackupRestoreRoundedIcon className={styles['round-trip-icon']} />
      </Checkbox>
    </FieldSet>
  )
}
