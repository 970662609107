import { useMap } from 'react-map-gl/maplibre'
import { findBeforeIdBehindSymbols } from '../helpers'
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

export const useRouteLayerBeforeId = (): string | undefined => {
  const { current: map } = useMap()

  const [routeLayerBeforeId, setRouteLayerBeforeId] = useState<string | undefined>(
    map && findBeforeIdBehindSymbols(map),
  )

  useEffect(() => {
    const tryToFindBeforeIdAgain = debounce(() => {
      setRouteLayerBeforeId(map && findBeforeIdBehindSymbols(map))
    }, 50)
    map?.on('data', tryToFindBeforeIdAgain)
    map?.on('zoom', tryToFindBeforeIdAgain)
    return () => {
      map?.off('data', tryToFindBeforeIdAgain)
      map?.off('zoom', tryToFindBeforeIdAgain)
      tryToFindBeforeIdAgain.flush()
    }
  }, [map])

  return routeLayerBeforeId
}
