import { RouteStatistics, RouteStatisticsItem } from 'shared/ui-components'
import { SpeedIcon } from 'shared/ui-design-system/icons/speed-icon'
import { DurationIcon } from 'shared/ui-design-system/icons/duration-icon'
import { DescentIcon } from 'shared/ui-design-system/icons/descent-icon'
import { DistanceIcon } from 'shared/ui-design-system/icons/distance-icon'
import { useUserState } from 'web-app/feature-user'
import { useRouteMessages, useRouteStatsFormatters } from 'shared/feature-routes'
import { AltitudeIcon } from 'shared/ui-design-system/icons/altitude-icon'
import { AscentIcon } from 'shared/ui-design-system/icons/ascent-icon'

type RouteStat =
  | 'durationSeconds'
  | 'distanceMeters'
  | 'ascentInM'
  | 'descentInM'
  | 'averageSpeedMetersPerSecond'
  | 'maxAltitudeInM'

export interface RouteStatsProps {
  extended?: boolean
  durationSeconds?: number | null
  distanceMeters?: number
  ascentInM?: number | null
  descentInM?: number | null
  averageSpeedMetersPerSecond?: number | null
  maxAltitudeInM?: number | null
  overrides?: RouteStat[]
}

/**
 * Shows detailed route stats with fallbacks.
 */
export const RouteStats = ({
  extended,
  durationSeconds,
  distanceMeters,
  ascentInM,
  descentInM,
  averageSpeedMetersPerSecond,
  maxAltitudeInM,
  overrides,
}: RouteStatsProps) => {
  const { durationLabel, distanceLabel, averageSpeedLabel, ascentLabel, descentLabel, maxAltitudeLabel } =
    useRouteMessages()
  const { unitPreference } = useUserState()
  const { formatRouteDuration, formatRouteDistance, formatRouteSpeed, formatRouteElevation } = useRouteStatsFormatters(
    unitPreference === 'imperial',
  )

  return (
    <RouteStatistics columns={extended ? 3 : 4}>
      <RouteStatisticsItem
        icon={<DurationIcon />}
        value={durationSeconds !== undefined ? formatRouteDuration(durationSeconds) : undefined}
        label={durationLabel}
        withChangeIndicator={overrides?.includes('durationSeconds')}
      />
      <RouteStatisticsItem
        icon={<DistanceIcon />}
        value={distanceMeters !== undefined ? formatRouteDistance(distanceMeters) : undefined}
        label={distanceLabel}
        withChangeIndicator={overrides?.includes('distanceMeters')}
      />
      {extended && (
        <RouteStatisticsItem
          icon={<SpeedIcon />}
          value={averageSpeedMetersPerSecond !== undefined ? formatRouteSpeed(averageSpeedMetersPerSecond) : undefined}
          label={averageSpeedLabel}
          withChangeIndicator={overrides?.includes('averageSpeedMetersPerSecond')}
        />
      )}
      <RouteStatisticsItem
        icon={<AscentIcon />}
        value={ascentInM !== undefined ? formatRouteElevation(ascentInM) : undefined}
        label={ascentLabel}
        withChangeIndicator={overrides?.includes('ascentInM')}
      />
      <RouteStatisticsItem
        icon={<DescentIcon />}
        value={descentInM !== undefined ? formatRouteElevation(descentInM) : undefined}
        label={descentLabel}
        withChangeIndicator={overrides?.includes('descentInM')}
      />
      {extended && (
        <RouteStatisticsItem
          icon={<AltitudeIcon />}
          value={maxAltitudeInM !== undefined ? formatRouteElevation(maxAltitudeInM) : undefined}
          label={maxAltitudeLabel}
          withChangeIndicator={overrides?.includes('maxAltitudeInM')}
        />
      )}
    </RouteStatistics>
  )
}
