import { useCallback, useMemo, useRef, useState } from 'react'
import {
  calculateFreehandEndSegment,
  calculateFreehandMiddleSegments,
  calculateFreehandStartSegment,
  splitLineAtPoints,
} from '../../state/helpers'
import { LngLat } from 'shared/ui-map'
import { useControlPoints, useRoutePlannerState } from '../../state'
import { getSegmentsAffectedByControlPoint, getSegmentsAffectedByWaypoint } from './helpers'
import { useLocale } from 'shared/util-intl'
import { LngLatElevation, RichMultiLineString, RichPosition, lngLatToPosition2d } from 'shared/util-geo'
import { throttle } from 'lodash'
import { logError } from 'web-app/utils-error-handling'
import calculateDistance from '@turf/distance'
import { CalculatedRoute, fetchCalculatedRoute } from 'shared/data-access-routing'

const MIN_PREVIEW_THROTTLING_MILLISECONDS = 150
const PREVIEW_THROTTLING_FACTOR = 8

type PreviewArgs = {
  originalPoint: LngLatElevation | null
  pointBefore: LngLatElevation | null
  pointAfter: LngLatElevation | null
  segmentIndexes: number[] | null
}

function composeRequestWaypoints(draggedPoint: LngLat, previewArgs: PreviewArgs): LngLat[] {
  const requestWaypoints: LngLat[] = [draggedPoint]
  if (previewArgs.pointBefore) {
    requestWaypoints.unshift(previewArgs.pointBefore)
  }
  if (previewArgs.pointAfter) {
    requestWaypoints.push(previewArgs.pointAfter)
  }
  return requestWaypoints
}

export const useRoutePreview = () => {
  const { language } = useLocale()
  const { routingMode, routingProfile, routingLevel, waypoints, geometry } = useRoutePlannerState()
  const controlPoints = useControlPoints()

  /** Record of line coordinates by segment index */
  const [previewSegments, setPreviewSegments] = useState<Record<number, RichPosition[][]> | null>(null)

  const handleSegmentsPreview = useCallback((routingRouteData: CalculatedRoute, segmentIndexes: number[] | null) => {
    const segments = splitLineAtPoints(routingRouteData.geometry, routingRouteData.snappedWaypoints)
    const previewSegments: Record<number, RichPosition[][]> = {}
    if (segmentIndexes?.length === routingRouteData.snappedWaypoints.length - 1) {
      segmentIndexes.forEach((segmentIndex, i) => {
        previewSegments[segmentIndex] = [segments.coordinates[i]]
      })
    } else if (segmentIndexes?.length === 1) {
      previewSegments[segmentIndexes[0]] = segments.coordinates
    }
    setPreviewSegments(previewSegments)
  }, [])

  const handleFreehandSegmentsPreview = useCallback(
    (draggedPoint: LngLat, { segmentIndexes, pointBefore, pointAfter }: PreviewArgs) => {
      if (pointBefore && pointAfter) {
        if (segmentIndexes?.length === 2) {
          const freehandSegments = calculateFreehandMiddleSegments(pointBefore, draggedPoint, pointAfter)
          setPreviewSegments({
            [segmentIndexes[0]]: [freehandSegments[0]],
            [segmentIndexes[1]]: [freehandSegments[1]],
          })
        } else if (segmentIndexes?.length === 1) {
          setPreviewSegments({
            [segmentIndexes[0]]: calculateFreehandMiddleSegments(pointBefore, draggedPoint, pointAfter),
          })
        }
      } else if (pointAfter && segmentIndexes?.length === 1) {
        setPreviewSegments({
          [segmentIndexes[0]]: [calculateFreehandStartSegment(draggedPoint, pointAfter)],
        })
      } else if (pointBefore && segmentIndexes?.length === 1) {
        setPreviewSegments({
          [segmentIndexes[0]]: [calculateFreehandEndSegment(pointBefore, draggedPoint)],
        })
      }
    },
    [],
  )

  const [previewArgs, setPreviewArgs] = useState<PreviewArgs | null>(null)

  const previewCalculationThrottling = useMemo<number>(() => {
    if (!previewArgs) return 0
    const { originalPoint, pointBefore, pointAfter } = previewArgs
    let originalDistance = 0
    if (originalPoint) {
      if (pointBefore) {
        originalDistance = calculateDistance(lngLatToPosition2d(pointBefore), lngLatToPosition2d(originalPoint))
      }
      if (pointAfter) {
        originalDistance += calculateDistance(lngLatToPosition2d(originalPoint), lngLatToPosition2d(pointAfter))
      }
    } else if (pointBefore && pointAfter) {
      originalDistance = calculateDistance(lngLatToPosition2d(pointBefore), lngLatToPosition2d(pointAfter))
    }
    return Math.max(MIN_PREVIEW_THROTTLING_MILLISECONDS, originalDistance * PREVIEW_THROTTLING_FACTOR)
  }, [previewArgs])

  const requestCounter = useRef<number>(0)

  const [calculatePreview, cancelPreviewCalculation] = useMemo<
    [calculatePreview: (draggedPoint: LngLat) => void, cancelPreviewCalculation: () => void]
  >(() => {
    if (!previewArgs) return [() => logError('Missing preview args.'), () => {}]

    if (routingMode === 'freehand') {
      return [
        (draggedPoint: LngLat) => {
          handleFreehandSegmentsPreview(draggedPoint, previewArgs)
        },
        () => {},
      ]
    }

    const calculatePreview = throttle(
      async (draggedPoint: LngLat) => {
        const requestNumber = ++requestCounter.current
        const res = await fetchCalculatedRoute(
          composeRequestWaypoints(draggedPoint, previewArgs),
          language,
          routingProfile,
          routingLevel,
        )
        if (requestCounter.current !== requestNumber) return
        if (res.success) {
          handleSegmentsPreview(res.data, previewArgs.segmentIndexes)
        } else {
          setPreviewSegments(null)
        }
      },
      previewCalculationThrottling,
      { leading: true },
    )
    const cancelPreviewCalculation = () => {
      calculatePreview.cancel()
    }
    return [calculatePreview, cancelPreviewCalculation]
  }, [
    handleFreehandSegmentsPreview,
    handleSegmentsPreview,
    language,
    previewArgs,
    previewCalculationThrottling,
    routingLevel,
    routingMode,
    routingProfile,
  ])

  const calculateWaypointDragPreview = useCallback(
    async (waypointIndex: number, lngLat: LngLat) => {
      if (!previewArgs) {
        const controlPointIndex = waypoints[waypointIndex]?.controlPointIndex
        if (typeof controlPointIndex !== 'number' || !controlPoints || controlPoints.length < 2) return

        setPreviewArgs({
          originalPoint: controlPoints[controlPointIndex],
          pointBefore: controlPointIndex > 0 ? controlPoints[controlPointIndex - 1] : null,
          pointAfter: controlPointIndex < controlPoints.length - 1 ? controlPoints[controlPointIndex + 1] : null,
          segmentIndexes: getSegmentsAffectedByWaypoint(waypoints, controlPoints, waypointIndex),
        })
      } else {
        calculatePreview(lngLat)
      }
    },
    [previewArgs, calculatePreview, waypoints, controlPoints],
  )

  const calculateControlPointDragPreview = useCallback(
    async (controlPointIndex: number, lngLat: LngLat) => {
      if (!previewArgs) {
        if (!controlPoints || controlPoints.length < 2) return

        setPreviewArgs({
          originalPoint: controlPoints[controlPointIndex],
          pointBefore: controlPointIndex > 0 ? controlPoints[controlPointIndex - 1] : null,
          pointAfter: controlPointIndex < controlPoints.length - 1 ? controlPoints[controlPointIndex + 1] : null,
          segmentIndexes: getSegmentsAffectedByControlPoint(controlPoints, controlPointIndex),
        })
      } else {
        calculatePreview(lngLat)
      }
    },
    [previewArgs, controlPoints, calculatePreview],
  )

  const calculateSegmentDragPreview = useCallback(
    async (segmentIndex: number, lngLat: LngLat) => {
      if (!previewArgs) {
        if (!controlPoints || segmentIndex < 0 || segmentIndex > controlPoints.length - 2) return

        setPreviewArgs({
          originalPoint: null,
          pointBefore: controlPoints[segmentIndex],
          pointAfter: controlPoints[segmentIndex + 1],
          segmentIndexes: [segmentIndex],
        })
      } else {
        calculatePreview(lngLat)
      }
    },
    [controlPoints, previewArgs, calculatePreview],
  )

  const resetPreview = () => {
    setPreviewArgs(null)
    setPreviewSegments(null)
  }

  /** Geometry including preview segments */
  const mapRouteGeometry = useMemo<RichMultiLineString | undefined>(
    () =>
      previewSegments && geometry
        ? {
            type: 'RichMultiLineString',
            coordinates: geometry.coordinates.reduce((coordinates: RichPosition[][], segment: RichPosition[], i) => {
              if (previewSegments[i]) {
                coordinates.push(...previewSegments[i])
              } else {
                coordinates.push(segment)
              }
              return coordinates
            }, [] as RichPosition[][]),
          }
        : geometry || undefined,
    [geometry, previewSegments],
  )

  /** Preview segments or combination of potentially affected segments from hovering and selection */
  const highlightedPreviewSegments = useMemo<number[] | null>(() => {
    if (!previewSegments) return null
    const highlightedPreviewSegments: number[] = []
    for (const originalSegmentIndex in previewSegments) {
      for (let i = 0; i < previewSegments[originalSegmentIndex].length; i++) {
        highlightedPreviewSegments.push(Number.parseInt(originalSegmentIndex) + i)
      }
    }
    return highlightedPreviewSegments
  }, [previewSegments])

  return {
    calculateWaypointDragPreview,
    calculateControlPointDragPreview,
    calculateSegmentDragPreview,
    cancelPreviewCalculation,
    resetPreview,
    mapRouteGeometry,
    highlightedPreviewSegments,
  }
}
