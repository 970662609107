import { NavigationControl } from 'shared/ui-map'
import { WebAppControls, useBreakpoints } from 'web-app/ui-layout'
import { GeolocateControl } from './controls/geolocate-control'
import { MapStyleControl } from './controls/map-style-control'
import { MAP_ID } from './settings'

export const WebAppMapControls = () => {
  const { layoutBreakpoint } = useBreakpoints()

  return (
    <WebAppControls>
      <NavigationControl mapId={MAP_ID} />
      <GeolocateControl />
      <MapStyleControl small={!layoutBreakpoint} />
    </WebAppControls>
  )
}
