import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { API_URL_GET_DEFAULT_MAP_STYLE, MapStyleEntity } from 'shared/data-access-core'
import { MapViewport, MapViewportChanges } from 'shared/ui-map'
import { DEFAULT_VIEWPORT } from '../settings'
import { LngLatBoundsArray } from 'shared/util-geo'

export const MAP_SLICE_KEY = 'map'

export interface MapState {
  viewport: MapViewport
  mapStyles: MapStyleEntity[] | null
  mapStyle: string
  isGlobalHeatmapEnabled: boolean
  isGlobalHeatmapSupported: boolean

  /** While set, the map is trying to move the viewport to the given specifications */
  desiredViewport: MapViewportChanges | null

  /** While set, the map is trying to fit the viewport to this bounding box */
  desiredBounds: LngLatBoundsArray | null
}

export type StateWithMapSlice = {
  [MAP_SLICE_KEY]: MapState
}

export const initialState: MapState = {
  viewport: DEFAULT_VIEWPORT,
  mapStyles: null,
  mapStyle: API_URL_GET_DEFAULT_MAP_STYLE,
  isGlobalHeatmapEnabled: false,
  isGlobalHeatmapSupported: false,
  desiredBounds: null,
  desiredViewport: null,
}

const slice = createSlice({
  name: MAP_SLICE_KEY,
  initialState,
  reducers: {
    viewportChanged(state, action: PayloadAction<MapViewportChanges>) {
      state.viewport = {
        ...state.viewport,
        ...action.payload,
      }
    },
    mapStyleChanged(state, action: PayloadAction<string>) {
      state.mapStyle = action.payload
    },
    mapStylesLoaded(state, action: PayloadAction<MapStyleEntity[]>) {
      state.mapStyles = action.payload
    },
    globalHeatmapEnabled(state) {
      state.isGlobalHeatmapEnabled = true
    },
    globalHeatmapDisabled(state) {
      state.isGlobalHeatmapEnabled = false
    },
    globalHeatmapSupportDetected(state, action: PayloadAction<boolean>) {
      state.isGlobalHeatmapSupported = action.payload
    },
    viewportDesired(state, action: PayloadAction<MapViewportChanges>) {
      state.desiredBounds = null
      state.desiredViewport = action.payload
    },
    viewportMatched(state, action: PayloadAction<string>) {
      if (state.desiredViewport && action.payload === JSON.stringify(state.desiredViewport)) {
        state.desiredViewport = null
      }
    },
    boundsDesired(state, action: PayloadAction<LngLatBoundsArray>) {
      state.desiredViewport = null
      state.desiredBounds = action.payload
    },
    boundsFit(state, action: PayloadAction<string>) {
      if (state.desiredBounds && action.payload === state.desiredBounds.toString()) {
        state.desiredBounds = null
      }
    },
  },
})

export const {
  viewportChanged,
  mapStyleChanged,
  mapStylesLoaded,
  globalHeatmapEnabled,
  globalHeatmapDisabled,
  globalHeatmapSupportDetected,
  boundsDesired,
  boundsFit,
  viewportDesired,
  viewportMatched,
} = slice.actions

const persistConfig: PersistConfig<MapState> = {
  key: MAP_SLICE_KEY,
  storage,
  whitelist: ['viewport', 'mapStyle', 'isGlobalHeatmapEnabled', 'isGlobalHeatmapSupported'],
}

export const mapReducer = persistReducer(persistConfig, slice.reducer)
