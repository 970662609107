import clsx from 'clsx'
import React from 'react'

import styles from './Control.module.scss'

export const Control = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={clsx(className, styles['root'])} {...rest}>
    {children}
  </div>
)
