import { Select } from 'shared/ui-components'
import { RoutePlannerSliceDispatch, changeRoutingProfile, useRoutePlannerState } from '../../state'
import { ROUTING_PROFILES, RoutingProfile } from 'shared/data-access-routing'
import { useDispatch } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import { useLocale } from 'shared/util-intl'
import { PremiumModalRoutingProfiles, useUser } from 'web-app/feature-user'

export const PREMIUM_ROUTING_PROFILES: RoutingProfile[] = [
  'cycling_paths',
  'e_bike',
  'heatmap',
  'smooth_ride',
  'racingbike',
  'mtb',
]

export const RoutingProfileSelect = () => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const [user] = useUser()
  const { routingProfile, isCalculatingRoute } = useRoutePlannerState()

  const isFreeUser = !user?.isPremium

  const [isPremiumModalShown, setIsPremiumModalShown] = useState<boolean>(false)

  const handleSelect = useCallback(
    (routingProfile: RoutingProfile) => {
      if (isFreeUser && PREMIUM_ROUTING_PROFILES.includes(routingProfile)) {
        setIsPremiumModalShown(true)
      } else {
        dispatch(changeRoutingProfile(routingProfile))
      }
    },
    [dispatch, isFreeUser],
  )

  const profileLabels = useMemo(
    () => ({
      bike_networks: intl.formatMessage({
        id: 'route_profile_balanced',
        defaultMessage: 'Balanced',
      }),
      cycling_paths: intl.formatMessage({
        id: 'route_profile_cycling_paths',
        defaultMessage: 'Cycling paths',
      }),
      bike_fastest: intl.formatMessage({
        id: 'route_profile_fastest',
        defaultMessage: 'Fastest',
      }),
      e_bike: intl.formatMessage({
        id: 'route_profile_e_bike',
        defaultMessage: 'E-bike',
      }),
      heatmap: intl.formatMessage({
        id: 'route_profile_heatmap',
        defaultMessage: 'Popular',
      }),
      smooth_ride: intl.formatMessage({
        id: 'route_profile_smooth_ride',
        defaultMessage: 'Smooth ride',
      }),
      racingbike: intl.formatMessage({
        id: 'route_profile_road_bike',
        defaultMessage: 'Road bike',
      }),
      mtb: intl.formatMessage({
        id: 'route_profile_mtb',
        defaultMessage: 'Mountain bike',
      }),
    }),
    [intl],
  )

  return (
    <>
      <Select
        name="routing-profile"
        label={intl.formatMessage({
          id: 'routing_profile_select_label',
          defaultMessage: 'Routing profile',
        })}
        hiddenLabel
        value={routingProfile ?? 'bike_networks'}
        onSelect={handleSelect}
        options={ROUTING_PROFILES.map((profile) => ({
          value: profile,
          label: profileLabels[profile],
          hasPremiumIndicator: isFreeUser && PREMIUM_ROUTING_PROFILES.includes(profile),
        }))}
        disabled={isCalculatingRoute}
      />
      <PremiumModalRoutingProfiles isOpen={isPremiumModalShown} onClose={() => setIsPremiumModalShown(false)} />
    </>
  )
}
