import { LineString } from 'geojson'
import { RegionRelationCategory, RouteBikeType, RouteSurface, RouteSurfaceTag } from '../entities'
import { AlongTheRouteData, Position2d, RichLineString, composeRichLineString } from 'shared/util-geo'
import { getApproximateElevation } from 'shared/data-access-routing'

export function getRegionRelationCategory(resCategory: string): RegionRelationCategory {
  return resCategory === 'commute' ? 'commute' : resCategory === 'bike_packing' ? 'bike_packing' : 'day_trip'
}

const SURFACE_TAGS_MAP: Record<RouteSurfaceTag, RouteSurface> = {
  paved_all: RouteSurface.Paved,
  unpaved_all: RouteSurface.Unpaved,
  gravel: RouteSurface.Gravel,
  ground: RouteSurface.Ground,
}

export function mapTagToSurface(responseValue: unknown): RouteSurface | null {
  return (typeof responseValue === 'string' && SURFACE_TAGS_MAP[responseValue as RouteSurfaceTag]) || null
}

const SURFACE_TAGS_MAP_REVERSE: Record<RouteSurface, RouteSurfaceTag> = {
  [RouteSurface.Paved]: 'paved_all',
  [RouteSurface.Unpaved]: 'unpaved_all',
  [RouteSurface.Gravel]: 'gravel',
  [RouteSurface.Ground]: 'ground',
}

export function mapSurfaceToTag(surface: RouteSurface): RouteSurfaceTag {
  return SURFACE_TAGS_MAP_REVERSE[surface]
}

export function mapToSurface(responseValue: unknown): RouteSurface | null {
  return typeof responseValue === 'number' && responseValue >= 1 && responseValue <= 4
    ? (responseValue as RouteSurface)
    : null
}

export function mapToBikeType(responseValue: unknown): RouteBikeType | null {
  return typeof responseValue === 'number' && responseValue >= 1 && responseValue <= 3
    ? (responseValue as RouteBikeType)
    : null
}

export async function enrichGeometry(
  geometry: LineString,
  data?: AlongTheRouteData,
  totalDistanceMeters?: number,
  totalDurationSeconds?: number | null,
): Promise<RichLineString> {
  if (geometry.coordinates[0].length < 3) {
    const result = await getApproximateElevation(geometry.coordinates as Position2d[])
    if (result.success) {
      geometry.coordinates = result.data
    }
  }
  return composeRichLineString(
    geometry,
    data,
    totalDistanceMeters && totalDurationSeconds
      ? {
          totalDistanceMeters,
          totalDurationSeconds,
        }
      : undefined,
  )
}
