import { MultiLineString } from 'geojson'
import { has, isEmpty } from 'lodash'
import { postToCoreApi, getFromCoreApi } from '../network'
import { API_PATH_GEO_FILE_UPLOAD } from '../config'
import { RichLineString, convertToLineString, getTotalDistanceMeters, isValidGeometry } from 'shared/util-geo'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { logError } from 'shared/util-error-handling'
import { enrichGeometry } from './helpers'

// How often to fetch route import status (in ms)
const STATUS_CHECK_TIMEOUT_MS = 3000

// Maximum number of attempts to fetch route import status
const STATUS_CHECK_MAX_ATTEMPTS = 200

type FileUploadResponseData = {
  title?: string
  description?: string
  geometry: MultiLineString
  distance?: number
  matched_data?: {
    distance: number
    geometry: MultiLineString | null
  }
}

type FileUploadResponse = {
  task_id: string
  status: 'RUNNING' | 'PENDING' | 'FAILED' | 'FINISHED'
  data: FileUploadResponseData | null
}

export type RouteImportResult = {
  title: string | null
  description: string | null
  geometry: RichLineString
  geometryMatched: RichLineString | null
  distance: number
  distanceMatched: number
  isOriginallyMultiLine: boolean
}

type UploadRouteForProcessingErrors = {
  invalidFileType?: true
  uploadError?: true
  unexpectedResponse?: true
}

type GetRouteDataErrors = {
  processingCanceled?: true
  processingFailed?: true
  timeout?: true
}

export class RouteImportApiService {
  private statusCheckMaxAttempts: number

  // Current number of route import status attempts
  private statusCheckCount = 0

  // When ending route processing checks (fetching status of uploaded route)
  private isProcessingCanceled = false

  private uploadController: AbortController | undefined
  private statusController: AbortController | undefined

  /***
   * @param statusCheckMaxAttempts Maximum number of attempts to fetch route import status
   */
  constructor(statusCheckMaxAttempts?: number) {
    this.statusCheckMaxAttempts = statusCheckMaxAttempts || STATUS_CHECK_MAX_ATTEMPTS
    return this
  }

  /**
   * Cancel all ongoing API calls.
   */
  cancel() {
    this.isProcessingCanceled = true
    if (this.uploadController) {
      this.uploadController.abort()
    }
    if (this.statusController) {
      this.statusController.abort()
    }
    return this
  }

  /**
   * Whether all API requests have been canceled.
   */
  isCanceled(): boolean {
    return this.isProcessingCanceled
  }

  /**
   * Submit selected GPX or KML file to be processed.
   * Returns Task ID of uploaded route that's being processed.
   * @link https://development.bikemap.net/api/swagger-ui/#/geo_file_upload/geo_file_upload_create
   */
  async uploadRouteForProcessing(file: File): ApiResult<string, UploadRouteForProcessingErrors> {
    // Reset on new request
    this.isProcessingCanceled = false

    const fileType = this.getFileContentType(file.name)
    if (!fileType) {
      return createFailureResult({ invalidFileType: true })
    }

    try {
      this.uploadController = new AbortController()

      const res: FileUploadResponse = await postToCoreApi(API_PATH_GEO_FILE_UPLOAD, {
        body: {
          file: new Blob([file], {
            type: fileType,
          }),
          // Always match to the OSM-Street-Network because this will contain both route versions
          match_to_osm: 1,
        },
        signal: this.uploadController?.signal,
      })

      try {
        const parser = new ResponseParser(res)
        return createSuccessResult(parser.requireString('task_id'))
      } catch (error) {
        return createFailureResult({ unexpectedResponse: true }, { error })
      }
    } catch (e) {
      return createFailureResult({ uploadError: true })
    }
  }

  /**
   * Periodically check status of uploaded route.
   * Returns route data if finished successfully, null if canceled, or error.
   */
  getRouteData(statusTaskId: string): ApiResult<RouteImportResult, GetRouteDataErrors> {
    return new Promise((resolve) => {
      // Reset on new request
      this.isProcessingCanceled = false
      this.statusCheckCount = 0

      const periodicallyFetchRouteProcessingStatus = async () => {
        this.statusCheckCount++

        if (this.isProcessingCanceled) {
          // Cancel fetching the status
          return resolve(createFailureResult({ processingCanceled: true }))
        }

        // End fetching the status when we get route data
        const routeData = await this.checkRouteDataStatus(statusTaskId)
        if (routeData === false) {
          return resolve(createFailureResult({ processingFailed: true }))
        }

        if (typeof routeData !== 'boolean') {
          return resolve(createSuccessResult(routeData))
        }

        // End fetching the status if we exceeded number of attempts
        if (this.statusCheckCount >= this.statusCheckMaxAttempts) {
          return resolve(createFailureResult({ timeout: true }))
        }

        // Recursive call with delay (make sure to exit above if another attempt isn't needed)
        setTimeout(periodicallyFetchRouteProcessingStatus, STATUS_CHECK_TIMEOUT_MS)
      }

      // Check for status without delay first
      return periodicallyFetchRouteProcessingStatus()
    })
  }

  /**
   * Get file Content-Type from file name.
   */
  private getFileContentType = (fileName: string): string | undefined => {
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
    if (fileExtension === 'gpx') {
      return 'application/gpx+xml'
    }
    if (fileExtension === 'kml') {
      return 'application/kml+xml'
    }
    return undefined
  }

  /**
   * Get status about route being processed.
   * Returns fetched data, false for wrong response, or true if we need to retry.
   * @link https://development.bikemap.net/api/swagger-ui/#/geo_file_upload/geo_file_upload_retrieve
   */
  private checkRouteDataStatus = async (statusTaskId: string): Promise<RouteImportResult | boolean> => {
    let res: FileUploadResponse | undefined
    try {
      this.statusController = new AbortController()

      res = (await getFromCoreApi(API_PATH_GEO_FILE_UPLOAD, {
        queryParams: {
          task_id: statusTaskId,
        },
        signal: this.statusController?.signal,
      })) as FileUploadResponse
    } catch (e) {
      logError('Check route status', e, { statusTaskId, res })
      return false
    }

    if (!(res && has(res, 'status'))) {
      logError('Could not get valid response', null, { statusTaskId, res })
      return false
    }

    if (res && res.status === 'FAILED') {
      logError('Failed to process this route', null, { statusTaskId, res })
      return false
    }

    if (res && res.data && res.status === 'FINISHED') {
      if (!isValidGeometry(res.data.geometry)) {
        logError('Route geometry coordinates not received', null, { statusTaskId, res })
        return false
      }

      // Successful response
      return this.formatRouteDataFromResponse(res.data)
    }

    // Still waiting, so this will just continue trying
    return true
  }

  /**
   * Take API response data and format it for our needs.
   */
  private formatRouteDataFromResponse = async (data: FileUploadResponseData): Promise<RouteImportResult> => {
    const matchedData = !isEmpty(data.matched_data) ? data.matched_data : null
    const geometryMatched = matchedData && isValidGeometry(matchedData.geometry) ? matchedData.geometry : null
    const isMultiLine = Array.isArray(data.geometry.coordinates) && data.geometry.coordinates.length > 1
    const geometry = await enrichGeometry(convertToLineString(data.geometry))

    return {
      title: data.title || null,
      description: data.description || null,
      geometry,
      geometryMatched: geometryMatched ? await enrichGeometry(convertToLineString(geometryMatched)) : null,
      distance: (!isMultiLine && data.distance) || getTotalDistanceMeters(geometry),
      distanceMatched: matchedData && matchedData.distance ? matchedData.distance : 0,
      isOriginallyMultiLine: isMultiLine,
    }
  }
}
