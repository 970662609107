import { useLocale } from 'shared/util-intl'
import { useCreator, useRoute } from 'web-app/feature-route'
import { getRouteDetailsUrl } from 'shared/util-navigation'
import { UserContentReporting } from 'web-app/feature-reporting'

export const CreatorFlagActions = () => {
  const { intl } = useLocale()
  const route = useRoute()
  const creator = useCreator()

  return (
    route && (
      <UserContentReporting
        creator={creator}
        entityId={route.id}
        entityUrl={getRouteDetailsUrl(route.id)}
        reportLabel={intl.formatMessage({
          id: 'route_details_report_route',
          defaultMessage: 'Report route',
        })}
        entityIdentifier="route"
      />
    )
  )
}
