import React, { TouchEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LngLat, EditableWaypoints } from '../types'
import { decomposeWaypoints, getLetterFromViaPointIndex } from '../helpers'
import MapMarkerEnd from '../markers/map-marker-end'
import MapMarkerStart from '../markers/map-marker-start'
import MapMarkerVia from '../markers/map-marker-via'
import { Feature, FeatureCollection, Position } from 'geojson'
import { ROUTE_CONTROL_POINTS_LAYER_ID, ROUTE_GEOMETRY_SOURCE_ID } from '../settings'
import { Layer, MapLayerMouseEvent, MarkerDragEvent, MarkerProps, Source, useMap } from 'react-map-gl/maplibre'
import { MapMarkerInteraction } from '../markers'
import { useMapImage } from '../use-map-image'
import { MapGeoJSONFeature } from 'maplibre-gl'
import {
  RichLineString,
  RichMultiLineString,
  RichPosition,
  isWaypoint,
  positionToLngLat,
  reduceToPosition,
} from 'shared/util-geo'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { useControlPointRelations } from './use-control-point-relations'
import { useRouteLines } from './use-route-lines'
import { useInactiveRouteLines } from './use-inactive-route-lines'
import { useHighlightedRouteLines } from './use-highlighted-route-lines'

import controlPointImg from '../img/route-control-point.png'

type HoveredFeature = {
  type: 'segment' | 'control-point'
  index: number
}

interface EditableMapRouteProps {
  id: string
  waypoints: EditableWaypoints
  geometry?: RichMultiLineString
  originalGeometry?: RichLineString
  selectedWaypoint?: number | null
  highlightedSegments?: number[]
  tooltip?: string
  onWaypointHover: (waypointIndex: number) => void
  onWaypointClick: (waypointIndex: number) => void
  onWaypointDrag: (waypointIndex: number, lngLat: LngLat) => void
  onWaypointDrop: (waypointIndex: number, lngLat: LngLat) => void
  onControlPointHover: (controlPointIndex: number) => void
  onControlPointClick: (controlPointIndex: number) => void
  onControlPointDrag: (controlPointIndex: number, lngLat: LngLat) => void
  onControlPointDrop: (controlPointIndex: number, lngLat: LngLat) => void
  onSegmentHover: (segmentIndex: number) => void
  onSegmentClick: (segmentIndex: number, lngLat: LngLat) => void
  onSegmentDrag: (segmentIndex: number, lngLat: LngLat) => void
  onSegmentDrop: (segmentIndex: number, lngLat: LngLat) => void
  onLeave: () => void
}

/**
 * Representation of a route on the map that can be manipulated by the user.
 */
export const EditableMapRoute = ({
  id,
  waypoints,
  geometry,
  originalGeometry,
  selectedWaypoint,
  highlightedSegments,
  tooltip,
  onWaypointHover,
  onWaypointClick,
  onWaypointDrag,
  onWaypointDrop,
  onControlPointHover,
  onControlPointClick,
  onControlPointDrag,
  onControlPointDrop,
  onSegmentHover,
  onSegmentClick,
  onSegmentDrag,
  onSegmentDrop,
  onLeave,
}: EditableMapRouteProps) => {
  const { current: map } = useMap()

  useMapImage(controlPointImg, 'route-control-point')
  const routeLayerBeforeId = useRouteLayerBeforeId()

  const { start, viaPoints, end } = useMemo(() => decomposeWaypoints(waypoints), [waypoints])

  const controlPointsLayerId = useMemo(() => ROUTE_CONTROL_POINTS_LAYER_ID.replace('{baseId}', id), [id])

  const controlPoints = useMemo<RichPosition[]>(() => {
    if (!geometry?.coordinates.length) return []
    const controlPoints = [geometry.coordinates[0][0]]
    for (const segmentPoints of geometry.coordinates) {
      controlPoints.push(
        segmentPoints.length ? segmentPoints[segmentPoints.length - 1] : controlPoints[controlPoints.length - 1],
      )
    }
    return controlPoints
  }, [geometry?.coordinates])

  const controlPointRelationLines = useMemo<[Position, Position][]>(() => {
    const relationLines: [Position, Position][] = []

    // Validity check to prevent flash of intermediate state:
    const lastWaypoint = waypoints.length && waypoints[waypoints.length - 1]
    if (lastWaypoint && isWaypoint(lastWaypoint) && lastWaypoint.controlPointIndex !== controlPoints.length - 1) {
      return relationLines
    }

    waypoints.forEach((waypoint) => {
      if (isWaypoint(waypoint)) {
        const controlPoint = controlPoints[waypoint.controlPointIndex]
        if (controlPoint) {
          const { lng, lat } = waypoint
          relationLines.push([[lng, lat], reduceToPosition(controlPoint)])
        }
      }
    })
    return relationLines
  }, [controlPoints, waypoints])

  const routeLines = useRouteLines(id, (geometry?.coordinates as Position[][]) || [])
  const highlightedRouteLines = useHighlightedRouteLines(
    id,
    highlightedSegments && geometry
      ? highlightedSegments.map((segmentIndex) => geometry.coordinates[segmentIndex] as Position[])
      : [],
  )
  const inactiveRouteLines = useInactiveRouteLines(
    id,
    originalGeometry ? [originalGeometry.coordinates as Position[]] : [],
  )
  const controlPointRelations = useControlPointRelations(id, controlPointRelationLines)

  const [hoveredPoint, setHoveredPoint] = useState<LngLat | null>()

  const hoveredFeature = useRef<HoveredFeature | null>(null)
  const isDragging = useRef<boolean>(false)

  const handleWaypointHover = (index: number) => (e: React.MouseEvent | TouchEvent) => {
    e.stopPropagation()
    setHoveredPoint(null)
    onWaypointHover(index)
  }

  const handleWaypointClick =
    (index: number): MarkerProps['onClick'] =>
    (e) => {
      e.originalEvent.stopPropagation()
      onWaypointClick(index)
    }

  const handleWaypointDrag = (index: number) => (e: MarkerDragEvent) => {
    isDragging.current = true
    onWaypointDrag(index, e.lngLat)
  }

  const handleWaypointDrop = (index: number) => (e: MarkerDragEvent) => {
    onWaypointDrop(index, e.lngLat)
    isDragging.current = false
  }

  /** @returns whether a hovered feature was found and handled */
  const handleControlPointHover = useCallback(
    (features: MapGeoJSONFeature[]): boolean => {
      const controlPointFeature = features.find(({ layer }) => layer.id === controlPointsLayerId)
      if (controlPointFeature?.properties) {
        const index = controlPointFeature.properties['controlPointIndex']
        const controlPoint = controlPoints[index]
        if (
          controlPoint &&
          !(hoveredFeature.current?.type === 'control-point' && hoveredFeature.current.index === index)
        ) {
          hoveredFeature.current = { type: 'control-point', index }
          setHoveredPoint(positionToLngLat(controlPoint))
          onControlPointHover(index)
        }
        return true
      }
      return false
    },
    [controlPoints, controlPointsLayerId, onControlPointHover],
  )

  /** @returns whether a hovered feature was found and handled */
  const handleSegmentHover = useCallback(
    (features: MapGeoJSONFeature[], event: MapLayerMouseEvent): boolean => {
      const segmentFeature = features.find(({ layer }) => layer.id === routeLines.outlineLayerProps.id)
      if (segmentFeature?.properties && typeof segmentFeature.properties['segmentIndex'] === 'number') {
        const index = segmentFeature.properties['segmentIndex']
        if (!(hoveredFeature.current?.type === 'segment' && hoveredFeature.current.index === index)) {
          hoveredFeature.current = { type: 'segment', index }
          onSegmentHover(index)
        }
        setHoveredPoint(event.lngLat)
        return true
      }
      return false
    },
    [onSegmentHover, routeLines.outlineLayerProps.id],
  )

  const handleLeave = useCallback(() => {
    if (hoveredFeature.current) {
      setTimeout(() => {
        // give drag start event time to be handled
        if (!isDragging.current) {
          onLeave()
          hoveredFeature.current = null
          setHoveredPoint(null)
        }
      }, 50)
    }
  }, [onLeave])

  const handleMapHover = useCallback(
    (e: MapLayerMouseEvent) => {
      if (map && !isDragging.current) {
        const features = map.queryRenderedFeatures(e.point)

        return handleControlPointHover(features) || handleSegmentHover(features, e) || handleLeave()
      }
    },
    [handleControlPointHover, handleLeave, handleSegmentHover, map],
  )

  const handleInteractionMarkerClick: MarkerProps['onClick'] = (e) => {
    e.originalEvent.stopPropagation()
    if (hoveredFeature.current?.type === 'segment' && hoveredPoint) {
      onSegmentClick(hoveredFeature.current.index, hoveredPoint)
    } else if (hoveredFeature.current?.type === 'control-point') {
      onControlPointClick(hoveredFeature.current.index)
    }
    handleLeave()
  }

  const handleInteractionMarkerDragStart = (e: MarkerDragEvent) => {
    if (hoveredFeature.current) {
      isDragging.current = true
      if (hoveredFeature.current.type === 'segment') {
        onSegmentDrag(hoveredFeature.current.index, e.lngLat)
      } else if (hoveredFeature.current.type === 'control-point') {
        onControlPointDrag(hoveredFeature.current.index, e.lngLat)
      }
    }
  }

  const handleInteractionMarkerDrag = (e: MarkerDragEvent) => {
    if (isDragging.current && hoveredFeature.current) {
      if (hoveredFeature.current.type === 'segment') {
        onSegmentDrag(hoveredFeature.current.index, e.lngLat)
      } else if (hoveredFeature.current.type === 'control-point') {
        onControlPointDrag(hoveredFeature.current.index, e.lngLat)
      }
    }
  }

  const handleInteractionMarkerDrop = (e: MarkerDragEvent) => {
    if (hoveredFeature.current?.type === 'segment') {
      onSegmentDrop(hoveredFeature.current.index, e.lngLat)
    } else if (hoveredFeature.current?.type === 'control-point') {
      onControlPointDrop(hoveredFeature.current.index, e.lngLat)
    }
    isDragging.current = false
    setHoveredPoint(null)
  }

  useEffect(() => {
    if (map) {
      map.on('mousemove', handleMapHover)
    }
    return () => {
      map?.off('mousemove', handleMapHover)
    }
  }, [map, handleMapHover])

  useEffect(() => {
    if (
      hoveredFeature.current?.type === 'control-point' &&
      hoveredPoint &&
      !controlPoints.find(([lng, lat]) => lng === hoveredPoint.lng && lat === hoveredPoint.lat)
    ) {
      // Hover state outdated
      handleLeave()
    }
  }, [controlPoints, handleLeave, hoveredPoint])

  const sourceData = useMemo<FeatureCollection>(() => {
    const features: Feature[] = [
      ...routeLines.features,
      ...highlightedRouteLines.features,
      ...inactiveRouteLines.features,
      ...controlPointRelations.features,
    ]

    controlPoints.forEach((controlPoint, controlPointIndex) => {
      for (const waypoint of waypoints) {
        if (isWaypoint(waypoint) && waypoint.controlPointIndex === controlPointIndex) return
      }
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: reduceToPosition(controlPoint),
        },
        properties: {
          type: 'control-point',
          controlPointIndex,
        },
      })
    })

    return {
      type: 'FeatureCollection',
      features,
    }
  }, [
    controlPointRelations.features,
    controlPoints,
    highlightedRouteLines.features,
    inactiveRouteLines.features,
    routeLines.features,
    waypoints,
  ])

  const sourceId = useMemo<string>(() => ROUTE_GEOMETRY_SOURCE_ID.replace('{baseId}', id), [id])

  return (
    <>
      <Source id={sourceId} type="geojson" data={sourceData}>
        <Layer {...controlPointRelations.layerProps} beforeId={routeLayerBeforeId} />
        <Layer {...inactiveRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer {...inactiveRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer {...routeLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer {...routeLines.lineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer {...highlightedRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer {...highlightedRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer
          id={controlPointsLayerId}
          type="symbol"
          layout={{
            'icon-image': 'route-control-point',
            'icon-size': 0.5,
          }}
          filter={['==', ['get', 'type'], 'control-point']}
        />
      </Source>
      {start && (
        <MapMarkerStart
          longitude={start.lng}
          latitude={start.lat}
          style={{
            zIndex: 2,
            pointerEvents: isDragging.current ? 'none' : 'auto', // for dropping dragged interaction marker behind it
          }}
          onHover={handleWaypointHover(0)}
          onLeave={onLeave}
          onClick={handleWaypointClick(0)}
          onDrag={handleWaypointDrag(0)}
          onDragEnd={handleWaypointDrop(0)}
          selected={selectedWaypoint === 0}
        />
      )}
      {viaPoints.map(({ lng, lat }, i) => (
        <MapMarkerVia
          key={i}
          longitude={lng}
          latitude={lat}
          label={getLetterFromViaPointIndex(i)}
          style={{
            zIndex: 2,
            pointerEvents: isDragging.current ? 'none' : 'auto', // for dropping dragged interaction marker behind it
          }}
          onHover={handleWaypointHover(i + 1)}
          onLeave={onLeave}
          onClick={handleWaypointClick(i + 1)}
          onDrag={handleWaypointDrag(i + 1)}
          onDragEnd={handleWaypointDrop(i + 1)}
          selected={selectedWaypoint === i + 1}
        />
      ))}
      {end && (
        <MapMarkerEnd
          longitude={end.lng}
          latitude={end.lat}
          style={{
            zIndex: 1,
            pointerEvents: isDragging.current ? 'none' : 'auto', // for dropping dragged interaction marker behind it
          }}
          onHover={handleWaypointHover(waypoints.length - 1)}
          onLeave={onLeave}
          onClick={handleWaypointClick(waypoints.length - 1)}
          onDrag={handleWaypointDrag(waypoints.length - 1)}
          onDragEnd={handleWaypointDrop(waypoints.length - 1)}
          selected={selectedWaypoint === waypoints.length - 1}
        />
      )}
      {hoveredPoint && (
        <MapMarkerInteraction
          style={{ cursor: 'pointer' }}
          longitude={hoveredPoint.lng}
          latitude={hoveredPoint.lat}
          tooltip={tooltip}
          onClick={handleInteractionMarkerClick}
          onDragStart={handleInteractionMarkerDragStart}
          onDrag={handleInteractionMarkerDrag}
          onDragEnd={handleInteractionMarkerDrop}
        />
      )}
    </>
  )
}
