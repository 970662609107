import { FloatingButton } from 'shared/ui-components'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { useLocale } from 'shared/util-intl'
import { Fade } from '@mui/material'
import { DiscoverSliceDispatch, updateDiscoverResults, useDiscoverState } from './state'
import { useCallback } from 'react'
import { useMapState, useWebAppMap } from 'web-app/feature-map'
import { getMapBounds } from 'shared/ui-map'
import { useDiscoverParams } from './use-discover-params'
import { useDispatch } from 'react-redux'

const MIN_ZOOM_FOR_SEARCH_REQUEST = 6

interface SearchHereButtonProps {
  geonameId?: number
}

export const SearchHereButton = ({ geonameId }: SearchHereButtonProps) => {
  const dispatch = useDispatch() as DiscoverSliceDispatch
  const { intl } = useLocale()
  const map = useWebAppMap()
  const params = useDiscoverParams()
  const { areResultsOffScreen } = useDiscoverState()
  const { viewport } = useMapState()

  const handleClick = useCallback(() => {
    if (!map) return
    const bounds = getMapBounds(map)
    dispatch(updateDiscoverResults({ params, bounds, geonameId }))
  }, [dispatch, geonameId, map, params])

  return (
    <Fade in={areResultsOffScreen && viewport.zoom >= MIN_ZOOM_FOR_SEARCH_REQUEST}>
      <FloatingButton icon={<SearchRoundedIcon />} onClick={handleClick}>
        {intl.formatMessage({
          id: 'discover_top_action_search_here',
          defaultMessage: 'Find routes in this area',
        })}
      </FloatingButton>
    </Fade>
  )
}
