import { useMemo } from 'react'
import { BikeNetwork, RichLineString } from 'shared/util-geo'
import { colors } from 'shared/ui-design-system'
import { useLocale } from 'shared/util-intl'
import { AlongTheRouteDiagram } from './along-the-route-diagram'

interface BikeNetworkDiagramProps {
  geometry: RichLineString
  routeDistanceMeters: number
}

export const BikeNetworkDiagram = ({ geometry, routeDistanceMeters }: BikeNetworkDiagramProps) => {
  const { intl } = useLocale()

  const labels = useMemo<Record<BikeNetwork, string>>(
    () => ({
      [BikeNetwork.International]: intl.formatMessage({
        id: 'bike_network_international_label',
        defaultMessage: 'International cycling route',
      }),
      [BikeNetwork.National]: intl.formatMessage({
        id: 'bike_network_national_label',
        defaultMessage: 'National cycling route',
      }),
      [BikeNetwork.Regional]: intl.formatMessage({
        id: 'bike_network_regional_label',
        defaultMessage: 'Regional cycling route',
      }),
      [BikeNetwork.Local]: intl.formatMessage({
        id: 'bike_network_local_label',
        defaultMessage: 'Local cycling route',
      }),
    }),
    [intl],
  )

  const bikeNetworkColors = useMemo<Record<BikeNetwork, string>>(
    () => ({
      [BikeNetwork.International]: colors.bikeNetworkComp.international,
      [BikeNetwork.National]: colors.bikeNetworkComp.national,
      [BikeNetwork.Regional]: colors.bikeNetworkComp.regional,
      [BikeNetwork.Local]: colors.bikeNetworkComp.local,
    }),
    [],
  )

  return (
    <AlongTheRouteDiagram
      geometry={geometry}
      attributeIndex={7}
      colors={bikeNetworkColors}
      labels={labels}
      nullLabel={intl.formatMessage({
        id: 'bike_network_missing_label',
        defaultMessage: 'Other',
      })}
      routeDistanceMeters={routeDistanceMeters}
    />
  )
}
