import { createSelector } from 'reselect'
import {
  CompleteWaypoints,
  IncompleteWaypoints,
  PLANNER_SLICE_KEY,
  RoutePlannerState,
  SavableRouteState,
  SelectedWaypointState,
  StateWithRoutePlannerSlice,
  WaypointsState,
  RoutedWaypoint,
  RoutePlannerStats,
} from './types'
import {
  combineSegmentsToLine,
  deriveControlPointIndexesFromSegments,
  deriveControlPointsFromSegments,
} from './helpers'
import { ROUTE_SLICE_KEY, StateWithRouteSlice } from 'web-app/feature-route'
import { RouteEntity, RouteEntityMapData } from 'shared/data-access-core'
import { LngLatElevation, RichLineString, areLngLatsEqual, deriveTotalStats } from 'shared/util-geo'
import { useSelector } from 'react-redux'

type UndoRedoSelectorReturn = {
  canUndo: boolean
  canRedo: boolean
}

export const undoRedoSelector: (state: StateWithRoutePlannerSlice) => UndoRedoSelectorReturn = createSelector(
  (state: StateWithRoutePlannerSlice) => state[PLANNER_SLICE_KEY],
  (slice) => ({
    canUndo: slice.past.length > 0,
    canRedo: slice.future.length > 0,
  }),
)

export const useUndoRedo = () => useSelector(undoRedoSelector)

export const routePlannerSliceSelector: (state: StateWithRoutePlannerSlice) => RoutePlannerState = createSelector(
  (state: StateWithRoutePlannerSlice) => state[PLANNER_SLICE_KEY],
  (slice) => slice.present,
)

export const useRoutePlannerState = () => useSelector(routePlannerSliceSelector)

export const waypointsSelector: (state: StateWithRoutePlannerSlice) => WaypointsState = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).waypoints,
  (waypoints) =>
    waypoints.length > 2 || (waypoints[0] && waypoints[waypoints.length - 1])
      ? {
          isFullRoute: true,
          waypoints: waypoints as CompleteWaypoints,
          start: waypoints[0] as RoutedWaypoint,
          via: waypoints.slice(1, waypoints.length - 1) as RoutedWaypoint[],
          end: waypoints[waypoints.length - 1] as RoutedWaypoint,
        }
      : {
          isFullRoute: false,
          waypoints: waypoints as IncompleteWaypoints,
          start: waypoints[0],
          via: [],
          end: waypoints[waypoints.length - 1],
        },
)

export const useWaypoints = () => useSelector(waypointsSelector)

export const isRoundTripSelector: (state: StateWithRoutePlannerSlice) => boolean = createSelector(
  waypointsSelector,
  ({ start, end }: WaypointsState) => !!(start && end) && start.lng === end.lng && start.lat === end.lat,
)

export const useIsRoundtrip = () => useSelector(isRoundTripSelector)

export const selectedWaypointSelector: (state: StateWithRoutePlannerSlice) => SelectedWaypointState | null =
  createSelector(
    [
      (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).selectedWaypoint,
      (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).waypoints,
    ],
    (selectedWaypoint, waypoints) => {
      const hasSelectedWaypoint = selectedWaypoint !== null
      const waypoint = hasSelectedWaypoint ? waypoints[selectedWaypoint] : null

      return hasSelectedWaypoint && waypoint
        ? {
            index: selectedWaypoint,
            waypoint,
          }
        : null
    },
  )

export const useSelectedWaypoint = () => useSelector(selectedWaypointSelector)

export const hasRouteDistanceSelector: (state: StateWithRoutePlannerSlice) => boolean = createSelector(
  (state: StateWithRoutePlannerSlice) => waypointsSelector(state),
  ({ isFullRoute, waypoints }) => {
    if (!isFullRoute) return false
    let firstLng: number | undefined, firstLat: number | undefined
    for (const waypoint of waypoints) {
      if (typeof firstLng === 'undefined' || typeof firstLat === 'undefined') {
        firstLng = waypoint.lng
        firstLat = waypoint.lat
      } else {
        if (waypoint.lng !== firstLng || waypoint.lat !== firstLat) {
          return true
        }
      }
    }
    return false
  },
)

export const useHasRouteDistance = () => useSelector(hasRouteDistanceSelector)

export const baseRouteSelector: (
  state: StateWithRoutePlannerSlice & StateWithRouteSlice,
) => (RouteEntity & RouteEntityMapData) | null = createSelector(
  [
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).basedOnRouteId,
    (state: StateWithRouteSlice) => state[ROUTE_SLICE_KEY].route,
  ],
  (basedOnRouteId, route) => (basedOnRouteId && route && basedOnRouteId === route.id ? route : null),
)

export const useBaseRoute = () => useSelector(baseRouteSelector)

export const finalGeometrySelector: (state: StateWithRoutePlannerSlice) => RichLineString | null = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
  (geometry) => geometry && combineSegmentsToLine(geometry),
)

export const useFinalGeometry = () => useSelector(finalGeometrySelector)

export const controlPointsSelector: (state: StateWithRoutePlannerSlice) => LngLatElevation[] | null = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
  (geometry) => geometry && deriveControlPointsFromSegments(geometry),
)

export const useControlPoints = () => useSelector(controlPointsSelector)

export const plannedRouteStatsSelector: (state: StateWithRoutePlannerSlice) => RoutePlannerStats | null =
  createSelector(
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).isCalculatingRoute,
    (geometry, isCalculatingRoute) => {
      if (isCalculatingRoute || !geometry) return null

      let distanceMeters = 0
      let durationSeconds = 0
      let maximumElevationMeters = 0
      geometry.coordinates.forEach((segment) => {
        const segmentStats = deriveTotalStats(segment)
        distanceMeters += segmentStats.distanceMeters
        durationSeconds += segmentStats.durationSeconds
        if (segmentStats.maximumElevationMeters > maximumElevationMeters) {
          maximumElevationMeters = segmentStats.maximumElevationMeters
        }
      })

      return { distanceMeters, durationSeconds, maximumElevationMeters }
    },
  )

export const usePlannedRouteStats = () => useSelector(plannedRouteStatsSelector)

export const controlPointIndexesSelector: (state: StateWithRoutePlannerSlice) => [number, number, ...number[]] | null =
  createSelector(
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
    (geometry) => geometry && deriveControlPointIndexesFromSegments(geometry),
  )

export const hasRouteBeenChangedSelector: (state: StateWithRoutePlannerSlice & StateWithRouteSlice) => boolean =
  createSelector(
    [
      (state: StateWithRoutePlannerSlice & StateWithRouteSlice) => baseRouteSelector(state),
      plannedRouteStatsSelector,
      waypointsSelector,
    ],
    (baseRoute, stats, { waypoints }) =>
      !baseRoute ||
      stats?.distanceMeters !== baseRoute.distanceMeters ||
      stats.durationSeconds !== (baseRoute.durationSeconds ?? null) ||
      waypoints.length !== baseRoute.waypoints.length ||
      !!waypoints.find((w, i) => w && !areLngLatsEqual(w, baseRoute.waypoints[i])),
  )

export const useHasRouteBeenChanged = () => useSelector(hasRouteBeenChangedSelector)

export const savableRouteSelector: (state: StateWithRoutePlannerSlice) => SavableRouteState | null = createSelector(
  [finalGeometrySelector, plannedRouteStatsSelector, waypointsSelector, controlPointIndexesSelector],
  (geometry, stats, { isFullRoute, waypoints }, controlPointIndexes) => {
    if (isFullRoute && geometry && stats && waypoints.length >= 2 && controlPointIndexes) {
      return {
        geometry,
        distanceMeters: stats.distanceMeters,
        durationSeconds: stats.durationSeconds,
        waypoints,
        controlPointIndexes,
        start: waypoints[0],
        via: waypoints.length > 2 ? waypoints.slice(1, -1) : [],
        end: waypoints[waypoints.length - 1],
      }
    }
    return null
  },
)

export const useSavableRoute = () => useSelector(savableRouteSelector)
