import { useState } from 'react'
import { useLocale } from 'shared/util-intl'

type ValidationOptions = {
  isRequired?: boolean
}

export const useValidation = (
  label: string,
  onValidation?: (value: string) => string | null,
  options: ValidationOptions = {},
) => {
  const { intl } = useLocale()
  const [error, setError] = useState<string | null>()

  const validate = (value: string): boolean => {
    let error: string | null = null
    if (options.isRequired && !value) {
      error = intl.formatMessage(
        {
          id: 'editable_content_form_error_empty',
          defaultMessage: '{label} is required.',
        },
        {
          label,
        },
      )
    } else if (onValidation) {
      error = onValidation(value)
    }
    setError(error)
    return !error
  }

  return { error, validate }
}
