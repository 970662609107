import { Button } from 'shared/ui-components'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import { useLocale } from 'shared/util-intl'
import { useRoute } from 'web-app/feature-route'
import { useFragmentRouting } from '../../use-fragment-routing'
import { EMBED_FRAGMENT_HASH } from '../../config'

import styles from './embed-action.module.css'

export const EmbedAction = () => {
  const { getFragmentLinkProps } = useFragmentRouting()
  const { intl } = useLocale()
  const route = useRoute()

  return (
    <Button
      variant="secondary"
      icon={<CodeRoundedIcon />}
      disabled={!route || route.isPrivate}
      {...getFragmentLinkProps(EMBED_FRAGMENT_HASH)}
      className={styles['button']}
    >
      {intl.formatMessage({
        id: 'route_details_sharing_button_embed',
        defaultMessage: 'Embed',
      })}
    </Button>
  )
}
