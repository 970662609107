import { CollectionRoutesSorting } from 'shared/data-access-core'
import { CollectionRoutesParams } from './state'

export const COLLECTION_ROUTES_PAGE_SIZE = 50 // must be at least 20 to cover tours

export const COLLECTION_ROUTES_DEFAULT_SORTING: CollectionRoutesSorting = 'custom-asc'

export const COLLECTION_ROUTES_DEFAULT_PARAMS: CollectionRoutesParams = {
  sorting: COLLECTION_ROUTES_DEFAULT_SORTING,
  onlyLoops: false,
  distanceKilometers: [0, null],
  ascentMeters: [0, null],
  title: '',
  bikeTypes: [],
  surfaces: [],
}
