import { useEffect, useRef } from 'react'
import { MapLayerMouseEvent, useMap } from 'react-map-gl/maplibre'

/**
 * Add a higher level click / tap handler to the map.
 * The handler won't be called if
 * - it is a double click / tap (zoom to position) or
 * - the click is immediately followed by a touch start (one-finger zoom on touch device).
 */
export const useMapClick = (mapId: string, onMapClick: (event: MapLayerMouseEvent) => void, active = true) => {
  const { [mapId]: map } = useMap()

  const status = useRef<'idle' | 'pending' | 'canceled'>('idle')

  useEffect(() => {
    if (!active) return

    const cancel = () => {
      status.current = 'canceled'
      map?.off('touchstart', cancel)
      map?.off('mousedown', cancel)
    }

    if (status.current === 'pending') {
      // Restore clear listeners
      map?.on('touchstart', cancel)
      map?.on('mousedown', cancel)
    }

    const handler = (event: MapLayerMouseEvent) => {
      if (status.current !== 'idle') return
      status.current = 'pending'
      setTimeout(() => {
        if (status.current === 'pending') {
          onMapClick(event)
          map?.off('touchstart', cancel)
          map?.off('mousedown', cancel)
        }
        status.current = 'idle'
      }, 300)
      map?.on('touchstart', cancel)
      map?.on('mousedown', cancel)
    }

    map?.on('click', handler)

    return () => {
      map?.off('click', handler)
      map?.off('touchstart', cancel)
      map?.off('mousedown', cancel)
    }
  }, [active, map, onMapClick])
}
