import { useMemo, useState } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Button, UserContent, useMessages } from 'shared/ui-components'

const CHARACTERS_PER_LINE = 50

interface CollapsedTextProps {
  children: string
  collapsedLines?: number
}

export const CollapsedText = ({ children, collapsedLines = 5 }: CollapsedTextProps) => {
  const { showMoreLabel } = useMessages()

  const collapseLengthThreshold = useMemo<number>(() => (collapsedLines + 1) * CHARACTERS_PER_LINE, [collapsedLines])
  const [isCollapsed, setIsCollapsed] = useState<boolean>(children.length >= collapseLengthThreshold)

  return (
    <>
      <UserContent secondary linesLimit={isCollapsed ? collapsedLines : undefined}>
        {children}
      </UserContent>
      {isCollapsed && (
        <Button
          block
          variant="secondary"
          size="medium"
          icon={<ExpandMoreRoundedIcon />}
          onClick={() => setIsCollapsed(false)}
        >
          {showMoreLabel}
        </Button>
      )}
    </>
  )
}
