import { Point } from 'geojson'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import { LngLat, LngLatElevation } from 'shared/util-geo'
import { API_PATH_NEAREST } from '../definitions'
import { getFromRoutingApi } from '../network'

type Response = {
  distance: number
  point: Point
}

/**
 * Get the nearest routable point with elevation and its distance to the given point.
 */
export async function fetchNearestPoint(point: LngLat): ApiResult<{
  distanceMeters: number
  point: LngLatElevation
}> {
  try {
    const response: Response = await getFromRoutingApi(API_PATH_NEAREST, {
      queryParams: {
        point: `${point.lat},${point.lng}`,
        elevation: 'true',
      },
    })

    try {
      const [lng, lat, elevation] = response.point.coordinates
      if (elevation) {
        return createSuccessResult({
          distanceMeters: response.distance,
          point: { lng, lat, elevation },
        })
      }
    } catch {
      return createFailureResult({ unexpectedResponse: true })
    }
    return createFailureResult({ unexpectedResponse: true })
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
