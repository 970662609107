import { NavigateOptions, useNavigate } from 'react-router-dom'
import { RegionEntity } from 'shared/data-access-core'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { LngLat } from 'shared/util-geo'
import {
  WEB_APP_PATH_COLLECTION_ROUTE_DETAILS,
  WEB_APP_PATH_DISCOVER,
  WEB_APP_PATH_IMPORT,
  WEB_APP_PATH_REGION,
  WEB_APP_PATH_ROUTE_DETAILS,
  WEB_APP_PATH_ROUTE_EDIT,
  WEB_APP_PATH_ROUTE_PLANNER,
  WEB_APP_PATH_ROUTE_PRINT,
} from 'shared/util-navigation'

export const useNavigateToHome = () => {
  const navigate = useNavigate()

  return () => navigate('/')
}

export const useNavigateToDiscover = () => {
  const navigate = useNavigate()

  return (state?: { selectedLocation?: GeocoderLocation; keepMapViewport?: boolean }) =>
    navigate(WEB_APP_PATH_DISCOVER, { state })
}

export const useNavigateToRegion = () => {
  const navigate = useNavigate()

  return (geonameId: number, state?: { regionPreview?: RegionEntity }, searchParams = '') => {
    navigate(WEB_APP_PATH_REGION.replace(':geonameId', geonameId.toString()) + (searchParams && '?' + searchParams), {
      state,
    })
  }
}

export const useNavigateToRouteImport = () => {
  const navigate = useNavigate()

  return () => navigate(WEB_APP_PATH_IMPORT)
}

export type RoutePlannerInitializationState = {
  origin?: LngLat & { address?: string; poiName?: string }
  destination?: LngLat & { address?: string; poiName?: string }
}

export const useNavigateToRoutePlanner = () => {
  const navigate = useNavigate()

  return (state?: RoutePlannerInitializationState) => navigate(WEB_APP_PATH_ROUTE_PLANNER, { state })
}

export type RouteDetailsPreviousView = {
  path: string
  label: string
}

export const useNavigateToRouteDetails = () => {
  const navigate = useNavigate()

  return (
    routeId: number,
    options?: Omit<NavigateOptions, 'state'> & { state?: { previousView?: RouteDetailsPreviousView } },
  ) => {
    navigate(WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', routeId.toString()), options)
  }
}

export const useNavigateToCollectionRouteDetails = () => {
  const navigate = useNavigate()

  return (routeId: number, routeCollectionId: number) =>
    navigate(
      WEB_APP_PATH_COLLECTION_ROUTE_DETAILS.replace(':routeCollectionId', routeCollectionId.toString()).replace(
        ':routeId',
        routeId.toString(),
      ),
    )
}

export const useNavigateToRouteEdit = () => {
  const navigate = useNavigate()

  return (routeId: number) => navigate(WEB_APP_PATH_ROUTE_EDIT.replace(':routeId', routeId.toString()))
}

export const useNavigateToRoutePrint = () => {
  const navigate = useNavigate()

  return (routeId: number) => navigate(WEB_APP_PATH_ROUTE_PRINT.replace(':routeId', routeId.toString()))
}
