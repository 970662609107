import { useMemo, useState } from 'react'
import { RichLineString } from 'shared/util-geo'
import { Typography } from '@mui/material'
import { formatLargeLength } from 'shared/util-formatting'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import clsx from 'clsx'
import { Button } from 'shared/ui-components'

import styles from './along-the-route-diagram.module.css'

type DiagramData<Attribute> = {
  category: Attribute
  percentage: number
}

type CategoryGroup<Attribute> = {
  categories: Attribute[]
  label: string
}

export type CategoryGroups<Attribute> = [CategoryGroup<Attribute>, CategoryGroup<Attribute>]

interface AlongTheRouteDiagramProps<Attribute extends number> {
  geometry: RichLineString
  attributeIndex: number
  colors: Record<Attribute, string>
  labels: Record<Attribute, string>
  nullLabel: string
  routeDistanceMeters: number
  groups?: CategoryGroups<Attribute>
}

export function AlongTheRouteDiagram<Attribute extends number>({
  geometry,
  attributeIndex,
  colors,
  labels,
  nullLabel,
  routeDistanceMeters,
  groups,
}: AlongTheRouteDiagramProps<Attribute>) {
  const { intl, language } = useLocale()
  const { unitPreference } = useUserState()

  const distanceSums = useMemo<Record<Attribute, number>>(() => {
    const distanceSums: Record<Attribute, number> = {} as Record<Attribute, number>
    for (const position of geometry.coordinates) {
      const category = position[attributeIndex] as Attribute
      if (category !== null) {
        distanceSums[category] = (distanceSums[category] || 0) + position[3]
      }
    }
    return distanceSums
  }, [attributeIndex, geometry.coordinates])

  const diagramData = useMemo<DiagramData<Attribute>[]>(() => {
    const data: DiagramData<Attribute>[] = []
    for (const category in distanceSums) {
      const percentage = (distanceSums[category] / routeDistanceMeters) * 100
      data.push({ category, percentage })
    }
    const sortedData = data.sort((a, b) => b.percentage - a.percentage)
    return sortedData
  }, [distanceSums, routeDistanceMeters])

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const legendData = useMemo<DiagramData<Attribute>[]>(
    () => (isExpanded ? diagramData : diagramData.slice(0, 3)),
    [diagramData, isExpanded],
  )

  const nullPercentage = useMemo<number>(() => {
    let totalPercentage = 0
    for (const { percentage } of diagramData) {
      totalPercentage += percentage
    }
    return 100 - totalPercentage
  }, [diagramData])

  return (
    <div className={styles['container']}>
      <div className={styles['diagram-container']}>
        {diagramData.map(({ category, percentage }) => (
          <div
            key={category}
            className={styles['diagram-area']}
            style={{ width: `${percentage}%`, backgroundColor: colors[category] }}
          />
        ))}
        <div className={styles['diagram-area']} style={{ width: `${nullPercentage}%` }} />
      </div>
      {groups && (
        <div className={styles['groups-container']}>
          {groups.map(({ categories, label }, i) => {
            const percentage = diagramData.reduce(
              (sum, { category, percentage }) => sum + (categories.includes(category) ? percentage : 0),
              0,
            )
            return (
              !!percentage && (
                <div key={i} className={styles['group']}>
                  <Typography variant="body2" fontWeight={500}>
                    {label}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatLargeLength(
                      (routeDistanceMeters * percentage) / 100,
                      language,
                      unitPreference === 'imperial',
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    ({percentage < 1 ? '<1' : Math.round(percentage)} %)
                  </Typography>
                </div>
              )
            )
          })}
        </div>
      )}
      <ul className={styles['legend']}>
        {legendData.map(({ category, percentage }, i) => (
          <li
            key={category}
            className={clsx(styles['legend-item'], {
              [styles['legend-item-before-expand']]:
                i === 2 && !isExpanded && diagramData.length > (nullPercentage ? 2 : 3),
            })}
          >
            <div className={styles['legend-color']} style={{ backgroundColor: colors[category] }} />
            <Typography variant="body2" fontWeight={500}>
              {labels[category]}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatLargeLength(distanceSums[category], language, unitPreference === 'imperial')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ({percentage < 1 ? '<1' : Math.round(percentage)} %)
            </Typography>
          </li>
        ))}
        {!isExpanded && diagramData.length > (nullPercentage ? 2 : 3) ? (
          <li key="more" className={styles['expand-button-item']}>
            <Button
              variant="secondary"
              size="small"
              icon={<ExpandMoreRoundedIcon />}
              onClick={() => setIsExpanded(true)}
            >
              {intl.formatMessage({
                id: 'along_the_route_diagram_more_button',
                defaultMessage: 'More',
              })}
            </Button>
          </li>
        ) : (
          !!nullPercentage && (
            <li className={styles['legend-item']}>
              <div className={styles['legend-color']} />
              <Typography variant="body2" fontWeight={500}>
                {nullLabel}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatLargeLength(
                  (routeDistanceMeters * nullPercentage) / 100,
                  language,
                  unitPreference === 'imperial',
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                ({nullPercentage < 1 ? '<1' : Math.round(nullPercentage)} %)
              </Typography>
            </li>
          )
        )}
      </ul>
    </div>
  )
}
