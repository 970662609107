import { useRoute } from '../state'
import { RouteStats } from './route-stats'
import { useMemo } from 'react'
import { RichPosition } from 'shared/util-geo'

export type RouteStatsOverrides = {
  durationSeconds?: number
  distanceMeters?: number
  ascentInM?: number
  descentInM?: number
  averageSpeedMetersPerSecond?: number
  maxAltitudeInM?: number
}

export interface MainRouteStatsProps {
  extended?: boolean
  overrides?: RouteStatsOverrides
  loading?: boolean
}

/**
 * Route statistics for the current main route in global state.
 */
export const MainRouteStats = ({ extended, overrides, loading }: MainRouteStatsProps) => {
  const route = useRoute()

  const overrideKeys = useMemo(() => {
    if (!overrides) return []
    const definedKeys = []
    for (const key in overrides) {
      if (overrides[key as keyof RouteStatsOverrides] !== undefined) {
        definedKeys.push(key)
      }
    }
    return definedKeys as (keyof RouteStatsOverrides)[]
  }, [overrides])

  return (
    <RouteStats
      extended={extended}
      durationSeconds={route && !loading ? overrides?.durationSeconds ?? route.durationSeconds ?? null : undefined}
      distanceMeters={route && !loading ? overrides?.distanceMeters ?? route.distanceMeters : undefined}
      averageSpeedMetersPerSecond={
        extended && route && !loading
          ? overrides?.averageSpeedMetersPerSecond ||
            (!overrides && route.averageSpeedMetersPerSecond) ||
            (overrides?.durationSeconds &&
              (overrides?.distanceMeters || route.distanceMeters) / overrides?.durationSeconds) ||
            (route.durationSeconds && (overrides?.distanceMeters || route.distanceMeters) / route.durationSeconds) ||
            null
          : undefined
      }
      ascentInM={route && !loading ? overrides?.ascentInM ?? route.ascentMeters ?? null : undefined}
      descentInM={route && !loading ? overrides?.descentInM ?? route.descentMeters ?? null : undefined}
      maxAltitudeInM={
        extended && route && !loading
          ? overrides?.maxAltitudeInM ?? findMaxAltitude(route.geometry.coordinates)
          : undefined
      }
      overrides={overrideKeys}
    />
  )
}

function findMaxAltitude(coordinates: RichPosition[]): number {
  let max = 0
  for (const position of coordinates) {
    if (position[2] > max) {
      max = position[2]
    }
  }
  return max
}
