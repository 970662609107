import { RouteCollectionForm, createRouteCollection, fetchUserRouteCollections } from 'shared/data-access-core'
import {
  ROUTE_SLICE_KEY,
  RouteSliceDispatch,
  StateWithRouteSlice,
  assignCollection,
  routeSliceSelector,
} from 'web-app/feature-route'
import {
  EntitiesSliceDispatch,
  routeAddedToCollection,
  routeCollectionFetched,
  routeCollectionsFetched,
  userFetched,
} from 'web-app/data-access-entities'
import { StateWithUserSlice, routeCollectionCreated, userSliceSelector } from 'web-app/feature-user'

export const createAndAssignCollection =
  (routeCollectionForm: RouteCollectionForm) =>
  async (dispatch: RouteSliceDispatch, getState: () => StateWithRouteSlice) => {
    const { route, assignedRouteCollectionIds } = getState()[ROUTE_SLICE_KEY]
    if (!route || !assignedRouteCollectionIds) return

    const res = await createRouteCollection(routeCollectionForm)
    if (res.success) {
      const { collection, owner } = res.data
      dispatch(routeCollectionCreated(collection.id))
      dispatch(routeCollectionFetched(collection))
      dispatch(userFetched(owner))
      dispatch(assignCollection(collection.id))
      dispatch(routeAddedToCollection(collection.id))
    }
  }

export const fetchCollectionsAssignedToGlobalRoute =
  () => async (dispatch: EntitiesSliceDispatch, getState: () => StateWithRouteSlice & StateWithUserSlice) => {
    const state = getState()
    const { user } = userSliceSelector(state)
    const { route } = routeSliceSelector(state)
    if (!user || !route) return

    const res = await fetchUserRouteCollections(user.id, route.id)
    if (res.success) {
      dispatch(routeCollectionsFetched(res.data.routeCollections))
    }
  }
