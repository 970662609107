import clsx from 'clsx'
import { Skeleton } from '@mui/material'
import { PremiumLockIcon } from 'shared/ui-design-system/icons/premium-lock-icon'
import { RequiredMapStyleData } from './types'
import { colors } from 'shared/ui-design-system'

import styles from './map-style-button.module.scss'

export interface MapStyleButtonProps<MapStyleData> {
  mapStyle: MapStyleData
  active: boolean
  withPremiumIndicator: boolean
  onClick: () => void
}

export function MapStyleButton<MapStyleData extends RequiredMapStyleData>({
  mapStyle,
  active,
  withPremiumIndicator,
  onClick,
}: MapStyleButtonProps<MapStyleData>) {
  return (
    <button
      className={clsx(styles['map-style-button'], {
        [styles['map-style-button-active']]: active,
      })}
      aria-label={mapStyle.name}
      onClick={!active ? onClick : undefined}
    >
      <img src={mapStyle.thumbnail} alt={mapStyle.name} />
      {withPremiumIndicator && mapStyle.isPremium && (
        <PremiumLockIcon
          className={styles['premium-lock']}
          style={{ color: colors.premiumComp.premiumIndicator }}
          data-testid="premium-lock"
        />
      )}
      <div className={styles['map-style-btn-label']}>{mapStyle.name}</div>
    </button>
  )
}

export const MapStyleButtonSkeleton = () => (
  <Skeleton variant="rectangular" className={styles['map-style-button-skeleton']} data-testid="skeleton" />
)
