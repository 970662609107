import { ErrorBoundary, ErrorBoundaryFallback, ViewNotFound } from 'web-app/utils-error-handling'
import { DefaultHeader } from 'web-app/feature-navigation'
import { WebAppMapControls, WebAppMapFooter, WebAppMapLayout } from 'web-app/feature-map'
import { RouteCollectionSheet } from './route-collection-sheet'
import {
  LoadMoreRoutesButton,
  RouteCollectionSliceDispatch,
  resetRouteCollectionState,
  useCollectionRoutesParams,
  useRouteCollectionById,
  useRouteCollectionState,
  useRouteCollectionTitle,
  useTourGeometries,
} from 'web-app/feature-route-collection'
import { useDispatch } from 'react-redux'
import { RouteCollectionMap } from './route-collection-map'
import { WebAppTopControl } from 'web-app/ui-layout'
import { ElevationCurveProvider } from 'shared/feature-elevation-curve'
import { useUserState } from 'web-app/feature-user'

interface RouteCollectionProps {
  routeCollectionId: number
}

export function RouteCollection({ routeCollectionId }: RouteCollectionProps) {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { isRouteCollectionUnavailable } = useRouteCollectionState()
  const params = useCollectionRoutesParams()
  const { unitPreference } = useUserState()
  const tourGeometries = useTourGeometries()

  useRouteCollectionById(routeCollectionId, params)
  useRouteCollectionTitle()

  return isRouteCollectionUnavailable ? (
    <ViewNotFound />
  ) : (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          beforeReload={async () => {
            dispatch(resetRouteCollectionState())
          }}
        />
      }
    >
      <ElevationCurveProvider geometry={tourGeometries} unitPreference={unitPreference}>
        <WebAppMapLayout>
          <DefaultHeader />
          <RouteCollectionSheet />
          <RouteCollectionMap routeCollectionId={routeCollectionId} />
          <WebAppMapControls />
          <WebAppMapFooter />
          <WebAppTopControl>
            <LoadMoreRoutesButton params={params} />
          </WebAppTopControl>
        </WebAppMapLayout>
      </ElevationCurveProvider>
    </ErrorBoundary>
  )
}
