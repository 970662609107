import { API_PATH_ROUTE_COLLECTION_ROUTES_CHANGES } from '../../config'
import { putToCoreApi } from '../../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export async function changeRouteCollectionRoutes(
  routeCollectionId: number,
  routeIds: number[],
  originalItemsCount: number,
): ApiResult<null> {
  try {
    await putToCoreApi(API_PATH_ROUTE_COLLECTION_ROUTES_CHANGES, {
      params: {
        routeCollectionId,
      },
      body: {
        routes: routeIds.map((id) => ({ id })),
        max_index: originalItemsCount,
      },
      type: 'json',
    })
    return createSuccessResult(null)
  } catch (error) {
    return createFailureResult(
      {
        unexpectedError: true,
      },
      { routeCollectionId, routeIds, originalItemsCount },
    )
  }
}
