import { RoutePoiMapMarker } from './map/route-poi-map-marker'
import { useRoutePois } from './route-pois-context'
import { RoutePoiEntity } from 'shared/data-access-core'
import { RoutePoiMapMarkerEditing } from './map/route-poi-map-marker-editing'
import { useMapClick } from 'shared/ui-map'

interface RoutePoisMapFeaturesProps {
  mapId: string
  onSelect?: (routePoi: RoutePoiEntity) => void
  interactive?: boolean
}

/**
 * Shows route POIs on a map.
 */
export const RoutePoisMapFeatures = ({ mapId, onSelect, interactive = true }: RoutePoisMapFeaturesProps) => {
  const { routePois, selectedRoutePoi, editingPosition, onRoutePoiSelectionChange } = useRoutePois()

  useMapClick(mapId, () => {
    onRoutePoiSelectionChange(null)
  })

  return (
    <>
      {routePois
        .sort((a, b) => b.position.lat - a.position.lat)
        .map((routePoi) => {
          return !editingPosition || routePoi.id !== selectedRoutePoi?.id ? (
            <RoutePoiMapMarker key={routePoi.id} routePoi={routePoi} onSelect={onSelect} interactive={interactive} />
          ) : null
        })}
      {editingPosition && <RoutePoiMapMarkerEditing position={editingPosition} />}
    </>
  )
}
