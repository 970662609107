import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { formatAnalyticsData } from 'shared/util-analytics'
import { Divider, Button, ButtonGroup } from 'shared/ui-components'
import { GPXUploadIcon } from 'shared/ui-design-system/icons/gpx-upload-icon'
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded'
import { WebAppContent, WebAppMainActions, WebAppSheet } from 'web-app/ui-layout'
import { useUser } from 'web-app/feature-user'
import { useRouteImportState, useIsAlreadyUploaded, stepNext, reset } from '../state'
import { RouteImportUploader } from './route-import-uploader'
import { Typography } from '@mui/material'
import { ToolHeader, useCancel, useSessionInfo } from 'web-app/feature-navigation'
import { getIntegrationsUrl } from 'shared/util-navigation'

import styles from './step-upload.module.scss'

import garminAvif from './images/garmin.avif'
import garminWebp from './images/garmin.webp'
import garminPng from './images/garmin.png'
import wahooAvif from './images/wahoo.avif'
import wahooWebp from './images/wahoo.webp'
import wahooPng from './images/wahoo.png'

const RouteImportStepUpload = () => {
  const { intl } = useLocale()
  const dispatch = useDispatch()
  const cancel = useCancel()
  const sessionInfo = useSessionInfo()

  const { isUploadInProgress, routeFileName } = useRouteImportState()
  const [user, isUserLoaded] = useUser()
  const isAlreadyUploaded = useIsAlreadyUploaded()

  const onCancel = () => {
    dispatch(reset())
    cancel()
  }

  const header = (
    <ToolHeader
      title={intl.formatMessage({
        id: 'route_import_title_step_upload',
        defaultMessage: 'Route import',
      })}
      onCancel={onCancel}
    />
  )

  if (isAlreadyUploaded) {
    const nextButtonLabel = intl.formatMessage({
      id: 'route_import_next_step_button',
      defaultMessage: 'Next step',
    })
    return (
      <>
        {header}
        <WebAppSheet fullHeight>
          <WebAppContent>
            <RouteImportUploader
              uploadButtonLabel={intl.formatMessage({
                id: 'route_import_step_upload_uploader_button_replace',
                defaultMessage: 'Replace file',
              })}
            >
              <div className={styles['uploader-initial-content']}>
                <InsertDriveFileRoundedIcon className={styles['uploadIcon'] + ' ' + styles['uploadedIcon']} />
                <Typography paragraph>{routeFileName}</Typography>
              </div>
            </RouteImportUploader>
          </WebAppContent>
        </WebAppSheet>
        <WebAppMainActions>
          <Button block onClick={() => dispatch(stepNext())} disabled={isUploadInProgress}>
            {nextButtonLabel}
          </Button>
        </WebAppMainActions>
      </>
    )
  }

  return (
    <>
      {header}
      <WebAppSheet fullHeight>
        <WebAppContent>
          <RouteImportUploader
            variant="primary"
            uploadButtonLabel={intl.formatMessage({
              id: 'route_import_step_upload_uploader_button_upload',
              defaultMessage: 'Upload file',
            })}
          >
            <div className={styles['uploader-initial-content']}>
              <GPXUploadIcon className={styles['uploadIcon']} />
              <Typography paragraph>
                {intl.formatMessage({
                  id: 'route_import_upload_dialog',
                  defaultMessage:
                    'Drag and drop your .gpx and .kml files here or click the button to browse your files.',
                })}
              </Typography>
            </div>
          </RouteImportUploader>
          {isUserLoaded && !!user && (
            <>
              <Divider>
                {intl.formatMessage({
                  id: 'route_import_step_upload_content_separator',
                  defaultMessage: 'or',
                })}
              </Divider>
              <div className={styles['integrations']}>
                <Typography variant="h3">
                  {intl.formatMessage({
                    id: 'route_import_step_upload_heading_integrations',
                    defaultMessage: 'Connect with external tools',
                  })}
                </Typography>
                <Typography paragraph padding="0 1rem">
                  {intl.formatMessage({
                    id: 'route_import_step_upload_integrations_description',
                    defaultMessage: 'Seamlessly sync your Bikemap profile with your external bike computer.',
                  })}
                </Typography>
                <div className={styles['integrations-logos']}>
                  <picture>
                    <source srcSet={garminAvif} type="image/avif" />
                    <source srcSet={garminWebp} type="image/webp" />
                    <source srcSet={garminPng} type="image/png" />
                    <img className={styles['garmin-logo']} src={garminPng} alt="" width="128" height="60" />
                  </picture>
                  <picture>
                    <source srcSet={wahooAvif} type="image/avif" />
                    <source srcSet={wahooWebp} type="image/webp" />
                    <source srcSet={wahooPng} type="image/png" />
                    <img className={styles['wahoo-logo']} src={wahooPng} alt="" width="128" height="44" />
                  </picture>
                </div>
                <ButtonGroup>
                  <Button
                    href={getIntegrationsUrl({
                      ...sessionInfo,
                      slug: user?.slug,
                    })}
                    analytics={formatAnalyticsData({ event: 'integrations' })}
                    variant="secondary"
                  >
                    {intl.formatMessage({
                      id: 'route_import_integrations_button',
                      defaultMessage: 'Manage integrations',
                    })}
                  </Button>
                </ButtonGroup>
              </div>
            </>
          )}
        </WebAppContent>
      </WebAppSheet>
    </>
  )
}

export default RouteImportStepUpload
