import { FullScreenOverlay, FullScreenOverlayProps } from 'shared/ui-components'
import { SearchInput } from './search-input'
import React from 'react'

interface SearchSuggestionsOverlayProps {
  children: FullScreenOverlayProps['children']
  open: boolean
  label: string
  value: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  onReset: () => void
  onClose: () => void
  onSubmit: () => void
}

export const SearchSuggestionsOverlay = ({
  children,
  open,
  label,
  onClose,
  ...inputProps
}: SearchSuggestionsOverlayProps) => {
  return (
    <FullScreenOverlay
      open={open}
      onClose={onClose}
      // Prevent brining back focus to input field which would just re-open this overlay
      disableRestoreFocus
      aria-label={label}
      header={<SearchInput autoFocus label={label} {...inputProps} />}
    >
      {children}
    </FullScreenOverlay>
  )
}
