import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import { RouteCollectionCategory, RouteCollectionEntity, RouteEntity, UserEntity } from 'shared/data-access-core'
import { Button, ButtonGroup, Dialog, RouteCollectionItemSkeleton, ToolButton, useMessages } from 'shared/ui-components'
import { getFavoriteRoutesUrl, getRouteCollectionPath } from 'shared/util-navigation'
import { useInternalLink, useSessionInfo } from 'web-app/feature-navigation'
import { useUserState } from 'web-app/feature-user'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { toggleGlobalFavoriteRoute, unassignCollection, useAssignedRouteCollections } from 'web-app/feature-route'
import { RouteDetailsSliceDispatch, fetchCollectionsAssignedToGlobalRoute } from '../../state'
import { FavoritesItem } from './favorites-item'
import { CollectionItem } from './collection-item'
import {
  useRemoveRouteFromCollectionErrorNotification,
  useRouteRemovedAndTourBrokenNotification,
  useRouteRemovedFromCollectionNotification,
} from '../../hooks/notifications'
import { useLocale } from 'shared/util-intl'
import { collectionRouteGeometriesObsolete } from 'web-app/feature-route-collection'

import styles from './assigned-collections-list.module.scss'

interface RemoveButtonProps {
  onClick: () => void
}

const RemoveButton = ({ onClick }: RemoveButtonProps) => {
  const { removeLabel } = useMessages()

  return (
    <ToolButton variant="ghost-danger" icon={<RemoveCircleRoundedIcon />} ariaLabel={removeLabel} onClick={onClick} />
  )
}

interface FavoritesListItemProps {
  user: UserEntity
}

const FavoritesListItem = ({ user }: FavoritesListItemProps) => {
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const sessionInfo = useSessionInfo()

  const favoritesUrl = getFavoriteRoutesUrl({
    slug: user?.slug,
    ...sessionInfo,
  })

  return (
    <li className={styles['item']}>
      <a href={favoritesUrl} className={styles['link']}>
        <FavoritesItem />
      </a>
      <RemoveButton onClick={() => dispatch(toggleGlobalFavoriteRoute())} />
    </li>
  )
}

interface CollectionListItemProps {
  routeCollection: RouteCollectionEntity
  contextRouteCollectionId?: number
}

const CollectionListItem = ({ routeCollection, contextRouteCollectionId }: CollectionListItemProps) => {
  const { intl } = useLocale()
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const showRouteRemovedFromCollectionNotification = useRouteRemovedFromCollectionNotification()
  const showRouteRemovedAndTourBrokenNotification = useRouteRemovedAndTourBrokenNotification()
  const showRemoveRouteFromCollectionErrorNotification = useRemoveRouteFromCollectionErrorNotification()
  const getInternalLinkProps = useInternalLink()
  const { cancelLabel } = useMessages()

  const [isConfirmationDialogShown, setIsConfirmationDialogShown] = useState<boolean>(false)

  const isKnownTour = useMemo<boolean>(
    () => routeCollection.id === contextRouteCollectionId && routeCollection.category === RouteCollectionCategory.Tour,
    [contextRouteCollectionId, routeCollection.category, routeCollection.id],
  )

  const handleRemove = async () => {
    try {
      const { category, previousCategory } = await dispatch(unassignCollection(routeCollection.id)).unwrap()
      if (isKnownTour) {
        dispatch(collectionRouteGeometriesObsolete())
      }
      if (routeCollection.routesCount > 1 && category === null && previousCategory === RouteCollectionCategory.Tour) {
        // broke a tour
        showRouteRemovedAndTourBrokenNotification(routeCollection)
      } else {
        showRouteRemovedFromCollectionNotification(routeCollection)
      }
    } catch {
      showRemoveRouteFromCollectionErrorNotification(routeCollection)
    }
  }

  return (
    <li className={styles['item']}>
      <a {...getInternalLinkProps(getRouteCollectionPath(routeCollection.id))} className={styles['link']}>
        <CollectionItem collection={routeCollection} />
      </a>
      <RemoveButton onClick={isKnownTour ? () => setIsConfirmationDialogShown(true) : handleRemove} />
      <Dialog
        open={isConfirmationDialogShown}
        title={intl.formatMessage({
          id: 'route_details_remove_tour_dialog_title',
          defaultMessage: 'Removing route from a tour',
        })}
        text={intl.formatMessage(
          {
            id: 'route_details_remove_tour_dialog_text',
            defaultMessage:
              'If you remove this route from "{collectionTitle}" the collection might not be a tour anymore. Are you sure?',
          },
          {
            collectionTitle: routeCollection.title,
          },
        )}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" onClick={() => setIsConfirmationDialogShown(false)}>
              {cancelLabel}
            </Button>
            <Button variant="danger-primary" onClick={handleRemove}>
              {intl.formatMessage({
                id: 'route_details_remove_tour_dialog_confirm',
                defaultMessage: 'Yes, remove it',
              })}
            </Button>
          </ButtonGroup>
        }
      />
    </li>
  )
}

interface AssignedCollectionsListProps {
  route: RouteEntity
  assignedRouteCollectionIds: number[]
  contextRouteCollectionId?: number
}

export const AssignedCollectionsList = ({
  route,
  assignedRouteCollectionIds,
  contextRouteCollectionId,
}: AssignedCollectionsListProps) => {
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const { user } = useUserState()
  const assignedRouteCollections = useAssignedRouteCollections()

  useEffect(() => {
    if (assignedRouteCollectionIds.length) {
      dispatch(fetchCollectionsAssignedToGlobalRoute())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ul className={styles['list']}>
      {route.isFavorite && user && <FavoritesListItem user={user} />}
      {assignedRouteCollections
        ? assignedRouteCollections.map((collection) => (
            <CollectionListItem
              key={collection.id}
              routeCollection={collection}
              contextRouteCollectionId={contextRouteCollectionId}
            />
          ))
        : assignedRouteCollectionIds.map((id) => (
            <li className={clsx(styles['item'], styles['item-loading'])} key={id}>
              <RouteCollectionItemSkeleton />
            </li>
          ))}
    </ul>
  )
}
