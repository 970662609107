import { getFromCoreApi } from '../network'
import { API_PATH_GEO_IP } from '../config'
import { LngLat } from 'shared/util-geo'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'

type Response = {
  longitude: number
  latitude: number
}

/**
 * Get user's approximate geolocation based on request's IP address.
 * @link https://development.bikemap.net/api/docs/#/geoip_lookup/geoip_lookup_retrieve
 */
export async function fetchIpBasedLocation(): ApiResult<LngLat> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_GEO_IP)

    try {
      const parser = new ResponseParser(res)
      return createSuccessResult({
        lng: parser.requireNumber('longitude'),
        lat: parser.requireNumber('latitude'),
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch {
    return createFailureResult({ unexpectedError: true })
  }
}
