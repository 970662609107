import { useDispatch } from 'react-redux'
import { MapMarkerLocation, useMapClick, useMapContextMenu, useMapLongPress } from 'shared/ui-map'
import { ElevationCurveMapFeatures } from 'shared/feature-elevation-curve'
import { RoutePoisMapFeatures, useRoutePoiPopupProps } from 'shared/feature-route-pois'
import { locationSelected, mapPopupClosed, useRouteDetailsState } from '../state'
import { WebAppMap, MAP_ID, useWebAppMap, boundsDesired } from 'web-app/feature-map'
import { RouteDetailsMapPopup } from './route-details-map-popup'
import { MainMapRoute, useIsOwnRoute, useRouteState } from 'web-app/feature-route'
import { MapLayerTouchEvent } from 'maplibre-gl'
import { MapLayerMouseEvent } from 'react-map-gl/maplibre'
import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useCollectionContextRoutesLine } from 'web-app/feature-route-collection'

const MAP_ROUTE_ID = 'route-details-route'

const RouteDetailsMapSelection = () => {
  const dispatch = useDispatch()
  const map = useWebAppMap()
  const isRoutePoiPopupOpen = !!useRoutePoiPopupProps()
  const isOwnRoute = useIsOwnRoute()
  const { selectedLocation } = useRouteDetailsState()

  const handleMapSelection = debounce((e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    if (selectedLocation) {
      dispatch(mapPopupClosed())
    } else if (isOwnRoute && !isRoutePoiPopupOpen) {
      dispatch(locationSelected({ ...e.lngLat }))
    }
  }, 300)

  const handleMapClick = useMemo(
    () =>
      debounce((event: MapLayerMouseEvent) => {
        const currentMap = map?.getMap()
        if (currentMap && !currentMap.isZooming() && !currentMap.isMoving()) {
          handleMapSelection(event)
        }
      }, 300),
    [handleMapSelection, map],
  )

  useMapClick(MAP_ID, handleMapClick)
  useMapContextMenu(MAP_ID, handleMapSelection)
  useMapLongPress(MAP_ID, handleMapSelection)

  return selectedLocation && <MapMarkerLocation longitude={selectedLocation.lng} latitude={selectedLocation.lat} />
}

const MapRouteWithCollectionContext = () => {
  const collectionContextRoutesGeometry = useCollectionContextRoutesLine()
  return <MainMapRoute inactiveGeometry={collectionContextRoutesGeometry} />
}

interface RouteDetailsMapProps {
  routeId: number
  withCollectionContext?: boolean
}

export const RouteDetailsMap = ({ routeId, withCollectionContext }: RouteDetailsMapProps) => {
  const dispatch = useDispatch()
  const { route, isRouteLoaded } = useRouteState()

  useEffect(() => {
    if (isRouteLoaded && route?.id === routeId && route.bounds) {
      dispatch(boundsDesired(route.bounds))
    }
  }, [dispatch, isRouteLoaded, route, routeId])

  return (
    <WebAppMap>
      {route && (
        <>
          {withCollectionContext ? <MapRouteWithCollectionContext /> : <MainMapRoute />}
          <ElevationCurveMapFeatures mapRouteId={MAP_ROUTE_ID} geometry={route.geometry} />
          <RoutePoisMapFeatures mapId={MAP_ID} onSelect={() => dispatch(mapPopupClosed())} />
          <RouteDetailsMapSelection />
        </>
      )}
      <RouteDetailsMapPopup />
    </WebAppMap>
  )
}
