import { useRouteCollectionState } from '../state'
import { RouteCollectionCategory } from 'shared/data-access-core'
import { TourMapRoutes } from './tour-map-routes'
import { DefaultMapRoutes } from './default-map-routes'
import { SelectedMapRoute } from './selected-map-route'

export const RouteCollectionMapRoutes = () => {
  const { routeCollection, selectedRouteId } = useRouteCollectionState()

  return (
    <>
      {routeCollection?.category === RouteCollectionCategory.Tour ? <TourMapRoutes /> : <DefaultMapRoutes />}
      {selectedRouteId !== null && <SelectedMapRoute />}
    </>
  )
}
