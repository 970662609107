/**
 * https://bikemap.atlassian.net/wiki/spaces/MOB/pages/362774803/Ascent+Descent+calculation+on+clients
 * Threshold value chosen for filter out insignificant height changes
 */
export const ELEVATION_TOLERANCE = 3.0

/**
 * Tolerance in meters up to which elevation spikes to 0 are not flattened, eg. for routes along
 * the coast.
 */
export const ELEVATION_ZERO_SPIKE_TOLERANCE = 10
