import { Button, Link, Tag, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Popover, Typography } from '@mui/material'
import { getHelpCenterToursUrl } from 'shared/util-navigation'

import styles from './tour-tag.module.scss'

interface TourTagProps {
  className?: string
}

export const TourTag = ({ className }: TourTagProps) => {
  const { intl, language } = useLocale()
  const { closeLabel, learnMoreLabel } = useMessages()

  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false)

  const tagRef = useRef(null)

  return (
    <>
      <Tag ref={tagRef} variant="dark" className={clsx(styles['tour-tag'], className)}>
        {intl.formatMessage({
          id: 'route_collection_tour_tag',
          defaultMessage: 'Tour',
        })}
        <Link
          onColor
          className={styles['tour-tag-info-link']}
          href={getHelpCenterToursUrl(language)}
          target="_blank"
          title={intl.formatMessage({
            id: 'route_collection_tour_tag_info',
            defaultMessage: 'Info about tours',
          })}
          onClick={(e) => {
            e.preventDefault()
            setIsTooltipShown(true)
          }}
        >
          <InfoOutlinedIcon />
        </Link>
      </Tag>
      <Popover
        anchorEl={tagRef.current}
        open={isTooltipShown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsTooltipShown(false)
        }}
        slotProps={{
          paper: {
            className: styles['tooltip'],
          },
        }}
      >
        <Button
          className={styles['close-button']}
          size="medium"
          icon={<CloseRoundedIcon />}
          variant={'onColor-ghost'}
          ariaLabel={closeLabel}
          onClick={() => setIsTooltipShown(false)}
        />
        <Typography variant="h4">
          {intl.formatMessage({
            id: 'route_collection_tour_tooltip_heading',
            defaultMessage: 'What is a tour?',
          })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'route_collection_tour_tooltip_explanation',
            defaultMessage: `
A Tour is a collection of routes that are sequentially connected or no more than 2 km (1.2 mi) apart. Breaking this
criteria converts the tour to a standard collection, removing Map View access in the app version.
            `,
          })}
        </Typography>
        <div className={styles['tooltip-buttons']}>
          <Button variant="onColor-ghost" size="medium" onClick={() => setIsTooltipShown(false)}>
            {closeLabel}
          </Button>
          <Button variant="onColor-ghost" size="medium" href={getHelpCenterToursUrl(language)} target="_blank">
            {learnMoreLabel}
          </Button>
        </div>
      </Popover>
    </>
  )
}
