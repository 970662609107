import React, { SVGProps } from 'react'

export const ErrorIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 256 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#something-went-wrong-svg-a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m222.923 48.566.001 65.283a2.984 2.984 0 0 1 2.287 2.901l-.001.448 17.905.001c8.435 0 12.973 2.318 12.973 7.187 0 4.81-4.408 6.986-12.535 6.769l-.438-.014-44.338-.003-.808-.054c-6.455-.364-9.354 1.073-9.354 4.093 0 3.565 2.31 4.736 7.908 4.88l1.187.017 1.153.002h12.93a2.288 2.288 0 0 1 1.982-3.431h3.43a2.288 2.288 0 0 1 1.981 3.431h2.022v2.288l-23.516-.002a72.731 72.731 0 0 1-.848-.01c-6.964-.122-10.516-1.81-10.516-7.175 0-4.828 4.3-6.867 12.113-6.354l.422.03 44.293.001c7.325.267 10.644-1.307 10.644-4.468 0-3.126-3.218-4.832-10.258-4.898l-15.457-.001.078.002a1.716 1.716 0 0 1-.094 3.429h-20.584a1.716 1.716 0 0 1 0-3.432l3.493.001a2.972 2.972 0 0 1-.634-1.839v-.898c0-1.408.975-2.588 2.286-2.901l.001-65.283h10.292Zm5.718-22.878-.004.068c1.307.316 2.292 1.702 2.292 3.363 0 1.84-1.207 3.342-2.722 3.428v.004l-.014-.003a2.483 2.483 0 0 1-1.498-.419l-5.328-1.49c-.486.842-1.363 1.49-2.429 1.766v9.414c2.873.585 5.058 3.3 5.127 6.587l.002.16h-12.58c0-3.371 2.228-6.175 5.164-6.754l-.001-9.399c-1.08-.271-1.972-.923-2.463-1.774l-6.84 1.912-.013-.213a2.432 2.432 0 0 1-.993.213c-1.579 0-2.859-1.536-2.859-3.432 0-1.895 1.28-3.431 2.859-3.431.197 0 .39.024.576.07l-.004-.07 7.257 1.943c.646-1.15 2.018-1.943 3.607-1.943 1.589 0 2.961.793 3.608 1.943l5.095-1.364a2.521 2.521 0 0 1 1.59-.58c.145 0 .288.014.428.04l.143-.04Z"
        fill="#1D4159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.097 80.178c3.92 1.124 6.256 4.974 5.216 8.598-.66 2.302-2.522 3.96-4.797 4.575 1.147 1.134-.164 3.055-3.932 5.761l-.18.13-1.958 1.663c.07.429.049.871-.076 1.305-.52 1.812-2.652 2.791-4.763 2.185-2.111-.605-3.401-2.565-2.881-4.377.52-1.812 2.652-2.79 4.763-2.185.194.055.382.123.561.2 1.033-.919 1.915-2.652 2.645-5.2l.244-.075c-3.058-1.54-4.732-4.882-3.822-8.053 1.039-3.624 5.06-5.651 8.98-4.527ZM180.132 48.524l2.812-1.561c.836-2.917 1.982-4.166 3.438-3.749 1.457.418 1.975 1.356 1.557 2.814l-1.719 1.874c2.386 5.42 3.474 8.493 3.265 9.222-.209.73-.573.625-1.092-.313l-2.8-6.721-2.498.467-1.406.78-1.557-2.813Z"
        fill="#4D6E81"
      />
      <path d="m52.503 81.362 43.433 13.582-2.104 15.198-49.99-6.435" stroke="#4D6E81" strokeWidth=".569" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.115 85.985a2.274 2.274 0 0 0-1.557-2.814l-10.92-3.132-.165-.04a2.274 2.274 0 0 0-2.647 1.602 2.274 2.274 0 0 0 1.557 2.814l5.044 1.446-.315.114L93.626 104h4.834l-6.037-16.707.88.253.166.04a2.275 2.275 0 0 0 2.646-1.601Z"
        fill="#1D4159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.13 111.457c4.1 1.175 8.342-.489 10.63-3.829a9.674 9.674 0 0 0 1.318-2.806c1.472-5.135-1.49-10.49-6.617-11.96-5.126-1.47-10.476 1.502-11.948 6.636-1.473 5.135 1.49 10.489 6.617 11.959Z"
        fill="#879EAD"
      />
      <path
        d="m167.704 120.717-9.575-73.768c15.815-.2 24.083 2.566 24.804 8.297.72 5.73-3.447 9.27-12.501 10.62"
        stroke="#879EAD"
        strokeWidth="3.877"
        strokeLinecap="round"
      />
      <path
        d="M160.29 64.141 94.917 48.947M103.121 98.647l58.099-29.505M94.324 26.286l-.102 74.544"
        stroke="#879EAD"
        strokeWidth="3.877"
      />
      <path d="m93.349 54.416-44.68 32.168 46.018 16.747" stroke="#879EAD" strokeWidth="3.877" strokeLinejoin="round" />
      <path
        d="M38.946 120.491c19.3 5.534 39.44-5.65 44.983-24.98 5.543-19.33-5.61-39.487-24.91-45.021-19.3-5.534-39.44 5.65-44.983 24.98-5.543 19.33 5.61 39.487 24.91 45.021Z"
        stroke="#1D4159"
        strokeWidth="4.474"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.208 17.214c-7.557-4.318-11.763-4.986-12.618-2.005-1.282 4.471 3.781 12.375 12.763 11.724 5.988-.433 12.285-.24 18.891.578 2.424 1.77 3.16.907 2.209-2.592-.951-3.5-8.033-6.067-21.245-7.705Z"
        fill="#879EAD"
      />
      <path
        d="M112.262 163.878c20.041 0 36.287-5.007 36.287-11.184s-16.246-11.185-36.287-11.185-36.287 5.008-36.287 11.185c0 6.177 16.246 11.184 36.287 11.184Z"
        stroke="#1D4159"
        strokeWidth="4.474"
      />
      <path d="m197.81 118.746-3.409-6.702-17.209 8.782" stroke="#4D6E81" strokeWidth="2.485" strokeLinejoin="round" />
      <path d="m94.25 104.75 5.25 15.75h5l-5.75-17.25a2.372 2.372 0 1 0-4.5 1.5Z" fill="#1D4159" />
      <rect x="94" y="120" width="16" height="5" rx="2.5" fill="#1D4159" />
    </g>
    <defs>
      <clipPath id="something-went-wrong-svg-a">
        <path fill="#fff" d="M0 0h256v167.021H0z" />
      </clipPath>
    </defs>
  </svg>
)
