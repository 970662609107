import { API_PATH_ROUTE } from '../../config'
import {
  RouteEntity,
  RouteEntityAnalyticsData,
  RouteEntityDetails,
  RouteEntityMapData,
  UserEntity,
} from '../../entities'
import { getFromCoreApi } from '../../network'
import {
  ApiFailureResult,
  ApiResult,
  AuthError,
  GoneError,
  MinimalEndpointErrors,
  NotFoundError,
  ResponseParser,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'
import { AlongTheRouteAttribute, isValidGeometry } from 'shared/util-geo'
import { MultiLineString, MultiPoint, Point, Polygon } from 'geojson'
import { getWaypointsAndControlPointIndexes, parseUserEntity } from './helpers'
import { enrichGeometry, mapToBikeType, mapToSurface } from '../helpers'

type Response = {
  admin_two_id?: number
  altitude_difference_back: number | null
  altitude_difference: number | null
  average_speed: null // seems to be always null
  bounding_box: Polygon
  category: number[] | null
  copied_from: number | null
  created: string
  description: string | null
  distance: number
  duration: number | null
  external_id?: string
  gpx: string
  ground: number[] | null
  has_pois: boolean
  hasz: boolean
  id: number
  is_favorite: boolean
  is_loop: boolean
  is_private: boolean
  kml: string
  location?: string
  max_elevation: number | null
  points: MultiLineString
  route_favorite_count: number
  images: {
    fallback: string
    item: string | null
    tile: string | null
  }[]
  start: Point
  staticmaps: {
    bmx_big: string | null
    preview: string | null
    small: string | null
  } | null
  title: string
  user: {
    displayname: string
    id: number
    image: {
      fallback: string
      small: string | null
    } | null
    is_subscribed: boolean
    slug: string
  }
  views: number
}

type RouteData = {
  route: RouteEntity
  creator: UserEntity | null
}

type RouteErrors = MinimalEndpointErrors & {
  gone?: true
  notFound?: true
  unauthorized?: true
}

/**
 * Fetch an existing route and its creator (user).
 */
export async function fetchRoute(routeId: number): ApiResult<RouteData, RouteErrors> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_ROUTE, {
      params: { routeId },
    })

    try {
      const creator = parseUserEntity(res.user)
      return createSuccessResult({
        route: parseRouteEntity(new ResponseParser(res), creator?.id ?? null),
        creator,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    return handleRequestError(error, routeId)
  }
}

export type RouteDetailsData = {
  route: RouteEntity & RouteEntityDetails & RouteEntityMapData & RouteEntityAnalyticsData
  creator: UserEntity | null
  collectionIds: number[]
}

type RouteDetailsErrors = RouteErrors & {
  invalidGeometry?: true
}

/**
 * Fetch detailed info and geometry of an existing route and related information.
 */
export async function fetchRouteDetails(routeId: number): ApiResult<RouteDetailsData, RouteDetailsErrors> {
  try {
    const res: Response & {
      bike_network: AlongTheRouteAttribute<string>[] | null
      bm_surface: AlongTheRouteAttribute<string>[] | null
      bm_way_type: AlongTheRouteAttribute<string>[] | null
      /** 3rd dimension is index of control point */
      control_point_indexes: number[] | null
      routecollections: number[]
      waypoints: MultiPoint | null
    } = await getFromCoreApi(API_PATH_ROUTE, {
      params: { routeId },
      queryParams: {
        include: 'waypoints,control_point_indexes,routecollections,bm_surface,bm_way_type,bike_network',
      },
    })

    if (!isValidGeometry(res.points)) {
      return createFailureResult({ invalidGeometry: true }, { routeId, res })
    }

    try {
      const parser = new ResponseParser(res)
      const geometry = parser.requireLineString('points')
      const creator = parseUserEntity(res.user)

      const baseEntity = parseRouteEntity(new ResponseParser(res as Response), creator?.id ?? null)

      return createSuccessResult({
        route: {
          ...baseEntity,
          bounds: parser.requireBounds('bounding_box'),
          copiedFrom: parser.takeNumber('copied_from'),
          description: parser.takeString('description'),
          externalId: parser.takeString('external_id'),
          geometry: await enrichGeometry(
            geometry,
            {
              surfaces: res.bm_surface?.length ? res.bm_surface[0] : undefined,
              wayTypes: res.bm_way_type?.length ? res.bm_way_type[0] : undefined,
              bikeNetworks: res.bike_network?.length ? res.bike_network[0] : undefined,
            }, // TODO WEB-1701 pass along-the-route info from backend
            baseEntity.distanceMeters,
            baseEntity.durationSeconds,
          ),
          gpxUrl: parser.requireString('gpx'),
          hasPois: false,
          kmlUrl: parser.requireString('kml'),
          ...getWaypointsAndControlPointIndexes(geometry, res.waypoints, res.control_point_indexes),
        },
        creator,
        collectionIds: res.routecollections,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    return handleRequestError(error, routeId)
  }
}

function parseRouteEntity(parser: ResponseParser<Response>, creatorId: number | null): RouteEntity {
  const locationName = parser.takeString('location')
  const geonameId = parser.takeNumber('admin_two_id')
  const distanceMeters = parser.requireNumber('distance')
  const durationSeconds = parser.takeNumber('duration')
  return {
    id: parser.requireNumber('id'),
    title: parser.requireString('title'),
    distanceMeters,
    ascentMeters: parser.takeNumber('altitude_difference'),
    descentMeters: parser.takeNumber('altitude_difference_back'),
    durationSeconds,
    averageSpeedMetersPerSecond: durationSeconds ? distanceMeters / durationSeconds : null,
    maximumElevationMeters: parser.takeNumber('max_elevation'),
    isPrivate: parser.requireBoolean('is_private'),
    location: geonameId && locationName ? { geonameId, name: locationName } : null,
    favoriteCount: parser.requireNumber('route_favorite_count'),
    created: parser.requireTimestamp('created'),
    surfaces: parser.takeArray('ground', mapToSurface),
    bikeTypes: parser.takeArray('category', mapToBikeType),
    images: parser.takeAsImageSizesList('images', {
      item: 'item',
      tile: 'tile',
      original: 'fallback',
    }),
    isFavorite: parser.requireBoolean('is_favorite'),
    isLoop: parser.requireBoolean('is_loop'),
    staticMap: parser.takeImageSizesWithoutFallback('staticmaps', {
      item: 'preview',
      tile: 'bmx_big',
      tileSecondary: 'small',
      overview: 'bmx_big',
      medium: 'bmx_big',
    }),
    start: parser.requireLngLat('start'),
    creatorId,
  }
}

function handleRequestError(error: unknown, routeId: number): ApiFailureResult<RouteErrors> {
  if (error instanceof AuthError) {
    return createFailureResult({ unauthorized: true }, { routeId })
  }
  if (error instanceof GoneError) {
    return createFailureResult({ gone: true }, { routeId })
  }
  if (error instanceof NotFoundError) {
    return createFailureResult({ notFound: true }, { routeId })
  }
  return createFailureResult({ unexpectedError: true }, { routeId })
}
