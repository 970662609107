import { useMemo } from 'react'
import { Feature } from 'geojson'
import { ROUTE_OUTLINE_PROPS, ROUTE_LINE_PROPS } from './helpers'
import { LineLayerSpecification } from 'maplibre-gl'
import { colors } from 'shared/ui-design-system'
import { RouteGeometryPreview } from './map-tour-routes'
import { castToLineString } from 'shared/util-geo'

type LayerProps = Omit<LineLayerSpecification, 'source'>

const IDENTIFIER = 'alternating-routes'
const COLOR_KEYS = [
  'polyline1',
  'polyline2',
  'polyline3',
  'polyline4',
  'polyline5',
  'polyline6',
  'polyline7',
  'polyline8',
  'polyline9',
  'polyline10',
] as const

export const useAlternatingRouteLines = (
  id: string,
  segments: RouteGeometryPreview[],
  highlightedRouteId: number | null,
): {
  features: Feature[]
  lineLayerProps: LayerProps
  outlineLayerProps: LayerProps
} => {
  const features = useMemo<Feature[]>(
    () =>
      segments.map(({ routeId, geometry }, segmentIndex) => {
        const alternatingColorIndex = segmentIndex % 10
        const isHighlighted = routeId === highlightedRouteId
        const colorSet = colors['polylineRandom'][COLOR_KEYS[alternatingColorIndex]] as {
          foreground: string
          background: string
        }
        return {
          type: 'Feature',
          geometry: castToLineString(geometry),
          properties: {
            routeId,
            type: IDENTIFIER,
            segmentIndex,
            lineColor: colorSet[isHighlighted ? 'background' : 'foreground'],
            outlineColor: colorSet[isHighlighted ? 'foreground' : 'background'],
          },
        }
      }),
    [highlightedRouteId, segments],
  )
  const lineLayerProps = useMemo<LayerProps>(
    () => ({
      ...ROUTE_LINE_PROPS,
      id: `${id}-${IDENTIFIER}-line`,
      filter: ['==', ['get', 'type'], IDENTIFIER],
      paint: {
        ...ROUTE_LINE_PROPS.paint,
        'line-color': ['get', 'lineColor'],
      },
    }),
    [id],
  )
  const outlineLayerProps = useMemo<LayerProps>(
    () => ({
      ...ROUTE_OUTLINE_PROPS,
      id: `${id}-${IDENTIFIER}-outline`,
      filter: ['==', ['get', 'type'], IDENTIFIER],
      paint: {
        ...ROUTE_OUTLINE_PROPS.paint,
        'line-color': ['get', 'outlineColor'],
      },
    }),
    [id],
  )
  return { features, lineLayerProps, outlineLayerProps }
}
