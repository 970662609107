import { createSelector } from 'reselect'
import { RouteCollectionCategory, RouteCollectionEntity, RouteEntity, UserEntity } from 'shared/data-access-core'
import {
  AssignedRoutesListItemState,
  ROUTE_COLLECTION_SLICE_KEY,
  RouteCollectionState,
  StateWithRouteCollectionSlice,
} from './types'
import { StateWithUserSlice, USER_SLICE_KEY } from 'web-app/feature-user'
import { useSelector } from 'react-redux'
import { ENTITIES_SLICE_KEY, StateWithEntitiesSlice } from 'web-app/data-access-entities'
import { RouteGeometryPreview, RouteStart } from 'shared/ui-map'
import { getRouteType } from './helpers'
import { RichLineString, RichMultiLineString } from 'shared/util-geo'

export const routeCollectionSliceSelector = (state: StateWithRouteCollectionSlice): RouteCollectionState =>
  state[ROUTE_COLLECTION_SLICE_KEY]

export const useRouteCollectionState = () => useSelector(routeCollectionSliceSelector)

export const routeCollectionSelector: (state: StateWithRouteCollectionSlice) => RouteCollectionEntity | undefined =
  createSelector(routeCollectionSliceSelector, (slice) => slice.routeCollection)

/** Use the current main route collection from the app's global state */
export const useRouteCollection = () => useSelector(routeCollectionSelector)

export const routeCollectionOwnerSelector: (
  state: StateWithRouteCollectionSlice & StateWithEntitiesSlice,
) => UserEntity | undefined = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].routeCollection,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].users,
  (routeCollection, users) => routeCollection && users[routeCollection.owner],
)

/** Use the owner of the current main route collection from the app's global state */
export const useRouteCollectionOwner = () => useSelector(routeCollectionOwnerSelector)

export const assignedRouteIdsSelector: (state: StateWithRouteCollectionSlice) => undefined | number[] = createSelector(
  routeCollectionSliceSelector,
  (slice) => slice.assignedRouteIds,
)

/** Use the IDs of routes assigned to the current main route collection from the app's global state */
export const useAssignedRouteIds = () => useSelector(assignedRouteIdsSelector)

export const isOwnRouteCollectionSelector: (
  state: StateWithRouteCollectionSlice & StateWithUserSlice,
) => boolean | undefined = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].routeCollection,
  (state: StateWithUserSlice) => state[USER_SLICE_KEY].user,
  (state: StateWithUserSlice) => state[USER_SLICE_KEY].isUserLoaded,
  (routeCollection, user, isUserLoaded) => {
    if (user && isUserLoaded) {
      return !!(user.isStaff || routeCollection?.owner === user.id)
    }
    return undefined
  },
)

/** Use the information whether the main route collection belongs to the current user */
export const useIsOwnRouteCollection = () => useSelector(isOwnRouteCollectionSelector)

export const routeCollectionRouteStartsSelector: (
  state: StateWithRouteCollectionSlice & StateWithEntitiesSlice,
) => RouteStart[] | null = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].assignedRouteIds,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routes,
  (assignedRouteIds, routes) =>
    assignedRouteIds
      ? assignedRouteIds.reduce((items: RouteStart[], routeId) => {
          const route = routes[routeId]
          if (!route) return items
          items.push({
            routeId,
            routeType: getRouteType(route.bikeTypes),
            position: route.start,
          })
          return items
        }, [])
      : null,
)

export const useRouteCollectionRouteStarts = () => useSelector(routeCollectionRouteStartsSelector)

export const routeCollectionGeometriesSelector: (
  state: StateWithRouteCollectionSlice & StateWithEntitiesSlice,
) => RouteGeometryPreview[] | null = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].assignedRouteIds,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routes,
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].geometries,
  (assignedRouteIds, routes, geometries) =>
    assignedRouteIds
      ? assignedRouteIds.reduce((items: RouteGeometryPreview[], routeId) => {
          const route = routes[routeId]
          const geometry = geometries[routeId]
          if (!route || !geometry) return items
          items.push({
            routeId,
            routeType: getRouteType(route.bikeTypes),
            geometry,
          })
          return items
        }, [])
      : null,
)

export const useRouteCollectionGeometries = () => useSelector(routeCollectionGeometriesSelector)

export const assignedRoutesSelector: (
  state: StateWithRouteCollectionSlice & StateWithEntitiesSlice,
) => AssignedRoutesListItemState[] | null = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].assignedRouteIds,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routes,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].users,
  (assignedRouteIds, routes, users) =>
    assignedRouteIds
      ? assignedRouteIds.reduce((items: AssignedRoutesListItemState[], routeId) => {
          const route = routes[routeId]
          if (!route) return items
          const creator = typeof route.creatorId === 'number' ? users[route.creatorId] : undefined
          items.push({
            key: route.id.toString(),
            route,
            creator,
          })
          return items
        }, [])
      : null,
)

export const useAssignedRoutes = () => useSelector(assignedRoutesSelector)

export const selectedRoutePreviewSelector: (
  state: StateWithRouteCollectionSlice & StateWithEntitiesSlice,
) => (RouteEntity & { geometry: RichLineString | null }) | null = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].selectedRouteId,
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].geometries,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routes,
  (selectedRouteId, geometries, routes) => {
    if (!selectedRouteId) return null
    const geometry = geometries[selectedRouteId] || null
    const routeEntity = routes[selectedRouteId]
    return routeEntity ? { ...routeEntity, geometry } : null
  },
)

export const useSelectedRoutePreview = () => useSelector(selectedRoutePreviewSelector)

export const collectionContextRoutesLineSelector: (state: StateWithRouteCollectionSlice) => RichMultiLineString =
  createSelector(
    (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].geometries,
    (geometries) => ({
      type: 'RichMultiLineString',
      coordinates: Object.values(geometries).map((g) => g.coordinates),
    }),
  )

export const useCollectionContextRoutesLine = () => useSelector(collectionContextRoutesLineSelector)

export const tourGeometriesSelector: (
  state: StateWithRouteCollectionSlice & StateWithEntitiesSlice,
) => RichMultiLineString | undefined = createSelector(
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].routeCollection,
  (state: StateWithRouteCollectionSlice) => state[ROUTE_COLLECTION_SLICE_KEY].assignedRouteIds,
  routeCollectionGeometriesSelector,
  (routeCollection, assignedRouteIds, geometryPreviews) =>
    routeCollection?.category === RouteCollectionCategory.Tour &&
    geometryPreviews &&
    geometryPreviews.length === assignedRouteIds?.length
      ? {
          type: 'RichMultiLineString',
          coordinates: geometryPreviews.map(({ geometry }) => geometry.coordinates),
        }
      : undefined,
)

export const useTourGeometries = () => useSelector(tourGeometriesSelector)
