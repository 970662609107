import { ImagesLightbox } from '../components/images/images-lightbox'
import { EmbedConfigurator } from '../components/embedding/embed-configurator'
import { WebAppAdditionalContent } from 'web-app/ui-layout'
import { useMemo } from 'react'
import { useRoute } from 'web-app/feature-route'
import { EMBED_FRAGMENT_HASH, IMAGE_FRAGMENT_HASH } from '../config'
import { useFragmentRouting } from '../use-fragment-routing'

export const RouteDetailsAdditionalContent = () => {
  const { fragmentHash, fragmentValue, resetFragment } = useFragmentRouting()
  const route = useRoute()

  const openImageIndex = useMemo<number | null>(() => {
    if (
      route &&
      fragmentHash === IMAGE_FRAGMENT_HASH &&
      fragmentValue !== null &&
      fragmentValue >= 0 &&
      fragmentValue < route?.images.length
    ) {
      return fragmentValue
    }
    return null
  }, [fragmentHash, fragmentValue, route])

  return (
    <WebAppAdditionalContent
      open={openImageIndex !== null || fragmentHash === EMBED_FRAGMENT_HASH}
      onClose={resetFragment}
      closeButtonOnColor
    >
      {openImageIndex !== null ? <ImagesLightbox openImageIndex={openImageIndex} /> : <EmbedConfigurator />}
    </WebAppAdditionalContent>
  )
}
