import { useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { showNotification } from 'web-app/feature-notifications'
import { RouteImportApiService } from 'shared/data-access-core'
import {
  uploadRequest,
  uploadSuccess,
  uploadFailure,
  getRouteDataSuccess,
  getRouteDataFailure,
  uploadCanceled,
  useRouteImportState,
} from '../state'

/**
 * Handles uploading and processing routes with error handling, canceling, and other necessary actions.
 */
export const useRouteUpload = (): [(files: File[]) => void, () => void] => {
  const { intl } = useLocale()
  const dispatch = useDispatch()

  const { statusTaskId, routeData } = useRouteImportState()

  const routeImportApi = useMemo<RouteImportApiService>(() => new RouteImportApiService(), [])

  const showNotificationError = useCallback(
    (description: string, analyticsId: string) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_upload_error_notification_title',
            defaultMessage: 'Route import failed',
          }),
          description,
          variant: 'danger',
        },
        analyticsId,
      )
    },
    [intl],
  )

  const onRouteFileDrop = async (files: File[]) => {
    const errorNotificationInvalidFile = intl.formatMessage({
      id: 'route_upload_error_notification_description_invalid_file',
      defaultMessage: 'Selected file is not valid. Please make sure you are using .gpx or .kml file.',
    })

    if (!(files && files[0])) {
      // Can be empty if wrong file type was dropped
      showNotificationError(errorNotificationInvalidFile, 'error_route_import_upload_invalid_file')
      return
    }

    dispatch(uploadRequest(files[0].name))

    const uploadResult = await routeImportApi.uploadRouteForProcessing(files[0])

    // Ignore the response if canceled by user in the meantime
    if (uploadResult.success && !routeImportApi.isCanceled()) {
      dispatch(uploadSuccess(uploadResult.data))
    } else if (!uploadResult.success) {
      if (uploadResult.errors.invalidFileType) {
        showNotificationError(errorNotificationInvalidFile, 'error_route_import_upload_invalid_file')
      } else {
        showNotificationError(
          intl.formatMessage({
            id: 'route_upload_error_notification_description_processing_start',
            defaultMessage: 'Could not start processing this route. Please try again later.',
          }),
          'error_route_import_upload_processing_start',
        )
      }
      dispatch(uploadFailure())
    }
  }

  const onCancel = () => {
    routeImportApi.cancel()
    dispatch(uploadCanceled())
  }

  const processRoute = useCallback(async () => {
    // If we have uploaded file in progress (route task IDs) but not final route data
    if (statusTaskId && !routeData) {
      // Start fetching route data
      const result = await routeImportApi.getRouteData(statusTaskId)

      if (result.success) {
        if (result.data.isOriginallyMultiLine) {
          showNotification(
            {
              title: intl.formatMessage({
                id: 'route_upload_notification_only_first_segment_title',
                defaultMessage: 'Only imported first segment',
              }),
              description: intl.formatMessage({
                id: 'route_upload_notification_only_first_segment_description',
                defaultMessage:
                  'Routes with multiple disconnected segments are not supported. Only the first part of your route could be imported to Bikemap.',
              }),
              variant: 'warning',
              duration: Infinity,
            },
            'warning_route_import_upload_multipart_route',
          )
        }

        // This will also move to the next step
        dispatch(getRouteDataSuccess(result.data))
      } else if (!routeImportApi.isCanceled()) {
        showNotificationError(
          intl.formatMessage({
            id: 'route_upload_error_notification_description_processing',
            defaultMessage: 'Could not process this route. Please try again later.',
          }),
          'error_route_import_upload_processing',
        )

        dispatch(getRouteDataFailure())
      }
    }
  }, [statusTaskId, routeData, routeImportApi, dispatch, intl, showNotificationError])

  useEffect(() => {
    // When upload states change (on file upload or page reload)
    processRoute()
  }, [processRoute])

  return [onRouteFileDrop, onCancel]
}
