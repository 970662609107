import { useLocale } from 'shared/util-intl'
import { showNotification, useCommonErrorNotification } from 'web-app/feature-notifications'

export function useRouteCollectionImageUploadNotifications() {
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()

  return {
    showFileTypeErrorNotification: (fileName: string) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_collection_image_file_type_error_title',
            defaultMessage: 'Unsupported file type',
          }),
          description: intl.formatMessage(
            {
              id: 'route_collection_image_file_type_error_description',
              defaultMessage:
                'The selected file "{fileName}" is not supported. Please upload a .jpg, .jpeg or .png file.',
            },
            {
              fileName,
            },
          ),
          variant: 'danger',
        },
        'route_collection_image_file_type_error',
      )
    },

    showFileSizeErrorNotification: (maxFileSize: number) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_collection_image_file_size_error_title',
            defaultMessage: 'File too large',
          }),
          description: intl.formatMessage(
            {
              id: 'route_collection_image_file_size_error_description',
              defaultMessage: 'The selected image file exceeds the maximum allowed file size of {maxFileSize}MB.',
            },
            {
              maxFileSize,
            },
          ),
          variant: 'danger',
        },
        'route_collection_image_file_size_error',
      )
    },

    showUploadErrorNotification: () => {
      showCommonErrorNotification(
        intl.formatMessage({
          id: 'route_collection_image_upload_error_details',
          defaultMessage:
            'Route collection image could not be uploaded. Please try again later or use a different image.',
        }),
      )
    },
  }
}
