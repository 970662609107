import { useMemo } from 'react'
import { Feature } from 'geojson'
import { SymbolLayerSpecification } from 'maplibre-gl'
import { RouteStart } from '../types'
import { useMapImage } from '../use-map-image'
import { lngLatToPosition2d } from 'shared/util-geo'
import { ROUTE_MARKER_LAYER_LAYOUT } from '../settings'

import defaultMarkerImg from '../img/route-marker-default.png'
import mtbMarkerImg from '../img/route-marker-mtb.png'
import roadBikeMarkerImg from '../img/route-marker-road-bike.png'

type LayerProps = Omit<SymbolLayerSpecification, 'source'>

const IDENTIFIER = 'route-markers'

export const useRouteMarkers = (
  id: string,
  routes: RouteStart[],
  highlightedRouteId: number | null,
): {
  features: Feature[]
  layerProps: LayerProps
} => {
  useMapImage(defaultMarkerImg, 'route-marker-default')
  useMapImage(mtbMarkerImg, 'route-marker-mtb')
  useMapImage(roadBikeMarkerImg, 'route-marker-road-bike')

  const features = useMemo<Feature[]>(
    () =>
      routes.map(
        ({ routeId, routeType, position }): Feature => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lngLatToPosition2d(position),
          },
          properties: {
            routeId,
            icon: `route-marker-${routeType}`,
            active: routeId === highlightedRouteId,
          },
        }),
      ),
    [highlightedRouteId, routes],
  )
  const layerProps = useMemo<LayerProps>(
    () => ({
      id: `${id}-${IDENTIFIER}`,
      type: 'symbol',
      layout: ROUTE_MARKER_LAYER_LAYOUT,
    }),
    [id],
  )
  return { features, layerProps }
}
