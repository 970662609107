import { useCallback, useState } from 'react'
import { BASE_SIZE_PX, HEIGHT_BREAKPOINT_REM, LAYOUT_BREAKPOINT_REM, NAVIGATION_BREAKPOINT_REM } from './definitions'
import { useOnDebouncedWindowResize } from './use-on-debounced-window-resize'

type BreakpointFlags = {
  layoutBreakpoint: boolean
  navigationBreakpoint: boolean
  heightBreakpoint: boolean
}

const getBreakpointFlags = (): BreakpointFlags => {
  return {
    layoutBreakpoint: window.innerWidth >= LAYOUT_BREAKPOINT_REM * BASE_SIZE_PX,
    navigationBreakpoint: window.innerWidth >= NAVIGATION_BREAKPOINT_REM * BASE_SIZE_PX,
    heightBreakpoint: window.innerHeight >= HEIGHT_BREAKPOINT_REM * BASE_SIZE_PX,
  }
}

export const useBreakpoints = (): BreakpointFlags => {
  const [breakpointFlags, setBreakpointFlags] = useState<BreakpointFlags>(getBreakpointFlags)

  useOnDebouncedWindowResize(
    useCallback(() => {
      setBreakpointFlags(getBreakpointFlags)
    }, []),
  )

  return breakpointFlags
}
