import { useDispatch } from 'react-redux'
import { RouteSliceDispatch, saveRouteChanges, useIsOwnRoute, useRoute } from 'web-app/feature-route'

export function useRouteContentEditing<ValueType>(name: string): {
  name: string
  canEdit?: boolean
  onSubmit: (value: ValueType) => Promise<void>
} {
  const dispatch = useDispatch() as RouteSliceDispatch
  const route = useRoute()
  const isOwnRoute = useIsOwnRoute()

  return {
    name,
    canEdit: isOwnRoute,
    onSubmit: async (value: ValueType) => {
      if (route) {
        const form = { [name]: value }
        await dispatch(saveRouteChanges({ form })).unwrap()
      }
    },
  }
}
