import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { formatAnalyticsData } from 'shared/util-analytics'
import { Button, FieldSet, MassiveRadioButton } from 'shared/ui-components'
import { useRouteImportState, routeSimplifyChanged, stepPrev, stepNext, reset } from '../state'
import { useRouteSimplificationSimplifyStep } from './use-route-simplification'
import ImageSimplifyYes from './images/route-preview-simplify-yes.svg'
import ImageSimplifyYesCompact from './images/route-preview-simplify-yes-compact.svg'
import ImageSimplifyNo from './images/route-preview-simplify-no.svg'
import ImageSimplifyNoCompact from './images/route-preview-simplify-no-compact.svg'
import { Typography } from '@mui/material'
import { WebAppContent, WebAppContentLoading, WebAppMainActions, WebAppSheet } from 'web-app/ui-layout'
import { ToolHeader, useCancel } from 'web-app/feature-navigation'

const RouteImportStepSimplify = () => {
  const { intl } = useLocale()
  const dispatch = useDispatch()
  const cancel = useCancel()
  const { isUploadInProgress, isSimplifySelected } = useRouteImportState()
  useRouteSimplificationSimplifyStep()

  const optionPropsYes = {
    name: 'route-import-simplify-option',
    label: intl.formatMessage({
      id: 'route_import_step_simplify_option_yes_label',
      defaultMessage: 'Smoothen GPS inaccuracies',
    }),
    description: intl.formatMessage({
      id: 'route_import_step_simplify_option_yes_description',
      defaultMessage:
        'Remove inaccurate GPS spikes while preserving your original route, time stamps and other important information.',
    }),
    checked: isSimplifySelected,
    onChange: () => dispatch(routeSimplifyChanged(true)),
    analytics: formatAnalyticsData({
      event: 'gps_trackpoints_choice',
      'remove-spikes': 'true',
    }),
  }

  const optionPropsNo = {
    name: 'route-import-simplify-option',
    label: intl.formatMessage({
      id: 'route_import_step_simplify_option_no_label',
      defaultMessage: 'Keep original GPS trackpoints',
    }),
    description: intl.formatMessage({
      id: 'route_import_step_simplify_option_no_description',
      defaultMessage:
        "Don't optimize your route and keep all original GPS trackpoints in place. Keep in mind that this makes the route harder to modify in the route editor afterwards!",
    }),
    checked: !isSimplifySelected,
    onChange: () => dispatch(routeSimplifyChanged(false)),
    analytics: formatAnalyticsData({
      event: 'gps_trackpoints_choice',
      'remove-spikes': 'false',
    }),
  }

  const importButtonLabel = intl.formatMessage({
    id: 'route_import_import_button',
    defaultMessage: 'Import',
  })

  const onCancel = () => {
    dispatch(reset())
    cancel()
  }

  return (
    <>
      <ToolHeader
        title={intl.formatMessage({
          id: 'route_import_title_step_simplify',
          defaultMessage: 'GPS options',
        })}
        onBack={() => dispatch(stepPrev())}
        onCancel={onCancel}
      />
      <WebAppSheet>
        {isUploadInProgress ? (
          <WebAppContentLoading />
        ) : (
          <WebAppContent
            renderLarge={() => (
              <FieldSet role="radiogroup">
                <MassiveRadioButton {...optionPropsYes} image={<ImageSimplifyYes />} />
                <MassiveRadioButton {...optionPropsNo} image={<ImageSimplifyNo />} />
              </FieldSet>
            )}
            renderSmall={() => (
              <>
                <Typography variant="h3">
                  {intl.formatMessage({
                    id: 'route_import_step_simplify_label_choose',
                    defaultMessage: 'Choose between',
                  })}
                </Typography>
                <FieldSet role="radiogroup">
                  <MassiveRadioButton isCompact {...optionPropsYes} image={<ImageSimplifyYesCompact />} />
                  <MassiveRadioButton isCompact {...optionPropsNo} image={<ImageSimplifyNoCompact />} />
                </FieldSet>
              </>
            )}
          />
        )}
      </WebAppSheet>
      <WebAppMainActions>
        <Button
          block
          onClick={() => dispatch(stepNext())}
          disabled={isUploadInProgress}
          ariaLabel={importButtonLabel}
          data-testid="route-import-import-button"
          data-step="simplify"
        >
          {importButtonLabel}
        </Button>
      </WebAppMainActions>
    </>
  )
}

export default RouteImportStepSimplify
