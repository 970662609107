import { IntlShape } from './types'

export const commonMessages = (intl: IntlShape) => ({
  backLabel: intl.formatMessage({
    id: 'common_back',
    defaultMessage: 'Back',
  }),
  cancelLabel: intl.formatMessage({
    id: 'common_cancel',
    defaultMessage: 'Cancel',
  }),
  closeLabel: intl.formatMessage({
    id: 'common_close',
    defaultMessage: 'Close',
  }),
  collapseLabel: intl.formatMessage({
    id: 'common_collapse',
    defaultMessage: 'Collapse',
  }),
  deleteLabel: intl.formatMessage({
    id: 'common_delete',
    defaultMessage: 'Delete',
  }),
  errorLabel: intl.formatMessage({
    id: 'common_error',
    defaultMessage: 'Error',
  }),
  expandLabel: intl.formatMessage({
    id: 'common_expand',
    defaultMessage: 'Expand',
  }),
  resetLabel: intl.formatMessage({
    id: 'common_reset',
    defaultMessage: 'Reset',
  }),
  removeLabel: intl.formatMessage({
    id: 'common_remove',
    defaultMessage: 'Remove',
  }),
  editLabel: intl.formatMessage({
    id: 'common_edit',
    defaultMessage: 'Edit',
  }),
  saveLabel: intl.formatMessage({
    id: 'common_save',
    defaultMessage: 'Save',
  }),
  showMoreLabel: intl.formatMessage({
    id: 'common_show_more',
    defaultMessage: 'Show more',
  }),
  privateLabel: intl.formatMessage({
    id: 'common_private',
    defaultMessage: 'Private',
  }),
  publicLabel: intl.formatMessage({
    id: 'common_public',
    defaultMessage: 'Public',
  }),
  shareLabel: intl.formatMessage({
    id: 'common_share',
    defaultMessage: 'Share',
  }),
  undoLabel: intl.formatMessage({
    id: 'common_undo',
    defaultMessage: 'Undo',
  }),
  learnMoreLabel: intl.formatMessage({
    id: 'common_learn_more',
    defaultMessage: 'Learn more',
  }),
})
