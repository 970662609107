import { getHelpCenterUrl } from 'shared/util-navigation'
import { Button, ButtonGroup, NotFoundIllustration } from 'shared/ui-components'
import { WebAppMap, WebAppMapControls, WebAppMapFooter, WebAppMapLayout } from 'web-app/feature-map'
import { WebAppContent, WebAppSheet } from 'web-app/ui-layout'
import { useUserState } from 'web-app/feature-user'
import InfoIcon from '@mui/icons-material/Info'
import { useLocale } from 'shared/util-intl'
import { DefaultHeader, DiscoverLink, RoutePlannerLink, SignupLoginLink } from 'web-app/feature-navigation'
import { Typography } from '@mui/material'

import styles from './ViewNotFound.module.scss'

export const ViewNotFound = () => {
  const { intl, language } = useLocale()
  const { isLoggedIn } = useUserState()

  const helpButtonLabel = intl.formatMessage({
    id: 'view_not_found_help_center_button',
    defaultMessage: 'Visit our help center',
  })

  return (
    <WebAppMapLayout initialFold="full">
      <DefaultHeader />
      <WebAppSheet>
        <WebAppContent>
          <div className={styles['illustration']}>
            <NotFoundIllustration />
          </div>
          <Typography variant="h3" component="h2" color="textSecondary" textAlign="center">
            {intl.formatMessage({
              id: 'view_not_found_title',
              defaultMessage: 'View not found!',
            })}
          </Typography>
          <Typography paragraph color="textSecondary" textAlign="center">
            {isLoggedIn
              ? intl.formatMessage({
                  id: 'view_not_found_dialog_logged_in',
                  defaultMessage: 'We couldn’t find what you where looking for. Here are some options to continue.',
                })
              : intl.formatMessage({
                  id: 'view_not_found_dialog_anonymous',
                  defaultMessage:
                    'We couldn’t find what you were looking for. Continue by signing up or logging in if you already own a Bikemap account.',
                })}
          </Typography>
        </WebAppContent>
        <WebAppContent>
          {isLoggedIn ? (
            <ButtonGroup stack>
              <DiscoverLink primary />
              <RoutePlannerLink />
              <Button
                variant="secondary"
                href={getHelpCenterUrl(language)}
                icon={<InfoIcon />}
                ariaLabel={helpButtonLabel}
              >
                {helpButtonLabel}
              </Button>
            </ButtonGroup>
          ) : (
            <SignupLoginLink primary />
          )}
        </WebAppContent>
      </WebAppSheet>
      <WebAppMap />
      <WebAppMapControls />
      <WebAppMapFooter />
    </WebAppMapLayout>
  )
}

export default ViewNotFound
