import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, ButtonGroup, UploaderContainer, UploaderFilePreview, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { logError } from 'web-app/utils-error-handling'
import { MAX_IMAGE_FILE_SIZE_MB } from '../config'
import { useRouteCollectionImageUploadNotifications } from '../notifications'
import { useEditableContentContext } from 'web-app/feature-editable-content'
import { resizeImageForUpload } from 'web-app/util-file-upload'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { FileRejection, useDropzone } from 'react-dropzone'
import { RouteCollectionSliceDispatch, saveGlobalRouteCollectionChanges } from 'web-app/feature-route-collection'
import { useDispatch } from 'react-redux'

interface RouteCollectionImageUploaderProps {
  currentImage: { tile: string } | null
}

export const RouteCollectionImageUploader = ({ currentImage }: RouteCollectionImageUploaderProps) => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { intl } = useLocale()
  const { cancelLabel, saveLabel } = useMessages()
  const notifications = useRouteCollectionImageUploadNotifications()
  const { onEditingDone } = useEditableContentContext()

  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [isCurrentImageRemoved, setIsCurrentImageRemoved] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isResizing, setIsResizing] = useState<boolean>(false)

  const onRouteImageDrop = async (files: File[]) => {
    setIsResizing(true)
    try {
      setSelectedImage(await resizeImageForUpload(files[0]))
    } catch (error) {
      logError('Error processing image', error)
      notifications.showUploadErrorNotification()
    }
    setIsResizing(false)
  }

  const onDropRejected = (rejectedImages: FileRejection[]): void => {
    try {
      const rejection = rejectedImages[0]
      const code = rejection.errors[0].code
      const fileName = rejection.file.name
      if (code === 'file-invalid-type') {
        notifications.showFileTypeErrorNotification(fileName)
      }
      if (code === 'file-too-large') {
        notifications.showFileSizeErrorNotification(MAX_IMAGE_FILE_SIZE_MB)
      }
    } catch (e) {
      logError('Problem showing errors of rejected images', e, { rejectedImages })
    }
  }

  const handleCancel = () => {
    onEditingDone()
    setSelectedImage(null)
  }

  const canSubmit = selectedImage || isCurrentImageRemoved

  /**
   * Upload added or remove deleted image.
   */
  const handleSubmit = async () => {
    if (!canSubmit) return
    setIsUploading(true)
    try {
      await dispatch(saveGlobalRouteCollectionChanges({ image: selectedImage })).unwrap()
      onEditingDone()
      setIsCurrentImageRemoved(false)
    } catch {
      notifications.showUploadErrorNotification()
    }
    setIsUploading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isUploading || isResizing,
    onDrop: onRouteImageDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    multiple: false,
    onDropRejected,
  })

  const canReplace = (currentImage && !isCurrentImageRemoved) || selectedImage

  const uploadButtonLabel = canReplace
    ? intl.formatMessage({
        id: 'route_collection_image_upload_button_replace',
        defaultMessage: 'Replace cover image',
      })
    : intl.formatMessage({
        id: 'route_collection_image_upload_button',
        defaultMessage: 'Set cover image',
      })

  return (
    <UploaderContainer
      label={intl.formatMessage({
        id: 'route_collection_image_label',
        defaultMessage: 'Cover image',
      })}
      hideLabel
      dropzoneRootProps={getRootProps()}
      dropzoneInputProps={getInputProps()}
      loading={isUploading || isResizing}
      messages={{
        dropFilesHere: intl.formatMessage({
          id: 'route_collection_image_drop_label',
          defaultMessage: 'Drop your picture here...',
        }),
      }}
      isDragActive={isDragActive}
      renderUploadButton={(props) => (
        <Button {...props} icon={canReplace ? <AutorenewRoundedIcon /> : <AddIcon />} ariaLabel={uploadButtonLabel}>
          {uploadButtonLabel}
        </Button>
      )}
      renderActions={() => (
        <ButtonGroup>
          <Button variant="secondary" onClick={handleCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={!canSubmit} onClick={handleSubmit}>
            {saveLabel}
          </Button>
        </ButtonGroup>
      )}
    >
      {selectedImage ? (
        <UploaderFilePreview
          wide
          imageUrl={
            selectedImage.type && selectedImage.type.includes('image') ? URL.createObjectURL(selectedImage) : undefined
          }
          fileType="image"
          onRemove={() => setSelectedImage(null)}
        />
      ) : currentImage && !isCurrentImageRemoved ? (
        <UploaderFilePreview
          wide
          imageUrl={currentImage.tile}
          fileType="image"
          onRemove={() => setIsCurrentImageRemoved(true)}
        />
      ) : null}
    </UploaderContainer>
  )
}
