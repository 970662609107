import { Skeleton, Typography } from '@mui/material'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'
import { Link } from 'shared/ui-components'
import { useNavigate } from 'react-router-dom'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { EditableString } from 'web-app/feature-editable-content'
import { useLocale } from 'shared/util-intl'
import { useRouteCollectionContentEditing } from '../use-route-collection-content-editing'

import styles from './route-collection-header.module.css'

interface EditableRouteHeaderProps {
  title?: string
  previousView?: RouteDetailsPreviousView
}

export const EditableRouteCollectionHeader = ({ title, previousView }: EditableRouteHeaderProps) => {
  const { intl } = useLocale()
  const navigate = useNavigate()
  const contentEditingProps = useRouteCollectionContentEditing('title')

  return (
    <EditableString
      currentValue={title}
      renderContent={({ value, editButton }) => (
        <>
          {editButton && <div className={styles['floating-container']}>{editButton}</div>}
          {previousView && (
            <Link
              marginBottom="0.25rem"
              href={previousView.path}
              onClick={(e) => {
                e.preventDefault()
                navigate(previousView.path)
              }}
            >
              <ArrowBackRoundedIcon /> {previousView.label}
            </Link>
          )}
          <Typography variant="h3" component="h1" className={styles['title']}>
            {value}
          </Typography>
        </>
      )}
      renderLoading={() => (
        <Typography variant="h3" component="h1" marginBottom="0.25rem">
          <Skeleton width="80%" data-testid="skeleton" />
        </Typography>
      )}
      label={intl.formatMessage({
        id: 'route_collection_title_input_label',
        defaultMessage: 'Collection title',
      })}
      placeholder={intl.formatMessage({
        id: 'route_collection_title_input_placeholder',
        defaultMessage: 'Enter a route collection title...',
      })}
      isRequired
      {...contentEditingProps}
    />
  )
}
