import { formatDuration, formatLargeLength, formatLength, formatSpeed } from 'shared/util-formatting'
import { AvailableLocale, useLocale } from 'shared/util-intl'

type RouteStatsFormatters = {
  formatRouteDuration: (durationSeconds: number | null) => string
  formatRouteDistance: (distanceMeters: number | null) => string
  formatRouteSpeed: (speedInMs: number | null) => string
  formatRouteElevation: (elevationInM: number | null) => string
}

export const useRouteStatsFormatters = (isUnitPreferenceImperial: boolean): RouteStatsFormatters => {
  const { language } = useLocale()

  return {
    formatRouteDuration: (durationSeconds: number | null) => {
      return getFormattedRouteDuration(durationSeconds, language)
    },
    formatRouteDistance: (distanceMeters: number | null) => {
      return getFormattedRouteDistance(distanceMeters, language, isUnitPreferenceImperial)
    },
    formatRouteSpeed: (speedInMs: number | null) => {
      return getFormattedRouteSpeed(speedInMs, language, isUnitPreferenceImperial)
    },
    formatRouteElevation: (elevationInM: number | null) => {
      return getFormattedRouteElevation(elevationInM, language, isUnitPreferenceImperial)
    },
  }
}

/**
 * Format duration of a route entity.
 */
export function getFormattedRouteDuration(durationSeconds: number | null, language: AvailableLocale): string {
  return durationSeconds !== null && durationSeconds > 0 ? formatDuration(durationSeconds, language) : '-:--'
}

/**
 * Format distance of a route entity.
 */
export function getFormattedRouteDistance(
  distanceMeters: number | null,
  language: AvailableLocale,
  isUnitPreferenceImperial: boolean,
): string {
  return distanceMeters !== null ? formatLargeLength(distanceMeters, language, isUnitPreferenceImperial) : '---'
}

/**
 * Format average speed of a route entity.
 */
export function getFormattedRouteSpeed(
  speedInMs: number | null,
  language: AvailableLocale,
  isUnitPreferenceImperial: boolean,
): string {
  if (speedInMs !== null) {
    return formatSpeed(speedInMs, language, isUnitPreferenceImperial)
  }
  return '---'
}

/**
 * Format ascent, descent or other elevation related value of a route entity.
 */
export function getFormattedRouteElevation(
  elevationInM: number | null,
  language: AvailableLocale,
  isUnitPreferenceImperial: boolean,
): string {
  if (elevationInM !== null) {
    return formatLength(elevationInM, language, isUnitPreferenceImperial)
  }
  return '---'
}
