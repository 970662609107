import { GeocoderLocation } from 'shared/data-access-geocoding'

export type LocationDisplayValues = {
  displayValueTitle: string
  displayValueSubtitle: string | null
}

/**
 * Formats geocoder location into a consistent division into two lines.
 */
export function formatLocationDisplayValues({
  poiName,
  address,
  country,
  admin1,
}: Omit<GeocoderLocation, 'position' | 'type'>): LocationDisplayValues {
  if (poiName) {
    return {
      displayValueTitle: poiName,
      displayValueSubtitle: country ? `${address}, ${country}` : address,
    }
  }

  return {
    displayValueTitle: address,
    displayValueSubtitle: country && admin1 ? `${admin1}, ${country}` : country || null,
  }
}
