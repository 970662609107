import React, { CSSProperties, ReactNode } from 'react'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import { Modal as MuiModal, ModalProps as MuiModalProps, Fade, Zoom, Backdrop } from '@mui/material'
import { usePrevious } from '../../shared/hooks'
import { ToolButton } from '../ToolButton'
import { useMessages } from '../MessagesProvider'

import styles from './Modal.module.scss'

type ModalOnCloseReasons = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'

type MuiModalOverrideProps = {
  onClose?: (e: React.MouseEvent | React.KeyboardEvent, reason: ModalOnCloseReasons) => void
}

export type ModalProps = Omit<MuiModalProps, 'children'> &
  MuiModalOverrideProps & {
    onOpen?: () => void
    'aria-label': string
    buttons?: React.ReactNode
    closeButtonOnColor?: boolean
    fullScreen?: boolean
    children: ReactNode
    width?: string
  }

export function Modal({ onOpen, buttons, closeButtonOnColor, children, fullScreen, width, ...modalProps }: ModalProps) {
  const { closeLabel } = useMessages()

  const prevOpen = usePrevious(modalProps.open)
  if (onOpen && modalProps.open === true && prevOpen !== modalProps.open) {
    onOpen()
  }

  const onCloseButtonClick = (e: React.MouseEvent | React.KeyboardEvent): void => {
    if (modalProps.onClose) {
      modalProps.onClose(e, 'closeButtonClick')
    }
  }

  const dismissButtonProps = {
    onClick: onCloseButtonClick,
    icon: <CloseIcon />,
    'data-testid': 'dismiss-button',
    ariaLabel: closeLabel,
  }

  return (
    <MuiModal
      closeAfterTransition
      BackdropComponent={Backdrop} // Necessary for close on backdrop click
      {...modalProps}
    >
      <Fade in={modalProps.open}>
        <div className={styles['animated-full-height']}>
          <Zoom in={modalProps.open} style={{ transformOrigin: 'center center center' }}>
            <div className={styles['animated-full-height']}>
              <div
                className={clsx(styles['modal'], { [styles['full-screen']]: fullScreen })}
                style={width ? ({ '--modal-width': width } as CSSProperties) : undefined}
              >
                {modalProps.onClose && (
                  <span className={styles['dismiss-button']}>
                    <ToolButton
                      {...dismissButtonProps}
                      variant={closeButtonOnColor ? 'onColor-ghost' : 'ghost-secondary'}
                    />
                  </span>
                )}
                <div className={styles['content']}>{children}</div>
                {buttons && <div className={styles['buttons-container']}>{buttons}</div>}
              </div>
            </div>
          </Zoom>
        </div>
      </Fade>
    </MuiModal>
  )
}
