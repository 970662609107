import { useState } from 'react'
import { Button, ButtonGroup } from 'shared/ui-components'
import { NavigationIcon } from 'shared/ui-design-system/icons/navigation-icon'
import { useLocale } from 'shared/util-intl'
import { getNavigationDynamicLink, getRouteDetailsDynamicLink } from 'shared/util-navigation'
import { RouteEditLink } from 'web-app/feature-navigation'
import { useIsOwnRoute, useRoute } from 'web-app/feature-route'
import { PremiumModalNavigation, useUser } from 'web-app/feature-user'
import { WebAppMainActions, useBreakpoints } from 'web-app/ui-layout'

interface RouteDetailsMainActionsProps {
  routeId: number
}

export const RouteDetailsMainActions = ({ routeId }: RouteDetailsMainActionsProps) => {
  const { intl } = useLocale()
  const route = useRoute()
  const isOwnRoute = useIsOwnRoute()
  const [user, isUserLoaded] = useUser()
  const { layoutBreakpoint } = useBreakpoints()

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState<boolean>(false)

  return (
    <WebAppMainActions>
      <ButtonGroup>
        <Button
          icon={<NavigationIcon />}
          href={getNavigationDynamicLink(routeId, {
            utmSource: 'web_app',
            utmMedium: 'route_details',
            utmCampaign: 'navigate',
          })}
          withPremiumIndicator={isUserLoaded && !user?.isPremium}
          disabled={!isUserLoaded || !route}
        >
          {intl.formatMessage({
            id: 'route_details_footer_button_navigate',
            defaultMessage: 'Navigate',
          })}
        </Button>
        <PremiumModalNavigation isOpen={isPremiumModalOpen} onClose={() => setIsPremiumModalOpen(false)} />
        {route && isOwnRoute ? (
          <RouteEditLink routeId={route.id}>
            {intl.formatMessage({
              id: 'route_details_footer_button_edit',
              defaultMessage: 'Edit route',
            })}
          </RouteEditLink>
        ) : (
          !layoutBreakpoint &&
          !user?.isPremium && (
            <Button
              variant="secondary"
              href={getRouteDetailsDynamicLink(routeId, {
                utmSource: 'web_app',
                utmMedium: 'route_details',
                utmCampaign: 'open_in_app',
              })}
            >
              {intl.formatMessage({
                id: 'route_details_footer_button_app',
                defaultMessage: 'Open in app',
              })}
            </Button>
          )
        )}
      </ButtonGroup>
    </WebAppMainActions>
  )
}
