import { Button, ButtonGroup, Dialog } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useDispatch } from 'react-redux'
import { reset, useRoutePrintState } from './state'
import { useState } from 'react'
import { ToolHeader, useNavigateToRouteDetails } from 'web-app/feature-navigation'

interface RoutePrintHeaderProps {
  routeId: number
}

export const RoutePrintHeader = ({ routeId }: RoutePrintHeaderProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const { detailedMapViewports, detailedMapImages } = useRoutePrintState()

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  const cancel = () => {
    dispatch(reset())
    navigateToRouteDetails(routeId)
  }

  const handleCancel = () => {
    if (Object.keys(detailedMapViewports).length || Object.keys(detailedMapImages).length) {
      setIsCancelDialogOpen(true)
    } else {
      cancel()
    }
  }

  const handleCancelConfirm = () => {
    setIsCancelDialogOpen(false)
    cancel()
  }

  return (
    <>
      <ToolHeader
        title={intl.formatMessage({
          id: 'route_print_title',
          defaultMessage: 'Route print',
        })}
        onCancel={handleCancel}
      />
      <Dialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        title={intl.formatMessage({
          id: 'route_print_cancel_dialog_title',
          defaultMessage: 'Do you really want to quit?',
        })}
        text={intl.formatMessage({
          id: 'route_print_cancel_dialog_text',
          defaultMessage:
            'You will lose the changes you made so far. If you would like to print it later, you can already save it as PDF. Just click "Print or Save" and select PDF in the browser dialog.',
        })}
        buttons={
          <ButtonGroup stack>
            <Button variant="secondary" onClick={() => setIsCancelDialogOpen(false)}>
              {intl.formatMessage({
                id: 'route_print_cancel_dialog_cancel',
                defaultMessage: 'Cancel',
              })}
            </Button>
            <Button variant="danger-primary" onClick={handleCancelConfirm}>
              {intl.formatMessage({
                id: 'route_print_cancel_dialog_confirm',
                defaultMessage: 'Dismiss changes',
              })}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  )
}
