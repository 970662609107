import { LineString, MultiLineString } from 'geojson'
import { RichLineString, composeRichLineString } from '../lib/rich-geometry'

export const dummyGeometry: LineString = {
  type: 'LineString',
  coordinates: [
    [17.111, 49.112, 111.0],
    [17.113, 49.114, 113.0],
    [17.117, 49.113, 105.0],
  ],
}

export const richDummyGeometry: RichLineString = composeRichLineString(dummyGeometry)

export const longerDummyGeometry: LineString = {
  type: 'LineString',
  coordinates: [
    [15.16624, 47.69274, 802.53],
    [15.16629, 47.69269, 805],
    [15.16636, 47.69267, 807.86],
    [15.16644, 47.69268, 810.54],
    [15.16695, 47.69301, 818.72],
    [15.16722, 47.69315, 821.86],
    [15.16743, 47.69324, 824.53],
    [15.16805, 47.69346, 827.74],
    [15.16856, 47.69369, 828.87],
    [15.16894, 47.69394, 830.52],
    [15.16921, 47.69421, 832.62],
    [15.16927, 47.69425, 833],
    [15.16941, 47.69431, 833.97],
    [15.16954, 47.69434, 834.9],
    [15.16976, 47.69432, 836.16],
    [15.17048, 47.69408, 833.98],
    [15.17064, 47.69404, 832.75],
    [15.17091, 47.69401, 830.99],
    [15.17141, 47.69402, 827.87],
    [15.17333, 47.6941, 829.56],
    [15.17379, 47.69415, 828.15],
    [15.17411, 47.69422, 827.46],
    [15.17582, 47.69473, 821.18],
  ],
}

export const longerRichDummyGeometry: RichLineString = composeRichLineString(longerDummyGeometry)

export const dummyMultiGeometry: MultiLineString = {
  type: 'MultiLineString',
  coordinates: [
    [
      [17.111, 49.112, 111.0],
      [17.113, 49.114, 113.0],
      [17.117, 49.113, 105.0],
    ],
    [
      [15.111, 47.112, 110.0],
      [15.113, 47.114, 111.1],
    ],
  ],
}
