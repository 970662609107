import { useMemo, useState } from 'react'
import { RichLineString } from 'shared/util-geo'
import { InlineTabs, InlineTabsOption } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { WayTypesDiagram } from './way-types-diagram'
import { BikeNetworkDiagram } from './bike-network-diagram'

type TabKey = 'wayTypes' | 'bikeNetwork'

interface WayTypesAndBikeNetworkProps {
  geometry: RichLineString
  routeDistanceMeters: number
}

export const WayTypesAndBikeNetwork = ({ geometry, routeDistanceMeters }: WayTypesAndBikeNetworkProps) => {
  const { intl } = useLocale()

  const [openTab, setOpenTab] = useState<TabKey>('wayTypes')

  const tabOptions = useMemo<InlineTabsOption<TabKey>[]>(
    () => [
      {
        value: 'wayTypes',
        label: intl.formatMessage({
          id: 'route_way_types_tab',
          defaultMessage: 'Waytype',
        }),
      },
      {
        value: 'bikeNetwork',
        label: intl.formatMessage({
          id: 'route_bike_network_tab',
          defaultMessage: 'Cycle route network',
        }),
      },
    ],
    [intl],
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <InlineTabs options={tabOptions} value={openTab} onSelect={setOpenTab} />
      {openTab === 'wayTypes' && <WayTypesDiagram geometry={geometry} routeDistanceMeters={routeDistanceMeters} />}
      {openTab === 'bikeNetwork' && (
        <BikeNetworkDiagram geometry={geometry} routeDistanceMeters={routeDistanceMeters} />
      )}
    </div>
  )
}
