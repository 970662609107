import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'
import { LocationList } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

interface SuggestionRoundTripProps {
  onClick: () => void
}

export const SuggestionRoundTrip = ({ onClick }: SuggestionRoundTripProps) => {
  const { intl } = useLocale()

  return (
    <LocationList.Action
      title={intl.formatMessage({
        id: 'route_planner_location_results_back_to_start',
        defaultMessage: 'Back to starting point',
      })}
      subtitle={intl.formatMessage({
        id: 'route_planner_location_results_round_trip_subtitle',
        defaultMessage: 'Creates a round trip',
      })}
      icon={<SettingsBackupRestoreRoundedIcon />}
      onClick={onClick}
    />
  )
}
