import { LineString, MultiLineString, Point, Polygon } from 'geojson'
import { LngLat, LngLatBoundsArray, convertToLineString, positionToLngLat } from 'shared/util-geo'
import bbox from '@turf/bbox'

export class ResponseParserError<ExpectedResponseType> extends Error {
  constructor(key: keyof ExpectedResponseType) {
    super(`Unexpected value of ${key.toString()} in response.`)
    this.name = 'ResponseParserError'
  }
}

// Utility type to extract keys based on value type
type KeysWithValueType<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never
}[keyof T]

type ImageSizesResponse = {
  fallback: string
  [key: string]: string | null
}

export class ResponseParser<ExpectedResponseType extends Record<string, unknown>> {
  private res: ExpectedResponseType

  constructor(res: ExpectedResponseType) {
    this.res = res
  }

  requireNumber(key: KeysWithValueType<ExpectedResponseType, number>): number {
    const value = this.res[key]
    if (typeof value !== 'number') {
      throw new ResponseParserError(key.toString())
    }
    return value
  }

  takeNumber(key: KeysWithValueType<ExpectedResponseType, number | null | undefined>): number | null {
    const value = this.res[key]
    if (typeof value === 'number') {
      return value
    }
    if (typeof value === 'undefined' || value === null) {
      return null
    }
    throw new ResponseParserError(key.toString())
  }

  takeAsNumber(key: KeysWithValueType<ExpectedResponseType, number | string>): number {
    const value = this.res[key]
    if (typeof value === 'number') {
      return value
    }
    if (typeof value !== 'string') {
      throw new ResponseParserError(key.toString())
    }
    const number = Number.parseFloat(value as string)
    if (typeof number !== 'number' || Number.isNaN(number)) {
      throw new ResponseParserError(key.toString())
    }
    return number
  }

  requireString(key: KeysWithValueType<ExpectedResponseType, string>): string {
    const value = this.res[key]
    if (!value || typeof value !== 'string') {
      throw new ResponseParserError(key.toString())
    }
    return value
  }

  takeString(key: KeysWithValueType<ExpectedResponseType, string | null | undefined>): string | null {
    const value = this.res[key]
    if (value && typeof value !== 'string') {
      throw new ResponseParserError(key.toString())
    }
    return (value as string) || null
  }

  requireBoolean(key: KeysWithValueType<ExpectedResponseType, boolean>): boolean {
    const value = this.res[key]
    if (typeof value !== 'boolean') {
      throw new ResponseParserError(key.toString())
    }
    return value
  }

  takeAsBoolean(key: KeysWithValueType<ExpectedResponseType, boolean | null | undefined>): boolean {
    return !!this.res[key]
  }

  takeArray<ItemType, ResultType>(
    key: KeysWithValueType<ExpectedResponseType, ItemType[] | null | undefined>,
    mapFn: (item: ItemType) => ResultType | null,
  ): ResultType[] {
    const value = this.res[key]
    if (!value) {
      return []
    }
    if (!Array.isArray(value)) {
      throw new ResponseParserError(key.toString())
    }
    return value.map(mapFn).filter((v) => v !== null) as ResultType[]
  }

  in<Key extends KeysWithValueType<ExpectedResponseType, Record<string, unknown> | null | undefined>>(key: Key) {
    const value = this.res[key]
    if (!value) {
      throw new ResponseParserError(key.toString())
    }
    return new ResponseParser(value)
  }

  inOptional<Key extends KeysWithValueType<ExpectedResponseType, Record<string, unknown> | null | undefined>>(
    key: Key,
  ) {
    const value = this.res[key]
    return value ? new ResponseParser(value) : new NullResponseParser()
  }

  has(key: keyof ExpectedResponseType): boolean {
    const value = this.res[key]
    return typeof value !== 'undefined' && value !== null
  }

  takeImageSizes<MappingType extends Record<string, string>>(
    key: KeysWithValueType<ExpectedResponseType, ImageSizesResponse | null | undefined>,
    mapping: MappingType,
  ): Record<keyof MappingType, string> | null {
    const value = this.res[key] as ImageSizesResponse | null
    if (!value) {
      return null
    }
    if (typeof value !== 'object' || !value.fallback || typeof value.fallback !== 'string') {
      throw new ResponseParserError(key.toString())
    }
    const imageSizes: Record<keyof MappingType, string> = { ...mapping }
    for (const key in imageSizes) {
      const imageSizeValue = value[mapping[key]]
      if (!imageSizeValue) {
        imageSizes[key] = value.fallback
      } else if (typeof imageSizeValue !== 'string') {
        throw new ResponseParserError(key.toString())
      } else {
        imageSizes[key] = imageSizeValue
      }
    }
    return imageSizes
  }

  takeImageSizesWithoutFallback<MappingType extends Record<string, string>>(
    key: KeysWithValueType<ExpectedResponseType, Omit<ImageSizesResponse, 'fallback'> | null | undefined>,
    mapping: MappingType,
  ): Record<keyof MappingType, string> | null {
    const value = this.res[key] as ImageSizesResponse | null
    if (!value) {
      return null
    }
    if (typeof value !== 'object') {
      throw new ResponseParserError(key.toString())
    }
    const imageSizes: Record<keyof MappingType, string> = { ...mapping }
    for (const key in imageSizes) {
      const imageSizeValue = value[mapping[key]]
      if (!imageSizeValue) {
        return null
      }
      if (typeof imageSizeValue !== 'string') {
        throw new ResponseParserError(key.toString())
      }
      imageSizes[key] = imageSizeValue
    }
    return imageSizes
  }

  takeAsImageSizesList<MappingType extends Record<string, string>>(
    key: KeysWithValueType<ExpectedResponseType, ImageSizesResponse[] | null>,
    mapping: MappingType,
  ): Record<keyof MappingType, string>[] {
    const value = this.res[key] as ImageSizesResponse[] | null
    if (!value) {
      return []
    }
    if (!Array.isArray(value)) {
      throw new ResponseParserError(key.toString())
    }
    return value.map((item) => {
      if (!item.fallback || typeof item.fallback !== 'string') {
        throw new ResponseParserError(key.toString())
      }
      const imageSizes: Record<keyof MappingType, string> = { ...mapping }
      for (const key in imageSizes) {
        imageSizes[key] = item[mapping[key]] || item.fallback
      }
      return imageSizes
    })
  }

  requireLngLat(key: KeysWithValueType<ExpectedResponseType, Point>): LngLat {
    const value = this.res[key] as Point
    if (value?.type === 'Point' && value.coordinates?.length >= 2) {
      return positionToLngLat(value.coordinates)
    }
    throw new ResponseParserError(key.toString())
  }

  requireLineString(key: KeysWithValueType<ExpectedResponseType, LineString | MultiLineString>): LineString {
    const value = this.res[key] as LineString | MultiLineString
    if (value?.type === 'LineString' && value.coordinates?.length) {
      return value
    }
    if (value?.type === 'MultiLineString' && value.coordinates?.length) {
      return convertToLineString(value)
    }
    throw new ResponseParserError(key.toString())
  }

  requireTimestamp(key: KeysWithValueType<ExpectedResponseType, string>): number {
    const value = this.res[key]
    if (typeof value === 'string') {
      const timestamp = Date.parse(value)
      if (!Number.isNaN(timestamp)) {
        return timestamp
      }
    }
    throw new ResponseParserError(key.toString())
  }

  requireBounds(key: KeysWithValueType<ExpectedResponseType, Polygon>): LngLatBoundsArray {
    const value = this.res[key] as Polygon
    if (value?.type === 'Polygon' && value.coordinates?.length) {
      const bboxResult = bbox(value)
      return bboxResult.length === 4 ? bboxResult : [bboxResult[0], bboxResult[1], bboxResult[3], bboxResult[4]]
    }
    throw new ResponseParserError(key.toString())
  }

  takeBoundsArray(key: KeysWithValueType<ExpectedResponseType, number[] | null | undefined>): LngLatBoundsArray | null {
    const value = this.res[key]
    if (!value) {
      return null
    }
    if (Array.isArray(value) && value?.length === 4) {
      const [lat1, lng1, lat2, lng2] = value
      return [lng1, lat1, lng2, lat2]
    }
    throw new ResponseParserError(key.toString())
  }

  takeAsStringArray(key: KeysWithValueType<ExpectedResponseType, string[] | null>): string[] {
    const value = this.res[key] as string[]
    if (!value) {
      return []
    }
    if (!Array.isArray(value)) {
      throw new ResponseParserError(key.toString())
    }
    for (const item of value) {
      if (typeof item !== 'string') {
        throw new ResponseParserError(key.toString())
      }
    }
    return value
  }
}

class NullResponseParser {
  requireNumber(key: string): null {
    return null
  }

  requireString(key: string): null {
    return null
  }

  takeString(key: string): null {
    return null
  }

  has(key: string): null {
    return null
  }
}
