'use client'

import { RouteEntity } from 'shared/data-access-core'
import { RouteMiniItem, RouteMiniItemProps } from 'shared/ui-components'
import { useRouteMessages } from './use-route-messages'

type OmittedProps = 'title' | 'location' | 'image' | 'staticMap' | 'messages'

export interface RouteEntityMiniItemProps extends Omit<RouteMiniItemProps, OmittedProps> {
  route: RouteEntity
  imperial?: boolean
}

/**
 * Renders a route mini item based on a given route entity.
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const RouteEntityMiniItem = ({ route, imperial, ...routeMiniItemProps }: RouteEntityMiniItemProps) => {
  const { locationLabel } = useRouteMessages()

  return (
    <RouteMiniItem
      title={route.title}
      location={route.location?.name}
      image={route.images.length ? route.images[0].item : undefined}
      staticMap={route.staticMap?.item || undefined}
      messages={{ locationLabel }}
      {...routeMiniItemProps}
    />
  )
}
