import { DiscoverRouteMarkers } from 'shared/ui-map'
import { MAP_ID, WebAppMapPopup } from 'web-app/feature-map'
import { useDiscoverRouteStarts } from './state'
import { LngLat } from 'shared/util-geo'
import { MainMapRoute, MainRouteItem, useRoute } from 'web-app/feature-route'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'

export type RouteSelection = LngLat & {
  id: number
}

interface DiscoverMapRoutesProps {
  selectedRoute: RouteSelection | null
  interactive?: boolean
  routeDetailsPreviousView?: RouteDetailsPreviousView
  onRouteSelect: (routeId: number, start: LngLat) => void
  onSelectionCancel: () => void
}

export const DiscoverMapRoutes = ({
  selectedRoute,
  interactive = true,
  routeDetailsPreviousView,
  onRouteSelect,
  onSelectionCancel,
}: DiscoverMapRoutesProps) => {
  const routeStarts = useDiscoverRouteStarts()
  const route = useRoute()

  return (
    <>
      {routeStarts && (
        <DiscoverRouteMarkers
          id="discover-routes"
          mapId={MAP_ID}
          routeStarts={routeStarts}
          interactive={interactive}
          onClick={onRouteSelect}
          onClickOutside={() => selectedRoute && onSelectionCancel()}
        />
      )}
      {selectedRoute && (
        <>
          <MainMapRoute />
          <WebAppMapPopup
            longitude={selectedRoute.lng}
            latitude={selectedRoute.lat}
            markerHeight={route && 40}
            maxWidth="23.5rem"
          >
            <MainRouteItem routeDetailsPreviousView={routeDetailsPreviousView} onClick={() => onSelectionCancel()} />
          </WebAppMapPopup>
        </>
      )}
    </>
  )
}
