import clsx from 'clsx'
import { MouseEvent, ReactNode } from 'react'
import { BikemapBikeIcon } from 'shared/ui-design-system/icons/bikemap-bike-icon'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { Skeleton } from '@mui/material'
import { Link } from '../Link'

import styles from './route-mini-item.module.scss'

type RenderImageProps = {
  className: string
  src: string
  width: number
  height: number
  alt: string
  loading: 'lazy' | 'eager'
}

export interface RouteMiniItemProps {
  title: string
  location?: string
  image?: string
  staticMap?: string
  href: string
  eagerLoading?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  messages: {
    locationLabel: string
  }
  renderImage?: (props: RenderImageProps) => ReactNode
  analyticsId?: string
}

export const RouteMiniItem = ({
  title,
  location,
  image,
  staticMap,
  href,
  eagerLoading,
  onClick,
  messages,
  renderImage,
  analyticsId,
}: RouteMiniItemProps) => {
  const getImage = (): ReactNode => {
    const src = image || staticMap
    if (src) {
      const imageProps: RenderImageProps = {
        className: styles['image'],
        src,
        alt: title,
        loading: eagerLoading ? 'eager' : 'lazy',
        width: 72,
        height: 72,
      }
      return renderImage ? renderImage(imageProps) : <img {...imageProps} alt={imageProps.alt} />
    }
    return null
  }

  return (
    <div className={styles['container']}>
      <div className={styles['media']}>{getImage() || <BikemapBikeIcon className={styles['placeholder-icon']} />}</div>
      <div className={styles['content']}>
        <Link secondary className={styles['title']} href={href} onClick={onClick} data-aid={analyticsId}>
          {title}
        </Link>
        <dl className={styles['attributes']}>
          {location && (
            <div className={clsx(styles['attribute-item'], styles['location-attribute'])}>
              <dt>
                <span>{messages.locationLabel}</span>
                <LocationOnRoundedIcon fontSize="small" />
              </dt>
              <dd>{location}</dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  )
}

export const RouteMiniItemSkeleton = () => (
  <div className={styles['container']} style={{ paddingRight: '3.5rem' }}>
    <div className={styles['media']}>
      <Skeleton variant="rectangular" height="100%" />
    </div>
    <div className={styles['content']}>
      <div className={styles['title']}>
        <Skeleton />
      </div>
      <dl className={styles['attributes']}>
        <div className={styles['attribute-item']}>
          <Skeleton width="7rem" />
        </div>
      </dl>
    </div>
  </div>
)
