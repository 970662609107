import clsx from 'clsx'
import React, { ForwardedRef, forwardRef } from 'react'
import { BaseButton, BaseButtonProps } from '../../base/BaseButton'

import styles from './floating-button.module.scss'

export interface FloatingButtonProps extends BaseButtonProps {
  children?: string
  icon?: React.ReactNode
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const FloatingButton = forwardRef(
  (
    { children, className, icon, variant = 'primary', disabled = false, onClick, ...rest }: FloatingButtonProps,
    ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const isIconOnly = !!icon && !children

    const baseButtonProps: BaseButtonProps = {
      className: clsx(styles['button'], className, {
        [styles['icon-only']]: isIconOnly,
      }),
      disabled,
      variant,
      type: 'button',
      onClick,
      ...rest,
    }

    const iconClasses = clsx(styles['icon'], {
      [styles['icon-only-icon']]: isIconOnly,
    })

    return (
      <BaseButton {...baseButtonProps} ref={ref}>
        {!!icon && <span className={iconClasses}>{icon}</span>}
        {children}
      </BaseButton>
    )
  },
)
