import { useEffect, useState } from 'react'
import {
  RouteEntity,
  UserEntity,
  addRouteToFavorites,
  fetchRoute,
  removeRouteFromFavorites,
} from 'shared/data-access-core'
import { RouteTileSkeleton } from 'shared/ui-components'
import { WebAppRouteEntityTile } from './route-entity-tile'

interface RouteByIdTileProps {
  routeId: number
  imperial?: boolean
}

/**
 * Renders a route tile skeleton while fetching data and then renders a route tile based on local route entity state.
 * Useful if entity hasn't been fetched yet and won't be relevant anywhere else.
 */
export const RouteByIdTile = ({ routeId, imperial }: RouteByIdTileProps) => {
  const [route, setRoute] = useState<RouteEntity>()
  const [creator, setCreator] = useState<UserEntity | null>()

  useEffect(() => {
    fetchRoute(routeId).then((result) => {
      if (result.success) {
        setRoute(result.data.route)
        setCreator(result.data.creator)
      }
    })
  }, [routeId])

  const handleFavoriteToggle = async () => {
    if (!route) return
    const originalRoute = route
    if (route.isFavorite) {
      setRoute({
        ...route,
        isFavorite: false,
        favoriteCount: route.favoriteCount - 1,
      })
      const result = await removeRouteFromFavorites(routeId)
      if (!result.success) {
        setRoute(originalRoute)
      }
    } else {
      setRoute({
        ...route,
        isFavorite: true,
        favoriteCount: route.favoriteCount + 1,
      })
      const result = await addRouteToFavorites(routeId)
      if (!result.success) {
        setRoute(originalRoute)
      }
    }
  }

  return route ? (
    <WebAppRouteEntityTile
      route={route}
      creator={creator ?? null}
      imperial={imperial}
      onFavoriteToggle={handleFavoriteToggle}
    />
  ) : (
    <RouteTileSkeleton />
  )
}
