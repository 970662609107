/**
 * Index used for state normalization, to be used as single source of truth for entities.
 */
export type EntityIndex<Entity> = { [id: number]: Entity }

/**
 * Derive an array of entities from a given list of IDs and an entities index.
 */
export function getFromIndex<Entity>(ids: number[], index: EntityIndex<Entity>): Entity[] {
  const list: Entity[] = []
  for (const id of ids) {
    if (index[id]) {
      list.push(index[id])
    }
  }
  return list
}
