import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonGroup, Input } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { RouteSliceDispatch } from 'web-app/feature-route'
import { createAndAssignCollection } from '../../state'
import { PrivacyFieldSet } from 'web-app/feature-editable-content'

import styles from './create-collection-form.module.css'

const MIN_TITLE_LENGTH = 3

interface CreateCollectionFormProps {
  onCancel: () => void
  onDone: () => void
}

export const CreateCollectionForm = ({ onCancel, onDone }: CreateCollectionFormProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const { intl } = useLocale()

  const [title, setTitle] = useState<string>('')
  const [isPrivate, setIsPrivate] = useState<boolean>(true)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(createAndAssignCollection({ title, isPrivate }))
    onDone()
  }

  return (
    <form className={styles['form']} onSubmit={handleSubmit}>
      <Input
        autoFocus
        name="title"
        value={title}
        label={intl.formatMessage({
          id: 'collections_popover_create_title_label',
          defaultMessage: 'Title',
        })}
        placeholder={intl.formatMessage({
          id: 'collections_popover_create_title_placeholder',
          defaultMessage: 'Enter a title...',
        })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
        onReset={() => setTitle('')}
      />
      <PrivacyFieldSet
        value={isPrivate}
        onChange={setIsPrivate}
        publicDescription={intl.formatMessage({
          id: 'route_collection_privacy_field_set_option_public_description',
          defaultMessage: 'The route collection will be publicly available and you can share it with your friends.',
        })}
        privateDescription={intl.formatMessage({
          id: 'route_collection_privacy_field_set_option_private_description',
          defaultMessage: 'The route collection will only be visible to you and can not be shared.',
        })}
      />
      <ButtonGroup>
        <Button variant="secondary" onClick={onCancel}>
          {intl.formatMessage({
            id: 'collections_popover_create_cancel_label',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button disabled={title.length < MIN_TITLE_LENGTH} type="submit">
          {intl.formatMessage({
            id: 'collections_popover_create_submit_label',
            defaultMessage: 'Create',
          })}
        </Button>
      </ButtonGroup>
    </form>
  )
}
