import { BikemapLogo, Button, ButtonGroup, ErrorIllustration, Link } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { getHelpCenterUrl, statelessHomeUrl } from 'shared/util-navigation'
import { DefaultFooter } from 'web-app/feature-navigation'
import {
  WebAppContent,
  WebAppLayout,
  WebAppSheet,
  WebAppMedia,
  WebAppHeader,
  WebAppHeaderItems,
} from 'web-app/ui-layout'
import RefreshIcon from '@mui/icons-material/Refresh'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import { Typography } from '@mui/material'

import styles from './ErrorBoundaryFallback.module.scss'

interface ErrorBoundaryFallbackProps {
  beforeReload?: () => Promise<void>
}

export function ErrorBoundaryFallback(props: ErrorBoundaryFallbackProps) {
  const { intl, language } = useLocale()

  const handleRefresh = () => {
    if (props.beforeReload) {
      props.beforeReload().then(() => {
        window.location.reload()
      })
    } else {
      window.location.reload()
    }
  }

  const refreshButtonLabel = intl.formatMessage({
    id: 'error_boundary_refresh_button',
    defaultMessage: 'Refresh website',
  })

  const homeButtonLabel = intl.formatMessage({
    id: 'error_boundary_home_button',
    defaultMessage: 'Visit our home page',
  })

  const helpButtonLabel = intl.formatMessage({
    id: 'error_boundary_help_center_button',
    defaultMessage: 'Visit our help center',
  })

  return (
    <WebAppLayout initialFold="full">
      <WebAppHeader>
        <WebAppHeaderItems>
          <Link
            href={statelessHomeUrl}
            aria-label={intl.formatMessage({
              id: 'fallback_error_bikemap_header_logo_link_aria_label',
              defaultMessage: 'Bikemap home',
            })}
            style={{ marginTop: '0.25rem' }}
          >
            <BikemapLogo />
          </Link>
        </WebAppHeaderItems>
      </WebAppHeader>
      <WebAppSheet>
        <WebAppContent>
          <Typography variant="h3" component="h2" textAlign="center">
            {intl.formatMessage({
              id: 'error_boundary_fallback_heading',
              defaultMessage: 'Something went wrong!',
            })}
          </Typography>
          <Typography paragraph color="textSecondary" textAlign="center">
            {intl.formatMessage({
              id: 'error_boundary_fallback_message',
              defaultMessage: 'It seems there was a problem. Try refreshing the webpage or visit our help center.',
            })}
          </Typography>
        </WebAppContent>
        <WebAppContent>
          <ButtonGroup stack>
            <Button variant="primary" onClick={handleRefresh} icon={<RefreshIcon />} ariaLabel={refreshButtonLabel}>
              {refreshButtonLabel}
            </Button>
            <Button variant="secondary" href={statelessHomeUrl} icon={<HomeIcon />} ariaLabel={homeButtonLabel}>
              {homeButtonLabel}
            </Button>
            <Button
              variant="secondary"
              href={getHelpCenterUrl(language)}
              icon={<InfoIcon />}
              ariaLabel={helpButtonLabel}
            >
              {helpButtonLabel}
            </Button>
          </ButtonGroup>
        </WebAppContent>
      </WebAppSheet>
      <WebAppMedia>
        <div className={styles['illustration-large']}>
          <ErrorIllustration />
        </div>
      </WebAppMedia>
      <DefaultFooter />
    </WebAppLayout>
  )
}

export default ErrorBoundaryFallback
