import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { useRoute } from 'web-app/feature-route'
import { WebAppContent } from 'web-app/ui-layout'
import { MAX_NUMBER_PRINT_IMAGES, imageRemoved, imageSelected, useRoutePrintState } from '../state'

import styles from './images-options.module.scss'

interface ImageOptionButtonProps {
  imageIndex: number
  image: string
}

const ImageOptionButton = ({ imageIndex, image }: ImageOptionButtonProps) => {
  const dispatch = useDispatch()
  const { selectedImages } = useRoutePrintState()

  const selectedPosition = selectedImages.findIndex((v) => v === imageIndex) + 1 || null
  const canSelect = selectedImages.length < MAX_NUMBER_PRINT_IMAGES

  const handleClick = () => {
    if (selectedPosition) {
      dispatch(imageRemoved({ imageIndex }))
    } else {
      dispatch(imageSelected({ imageIndex }))
    }
  }

  return (
    <button className={styles['button']} onClick={handleClick} disabled={!selectedPosition && !canSelect}>
      <img src={image} alt="" className={styles['image']} />
      {(!!selectedPosition || canSelect) && (
        <div className={clsx(styles['indicator'], { [styles['indicator-selected']]: !!selectedPosition })}>
          {selectedPosition}
        </div>
      )}
    </button>
  )
}

export const ImagesOptions = () => {
  const { intl } = useLocale()
  const route = useRoute()

  return (
    <WebAppContent>
      <Typography variant="h4" component="h2">
        {intl.formatMessage({
          id: 'route_print_images_options_heading',
          defaultMessage: 'Include route photos',
        })}
      </Typography>
      <div className={styles['images-grid']}>
        {route?.images.map((image, i) => <ImageOptionButton key={i} imageIndex={i} image={image.item} />)}
      </div>
    </WebAppContent>
  )
}
