import { debounce } from 'lodash'
import { useEffect } from 'react'

export const useOnDebouncedWindowResize = (handler: () => void) => {
  useEffect(() => {
    handler()
    const debouncedHandler = debounce(handler, 100)
    window.addEventListener('resize', debouncedHandler)
    return () => {
      window.removeEventListener('resize', debouncedHandler)
      debouncedHandler.cancel()
    }
  }, [handler])
}
