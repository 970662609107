import { useState } from 'react'
import { useNavigateToRoutePrint } from 'web-app/feature-navigation'
import { Typography } from '@mui/material'
import PrintRoundedIcon from '@mui/icons-material/PrintRounded'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useLocale } from 'shared/util-intl'
import { PremiumModalExport, useUser } from 'web-app/feature-user'
import { Button } from 'shared/ui-components'
import { PremiumLockIcon } from 'shared/ui-design-system/icons/premium-lock-icon'
import { useRoute } from 'web-app/feature-route'

import styles from './export-actions.module.scss'

interface ExportActionsProps {
  heading: string
}

export const ExportActions = ({ heading }: ExportActionsProps) => {
  const navigateToRoutePrint = useNavigateToRoutePrint()
  const { intl } = useLocale()
  const route = useRoute()
  const [user, isUserLoaded] = useUser()

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState<boolean>(false)

  return (
    <>
      <div className={styles['heading']}>
        <Typography variant="h4" component="h2">
          {heading}
        </Typography>
        {isUserLoaded && !user?.isPremium && (
          <div className={styles['premium-info']}>
            {intl.formatMessage({
              id: 'route_details_content_export_premium_info',
              defaultMessage: 'premium feature',
            })}
            <PremiumLockIcon />
          </div>
        )}
      </div>
      <div className={styles['buttons']}>
        <Button
          variant="secondary"
          icon={<PrintRoundedIcon />}
          disabled={!route}
          onClick={() => route && navigateToRoutePrint(route.id)}
        />
        <Button
          variant="secondary"
          icon={<FileDownloadOutlinedIcon />}
          disabled={!route}
          href={(user?.isPremium && route?.gpxUrl) || undefined}
          hrefType={user?.isPremium ? 'application/gpx+xml' : undefined}
          onClick={!user?.isPremium ? () => setIsPremiumModalOpen(true) : undefined}
        >
          {intl.formatMessage({
            id: 'route_details_content_export_gpx',
            defaultMessage: 'GPX',
          })}
        </Button>
        <Button
          variant="secondary"
          icon={<FileDownloadOutlinedIcon />}
          disabled={!route}
          href={(user?.isPremium && route?.kmlUrl) || undefined}
          hrefType={user?.isPremium ? 'application/vnd.google-earth.kml+xml' : undefined}
          onClick={!user?.isPremium ? () => setIsPremiumModalOpen(true) : undefined}
        >
          {intl.formatMessage({
            id: 'route_details_content_export_kml',
            defaultMessage: 'KML',
          })}
        </Button>
      </div>
      {isPremiumModalOpen && (
        <PremiumModalExport isOpen={isPremiumModalOpen} onClose={() => setIsPremiumModalOpen(false)} />
      )}
    </>
  )
}
