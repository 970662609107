import { getFromCoreApi } from '../network'
import { API_PATH_MAP_STYLES } from '../config'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { MapStyleEntity } from '../entities'

type ResponseItem = {
  id: number
  name: string
  thumbnail: string
  style: string
  is_premium: boolean
  is_default: boolean
}

/**
 * Get the list of available map styles.
 */
export async function fetchMapStyles(): ApiResult<MapStyleEntity[]> {
  try {
    const res: ResponseItem[] = await getFromCoreApi(API_PATH_MAP_STYLES)

    try {
      return createSuccessResult(
        res.map((resItem) => {
          const parser = new ResponseParser(resItem)
          return {
            id: parser.requireNumber('id'),
            name: parser.requireString('name'),
            thumbnail: parser.requireString('thumbnail'),
            isPremium: parser.requireBoolean('is_premium'),
            isDefault: parser.requireBoolean('is_default'),
            url: parser.requireString('style'),
          }
        }),
      )
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch {
    return createFailureResult({ unexpectedError: true })
  }
}
