import { useMemo } from 'react'
import { useDiscoverParams } from './use-discover-params'
import { DEFAULT_ASCENT, DEFAULT_DISTANCE } from 'shared/util-discover'
import { isEqual } from 'lodash'
import { DEFAULT_SORTING } from './definitions'

export const useDiscoverFiltersCount = (defaultSorting: string = DEFAULT_SORTING): number => {
  const { sorting, onlyLoops, distanceKilometers, ascentMeters, title, bikeTypes, surfaces } = useDiscoverParams({
    defaultSorting,
  })

  return useMemo<number>(() => {
    let num = 0
    if (sorting !== defaultSorting) num++
    if (onlyLoops) num++
    if (!isEqual(distanceKilometers, DEFAULT_DISTANCE)) num++
    if (!isEqual(ascentMeters, DEFAULT_ASCENT)) num++
    if (title) num++
    if (bikeTypes.length) num++
    if (surfaces.length) num++
    return num
  }, [ascentMeters, bikeTypes.length, defaultSorting, distanceKilometers, onlyLoops, sorting, surfaces.length, title])
}
