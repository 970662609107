import { useUserGeolocation } from 'web-app/feature-user'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { viewportDesired } from 'web-app/feature-map'

export const useInitialization = (keepMapViewport?: boolean) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { isGeolocationDenied, geolocate } = useUserGeolocation()

  const initAtUserLocation = useCallback(async () => {
    if (isGeolocationDenied) return
    const location = await geolocate()
    if (location) {
      dispatch(
        viewportDesired({
          center: location,
          zoom: 12,
        }),
      )
    }
  }, [dispatch, geolocate, isGeolocationDenied])

  useEffect(() => {
    if (searchParams.size === 0 && !keepMapViewport) {
      initAtUserLocation()
    }
  }, [initAtUserLocation, keepMapViewport, searchParams.size])
}
