import React from 'react'
import clsx from 'clsx'

import styles from './FieldSet.module.scss'

export interface FieldSetProps {
  children: React.ReactNode
  label?: string
  bordered?: boolean
  className?: string
  role?: string
}

export function FieldSet(props: FieldSetProps) {
  const { children, label, bordered, className, ...rest } = props

  return (
    <fieldset className={clsx(styles['root'], className)} aria-label={label || ''} {...rest}>
      {label && <legend className={styles['label']}>{label}</legend>}
      <div
        className={clsx(styles['wrapper'], {
          [styles['bordered']]: bordered,
        })}
      >
        {children}
      </div>
    </fieldset>
  )
}

export default FieldSet
