import {
  RouteMiniItemSkeleton,
  SortableList,
  SortableDragHandle,
  useMessages,
  ToolButton,
  Button,
} from 'shared/ui-components'
import {
  RouteCollectionSliceDispatch,
  fetchMoreGlobalRouteCollectionRoutes,
  useRouteCollection,
  useRouteCollectionState,
} from '../state'
import { WebAppRouteEntityMiniItem } from 'web-app/feature-route'
import { useLocale } from 'shared/util-intl'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { useDispatch } from 'react-redux'
import { RouteEntity } from 'shared/data-access-core'
import { useMemo } from 'react'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { useScrollPagination } from 'web-app/ui-layout'
import { useEntitiesState } from 'web-app/data-access-entities'
import { COLLECTION_ROUTES_DEFAULT_PARAMS } from '../definitions'

import styles from './route-collection-routes-manager.module.scss'

interface RouteCollectionRoutesManagerProps {
  updatedList?: number[]
  onListUpdate: (updatedList: number[]) => void
}

export const RouteCollectionRoutesManager = ({ updatedList, onListUpdate }: RouteCollectionRoutesManagerProps) => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { intl } = useLocale()
  const { removeLabel } = useMessages()
  const { isLoadingMoreRoutes, filteredRoutesCount, assignedRouteIds } = useRouteCollectionState()
  const { routes } = useEntitiesState()
  const routeCollection = useRouteCollection()

  const { canLoadMore, loadMoreButtonRef, loadMore } = useScrollPagination(
    !assignedRouteIds,
    assignedRouteIds?.length,
    filteredRoutesCount,
    async () => {
      dispatch(fetchMoreGlobalRouteCollectionRoutes(COLLECTION_ROUTES_DEFAULT_PARAMS))
    },
  )

  const items = useMemo(
    () =>
      updatedList
        ? updatedList.reduce(
            (items, routeId) => {
              const route = routes[routeId]
              if (route) {
                items.push({
                  key: route.id.toString(),
                  route,
                })
              }
              return items
            },
            [] as { key: string; route: RouteEntity }[],
          )
        : null,
    [updatedList, routes],
  )

  return items ? (
    <>
      <SortableList
        items={items}
        gap="1rem"
        renderItem={({ route }, handleProps, contextData) => {
          return (
            <div className={styles['item-grid']}>
              <SortableDragHandle
                {...handleProps}
                label={intl.formatMessage({
                  id: 'route_collection_routes_manager_drag_handle',
                  defaultMessage: 'Grab to drag and sort',
                })}
                isSorting={!!contextData.active}
              />
              <WebAppRouteEntityMiniItem route={route} routeCollectionId={routeCollection?.id} />
              <ToolButton
                variant="ghost-danger"
                icon={<RemoveCircleRoundedIcon />}
                ariaLabel={removeLabel}
                onClick={() => onListUpdate(updatedList ? updatedList.filter((id) => id !== route.id) : [])}
              />
            </div>
          )
        }}
        onSort={(reorderedRoutes) => onListUpdate(reorderedRoutes.map(({ route }) => route.id))}
      />
      {isLoadingMoreRoutes && <SkeletonList />}
      {canLoadMore && (
        <Button ref={loadMoreButtonRef} block variant="secondary" onClick={loadMore}>
          {intl.formatMessage({
            id: 'paginated_list_load_more',
            defaultMessage: 'Load more',
          })}
        </Button>
      )}
    </>
  ) : (
    <SkeletonList />
  )
}

const SkeletonList = () => {
  const { removeLabel } = useMessages()
  return (
    <ul className={styles['loading-list']}>
      {Array.from({ length: 8 }).map((_, i) => (
        <li key={i} className={styles['item-grid']}>
          <DragHandleIcon />
          <RouteMiniItemSkeleton />
          <ToolButton variant="ghost-danger" icon={<RemoveCircleRoundedIcon />} ariaLabel={removeLabel} disabled />
        </li>
      ))}
    </ul>
  )
}
