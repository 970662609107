import { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import LayersIcon from '@mui/icons-material/Layers'
import { Fade, Popover, SwipeableDrawer, Typography } from '@mui/material'
import { Control } from '../Control'
import { ToolButton } from '../ToolButton'
import { useMessages } from '../MessagesProvider'
import { MapStyleControlContent, MapStyleControlContentProps } from './map-style-control-content'
import { RequiredMapStyleData } from './types'

import styles from './map-style-control.module.scss'

export interface MapStyleControlProps<MapStyleData> extends MapStyleControlContentProps<MapStyleData> {
  messages: MapStyleControlContentProps<MapStyleData>['messages'] & {
    label: string
    sublabel: string
  }
  small?: boolean
  blocking?: boolean
  maxHeight?: string
}

export function MapStyleControl<MapStyleData extends RequiredMapStyleData = RequiredMapStyleData>({
  messages,
  small,
  blocking,
  maxHeight,
  ...contentProps
}: MapStyleControlProps<MapStyleData>) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const closedRef = useRef(null)

  const { collapseLabel, expandLabel } = useMessages()
  const { label, sublabel, ...contentMessages } = messages

  return small ? (
    <>
      <Control>
        <ToolButton
          variant="ghost-primary"
          icon={<LayersIcon />}
          ariaLabel={messages.label}
          onClick={() => {
            setIsOpen(true)
          }}
        />
      </Control>
      <SwipeableDrawer
        open={isOpen}
        onOpen={() => ''}
        onClose={() => {
          setIsOpen(false)
        }}
        anchor="bottom"
        allowSwipeInChildren
        disableDiscovery
        disableSwipeToOpen
        PaperProps={{
          className: styles['bottom-sheet'],
        }}
      >
        <MapStyleControlContent {...contentProps} messages={contentMessages} />
      </SwipeableDrawer>
    </>
  ) : (
    <>
      <Fade in={!isOpen}>
        <div className={styles['closed']} ref={closedRef}>
          <div className={styles['label']}>
            <Typography variant="h4" marginBottom={0}>
              {messages.label}
            </Typography>
            <Typography paragraph variant="body2" color="textSecondary" marginBottom={0}>
              {messages.sublabel}
            </Typography>
          </div>
          <ToolButton
            variant="secondary"
            icon={<LayersIcon />}
            ariaLabel={expandLabel}
            coveringClickTarget
            onClick={() => {
              setIsOpen(true)
            }}
          />
        </div>
      </Fade>
      <Popover
        anchorEl={closedRef.current}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        PaperProps={{
          className: styles['open'],
          style: { maxHeight },
        }}
      >
        <div className={styles['close-button-holder']}>
          <ToolButton
            variant="ghost-secondary"
            icon={<CloseIcon />}
            ariaLabel={collapseLabel}
            onClick={() => {
              setIsOpen(false)
            }}
          />
        </div>
        <MapStyleControlContent {...contentProps} messages={contentMessages} />
      </Popover>
    </>
  )
}
