import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'
import { useLocale } from 'shared/util-intl'
import { IllustratedButton } from 'shared/ui-components'
import { useState } from 'react'
import { useBreakpoints } from 'web-app/ui-layout'
import { LoopPlannerModal } from './loop-planner-modal'
import { getDiscoverDynamicLink } from 'shared/util-navigation'

import png from './loop-planner.png'
import webp from './loop-planner.webp'
import avif from './loop-planner.avif'

export const LoopPlannerIllustratedLink = () => {
  const { intl } = useLocale()
  const { layoutBreakpoint } = useBreakpoints()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <IllustratedButton
        icon={<SettingsBackupRestoreRoundedIcon />}
        image={{ png, webp, avif }}
        onClick={
          layoutBreakpoint
            ? (e) => {
                e.preventDefault()
                setIsModalOpen(true)
              }
            : undefined
        }
        href={getDiscoverDynamicLink({
          utmSource: 'web_app',
          utmMedium: 'home_features_gallery',
          utmCampaign: 'loop_planner',
        })}
      >
        {intl.formatMessage({
          id: 'loop_planner_illustrated_link_label',
          defaultMessage: 'Ride a loop',
        })}
      </IllustratedButton>
      <LoopPlannerModal open={isModalOpen} onClose={() => setIsModalOpen(false)} png={png} webp={webp} avif={avif} />
    </>
  )
}
