import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#map-marker-poi-filter)">
      <path
        fill="#000"
        fillOpacity=".15"
        d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"
      />
    </g>
    <path
      fill="url(#map-marker-poi-gradient)"
      stroke="#fff"
      strokeWidth="1.5"
      d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"
    />
    <path
      fill="#fff"
      d="M14.099 24.09c-.404.315-.817.324-1.239.027-.421-.298-.562-.691-.421-1.18l1.502-4.855-3.822-2.702c-.421-.297-.549-.69-.381-1.18.166-.49.504-.735 1.014-.735h4.717L17 8.428c.087-.245.223-.434.408-.565.184-.13.382-.196.593-.196.21 0 .408.065.592.196.185.131.321.32.41.564l1.528 5.038h4.717c.51 0 .848.245 1.015.734.167.49.04.884-.382 1.181l-3.822 2.702 1.503 4.854c.14.49 0 .883-.422 1.18-.422.298-.835.29-1.239-.026L18 21.127l-3.901 2.964Z"
    />
    <defs>
      <linearGradient
        id="map-marker-poi-gradient"
        x1="27.44"
        x2="-1.476"
        y1="6.126"
        y2="29.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1FC5D8" />
        <stop offset="1" stopColor="#168A97" />
      </linearGradient>
      <filter
        id="map-marker-poi-filter"
        width="34.666"
        height="18.667"
        x=".667"
        y="38.667"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2488_57588" stdDeviation="2" />
      </filter>
    </defs>
  </svg>
)

export default svg
