import { ReactNode, useMemo } from 'react'
import { RouteBikeType } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'
import { CityBikeIcon } from 'shared/ui-design-system/icons/city-bike-icon'
import { MtbIcon } from 'shared/ui-design-system/icons/mtb-icon'
import { RoadBikeIcon } from 'shared/ui-design-system/icons/road-bike-icon'

type BikeTypeLabels = {
  1: string
  2: string
  3: string
}

export const useBikeTypeLabels = (): BikeTypeLabels => {
  const { intl } = useLocale()

  const labels = useMemo<BikeTypeLabels>(
    () => ({
      1: intl.formatMessage({
        id: 'route_bike_type_road_bike',
        defaultMessage: 'Road bike',
      }),
      2: intl.formatMessage({
        id: 'route_bike_type_mtb',
        defaultMessage: 'MTB',
      }),
      3: intl.formatMessage({
        id: 'route_bike_type_city_bike',
        defaultMessage: 'City bike',
      }),
    }),
    [intl],
  )

  return labels
}

type BikeTypeIcons = {
  1: ReactNode
  2: ReactNode
  3: ReactNode
}

export const useBikeTypeIcons = (): BikeTypeIcons => {
  const icons = useMemo<BikeTypeIcons>(
    () => ({
      1: <RoadBikeIcon />,
      2: <MtbIcon />,
      3: <CityBikeIcon />,
    }),
    [],
  )

  return icons
}

export const useBikeTypes = (bikeTypes: RouteBikeType[]): string[] => {
  const labels = useBikeTypeLabels()
  return useMemo(() => bikeTypes.map((key) => labels[key]), [bikeTypes, labels])
}
