import { useParams } from 'react-router-dom'
import { RouteCollection } from 'web-app/view-route-collection'
import { ViewNotFound } from 'web-app/utils-error-handling'

const RouteCollectionView = () => {
  const params = useParams()

  const routeCollectionId = params['routeCollectionId'] ? Number.parseInt(params['routeCollectionId']) : undefined

  return routeCollectionId ? <RouteCollection routeCollectionId={routeCollectionId} /> : <ViewNotFound />
}

export default RouteCollectionView
