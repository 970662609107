import { API_PATH_USER_ROUTE_COLLECTIONS } from '../../config'
import { EntityIndex, RouteCollectionEntity } from '../../entities'
import { getFromCoreApi } from '../../network'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'

type QueryParams = {
  page?: number
  route_id?: number
}

type ResponseResult = {
  ascent_total: number
  category: number | null
  created: string
  descent_total: number
  description: string
  distance_total: number
  duration_total: number
  encrypted_external_id: string
  external_id: string
  id: number
  image: {
    fallback: string
    item: string | null
    tile: string | null
  } | null
  modified: string
  route_count: number
  title: string
  visibility: boolean
}

type Response = {
  results: ResponseResult[]
  page: { next: number | null }
}

export async function fetchUserRouteCollections(
  userId: number,
  routeId?: number,
): ApiResult<{
  userRouteCollectionIds: number[]
  routeCollections: EntityIndex<RouteCollectionEntity>
}> {
  try {
    const queryParams: QueryParams = {}
    if (routeId) {
      queryParams.route_id = routeId
    }

    let res: Response
    let page = 1
    let results: ResponseResult[] = []
    do {
      res = await getFromCoreApi(API_PATH_USER_ROUTE_COLLECTIONS, {
        params: { userId },
        queryParams: {
          ...queryParams,
          page: page++,
        },
      })
      results = results.concat(res.results)
    } while (res.page.next)

    try {
      const routeCollections: EntityIndex<RouteCollectionEntity> = {}
      const userRouteCollectionIds: number[] = results.map((res: ResponseResult) => {
        const parser = new ResponseParser(res)
        const id = parser.requireNumber('id')
        const categoryValue = parser.takeNumber('category')

        if (!routeCollections[id]) {
          routeCollections[id] = {
            category: categoryValue && categoryValue >= 1 && categoryValue <= 3 ? categoryValue : null,
            created: parser.requireTimestamp('created'),
            description: parser.takeString('description'),
            id,
            image: parser.takeImageSizes('image', {
              item: 'item',
              tile: 'tile',
            }),
            isPrivate: !parser.requireBoolean('visibility'),
            maximumElevationMeters: null, // not provided by this endpoint!
            owner: userId,
            routesCount: parser.requireNumber('route_count'),
            title: parser.requireString('title'),
            totalAscentMeters: parser.requireNumber('ascent_total'),
            totalDescentMeters: parser.requireNumber('descent_total'),
            totalDistanceMeters: parser.requireNumber('distance_total'),
            totalDurationSeconds: parser.requireNumber('duration_total'),
          }
        }

        return id
      })

      return createSuccessResult({ userRouteCollectionIds, routeCollections })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { userId, routeId })
  }
}
