import { useSelector } from 'react-redux'
import { DISCOVER_SLICE_KEY, DiscoverState, ResultsListItemState, StateWithDiscoverSlice } from './types'
import { createSelector } from 'reselect'
import { ENTITIES_SLICE_KEY, StateWithEntitiesSlice } from 'web-app/data-access-entities'
import { RouteStart } from 'shared/ui-map'
import { RouteBikeType } from 'shared/data-access-core'

export const discoverSliceSelector = (state: StateWithDiscoverSlice): DiscoverState => state[DISCOVER_SLICE_KEY]

export const useDiscoverState = () => useSelector(discoverSliceSelector)

export const discoverRouteStartsSelector: (
  state: StateWithDiscoverSlice & StateWithEntitiesSlice,
) => RouteStart[] | null = createSelector(
  (state: StateWithDiscoverSlice) => state[DISCOVER_SLICE_KEY].results,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routes,
  (results, routes) =>
    results
      ? results.reduce((items: RouteStart[], routeId) => {
          const route = routes[routeId]
          if (!route) return items
          items.push({
            routeId,
            routeType: getRouteType(route.bikeTypes),
            position: route.start,
          })
          return items
        }, [])
      : null,
)

function getRouteType(bikeTypes: RouteBikeType[]): 'default' | 'road-bike' | 'mtb' {
  if (bikeTypes.length === 1) {
    if (bikeTypes[0] === 1) return 'road-bike'
    if (bikeTypes[0] === 2) return 'mtb'
  }
  return 'default'
}

export const useDiscoverRouteStarts = () => useSelector(discoverRouteStartsSelector)

export const discoverRoutesSelector: (
  state: StateWithDiscoverSlice & StateWithEntitiesSlice,
) => ResultsListItemState[] | null = createSelector(
  (state: StateWithDiscoverSlice) => state[DISCOVER_SLICE_KEY].results,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].routes,
  (state: StateWithEntitiesSlice) => state[ENTITIES_SLICE_KEY].users,
  (results, routes, users) =>
    results
      ? results.reduce((items: ResultsListItemState[], routeId) => {
          const route = routes[routeId]
          if (!route) return items
          const creator = typeof route.creatorId === 'number' ? users[route.creatorId] : undefined
          items.push({
            key: route.id.toString(),
            route,
            creator,
          })
          return items
        }, [])
      : null,
)

export const useDiscoverRoutes = () => useSelector(discoverRoutesSelector)
