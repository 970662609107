import { useMemo } from 'react'
import { RichLineString, WayType } from 'shared/util-geo'
import { useLocale } from 'shared/util-intl'
import { AlongTheRouteDiagram } from './along-the-route-diagram'
import { colors } from 'shared/ui-design-system'

interface WayTypesDiagramProps {
  geometry: RichLineString
  routeDistanceMeters: number
}

export const WayTypesDiagram = ({ geometry, routeDistanceMeters }: WayTypesDiagramProps) => {
  const { intl } = useLocale()

  const labels = useMemo<Record<WayType, string>>(
    () => ({
      [WayType.BusyRoad]: intl.formatMessage({
        id: 'way_type_busy_road_label',
        defaultMessage: 'Busy road',
      }),
      [WayType.Road]: intl.formatMessage({
        id: 'way_type_road_label',
        defaultMessage: 'Road',
      }),
      [WayType.QuietRoad]: intl.formatMessage({
        id: 'way_type_quiet_road_label',
        defaultMessage: 'Quiet road',
      }),
      [WayType.LivingStreet]: intl.formatMessage({
        id: 'way_type_living_street_label',
        defaultMessage: 'Living street',
      }),
      [WayType.AccessRoad]: intl.formatMessage({
        id: 'way_type_access_road_label',
        defaultMessage: 'Access road',
      }),
      [WayType.PedestrianArea]: intl.formatMessage({
        id: 'way_type_pedestrian_area_label',
        defaultMessage: 'Pedestrian area',
      }),
      [WayType.Steps]: intl.formatMessage({
        id: 'way_type_steps_label',
        defaultMessage: 'Steps',
      }),
      [WayType.Track]: intl.formatMessage({
        id: 'way_type_track_label',
        defaultMessage: 'Track',
      }),
      [WayType.Path]: intl.formatMessage({
        id: 'way_type_path_label',
        defaultMessage: 'Path',
      }),
      [WayType.Cycleway]: intl.formatMessage({
        id: 'way_type_cycleway_label',
        defaultMessage: 'Cycleway',
      }),
    }),
    [intl],
  )

  const wayTypeColors = useMemo<Record<WayType, string>>(
    () => ({
      [WayType.BusyRoad]: colors.wayTypesComp.busyRoad,
      [WayType.Road]: colors.wayTypesComp.road,
      [WayType.QuietRoad]: colors.wayTypesComp.quietRoad,
      [WayType.LivingStreet]: colors.wayTypesComp.livingStreet,
      [WayType.AccessRoad]: colors.wayTypesComp.accessRoad,
      [WayType.PedestrianArea]: colors.wayTypesComp.pedestrianArea,
      [WayType.Steps]: colors.wayTypesComp.steps,
      [WayType.Track]: colors.wayTypesComp.track,
      [WayType.Path]: colors.wayTypesComp.path,
      [WayType.Cycleway]: colors.wayTypesComp.cycleway,
    }),
    [],
  )

  return (
    <AlongTheRouteDiagram
      geometry={geometry}
      attributeIndex={6}
      colors={wayTypeColors}
      labels={labels}
      nullLabel={intl.formatMessage({
        id: 'way_type_missing_label',
        defaultMessage: 'Undefined',
      })}
      routeDistanceMeters={routeDistanceMeters}
    />
  )
}
