import { useDropzone } from 'react-dropzone'
import { Button, UploaderContainer, UploaderVariant, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useRouteUpload } from './use-route-upload'
import { ReactNode, useMemo } from 'react'
import { useRouteImportState } from '../state'

interface RouteImportUploaderProps {
  variant?: UploaderVariant
  uploadButtonLabel: string
  children?: ReactNode
}

export const RouteImportUploader = ({ variant, uploadButtonLabel, children }: RouteImportUploaderProps) => {
  const { intl } = useLocale()
  const { cancelLabel } = useMessages()
  const [onRouteFileDrop, onRouteUploadCancel] = useRouteUpload()
  const { isUploadInProgress, statusTaskId, routeData } = useRouteImportState()

  const isLoading = useMemo<boolean>(
    () => !!(isUploadInProgress || (statusTaskId && !routeData)),
    [isUploadInProgress, routeData, statusTaskId],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isLoading,
    onDrop: onRouteFileDrop,
    accept: {
      // Only .gpx files are allowed on iOS and all files are allowed on iPadOS
      'application/*': ['.gpx', '.kml'],
    },
    multiple: false,
  })

  return (
    <UploaderContainer
      variant={variant}
      label={intl.formatMessage({
        id: 'route_import_uploader_label',
        defaultMessage: 'Upload file',
      })}
      hideLabel
      dropzoneRootProps={getRootProps()}
      dropzoneInputProps={getInputProps()}
      loading={isLoading}
      messages={{
        dropFilesHere: intl.formatMessage({
          id: 'route_import_ploader_message_drop_files_here',
          defaultMessage: 'Drop your files here...',
        }),
      }}
      isDragActive={isDragActive}
      renderUploadButton={(props) => (
        <Button {...props} ariaLabel={uploadButtonLabel}>
          {uploadButtonLabel}
        </Button>
      )}
      renderCancelButton={() => (
        <Button variant="secondary" ariaLabel={cancelLabel} onClick={onRouteUploadCancel}>
          {cancelLabel}
        </Button>
      )}
      inputDataTestId="route-import-route-file"
    >
      {children}
    </UploaderContainer>
  )
}
