import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#map-marker-edit-filter)">
      <path
        fill="#000"
        fillOpacity=".15"
        d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"
      />
    </g>
    <path
      fill="#fff"
      stroke="#0873E7"
      strokeWidth="1.5"
      d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"
    />
    <path
      fill="#0873E7"
      d="M17.167 16.833h-4.271l.917.896c.166.167.25.368.25.604a.823.823 0 0 1-.25.605.823.823 0 0 1-.604.25.823.823 0 0 1-.605-.25l-2.354-2.355a.73.73 0 0 1-.177-.27.923.923 0 0 1-.052-.313c0-.111.017-.215.052-.313a.73.73 0 0 1 .177-.27l2.354-2.354a.811.811 0 0 1 .594-.25.81.81 0 0 1 .594.25c.167.166.25.364.25.593 0 .23-.083.427-.25.594l-.917.917h4.292v-4.292l-.938.938a.763.763 0 0 1-.583.24.839.839 0 0 1-.583-.261.811.811 0 0 1-.25-.594.81.81 0 0 1 .25-.594l2.354-2.354a.731.731 0 0 1 .27-.177A.922.922 0 0 1 18 8.02c.111 0 .215.017.313.052a.73.73 0 0 1 .27.177l2.376 2.375a.8.8 0 0 1 .25.583.8.8 0 0 1-.25.584.812.812 0 0 1-.594.25.811.811 0 0 1-.594-.25l-.938-.917v4.292h4.271l-.916-.896a.823.823 0 0 1-.25-.604c0-.236.083-.438.25-.604a.823.823 0 0 1 .604-.25c.236 0 .437.083.604.25l2.354 2.354a.73.73 0 0 1 .177.27.92.92 0 0 1 .052.313.92.92 0 0 1-.052.313.73.73 0 0 1-.177.27l-2.375 2.375a.763.763 0 0 1-.583.24.838.838 0 0 1-.584-.26.811.811 0 0 1-.25-.594c0-.23.084-.427.25-.594l.917-.917h-4.291v4.271l.895-.916a.823.823 0 0 1 .605-.25c.236 0 .437.083.604.25.166.166.25.368.25.604a.823.823 0 0 1-.25.604l-2.354 2.354a.731.731 0 0 1-.271.177.923.923 0 0 1-.313.052.923.923 0 0 1-.312-.052.731.731 0 0 1-.271-.177l-2.375-2.375a.776.776 0 0 1-.24-.594.85.85 0 0 1 .26-.593.81.81 0 0 1 .594-.25c.23 0 .428.083.594.25l.917.937v-4.292Z"
    />
    <defs>
      <filter
        id="map-marker-edit-filter"
        width="34.666"
        height="18.667"
        x=".667"
        y="38.667"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2488_57595" stdDeviation="2" />
      </filter>
    </defs>
  </svg>
)

export default svg
