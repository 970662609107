import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ENTITIES_SLICE_KEY, EntitiesState } from './types'
import { EntityIndex, RouteCollectionEntity, RouteEntity, UserEntity } from 'shared/data-access-core'

export const initialState: EntitiesState = {
  routeCollections: {},
  routes: {},
  users: {},
}

const slice = createSlice({
  name: ENTITIES_SLICE_KEY,
  initialState,
  reducers: {
    routeCollectionsFetched(state, action: PayloadAction<EntityIndex<RouteCollectionEntity>>) {
      state.routeCollections = {
        ...state.routeCollections,
        ...action.payload,
      }
    },
    routeCollectionFetched(state, action: PayloadAction<RouteCollectionEntity>) {
      state.routeCollections[action.payload.id] = action.payload
    },

    routesFetched(state, action: PayloadAction<EntityIndex<RouteEntity>>) {
      state.routes = {
        ...state.routes,
        ...action.payload,
      }
    },
    routeFetched(state, action: PayloadAction<RouteEntity>) {
      state.routes[action.payload.id] = action.payload
    },

    usersFetched(state, action: PayloadAction<EntityIndex<UserEntity>>) {
      state.users = {
        ...state.users,
        ...action.payload,
      }
    },
    userFetched(state, action: PayloadAction<UserEntity>) {
      state.users[action.payload.id] = action.payload
    },

    routeAddedToFavorites(state, action: PayloadAction<{ routeId: number }>) {
      const route = state.routes[action.payload.routeId]
      if (route) {
        route.isFavorite = true
        route.favoriteCount++
      }
    },
    routeRemovedFromFavorites(state, action: PayloadAction<{ routeId: number }>) {
      const route = state.routes[action.payload.routeId]
      if (route) {
        route.isFavorite = false
        route.favoriteCount--
      }
    },

    routeAddedToCollection(state, action: PayloadAction<number>) {
      if (state.routeCollections[action.payload]) {
        state.routeCollections[action.payload].routesCount++
      }
    },
    routeRemovedFromCollection(state, action: PayloadAction<number>) {
      if (state.routeCollections[action.payload]) {
        state.routeCollections[action.payload].routesCount--
      }
    },
  },
})

export const {
  routeCollectionsFetched,
  routeCollectionFetched,
  routesFetched,
  routeFetched,
  usersFetched,
  userFetched,
  routeAddedToFavorites,
  routeRemovedFromFavorites,
  routeAddedToCollection,
  routeRemovedFromCollection,
} = slice.actions

export const entitiesReducer = slice.reducer
