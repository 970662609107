import { RouteSliceDispatch, toggleGlobalFavoriteRoute, useRoute } from '../state'
import { RouteItemSkeleton } from 'shared/ui-components'
import { useUserState } from 'web-app/feature-user'
import { useDispatch } from 'react-redux'
import { WebAppRouteEntityItem } from './route-entity-item'
import React from 'react'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'

interface MainRouteItemProps {
  routeDetailsPreviousView?: RouteDetailsPreviousView
  onClick?: (e: React.MouseEvent) => void
}

export const MainRouteItem = ({ routeDetailsPreviousView, onClick }: MainRouteItemProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const route = useRoute()
  const { unitPreference } = useUserState()

  return route ? (
    <WebAppRouteEntityItem
      route={route}
      onFavoriteToggle={() => dispatch(toggleGlobalFavoriteRoute())}
      routeDetailsPreviousView={routeDetailsPreviousView}
      imperial={unitPreference === 'imperial'}
      onClick={onClick}
    />
  ) : (
    <RouteItemSkeleton />
  )
}
