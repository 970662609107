import {
  ApiResult,
  MinimalEndpointErrors,
  ResponseError,
  ResponseParser,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'
import {
  PREMIUM_PLAN_PERIODS,
  PremiumPlanPeriod,
  UserEntity,
  UserEntityAnalyticsData,
  UserEntityAvatarSizes,
  UserEntityDetails,
  UserEntitySessionData,
} from '../../entities'
import { getFromCoreApi } from '../../network'
import { API_PATH_USER } from '../../config'
import { logError } from 'shared/util-error-handling'

type Response = {
  id: number
  slug: string
  displayname: string
  is_subscribed: boolean
  subscription_info?: {
    plan?: string
    trial_ends_at?: string
  }
  external_id: string
  favorited_count: number
  is_staff?: boolean
  email: string
  avatar_image_data: {
    fallback: string
    small: string | null
    medium: string | null
    large: string | null
    huge: string | null
  } | null
  cover_image_data: {
    fallback: string
    small: string | null
    small_wide: string | null
    medium: string | null
    large: string | null
  } | null
}

export async function fetchUserSession(): ApiResult<
  UserEntity & UserEntityAvatarSizes & UserEntityDetails & UserEntityAnalyticsData & UserEntitySessionData,
  MinimalEndpointErrors & { unauthenticated?: true }
> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_USER, {
      params: { userId: 'self' },
    })
    try {
      const parser = new ResponseParser(res)
      const isPremium = parser.requireBoolean('is_subscribed')
      const premiumPlan = parser.inOptional('subscription_info').takeString('plan')
      return createSuccessResult({
        id: parser.requireNumber('id'),
        slug: parser.requireString('slug'),
        name: parser.requireString('displayname'),
        avatar: parser.takeImageSizes('avatar_image_data', {
          small: 'small',
          medium: 'medium',
          large: 'large',
          huge: 'huge',
        }),
        coverImage: parser.takeImageSizes('cover_image_data', {
          small: 'small',
          smallWide: 'small_wide',
          large: 'medium', // for entity type consistency
          largeWide: 'large', // for entity type consistency
        }),
        isPremium,
        externalId: parser.requireString('external_id'),
        planPeriod: (isPremium && getPremiumPlanPeriod(premiumPlan)) || 'free',
        favoriteRoutesCount: parser.requireNumber('favorited_count'),
        isStaff: parser.takeAsBoolean('is_staff'),
        email: parser.requireString('email'),
        hadTrial: !!parser.inOptional('subscription_info').has('trial_ends_at'),
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    if (error instanceof ResponseError && error.status === 404) {
      return createFailureResult({ unauthenticated: true }, {}, { silent: true })
    }
    return createFailureResult({ unexpectedError: true })
  }
}

function getPremiumPlanPeriod(premiumPlan: string | null): PremiumPlanPeriod | undefined {
  if (premiumPlan) {
    for (const period of PREMIUM_PLAN_PERIODS) {
      if (premiumPlan.includes(period)) {
        return period
      }
    }
    logError('Subscription period could not be detected.')
  }
  return undefined
}
