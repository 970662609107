import { Input, InputProps } from 'shared/ui-components'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import React, { ForwardedRef, forwardRef, useCallback } from 'react'

interface SearchInputProps extends Omit<InputProps, 'name'> {
  className?: string
  onSubmit: () => void
}

export const SearchInput = forwardRef(
  ({ className, label, icon, onSubmit, ...inputProps }: SearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
      (e) => {
        e.preventDefault()
        onSubmit()
      },
      [onSubmit],
    )

    return (
      <form className={className} onSubmit={handleSubmit}>
        <Input
          ref={ref}
          name="search"
          label={label}
          placeholder={label}
          hideLabel
          icon={icon === undefined ? <SearchRoundedIcon /> : icon}
          autoComplete="off"
          {...inputProps}
        />
      </form>
    )
  },
)
