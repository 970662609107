import { useEffect, useMemo, useRef, useState } from 'react'
import { Chip, ToolButton, useMessages } from 'shared/ui-components'
import { TrekkingBikeIcon } from 'shared/ui-design-system/icons/trekking-bike-icon'
import { SurroundingIcon } from 'shared/ui-design-system/icons/surrounding-icon'
import { RoadBikeIcon } from 'shared/ui-design-system/icons/road-bike-icon'
import { MtbIcon } from 'shared/ui-design-system/icons/mtb-icon'
import { CityBikeIcon } from 'shared/ui-design-system/icons/city-bike-icon'
import { GravelBikeIcon } from 'shared/ui-design-system/icons/gravel-bike-icon'
import { useLocale } from 'shared/util-intl'
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { BASE_SIZE_PX } from 'web-app/ui-layout'
import { debounce, isEqual } from 'lodash'
import clsx from 'clsx'
import { useDiscoverFilters } from './use-discover-filters'
import {
  DEFAULT_FILTERS,
  PRESET_GRAVEL,
  PRESET_MTB,
  PRESET_RELAXED,
  PRESET_ROAD,
  PRESET_ROUND_TRIPS,
  PRESET_TREKKING,
} from 'shared/util-discover'
import { useDiscoverReset } from './use-discover-reset'

import styles from './discover-presets.module.scss'

export const DiscoverPresets = () => {
  const { intl } = useLocale()
  const { showMoreLabel } = useMessages()
  const [filters, setFilters] = useDiscoverFilters()
  const handleReset = useDiscoverReset()

  const [scrollLeft, setScrollLeft] = useState<number>(0)

  const listRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const list = listRef.current
    if (!list) return
    const handler = debounce(
      () => {
        setScrollLeft(list.scrollLeft)
      },
      100,
      { leading: true },
    )
    list.addEventListener('scroll', handler)
    return () => list.removeEventListener('scroll', handler)
  })

  const handleScroll = () => {
    if (!listRef.current) return
    listRef.current?.scrollBy({ left: listRef.current.clientWidth - 10 * BASE_SIZE_PX, behavior: 'smooth' })
  }

  const handleScrollBack = () => {
    if (!listRef.current) return
    listRef.current?.scrollBy({ left: -(listRef.current.clientWidth - 10 * BASE_SIZE_PX), behavior: 'smooth' })
  }

  const canScroll = useMemo<boolean>(
    () => !listRef.current || scrollLeft < listRef.current.scrollWidth - listRef.current.clientWidth - 1,
    [scrollLeft],
  )

  const canScrollBack = useMemo<boolean>(() => scrollLeft > 0, [scrollLeft])

  const isAll = useMemo<boolean>(() => isEqual(filters, DEFAULT_FILTERS), [filters])

  const isRoundTrips = useMemo<boolean>(() => isEqual(filters, PRESET_ROUND_TRIPS), [filters])

  const isRelaxed = useMemo<boolean>(() => isEqual(filters, PRESET_RELAXED), [filters])

  const isGravel = useMemo<boolean>(() => isEqual(filters, PRESET_GRAVEL), [filters])

  const isRoad = useMemo<boolean>(() => isEqual(filters, PRESET_ROAD), [filters])

  const isMtb = useMemo<boolean>(() => isEqual(filters, PRESET_MTB), [filters])

  const isTrekking = useMemo<boolean>(() => isEqual(filters, PRESET_TREKKING), [filters])

  return (
    <div
      className={clsx(styles['container'], {
        [styles['scrollable']]: canScroll,
        [styles['scrollable-back']]: canScrollBack,
      })}
    >
      <ul ref={listRef} className={styles['list']}>
        <li>
          <Chip icon={<SurroundingIcon />} active={isAll} onClick={handleReset}>
            {intl.formatMessage({
              id: 'discover_preset_all',
              defaultMessage: 'All',
            })}
          </Chip>
        </li>
        <li>
          <Chip
            icon={<SettingsBackupRestoreRoundedIcon />}
            active={isRoundTrips}
            onClick={isRoundTrips ? handleReset : () => setFilters(PRESET_ROUND_TRIPS)}
          >
            {intl.formatMessage({
              id: 'discover_preset_round_trips',
              defaultMessage: 'Round trips',
            })}
          </Chip>
        </li>
        <li>
          <Chip
            icon={<CityBikeIcon />}
            active={isRelaxed}
            onClick={isRelaxed ? handleReset : () => setFilters(PRESET_RELAXED)}
          >
            {intl.formatMessage({
              id: 'discover_preset_relaxed',
              defaultMessage: 'Relaxed',
            })}
          </Chip>
        </li>
        <li>
          <Chip
            icon={<GravelBikeIcon />}
            active={isGravel}
            onClick={isGravel ? handleReset : () => setFilters(PRESET_GRAVEL)}
          >
            {intl.formatMessage({
              id: 'discover_preset_gravel',
              defaultMessage: 'Gravel',
            })}
          </Chip>
        </li>
        <li>
          <Chip icon={<RoadBikeIcon />} active={isRoad} onClick={isRoad ? handleReset : () => setFilters(PRESET_ROAD)}>
            {intl.formatMessage({
              id: 'discover_preset_road',
              defaultMessage: 'Road',
            })}
          </Chip>
        </li>
        <li>
          <Chip icon={<MtbIcon />} active={isMtb} onClick={isMtb ? handleReset : () => setFilters(PRESET_MTB)}>
            {intl.formatMessage({
              id: 'discover_preset_mtb',
              defaultMessage: 'MTB',
            })}
          </Chip>
        </li>
        <li>
          <Chip
            icon={<TrekkingBikeIcon />}
            active={isTrekking}
            onClick={isTrekking ? handleReset : () => setFilters(PRESET_TREKKING)}
          >
            {intl.formatMessage({
              id: 'discover_preset_trekking',
              defaultMessage: 'Trekking',
            })}
          </Chip>
        </li>
      </ul>
      <ToolButton
        className={clsx(styles['scroll-back-button'], { [styles['hidden']]: !canScrollBack })}
        variant="ghost-secondary"
        icon={<ChevronLeftRoundedIcon />}
        ariaLabel={showMoreLabel}
        onClick={handleScrollBack}
      />
      <ToolButton
        className={clsx(styles['scroll-button'], { [styles['hidden']]: !canScroll })}
        variant="ghost-secondary"
        icon={<ChevronRightRoundedIcon />}
        ariaLabel={showMoreLabel}
        onClick={handleScroll}
      />
    </div>
  )
}
