import { RefObject, useCallback, useMemo, useRef } from 'react'
import { ElevationCurveWithSkeleton } from 'shared/feature-elevation-curve'
import { RoutePlannerStatistics } from './route-planner-statistics'
import { useRoutePlannerState, useWaypoints } from '../state'
import LocationsList from './locations-list'
import { RoutingOptions } from '../components/routing-options'
import { RoutePlannerDiagrams } from './route-planner-diagrams'
import { WebAppContent, WebAppSheet, WebAppScrollTooltip } from 'web-app/ui-layout'
import { useLocale } from 'shared/util-intl'

export const RoutePlannerSheet = () => {
  const { intl } = useLocale()
  const { isFullRoute, start, end } = useWaypoints()
  const { basedOnRouteId } = useRoutePlannerState()

  const statsContentRef = useRef(null)

  /**
   * Scroll currently active/focused location field to the top if it's too close to the bottom.
   */
  const onWaypointItemFocus = useCallback((waypointRef: RefObject<HTMLDivElement>) => {
    if (waypointRef.current) {
      const waypoint = waypointRef.current.getBoundingClientRect()
      const isTooCloseToBottom = waypoint.bottom + 320 > window.innerHeight
      if (isTooCloseToBottom) {
        waypointRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  const locations = useMemo(() => <LocationsList onWaypointItemFocus={onWaypointItemFocus} />, [onWaypointItemFocus])

  return (
    <WebAppSheet>
      {locations}
      {(start || end || basedOnRouteId) && <RoutingOptions />}
      {isFullRoute && (
        <WebAppContent ref={statsContentRef}>
          <RoutePlannerStatistics />
          <ElevationCurveWithSkeleton height="8rem" />
        </WebAppContent>
      )}
      {isFullRoute && (
        <WebAppContent>
          <RoutePlannerDiagrams />
        </WebAppContent>
      )}
      <WebAppScrollTooltip scrollToRef={statsContentRef}>
        {intl.formatMessage({
          id: 'route_planner_scroll_tooltip_route_estimates',
          defaultMessage: 'Route estimates',
        })}
      </WebAppScrollTooltip>
    </WebAppSheet>
  )
}
