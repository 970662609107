import { FilterRange, RouteBikeType, RouteSearchSorting, RouteSurface } from 'shared/data-access-core'
import { DiscoverSortingOptions, useDiscoverSorting } from './use-discover-sorting'
import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useDiscoverFilters } from './use-discover-filters'

export type DiscoverParams<ExtraSortingOptionType = RouteSearchSorting> = {
  sorting: RouteSearchSorting | ExtraSortingOptionType
  onlyLoops: boolean
  distanceKilometers: FilterRange
  ascentMeters: FilterRange
  title: string
  bikeTypes: RouteBikeType[]
  surfaces: RouteSurface[]
}

export function useDiscoverParams<ExtraSortingOptionType extends string = RouteSearchSorting>(
  sortingOptions: DiscoverSortingOptions<ExtraSortingOptionType> = {},
): DiscoverParams<ExtraSortingOptionType> {
  const [sorting] = useDiscoverSorting<ExtraSortingOptionType>(sortingOptions)
  const [filters] = useDiscoverFilters()

  const [discoverParams, setDiscoverParams] = useState<DiscoverParams<ExtraSortingOptionType>>({
    sorting,
    ...filters,
  })

  useEffect(() => {
    const paramsUpdate = { sorting, ...filters }
    if (!isEqual(paramsUpdate, discoverParams)) {
      setDiscoverParams(paramsUpdate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, filters])

  return discoverParams
}
