import React, { ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import _uniqueId from 'lodash/uniqueId'
import { Chip } from '../Chip'
import { FormControl } from '@mui/material'

import styles from './chip-select-field.module.scss'

export type ChipSelectFieldOption = {
  label: string
  icon?: ReactNode
}

export interface ChipSelectFieldProps<ValueType extends string> {
  name: string
  label: string
  options: Record<ValueType, ChipSelectFieldOption>
  value: ValueType[]
  onChange: (value: ValueType[]) => void
  labelHidden?: boolean
}

export function ChipSelectField<ValueType extends string>({
  name,
  label,
  options,
  value,
  onChange,
  labelHidden,
}: ChipSelectFieldProps<ValueType>) {
  const id = useMemo(() => _uniqueId(name + '-'), [name])

  const toggleValue = (newValue: string) => {
    const newValues: string[] = []
    for (const key in options) {
      const wasSelected = value.includes(key)
      if ((wasSelected && newValue !== key) || (!wasSelected && newValue === key)) {
        newValues.push(key)
      }
    }

    onChange(newValues as ValueType[])
  }

  const optionElements: React.ReactNode[] = []
  const chips: React.ReactNode[] = []

  for (const key in options) {
    const option = options[key]
    const isSelected = value.includes(key)
    optionElements.push(
      <option key={key} value={key}>
        {option.label}
      </option>,
    )
    chips.push(
      <Chip key={key} active={isSelected} onClick={() => toggleValue(key)} data-testid="chip" icon={option.icon}>
        {option.label}
      </Chip>,
    )
  }

  return (
    <FormControl>
      <label className={clsx(styles['label'], { [styles['invisible-label']]: labelHidden })} htmlFor={id}>
        {label}
      </label>
      <select
        className={styles['select']}
        id={id}
        name={name}
        multiple
        defaultValue={value.map((item) => '' + item)}
        tabIndex={-1}
      >
        {optionElements}
      </select>
      <div className={styles['chips']}>{chips}</div>
    </FormControl>
  )
}
