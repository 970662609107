import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { useRouteCollectionOwner, useIsOwnRouteCollection, useRouteCollection } from 'web-app/feature-route-collection'
import { OwnerLink } from './owner-link'
import { OwnerFlagActions } from './owner-flag-actions'
import { formatCreatedTimeAgo } from 'shared/util-formatting'

import styles from './owner-actions.module.css'

export const OwnerActions = () => {
  const { intl } = useLocale()
  const routeCollection = useRouteCollection()
  const owner = useRouteCollectionOwner()
  const isOwnRouteCollection = useIsOwnRouteCollection()

  const createdString = useMemo<string | undefined>(() => {
    if (!routeCollection) return undefined

    if (isOwnRouteCollection) {
      return intl.formatMessage(
        {
          id: 'route_collection_created_string',
          defaultMessage: 'on {date}',
        },
        {
          date: intl.formatDate(routeCollection.created),
        },
      )
    }

    return formatCreatedTimeAgo(
      routeCollection.created,
      intl.formatRelativeTime,
      intl.formatMessage({
        id: 'route_collection_created_less_than_minute_ago',
        defaultMessage: 'less than a minute ago',
      }),
    )
  }, [intl, isOwnRouteCollection, routeCollection])

  return (
    <div className={styles['container']}>
      <div className={styles['owner-row']}>
        {owner ? (
          <OwnerLink creator={owner} createdTimeAgo={createdString} />
        ) : (
          <Typography paragraph color="textSecondary" margin={0}>
            {intl.formatMessage(
              {
                id: 'route_collection_creator_anonymous',
                defaultMessage: 'Created {createdString}.',
              },
              {
                createdString,
              },
            )}
          </Typography>
        )}
        {!isOwnRouteCollection && <OwnerFlagActions />}
      </div>
    </div>
  )
}
