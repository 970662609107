import { Button, ButtonGroup, DeletingIndicator, Dialog, Modal, useMessages } from 'shared/ui-components'
import { useState } from 'react'
import { useLocale } from 'shared/util-intl'
import { useCancel, useSessionInfo } from 'web-app/feature-navigation'
import { deleteRouteCollection } from 'shared/data-access-core'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import {
  RouteCollectionSliceDispatch,
  resetRouteCollectionState,
  useRouteCollection,
} from 'web-app/feature-route-collection'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { getRouteCollectionsUrl } from 'shared/util-navigation'
import { useUser } from 'web-app/feature-user'
import { useDispatch } from 'react-redux'

export const DeleteRouteCollectionAction = () => {
  const { intl } = useLocale()
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const cancel = useCancel()
  const routeCollection = useRouteCollection()
  const showCommonErrorNotification = useCommonErrorNotification()
  const { cancelLabel, deleteLabel } = useMessages()
  const [user] = useUser()
  const sessionInfo = useSessionInfo()

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const handleDelete = async () => {
    if (!routeCollection) return

    setIsDeleteDialogShown(false)
    setIsDeleting(true)

    const result = await deleteRouteCollection(routeCollection.id)

    if (result.success) {
      dispatch(resetRouteCollectionState())
      cancel(
        user
          ? () => {
              window.location.href = getRouteCollectionsUrl({ ...sessionInfo, slug: user.slug })
            }
          : undefined,
      )
    } else {
      showCommonErrorNotification(
        intl.formatMessage({
          id: 'route_collection_delete_collection_error_details',
          defaultMessage: 'The route collection could not be deleted.',
        }),
      )
      setIsDeleting(false)
    }
  }

  const deletingMessage = intl.formatMessage({
    id: 'route_collection_creator_deleting_message',
    defaultMessage: 'Deleting route collection...',
  })

  return (
    <>
      <Button
        icon={<DeleteForeverRoundedIcon />}
        variant="danger-secondary"
        onClick={() => setIsDeleteDialogShown(true)}
      >
        {intl.formatMessage({
          id: 'route_collection_creator_button_delete',
          defaultMessage: 'Delete',
        })}
      </Button>
      <Dialog
        open={isDeleteDialogShown}
        title={intl.formatMessage({
          id: 'route_collection_creator_delete_dialog_title',
          defaultMessage: 'Are you sure?',
        })}
        text={intl.formatMessage({
          id: 'route_collection_creator_delete_dialog_text',
          defaultMessage:
            'The route collection and its content will be permanently deleted. This does not affect the routes themselves.',
        })}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" onClick={() => setIsDeleteDialogShown(false)}>
              {cancelLabel}
            </Button>
            <Button variant="danger-primary" onClick={handleDelete}>
              {deleteLabel}
            </Button>
          </ButtonGroup>
        }
        onClose={() => setIsDeleteDialogShown(false)}
      />
      <Modal open={isDeleting} aria-label={deletingMessage}>
        <DeletingIndicator message={deletingMessage} />
      </Modal>
    </>
  )
}
