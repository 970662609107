import { RegionKind } from 'shared/data-access-core'

export const RESULTS_LIST_LENGTH = 4

export const QUERY_DEBOUNCE_TIME = 750

export const PLACE_DEFAULT_ZOOM_LEVEL = 14

export const REGION_DEFAULT_ZOOM_LEVELS: Record<RegionKind, number> = {
  country: 5,
  admin_1: 7,
  admin_2: 12,
}
