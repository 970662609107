import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Modal as MuiModal, ModalProps as MuiModalProps, Slide } from '@mui/material'
import { ToolButton } from '../ToolButton'
import { useMessages } from '../MessagesProvider'

import styles from './FullScreenOverlay.module.scss'

type FullScreenOverlayOnCloseReasons = 'escapeKeyDown' | 'closeButtonClick'

type MuiModalOverrideProps = {
  onClose?: (e: React.MouseEvent | React.KeyboardEvent, reason: FullScreenOverlayOnCloseReasons) => void
}

export type FullScreenOverlayProps = MuiModalProps &
  MuiModalOverrideProps & {
    'aria-label': string
    header?: React.ReactNode
  }

export function FullScreenOverlay({ header, children, ...modalProps }: FullScreenOverlayProps) {
  const { closeLabel } = useMessages()

  const onCloseButtonClick = (e: React.MouseEvent | React.KeyboardEvent): void => {
    if (modalProps.onClose) {
      modalProps.onClose(e, 'closeButtonClick')
    }
  }

  const dismissButtonProps = {
    onClick: onCloseButtonClick,
    icon: <CloseIcon />,
    'data-testid': 'dismiss-button',
    ariaLabel: closeLabel,
  }

  return (
    <MuiModal hideBackdrop {...modalProps}>
      <Slide direction="up" in={modalProps.open}>
        <div className={styles['modal']}>
          <div className={styles['header-wrapper']}>
            <div className={styles['header']}>{header}</div>
            <ToolButton {...dismissButtonProps} variant="ghost-secondary" />
          </div>
          <div className={styles['content']}>{children}</div>
        </div>
      </Slide>
    </MuiModal>
  )
}
