import {
  ApiResult,
  AuthError,
  NotFoundError,
  ResponseParser,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'
import { API_PATH_ROUTE_COLLECTION } from '../../config'
import { getFromCoreApi } from '../../network'
import { RouteCollectionEntity, RouteCollectionEntityDetails, UserEntity, UserEntityAvatarSizes } from '../../entities'

type Response = {
  ascent_total: number
  category: number | null
  created: string
  descent_total: number
  description: string
  distance_total: number
  duration_total: number
  has_hidden_routes?: boolean
  id: number
  image: {
    fallback: string
    item: string | null
    large: string | null
    large_wide: string | null
    open_graph: string | null
    small: string | null
    small_wide: string | null
    tile: string | null
  } | null
  max_elevation: number | null
  route_count: number
  should_seo_index: boolean
  title: string
  user: {
    id: number
    displayname: string
    is_subscribed: boolean
    slug: string
    image: {
      fallback: string
      small: string | null
    } | null
  }
  visibility: boolean
}

export async function fetchRouteCollection(routeCollectionId: number): ApiResult<{
  collection: RouteCollectionEntity & RouteCollectionEntityDetails
  owner: UserEntity & UserEntityAvatarSizes
}> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_ROUTE_COLLECTION, {
      params: { routeCollectionId },
    })

    try {
      const parser = new ResponseParser(res)
      const categoryValue = parser.takeNumber('category')

      const owner: UserEntity & UserEntityAvatarSizes = {
        id: parser.in('user').requireNumber('id'),
        slug: parser.in('user').requireString('slug'),
        name: parser.in('user').requireString('displayname'),
        avatar: parser.in('user').takeImageSizes('image', {
          small: 'small',
          medium: 'medium',
          large: 'large',
          huge: 'huge',
        }),
        isPremium: parser.in('user').requireBoolean('is_subscribed'),
      }

      return createSuccessResult({
        collection: {
          category: categoryValue && categoryValue >= 1 && categoryValue <= 3 ? categoryValue : null,
          created: parser.requireTimestamp('created'),
          description: parser.takeString('description'),
          hasPrivateRoutes: parser.takeAsBoolean('has_hidden_routes'),
          id: parser.requireNumber('id'),
          image: parser.takeImageSizes('image', {
            item: 'item',
            tile: 'tile',
            small: 'small',
            smallWide: 'small_wide',
            large: 'large',
            largeWide: 'large_wide',
            openGraph: 'open_graph',
          }),
          isPrivate: !parser.requireBoolean('visibility'),
          maximumElevationMeters: parser.takeNumber('max_elevation'),
          owner: owner.id,
          routesCount: parser.requireNumber('route_count'),
          shouldBeIndexed: parser.requireBoolean('should_seo_index'),
          title: parser.requireString('title'),
          totalAscentMeters: parser.requireNumber('ascent_total'),
          totalDescentMeters: parser.requireNumber('descent_total'),
          totalDistanceMeters: parser.requireNumber('distance_total'),
          totalDurationSeconds: parser.requireNumber('duration_total'),
        },
        owner,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    if (error instanceof AuthError) {
      return createFailureResult({ unauthorized: true }, { routeCollectionId })
    }
    if (error instanceof NotFoundError) {
      return createFailureResult({ notFound: true }, { routeCollectionId })
    }
    return createFailureResult({
      unexpectedError: true,
    })
  }
}
