import styles from './Divider.module.scss'

export interface DividerProps {
  children?: string
}

export function Divider(props: DividerProps) {
  const { children } = props

  return <div className={styles['root']}>{children && <span className={styles['content']}>{children}</span>}</div>
}

export default Divider
