import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createMigrate, MigrationManifest, PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { hasGeometry, hasMatching, hasSimplify } from './selectors'
import { getNextStep, getPrevStep } from './selectors-steps'
import { RouteImportState } from './types'

export const IMPORT_SLICE_KEY = 'import'

export const initialState: RouteImportState = {
  fromStep: 'upload',
  step: 'upload',
  isUploadInProgress: false,
  routeFileName: null,
  statusTaskId: null,
  routeData: null,
  isMatchingSelected: true,
  isSimplifySelected: true,
  simplifiedRouteData: null,
  isSimplifiedRouteMissing: false,
  title: null,
  description: null,
}

const slice = createSlice({
  name: IMPORT_SLICE_KEY,
  initialState,
  reducers: {
    stepNext(state) {
      return {
        ...state,
        fromStep: state.step,
        step: getNextStep(state),
      }
    },
    stepPrev(state) {
      return {
        ...state,
        fromStep: state.step,
        step: getPrevStep(state),
      }
    },
    uploadRequest(state, action: PayloadAction<RouteImportState['routeFileName']>) {
      return {
        ...state,
        isUploadInProgress: true,
        routeFileName: action.payload,
      }
    },
    uploadSuccess(state, action: PayloadAction<RouteImportState['statusTaskId']>) {
      const statusTaskId = action.payload

      // Reset everything if we didn't get status task ID
      if (!statusTaskId) {
        return initialState
      }

      return {
        ...state,
        statusTaskId,
        // Reset other data which might be old
        routeData: initialState.routeData,
        simplifiedRouteData: initialState.simplifiedRouteData,
        title: null,
        description: null,
      }
    },
    uploadFailure() {
      return initialState
    },
    getRouteDataSuccess(state, action: PayloadAction<RouteImportState['routeData']>) {
      const routeData = action.payload
      const newState = {
        ...state,
        routeData,
        // Reset simplified route which might be old
        simplifiedRouteData: initialState.simplifiedRouteData,
        // Reset or if found from imported route use that
        title: routeData?.title || null,
        description: routeData?.description || null,
      }
      const step = getNextStep(newState)
      const isUploadInProgress =
        (step === 'matching' && !hasMatching(newState)) || (step === 'simplify' && !hasSimplify(newState))

      if (!hasGeometry(newState)) {
        return initialState
      }

      return {
        ...newState,
        fromStep: state.step,
        // Move to next step
        step,
        isUploadInProgress,
        // Deselect map-matching if we skipped it
        isMatchingSelected: step !== 'matching' ? false : state.isMatchingSelected,
      }
    },
    getRouteDataFailure() {
      return initialState
    },
    uploadCanceled() {
      return initialState
    },
    mapMatchingChanged(state, action: PayloadAction<RouteImportState['isMatchingSelected']>) {
      return {
        ...state,
        isMatchingSelected: action.payload,
      }
    },
    routeSimplifyChanged(state, action: PayloadAction<RouteImportState['isSimplifySelected']>) {
      return {
        ...state,
        isSimplifySelected: action.payload,
      }
    },
    routeSimplifySuccess(state, action: PayloadAction<RouteImportState['simplifiedRouteData']>) {
      return {
        ...state,
        isUploadInProgress: false,
        simplifiedRouteData: action.payload,
        isSimplifiedRouteMissing: false,
      }
    },
    routeSimplifyFailure(state) {
      // Go to next step if we're not on matching step (there we just pre-fetch simplified route)
      const shouldGoToNextStep = state.step !== 'matching'
      return {
        ...state,
        isUploadInProgress: false,
        isSimplifiedRouteMissing: true,
        fromStep: shouldGoToNextStep ? state.step : state.fromStep,
        step: shouldGoToNextStep ? getNextStep(state) : state.step,
      }
    },
    saveRouteFailure(state) {
      state.step = state.fromStep
      state.fromStep = 'save'
    },
    reset() {
      return initialState
    },
  },
})

export const {
  stepNext,
  stepPrev,
  uploadRequest,
  uploadSuccess,
  uploadFailure,
  getRouteDataSuccess,
  getRouteDataFailure,
  uploadCanceled,
  mapMatchingChanged,
  routeSimplifyChanged,
  routeSimplifySuccess,
  routeSimplifyFailure,
  saveRouteFailure,
  reset,
} = slice.actions

const migrations = {
  1: (): RouteImportState => initialState, // reset after refactoring data-access-core return types
}

const persistConfig: PersistConfig<RouteImportState> = {
  key: IMPORT_SLICE_KEY,
  storage,
  migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: true }),
}

export const routeImportReducer = persistReducer(persistConfig, slice.reducer)
