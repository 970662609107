import { LocationList } from 'shared/ui-components'
import { BikemapBikeIcon } from 'shared/ui-design-system/icons/bikemap-bike-icon'
import { RouteEntity } from 'shared/data-access-core'
import { SearchResult } from './types'
import { useLocale } from 'shared/util-intl'

interface RouteResultItemProps {
  route: RouteEntity
  onSelect: (result: SearchResult) => void
}

export const RouteResultItem = ({ route, onSelect }: RouteResultItemProps) => {
  const { intl } = useLocale()

  return (
    <LocationList.Item
      title={route.title}
      subtitle={
        route.location
          ? intl.formatMessage(
              {
                id: 'search_route_result_item_subtitle',
                defaultMessage: 'Route in {region}',
              },
              {
                region: route.location.name,
              },
            )
          : intl.formatMessage({
              id: 'search_route_result_item_subtitle_fallback',
              defaultMessage: 'Route',
            })
      }
      icon={<BikemapBikeIcon />}
      onClick={() => onSelect({ type: 'route', data: route })}
    />
  )
}
