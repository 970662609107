import clsx from 'clsx'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { BaseButton } from '../../base/BaseButton'
import { Avatar, AvatarProps } from '../Avatar'

import styles from './avatar-menu-button.module.scss'

export interface AvatarMenuButtonProps extends Omit<AvatarProps, 'className'> {
  isMenuOpen: boolean
  ariaLabel: string
  compact?: boolean
  onClick: () => void
}

export const AvatarMenuButton = ({
  isMenuOpen,
  ariaLabel,
  compact,
  onClick,
  ...avatarProps
}: AvatarMenuButtonProps) => (
  <BaseButton
    variant="secondary"
    className={clsx(styles['root'], { [styles['compact']]: compact })}
    onClick={onClick}
    ariaLabel={ariaLabel}
  >
    {isMenuOpen ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
    <div className={styles['avatar']}>
      <Avatar {...avatarProps} />
    </div>
  </BaseButton>
)
