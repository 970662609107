import { Button } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { ReactNode } from 'react'
import { useScrollPagination } from './use-scroll-pagination'

import styles from './paginated-list.module.css'

interface PaginatedListProps<ItemType> {
  loading?: boolean
  results: ItemType[] | null
  count?: number
  children?: ReactNode
  skeleton: ReactNode
  renderItem: (item: ItemType) => ReactNode
  onLoadMore: () => Promise<void>
}

export function PaginatedList<ItemType extends { key: string }>({
  loading,
  results,
  count,
  children,
  skeleton,
  renderItem,
  onLoadMore,
}: PaginatedListProps<ItemType>) {
  const { intl } = useLocale()
  const { canLoadMore, isLoadingMore, loadMoreButtonRef, loadMore } = useScrollPagination(
    !!loading,
    results?.length,
    count,
    onLoadMore,
  )

  return loading || !results || results.length ? (
    <ul className={styles['list']}>
      {loading || !results ? (
        <>
          <li>{skeleton}</li>
          <li>{skeleton}</li>
          <li>{skeleton}</li>
        </>
      ) : (
        results.map((item) => <li key={item.key}>{renderItem(item)}</li>)
      )}
      {isLoadingMore && (
        <>
          <li>{skeleton}</li>
          <li>{skeleton}</li>
          <li>{skeleton}</li>
        </>
      )}
      {canLoadMore && (
        <Button ref={loadMoreButtonRef} block variant="secondary" onClick={loadMore}>
          {intl.formatMessage({
            id: 'paginated_list_load_more',
            defaultMessage: 'Load more',
          })}
        </Button>
      )}
    </ul>
  ) : (
    children
  )
}
