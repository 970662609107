import { LngLat } from 'shared/util-geo'

export const ROUTE_POI_TYPES = ['sight', 'photo', 'repair', 'water', 'hotel'] as const

export type RoutePoiType = (typeof ROUTE_POI_TYPES)[number]

export type RoutePoiEntity = {
  id: number
  routeId: number
  position: LngLat
  type: RoutePoiType
  description: string | null
  image: {
    mapPopup: string
  } | null
}

export const dummyRoutePoiEntity: RoutePoiEntity = {
  id: 531,
  routeId: 100,
  position: { lng: 17.111, lat: 49.114 },
  type: 'photo',
  description: 'Some description...',
  image: {
    mapPopup: '/map-popup',
  },
}
