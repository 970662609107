import { LineString } from 'geojson'
import { controlPointsSelector, routePlannerSliceSelector, waypointsSelector } from '../selectors'
import {
  controlPointInserted,
  implicitWaypointDrawn,
  selectedLocationAddedAlongRoute,
  waypointInserted,
} from '../state'
import { RoutePlannerSliceDispatch, StateWithRoutePlannerSlice, WaypointTemplate } from '../types'
import { calculateSegments } from './route-calculation'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { LngLat } from 'shared/ui-map'
import { StateWithIntlSlice } from 'shared/util-intl'
import { reverseGeocode } from './geocoding'
import { logError } from 'web-app/utils-error-handling'
import { fitMapToGeometryBounds } from './thunks'
import { lngLatToPosition2d } from 'shared/util-geo'

/**
 * Insert a new waypoint at any position in the waspoints list (before given index) and recalculate affected
 * segments accordingly. Control points between affected segments are removed.
 */
export function insertWaypoint(beforeIndex: number, waypoint: WaypointTemplate) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const originalState = getState()

    if (routePlannerSliceSelector(originalState).isCalculatingRoute) {
      logError('Trying to insert waypoint while route is still being calculated')
      return
    }

    const { isFullRoute: wasFullRoute, waypoints: originalWaypoints } = waypointsSelector(originalState)
    dispatch(waypointInserted({ beforeIndex, waypoint }))

    const state = getState()
    const { isFullRoute, waypoints } = waypointsSelector(state)
    if (isFullRoute) {
      const controlPoints = controlPointsSelector(state)
      if (beforeIndex === 0) {
        // start
        await dispatch(calculateSegments([waypoint, waypoints[1]], 0, 0))
      } else if (beforeIndex < waypoints.length - 1) {
        // via
        if (!controlPoints || !wasFullRoute) return // invalid state
        const startControlPointIndex = originalWaypoints[beforeIndex - 1].controlPointIndex
        const startControlPoint = controlPoints[startControlPointIndex]
        const endControlPointIndex = originalWaypoints[beforeIndex].controlPointIndex
        const endControlPoint = controlPoints[endControlPointIndex]
        await dispatch(
          calculateSegments(
            [startControlPoint, waypoint, endControlPoint],
            startControlPointIndex,
            endControlPointIndex - startControlPointIndex,
          ),
        )
      } else {
        // end
        const previousWaypoint = waypoints[waypoints.length - 2]
        const requestWaypoints = [
          controlPoints ? controlPoints[previousWaypoint.controlPointIndex] : previousWaypoint,
          waypoint,
        ]
        await dispatch(calculateSegments(requestWaypoints, previousWaypoint.controlPointIndex, 0))
      }
      dispatch(fitMapToGeometryBounds())
    }
  }
}

/**
 * Insert the currently selected location as a waypoint and reroute within the nearest route segment.
 */
export function insertSelectedLocationAlongRoute() {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const state = getState()

    if (routePlannerSliceSelector(state).isCalculatingRoute) {
      logError('Trying to insert selected location along route while route is still being calculated')
      return
    }

    const { selectedLocation } = routePlannerSliceSelector(state)
    const controlPoints = controlPointsSelector(state)
    if (selectedLocation && controlPoints) {
      const directSegments: LineString = {
        type: 'LineString',
        coordinates: controlPoints.map(lngLatToPosition2d),
      }
      const { lng, lat } = selectedLocation
      const { properties } = nearestPointOnLine(directSegments, [lng, lat])
      const lastSegmentIndex = controlPoints.length - 2
      const segmentIndex =
        typeof properties.index === 'number' ? Math.min(properties.index, lastSegmentIndex) : lastSegmentIndex
      dispatch(selectedLocationAddedAlongRoute({ segmentIndex }))
      const requestWaypoints = [controlPoints[segmentIndex], selectedLocation, controlPoints[segmentIndex + 1]]
      await dispatch(calculateSegments(requestWaypoints, segmentIndex, 1))
    }
  }
}

/**
 * Extend the route to the given location and add an implicit destination, which moves on while drawing.
 */
export function drawRoute(location: LngLat) {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithIntlSlice,
  ) => {
    if (routePlannerSliceSelector(getState()).isCalculatingRoute) {
      logError('Trying to draw route while route is still being calculated')
      return
    }

    dispatch(implicitWaypointDrawn({ ...location }))

    const state = getState()
    const { isFullRoute, start, end } = waypointsSelector(state)
    const controlPoints = controlPointsSelector(state)
    if (isFullRoute) {
      // needs routing
      const requestWaypoints = controlPoints ? [controlPoints[controlPoints.length - 1], location] : [start, end]
      dispatch(calculateSegments(requestWaypoints, controlPoints ? controlPoints.length - 1 : 0, 0))
    }
    dispatch(reverseGeocode(location))
  }
}

/**
 * Insert a control point and reroute within a given route segment.
 */
export function insertDraggedControlPoint(segmentIndex: number, location: LngLat) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const state = getState()

    if (routePlannerSliceSelector(state).isCalculatingRoute) {
      logError('Trying to insert dragged control point while route is still being calculated')
      return
    }

    const controlPoints = controlPointsSelector(state)
    if (controlPoints && segmentIndex >= 0 && segmentIndex < controlPoints.length - 1) {
      dispatch(controlPointInserted({ beforeIndex: segmentIndex + 1 }))
      const requestWaypoints = [controlPoints[segmentIndex], location, controlPoints[segmentIndex + 1]]
      await dispatch(calculateSegments(requestWaypoints, segmentIndex, 1))
    }
  }
}
