import { SubscriptionPlanEntity } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'

const useCommonAttributes = (plan: SubscriptionPlanEntity) => {
  const { intl } = useLocale()

  return {
    freeTrial: intl.formatMessage(
      {
        id: 'subscription_attribute_free_trial',
        defaultMessage: 'Free trial for {trialDays} days',
      },
      {
        trialDays: plan.trialDays,
      },
    ),
    cancelAnytime: intl.formatMessage({
      id: 'subscription_attribute_cancel_anytime',
      defaultMessage: 'Cancel anytime',
    }),
  }
}

const useOneTimePlanLabels = () => {
  const { intl } = useLocale()

  return {
    interval: intl.formatMessage({
      id: 'subscription_label_one_time_payment',
      defaultMessage: 'One-time payment',
    }),
  }
}

export const useMonthlyLabels = (plan: SubscriptionPlanEntity) => {
  const { intl } = useLocale()
  const attributes = useCommonAttributes(plan)

  return {
    name: intl.formatMessage({
      id: 'subscription_label_monthly_name',
      defaultMessage: 'Monthly',
    }),
    interval: intl.formatMessage({
      id: 'subscription_label_monthly_interval',
      defaultMessage: 'per month',
    }),
    attributes: [
      intl.formatMessage({
        id: 'subscription_attribute_monthly_access',
        defaultMessage: 'Get access to all exclusive Premium features for a month',
      }),
      attributes.freeTrial,
      attributes.cancelAnytime,
    ],
  }
}

export const useYearlyLabels = (plan: SubscriptionPlanEntity) => {
  const { intl } = useLocale()
  const attributes = useCommonAttributes(plan)

  return {
    name: intl.formatMessage({
      id: 'subscription_label_yearly_name',
      defaultMessage: 'Annual',
    }),
    interval: intl.formatMessage({
      id: 'subscription_label_yearly_interval',
      defaultMessage: 'per year',
    }),
    attributes: [
      intl.formatMessage({
        id: 'subscription_attribute_yearly_access',
        defaultMessage: 'Get access to all exclusive Premium features for a whole year',
      }),
      attributes.freeTrial,
      attributes.cancelAnytime,
    ],
  }
}

export const useThreeYearsLabels = () => {
  const { intl } = useLocale()
  const oneTimePlanLabels = useOneTimePlanLabels()

  return {
    name: intl.formatMessage({
      id: 'subscription_label_3_years_name',
      defaultMessage: '3 years',
    }),
    interval: oneTimePlanLabels.interval,
    attributes: [
      intl.formatMessage({
        id: 'subscription_attribute_3_years_access',
        defaultMessage: 'Get access to all exclusive Premium features for three years',
      }),
      oneTimePlanLabels.interval,
      intl.formatMessage({
        id: 'subscription_attribute_3_years_expiration',
        defaultMessage: 'Expires automatically',
      }),
    ],
  }
}

export const useLifetimeLabels = () => {
  const { intl } = useLocale()
  const oneTimePlanLabels = useOneTimePlanLabels()

  return {
    name: intl.formatMessage({
      id: 'subscription_label_lifetime_name',
      defaultMessage: 'Lifetime',
    }),
    interval: oneTimePlanLabels.interval,
    attributes: [
      intl.formatMessage({
        id: 'subscription_attribute_lifetime_access',
        defaultMessage: 'Get access to all exclusive Premium features forever',
      }),
      oneTimePlanLabels.interval,
      intl.formatMessage({
        id: 'subscription_attribute_lifetime_up_to_date',
        defaultMessage: 'Stay up to date with cycling technology',
      }),
    ],
  }
}

export const useGiftYearlyLabels = () => {
  const { intl } = useLocale()
  const oneTimePlanLabels = useOneTimePlanLabels()

  return {
    name: intl.formatMessage({
      id: 'subscription_label_gift_yearly_name',
      defaultMessage: 'One year',
    }),
    interval: oneTimePlanLabels.interval,
    attributes: [
      intl.formatMessage({
        id: 'subscription_attribute_gift_yearly_access',
        defaultMessage: 'Give access to all exclusive Premium features for a whole year',
      }),
      oneTimePlanLabels.interval,
      intl.formatMessage({
        id: 'subscription_attribute_gift_yearly_expiration',
        defaultMessage: 'Automatically ends 12 months after activation',
      }),
    ],
  }
}

export const useGiftThreeYearsLabels = () => {
  const { intl } = useLocale()
  const oneTimePlanLabels = useOneTimePlanLabels()

  return {
    name: intl.formatMessage({
      id: 'subscription_label_gift_3_years_name',
      defaultMessage: '3 years',
    }),
    interval: oneTimePlanLabels.interval,
    attributes: [
      intl.formatMessage({
        id: 'subscription_attribute_gift_3_years_access',
        defaultMessage: 'Give access to all exclusive Premium features for three years',
      }),
      oneTimePlanLabels.interval,
      intl.formatMessage({
        id: 'subscription_attribute_gift_3_years_expiration',
        defaultMessage: 'Automatically ends 36 months after activation',
      }),
    ],
  }
}
