import { Chip, ChipSkeleton } from 'shared/ui-components'
import { logError } from 'shared/util-error-handling'
import { ReactNode } from 'react'

import styles from './chips-row.module.css'

interface ChipsRowProps {
  chips: number[]
  labels: Record<number, string>
  icons: Record<number, ReactNode>
}

export const ChipsRow = ({ chips, labels, icons }: ChipsRowProps) => (
  <div className={styles['container']} data-testid="chips-row-container">
    {chips.map((chip) => {
      const label = labels[chip]
      if (label) {
        return (
          <Chip key={chip} icon={icons[chip]}>
            {labels[chip]}
          </Chip>
        )
      }
      logError('No label available for this chip: ' + chip)
      return null
    })}
  </div>
)

export const ChipsRowSkeleton = () => (
  <div className={styles['container']}>
    <ChipSkeleton />
    <ChipSkeleton />
  </div>
)
