import { LocationList } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useSearchState } from './state'
import { PlaceResultItem } from './place-result-item'
import { SearchResult } from './types'
import { RegionResultItem } from './region-result-item'
import { RouteResultItem } from './route-result-item'

interface SearchHistoryListProps {
  includeRegions?: boolean
  onSelect: (result: SearchResult) => void
}

export const SearchHistoryList = ({ includeRegions, onSelect }: SearchHistoryListProps) => {
  const { intl } = useLocale()
  const { history } = useSearchState()

  const visibleResults = includeRegions ? history : history.filter((result) => result.type === 'place')

  return (
    visibleResults.length > 0 && (
      <LocationList.List
        title={intl.formatMessage({
          id: 'search_history_list_title',
          defaultMessage: 'Search history',
        })}
        secondary
      >
        {visibleResults.map((result, index) =>
          result.type === 'place' ? (
            <PlaceResultItem key={`search-history-item-${index}`} place={result.data} onSelect={onSelect} />
          ) : result.type === 'region' ? (
            <RegionResultItem key={`search-history-item-${index}`} region={result.data} onSelect={onSelect} />
          ) : (
            <RouteResultItem key={`search-history-item-${index}`} route={result.data} onSelect={onSelect} />
          ),
        )}
      </LocationList.List>
    )
  )
}
