import { ElevationCurveProvider } from 'shared/feature-elevation-curve'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'
import { MainRouteStats, useRoute } from 'web-app/feature-route'
import { UserContent } from 'shared/ui-components'
import { BasePage } from './base-page'
import { PrintElevationCurve } from '../components/print-elevation-curve'
import { PrintAppSection } from '../components/print-app-section'
import { useRoutePrintState } from '../state'
import { OverviewMap } from '../components/overview-map'

import styles from './overview-page.module.css'

export const OverviewPage = () => {
  const { intl } = useLocale()
  const { unitPreference } = useUserState()
  const route = useRoute()
  const { isDescriptionShown } = useRoutePrintState()

  return (
    <BasePage
      title={intl.formatMessage({
        id: 'route_print_overview_page_title',
        defaultMessage: 'Route overview',
      })}
      number={1}
    >
      <div className={styles['container']}>
        <ElevationCurveProvider geometry={route?.geometry} unitPreference={unitPreference}>
          <OverviewMap />
          <div className={styles['route-content']}>
            <h2 className={styles['route-title']}>{route?.title}</h2>
            <MainRouteStats />
            <PrintElevationCurve />
            {route?.description && isDescriptionShown && (
              <div className={styles['description']}>
                <UserContent linesLimit={10}>{route?.description}</UserContent>
              </div>
            )}
            <div className={styles['sidebar']}>
              <PrintAppSection />
            </div>
          </div>
        </ElevationCurveProvider>
      </div>
    </BasePage>
  )
}
