import { createSlice, PayloadAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { createMigrate, MigrationManifest, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  Currency,
  UserEntity,
  UserEntityAnalyticsData,
  UserEntityDetails,
  UserEntitySessionData,
} from 'shared/data-access-core'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { StateWithEntitiesSlice } from 'web-app/data-access-entities'

export const USER_SLICE_KEY = 'user'

export type UnitPreference = 'metric' | 'imperial'

export type UserGeolocation = {
  lng: number
  lat: number
  geocoded: GeocoderLocation
}

export type UserState = {
  user: null | (UserEntity & UserEntityDetails & UserEntityAnalyticsData & UserEntitySessionData)
  isLoggedIn: boolean
  isUserLoaded: boolean
  unitPreference: UnitPreference
  currencyPreference: Currency | null

  /** Flag set during all the calls necessary to get an updated `geolocation` */
  isGeolocationLoading: boolean

  /** Flag set when client location has been denied by the user */
  isGeolocationDenied: boolean

  /** Accurate and geocoded geolocation of the user, based on browser client location */
  geolocation: UserGeolocation | null

  /** Timestamp when the last premium upsell trigger was shown */
  premiumTriggerLastShown: number | null

  /** IDs of the user's route collections */
  routeCollectionIds: number[] | undefined
}

export interface StateWithUserSlice {
  [USER_SLICE_KEY]: UserState
}

export type UserSliceDispatch = ThunkDispatch<StateWithUserSlice & StateWithEntitiesSlice, undefined, UnknownAction>

export const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  isUserLoaded: false,
  unitPreference: 'metric',
  currencyPreference: null,
  isGeolocationLoading: false,
  isGeolocationDenied: false,
  geolocation: null,
  premiumTriggerLastShown: null,
  routeCollectionIds: undefined,
}

const slice = createSlice({
  name: USER_SLICE_KEY,
  initialState,
  reducers: {
    getUserRequest(state) {
      return {
        ...state,
        isUserLoaded: false,
      }
    },
    getUserSuccess(
      state,
      action: PayloadAction<UserEntity & UserEntityDetails & UserEntityAnalyticsData & UserEntitySessionData>,
    ) {
      const user = action.payload
      return {
        ...state,
        user,
        isLoggedIn: true,
        isUserLoaded: true,
      }
    },
    getUserFailure(state) {
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isUserLoaded: true,
      }
    },
    unitPreferenceUpdated(state, action: PayloadAction<UnitPreference>) {
      return {
        ...state,
        unitPreference: action.payload,
      }
    },
    currencyPreferenceUpdated(state, action: PayloadAction<Currency>) {
      state.currencyPreference = action.payload
    },
    geolocationRequest(state) {
      return {
        ...state,
        isGeolocationLoading: true,
      }
    },
    geolocationSuccess(state, action: PayloadAction<UserGeolocation>) {
      return {
        ...state,
        isGeolocationLoading: false,
        isGeolocationDenied: false,
        geolocation: action.payload,
      }
    },
    geolocationFailure(state) {
      return {
        ...state,
        isGeolocationLoading: false,
        geolocation: null,
      }
    },
    geolocationDenied(state) {
      return {
        ...state,
        isGeolocationLoading: false,
        isGeolocationDenied: true,
        geolocation: null,
      }
    },
    favoriteRoutesCountUpdated(state, action: PayloadAction<number>) {
      if (state.user) {
        state.user.favoriteRoutesCount = action.payload
      }
    },
    premiumTriggerShown(state) {
      state.premiumTriggerLastShown = new Date().getTime()
    },
    userRouteCollectionsFetched(state, action: PayloadAction<number[]>) {
      state.routeCollectionIds = action.payload
    },
    routeCollectionCreated(state, action: PayloadAction<number>) {
      state.routeCollectionIds?.unshift(action.payload)
    },
  },
})

export const {
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  unitPreferenceUpdated,
  currencyPreferenceUpdated,
  geolocationRequest,
  geolocationSuccess,
  geolocationFailure,
  geolocationDenied,
  favoriteRoutesCountUpdated,
  premiumTriggerShown,
  userRouteCollectionsFetched,
  routeCollectionCreated,
} = slice.actions

const migrations = {
  1: (state: UserState): UserState => ({
    // reset user session data after entity refactoring
    ...state,
    user: null,
    isLoggedIn: false,
  }),
}

const persistConfig = {
  key: USER_SLICE_KEY,
  version: 1,
  storage,
  whitelist: [
    'user',
    'isLoggedIn',
    'unitPreference',
    'currencyPreference',
    'isGeolocationDenied',
    'premiumTriggerLastShown',
  ],
  migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: true }),
}

export const userReducer = persistReducer(persistConfig, slice.reducer)
