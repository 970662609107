import { ElevationData, UnitPreference } from '../types'
import { ELEVATION_ZERO_SPIKE_TOLERANCE } from '../settings'
import { metersToFeet } from 'shared/util-formatting'
import { RichPosition } from 'shared/util-geo'

/**
 * Calculates a graph of absolute distance and elevation per position from given 3-dimensional route geometry.
 */
export function calculateElevationCurve(
  coordinates: RichPosition[],
  unitPreference: UnitPreference,
  segmentOptions?: {
    index: number
    distanceOffset: number
  },
): ElevationData[] {
  if (coordinates.length < 2) {
    throw Error('Invalid route geometry for calculating elevation curve.')
  }

  const elevation: ElevationData[] = [
    {
      distance: segmentOptions?.distanceOffset ?? 0,
      elevation: getElevationFromPosition(coordinates, 0, unitPreference),
      segmentIndex: segmentOptions?.index ?? 0,
    },
  ]

  for (let i = 1; i < coordinates.length; i++) {
    // Elevation data should already be in desired unit to get round tick values
    const relativeDistance = unitPreference === 'imperial' ? metersToFeet(coordinates[i][3]) : coordinates[i][3]
    const absoluteDistance = elevation[i - 1].distance + relativeDistance

    elevation.push({
      distance: absoluteDistance,
      elevation: getElevationFromPosition(coordinates, i, unitPreference),
      segmentIndex: segmentOptions?.index ?? 0,
    })
  }

  return elevation
}

/**
 * Get unit-aware elevation value from a rich position, flattening spikes to 0.
 */
function getElevationFromPosition(coordinates: RichPosition[], i: number, unitPreference: UnitPreference): number {
  const elevation = coordinates[i][2]
  let flattenedElevation = elevation
  if (elevation === 0) {
    const isFirst = i === 0
    const isLast = i === coordinates.length - 1

    if (
      (isFirst || coordinates[i - 1][2] > ELEVATION_ZERO_SPIKE_TOLERANCE) &&
      (isLast || coordinates[i + 1][2] > ELEVATION_ZERO_SPIKE_TOLERANCE)
    ) {
      if (isFirst && !isLast) flattenedElevation = coordinates[i + 1][2]
      if (!isFirst && isLast) flattenedElevation = coordinates[i - 1][2]
      if (!isFirst && !isLast) flattenedElevation = (coordinates[i - 1][2] + coordinates[i + 1][2]) / 2
    }
  }

  return unitPreference === 'imperial' ? metersToFeet(flattenedElevation) : flattenedElevation
}
