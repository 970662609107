const DYNAMIC_LINK_BASE_URL = 'https://page.bikemap.it'

type TrackingParameters = {
  utmSource: string
  utmMedium: string
  utmCampaign: string
}

function getTrackingParamsString({ utmSource, utmMedium, utmCampaign }: TrackingParameters) {
  return (
    '?' +
    new URLSearchParams({
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    })
  )
}

export function getGeneralDynamicLink(trackingParams: TrackingParameters) {
  return DYNAMIC_LINK_BASE_URL + getTrackingParamsString(trackingParams)
}

const DYNAMIC_LINK_ROUTE_DETAILS = DYNAMIC_LINK_BASE_URL + '/r/:routeId'

export function getRouteDetailsDynamicLink(routeId: number, trackingParams: TrackingParameters) {
  return DYNAMIC_LINK_ROUTE_DETAILS.replace(':routeId', routeId.toString()) + getTrackingParamsString(trackingParams)
}

const DYNAMIC_LINK_NAVIGATION = DYNAMIC_LINK_BASE_URL + '/navigationsettings/:routeId'

export function getNavigationDynamicLink(routeId: number, trackingParams: TrackingParameters) {
  return DYNAMIC_LINK_NAVIGATION.replace(':routeId', routeId.toString()) + getTrackingParamsString(trackingParams)
}

const DYNAMIC_LINK_DISCOVER = DYNAMIC_LINK_BASE_URL + '/discover'

export function getDiscoverDynamicLink(trackingParams: TrackingParameters) {
  return DYNAMIC_LINK_DISCOVER + getTrackingParamsString(trackingParams)
}
