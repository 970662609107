import { ApiResult, NotFoundError, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { RegionEntity, RegionEntityDetails, RegionEntityMapData, RegionEntityParents } from '../../entities/region'
import { getFromCoreApi } from '../../network'
import { API_PATH_REGION } from '../../config'
import { Point } from 'geojson'

type Response = {
  id: number
  kind: string
  name: string
  introduction: string | null
  image: {
    fallback: string
    small: string | null
    small_wide: string | null
    large: string | null
    large_wide: string | null
    open_graph: string | null
  } | null
  facts: {
    downhill_route_count: number
    flat_route_count: number
    hilly_route_count: number
    popular_months: number[]
    population: number | null
    route_count: number
    total_distance: number
    uphill_route_count: number
  }
  center: Point
  bounds: number[] | null
  country: {
    id: number
    name: string
  } | null
  admin_1: {
    id: number
    name: string
  } | null
  highlights: { content: string }[]
  tips: { content: string }[]
}

export async function fetchRegion(
  id: number,
  language: 'en' | 'de',
): ApiResult<RegionEntity & RegionEntityDetails & RegionEntityParents & RegionEntityMapData> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_REGION, {
      headers: {
        'Accept-Language': language,
      },
      params: {
        geonameId: id,
      },
    })
    try {
      return createSuccessResult(convertToRegionEntity(res))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    if (e instanceof NotFoundError) {
      return createFailureResult({ notFound: true })
    }
    return createFailureResult({ unexpectedError: true })
  }
}

class UnexpectedRegionFormatError extends Error {}

function convertToRegionEntity(
  res: Response,
): RegionEntity & RegionEntityDetails & RegionEntityParents & RegionEntityMapData {
  const parser = new ResponseParser(res)

  const entity = {
    id: parser.requireNumber('id'),
    name: parser.requireString('name'),
    introduction: parser.takeString('introduction'),
    image: parser.takeImageSizes('image', {
      small: 'small',
      smallWide: 'small_wide',
      large: 'large',
      largeWide: 'large_wide',
      openGraph: 'open_graph',
    }),
    routesCount: parser.in('facts').requireNumber('route_count'),
    trackedDistanceKilometers: Math.round(parser.in('facts').requireNumber('total_distance') / 1000),
    downhillRoutesCount: parser.in('facts').requireNumber('downhill_route_count'),
    flatRoutesCount: parser.in('facts').requireNumber('flat_route_count'),
    hillyRoutesCount: parser.in('facts').requireNumber('hilly_route_count'),
    uphillRoutesCount: parser.in('facts').requireNumber('uphill_route_count'),
    popularMonths: parser.in('facts').takeArray('popular_months', (m) => (typeof m === 'number' ? m : null)),
    population: parser.in('facts').takeNumber('population'),
    center: parser.requireLngLat('center'),
    bounds: parser.takeBoundsArray('bounds'),
    highlights: parser.takeArray('highlights', (highlight: { content: string }) => highlight.content),
    tips: parser.takeArray('tips', (tip: { content: string }) => tip.content),
  }

  const kind = parser.requireString('kind')
  if (kind === 'country') {
    return {
      ...entity,
      kind: 'country',
    }
  }

  if (kind === 'admin_1') {
    return {
      ...entity,
      kind: 'admin_1',
      country: {
        id: parser.in('country').requireNumber('id'),
        name: parser.in('country').requireString('name'),
      },
    }
  }

  if (kind === 'admin_2') {
    return {
      ...entity,
      kind: 'admin_2',
      country: {
        id: parser.in('country').requireNumber('id'),
        name: parser.in('country').requireString('name'),
      },
      admin1: {
        id: parser.in('admin_1').requireNumber('id'),
        name: parser.in('admin_1').requireString('name'),
      },
    }
  }

  throw new UnexpectedRegionFormatError()
}
