import { fetchIpBasedLocation } from 'shared/data-access-core'
import { MapViewport } from 'shared/ui-map'

/**
 * Try to get the map viewport for the IP based user location.
 */
export async function getIpLocationViewport(): Promise<MapViewport | null> {
  const res = await fetchIpBasedLocation()
  if (res.success) {
    const { lng, lat } = res.data
    return {
      center: [lng, lat],
      zoom: 8,
      bearing: 0,
      pitch: 0,
    }
  }
  return null
}
