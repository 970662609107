import { useMemo } from 'react'
import { Feature } from 'geojson'
import { SymbolLayerSpecification } from 'maplibre-gl'
import { RouteStart } from '../types'
import { useMapImage } from '../use-map-image'
import { lngLatToPosition } from 'shared/util-geo'
import { ROUTE_MARKER_LAYER_LAYOUT } from '../settings'

import defaultMarkerImg1 from '../img/route-marker-default-1.png'
import defaultMarkerImg2 from '../img/route-marker-default-2.png'
import defaultMarkerImg3 from '../img/route-marker-default-3.png'
import defaultMarkerImg4 from '../img/route-marker-default-4.png'
import defaultMarkerImg5 from '../img/route-marker-default-5.png'
import defaultMarkerImg6 from '../img/route-marker-default-6.png'
import defaultMarkerImg7 from '../img/route-marker-default-7.png'
import defaultMarkerImg8 from '../img/route-marker-default-8.png'
import defaultMarkerImg9 from '../img/route-marker-default-9.png'
import defaultMarkerImg10 from '../img/route-marker-default-10.png'

import mtbMarkerImg1 from '../img/route-marker-mtb-1.png'
import mtbMarkerImg2 from '../img/route-marker-mtb-2.png'
import mtbMarkerImg3 from '../img/route-marker-mtb-3.png'
import mtbMarkerImg4 from '../img/route-marker-mtb-4.png'
import mtbMarkerImg5 from '../img/route-marker-mtb-5.png'
import mtbMarkerImg6 from '../img/route-marker-mtb-6.png'
import mtbMarkerImg7 from '../img/route-marker-mtb-7.png'
import mtbMarkerImg8 from '../img/route-marker-mtb-8.png'
import mtbMarkerImg9 from '../img/route-marker-mtb-9.png'
import mtbMarkerImg10 from '../img/route-marker-mtb-10.png'

import roadBikeMarkerImg1 from '../img/route-marker-road-bike-1.png'
import roadBikeMarkerImg2 from '../img/route-marker-road-bike-2.png'
import roadBikeMarkerImg3 from '../img/route-marker-road-bike-3.png'
import roadBikeMarkerImg4 from '../img/route-marker-road-bike-4.png'
import roadBikeMarkerImg5 from '../img/route-marker-road-bike-5.png'
import roadBikeMarkerImg6 from '../img/route-marker-road-bike-6.png'
import roadBikeMarkerImg7 from '../img/route-marker-road-bike-7.png'
import roadBikeMarkerImg8 from '../img/route-marker-road-bike-8.png'
import roadBikeMarkerImg9 from '../img/route-marker-road-bike-9.png'
import roadBikeMarkerImg10 from '../img/route-marker-road-bike-10.png'

type LayerProps = Omit<SymbolLayerSpecification, 'source'>

const IDENTIFIER = 'alternating-route-markers'

export const useAlternatingRouteMarkers = (
  id: string,
  routes: RouteStart[],
  highlightedRouteId: number | null,
): {
  features: Feature[]
  layerProps: LayerProps
} => {
  useMapImage(defaultMarkerImg1, 'route-marker-default-1')
  useMapImage(defaultMarkerImg2, 'route-marker-default-2')
  useMapImage(defaultMarkerImg3, 'route-marker-default-3')
  useMapImage(defaultMarkerImg4, 'route-marker-default-4')
  useMapImage(defaultMarkerImg5, 'route-marker-default-5')
  useMapImage(defaultMarkerImg6, 'route-marker-default-6')
  useMapImage(defaultMarkerImg7, 'route-marker-default-7')
  useMapImage(defaultMarkerImg8, 'route-marker-default-8')
  useMapImage(defaultMarkerImg9, 'route-marker-default-9')
  useMapImage(defaultMarkerImg10, 'route-marker-default-10')

  useMapImage(mtbMarkerImg1, 'route-marker-mtb-1')
  useMapImage(mtbMarkerImg2, 'route-marker-mtb-2')
  useMapImage(mtbMarkerImg3, 'route-marker-mtb-3')
  useMapImage(mtbMarkerImg4, 'route-marker-mtb-4')
  useMapImage(mtbMarkerImg5, 'route-marker-mtb-5')
  useMapImage(mtbMarkerImg6, 'route-marker-mtb-6')
  useMapImage(mtbMarkerImg7, 'route-marker-mtb-7')
  useMapImage(mtbMarkerImg8, 'route-marker-mtb-8')
  useMapImage(mtbMarkerImg9, 'route-marker-mtb-9')
  useMapImage(mtbMarkerImg10, 'route-marker-mtb-10')

  useMapImage(roadBikeMarkerImg1, 'route-marker-road-bike-1')
  useMapImage(roadBikeMarkerImg2, 'route-marker-road-bike-2')
  useMapImage(roadBikeMarkerImg3, 'route-marker-road-bike-3')
  useMapImage(roadBikeMarkerImg4, 'route-marker-road-bike-4')
  useMapImage(roadBikeMarkerImg5, 'route-marker-road-bike-5')
  useMapImage(roadBikeMarkerImg6, 'route-marker-road-bike-6')
  useMapImage(roadBikeMarkerImg7, 'route-marker-road-bike-7')
  useMapImage(roadBikeMarkerImg8, 'route-marker-road-bike-8')
  useMapImage(roadBikeMarkerImg9, 'route-marker-road-bike-9')
  useMapImage(roadBikeMarkerImg10, 'route-marker-road-bike-10')

  const features = useMemo<Feature[]>(
    () =>
      routes.map(
        ({ routeId, routeType, position }, i): Feature => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lngLatToPosition(position),
          },
          properties: {
            routeId,
            icon: `route-marker-${routeType}-${(i % 10) + 1}`,
            active: routeId === highlightedRouteId,
            segmentIndex: i,
          },
        }),
      ),
    [highlightedRouteId, routes],
  )
  const layerProps = useMemo<LayerProps>(
    () => ({
      id: `${id}-${IDENTIFIER}`,
      type: 'symbol',
      layout: ROUTE_MARKER_LAYER_LAYOUT,
    }),
    [id],
  )
  return { features, layerProps }
}
