import { ReactNode } from 'react'
import { useLayoutContext } from './layout-provider'

import styles from './web-app-main-actions.module.scss'

interface WebAppMainActionsProps {
  children: ReactNode
}

export const WebAppMainActions = ({ children }: WebAppMainActionsProps) => {
  const { mainActionsRef } = useLayoutContext()

  return (
    <div ref={mainActionsRef} className={styles['container']}>
      {children}
    </div>
  )
}
