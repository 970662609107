import { useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MAP_CENTER_KEY, MAP_ZOOM_KEY } from 'shared/util-discover'
import { useWebAppMap, viewportDesired } from 'web-app/feature-map'
import { useDispatch } from 'react-redux'

export const useDiscoverMapParams = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const map = useWebAppMap()

  const isInitialized = useRef<boolean | null>(false)

  const handleMapMoveEnd = useCallback(() => {
    if (!map) return
    const { lng, lat } = map.getCenter()
    searchParams.set(MAP_CENTER_KEY, `${lng},${lat}`)
    searchParams.set(MAP_ZOOM_KEY, map.getZoom().toString())
    setSearchParams(searchParams, { replace: true })
  }, [map, searchParams, setSearchParams])

  useEffect(() => {
    if (isInitialized.current) {
      map?.on('moveend', handleMapMoveEnd)
      return () => {
        map?.off('moveend', handleMapMoveEnd)
      }
    } else {
      const centerString = searchParams.get(MAP_CENTER_KEY)
      const center = centerString?.split(',').slice(0, 2).map(Number.parseFloat) as [number, number]
      const zoomString = searchParams.get(MAP_ZOOM_KEY)
      const zoom = zoomString && Number.parseFloat(zoomString)
      if (center && zoom) {
        const timeout = setTimeout(() => {
          dispatch(viewportDesired({ center, zoom }))
          isInitialized.current = true
        }, 50)
        return () => clearTimeout(timeout)
      }
      isInitialized.current = true
    }
    return () => {}
  }, [dispatch, handleMapMoveEnd, map, searchParams])
}
