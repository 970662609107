import { Typography } from '@mui/material'
import { SurfacesDiagram, WayTypesAndBikeNetwork } from 'web-app/feature-route'
import { useFinalGeometry, usePlannedRouteStats } from '../state'
import { useLocale } from 'shared/util-intl'

export const RoutePlannerDiagrams = () => {
  const { intl } = useLocale()
  const stats = usePlannedRouteStats()
  const finalGeometry = useFinalGeometry()

  return (
    stats &&
    finalGeometry && (
      <>
        <WayTypesAndBikeNetwork geometry={finalGeometry} routeDistanceMeters={stats.distanceMeters} />
        <Typography variant="h4" component="h2" marginTop="2rem">
          {intl.formatMessage({
            id: 'route_planner_surfaces_heading',
            defaultMessage: 'Surfaces',
          })}
        </Typography>
        <SurfacesDiagram geometry={finalGeometry} routeDistanceMeters={stats.distanceMeters} />
      </>
    )
  )
}
