import clsx from 'clsx'
import React from 'react'

import styles from './ButtonGroup.module.scss'

export interface ButtonGroupProps {
  children?: React.ReactNode
  stack?: boolean
}

export const ButtonGroup = ({ children, stack }: ButtonGroupProps) => (
  <div className={clsx(styles['root'], { [styles['stack']]: stack })}>{children}</div>
)

export default ButtonGroup
