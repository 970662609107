import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { RoutePrintState } from './types'
import { DEFAULT_VIEWPORT } from 'web-app/feature-map'
import { MapViewport } from 'shared/ui-map'
import { MAX_NUMBER_PRINT_IMAGES, ROUTE_PRINT_SLICE_KEY } from './settings'

const initialState: RoutePrintState = {
  forRouteId: undefined,
  overviewMapViewport: DEFAULT_VIEWPORT,
  overviewMapImage: null,
  mapAttributions: [],
  areDistanceMarkersShown: true,
  elevationCurveSvg: null,
  isDescriptionShown: false,
  detailedMapViewports: {},
  detailedMapImages: {},
  selectedImages: [],
  activePage: null,
}

const slice = createSlice({
  name: ROUTE_PRINT_SLICE_KEY,
  initialState,
  reducers: {
    initializedForRouteId(state, action: PayloadAction<number>) {
      return {
        ...(state.forRouteId && state.forRouteId !== action.payload ? initialState : state),
        forRouteId: action.payload,
      }
    },
    mapRendered(state, action: PayloadAction<{ image: string; viewport: MapViewport }>) {
      const { image, viewport } = action.payload
      state.overviewMapViewport = viewport
      state.overviewMapImage = image
    },
    mapAttributionsChanged(state, action: PayloadAction<string[]>) {
      state.mapAttributions = action.payload
    },
    distanceMarkersOptionChanged(state, action: PayloadAction<boolean>) {
      state.areDistanceMarkersShown = action.payload
    },
    elevationCurveRendered(state, action: PayloadAction<string>) {
      state.elevationCurveSvg = action.payload
    },
    descriptionOptionChanged(state, action: PayloadAction<boolean>) {
      state.isDescriptionShown = action.payload
      state.activePage = 1
    },
    detailedMapAdded(state) {
      const mapKey = Date.now().toString()
      state.detailedMapViewports = {
        ...state.detailedMapViewports,
        [mapKey]: state.overviewMapViewport,
      }
      state.detailedMapImages = {
        ...state.detailedMapImages,
        [mapKey]: null,
      }
      state.activePage = 1 + Math.ceil(state.selectedImages.length / 5) + Object.keys(state.detailedMapViewports).length
    },
    detailedMapRemoved(state, action: PayloadAction<{ mapKey: string }>) {
      delete state.detailedMapViewports[action.payload.mapKey]
      delete state.detailedMapImages[action.payload.mapKey]
      state.activePage = null
    },
    detailedMapRendered(state, action: PayloadAction<{ mapKey: string; image: string; viewport: MapViewport }>) {
      const { mapKey, image, viewport } = action.payload
      state.detailedMapViewports[mapKey] = viewport
      state.detailedMapImages[mapKey] = image
    },
    imageSelected(state, action: PayloadAction<{ imageIndex: number }>) {
      if (state.selectedImages.length < MAX_NUMBER_PRINT_IMAGES) {
        state.selectedImages.push(action.payload.imageIndex)
        state.activePage = 1 + Math.ceil(state.selectedImages.length / 5)
      }
    },
    imageRemoved(state, action: PayloadAction<{ imageIndex: number }>) {
      state.selectedImages = state.selectedImages.filter((imageIndex) => imageIndex !== action.payload.imageIndex)
      state.activePage = 1 + Math.ceil(state.selectedImages.length / 5)
    },
    reset() {
      return initialState
    },
  },
})

export const {
  initializedForRouteId,
  mapRendered,
  mapAttributionsChanged,
  distanceMarkersOptionChanged,
  elevationCurveRendered,
  descriptionOptionChanged,
  detailedMapAdded,
  detailedMapRemoved,
  detailedMapRendered,
  imageSelected,
  imageRemoved,
  reset,
} = slice.actions

const persistConfig: PersistConfig<RoutePrintState> = {
  key: ROUTE_PRINT_SLICE_KEY,
  storage,
  whitelist: [
    'forRouteId',
    'overviewMapViewport',
    'areDistanceMarkersShown',
    'isDescriptionShown',
    'detailedMapViewports',
    'selectedImages',
  ],
}

export const routePrintReducer = persistReducer(persistConfig, slice.reducer)
