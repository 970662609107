import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ASCENT_KEY, BIKE_TYPES_KEY, DISTANCE_KEY, ONLY_LOOPS_KEY, SURFACES_KEY, TITLE_KEY } from 'shared/util-discover'
import { SORTING_KEY } from './definitions'

export const useDiscoverParamsString = () => {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    const paramKeys = [SORTING_KEY, ONLY_LOOPS_KEY, DISTANCE_KEY, ASCENT_KEY, TITLE_KEY, BIKE_TYPES_KEY, SURFACES_KEY]
    const discoverParamsSearchParams = new URLSearchParams()
    searchParams.forEach((value, key) => {
      if (paramKeys.includes(key)) {
        discoverParamsSearchParams.set(key, value)
      }
    })
    return discoverParamsSearchParams.toString()
  }, [searchParams])
}
