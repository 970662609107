import { useMemo } from 'react'
import { Feature, FeatureCollection, Position } from 'geojson'
import { Layer, Source } from 'react-map-gl/maplibre'
import { getLetterFromViaPointIndex } from '../helpers'
import { useMapImage } from '../use-map-image'
import {
  ROUTE_DESTINATION_MARKER_LAYER_ID,
  ROUTE_GEOMETRY_SOURCE_ID,
  ROUTE_ORIGIN_MARKER_LAYER_ID,
  ROUTE_VIA_MARKER_LAYER_ID,
} from '../settings'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { LngLat, RichLineString, RichMultiLineString, Waypoints, castToLineString, isWaypoint } from 'shared/util-geo'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { useControlPointRelations } from './use-control-point-relations'
import { useRouteLines } from './use-route-lines'
import { useInactiveRouteLines } from './use-inactive-route-lines'
import { DESTINATION_MARKER_PROPS, ORIGIN_MARKER_PROPS, VIA_MARKER_PROPS } from './helpers'

import originMarkerImg from '../img/route-marker-origin.png'
import locationMarkerImg from '../img/location-marker.png'
import destinationMarkerImg from '../img/route-marker-destination.png'

interface MapRouteProps {
  id: string
  geometry: RichLineString
  inactiveGeometry?: RichLineString | RichMultiLineString
  waypoints: Waypoints | [LngLat, LngLat, ...LngLat[]]
  controlPointIndexes?: [number, number, ...number[]]
}

/**
 * Default representation of a route on the map.
 */
export const MapRoute = ({ id, geometry, inactiveGeometry, waypoints, controlPointIndexes }: MapRouteProps) => {
  useMapImage(originMarkerImg, 'route-marker-origin')
  useMapImage(locationMarkerImg, 'location-marker')
  useMapImage(destinationMarkerImg, 'route-marker-destination')

  const controlPointRelationLines = useMemo<[Position, Position][]>(
    () =>
      waypoints.reduce(
        (relations, waypoint, i) => {
          if (isWaypoint(waypoint) && geometry?.coordinates.length) {
            const { lng, lat } = waypoint
            const controlPoint =
              i === 0 // origin
                ? geometry.coordinates[0]
                : i === waypoints.length - 1 // destination
                  ? geometry.coordinates[geometry.coordinates.length - 1]
                  : // via
                    controlPointIndexes
                    ? geometry.coordinates[controlPointIndexes[waypoint.controlPointIndex]]
                    : nearestPointOnLine(castToLineString(geometry), [lng, lat]).geometry.coordinates

            if (controlPoint && !(controlPoint[0] === lng && controlPoint[1] === lat)) {
              relations.push([[lng, lat], controlPoint as Position])
            }
          }
          return relations
        },
        [] as [Position, Position][],
      ),
    [controlPointIndexes, geometry, waypoints],
  )

  const routeLayerBeforeId = useRouteLayerBeforeId()
  const routeLines = useRouteLines(id, [geometry.coordinates as Position[]])
  const inactiveRouteLines = useInactiveRouteLines(
    id,
    (inactiveGeometry
      ? inactiveGeometry.type === 'RichMultiLineString'
        ? inactiveGeometry.coordinates
        : [inactiveGeometry.coordinates]
      : []) as Position[][],
  )
  const controlPointRelations = useControlPointRelations(id, controlPointRelationLines)

  const sourceData = useMemo<FeatureCollection>(() => {
    const features: Feature[] = [
      ...routeLines.features,
      ...inactiveRouteLines.features,
      ...controlPointRelations.features,
    ]

    waypoints.forEach((waypoint, i) => {
      const { lng, lat } = waypoint

      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
        properties:
          i === 0
            ? {
                type: 'origin',
              }
            : i === waypoints.length - 1
              ? {
                  type: 'destination',
                }
              : {
                  type: 'via',
                  label: getLetterFromViaPointIndex(i - 1),
                },
      })
    })

    return {
      type: 'FeatureCollection',
      features,
    }
  }, [controlPointRelations.features, inactiveRouteLines.features, routeLines.features, waypoints])

  const sourceId = useMemo<string>(() => ROUTE_GEOMETRY_SOURCE_ID.replace('{baseId}', id), [id])

  return (
    <Source id={sourceId} type="geojson" data={sourceData}>
      <Layer {...controlPointRelations.layerProps} beforeId={routeLayerBeforeId} />
      <Layer {...inactiveRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...inactiveRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...ORIGIN_MARKER_PROPS} id={ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', id)} />
      <Layer {...VIA_MARKER_PROPS} id={ROUTE_VIA_MARKER_LAYER_ID.replace('{baseId}', id)} />
      <Layer {...DESTINATION_MARKER_PROPS} id={ROUTE_ORIGIN_MARKER_LAYER_ID.replace('{baseId}', id)} />
    </Source>
  )
}

export default MapRoute
