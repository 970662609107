import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CreateRouteData,
  ChangeRouteData,
  RouteChangeForm,
  RouteForm,
  RouteGeometryChangeForm,
  assignRouteToCollection,
  addRouteToFavorites,
  changeRoute,
  createRoute,
  unassignRouteFromCollection,
  removeRouteFromFavorites,
  RouteCollectionAssignmentData,
  RouteDetailsData,
  fetchRouteDetails,
} from 'shared/data-access-core'
import { ROUTE_SLICE_KEY, RouteSliceDispatch, StateWithRouteSlice } from './types'
import { pushRouteDataEvent } from 'shared/util-analytics'
import { routeSelector, routeSliceSelector } from './selectors'
import { routeAddedToFavorites, routeRemovedFromFavorites } from './state'
import { StateWithUserSlice, USER_SLICE_KEY, favoriteRoutesCountUpdated } from 'web-app/feature-user'
import { routeAddedToCollection, routeRemovedFromCollection } from 'web-app/data-access-entities'

/**
 * Save a new route to the server and get the result as global state.
 */
export const saveRoute = createAsyncThunk<
  CreateRouteData,
  RouteForm,
  { dispatch: RouteSliceDispatch; state: StateWithRouteSlice }
>(ROUTE_SLICE_KEY + '/save', async (form) => {
  const res = await createRoute(form)
  if (res.success) return res.data
  throw Error('Failed to save route')
})

/**
 * Fetch a route from the server and get the result as global state.
 */
export const fetchRoute = createAsyncThunk<
  RouteDetailsData,
  number,
  { dispatch: RouteSliceDispatch; state: StateWithRouteSlice }
>(ROUTE_SLICE_KEY + '/fetch', async (routeId) => {
  const res = await fetchRouteDetails(routeId)
  if (res.success) return res.data
  throw Error('Failed to fetch route')
})

export function initRouteById(routeId: number) {
  return async (dispatch: RouteSliceDispatch) => {
    try {
      const { route } = await dispatch(fetchRoute(routeId)).unwrap()
      if (route.externalId) {
        pushRouteDataEvent(route.externalId)
      }
    } catch {
      // do nothing
    }
  }
}

/**
 * Save patch changes for the current main route to the server and get the result as global state.
 */
export const saveRouteChanges = createAsyncThunk<
  ChangeRouteData,
  { form?: RouteChangeForm; geometryForm?: RouteGeometryChangeForm },
  { dispatch: RouteSliceDispatch; state: StateWithRouteSlice }
>(ROUTE_SLICE_KEY + '/saveChanges', async ({ form, geometryForm }, { getState }) => {
  const { route } = getState()[ROUTE_SLICE_KEY]
  if (route) {
    const res = await changeRoute(route.id, form || {}, geometryForm)
    if (res.success) return res.data
  }
  throw Error('Failed to save route changes')
})

/**
 * Add the current main route to a collection on the server and update global state.
 */
export const assignCollection = createAsyncThunk<
  RouteCollectionAssignmentData,
  number,
  { dispatch: RouteSliceDispatch; state: StateWithRouteSlice }
>(ROUTE_SLICE_KEY + '/assignCollection', async (routeCollectionId, { dispatch, getState }) => {
  const { route } = getState()[ROUTE_SLICE_KEY]
  if (route) {
    const res = await assignRouteToCollection(route.id, routeCollectionId)
    if (res.success) {
      dispatch(routeAddedToCollection(routeCollectionId))
      return res.data
    }
  }
  throw Error('Failed to assign collection')
})

/**
 * Remove the current main route from a collection on the server and update global state.
 */
export const unassignCollection = createAsyncThunk<
  RouteCollectionAssignmentData,
  number,
  { dispatch: RouteSliceDispatch; state: StateWithRouteSlice }
>(ROUTE_SLICE_KEY + '/unassignCollection', async (routeCollectionId, { dispatch, getState }) => {
  const { route } = getState()[ROUTE_SLICE_KEY]
  if (route) {
    const res = await unassignRouteFromCollection(route.id, routeCollectionId)
    if (res.success) {
      dispatch(routeRemovedFromCollection(routeCollectionId))
      return res.data
    }
  }
  throw Error('Failed to unassign collection')
})

/**
 * Create a new route just like the current one from global state.
 */
export function copyRoute(changes?: RouteGeometryChangeForm) {
  return async (dispatch: RouteSliceDispatch, getState: () => StateWithRouteSlice) => {
    const route = routeSliceSelector(getState()).route

    if (!route) return

    const form: RouteForm = {
      title: route.title,
      bikeTypes: route.bikeTypes,
      geometry: route.geometry,
      distanceMeters: route.distanceMeters,
      waypoints: route.waypoints,
      controlPointIndexes: route.controlPointIndexes,
      appVersion: 'Copy',
      isPrivate: true,
      copiedFrom: route.id,
    }
    if (route.description) {
      form.description = route.description
    }
    if (route.durationSeconds) {
      form.durationSeconds = route.durationSeconds
    }
    if (changes) {
      Object.assign(form, changes)
    }
    return dispatch(saveRoute(form)).unwrap()
  }
}

export function toggleGlobalFavoriteRoute() {
  return async (dispatch: RouteSliceDispatch, getState: () => StateWithRouteSlice & StateWithUserSlice) => {
    const state = getState()
    const route = routeSelector(state)
    const { user } = state[USER_SLICE_KEY]
    if (!route || !user) return
    if (route.isFavorite) {
      dispatch(routeRemovedFromFavorites()) // Optimistic as we don't get a real favorite count back from the API anyway
      const result = await removeRouteFromFavorites(route.id)
      if (result.success) {
        dispatch(favoriteRoutesCountUpdated(user.favoriteRoutesCount - 1))
      } else {
        dispatch(routeAddedToFavorites())
      }
    } else {
      dispatch(routeAddedToFavorites()) // Optimistic as we don't get a real favorite count back from the API anyway
      const result = await addRouteToFavorites(route.id)
      if (result.success) {
        dispatch(favoriteRoutesCountUpdated(user.favoriteRoutesCount + 1))
      } else {
        dispatch(routeRemovedFromFavorites())
      }
    }
  }
}
