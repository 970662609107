import { useLocale } from 'shared/util-intl'
import { BikemapLogo, Link } from 'shared/ui-components'
import { useInternalLink } from './use-internal-link'
import { WebAppHeader, WebAppHeaderItems } from 'web-app/ui-layout'
import { Navigation } from './navigation'

export const DefaultHeader = () => {
  const getInternalLinkProps = useInternalLink()
  const { intl } = useLocale()

  return (
    <WebAppHeader>
      <WebAppHeaderItems>
        <Link
          marginTop="0.25rem"
          {...getInternalLinkProps('/')}
          aria-label={intl.formatMessage({
            id: 'default_header_logo_link_label',
            defaultMessage: 'Bikemap home',
          })}
        >
          <BikemapLogo />
        </Link>
      </WebAppHeaderItems>
      <Navigation />
    </WebAppHeader>
  )
}
