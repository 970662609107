import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { LngLat } from 'shared/ui-map'
import { StateWithEntitiesSlice } from 'web-app/data-access-entities'
import { StateWithRouteSlice } from 'web-app/feature-route'
import { StateWithUserSlice } from 'web-app/feature-user'

export const ROUTE_DETAILS_SLICE_KEY = 'routeDetails'

export type RouteDetailsState = {
  selectedLocation: LngLat | null
}

export interface StateWithRouteDetailsSlice {
  [ROUTE_DETAILS_SLICE_KEY]: RouteDetailsState
}

export type RouteDetailsSliceDispatch = ThunkDispatch<
  StateWithRouteDetailsSlice & StateWithRouteSlice & StateWithUserSlice & StateWithEntitiesSlice,
  undefined,
  UnknownAction
>
