import { ChangeEvent } from 'react'
import { Input, TextAreaProps } from 'shared/ui-components'
import { EditableContent, EditableContentProps } from './editable-content'
import { CollapsedText } from 'web-app/ui-layout'
import { Skeleton, Typography } from '@mui/material'
import { useValidation } from './use-validation'

interface TextContentProps {
  value: string
  emptyPlaceholder: string
  collapsedLines?: number
}

const TextContent = ({ value, emptyPlaceholder, collapsedLines }: TextContentProps) =>
  value ? (
    <CollapsedText collapsedLines={collapsedLines}>{value}</CollapsedText>
  ) : (
    <Typography paragraph color="textSecondary">
      {emptyPlaceholder}
    </Typography>
  )

interface TextAreaFormProps extends Omit<TextAreaProps, 'onChange'> {
  label: string
  placeholder?: string
  isRequired?: boolean
  onChange: (value: string, isValid: boolean) => void
  onValidation?: (value: string) => string | null
}

const TextAreaForm = ({ label, placeholder, isRequired, onChange, onValidation, ...inputProps }: TextAreaFormProps) => {
  const { error, validate } = useValidation(label, onValidation, { isRequired })

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    onChange(value, validate(value))
  }

  return (
    <Input
      {...inputProps}
      textarea
      resize
      hideLabel
      label={label}
      placeholder={placeholder}
      error={error || undefined}
      onChange={handleChange}
    />
  )
}

interface EditableTextProps
  extends Omit<EditableContentProps<string>, 'renderContent' | 'renderLoading' | 'renderForm'> {
  heading: string
  emptyPlaceholder: string
  label: string
  placeholder?: string
  isRequired?: boolean
  collapsedLines?: number
  onValidation?: (value: string) => string | null
}

export const EditableText = ({
  emptyPlaceholder,
  label,
  placeholder,
  isRequired,
  collapsedLines,
  onValidation,
  ...props
}: EditableTextProps) => (
  <EditableContent
    {...props}
    renderContent={({ value }) => (
      <TextContent value={value} emptyPlaceholder={emptyPlaceholder} collapsedLines={collapsedLines} />
    )}
    renderLoading={() => (
      <Typography paragraph color="textSecondary">
        {Array.from({ length: collapsedLines && collapsedLines < 4 ? collapsedLines : 4 }).map((_, index) => (
          <Skeleton key={index} />
        ))}
        <Skeleton width="50%" />
      </Typography>
    )}
    renderForm={(props) => (
      <TextAreaForm
        {...props}
        label={label}
        placeholder={placeholder}
        isRequired={isRequired}
        onValidation={onValidation}
      />
    )}
  />
)
