import { Position } from 'geojson'
import { Position3d } from './types'

type Path2dOr3d = Array<Position>

/**
 * Based on:
 * @link https://github.com/graphhopper/graphhopper/blob/d70b63660ac5200b03c38ba3406b8f93976628a6/web/src/main/webapp/js/ghrequest.js#L139
 * As documented here:
 * @link https://github.com/graphhopper/graphhopper/blob/6.2/docs/web/api-doc.md
 */
export function decodePath(encoded: string, is3D: boolean): Path2dOr3d {
  const len = encoded.length
  let index = 0
  const array: Path2dOr3d = []
  let lat = 0
  let lng = 0
  let ele = 0

  while (index < len) {
    let b
    let shift = 0
    let result = 0
    do {
      b = encoded.charCodeAt(index++) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)
    const deltaLat = result & 1 ? ~(result >> 1) : result >> 1
    lat += deltaLat

    shift = 0
    result = 0
    do {
      b = encoded.charCodeAt(index++) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)
    const deltaLon = result & 1 ? ~(result >> 1) : result >> 1
    lng += deltaLon

    const finalLng = lng / 100000
    const finalLat = lat / 100000

    if (is3D) {
      // elevation
      shift = 0
      result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      const deltaEle = result & 1 ? ~(result >> 1) : result >> 1
      ele += deltaEle
      array.push([finalLng, finalLat, ele / 100])
    } else array.push([finalLng, finalLat])
  }
  return array
}

export function decodePath2d(encoded: string) {
  return decodePath(encoded, false) as unknown as [number, number][]
}

export function decodePath3d(encoded: string) {
  return decodePath(encoded, true) as unknown as Position3d[]
}
