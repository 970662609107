import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { useDispatch } from 'react-redux'
import { RouteDetailsSliceDispatch } from '../state'
import { copyRoute } from 'web-app/feature-route'
import { useLocale } from 'shared/util-intl'
import { useAuthenticationWall, useNavigateToRouteDetails } from 'web-app/feature-navigation'

export const useCopyRoute = () => {
  const { intl } = useLocale()
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const { doForAuthenticatedUser } = useAuthenticationWall()
  const showCommonErrorNotification = useCommonErrorNotification()

  const handleCopy = () => {
    doForAuthenticatedUser(async () => {
      try {
        const data = await dispatch(copyRoute())
        if (data) {
          return navigateToRouteDetails(data.route.id)
        }
      } catch {
        // Show error notification anyway
      }
      showCommonErrorNotification(
        intl.formatMessage({
          id: 'route_details_copy_route_error',
          defaultMessage: 'The route could not be copied.',
        }),
      )
    })
  }

  return { handleCopy }
}
