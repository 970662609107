import { RangeSlider } from 'shared/ui-components'
import { FILTERS_CHANGE_DEBOUNCE, MAX_DISTANCE_FILTER } from 'shared/util-discover'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useLocale } from 'shared/util-intl'
import { useRangeScaleConversion } from './use-range-scale-conversion'
import { UnitPreference, useUserState } from 'web-app/feature-user'
import { kilometersToMiles, milesToKilometers } from 'shared/util-formatting'
import { FilterRange } from 'shared/data-access-core'
import { useDiscoverFilters } from '../use-discover-filters'

const NORMAL_SCALE_MAX = 100
const INCREASED_SCALE_MAX = 150

export const DistanceField = () => {
  const { intl } = useLocale()
  const { unitPreference } = useUserState()
  const [filters, setFilters] = useDiscoverFilters()

  const [value, setValue] = useState<[number, number]>(getValueFromParam(filters.distanceKilometers, unitPreference))

  useEffect(
    () => setValue(getValueFromParam(filters.distanceKilometers, unitPreference)),
    [filters.distanceKilometers, unitPreference],
  )

  const { convertValueToScale, convertScaleToValue } = useRangeScaleConversion(
    NORMAL_SCALE_MAX,
    INCREASED_SCALE_MAX,
    MAX_DISTANCE_FILTER,
  )

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        ([min, max]: [number, number]) =>
          setFilters({
            distanceKilometers: [
              unitPreference === 'imperial' ? milesToKilometers(min) : min,
              max < MAX_DISTANCE_FILTER ? (unitPreference === 'imperial' ? milesToKilometers(max) : max) : null,
            ],
          }),
        FILTERS_CHANGE_DEBOUNCE,
      ),
    [setFilters, unitPreference],
  )

  const handleChange = (scale: [number, number]) => {
    const value = scale.map(convertScaleToValue) as [number, number]
    setValue(value)
    debouncedChangeHandler(value)
  }

  const unit = unitPreference === 'imperial' ? 'mi' : 'km'

  return (
    <RangeSlider
      name="discover-distance"
      label={intl.formatMessage({
        id: 'discover_filter_distance_label',
        defaultMessage: 'Length',
      })}
      max={INCREASED_SCALE_MAX}
      value={value.map(convertValueToScale) as [number, number]}
      markers={[
        { value: 0, label: `0 ${unit}` },
        { value: 50, label: `50 ${unit}` },
        { value: 100, label: `100 ${unit}` },
        { value: INCREASED_SCALE_MAX, label: `500+ ${unit}` },
      ]}
      onChange={handleChange}
      renderCurrentValue={([min, max]) => {
        const minLabel = `${convertScaleToValue(min)} ${unit}`
        const maxLabel = `${max < INCREASED_SCALE_MAX ? convertScaleToValue(max) : `${MAX_DISTANCE_FILTER}+`} ${unit}`
        return `${minLabel} - ${maxLabel}`
      }}
    />
  )
}

function getValueFromParam(distanceKilometers: FilterRange, unitPreference: UnitPreference): [number, number] {
  const [min, max] = distanceKilometers
  return [
    unitPreference === 'imperial' ? Math.round(kilometersToMiles(min)) : min,
    max ? (unitPreference === 'imperial' ? Math.round(kilometersToMiles(max)) : max) : MAX_DISTANCE_FILTER,
  ]
}
