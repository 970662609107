import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getBoundsFromGeometry } from 'shared/ui-map'
import { RichLineString } from 'shared/util-geo'
import { boundsDesired } from 'web-app/feature-map'

/**
 * Local state for fitting map bounds to a given geometry (if available) only once on initialization. Pass
 * the returned props to a map component.
 */
export const useInitialFitGeometry = (geometry: RichLineString | null) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const bounds = geometry && getBoundsFromGeometry(geometry)
    if (bounds) {
      dispatch(boundsDesired(bounds))
    }
  }, [dispatch, geometry])
}
