import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  ASCENT_KEY,
  BIKE_TYPES_KEY,
  DEFAULT_ASCENT,
  DEFAULT_BIKE_TYPES,
  DEFAULT_DISTANCE,
  DEFAULT_ONLY_LOOPS,
  DEFAULT_SURFACES,
  DEFAULT_TITLE,
  DISTANCE_KEY,
  DiscoverFilters,
  ONLY_LOOPS_KEY,
  SURFACES_KEY,
  TITLE_KEY,
} from 'shared/util-discover'
import { FilterRange, RouteBikeType, RouteSurface } from 'shared/data-access-core'
import { isEqual } from 'lodash'

type DiscoverFiltersUpdate = {
  onlyLoops?: boolean
  distanceKilometers?: FilterRange
  ascentMeters?: FilterRange
  title?: string
  bikeTypes?: RouteBikeType[]
  surfaces?: RouteSurface[]
}

export const useDiscoverFilters = (): [
  filters: DiscoverFilters,
  setFilters: (update: DiscoverFiltersUpdate) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams()

  const distanceKilometers = useMemo<FilterRange>(() => {
    const value = searchParams.get(DISTANCE_KEY)
    if (!value) return DEFAULT_DISTANCE
    const splitValues = value.split(',')
    const min = Number.parseInt(splitValues[0]) || 0
    const max = Number.parseInt(splitValues[1])
    return [min, max && max > min ? max : null]
  }, [searchParams])

  const ascentMeters = useMemo<FilterRange>(() => {
    const value = searchParams.get(ASCENT_KEY)
    if (!value) return DEFAULT_ASCENT
    const splitValues = value.split(',')
    const min = Number.parseInt(splitValues[0]) || 0
    const max = Number.parseInt(splitValues[1])
    return [min, max && max > min ? max : null]
  }, [searchParams])

  const bikeTypes = useMemo<RouteBikeType[]>(() => {
    const value = searchParams.get(BIKE_TYPES_KEY)?.split(',')
    if (!value) return []
    const bikeTypes: RouteBikeType[] = []
    for (const item of value) {
      const intItem = Number.parseInt(item)
      if (intItem && intItem >= 1 && intItem <= 3 && !bikeTypes.includes(intItem as RouteBikeType)) {
        bikeTypes.push(intItem as 1 | 2 | 3)
        if (bikeTypes.length === 3) break
      }
    }
    return bikeTypes
  }, [searchParams])

  const surfaces = useMemo<RouteSurface[]>(() => {
    const value = searchParams.get(SURFACES_KEY)?.split(',')
    if (!value) return []
    const surfaces: RouteSurface[] = []
    for (const item of value) {
      const intItem = Number.parseInt(item)
      if (intItem && intItem >= 1 && intItem <= 4 && !surfaces.includes(intItem as RouteSurface)) {
        surfaces.push(intItem as RouteSurface)
        if (surfaces.length === 4) break
      }
    }
    return surfaces
  }, [searchParams])

  const filters = useMemo<DiscoverFilters>(
    () => ({
      onlyLoops: searchParams.has(ONLY_LOOPS_KEY),
      distanceKilometers,
      ascentMeters,
      title: searchParams.get(TITLE_KEY) || DEFAULT_TITLE,
      bikeTypes,
      surfaces,
    }),
    [ascentMeters, bikeTypes, distanceKilometers, searchParams, surfaces],
  )

  const setFilters = useCallback(
    (update: DiscoverFiltersUpdate) => {
      const { onlyLoops, distanceKilometers, ascentMeters, title, bikeTypes, surfaces } = update

      if (onlyLoops === DEFAULT_ONLY_LOOPS) {
        searchParams.delete(ONLY_LOOPS_KEY)
      } else if (onlyLoops) {
        searchParams.set(ONLY_LOOPS_KEY, '1')
      }

      if (isEqual(distanceKilometers, DEFAULT_DISTANCE)) {
        searchParams.delete(DISTANCE_KEY)
      } else if (distanceKilometers) {
        searchParams.set(
          DISTANCE_KEY,
          distanceKilometers[1] ? distanceKilometers.join(',') : distanceKilometers[0].toString(),
        )
      }

      if (isEqual(ascentMeters, DEFAULT_ASCENT)) {
        searchParams.delete(ASCENT_KEY)
      } else if (ascentMeters) {
        searchParams.set(ASCENT_KEY, ascentMeters[1] ? ascentMeters.join(',') : ascentMeters[0].toString())
      }

      if (title === DEFAULT_TITLE) {
        searchParams.delete(TITLE_KEY)
      } else if (title) {
        searchParams.set(TITLE_KEY, title)
      }

      if (isEqual(bikeTypes, DEFAULT_BIKE_TYPES)) {
        searchParams.delete(BIKE_TYPES_KEY)
      } else if (bikeTypes) {
        searchParams.set(BIKE_TYPES_KEY, bikeTypes.join(','))
      }

      if (isEqual(surfaces, DEFAULT_SURFACES)) {
        searchParams.delete(SURFACES_KEY)
      } else if (surfaces) {
        searchParams.set(SURFACES_KEY, surfaces.join(','))
      }

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  return [filters, setFilters]
}
