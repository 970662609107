import { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './web-app-top-control.module.scss'
import additionalContentStyles from './web-app-additional-content.module.scss'

export interface WebAppTopControlProps {
  children: ReactNode
}

/**
 * Control in top area of the media, such as a main action for the map.
 */
export const WebAppTopControl = ({ children }: WebAppTopControlProps) => (
  <div className={clsx(styles['container'], additionalContentStyles['hidden-if-open'])}>{children}</div>
)
