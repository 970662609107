import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import {
  BASE_SIZE_PX,
  HEADER_HEIGHT_REM,
  SHEET_DEFAULT_FOLD_REM,
  SHEET_HANDLE_HEIGHT_REM,
  SHEET_MINIMAL_FOLD_REM,
} from './definitions'
import { useOnDebouncedWindowResize } from './use-on-debounced-window-resize'
import { InitialFold, LayoutProvider, useLayoutContext } from './layout-provider'
import { useBreakpoints } from './use-breakpoints'
import { useMediaParallax } from './use-media-parallax'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'

import styles from './web-app-layout.module.scss'

export interface WebAppLayoutProps {
  children: ReactNode
  initialFold?: InitialFold
  parallax?: boolean
}

const WebAppLayoutInner = ({ children, parallax = true }: WebAppLayoutProps) => {
  const location = useLocation()
  const { scrollContainerRef, snapScrollContainerRef, mainActionsRef, scrollToInitialFold } = useLayoutContext()
  const { layoutBreakpoint } = useBreakpoints()

  const fullScreenMapSpacerRef = useRef<HTMLDivElement>(null)

  const [fullSpacingHeightPx, setFullSpacingHeightPx] = useState<number>(0)
  const [isSnapScrolling, setIsSnapScrolling] = useState<boolean>(!layoutBreakpoint)

  const { mediaOffset, handleParallaxOnScroll } = useMediaParallax(fullSpacingHeightPx, parallax)

  useEffect(() => {
    if (!layoutBreakpoint) {
      scrollToInitialFold()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname, // Scroll to initial fold after every view change
  ])

  const scrollContainerHeightPx = window.innerHeight - HEADER_HEIGHT_REM * BASE_SIZE_PX
  const defaultSpacingHeightPx = scrollContainerHeightPx - SHEET_DEFAULT_FOLD_REM * BASE_SIZE_PX
  const sheetHandleHeightPx = SHEET_HANDLE_HEIGHT_REM * BASE_SIZE_PX

  useOnDebouncedWindowResize(
    useCallback(() => {
      const fullScreenSpacer = fullScreenMapSpacerRef.current
      if (fullScreenSpacer) {
        const fullSpacingHeightPx = fullScreenSpacer.clientHeight + defaultSpacingHeightPx
        setFullSpacingHeightPx(fullSpacingHeightPx)
        if (!layoutBreakpoint && scrollContainerRef.current) {
          setIsSnapScrolling(scrollContainerRef.current.scrollTop < fullSpacingHeightPx)
        }
      }
    }, [defaultSpacingHeightPx, layoutBreakpoint, scrollContainerRef]),
  )

  const handleSnapScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (handleParallaxOnScroll) {
        handleParallaxOnScroll(e)
      }
      if (fullSpacingHeightPx && snapScrollContainerRef.current) {
        setIsSnapScrolling(snapScrollContainerRef.current.scrollTop < fullSpacingHeightPx - 1)
      }
    },
    [fullSpacingHeightPx, handleParallaxOnScroll, snapScrollContainerRef],
  )

  return (
    <main
      className={styles['main']}
      style={
        {
          '--web-app-header-height': HEADER_HEIGHT_REM + 'rem',
          '--web-app-sheet-minimal-fold': SHEET_MINIMAL_FOLD_REM + 'rem',
          '--web-app-sheet-default-fold': SHEET_DEFAULT_FOLD_REM + 'rem',
          '--web-app-default-spacing-height': defaultSpacingHeightPx + 'px',
          '--web-app-container-height-sm': scrollContainerHeightPx + sheetHandleHeightPx + 'px',
          '--web-app-media-offset': mediaOffset,
          '--web-app-main-actions-height': (mainActionsRef.current?.clientHeight || 0) + 'px',
          '--web-app-sheet-handle-height': SHEET_HANDLE_HEIGHT_REM + 'rem',
          '--web-app-sticky-position': isSnapScrolling ? 'fixed' : 'sticky',
        } as CSSProperties
      }
    >
      <div
        ref={snapScrollContainerRef}
        className={styles['snapping-container']}
        onScroll={layoutBreakpoint ? undefined : handleSnapScroll}
      >
        <div ref={fullScreenMapSpacerRef} className={styles['full-screen-map-spacer']} />
        <div className={styles['default-map-spacer']} />
        <div
          ref={!layoutBreakpoint ? scrollContainerRef : undefined}
          className={clsx(styles['container'], { [styles['scrolling']]: !isSnapScrolling })}
        >
          {children}
        </div>
      </div>
    </main>
  )
}

export const WebAppLayout = ({ initialFold = 'default', ...props }: WebAppLayoutProps) => (
  <LayoutProvider initialFold={initialFold}>
    <WebAppLayoutInner {...props} />
  </LayoutProvider>
)
