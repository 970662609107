import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CSSProperties, ReactNode } from 'react'

import styles from './alert.module.css'

export interface AlertProps {
  children: ReactNode
  style?: CSSProperties
}

export const Alert = ({ children, style }: AlertProps) => (
  <div className={styles['container']} style={style}>
    <InfoOutlinedIcon className={styles['icon']} />
    <span>{children}</span>
  </div>
)
