import { FilterRange, RouteBikeType, RouteSurface } from 'shared/data-access-core'

export const FILTERS_CHANGE_DEBOUNCE = 500

export const ONLY_LOOPS_KEY = 'only_loops'
export const DEFAULT_ONLY_LOOPS = false

export const DISTANCE_KEY = 'distance'
export const DEFAULT_DISTANCE: FilterRange = [0, null]
export const MAX_DISTANCE_FILTER = 500

export const ASCENT_KEY = 'ascent'
export const DEFAULT_ASCENT: FilterRange = [0, null]
export const MAX_ASCENT_FILTER = 3000

export const TITLE_KEY = 'title'
export const DEFAULT_TITLE = ''

export const BIKE_TYPES_KEY = 'bike_types'
export const DEFAULT_BIKE_TYPES = []

export const SURFACES_KEY = 'surfaces'
export const DEFAULT_SURFACES = []

export const MAP_CENTER_KEY = 'center'
export const MAP_ZOOM_KEY = 'zoom'

export type DiscoverFilters = {
  onlyLoops: boolean
  distanceKilometers: FilterRange
  ascentMeters: FilterRange
  title: string
  bikeTypes: RouteBikeType[]
  surfaces: RouteSurface[]
}

export const DEFAULT_FILTERS: DiscoverFilters = {
  onlyLoops: DEFAULT_ONLY_LOOPS,
  distanceKilometers: DEFAULT_DISTANCE,
  ascentMeters: DEFAULT_ASCENT,
  title: DEFAULT_TITLE,
  bikeTypes: DEFAULT_BIKE_TYPES,
  surfaces: DEFAULT_SURFACES,
}

export const PRESET_ROUND_TRIPS: DiscoverFilters = {
  ...DEFAULT_FILTERS,
  onlyLoops: true,
}
export const PRESET_PARAMS_ROUND_TRIPS = `${ONLY_LOOPS_KEY}=1`

export const PRESET_RELAXED: DiscoverFilters = {
  ...DEFAULT_FILTERS,
  surfaces: [RouteSurface.Paved, RouteSurface.Gravel],
  ascentMeters: [0, 200],
}
export const PRESET_PARAMS_RELAXED = `${SURFACES_KEY}=${RouteSurface.Paved},${RouteSurface.Gravel}&${ASCENT_KEY}=0,200`

export const PRESET_GRAVEL: DiscoverFilters = {
  ...DEFAULT_FILTERS,
  surfaces: [RouteSurface.Gravel],
  distanceKilometers: [30, 150],
}
export const PRESET_PARAMS_GRAVEL = `${SURFACES_KEY}=${RouteSurface.Gravel}&${DISTANCE_KEY}=30,150`

export const PRESET_ROAD: DiscoverFilters = {
  ...DEFAULT_FILTERS,
  surfaces: [RouteSurface.Paved],
  distanceKilometers: [30, 150],
}
export const PRESET_PARAMS_ROAD = `${SURFACES_KEY}=${RouteSurface.Paved}&${DISTANCE_KEY}=30,150`

export const PRESET_MTB: DiscoverFilters = {
  ...DEFAULT_FILTERS,
  surfaces: [RouteSurface.Gravel, RouteSurface.Ground],
  distanceKilometers: [10, 75],
  ascentMeters: [400, MAX_ASCENT_FILTER],
}
export const PRESET_PARAMS_MTB = `
${SURFACES_KEY}=${RouteSurface.Gravel},${RouteSurface.Ground}&${DISTANCE_KEY}=10,75&${ASCENT_KEY}=400,${MAX_ASCENT_FILTER}
`

export const PRESET_TREKKING: DiscoverFilters = {
  ...DEFAULT_FILTERS,
  distanceKilometers: [200, MAX_DISTANCE_FILTER],
}
export const PRESET_PARAMS_TREKKING = `${DISTANCE_KEY}=200,${MAX_DISTANCE_FILTER}`
