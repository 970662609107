import { RequestParams } from './types'

/**
 * Replace variable parts of URL (e.g. /path/with/:id/).
 */
export function replaceParams(url: string, params?: RequestParams): string {
  for (const key in params) {
    url = url.replace(':' + key, String(params[key]))
  }
  return url
}

/**
 * Map an object to URL query parameters.
 */
export function serializeParams(url: string, params?: RequestParams): string {
  const separateQueryParams = []

  for (const key in params) {
    const value = params[key]

    // Convert different types to string
    if (Array.isArray(value)) {
      // For arrays, add the key multiple times
      value.forEach((item) => separateQueryParams.push(key + '=' + encodeURIComponent(item)))
    } else if (typeof value === 'boolean') {
      // Convert booleans to 0/1
      separateQueryParams.push(key + '=' + (value ? 1 : 0))
    } else if (value !== undefined && value !== null && value !== '') {
      // Take other existing values as they are
      separateQueryParams.push(key + '=' + encodeURIComponent(value))
    }
  }

  // Return either the query params or an empty string
  return url + (separateQueryParams.length ? '?' + separateQueryParams.join('&') : '')
}
