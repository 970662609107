import { MapPopupProps } from 'shared/ui-map'
import { WebAppMapPopup } from './web-app-map-popup'
import { ReactNode } from 'react'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { LocationDetails, LocationDetailsSkeleton } from './location-details'

interface LocationMapPopupProps extends Omit<MapPopupProps, 'mapId'> {
  children: ReactNode
  geocoded?: Omit<GeocoderLocation, 'position' | 'type'>
}

export const LocationMapPopup = ({ children, geocoded, ...popupProps }: LocationMapPopupProps) => {
  return (
    <WebAppMapPopup {...popupProps}>
      {geocoded ? <LocationDetails {...geocoded} /> : <LocationDetailsSkeleton />}
      {children}
    </WebAppMapPopup>
  )
}
