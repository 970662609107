import { Feature, FeatureCollection, Point as GeoJSONPoint } from 'geojson'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Layer, Source, useMap, MapLayerMouseEvent, Point, GeoJSONSource } from 'react-map-gl/maplibre'
import { RichLineString, isPointInBounds, positionToLngLat } from 'shared/util-geo'
import { useMapClick, useMapMouseMove } from '../hooks'
import { RouteStart } from '../types'
import { colors } from 'shared/ui-design-system'
import { getMapBounds } from '../helpers'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { useAlternatingRouteLines } from './use-alternating-route-lines'
import { useAlternatingRouteMarkers } from './use-alternating-route-markers'
import { useRouteMarkers } from './use-route-markers'

const MAX_ROUTE_GEOMETRY_PREVIEWS = 10

interface CollectionMapRoutesProps {
  id: string
  mapId: string
  routeStarts: RouteStart[]
  interactive?: boolean
  geometries: Record<number, RichLineString>
  onClick: (routeId: number) => void
  onClickOutside: () => void
  onReadyForGeometryPreview: (routeIds: number[]) => void
}

export const CollectionMapRoutes = ({
  id,
  mapId,
  routeStarts,
  interactive = true,
  geometries,
  onClick,
  onClickOutside,
  onReadyForGeometryPreview,
}: CollectionMapRoutesProps) => {
  const { [mapId]: map } = useMap()

  const [hoveredRouteId, setHoveredRouteId] = useState<number | null>(null)
  const [hoveredClusterId, setHoveredClusterId] = useState<number | null>(null)
  const [sortedRouteStarts, setSortedRouteStarts] = useState<{
    withoutPreview: RouteStart[]
    withPreview: RouteStart[]
  }>({
    withoutPreview: routeStarts,
    withPreview: [],
  })

  const sourceId = useMemo(() => `${id}-source`, [id])
  const clusterOutlinesLayerId = useMemo(() => `${id}-cluster-outlines-layer`, [id])
  const routeLayerBeforeId = useRouteLayerBeforeId()

  useEffect(() => {
    // Update the separate lists while preserving the order as far as possible to prevent changing colors
    setSortedRouteStarts(({ withoutPreview, withPreview }) => {
      const updatedWithoutPreview: RouteStart[] = [...withoutPreview]
      const updatedWithPreview: RouteStart[] = [...withPreview]
      routeStarts.forEach((routeStart) => {
        // Items can only get preview, not lose it
        if (geometries[routeStart.routeId]) {
          const indexInDefaultList = updatedWithoutPreview.findIndex(({ routeId }) => routeId === routeStart.routeId)
          if (indexInDefaultList >= 0) {
            // Item gets preview
            updatedWithPreview.push(routeStart)
            updatedWithoutPreview.splice(indexInDefaultList, 1)
          }
        }
      })
      return { withoutPreview: updatedWithoutPreview, withPreview: updatedWithPreview }
    })
  }, [geometries, routeStarts])

  const previewRouteLines = useAlternatingRouteLines(
    id,
    sortedRouteStarts.withPreview.map(({ routeId, routeType }) => ({
      routeId,
      routeType,
      geometry: geometries[routeId],
    })),
    hoveredRouteId,
  )
  const defaultRouteMarkers = useRouteMarkers(id, sortedRouteStarts.withoutPreview, hoveredRouteId)
  const previewRouteMarkers = useAlternatingRouteMarkers(id, sortedRouteStarts.withPreview, hoveredRouteId)

  const previewSourceData = useMemo<FeatureCollection>(
    () => ({
      type: 'FeatureCollection',
      features: previewRouteLines.features,
    }),
    [previewRouteLines.features],
  )

  const defaultSourceData = useMemo<FeatureCollection>(
    () => ({
      type: 'FeatureCollection',
      features: [
        ...defaultRouteMarkers.features,
        ...previewRouteMarkers.features.map((feature) => ({
          ...feature,
          properties: { ...feature.properties, hasPreview: true },
        })),
      ],
    }),
    [defaultRouteMarkers.features, previewRouteMarkers.features],
  )

  const findClickableFeature = useCallback(
    (point: Point): Feature | null => {
      if (!map) return null
      const features = map.getMap().queryRenderedFeatures(point)
      for (const feature of features) {
        if (
          [
            clusterOutlinesLayerId,
            previewRouteLines.outlineLayerProps.id,
            defaultRouteMarkers.layerProps.id,
            previewRouteMarkers.layerProps.id,
          ].includes(feature.layer.id)
        ) {
          return feature
        }
      }
      return null
    },
    [
      map,
      clusterOutlinesLayerId,
      previewRouteLines.outlineLayerProps.id,
      defaultRouteMarkers.layerProps.id,
      previewRouteMarkers.layerProps.id,
    ],
  )

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!map) return
      const feature = findClickableFeature(event.point)
      if (feature?.properties) {
        if (!hoveredRouteId && !hoveredClusterId) {
          map.getCanvas().style.cursor = 'pointer'
        }
        const featureRouteId = feature.properties['routeId']
        if (featureRouteId && hoveredRouteId !== featureRouteId) {
          setHoveredClusterId(null)
          setHoveredRouteId(featureRouteId)
        }
        const featureClusterId = feature.properties['cluster_id']
        if (featureClusterId && hoveredClusterId !== featureClusterId) {
          setHoveredClusterId(featureClusterId)
          setHoveredRouteId(null)
        }
      } else {
        if (hoveredRouteId || hoveredClusterId) {
          map.getCanvas().style.cursor = ''
          setHoveredClusterId(null)
          setHoveredRouteId(null)
        }
      }
    },
    [map, findClickableFeature, hoveredRouteId, hoveredClusterId],
  )
  useMapMouseMove(mapId, handleMouseMove, interactive)

  const handleMapClick = useCallback(
    async (event: MapLayerMouseEvent) => {
      const feature = findClickableFeature(event.point)
      const routeId = feature?.properties && feature.properties['routeId']
      const clusterId = feature?.properties && feature.properties['cluster_id']
      if (routeId) {
        onClick(routeId)
      } else if (map && clusterId) {
        const source = map.getSource(sourceId) as GeoJSONSource
        const center = positionToLngLat((feature.geometry as GeoJSONPoint).coordinates)
        try {
          const zoom = await source?.getClusterExpansionZoom(clusterId)
          map.easeTo({ center, zoom })
        } catch {
          map.easeTo({ center, zoom: map.getZoom() + 1 })
        }
      } else {
        onClickOutside()
      }
    },
    [findClickableFeature, map, onClick, onClickOutside, sourceId],
  )
  useMapClick(mapId, handleMapClick, interactive)

  const handleViewportChange = useCallback(() => {
    if (!map) return
    const bounds = getMapBounds(map)
    const routeIds = routeStarts.reduce((routeIds, routeStart) => {
      if (isPointInBounds(routeStart.position, bounds)) {
        routeIds.push(routeStart.routeId)
      }
      return routeIds
    }, [] as number[])
    if (routeIds.length && routeIds.length <= MAX_ROUTE_GEOMETRY_PREVIEWS) {
      onReadyForGeometryPreview(routeIds)
    }
  }, [map, onReadyForGeometryPreview, routeStarts])

  useEffect(() => {
    map?.on('moveend', handleViewportChange)
    map?.on('zoomend', handleViewportChange)
    return () => {
      map?.off('moveend', handleViewportChange)
      map?.off('zoomend', handleViewportChange)
    }
  }, [handleViewportChange, map])

  return (
    <>
      <Source id={`${id}-geometry-source`} type="geojson" data={previewSourceData}>
        <Layer {...previewRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
        <Layer {...previewRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      </Source>
      <Source id={sourceId} type="geojson" data={defaultSourceData} cluster clusterRadius={80}>
        <Layer
          id={clusterOutlinesLayerId}
          type="circle"
          filter={['has', 'point_count']}
          paint={{
            'circle-color': colors.neutral.primary,
            'circle-radius': 22,
          }}
        />
        <Layer
          id={`${id}-clusters-layer`}
          type="circle"
          filter={['has', 'point_count']}
          paint={{
            'circle-color': [
              'case',
              ['boolean', ['==', ['get', 'cluster_id'], hoveredClusterId], false],
              colors.actionColor.tertiary,
              colors.actionColor.secondary,
            ],
            'circle-radius': 20,
          }}
        />
        <Layer
          id={`${id}-cluster-counts-layer`}
          type="symbol"
          filter={['has', 'point_count']}
          layout={{
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Roboto Medium'],
            'text-size': 16,
            'text-offset': [0, 0.15],
          }}
          paint={{
            'text-color': colors.onColor.primary,
          }}
        />
        <Layer {...defaultRouteMarkers.layerProps} filter={['!has', 'hasPreview']} />
        <Layer {...previewRouteMarkers.layerProps} filter={['has', 'hasPreview']} />
      </Source>
    </>
  )
}
