import { Position } from 'geojson'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteCollectionCategory } from 'shared/data-access-core'
import { ElevationCurveMapFeatures } from 'shared/feature-elevation-curve'
import { getBoundsFromGeometry } from 'shared/ui-map'
import { lngLatToPosition, lngLatToPosition2d } from 'shared/util-geo'
import { WebAppMap, boundsDesired, viewportDesired } from 'web-app/feature-map'
import {
  RouteCollectionMapRoutes,
  useRouteCollectionRouteStarts,
  useRouteCollectionState,
  useTourGeometries,
} from 'web-app/feature-route-collection'
import { useAreParamsDefault } from './use-are-params-default'

interface RouteCollectionMapProps {
  routeCollectionId: number
}

export const RouteCollectionMap = ({ routeCollectionId }: RouteCollectionMapProps) => {
  const dispatch = useDispatch()
  const routeStarts = useRouteCollectionRouteStarts()
  const { isRouteCollectionLoaded, routeCollection, geometries } = useRouteCollectionState()
  const tourGeometries = useTourGeometries()
  const areParamsDefault = useAreParamsDefault()

  const [collectionFitByMapBounds, setCollectionFitByMapBounds] = useState<number | null>(null)

  useEffect(() => {
    if (
      isRouteCollectionLoaded &&
      routeStarts?.length &&
      routeCollectionId !== collectionFitByMapBounds &&
      routeCollection?.id === routeCollectionId
    ) {
      if (routeCollection?.category === RouteCollectionCategory.Tour) {
        if (geometries) {
          const coordinates: Position[] = []
          for (const { routeId } of routeStarts) {
            const geometry = geometries[routeId]
            if (!geometry) return
            coordinates.push(...(geometry.coordinates as Position[]))
          }
          const bounds = getBoundsFromGeometry({
            type: 'LineString',
            coordinates,
          })
          if (bounds) {
            dispatch(boundsDesired(bounds))
            setCollectionFitByMapBounds(routeCollectionId)
          }
        }
      } else {
        if (routeStarts.length > 1) {
          const bounds = getBoundsFromGeometry({
            type: 'MultiPoint',
            coordinates: routeStarts.map((routeStart) => lngLatToPosition(routeStart.position)),
          })
          if (bounds) {
            dispatch(boundsDesired(bounds))
            setCollectionFitByMapBounds(routeCollectionId)
          }
        } else {
          dispatch(viewportDesired({ center: lngLatToPosition2d(routeStarts[0].position), zoom: 10 }))
          setCollectionFitByMapBounds(routeCollectionId)
        }
      }
    }
  }, [
    collectionFitByMapBounds,
    dispatch,
    geometries,
    isRouteCollectionLoaded,
    routeCollection,
    routeCollection?.category,
    routeCollectionId,
    routeStarts,
  ])

  return (
    <WebAppMap>
      <RouteCollectionMapRoutes />
      {routeCollection && tourGeometries && areParamsDefault && (
        <ElevationCurveMapFeatures mapRouteId="collection-routes" geometry={tourGeometries} />
      )}
    </WebAppMap>
  )
}
