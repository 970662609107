import BikemapLogoSvg from './bikemap-logo.svg'
import clsx from 'clsx'

import styles from './bikemap-logo.module.scss'

export interface BikemapLogoProps {
  size?: string
  onColor?: boolean
}

export const BikemapLogo = ({ size, onColor }: BikemapLogoProps) => {
  return (
    <div className={clsx(styles['container'], { [styles['on-color']]: onColor })} style={{ fontSize: size ?? '1rem' }}>
      <BikemapLogoSvg />
    </div>
  )
}
