import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { RouteCollectionCategory, RouteCollectionEntity } from 'shared/data-access-core'
import { Button, Checkbox, RouteCollectionItemSkeleton } from 'shared/ui-components'
import { FavoritesItem } from './favorites-item'
import { CollectionItem } from './collection-item'
import { RouteDetailsSliceDispatch } from '../../state'
import { useLocale } from 'shared/util-intl'
import {
  RouteSliceDispatch,
  assignCollection,
  toggleGlobalFavoriteRoute,
  unassignCollection,
  useAssignedRouteCollectionIds,
  useRoute,
} from 'web-app/feature-route'
import {
  useAddRouteToCollectionErrorNotification,
  useRemoveRouteFromCollectionErrorNotification,
  useRouteAssignedAndTourBrokenNotification,
  useRouteAssignedAndTourCreatedNotification,
  useRouteRemovedAndTourBrokenNotification,
} from '../../hooks/notifications'
import { UserSliceDispatch, fetchGlobalUserRouteCollections, useUserRouteCollections } from 'web-app/feature-user'

import styles from './collection-assignment-list.module.css'

const FavoritesListItem = () => {
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const route = useRoute()

  const isFavorite = !!route?.isFavorite

  return (
    <li>
      <Checkbox
        controlPosition="end"
        name="favorites"
        checked={isFavorite}
        onChange={() => dispatch(toggleGlobalFavoriteRoute())}
      >
        <FavoritesItem />
      </Checkbox>
    </li>
  )
}

interface CollectionListItemProps {
  collection: RouteCollectionEntity
}

const CollectionListItem = ({ collection }: CollectionListItemProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const assignedRouteCollectionIds = useAssignedRouteCollectionIds()
  const showAddRouteToCollectionErrorNotification = useAddRouteToCollectionErrorNotification()
  const showRouteRemovedAndTourBrokenNotification = useRouteRemovedAndTourBrokenNotification()
  const showRouteAssignedAndTourBrokenNotification = useRouteAssignedAndTourBrokenNotification()
  const showRouteAssignedAndTourCreatedNotification = useRouteAssignedAndTourCreatedNotification()
  const showRemoveRouteFromCollectionErrorNotification = useRemoveRouteFromCollectionErrorNotification()

  const isAssigned = !!assignedRouteCollectionIds?.includes(collection.id)
  const handleChange = async () => {
    if (isAssigned) {
      try {
        const { category, previousCategory } = await dispatch(unassignCollection(collection.id)).unwrap()
        if (collection.routesCount > 1 && category === null && previousCategory === RouteCollectionCategory.Tour) {
          // broke a tour
          showRouteRemovedAndTourBrokenNotification(collection, { withUndo: false })
        }
      } catch {
        showRemoveRouteFromCollectionErrorNotification(collection)
      }
    } else {
      try {
        const { category, previousCategory } = await dispatch(assignCollection(collection.id)).unwrap()
        if (category === null && previousCategory === RouteCollectionCategory.Tour) {
          // broke a tour
          showRouteAssignedAndTourBrokenNotification(collection)
        } else if (
          collection.routesCount > 0 &&
          category === RouteCollectionCategory.Tour &&
          previousCategory === null
        ) {
          // created a tour
          showRouteAssignedAndTourCreatedNotification(collection)
        }
      } catch {
        showAddRouteToCollectionErrorNotification(collection)
      }
    }
  }

  return (
    <li>
      <Checkbox controlPosition="end" name="favorites" checked={isAssigned} onChange={handleChange}>
        <CollectionItem collection={collection} />
      </Checkbox>
    </li>
  )
}

interface CollectionAssignmentListProps {
  onCreate: () => void
}

export const CollectionAssignmentList = ({ onCreate }: CollectionAssignmentListProps) => {
  const dispatch = useDispatch() as UserSliceDispatch
  const { intl } = useLocale()
  const userRouteCollections = useUserRouteCollections()

  const sortedUserRouteCollections = useMemo(
    () => userRouteCollections && userRouteCollections.sort((a, b) => a.title.localeCompare(b.title)),
    [userRouteCollections],
  )

  useEffect(() => {
    dispatch(fetchGlobalUserRouteCollections())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ul className={styles['list']}>
      <li>
        <Button variant="secondary" block icon={<AddRoundedIcon />} onClick={onCreate}>
          {intl.formatMessage({
            id: 'collections_popover_add_new_collection',
            defaultMessage: 'Add new collection',
          })}
        </Button>
      </li>
      <FavoritesListItem />
      {!sortedUserRouteCollections ? (
        <>
          <li>
            <RouteCollectionItemSkeleton />
          </li>
          <li>
            <RouteCollectionItemSkeleton />
          </li>
          <li>
            <RouteCollectionItemSkeleton />
          </li>
        </>
      ) : (
        sortedUserRouteCollections.map((collection) => (
          <CollectionListItem key={collection.id} collection={collection} />
        ))
      )}
    </ul>
  )
}
