import { ReactNode, useMemo } from 'react'
import { RouteSurface } from 'shared/data-access-core'
import { UnpavedIcon } from 'shared/ui-design-system/icons/unpaved-icon'
import { GravelIcon } from 'shared/ui-design-system/icons/gravel-icon'
import { GroundIcon } from 'shared/ui-design-system/icons/ground-icon'
import { PavedIcon } from 'shared/ui-design-system/icons/paved-icon'
import { useLocale } from 'shared/util-intl'

export const useSurfaceLabels = () => {
  const { intl } = useLocale()

  const labels = useMemo<Record<RouteSurface, string>>(
    () => ({
      [RouteSurface.Paved]: intl.formatMessage({
        id: 'route_surface_paved',
        defaultMessage: 'Paved',
      }),
      [RouteSurface.Unpaved]: intl.formatMessage({
        id: 'route_surface_unpaved',
        defaultMessage: 'Unpaved',
      }),
      [RouteSurface.Gravel]: intl.formatMessage({
        id: 'route_surface_gravel',
        defaultMessage: 'Gravel',
      }),
      [RouteSurface.Ground]: intl.formatMessage({
        id: 'route_surface_ground',
        defaultMessage: 'Ground',
      }),
    }),
    [intl],
  )

  return labels
}

export const useSurfaceIcons = () =>
  useMemo<Record<RouteSurface, ReactNode>>(
    () => ({
      [RouteSurface.Paved]: <PavedIcon />,
      [RouteSurface.Unpaved]: <UnpavedIcon />,
      [RouteSurface.Gravel]: <GravelIcon />,
      [RouteSurface.Ground]: <GroundIcon />,
    }),
    [],
  )

export const useSurfaces = (surfaces: RouteSurface[]): string[] => {
  const labels = useSurfaceLabels()
  return useMemo(() => surfaces.map((key) => labels[key]), [surfaces, labels])
}
