import { useMemo } from 'react'
import { useRouteMessages } from 'shared/feature-routes'
import { Select, SelectOption } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import NorthRoundedIcon from '@mui/icons-material/NorthRounded'
import SouthRoundedIcon from '@mui/icons-material/SouthRounded'
import { RouteSearchSorting } from 'shared/data-access-core'
import { useDiscoverSorting } from '../use-discover-sorting'

interface SortingFieldProps<ExtraSortingOptionType> {
  extraSortingOptions?: { value: ExtraSortingOptionType; label: string }[]
  defaultSorting?: RouteSearchSorting | ExtraSortingOptionType
}

export function SortingField<ExtraSortingOptionType extends string = RouteSearchSorting>({
  extraSortingOptions,
  defaultSorting,
}: SortingFieldProps<ExtraSortingOptionType>) {
  const { intl } = useLocale()
  const { ascentLabel } = useRouteMessages()
  const [sorting, setSorting] = useDiscoverSorting({
    extraSortingOptions: extraSortingOptions?.map((o) => o.value),
    defaultSorting,
  })

  const distanceLabel = intl.formatMessage({
    id: 'discover_sorting_distance',
    defaultMessage: 'Length',
  })

  const options = useMemo<SelectOption<RouteSearchSorting | ExtraSortingOptionType>[]>(
    () => [
      ...(extraSortingOptions || []),
      {
        value: 'relevance-desc',
        label: intl.formatMessage({
          id: 'discover_sorting_relevance',
          defaultMessage: 'Relevance',
        }),
      },
      {
        value: 'rating-desc',
        label: intl.formatMessage({
          id: 'discover_sorting_popularity',
          defaultMessage: 'Popularity',
        }),
      },
      {
        value: 'distance-asc',
        label: distanceLabel,
        icon: <NorthRoundedIcon />,
      },
      {
        value: 'distance-desc',
        label: distanceLabel,
        icon: <SouthRoundedIcon />,
      },
      {
        value: 'ascent-asc',
        label: ascentLabel,
        icon: <NorthRoundedIcon />,
      },
      {
        value: 'ascent-desc',
        label: ascentLabel,
        icon: <SouthRoundedIcon />,
      },
    ],
    [ascentLabel, distanceLabel, extraSortingOptions, intl],
  )

  return (
    <Select
      label={intl.formatMessage({
        id: 'discover_sorting_label',
        defaultMessage: 'Sort by',
      })}
      options={options}
      name="discover-sorting"
      value={sorting}
      onSelect={setSorting}
    />
  )
}
