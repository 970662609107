import { SelectionMapRouteSection } from './selection-map-route-section'
import { ElevationMapMarkerPoint } from './elevation-map-marker-point'
import { useElevationCurveContext } from '../context'
import { useMemo } from 'react'
import { MapRouteDistanceMarkers } from 'shared/ui-map'
import { RichLineString, RichMultiLineString } from 'shared/util-geo'

interface ElevationCurveMapFeaturesProps {
  mapRouteId: string
  geometry: RichLineString | RichMultiLineString
  areDistanceMarkersPermanent?: boolean
}

export const ElevationCurveMapFeatures = ({
  mapRouteId,
  geometry,
  areDistanceMarkersPermanent,
}: ElevationCurveMapFeaturesProps) => {
  const { unitPreference, elevationPointIndex, selectionIndexes } = useElevationCurveContext()
  const combinedGeometry = useMemo<RichLineString>(
    () =>
      geometry.type === 'RichLineString'
        ? geometry
        : {
            type: 'RichLineString',
            coordinates: geometry.coordinates.flat(),
          },
    [geometry],
  )

  return (
    <>
      <MapRouteDistanceMarkers
        id={mapRouteId}
        geometry={geometry}
        unitPreference={unitPreference}
        visible={!selectionIndexes && (areDistanceMarkersPermanent || elevationPointIndex !== undefined)}
      />
      {selectionIndexes && <SelectionMapRouteSection geometry={geometry} selectionIndexes={selectionIndexes} />}
      <ElevationMapMarkerPoint geometry={combinedGeometry} />
    </>
  )
}
