import { useLocale } from 'shared/util-intl'
import { Campaign, CampaignPopupProps } from '../types'
import { Typography } from '@mui/material'
import { Button, Modal } from 'shared/ui-components'
import { PremiumModalHeader } from '../../premium-modal/premium-modal-header'
import { pushModalEvent } from 'shared/util-analytics'
import { ReactNode } from 'react'
import { getCheckoutUrl } from 'shared/util-navigation'
import { useUserState } from '../../state'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'

import avif from './christmas-premium.avif'
import webp from './christmas-premium.webp'
import fallback from './christmas-premium.jpg'

const DISCOUNT = 0.3
const VOUCHER_CODE = 'xmas24'

const Popup = ({ isOpen, onClose }: CampaignPopupProps) => {
  const { intl, language } = useLocale()
  const { unitPreference, currencyPreference } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  const label = intl.formatMessage({
    id: 'campaign_popup_badge_christmas_24',
    defaultMessage: 'Christmas offer',
  })

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-label={label}
      onOpen={() => pushModalEvent('premium_campaign')}
      closeButtonOnColor
    >
      <PremiumModalHeader image={{ avif, webp, fallback, alt: label }} />
      <div style={{ padding: '0 1rem 1rem' }}>
        <Typography variant="h2" textAlign="center" p="2rem 1rem 0.75rem">
          {intl.formatMessage(
            {
              id: 'campaign_popup_heading_christmas_24',
              defaultMessage: 'Holiday sale: {discount, number, ::percent} off!',
            },
            {
              discount: DISCOUNT,
            },
          )}
        </Typography>
        <Typography paragraph textAlign="center">
          {intl.formatMessage(
            {
              id: 'campaign_popup_text_christmas_24',
              defaultMessage: `
Make 2025 your best cycling year ever with a year of Bikemap Premium! Just redeem the voucher code <b>{voucher}</b> to
save {discount, number, ::percent} on your Bikemap Premium Annual membership and unlock all exclusive features.
              `,
            },
            {
              voucher: VOUCHER_CODE.toUpperCase(),
              discount: DISCOUNT,
              b: (chunks: ReactNode) => <strong key={chunks?.toString()}>{chunks}</strong>,
            },
          )}
        </Typography>
        <Typography paragraph textAlign="center" variant="body2" color="textSecondary" marginBottom="1rem">
          {intl.formatMessage(
            {
              id: 'campaign_popup_fineprint_christmas_24',
              defaultMessage: 'The voucher code {voucher} is valid until 26th of December, 2024.',
            },
            {
              voucher: VOUCHER_CODE.toUpperCase(),
            },
          )}
        </Typography>
        <Button
          block
          variant="accent"
          href={getCheckoutUrl({
            language,
            unitPreference,
            cookieConsentStatistics,
            plan: 'pro-yearly',
            currency: currencyPreference?.code || 'EUR',
            voucherCode: VOUCHER_CODE,
            successUrl: window.location.href,
            cancelUrl: window.location.href,
            trackingParameters: {
              utmMedium: 'popup',
              utmCampaign: 'xmas24',
            },
          })}
        >
          {intl.formatMessage(
            {
              id: 'campaign_popup_cta_christmas_24',
              defaultMessage: 'Go Premium with {discount, number, ::percent} off',
            },
            {
              discount: DISCOUNT,
            },
          )}
        </Button>
      </div>
    </Modal>
  )
}

const campaign: Campaign = {
  start: '2024-12-15',
  end: '2024-12-26 23:55',
  isUserTarget: (isLoggedIn, isPremium) => !isPremium,
  popup: Popup,
}

export default campaign
