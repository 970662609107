import { useMemo } from 'react'
import { Position } from 'geojson'
import { useElevationCurveContext } from '../context'
import { MapMarkerPoint } from 'shared/ui-map'
import { RichLineString, reduceToPosition } from 'shared/util-geo'

interface ElevationMapMarkerPointProps {
  geometry?: RichLineString
}

export const ElevationMapMarkerPoint = ({ geometry }: ElevationMapMarkerPointProps) => {
  const { elevationPointIndex } = useElevationCurveContext()

  const elevationPoint = useMemo<Position | null>(() => {
    if (
      geometry &&
      elevationPointIndex !== undefined &&
      geometry.coordinates.length > elevationPointIndex &&
      geometry.coordinates[elevationPointIndex]
    ) {
      return reduceToPosition(geometry.coordinates[elevationPointIndex])
    }
    return null
  }, [elevationPointIndex, geometry])

  return elevationPoint && <MapMarkerPoint longitude={elevationPoint[0]} latitude={elevationPoint[1]} />
}
