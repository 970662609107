export type UserEntity = {
  id: number
  slug: string
  name: string
  avatar: {
    small: string
  } | null
  isPremium: boolean
}

export const dummyUserEntity: UserEntity = {
  id: 12,
  name: 'John Doe',
  isPremium: false,
  avatar: {
    small: '/avatar-small',
  },
  slug: 'doe',
}

export type UserEntityAvatarSizes = {
  avatar: {
    small: string
    medium: string
    large: string
    huge: string
  } | null
}

export const dummyUserEntityAvatarSizes: UserEntityAvatarSizes = {
  avatar: {
    small: '/avatar-small',
    medium: '/avatar-medium',
    large: '/avatar-large',
    huge: '/avatar-huge',
  },
}

export const PREMIUM_PLAN_PERIODS = ['monthly', 'quarterly', 'yearly', '3-year', 'lifetime', 'referral'] as const

export type PremiumPlanPeriod = (typeof PREMIUM_PLAN_PERIODS)[number] | 'free'

export type UserEntityAnalyticsData = {
  externalId: string
  planPeriod: PremiumPlanPeriod
}

export const dummyUserEntityAnalyticsData: UserEntityAnalyticsData = {
  externalId: 'abc123',
  planPeriod: 'free',
}

export type UserEntityDetails = {
  coverImage: {
    small: string
    smallWide: string
    large: string
    largeWide: string
  } | null
}

export const dummyUserEntityDetails: UserEntityDetails = {
  coverImage: {
    small: '/small',
    smallWide: '/small-wide',
    large: '/medium',
    largeWide: '/large',
  },
}

export type UserEntitySessionData = {
  favoriteRoutesCount: number
  isStaff: boolean
  email: string
  hadTrial: boolean
}

export const dummyUserEntitySessionData: UserEntitySessionData = {
  favoriteRoutesCount: 5,
  isStaff: false,
  email: 'user@email.at',
  hadTrial: false,
}

export type UserEntityStats = {
  routesCount: number
}
