import { Slider } from '@mui/material'
import { uniqueId } from 'lodash'
import { useMemo } from 'react'

import styles from './range-slider.module.scss'

type RangeSliderMarker = {
  value: number
  label: string
}

export interface RangeSliderProps {
  name: string
  label: string
  max: number
  value: [number, number]
  markers: RangeSliderMarker[]
  onChange: (value: [number, number]) => void
  renderCurrentValue: (value: [number, number]) => string
}

export const RangeSlider = ({ name, label, max, value, markers, onChange, renderCurrentValue }: RangeSliderProps) => {
  const id = useMemo(() => uniqueId(name + '-'), [name])

  const handleChange = (event: Event, value: number | number[]) => {
    if (Array.isArray(value)) {
      const [minVal, maxVal] = value
      onChange([minVal || 0, maxVal || max])
    } else {
      onChange([0, max])
    }
  }

  return (
    <div className={styles['container']}>
      <label className={styles['label']} htmlFor={id}>
        {label}
      </label>
      <div className={styles['value']}>{renderCurrentValue(value)}</div>
      <div className={styles['slider-holder']}>
        <Slider id={id} max={max} value={value} onChange={handleChange} getAriaLabel={() => label} />
      </div>
      <div className={styles['markers']}>
        {markers.map(({ value, label }, i) => (
          <div key={i} className={styles['marker']} style={{ left: (value / max) * 100 + '%' }}>
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}
