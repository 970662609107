import { useEffect } from 'react'
import { LngLat, isPointInBounds } from 'shared/util-geo'
import { useWebAppMap } from './use-web-app-map'
import { getMapBounds } from 'shared/ui-map'
import { useMapState } from './state'

/**
 * Calls a handler when the map has been moved so far away from the given location that the location is not
 * within the map bounds anymore.
 */
export const useMoveMapAway = (location: LngLat | null, onMoveMapAway: () => void) => {
  const map = useWebAppMap()
  const { desiredViewport, desiredBounds } = useMapState()

  useEffect(() => {
    if (!map || !location || desiredViewport || desiredBounds) return
    const handler = () => {
      const bounds = getMapBounds(map)
      if (bounds && !isPointInBounds(location, bounds)) {
        console.log('move away')
        onMoveMapAway()
      }
    }
    map.on('moveend', handler)
    return () => {
      map.off('moveend', handler)
    }
  }, [desiredBounds, desiredViewport, location, map, onMoveMapAway])
}
