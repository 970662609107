'use client'

import { RouteEntity } from 'shared/data-access-core'
import { RouteItem, RouteItemProps } from 'shared/ui-components'
import { useRouteMessages } from './use-route-messages'
import { useRouteStatsFormatters } from './use-route-stats-formatters'

type OmittedProps =
  | 'title'
  | 'distance'
  | 'ascent'
  | 'descent'
  | 'location'
  | 'favoriteCount'
  | 'image'
  | 'staticMap'
  | 'isFavorite'
  | 'messages'

export interface RouteEntityItemProps extends Omit<RouteItemProps, OmittedProps> {
  route: RouteEntity
  imperial?: boolean
}

/**
 * Renders a route item based on a given route entity.
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const RouteEntityItem = ({ route, imperial, ...routeItemProps }: RouteEntityItemProps) => {
  const { distanceLabel, ascentLabel, descentLabel, locationLabel } = useRouteMessages()
  const { formatRouteDistance, formatRouteElevation } = useRouteStatsFormatters(!!imperial)

  return (
    <RouteItem
      title={route.title}
      distance={formatRouteDistance(route.distanceMeters)}
      ascent={formatRouteElevation(route.ascentMeters)}
      descent={formatRouteElevation(route.descentMeters)}
      location={route.location?.name}
      favoriteCount={route.favoriteCount}
      image={route.images.length ? route.images[0].item : undefined}
      staticMap={route.staticMap?.item || undefined}
      isFavorite={route.isFavorite}
      messages={{ distanceLabel, ascentLabel, descentLabel, locationLabel }}
      {...routeItemProps}
    />
  )
}
