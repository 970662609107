import { RouteCollectionCategory } from 'shared/data-access-core'
import { useRouteCollectionState } from '../state'
import { RouteCollectionStats } from './route-collection-stats'

/**
 * Total statistics for all routes in the current main route collection in global state.
 */
export const MainRouteCollectionStats = () => {
  const { routeCollection } = useRouteCollectionState()

  return (
    <RouteCollectionStats
      routesCount={routeCollection ? routeCollection.routesCount : undefined}
      durationSeconds={routeCollection ? routeCollection.totalDurationSeconds : undefined}
      distanceMeters={routeCollection ? routeCollection.totalDistanceMeters : undefined}
      averageSpeedMetersPerSecond={
        routeCollection
          ? routeCollection.totalDurationSeconds && routeCollection.totalDistanceMeters
            ? routeCollection.totalDistanceMeters / routeCollection.totalDurationSeconds
            : null
          : undefined
      }
      ascentMeters={routeCollection ? routeCollection.totalAscentMeters : undefined}
      descentMeters={routeCollection ? routeCollection.totalDescentMeters : undefined}
      maxAltitudeMeters={routeCollection ? routeCollection.maximumElevationMeters : undefined}
      isTour={routeCollection?.category === RouteCollectionCategory.Tour}
    />
  )
}
