import { CollectionMapRoutes } from 'shared/ui-map'
import { MAP_ID } from 'web-app/feature-map'
import {
  RouteCollectionSliceDispatch,
  fetchMissingPreviewGeometries,
  routeSelectionCleared,
  selectRoute,
  useRouteCollectionRouteStarts,
  useRouteCollectionState,
} from '../state'
import { useDispatch } from 'react-redux'

export const DefaultMapRoutes = () => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { selectedRouteId, geometries } = useRouteCollectionState()
  const routeStarts = useRouteCollectionRouteStarts()

  return (
    routeStarts && (
      <CollectionMapRoutes
        id="route-collection-routes"
        mapId={MAP_ID}
        routeStarts={routeStarts}
        geometries={geometries}
        onClick={(routeId) => dispatch(selectRoute(routeId))}
        onClickOutside={() => selectedRouteId !== null && dispatch(routeSelectionCleared())}
        onReadyForGeometryPreview={(routeIds) => dispatch(fetchMissingPreviewGeometries(routeIds))}
      />
    )
  )
}
