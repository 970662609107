import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  createGlobalRouteCollection,
  fetchCollectionRouteGeometry,
  fetchGlobalRouteCollection,
  fetchGlobalRouteCollectionRoutes,
  fetchMoreGlobalRouteCollectionRoutes,
  saveGlobalRouteCollectionChanges,
} from './thunks'
import { ROUTE_COLLECTION_SLICE_KEY, RouteCollectionState } from './types'

export const initialState: RouteCollectionState = {
  isRouteCollectionLoaded: false,
  isRouteCollectionUnavailable: false,
  routeCollection: undefined,
  assignedRouteIds: undefined,
  filteredRoutesCount: undefined,
  isLoadingMoreRoutes: false,
  geometries: {},
  selectedRouteId: null,
}

const slice = createSlice({
  name: ROUTE_COLLECTION_SLICE_KEY,
  initialState,
  reducers: {
    routeSelected(state, action: PayloadAction<number>) {
      state.selectedRouteId = action.payload
    },
    routeSelectionCleared(state) {
      state.selectedRouteId = null
    },
    collectionRouteGeometriesObsolete(state) {
      state.geometries = {}
    },
    resetRouteCollectionState() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder.addCase(createGlobalRouteCollection.pending, (state) => {
      state.isRouteCollectionLoaded = false
      state.isRouteCollectionUnavailable = false
    })
    builder.addCase(createGlobalRouteCollection.fulfilled, (state, action) => {
      state.isRouteCollectionLoaded = true
      state.routeCollection = action.payload
    })
    builder.addCase(createGlobalRouteCollection.rejected, (state) => {
      state.isRouteCollectionLoaded = true
      state.routeCollection = undefined
    })

    builder.addCase(fetchGlobalRouteCollection.pending, (state, action) => {
      state.isRouteCollectionLoaded = false
      if (state.routeCollection?.id !== action.meta.arg) {
        state.routeCollection = undefined
        state.isRouteCollectionUnavailable = false
      }
    })
    builder.addCase(fetchGlobalRouteCollection.fulfilled, (state, action) => {
      state.isRouteCollectionLoaded = true
      state.routeCollection = action.payload
    })
    builder.addCase(fetchGlobalRouteCollection.rejected, (state) => {
      state.isRouteCollectionLoaded = true
      state.routeCollection = undefined
      state.isRouteCollectionUnavailable = true
    })

    builder.addCase(saveGlobalRouteCollectionChanges.pending, (state) => {
      state.isRouteCollectionLoaded = false
    })
    builder.addCase(saveGlobalRouteCollectionChanges.fulfilled, (state, action) => {
      state.isRouteCollectionLoaded = true
      state.routeCollection = action.payload
    })
    builder.addCase(saveGlobalRouteCollectionChanges.rejected, (state) => {
      state.isRouteCollectionLoaded = true
    })

    builder.addCase(fetchGlobalRouteCollectionRoutes.pending, (state) => {
      state.assignedRouteIds = undefined
      state.routeIdsInTourOrder = undefined
      state.filteredRoutesCount = undefined
    })
    builder.addCase(fetchGlobalRouteCollectionRoutes.fulfilled, (state, action) => {
      state.assignedRouteIds = action.payload.assignedRouteIds
      state.routeIdsInTourOrder = action.payload.routeIdsInTourOrder
      state.filteredRoutesCount = action.payload.filteredRoutesCount
    })

    builder.addCase(fetchMoreGlobalRouteCollectionRoutes.pending, (state) => {
      state.isLoadingMoreRoutes = true
    })
    builder.addCase(fetchMoreGlobalRouteCollectionRoutes.fulfilled, (state, action) => {
      if (!state.isLoadingMoreRoutes) return
      state.isLoadingMoreRoutes = false
      state.filteredRoutesCount = action.payload.filteredRoutesCount
      if (!action.payload || !state.assignedRouteIds) return
      state.assignedRouteIds.push(...action.payload.assignedRouteIds)
      state.routeIdsInTourOrder = null // can't be a tour
    })
    builder.addCase(fetchMoreGlobalRouteCollectionRoutes.rejected, (state) => {
      state.isLoadingMoreRoutes = false
    })

    builder.addCase(fetchCollectionRouteGeometry.fulfilled, (state, action) => {
      state.geometries[action.meta.arg] = action.payload
    })
  },
})

export const { routeSelected, routeSelectionCleared, collectionRouteGeometriesObsolete, resetRouteCollectionState } =
  slice.actions

const persistConfig: PersistConfig<RouteCollectionState> = {
  key: ROUTE_COLLECTION_SLICE_KEY,
  storage,
  whitelist: ['routeCollection'],
}

export const routeCollectionReducer = persistReducer(persistConfig, slice.reducer)
