import { LngLat } from 'shared/ui-map'
import { RoutePlannerSliceDispatch, StateWithRoutePlannerSlice, WaypointTemplate } from '../types'
import { reverseGeocode } from './geocoding'
import { controlPointMoved, waypointMoved, waypointUpdated } from '../state'
import { controlPointsSelector, routePlannerSliceSelector, waypointsSelector } from '../selectors'
import { calculateSegments } from './route-calculation'
import { generateSimpleId } from '../helpers'
import { logError } from 'web-app/utils-error-handling'
import { fitMapToGeometryBounds } from './thunks'

/**
 * Update a waypoint that has been dragged to a new position. Recalculate previous and/or next segment.
 */
export function moveWaypoint(waypointIndex: number, location: LngLat) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    if (routePlannerSliceSelector(getState()).isCalculatingRoute) {
      logError('Trying to move waypoint while route is still being calculated')
      return
    }

    dispatch(reverseGeocode(location))
    dispatch(waypointMoved({ index: waypointIndex, waypoint: { ...location, id: generateSimpleId() } }))

    const state = getState()
    const { isFullRoute, waypoints } = waypointsSelector(state)
    const controlPoints = controlPointsSelector(state)
    if (isFullRoute && controlPoints) {
      if (waypointIndex === 0) {
        // origin
        await dispatch(calculateSegments([location, controlPoints[1]], 0, 1))
      } else if (waypointIndex === waypoints.length - 1) {
        // destination
        const sectionStartControlPointIndex = controlPoints.length - 2
        const requestWaypoints = [controlPoints[sectionStartControlPointIndex], location]
        await dispatch(calculateSegments(requestWaypoints, sectionStartControlPointIndex, 1))
      } else {
        // via
        const controlPointIndex = waypoints[waypointIndex].controlPointIndex
        const requestWaypoints = [controlPoints[controlPointIndex - 1], location, controlPoints[controlPointIndex + 1]]
        await dispatch(calculateSegments(requestWaypoints, controlPointIndex - 1, 2))
      }
    }
  }
}

/**
 * Update a waypoint after replacing it in the waypoints list. Recalculate the whole section (all segments)
 * from a previous waypoint and/or to a next one.
 */
export function updateWaypoint(waypointIndex: number, waypoint: WaypointTemplate) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const state = getState()

    if (routePlannerSliceSelector(state).isCalculatingRoute) {
      logError('Trying to update waypoint while route is still being calculated')
      return
    }

    const { isFullRoute, waypoints } = waypointsSelector(state)
    const controlPoints = controlPointsSelector(state)

    dispatch(waypointUpdated({ index: waypointIndex, waypoint }))

    if (isFullRoute && controlPoints) {
      if (waypointIndex === 0) {
        // origin
        const sectionEndControlPointIndex = waypoints[waypointIndex + 1].controlPointIndex
        const requestWaypoints = [waypoint, controlPoints[sectionEndControlPointIndex]]
        await dispatch(calculateSegments(requestWaypoints, 0, sectionEndControlPointIndex))
      } else if (waypointIndex === waypoints.length - 1) {
        // destination
        const sectionStartControlPointIndex = waypoints[waypointIndex - 1].controlPointIndex
        const requestWaypoints = [controlPoints[sectionStartControlPointIndex], waypoint]
        const numSegments = controlPoints.length - sectionStartControlPointIndex - 1
        await dispatch(calculateSegments(requestWaypoints, sectionStartControlPointIndex, numSegments))
      } else {
        // via
        const sectionStartControlPointIndex = waypoints[waypointIndex - 1].controlPointIndex
        const sectionEndControlPointIndex = waypoints[waypointIndex + 1].controlPointIndex
        const requestWaypoints = [
          controlPoints[sectionStartControlPointIndex],
          waypoint,
          controlPoints[sectionEndControlPointIndex],
        ]
        const numSegments = sectionEndControlPointIndex - sectionStartControlPointIndex
        await dispatch(calculateSegments(requestWaypoints, sectionStartControlPointIndex, numSegments))
      }
      dispatch(fitMapToGeometryBounds())
    }
  }
}

/**
 * Recalculate previous and/or next segment in order to update the control point location.
 */
export function updateDraggedControlPoint(controlPointIndex: number, location: LngLat) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    if (routePlannerSliceSelector(getState()).isCalculatingRoute) {
      logError('Trying to update dragged control point while route is still being calculated')
      return
    }

    dispatch(controlPointMoved())

    const controlPoints = controlPointsSelector(getState())
    if (controlPoints) {
      if (controlPointIndex === 0) {
        // start
        await dispatch(calculateSegments([location, controlPoints[1]], 0, 1))
      } else if (controlPointIndex === controlPoints.length - 1) {
        // end
        await dispatch(calculateSegments([controlPoints[controlPoints.length - 2], location], 0, 1))
      } else {
        // via
        const requestWaypoints = [controlPoints[controlPointIndex - 1], location, controlPoints[controlPointIndex + 1]]
        await dispatch(calculateSegments(requestWaypoints, controlPointIndex - 1, 2))
      }
    }
  }
}
