import { debounce } from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'

export type UseInputValueReturn = {
  inputProps: {
    value: string
    onChange: (e: React.FormEvent<HTMLInputElement>) => void
    onReset: () => void
  }
  setValue: (value: string) => void
}

/**
 * Group change events and run only if value changes.
 */
export function useInputValue(
  defaultValue: string,
  onDebouncedChange?: (val: string) => void,
  debounceTime = 300,
): UseInputValueReturn {
  const [value, setValue] = useState<string>(defaultValue)

  const onDebounced = useMemo(
    () =>
      debounce((val) => {
        if (onDebouncedChange) {
          onDebouncedChange(val)
        }
      }, debounceTime),
    [debounceTime, onDebouncedChange],
  )

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return {
    inputProps: {
      value,
      onChange: (e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value
        setValue(val)
        onDebounced(val)
      },
      onReset: () => {
        setValue('')
        onDebounced('')
      },
    },
    setValue,
  }
}
