import { useLocale } from 'shared/util-intl'
import { EditableBikeTypes } from '../components/tags/bike-types'
import { EditableRouteDescription } from '../components/description/route-description'
import { EditableRoutePrivacy } from '../components/route-privacy'
import { EditableRouteHeader } from '../components/header/route-header'
import { RouteImages } from '../components/images/route-images'
import { ElevationCurveWithSkeleton } from 'shared/feature-elevation-curve'
import { ShareAction } from '../components/share-action'
import { EmbedAction } from '../components/embedding/embed-action'
import { ButtonGroup } from 'shared/ui-components'
import { ExportActions } from '../components/export-actions'
import { Typography } from '@mui/material'
import { WebAppContent, WebAppSheet } from 'web-app/ui-layout'
import {
  MainRouteStats,
  RouteByIdTile,
  SurfacesDiagram,
  WayTypesAndBikeNetwork,
  useAssignedRouteCollectionIds,
  useCreator,
  useIsOwnRoute,
  useRoute,
} from 'web-app/feature-route'
import { CreatorActions } from '../components/creator/creator-actions'
import { RouteDetailCollections } from '../components/collections/route-detail-collections'
import { useRef } from 'react'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'
import { EditableContentProvider } from 'web-app/feature-editable-content'

interface RouteDetailsSheetProps {
  previousView?: RouteDetailsPreviousView
  contextRouteCollectionId?: number
}

export const RouteDetailsSheet = ({ previousView, contextRouteCollectionId }: RouteDetailsSheetProps) => {
  const { intl } = useLocale()
  const route = useRoute()
  const creator = useCreator()
  const isOwnRoute = useIsOwnRoute()
  const assignedRouteCollectionIds = useAssignedRouteCollectionIds()

  const sheetRef = useRef<HTMLDivElement>(null)

  return (
    <WebAppSheet ref={sheetRef}>
      <EditableContentProvider>
        <WebAppContent>
          <EditableRouteHeader title={route?.title} previousView={previousView} />
        </WebAppContent>
        {(!!route?.images.length || isOwnRoute) && (
          <WebAppContent>
            <RouteImages route={route} />
          </WebAppContent>
        )}
        <WebAppContent>
          <MainRouteStats extended />
        </WebAppContent>
        <WebAppContent>
          <ElevationCurveWithSkeleton height="8rem" />
        </WebAppContent>
        {isOwnRoute && (
          <WebAppContent>
            <EditableRoutePrivacy
              heading={intl.formatMessage({
                id: 'route_details_main_content_privacy_heading',
                defaultMessage: 'Privacy',
              })}
              isPrivate={route?.isPrivate}
            />
          </WebAppContent>
        )}
        <WebAppContent>
          <ButtonGroup>
            <ShareAction />
            <EmbedAction />
          </ButtonGroup>
        </WebAppContent>
        {(route?.description || isOwnRoute) && (
          <WebAppContent>
            <EditableRouteDescription
              heading={intl.formatMessage({
                id: 'route_details_main_content_description_heading',
                defaultMessage: 'About this route',
              })}
              route={route}
            />
          </WebAppContent>
        )}
        {route && (
          <WebAppContent>
            <WayTypesAndBikeNetwork geometry={route.geometry} routeDistanceMeters={route.distanceMeters} />
          </WebAppContent>
        )}
        {route && (
          <WebAppContent>
            <Typography variant="h4" component="h2">
              {intl.formatMessage({
                id: 'route_details_main_content_surfaces_heading',
                defaultMessage: 'Surfaces',
              })}
            </Typography>
            <SurfacesDiagram geometry={route.geometry} routeDistanceMeters={route.distanceMeters} />
          </WebAppContent>
        )}
        {(route?.bikeTypes.length || isOwnRoute) && (
          <WebAppContent>
            <EditableBikeTypes
              heading={intl.formatMessage({
                id: 'route_details_main_content_bike_types_heading',
                defaultMessage: 'Suited for',
              })}
              bikeTypes={route?.bikeTypes}
            />
          </WebAppContent>
        )}
      </EditableContentProvider>
      <WebAppContent>
        <ExportActions
          heading={intl.formatMessage({
            id: 'route_details_main_content_export_heading',
            defaultMessage: 'Export',
          })}
        />
      </WebAppContent>
      {route && assignedRouteCollectionIds && (
        <WebAppContent>
          <Typography variant="h4" component="h2">
            {intl.formatMessage({
              id: 'route_details_main_content_collections_heading',
              defaultMessage: 'In your collections',
            })}
          </Typography>
          {route && assignedRouteCollectionIds && (
            <RouteDetailCollections
              route={route}
              assignedRouteCollectionIds={assignedRouteCollectionIds}
              sheetEl={sheetRef.current}
              contextRouteCollectionId={contextRouteCollectionId}
            />
          )}
        </WebAppContent>
      )}
      {route && (
        <WebAppContent>
          {creator && (
            <Typography variant="h4" component="h2">
              {intl.formatMessage({
                id: 'route_details_creator_heading',
                defaultMessage: 'Created by',
              })}
            </Typography>
          )}
          <CreatorActions />
        </WebAppContent>
      )}
      {route && route.copiedFrom !== null && (
        <WebAppContent>
          <Typography variant="h4" component="h2">
            {intl.formatMessage({
              id: 'route_details_base_route_heading',
              defaultMessage: 'This route is based on',
            })}
          </Typography>
          <RouteByIdTile routeId={route.copiedFrom} />
        </WebAppContent>
      )}
    </WebAppSheet>
  )
}
