import { useCallback, useEffect, useRef } from 'react'
import { DiscoverSliceDispatch, discoverMapBoundsUpdated, updateDiscoverResults, useDiscoverState } from './state'
import { useDispatch } from 'react-redux'
import { DiscoverParams, useDiscoverParams } from './use-discover-params'
import { isEqual } from 'lodash'
import { useMapState, useWebAppMap } from 'web-app/feature-map'
import { getMapBounds } from 'shared/ui-map'
import { MapRef } from 'react-map-gl/maplibre'

/**
 * Set up implicit triggers for updating discover search results and params.
 * Include this once per view within the `WebAppMap`.
 */
export const useDiscover = (geonameId?: number) => {
  const dispatch = useDispatch() as DiscoverSliceDispatch
  const map = useWebAppMap()
  const { desiredBounds, desiredViewport } = useMapState()
  const params = useDiscoverParams()
  const { lastMapBounds } = useDiscoverState()

  const lastParams = useRef<DiscoverParams | null>(null)
  const lastGeonameId = useRef<number | undefined>(undefined)

  /** Query new results if any parameter differs from the last time */
  const queryIfNecessary = useCallback(
    (map: MapRef) => {
      const bounds = getMapBounds(map)
      if (
        !isEqual(params, lastParams.current) ||
        !isEqual(bounds, lastMapBounds) ||
        geonameId !== lastGeonameId.current
      ) {
        dispatch(updateDiscoverResults({ params, bounds, geonameId }))
        lastParams.current = params
        lastGeonameId.current = geonameId || undefined
      }
    },
    [dispatch, geonameId, lastMapBounds, params],
  )

  useEffect(() => {
    if (map && !desiredBounds && !desiredViewport) {
      queryIfNecessary(map)
      const handler = () => {
        dispatch(discoverMapBoundsUpdated(getMapBounds(map)))
      }
      map.on('moveend', handler)
      return () => {
        map.off('moveend', handler)
      }
    }
    return () => {}
  }, [desiredBounds, desiredViewport, dispatch, map, queryIfNecessary])
}
