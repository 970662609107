import { ReactNode } from 'react'
import { Skeleton, Typography } from '@mui/material'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useIsOwnRoute, useRoute } from 'web-app/feature-route'
import { FavoriteButton } from './favorite-button'
import { RouteDetailsPreviousView, useInternalLink } from 'web-app/feature-navigation'
import { useRouteContentEditing } from '../use-route-content-editing'
import { Link } from 'shared/ui-components'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { getRegionPath } from 'shared/util-navigation'
import { EditableString } from 'web-app/feature-editable-content'
import { useLocale } from 'shared/util-intl'
import { ROUTE_TITLE_MAX_LENGTH, ROUTE_TITLE_MIN_LENGTH } from 'shared/data-access-core'
import { isRouteTitleTooLong, isRouteTitleTooShort } from '../../helpers'

import styles from './route-header.module.scss'

interface RouteHeaderProps {
  title: string
  editButton?: ReactNode
  previousView?: RouteDetailsPreviousView
}

const RouteHeader = ({ title, editButton, previousView }: RouteHeaderProps) => {
  const route = useRoute()
  const isOwnRoute = useIsOwnRoute()
  const getInternalLinkProps = useInternalLink()

  return (
    <>
      {route && (
        <div className={styles['floating-container']}>
          {editButton || (!isOwnRoute && <FavoriteButton route={route} />)}
        </div>
      )}
      {previousView && (
        <Link marginBottom="0.25rem" {...getInternalLinkProps(previousView.path)}>
          <ArrowBackRoundedIcon /> {previousView.label}
        </Link>
      )}
      <Typography variant="h3" component="h1" className={styles['title']}>
        {title}
      </Typography>
      {route?.location && (
        <Typography variant="body2" color="textSecondary" paddingTop="0.25rem">
          <Link
            className={styles['secondary-info']}
            {...getInternalLinkProps(getRegionPath(route.location.geonameId))}
            secondary
          >
            <FmdGoodIcon className={styles['secondary-info-icon']} />
            &nbsp;{route.location.name}
          </Link>
          {isOwnRoute && (
            <span className={styles['secondary-info']}>
              <FavoriteIcon className={styles['secondary-info-icon']} />
              &nbsp;{route.favoriteCount}
            </span>
          )}
        </Typography>
      )}
    </>
  )
}

const RouteHeaderSkeleton = () => (
  <>
    <Typography variant="h3" component="h1" marginBottom="0.25rem">
      <Skeleton width="80%" data-testid="skeleton" />
    </Typography>
    <Typography variant="body2" color="textSecondary" paddingTop="0.25rem">
      <Skeleton width="50%" data-testid="skeleton" />
    </Typography>
  </>
)

interface EditableRouteHeaderProps {
  title?: string
  previousView?: RouteDetailsPreviousView
}

export const EditableRouteHeader = ({ title, previousView }: EditableRouteHeaderProps) => {
  const { intl } = useLocale()
  const contentEditingProps = useRouteContentEditing('title')

  const validate = (value: string): string | null => {
    if (isRouteTitleTooShort(value) || isRouteTitleTooLong(value)) {
      return intl.formatMessage(
        {
          id: 'route_title_form_error_too_short',
          defaultMessage: 'The title must be between {min} and {max} characters long.',
        },
        {
          min: ROUTE_TITLE_MIN_LENGTH.toString(),
          max: ROUTE_TITLE_MAX_LENGTH.toString(),
        },
      )
    }

    return null
  }

  return (
    <EditableString
      currentValue={title}
      renderContent={({ value, editButton }) => (
        <RouteHeader title={value} editButton={editButton} previousView={previousView} />
      )}
      renderLoading={() => <RouteHeaderSkeleton />}
      label={intl.formatMessage({
        id: 'route_title_input_label',
        defaultMessage: 'Route title',
      })}
      placeholder={intl.formatMessage({
        id: 'route_title_input_placeholder',
        defaultMessage: 'Enter a route title ...',
      })}
      isRequired
      onValidation={validate}
      {...contentEditingProps}
    />
  )
}
