import { useLocation } from 'react-router-dom'
import { useLocale, useTitle } from 'shared/util-intl'
import { Home } from 'web-app/view-home'

const FallbackView = () => {
  const { intl } = useLocale()
  const location = useLocation()

  useTitle(
    intl.formatMessage({
      id: 'home_document_title',
      defaultMessage: 'Bikemap - The essential app for cyclists, by cyclists',
    }),
  )

  const { selectedLocation, keepMapViewport } = location.state || {}

  return <Home initialState={{ selectedLocation, keepMapViewport }} />
}

export default FallbackView
