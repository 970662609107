import { Feature, FeatureCollection } from 'geojson'
import { useCallback, useMemo, useState } from 'react'
import { Layer, Source, useMap, MapLayerMouseEvent, Point } from 'react-map-gl/maplibre'
import { LngLat, positionToLngLat } from 'shared/util-geo'
import { useMapClick, useMapMouseMove } from '../hooks'
import { RouteStart } from '../types'
import { useRouteMarkers } from './use-route-markers'

interface DiscoverRouteMarkersProps {
  id: string
  mapId: string
  routeStarts: RouteStart[]
  interactive?: boolean
  onClick: (routeId: number, start: LngLat) => void
  onClickOutside: () => void
}

export const DiscoverRouteMarkers = ({
  id,
  mapId,
  routeStarts,
  interactive = true,
  onClick,
  onClickOutside,
}: DiscoverRouteMarkersProps) => {
  const { [mapId]: map } = useMap()

  const [hoveredRouteId, setHoveredRouteId] = useState<number | null>(null)

  const routeMarkers = useRouteMarkers(id, routeStarts, hoveredRouteId)

  const sourceData = useMemo<FeatureCollection>(
    () => ({
      type: 'FeatureCollection',
      features: routeMarkers.features,
    }),
    [routeMarkers.features],
  )

  const findClickableFeature = useCallback(
    (point: Point): Feature | null => {
      if (!map) return null
      const features = map.getMap().queryRenderedFeatures(point)
      for (const feature of features) {
        if (feature.layer.id === routeMarkers.layerProps.id) {
          map.getCanvas().style.cursor = 'pointer'
          setHoveredRouteId(feature.properties['routeId'])
          return feature
        }
      }
      return null
    },
    [map, routeMarkers.layerProps.id],
  )

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!map) return
      const feature = findClickableFeature(event.point)
      if (feature?.properties) {
        if (!hoveredRouteId) {
          map.getCanvas().style.cursor = 'pointer'
        }
        if (hoveredRouteId !== feature.properties['routeId']) {
          setHoveredRouteId(feature.properties['routeId'])
        }
      } else {
        if (hoveredRouteId) {
          map.getCanvas().style.cursor = ''
          setHoveredRouteId(null)
        }
      }
    },
    [hoveredRouteId, findClickableFeature, map],
  )
  useMapMouseMove(mapId, handleMouseMove, interactive)

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const feature = findClickableFeature(event.point)
      const routeId = feature?.properties && feature.properties['routeId']
      const start = feature?.geometry.type === 'Point' && positionToLngLat(feature.geometry.coordinates)
      if (routeId && start) {
        onClick(routeId, start)
      } else {
        onClickOutside()
      }
    },
    [findClickableFeature, onClick, onClickOutside],
  )
  useMapClick(mapId, handleMapClick, interactive)

  return (
    <Source id={`${id}-source`} type="geojson" data={sourceData}>
      <Layer {...routeMarkers.layerProps} />
    </Source>
  )
}
