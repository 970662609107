import { Point } from 'geojson'
import { ROUTE_POI_TYPES, RoutePoiEntity, RoutePoiType } from '../../entities'
import { ResponseParser } from 'shared/util-network'

export type RoutePoiResponse = {
  id: number
  image_data: {
    fallback: string
    map_popup: string | null
  } | null
  lng_lat: Point
  poi_class: string
  route_id: number
  text?: string
}

export const dummyRoutePoiResponse: RoutePoiResponse = {
  id: 531,
  image_data: {
    fallback: '/fallback',
    map_popup: '/map-popup',
  },
  lng_lat: {
    type: 'Point',
    coordinates: [17.111, 49.114],
  },
  poi_class: 'poi-photo',
  route_id: 100,
  text: 'Some description...',
}

function parseRoutePoiType(res: string): RoutePoiType {
  for (const type of ROUTE_POI_TYPES) {
    if (res.includes(type)) {
      return type
    }
  }
  return 'sight'
}

export function convertToRoutePoiEntity(res: RoutePoiResponse): RoutePoiEntity {
  const parser = new ResponseParser(res)
  return {
    id: parser.requireNumber('id'),
    routeId: parser.requireNumber('route_id'),
    position: parser.requireLngLat('lng_lat'),
    type: parseRoutePoiType(parser.requireString('poi_class')),
    description: parser.takeString('text'),
    image: parser.takeImageSizes('image_data', {
      mapPopup: 'map_popup',
    }),
  }
}
