import { LngLat, lngLatToPosition2d } from 'shared/util-geo'
import { API_PATH_ROUTE_POI } from '../../config'
import { RoutePoiEntity, RoutePoiType } from '../../entities'
import { patchToCoreApi } from '../../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import { RoutePoiResponse, convertToRoutePoiEntity } from './shared-response'

export type RoutePoiUpdateForm = {
  position?: LngLat
  type?: RoutePoiType
  description?: string
  image?: File | null
}

type UpdateRoutePoiRequestBody = {
  lng_lat?: string
  text?: string
  poi_class?: string
  image?: File | ''
}

export async function updateRoutePoi(routePoiId: number, form: RoutePoiUpdateForm): ApiResult<RoutePoiEntity> {
  try {
    const body: UpdateRoutePoiRequestBody = {}
    if (form.position) {
      body.lng_lat = JSON.stringify({
        type: 'Point',
        coordinates: lngLatToPosition2d(form.position),
      })
    }
    if (form.type) {
      body.poi_class = 'poi-' + form.type
    }
    if (form.description !== undefined) {
      body.text = form.description
    }
    if (form.image !== undefined) {
      body.image = form.image || ''
    }

    const res: RoutePoiResponse = await patchToCoreApi(API_PATH_ROUTE_POI, {
      params: { routePoiId },
      body,
    })

    try {
      return createSuccessResult(convertToRoutePoiEntity(res))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routePoiId, form })
  }
}
