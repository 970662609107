import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useFragmentRouting = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { fragmentHash, fragmentValue } = useMemo<{ fragmentHash: string | null; fragmentValue: number | null }>(() => {
    const [fragmentHash, valueString] = location.hash.split('-')
    const fragmentValue = Number.parseInt(valueString)
    return {
      fragmentHash: fragmentHash.length > 1 ? fragmentHash : null,
      fragmentValue: typeof fragmentValue === 'number' ? fragmentValue : null,
    }
  }, [location.hash])

  const getFragmentLinkProps = useCallback(
    (hash: string, value?: number) => {
      let href = location.pathname + hash
      if (typeof value === 'number') {
        href += `-${value}`
      }
      const onClick: React.MouseEventHandler = (e) => {
        e.preventDefault()
        navigate(href, { replace: location.hash.startsWith(hash) })
      }
      return { href, onClick }
    },
    [location.hash, location.pathname, navigate],
  )

  const resetFragment = useCallback(() => {
    navigate(location.pathname)
  }, [location.pathname, navigate])

  return { fragmentHash, fragmentValue, getFragmentLinkProps, resetFragment }
}
