import { useMap } from 'react-map-gl/maplibre'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Control, ToolButton } from 'shared/ui-components'
import { CompassIcon } from 'shared/ui-design-system/icons/compass-icon'
import { useLocale } from 'shared/util-intl'
import { useEffect, useState } from 'react'

interface NavigationControlProps {
  mapId: string
}

export const NavigationControl = ({ mapId }: NavigationControlProps) => {
  const { intl } = useLocale()
  const { [mapId]: map } = useMap()

  const [pitchTransform, setPitchTransform] = useState<string>('')
  const [bearingTransform, setBearingTransform] = useState<string>('')

  useEffect(() => {
    const handler = () => {
      const pitch = map?.getPitch()
      setPitchTransform(pitch ? `rotateX(${pitch}deg)` : '')
    }
    map?.on('pitch', handler)
    return () => {
      map?.off('pitch', handler)
    }
  }, [map])

  useEffect(() => {
    const handler = () => {
      const bearing = map?.getBearing()
      setBearingTransform(bearing ? `rotate(${bearing}deg)` : '')
    }
    map?.on('rotate', handler)
    return () => {
      map?.off('rotate', handler)
    }
  }, [map])

  const zoomInLabel = intl.formatMessage({
    id: 'navigation_control_zoom_in_label',
    defaultMessage: 'Zoom in',
  })
  const zoomOutLabel = intl.formatMessage({
    id: 'navigation_control_zoom_out_label',
    defaultMessage: 'Zoom out',
  })
  const orientationLabel = intl.formatMessage({
    id: 'navigation_control_orientation_label',
    defaultMessage: 'Orientation',
  })

  return (
    <Control>
      <ToolButton
        data-testid="zoom-in-button"
        variant="ghost-primary"
        ariaLabel={zoomInLabel}
        icon={<AddIcon />}
        onClick={() => {
          map?.zoomIn()
        }}
      />
      <ToolButton
        data-testid="zoom-out-button"
        variant="ghost-primary"
        ariaLabel={zoomOutLabel}
        icon={<RemoveIcon />}
        onClick={() => {
          map?.zoomOut()
        }}
      />
      <ToolButton
        variant="ghost-primary"
        data-testid="compass-button"
        ariaLabel={orientationLabel}
        icon={<CompassIcon style={{ transform: `${pitchTransform} ${bearingTransform}` }} data-testid="CompassIcon" />}
        onClick={() => {
          map?.resetNorthPitch()
        }}
      />
    </Control>
  )
}
