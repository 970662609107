import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { API_URL_GET_DEFAULT_MAP_STYLE, MapStyleEntity, fetchMapStyles } from 'shared/data-access-core'
import { MapStyleControl as BaseMapStyleControl } from 'shared/ui-components'
import { useUser, PremiumModalMapStyles } from 'web-app/feature-user'
import { mapStylesLoaded, mapStyleChanged, useMapState, globalHeatmapEnabled, globalHeatmapDisabled } from '../state'

export interface MapStyleControlProps {
  small?: boolean
}

export const MapStyleControl = (props: MapStyleControlProps) => {
  const { intl } = useLocale()

  const dispatch = useDispatch()

  const [user, isUserLoaded] = useUser()
  const { mapStyles, mapStyle, isGlobalHeatmapEnabled, isGlobalHeatmapSupported } = useMapState()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [hasMapStylesCallFailed, setHasMapStylesCallFailed] = useState<boolean>(false)

  const isPremiumUser = useMemo(() => user?.isPremium, [user])

  useEffect(() => {
    if (!mapStyles && !hasMapStylesCallFailed) {
      fetchMapStyles().then((result) => {
        if (result.success) {
          dispatch(mapStylesLoaded(result.data))
        } else {
          setHasMapStylesCallFailed(true)
        }
      })
    }
  }, [hasMapStylesCallFailed, mapStyles, dispatch])

  const activeMapStyleIndex = useMemo<number | undefined>(() => {
    if (mapStyles === null) return undefined
    let defaultIndex
    for (let i = 0; i < mapStyles.length; i++) {
      if (mapStyles[i].url === mapStyle) return i
      if (mapStyles[i].isDefault) {
        defaultIndex = i
      }
    }
    return defaultIndex
  }, [mapStyle, mapStyles])

  useEffect(() => {
    if (!mapStyles || activeMapStyleIndex === undefined) return

    const isPremiumMapStyle = mapStyles[activeMapStyleIndex].isPremium

    if (!isPremiumUser && isPremiumMapStyle) {
      dispatch(mapStyleChanged(API_URL_GET_DEFAULT_MAP_STYLE))
    }
  }, [isPremiumUser, mapStyles, activeMapStyleIndex, dispatch])

  const handleSelect = (mapStyleData: MapStyleEntity) => {
    if (!isUserLoaded) {
      return
    }

    if (mapStyleData.isPremium && !isPremiumUser) {
      setIsModalOpen(true)
      return
    }

    dispatch(mapStyleChanged(mapStyleData.url))
  }

  const messages = {
    label: intl.formatMessage({
      id: 'map_style_control_label',
      defaultMessage: 'Map settings',
    }),
    sublabel: intl.formatMessage({
      id: 'map_style_control_sublabel',
      defaultMessage: 'Map styles & heatmap layers',
    }),
    stylesHeading: intl.formatMessage({
      id: 'map_style_control_styles_heading',
      defaultMessage: 'Map styles',
    }),
    heatmapHeading: intl.formatMessage({
      id: 'map_style_control_heatmap_heading',
      defaultMessage: 'Heatmap layers',
    }),
    globalHeatmapLabel: intl.formatMessage({
      id: 'map_style_control_global_heatmap_label',
      defaultMessage: 'Show global heatmap',
    }),
  }

  if (hasMapStylesCallFailed) {
    return null
  }

  const handleGlobalHeatmapChange = (checked: boolean) => {
    dispatch(checked ? globalHeatmapEnabled() : globalHeatmapDisabled())
  }

  return (
    <>
      <BaseMapStyleControl<MapStyleEntity>
        mapStyles={mapStyles || undefined}
        active={activeMapStyleIndex}
        onSelect={handleSelect}
        messages={messages}
        globalHeatmapChecked={isGlobalHeatmapEnabled}
        onGlobalHeatmapChange={isGlobalHeatmapSupported ? handleGlobalHeatmapChange : undefined}
        withPremiumIndicators={!isPremiumUser}
        blocking={!isModalOpen}
        maxHeight="calc(100vh - 14rem)"
        {...props}
      />
      <PremiumModalMapStyles isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}
