import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ONLY_LOOPS_KEY } from 'shared/util-discover'

export const useDiscoverOnlyLoops = (): [onlyLoops: boolean, setOnlyLoops: (value: boolean) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onlyLoops = useMemo<boolean>(() => !!searchParams.has(ONLY_LOOPS_KEY), [searchParams])

  const setOnlyLoops = useCallback(
    (value: boolean) => {
      if (value) {
        searchParams.set(ONLY_LOOPS_KEY, '1')
      } else {
        searchParams.delete(ONLY_LOOPS_KEY)
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  return [onlyLoops, setOnlyLoops]
}
