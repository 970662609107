import clsx from 'clsx'
import React from 'react'
import { BaseButton, BaseButtonVariants } from '../../base/BaseButton'

import styles from './ToolButton.module.scss'

export interface ToolButtonProps {
  children?: string
  className?: string
  icon: React.ReactNode
  variant?: BaseButtonVariants
  disabled?: boolean
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  ariaLabel: string
  'data-testid'?: string
  coveringClickTarget?: boolean
  style?: React.CSSProperties
  href?: string
}

export const ToolButton = ({ children, className, icon, variant = 'primary', ...rest }: ToolButtonProps) => {
  const rootClasses = clsx(className, styles['root'], {
    [styles['with-label']]: children,
  })

  return (
    <BaseButton className={rootClasses} variant={variant} type="button" {...rest}>
      {icon}
      {children && <span className={styles['label']}>{children}</span>}
    </BaseButton>
  )
}

export default ToolButton
