import { Typography } from '@mui/material'
import { RouteTileSkeleton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'
import { WebAppRouteEntityTile } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { DiscoverSliceDispatch, loadMoreDiscoverResults, useDiscoverRoutes, useDiscoverState } from './state'
import { useCallback } from 'react'
import { PaginatedList } from 'web-app/ui-layout'
import { useDiscoverParams } from './use-discover-params'
import { RouteDetailsPreviousView } from 'web-app/feature-navigation'
import { useWebAppMap } from 'web-app/feature-map'
import { getMapBounds } from 'shared/ui-map'

interface DiscoverRoutesListProps {
  routeDetailsPreviousView?: RouteDetailsPreviousView
  geonameId?: number
  onRouteClick?: () => void
}

export const DiscoverRoutesList = ({ routeDetailsPreviousView, geonameId, onRouteClick }: DiscoverRoutesListProps) => {
  const dispatch = useDispatch() as DiscoverSliceDispatch
  const map = useWebAppMap()
  const { intl } = useLocale()
  const { unitPreference } = useUserState()
  const discoverRoutes = useDiscoverRoutes()
  const { isSearching, count } = useDiscoverState()
  const params = useDiscoverParams()

  const loadMore = useCallback(async () => {
    if (!map) return
    const bounds = getMapBounds(map)
    await dispatch(loadMoreDiscoverResults({ params, bounds, geonameId })).unwrap()
  }, [dispatch, geonameId, map, params])

  return (
    <PaginatedList
      loading={isSearching}
      results={discoverRoutes}
      count={count || undefined}
      skeleton={<RouteTileSkeleton />}
      renderItem={({ route, creator }) => (
        <WebAppRouteEntityTile
          route={route}
          creator={creator ?? null}
          imperial={unitPreference === 'imperial'}
          routeDetailsPreviousView={routeDetailsPreviousView}
          onClick={onRouteClick}
        />
      )}
      onLoadMore={loadMore}
    >
      <Typography paragraph color="textSecondary">
        {intl.formatMessage({
          id: 'discover_routes_list_no_results',
          defaultMessage: 'There are no routes in this region. Try zooming out or moving the map.',
        })}
      </Typography>
    </PaginatedList>
  )
}
