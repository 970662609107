import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { getFromCoreApi } from '../../network'
import { API_PATH_AVAILABLE_SUBSCRIPTION_PLANS } from '../../config'
import { SubscriptionPlanEntity } from '../../entities'

type ResponseItem = {
  code: string
  price: string
  trial_period: number
}

type Response = ResponseItem[]

export type SubscriptionPlans = {
  monthly?: SubscriptionPlanEntity
  yearly?: SubscriptionPlanEntity
  threeYears?: SubscriptionPlanEntity
  lifetime?: SubscriptionPlanEntity
}

export async function fetchAvailableSubscriptionPlans(currencyCode: string): ApiResult<SubscriptionPlans> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_AVAILABLE_SUBSCRIPTION_PLANS, {
      params: { currencyCode },
    })
    try {
      return createSuccessResult(getSubscriptionPlansFromResponse(res))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

export async function fetchAvailableGiftPlans(currencyCode: string): ApiResult<SubscriptionPlans> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_AVAILABLE_SUBSCRIPTION_PLANS, {
      params: { currencyCode },
      queryParams: { giftable: 1 },
    })
    try {
      return createSuccessResult(getSubscriptionPlansFromResponse(res))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

function getSubscriptionPlansFromResponse(res: Response): SubscriptionPlans {
  const subscriptionPlans: SubscriptionPlans = {}
  for (const planRes of res) {
    if (planRes.code.includes('monthly')) {
      subscriptionPlans.monthly = convertToSubscriptionPlanEntity(planRes)
    } else if (planRes.code.includes('yearly')) {
      subscriptionPlans.yearly = convertToSubscriptionPlanEntity(planRes)
    } else if (planRes.code.includes('3-years')) {
      subscriptionPlans.threeYears = convertToSubscriptionPlanEntity(planRes)
    } else if (planRes.code.includes('lifetime')) {
      subscriptionPlans.lifetime = convertToSubscriptionPlanEntity(planRes)
    }
  }
  return subscriptionPlans
}

function convertToSubscriptionPlanEntity(res: ResponseItem): SubscriptionPlanEntity {
  const parser = new ResponseParser(res)
  return {
    code: parser.requireString('code'),
    price: parser.takeAsNumber('price'),
    trialDays: parser.requireNumber('trial_period'),
  }
}
