import { replaceParams, serializeParams } from './helpers'
import {
  DeleteRequestOptions,
  FetchOptions,
  GetRequestOptions,
  PostRequestOptions,
  FormDataValue,
  RequestBody,
  PatchRequestOptions,
  PutRequestOptions,
} from './types'

export class AuthError extends Error {
  constructor(res?: Response) {
    super(res && `[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'AuthError'
  }
}

export class NotFoundError extends Error {
  constructor(res?: Response) {
    super(res && `[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'NotFoundError'
  }
}

export class GoneError extends Error {
  constructor(res?: Response) {
    super(res && `[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'GoneError'
  }
}

export class ResponseError extends Error {
  status: number

  constructor(res: Response) {
    super(`[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'ResponseError'
    this.status = res.status
  }
}

/**
 * Make network requests with our custom headers and error handling.
 * @throws Error
 */
async function makeRequest(url: string, options: FetchOptions) {
  try {
    const res = await fetch(url, {
      method: options.method,
      body: options.body,
      headers: options.headers,
      credentials: options.credentials || 'same-origin',
      signal: options.signal,
      next: options.next,
    } as FetchOptions)

    if (res.ok && res.status === 204) {
      return {}
    }

    if (res.ok || options.skipResponseValidation) {
      return res.json()
    }

    if (res.status === 401 || res.status === 403) {
      throw new AuthError(res)
    }
    if (res.status === 404) {
      throw new NotFoundError(res)
    }
    if (res.status === 410) {
      throw new GoneError(res)
    }

    // Handle invalid responses below in catch
    throw new ResponseError(res)
  } catch (error) {
    if (options.isSilent) {
      return error
    }
    throw error
  }
}

/**
 * Make GET requests.
 * @throws Error
 */
export function getRequest(url: string, options: GetRequestOptions = {}) {
  const requestUrl = serializeParams(replaceParams(url, options.params), options.queryParams)

  return makeRequest(requestUrl, {
    method: 'GET',
    headers: options.headers || {},
    credentials: options.credentials,
    signal: options.signal,
    isSilent: options.isSilent,
    next: { revalidate: 120 }, // SSR data cache to prevent render-blocking request
  })
}

/**
 * Make POST requests.
 * @throws Error
 */
export function postRequest(url: string, options: PostRequestOptions = {}) {
  const requestUrl = replaceParams(url, options.params)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'POST',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    skipResponseValidation: options.skipResponseValidation,
  })
}

/**
 * Make PUT requests.
 * @throws Error
 */
export function putRequest(url: string, options: PutRequestOptions = {}) {
  const requestUrl = replaceParams(url, options.params)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'PUT',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    skipResponseValidation: options.skipResponseValidation,
  })
}

/**
 * Make PATCH requests.
 * @throws Error
 */
export function patchRequest(url: string, options: PatchRequestOptions = {}) {
  const requestUrl = replaceParams(url, options.params)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'PATCH',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    skipResponseValidation: options.skipResponseValidation,
  })
}

/**
 * Make a DELETE request.
 * @throws Error
 */
export function deleteRequest(url: string, options: DeleteRequestOptions = {}) {
  const requestUrl = serializeParams(replaceParams(url, options.params), options.queryParams)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'DELETE',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    isSilent: options.isSilent,
  })
}

type RequestBodyPreparation = {
  body?: FetchOptions['body']
  headers: FetchOptions['headers']
}

function prepareRequestBody(
  body?: RequestBody,
  type: 'urlencoded' | 'json' | 'form-data' = 'form-data',
): RequestBodyPreparation {
  if (body) {
    if (type === 'urlencoded') {
      return {
        body: new URLSearchParams(body as Record<string, string>),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    }

    if (type === 'json') {
      return {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      }
    }

    if (type === 'form-data') {
      const formDataBody = new FormData()
      Object.entries(body).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          val.forEach((item) => formDataBody.append(key, item))
        } else {
          formDataBody.append(key, val as FormDataValue)
        }
      })
      return {
        body: formDataBody,
        headers: {},
      }
    }
  }

  return {
    headers: {},
  }
}
