import { LocationList } from 'shared/ui-components'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import { RegionEntity, RegionEntityMapData, RegionEntityParents } from 'shared/data-access-core'
import { SearchResult } from './types'

interface RegionResultItemProps {
  region: RegionEntity & RegionEntityParents & RegionEntityMapData
  onSelect: (result: SearchResult) => void
}

export const RegionResultItem = ({ region, onSelect }: RegionResultItemProps) => (
  <LocationList.Item
    title={region.name}
    subtitle={
      region.kind === 'admin_2'
        ? `${region.admin1.name}, ${region.country.name}`
        : region.kind === 'admin_1'
          ? region.country.name
          : undefined
    }
    icon={region.kind === 'admin_2' ? <ApartmentRoundedIcon /> : <PublicRoundedIcon />}
    onClick={() => onSelect({ type: 'region', data: region })}
  />
)
