import { MapPopup, MapPopupProps } from 'shared/ui-map'
import { MAP_ID } from '../settings'
import { WebAppTopContent, useBreakpoints } from 'web-app/ui-layout'

/**
 * Renders either as map popup on large viewports or as top content on small viewports.
 * Needs to be inside a `WebAppMap`.
 */
export const WebAppMapPopup = ({ children, hidden, ...props }: Omit<MapPopupProps, 'mapId'>) => {
  const { layoutBreakpoint } = useBreakpoints()

  return layoutBreakpoint ? (
    <MapPopup mapId={MAP_ID} {...props} hidden={hidden}>
      {children}
    </MapPopup>
  ) : (
    <WebAppTopContent hidden={hidden}>{children}</WebAppTopContent>
  )
}
