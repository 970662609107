import { PrivacyItem, PrivacyItemSkeleton } from './privacy-item'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { PrivacyFieldSet } from '../privacy-field-set'
import { useMessages } from 'shared/ui-components'
import { EditableContent, EditableContentProps } from '../editable-content'

interface PrivacyProps {
  isPrivate: boolean
  privateDescription: string
  publicDescription: string
}

const Privacy = ({ isPrivate, privateDescription, publicDescription }: PrivacyProps) => {
  const { privateLabel, publicLabel } = useMessages()

  return isPrivate ? (
    <PrivacyItem icon={<VisibilityOffRoundedIcon />} title={privateLabel} description={privateDescription} />
  ) : (
    <PrivacyItem icon={<VisibilityRoundedIcon />} title={publicLabel} description={publicDescription} />
  )
}

interface PrivacyFormProps {
  value: boolean
  privateDescription: string
  publicDescription: string
  onChange: (value: boolean) => void
}

const PrivacyForm = ({ value, privateDescription, publicDescription, onChange }: PrivacyFormProps) => (
  <form>
    <PrivacyFieldSet
      noLabel
      value={value}
      onChange={onChange}
      publicDescription={publicDescription}
      privateDescription={privateDescription}
    />
  </form>
)

interface EditablePrivacyProps
  extends Omit<EditableContentProps<boolean>, 'renderLoading' | 'renderContent' | 'renderForm'> {
  privateDescription: string
  publicDescription: string
}

export const EditablePrivacy = ({
  privateDescription,
  publicDescription,
  ...editableContentProps
}: EditablePrivacyProps) => (
  <EditableContent
    {...editableContentProps}
    renderContent={({ value }) => (
      <Privacy isPrivate={value} privateDescription={privateDescription} publicDescription={publicDescription} />
    )}
    renderForm={(props) => (
      <PrivacyForm {...props} privateDescription={privateDescription} publicDescription={publicDescription} />
    )}
    renderLoading={() => <PrivacyItemSkeleton />}
  />
)
