import { ForwardedRef, ReactNode, forwardRef, useCallback, useEffect, useState } from 'react'
import { TopContentContainer } from './web-app-top-content'
import { useLayoutContext } from './layout-provider'
import { throttle } from 'lodash'
import clsx from 'clsx'
import { useBreakpoints } from './use-breakpoints'

import styles from './web-app-sheet.module.scss'

interface WebAppSheetProps {
  children: ReactNode
  fullHeight?: boolean
}

export const WebAppSheet = forwardRef(
  ({ children, fullHeight }: WebAppSheetProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { mainActionsRef, scrollContainerRef, scrollToInitialFold } = useLayoutContext()
    const { layoutBreakpoint } = useBreakpoints()

    const checkIfNeedsShadow = useCallback(
      (el: HTMLDivElement | null): boolean => {
        if (layoutBreakpoint) {
          return (
            !!el &&
            !!el.clientHeight &&
            el.scrollHeight > el.clientHeight &&
            el.clientHeight + el.scrollTop < el.scrollHeight
          )
        }
        return !!mainActionsRef.current && mainActionsRef.current.getBoundingClientRect().bottom >= window.innerHeight
      },
      [layoutBreakpoint, mainActionsRef],
    )

    const [isShadowShown, setIsShadowShown] = useState<boolean>(checkIfNeedsShadow(scrollContainerRef.current))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      setIsShadowShown(checkIfNeedsShadow(scrollContainerRef.current))
    })

    useEffect(() => {
      if (!layoutBreakpoint) {
        scrollToInitialFold()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const throttledHandler = throttle(
        () => {
          setIsShadowShown(checkIfNeedsShadow(scrollContainerRef.current))
        },
        100,
        {
          leading: true,
          trailing: true,
        },
      )
      window.addEventListener('scroll', throttledHandler, true)

      return () => {
        window.removeEventListener('scroll', throttledHandler, true)
        throttledHandler.cancel()
      }
    }, [checkIfNeedsShadow, scrollContainerRef])

    return (
      <div ref={ref} className={styles['container']}>
        <TopContentContainer />
        <div
          ref={layoutBreakpoint ? scrollContainerRef : undefined}
          className={clsx(styles['content'], {
            [styles['min-height-content']]: !layoutBreakpoint && (mainActionsRef.current || fullHeight),
          })}
        >
          {children}
          {!layoutBreakpoint && (mainActionsRef.current || fullHeight) && <div className={styles['height-filler']} />}
          {mainActionsRef.current && (
            <div className={clsx(styles['scroll-shadow'], { [styles['scrolled-to-end']]: !isShadowShown })} />
          )}
        </div>
      </div>
    )
  },
)
