import { LineString, MultiLineString, Position } from 'geojson'
import {
  AlongTheRouteAttribute,
  BikeNetwork,
  Surface,
  WayType,
  getBikeNetwork,
  getSurface,
  getWayType,
} from './along-the-route-attributes'
import calculateDistance from '@turf/distance'
import { Position2d, Position3d } from './types'
import { logError } from 'shared/util-error-handling'

export type RichPosition = [
  /** Longitude */
  longitude: number,
  /** Latitude */
  latitude: number,
  /** Absolute elevation in meters */
  elevationMeters: number,
  /** Distance from previous position (or 0) */
  distanceMeters: number,
  /** Milliseconds since previous position */
  timeMilliseconds: number,
  /** Surface from previous position to this one */
  surface: Surface | null,
  /** Way type from previous position to this one */
  wayType: WayType | null,
  /** Bike network from previous position to this one */
  bikeNetwork: BikeNetwork | null,
]

export type RichLineString = {
  type: 'RichLineString'
  coordinates: RichPosition[]
}

export type RichMultiLineString = {
  type: 'RichMultiLineString'
  coordinates: RichPosition[][]
}

export type AlongTheRouteData = {
  distancesMeters?: number[]
  timesMilliseconds?: number[]
  surfaces?: AlongTheRouteAttribute<string>
  wayTypes?: AlongTheRouteAttribute<string>
  bikeNetworks?: AlongTheRouteAttribute<string>
}

type TotalStats = {
  totalDistanceMeters: number
  totalDurationSeconds: number
}

/** Part of the total stat, up to which the aggregated stat can differ to be considered matching */
const STATS_REFINEMENT_TOLERANCE_FACTOR = 0.01

/**
 * Compose a `RichLineString` based on several data sources, which may or may not be provided
 * by an API endpoint.
 */
export function composeRichLineString(
  lineString: LineString,
  data?: AlongTheRouteData,
  stats?: TotalStats,
): RichLineString {
  const coordinates = composeRichSegment(lineString.coordinates, data)
  if (stats) {
    refineRichSegment(coordinates, stats)
  }
  return {
    type: 'RichLineString',
    coordinates,
  }
}

/**
 * Convert a standard `MultiLineString` to a `RichMultiLineString`.
 */
export function composeRichMultiLineString(
  multiLineString: MultiLineString,
  data?: AlongTheRouteData,
): RichMultiLineString {
  return {
    type: 'RichMultiLineString',
    coordinates: multiLineString.coordinates.map((segment) => composeRichSegment(segment, data)),
  }
}

/**
 * Compose a segment of rich coordinates based on several data sources, which may or may not be provided
 * by an API endpoint.
 */
export function composeRichSegment(coordinates: Position[], data: AlongTheRouteData = {}): RichPosition[] {
  const richCoordinates: RichPosition[] = []

  const distancesMeters = getValidAlongTheRouteStat(coordinates.length, data.distancesMeters)
  const timesMilliseconds = getValidAlongTheRouteStat(coordinates.length, data.timesMilliseconds)

  const surfaces: AlongTheRouteAttribute<string> = getValidAlongTheRouteAttribute(coordinates.length, data.surfaces)
  let surfaceSectionIndex = 0
  const wayTypes: AlongTheRouteAttribute<string> = getValidAlongTheRouteAttribute(coordinates.length, data.wayTypes)
  let wayTypeSectionIndex = 0
  const bikeNetworks: AlongTheRouteAttribute<string> = getValidAlongTheRouteAttribute(
    coordinates.length,
    data.bikeNetworks,
  )
  let bikeNetworkSectionIndex = 0

  coordinates.forEach((position, i) => {
    const [longitude, latitude, elevation] = position as Position2d | Position3d

    const elevationMeters = elevation ?? 0
    const distanceMeters =
      i === 0
        ? 0
        : distancesMeters
          ? distancesMeters[i]
          : calculateDistance(coordinates[i - 1], coordinates[i], { units: 'meters' })
    const timeMilliseconds =
      i === 0
        ? 0
        : timesMilliseconds
          ? timesMilliseconds[i]
          : estimateDuration(distanceMeters, elevationMeters - richCoordinates[i - 1][2])

    richCoordinates.push([
      longitude,
      latitude,
      elevationMeters,
      distanceMeters,
      timeMilliseconds,
      getSurface(surfaces[surfaceSectionIndex][2]),
      getWayType(wayTypes[wayTypeSectionIndex][2]),
      getBikeNetwork(bikeNetworks[bikeNetworkSectionIndex][2]),
    ])

    if (i === surfaces[surfaceSectionIndex][1]) {
      surfaceSectionIndex++
    }
    if (i === wayTypes[wayTypeSectionIndex][1]) {
      wayTypeSectionIndex++
    }
    if (i === bikeNetworks[bikeNetworkSectionIndex][1]) {
      bikeNetworkSectionIndex++
    }
  })

  return richCoordinates
}

/**
 * Refine rich geometry to match known total stats. This changes given `coordinates` directly.
 */
function refineRichSegment(coordinates: RichPosition[], stats: TotalStats) {
  if (stats.totalDistanceMeters > 0 && stats.totalDurationSeconds > 0) {
    const derivedStats = deriveTotalStats(coordinates)
    const absoluteDistanceTolerance = stats.totalDistanceMeters * STATS_REFINEMENT_TOLERANCE_FACTOR
    const absoluteDurationTolerance = stats.totalDurationSeconds * STATS_REFINEMENT_TOLERANCE_FACTOR
    if (
      derivedStats.distanceMeters > 0 &&
      derivedStats.durationSeconds > 0 &&
      (derivedStats.distanceMeters < stats.totalDistanceMeters - absoluteDistanceTolerance ||
        derivedStats.distanceMeters > stats.totalDistanceMeters + absoluteDistanceTolerance ||
        derivedStats.durationSeconds < stats.totalDurationSeconds - absoluteDurationTolerance ||
        derivedStats.durationSeconds > stats.totalDurationSeconds + absoluteDurationTolerance)
    ) {
      const distanceFactor = stats.totalDistanceMeters / derivedStats.distanceMeters
      const durationFactor = stats.totalDurationSeconds / derivedStats.durationSeconds
      console.log(
        `Refining along-the-route stats (distance factor: ${distanceFactor}; duration factor: ${durationFactor})`,
      )
      coordinates.forEach((position) => {
        position[3] *= distanceFactor
        position[4] *= durationFactor
      })
    }
  }
}

function getValidAlongTheRouteStat(geometryLength: number, stat?: number[]): number[] | undefined {
  if (stat) {
    if (stat.length === geometryLength) {
      return stat
    }
    logError('Along-the-route stat does not match geometry', null, { geometryLength, stat })
  }
  return undefined
}

function getValidAlongTheRouteAttribute(
  geometryLength: number,
  attribute?: AlongTheRouteAttribute<string>,
): AlongTheRouteAttribute<string> {
  if (!attribute?.length) {
    return [[0, geometryLength - 1, 'missing']]
  }
  const attributeLength = attribute[attribute.length - 1][1] + 1
  if (attributeLength !== geometryLength) {
    console.log('Matching along-the-route attribute to geometry')
    const matchedAttribute: AlongTheRouteAttribute<string> = []
    for (const [startIndex, endIndex, value] of attribute) {
      const matchedStartIndex = Math.round(((startIndex + 1) / attributeLength) * geometryLength - 1)
      const matchedEndIndex = Math.round(((endIndex + 1) / attributeLength) * geometryLength - 1)
      if (matchedStartIndex !== matchedEndIndex) {
        matchedAttribute.push([matchedStartIndex, matchedEndIndex, value])
      }
    }
    return matchedAttribute
  }
  return attribute
}

// These are experimental values based on comparison with Graphhopper responses (WEB-1412, refactored in WEB-1654)
const ESTIMATED_AVERAGE_SPEED_IN_KMH = 17
const ESTIMATED_AVERAGE_SPEED_IN_MS = ESTIMATED_AVERAGE_SPEED_IN_KMH / 3.6
const GRADIENT_FACTOR_UP = 12
const GRADIENT_FACTOR_DOWN = 3

/**
 * Get a rough estimation of the route stats based on distance and elevation.
 */
export function estimateDuration(distanceMeters: number, elevationDifference: number): number {
  if (!distanceMeters) return 0
  const gradientFactor = elevationDifference > 0 ? GRADIENT_FACTOR_UP : GRADIENT_FACTOR_DOWN
  const equivalentFlatDistance = distanceMeters + elevationDifference * gradientFactor
  return Math.round((equivalentFlatDistance / ESTIMATED_AVERAGE_SPEED_IN_MS) * 1000)
}

/**
 * Simply cast to `LineString`, when having additional items in coordinates doesn't matter.
 */
export function castToLineString(richLineString: RichLineString): LineString {
  return {
    type: 'LineString',
    coordinates: richLineString.coordinates as Position[],
  }
}

/**
 * Simply cast to `MultiLineString`, when having additional items in coordinates doesn't matter.
 */
export function castToMultiLineString(richLineString: RichMultiLineString): MultiLineString {
  return {
    type: 'MultiLineString',
    coordinates: richLineString.coordinates as Position[][],
  }
}

/**
 * Convert a `RichPosition` to a new standard `Position` with elevation.
 */
export function reduceToPosition(richPosition: RichPosition): Position3d {
  const [longitude, latitude, elevation] = richPosition
  return [longitude, latitude, elevation]
}

/**
 * Convert `RichLineString` to `LineString`, reducing coordinates to standard `Position`.
 */
export function reduceToLineString(richLineString: RichLineString): LineString {
  return {
    type: 'LineString',
    coordinates: richLineString.coordinates.map(reduceToPosition),
  }
}

/**
 * Aggregate total distance from rich geometry.
 */
export function getTotalDistanceMeters(geometry: RichLineString): number {
  return geometry.coordinates.reduce((distance: number, position: RichPosition) => distance + position[3], 0)
}

export function copyRichPosition(position: RichPosition): RichPosition {
  return [...position] as RichPosition
}

/**
 * Calculate aggregated stats for a segment of rich route geometry.
 * TODO WEB-1659 calculate also ascent & descent here and not in elevation curve lib
 */
export function deriveTotalStats(coordinates: RichPosition[]): {
  distanceMeters: number
  durationSeconds: number
  maximumElevationMeters: number
} {
  let distanceMeters = 0
  let durationMilliseconds = 0
  let maximumElevationMeters = 0
  for (let i = 1; i < coordinates.length; i++) {
    if (coordinates[i][2] > maximumElevationMeters) {
      maximumElevationMeters = coordinates[i][2]
    }
    distanceMeters += coordinates[i][3]
    durationMilliseconds += coordinates[i][4]
  }
  return {
    distanceMeters,
    durationSeconds: Math.round(durationMilliseconds / 1000),
    maximumElevationMeters,
  }
}

export function getTimes(geometry: RichLineString): number[] {
  return geometry.coordinates.map((position) => position[4])
}
