import { RouteBikeType } from 'shared/data-access-core'
import { ChipsRow, ChipsRowSkeleton } from './chips-row'
import { useLocale } from 'shared/util-intl'
import { ChipSelectField, ChipSelectFieldOption } from 'shared/ui-components'
import { Typography } from '@mui/material'
import { useBikeTypeIcons, useBikeTypeLabels } from 'shared/feature-routes'
import { useMemo } from 'react'
import { useRouteContentEditing } from '../use-route-content-editing'
import { EditableContent } from 'web-app/feature-editable-content'

interface BikeTypesProps {
  bikeTypes: RouteBikeType[]
}

const BikeTypes = ({ bikeTypes }: BikeTypesProps) => {
  const { intl } = useLocale()
  const labels = useBikeTypeLabels()
  const icons = useBikeTypeIcons()

  return bikeTypes.length ? (
    <ChipsRow chips={bikeTypes} labels={labels} icons={icons} />
  ) : (
    <Typography paragraph color="textSecondary">
      {intl.formatMessage({
        id: 'route_bike_types_empty_placeholder',
        defaultMessage: 'No bike types set.',
      })}
    </Typography>
  )
}

interface BikeTypesFormProps {
  name: string
  value: RouteBikeType[]
  onChange: (value: RouteBikeType[]) => void
}

const BikeTypesForm = ({ name, value, onChange }: BikeTypesFormProps) => {
  const { intl } = useLocale()
  const labels = useBikeTypeLabels()
  const icons = useBikeTypeIcons()

  const options = useMemo<Record<1 | 2 | 3, ChipSelectFieldOption>>(
    () => ({
      1: { label: labels[1], icon: icons[1] },
      2: { label: labels[2], icon: icons[2] },
      3: { label: labels[3], icon: icons[3] },
    }),
    [icons, labels],
  )

  return (
    <ChipSelectField
      name={name}
      label={intl.formatMessage({
        id: 'route_bike_types_label',
        defaultMessage: 'Bike types',
      })}
      labelHidden
      options={options}
      value={value.map((v) => v.toString())}
      onChange={(selectedValues) => onChange(selectedValues.map((v) => Number.parseInt(v)) as RouteBikeType[])}
    />
  )
}

interface EditableBikeTypesProps {
  heading?: string
  bikeTypes?: RouteBikeType[]
}

export const EditableBikeTypes = ({ heading, bikeTypes }: EditableBikeTypesProps) => {
  const contentEditingProps = useRouteContentEditing('bikeTypes')

  return (
    <EditableContent
      currentValue={bikeTypes}
      renderContent={({ value }) => <BikeTypes bikeTypes={value} />}
      renderForm={(props) => <BikeTypesForm {...props} />}
      renderLoading={() => <ChipsRowSkeleton />}
      heading={heading}
      {...contentEditingProps}
    />
  )
}
