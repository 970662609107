import { RouteEntity } from 'shared/data-access-core'
import React from 'react'
import { getLoginUrl, getRouteDetailsPath } from 'shared/util-navigation'
import { RouteEntityItem } from 'shared/feature-routes'
import { useUser } from 'web-app/feature-user'
import {
  RouteDetailsPreviousView,
  useNavigateToCollectionRouteDetails,
  useNavigateToRouteDetails,
  useSessionInfo,
} from 'web-app/feature-navigation'

interface RouteEntityItemProps {
  route: RouteEntity
  imperial?: boolean
  routeDetailsPreviousView?: RouteDetailsPreviousView
  routeCollectionId?: number
  onFavoriteToggle: () => void
  onClick?: (e: React.MouseEvent) => void
}

/**
 * Renders a route item based on a given route entity.
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const WebAppRouteEntityItem = ({
  route,
  imperial,
  routeDetailsPreviousView,
  routeCollectionId,
  onFavoriteToggle,
  onClick,
}: RouteEntityItemProps) => {
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const navigateToCollectionRouteDetails = useNavigateToCollectionRouteDetails()
  const [user, isUserLoaded] = useUser()
  const sessionInfo = useSessionInfo()

  const handleHeartButtonClick = async () => {
    if (isUserLoaded && !user) {
      window.location.href = getLoginUrl({
        webAppPath: window.location.pathname,
        ...sessionInfo,
      })
    } else {
      onFavoriteToggle()
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
    e.preventDefault()
    if (routeCollectionId) {
      navigateToCollectionRouteDetails(route.id, routeCollectionId)
    } else {
      navigateToRouteDetails(route.id, { state: { previousView: routeDetailsPreviousView } })
    }
  }

  return (
    <RouteEntityItem
      route={route}
      imperial={imperial}
      href={getRouteDetailsPath(route.id)}
      onClick={handleClick}
      onHeartButtonClick={handleHeartButtonClick}
    />
  )
}
