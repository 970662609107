import { RichLineString, Surface } from 'shared/util-geo'
import { AlongTheRouteDiagram, CategoryGroups } from './along-the-route-diagram'
import { colors } from 'shared/ui-design-system'
import { useMemo } from 'react'
import { useLocale } from 'shared/util-intl'

interface SurfacesDiagramProps {
  geometry: RichLineString
  routeDistanceMeters: number
}

export const SurfacesDiagram = ({ geometry, routeDistanceMeters }: SurfacesDiagramProps) => {
  const { intl } = useLocale()

  const labels = useMemo<Record<Surface, string>>(
    () => ({
      [Surface.PavingStones]: intl.formatMessage({
        id: 'surface_paving_stones_label',
        defaultMessage: 'Paving stones',
      }),
      [Surface.Concrete]: intl.formatMessage({
        id: 'surface_concrete_label',
        defaultMessage: 'Concrete',
      }),
      [Surface.Asphalt]: intl.formatMessage({
        id: 'surface_asphalt_label',
        defaultMessage: 'Asphalt',
      }),
      [Surface.Paved]: intl.formatMessage({
        id: 'surface_paved_label',
        defaultMessage: 'Paved (undefined)',
      }),
      [Surface.Gravel]: intl.formatMessage({
        id: 'surface_gravel_label',
        defaultMessage: 'Gravel',
      }),
      [Surface.Unpaved]: intl.formatMessage({
        id: 'surface_unpaved_label',
        defaultMessage: 'Unpaved (undefined)',
      }),
      [Surface.Snow]: intl.formatMessage({
        id: 'surface_snow_label',
        defaultMessage: 'Snow',
      }),
      [Surface.Metal]: intl.formatMessage({
        id: 'surface_metal_label',
        defaultMessage: 'Metal',
      }),
      [Surface.Rock]: intl.formatMessage({
        id: 'surface_rock_label',
        defaultMessage: 'Rock',
      }),
      [Surface.MetalGrid]: intl.formatMessage({
        id: 'surface_metal_grid_label',
        defaultMessage: 'Metal grid',
      }),
      [Surface.Pebblestone]: intl.formatMessage({
        id: 'surface_pebblestone_label',
        defaultMessage: 'Pebblestone',
      }),
      [Surface.Mud]: intl.formatMessage({
        id: 'surface_mud_label',
        defaultMessage: 'Mud',
      }),
      [Surface.Wood]: intl.formatMessage({
        id: 'surface_wood_label',
        defaultMessage: 'Wood',
      }),
      [Surface.Ground]: intl.formatMessage({
        id: 'surface_ground_label',
        defaultMessage: 'Ground',
      }),
      [Surface.Woodchips]: intl.formatMessage({
        id: 'surface_woodchips_label',
        defaultMessage: 'Woodchips',
      }),
      [Surface.Cobblestones]: intl.formatMessage({
        id: 'surface_cobblestones_label',
        defaultMessage: 'Cobblestones',
      }),
      [Surface.Sand]: intl.formatMessage({
        id: 'surface_sand_label',
        defaultMessage: 'Sand',
      }),
      [Surface.LooseGravel]: intl.formatMessage({
        id: 'surface_loose_gravel_label',
        defaultMessage: 'Loose gravel',
      }),
      [Surface.Shells]: intl.formatMessage({
        id: 'surface_shells_label',
        defaultMessage: 'Shells',
      }),
    }),
    [intl],
  )

  const surfaceColors = useMemo<Record<Surface, string>>(
    () => ({
      [Surface.PavingStones]: colors.surfacesComp.pavingStones,
      [Surface.Concrete]: colors.surfacesComp.concrete,
      [Surface.Asphalt]: colors.surfacesComp.asphalt,
      [Surface.Paved]: colors.surfacesComp.paved,
      [Surface.Gravel]: colors.surfacesComp.gravel,
      [Surface.Unpaved]: colors.surfacesComp.unpaved,
      [Surface.Snow]: colors.surfacesComp.snow,
      [Surface.Metal]: colors.surfacesComp.metal,
      [Surface.Rock]: colors.surfacesComp.rock,
      [Surface.MetalGrid]: colors.surfacesComp.metalGrid,
      [Surface.Pebblestone]: colors.surfacesComp.pebblestone,
      [Surface.Mud]: colors.surfacesComp.mud,
      [Surface.Wood]: colors.surfacesComp.wood,
      [Surface.Ground]: colors.surfacesComp.ground,
      [Surface.Woodchips]: colors.surfacesComp.woodchips,
      [Surface.Cobblestones]: colors.surfacesComp.cobblestones,
      [Surface.Sand]: colors.surfacesComp.sand,
      [Surface.LooseGravel]: colors.surfacesComp.looseGravel,
      [Surface.Shells]: colors.surfacesComp.shells,
    }),
    [],
  )

  const groups = useMemo<CategoryGroups<Surface>>(
    () => [
      {
        categories: [
          Surface.PavingStones,
          Surface.Concrete,
          Surface.Asphalt,
          Surface.Paved,
          Surface.Metal,
          Surface.Wood,
          Surface.Cobblestones,
        ],
        label: intl.formatMessage({
          id: 'surface_group_paved_label',
          defaultMessage: 'Paved',
        }),
      },
      {
        categories: [
          Surface.Gravel,
          Surface.Unpaved,
          Surface.Snow,
          Surface.Rock,
          Surface.MetalGrid,
          Surface.Pebblestone,
          Surface.Mud,
          Surface.Ground,
          Surface.Woodchips,
          Surface.Sand,
          Surface.LooseGravel,
          Surface.Shells,
        ],
        label: intl.formatMessage({
          id: 'surface_group_unpaved_label',
          defaultMessage: 'Unpaved',
        }),
      },
    ],
    [intl],
  )

  return (
    <AlongTheRouteDiagram
      geometry={geometry}
      attributeIndex={5}
      colors={surfaceColors}
      labels={labels}
      nullLabel={intl.formatMessage({
        id: 'surface_missing_label',
        defaultMessage: 'Undefined',
      })}
      routeDistanceMeters={routeDistanceMeters}
      groups={groups}
    />
  )
}
