import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { MigrationManifest, PersistConfig, createMigrate, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { RESULTS_LIST_LENGTH } from './definitions'
import { SearchResult } from './types'

export const SEARCH_SLICE_KEY = 'search'

export interface SearchState {
  /** Results recently selected */
  history: SearchResult[]
}

export type StateWithSearchSlice = {
  [SEARCH_SLICE_KEY]: SearchState
}

export const initialState: SearchState = {
  history: [],
}

const slice = createSlice({
  name: SEARCH_SLICE_KEY,
  initialState,
  reducers: {
    resultSelected(state, action: PayloadAction<SearchResult>) {
      state.history = [
        action.payload,
        ...state.history.filter((result) => !isEqual(result, action.payload)).slice(0, RESULTS_LIST_LENGTH - 1),
      ]
    },
  },
})

export const { resultSelected } = slice.actions

const migrations = {
  1: (state: SearchState): SearchState => ({
    history: state.history.filter((result) => result.type === 'place'), // drop refactored entities
  }),
}

const persistConfig: PersistConfig<SearchState> = {
  key: SEARCH_SLICE_KEY,
  version: 1,
  storage,
  whitelist: ['history'],
  migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: true }),
}

export const searchReducer = persistReducer(persistConfig, slice.reducer)

export const useSearchState = () => useSelector((state: StateWithSearchSlice) => state[SEARCH_SLICE_KEY])
