import { deleteFromCoreApi } from '../../network'
import { API_PATH_ROUTE } from '../../config'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export async function deleteRoute(routeId: number): ApiResult<number> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE, {
      params: { routeId },
    })
    return createSuccessResult(routeId)
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
