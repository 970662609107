import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { RoutePlannerIllustratedLink } from 'web-app/feature-navigation'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { ToolButton, useMessages } from 'shared/ui-components'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { LoopPlannerIllustratedLink } from './loop-planner-illustrated-link'

import styles from './features-gallery.module.scss'

export const FeaturesGallery = () => {
  const { intl } = useLocale()
  const { showMoreLabel } = useMessages()

  const [scrollLeft, setScrollLeft] = useState<number>(0)

  const listRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const list = listRef.current
    if (!list) return
    const handler = debounce(
      () => {
        setScrollLeft(list.scrollLeft)
      },
      100,
      { leading: true },
    )
    list.addEventListener('scroll', handler)
    return () => list.removeEventListener('scroll', handler)
  })

  const handleScroll = () => {
    if (!listRef.current) return
    listRef.current?.scrollBy({ left: listRef.current.clientWidth, behavior: 'smooth' })
  }

  const handleScrollBack = () => {
    if (!listRef.current) return
    listRef.current?.scrollBy({ left: -listRef.current.clientWidth, behavior: 'smooth' })
  }

  const canScroll = useMemo<boolean>(
    () => !listRef.current || scrollLeft < listRef.current.scrollWidth - listRef.current.clientWidth - 1,
    [scrollLeft],
  )

  const canScrollBack = useMemo<boolean>(() => scrollLeft > 0, [scrollLeft])

  return (
    <div className={styles['container']}>
      <ul ref={listRef} className={styles['list']}>
        <li className={styles['item']}>
          <Typography variant="h3" marginBottom={0}>
            {intl.formatMessage({
              id: 'features_gallery_route_planner_heading',
              defaultMessage: 'Route planner',
            })}
          </Typography>
          <Typography color="textSecondary" marginBottom="0.5rem">
            {intl.formatMessage({
              id: 'features_gallery_route_planner_subheading',
              defaultMessage: 'Plan a route anywhere in the world',
            })}
          </Typography>
          <RoutePlannerIllustratedLink />
        </li>
        <li className={styles['item']}>
          <Typography variant="h3" marginBottom={0}>
            {intl.formatMessage({
              id: 'features_gallery_loop_planner_heading',
              defaultMessage: 'Loop planner',
            })}
          </Typography>
          <Typography color="textSecondary" marginBottom="0.5rem">
            {intl.formatMessage({
              id: 'features_gallery_loop_planner_subheading',
              defaultMessage: 'Let the app suggest your perfect round trip',
            })}
          </Typography>
          <LoopPlannerIllustratedLink />
        </li>
      </ul>
      <ToolButton
        className={clsx(styles['scroll-back-button'], { [styles['hidden']]: !canScrollBack })}
        variant="secondary"
        icon={<ChevronLeftRoundedIcon />}
        ariaLabel={showMoreLabel}
        onClick={handleScrollBack}
      />
      <ToolButton
        className={clsx(styles['scroll-button'], { [styles['hidden']]: !canScroll })}
        variant="secondary"
        icon={<ChevronRightRoundedIcon />}
        ariaLabel={showMoreLabel}
        onClick={handleScroll}
      />
    </div>
  )
}
