import { useLocale } from 'shared/util-intl'
import { SavableRouteState, reset } from '../../state'
import { useCallback } from 'react'
import { RouteGeometryChangeForm } from 'shared/data-access-core'
import { RouteSliceDispatch, copyRoute } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { BaseRouteAction } from './base-route-action'
import { useNavigateToRouteDetails } from 'web-app/feature-navigation'

interface CopyRouteActionProps {
  primary?: boolean
}

export const CopyRouteAction = (props: CopyRouteActionProps) => {
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()
  const dispatch = useDispatch() as RouteSliceDispatch

  const showRouteSaveErrorNotification = useCallback(() => {
    showCommonErrorNotification(
      intl.formatMessage({
        id: 'route_planner_copy_route_action_error',
        defaultMessage: 'The route copy could not be saved.',
      }),
    )
  }, [intl, showCommonErrorNotification])

  const handleRouteSubmit = useCallback(
    async ({ geometry, distanceMeters, durationSeconds, waypoints, controlPointIndexes }: SavableRouteState) => {
      try {
        const changes: RouteGeometryChangeForm = {
          geometry,
          distanceMeters,
          durationSeconds: durationSeconds || undefined,
          waypoints,
          controlPointIndexes,
        }
        const data = await dispatch(copyRoute(changes))
        if (data?.route) {
          dispatch(reset())
          navigateToRouteDetails(data.route.id)
        }
      } catch {
        showRouteSaveErrorNotification()
      }
    },
    [dispatch, navigateToRouteDetails, showRouteSaveErrorNotification],
  )

  return (
    <BaseRouteAction {...props} onRouteSubmit={handleRouteSubmit}>
      {intl.formatMessage({
        id: 'route_planner_copy_route_action_label',
        defaultMessage: 'Save copy',
      })}
    </BaseRouteAction>
  )
}
