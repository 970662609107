import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import {
  useRouteImportState,
  useSelectedRouteGeometry,
  useSelectedRouteDistance,
  stepPrev,
  reset,
  saveRouteFailure,
} from '../state'
import { useEffect } from 'react'
import { ROUTE_TITLE_MAX_LENGTH, ROUTE_TITLE_MIN_LENGTH, RouteForm } from 'shared/data-access-core'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { RouteSliceDispatch, saveRoute } from 'web-app/feature-route'
import { WebAppContentLoading, WebAppSheet } from 'web-app/ui-layout'
import { positionToLngLat } from 'shared/util-geo'
import { ToolHeader, useCancel, useNavigateToRouteDetails } from 'web-app/feature-navigation'

const RouteImportStepSave = () => {
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()
  const dispatch = useDispatch() as RouteSliceDispatch
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const cancel = useCancel()

  const { isUploadInProgress, title, description } = useRouteImportState()
  const selectedRouteGeometry = useSelectedRouteGeometry()
  const selectedRouteDistance = useSelectedRouteDistance()

  useEffect(() => {
    if (isUploadInProgress || !selectedRouteGeometry) return
    const routeForm: RouteForm = {
      geometry: selectedRouteGeometry,
      title:
        title && title.length >= ROUTE_TITLE_MIN_LENGTH
          ? title.length > ROUTE_TITLE_MAX_LENGTH
            ? title.slice(0, ROUTE_TITLE_MAX_LENGTH - 3) + '...'
            : title
          : intl.formatMessage({
              id: 'route_import_save_route_fallback_title',
              defaultMessage: 'Imported route',
            }),
      description: description || undefined,
      appVersion: 'Import',
      distanceMeters: selectedRouteDistance,
      waypoints: [
        { ...positionToLngLat(selectedRouteGeometry.coordinates[0]), controlPointIndex: 0 },
        {
          ...positionToLngLat(selectedRouteGeometry.coordinates[selectedRouteGeometry.coordinates.length - 1]),
          controlPointIndex: 1,
        },
      ],
      controlPointIndexes: [0, selectedRouteGeometry.coordinates.length - 1],
    }
    dispatch(saveRoute(routeForm))
      .unwrap()
      .then(({ route }) => {
        dispatch(reset())
        navigateToRouteDetails(route.id)
      })
      .catch(() => {
        dispatch(saveRouteFailure())
        showCommonErrorNotification(
          intl.formatMessage({
            id: 'route_import_save_route_error',
            defaultMessage: 'The route could not be saved.',
          }),
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadInProgress])

  const onCancel = () => {
    dispatch(reset())
    cancel()
  }

  return (
    <>
      <ToolHeader
        title={intl.formatMessage({
          id: 'route_import_title_step_save',
          defaultMessage: 'Saving your route',
        })}
        onBack={() => dispatch(stepPrev())}
        onCancel={onCancel}
      />
      <WebAppSheet>
        <WebAppContentLoading />
      </WebAppSheet>
    </>
  )
}

export default RouteImportStepSave
