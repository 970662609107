import { MapLayerMouseEvent } from 'react-map-gl/maplibre'
import { useCallback, useState } from 'react'
import { useMapClick, useMapContextMenu, useMapLongPress, useMapMouseMove } from 'shared/ui-map'
import { useLocale } from 'shared/util-intl'
import { MapLayerTouchEvent } from 'maplibre-gl'
import { MAP_ID } from './settings'
import { isSelectableFeature } from './helpers'
import { LngLat } from 'shared/util-geo'
import { useWebAppMap } from './use-web-app-map'

export type SelectedMapLocation = {
  position: LngLat
  address?: string
  poiName?: string
  country?: string
  admin1?: string
}

export const useMapLocationSelection = (
  onMapLocationSelect: (location: SelectedMapLocation) => void,
  onClickOutside: (event: MapLayerMouseEvent) => void,
  active = true,
) => {
  const map = useWebAppMap()
  const { intl } = useLocale()

  const [isFeatureHovered, setIsFeatureHovered] = useState<boolean>(false)

  const getPoiNameFromClass = useCallback(
    (featureClass: string) => {
      switch (featureClass) {
        case 'toilets':
          return intl.formatMessage({
            id: 'poi_name_toilets',
            defaultMessage: 'Toilets',
          })
        default:
          return intl.formatMessage({
            id: 'poi_name_fallback',
            defaultMessage: 'Point of interest',
          })
      }
    },
    [intl],
  )

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const currentMap = map?.getMap()
      if (!currentMap) return

      if (!active) {
        onClickOutside(event)
        return
      }

      const features = currentMap.queryRenderedFeatures(event.point)
      for (const feature of features) {
        if (isSelectableFeature(feature)) {
          const [lng, lat] = feature.geometry.coordinates
          const { name, address } = feature.properties
          const poiName = name || getPoiNameFromClass(feature.properties.class)
          onMapLocationSelect({
            position: { lng, lat },
            address,
            poiName,
          })
          return
        }
      }

      onClickOutside(event)
    },
    [active, getPoiNameFromClass, map, onClickOutside, onMapLocationSelect],
  )

  const handleMapContextMenu = useCallback(
    (event: MapLayerMouseEvent | MapLayerTouchEvent) => {
      if (active) {
        onMapLocationSelect({
          position: { ...event.lngLat },
        })
      }
    },
    [active, onMapLocationSelect],
  )

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!map) return

      if (!active) {
        if (isFeatureHovered) {
          setIsFeatureHovered(false)
          map.getCanvas().style.cursor = ''
        }
        return
      }

      const features = map.getMap().queryRenderedFeatures(event.point)
      for (const feature of features) {
        if (isSelectableFeature(feature)) {
          if (!isFeatureHovered) {
            setIsFeatureHovered(true)
            map.getCanvas().style.cursor = 'pointer'
          }
          return
        }
      }

      if (isFeatureHovered) {
        setIsFeatureHovered(false)
        map.getCanvas().style.cursor = ''
      }
    },
    [active, isFeatureHovered, map],
  )

  useMapClick(MAP_ID, handleMapClick)
  useMapContextMenu(MAP_ID, handleMapContextMenu)
  useMapLongPress(MAP_ID, handleMapContextMenu)
  useMapMouseMove(MAP_ID, handleMouseMove)
}
