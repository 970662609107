import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#map-marker-photo-filter)">
      <path
        fill="#000"
        fillOpacity=".15"
        d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"
      />
    </g>
    <path
      fill="url(#map-marker-photo-gradient)"
      stroke="#fff"
      strokeWidth="1.5"
      d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"
    />
    <path
      fill="#fff"
      d="M23.5 18.063c1.514-1.334 2.642-2.574 3.386-3.72.742-1.145 1.114-2.218 1.114-3.218 0-1.57-.503-2.816-1.51-3.74C25.483 6.462 24.32 6 23 6c-1.32 0-2.483.462-3.49 1.385-1.007.924-1.51 2.17-1.51 3.74 0 1 .372 2.073 1.115 3.218.743 1.146 1.87 2.386 3.385 3.72a.722.722 0 0 0 .5.187.722.722 0 0 0 .5-.188ZM18 19.75a2.011 2.011 0 0 1-1.48-.604 2.011 2.011 0 0 1-.604-1.48c0-.555.188-1.034.563-1.437.375-.403.868-.618 1.48-.646.36.5.683.924.968 1.271.285.347.656.75 1.114 1.209-.083.486-.319.888-.708 1.208-.389.32-.833.479-1.333.479Zm-6.667 4.583c-.458 0-.85-.163-1.177-.489a1.606 1.606 0 0 1-.49-1.177v-10c0-.459.164-.85.49-1.177.327-.327.719-.49 1.177-.49h2.646l1.02-1.125c.154-.18.337-.316.553-.407.215-.09.44-.135.677-.135h.313c-.07.278-.122.58-.157.907a9.034 9.034 0 0 0-.052.947c0 .487.07.959.209 1.417.138.459.312.938.52 1.438-.82.18-1.493.61-2.02 1.291a3.705 3.705 0 0 0-.792 2.334c0 1.041.364 1.927 1.093 2.656.73.73 1.615 1.094 2.657 1.094.722 0 1.385-.188 1.99-.563a3.568 3.568 0 0 0 1.364-1.52c.194.152.382.312.563.479.18.166.368.326.562.479.139.11.31.17.51.177a.83.83 0 0 0 .532-.157c.5-.402.982-.83 1.448-1.281.465-.451.92-.906 1.364-1.364v5c0 .458-.163.85-.489 1.177-.327.326-.72.49-1.178.49H11.333Zm10.958-11.458c-.18.139-.33.163-.447.072-.118-.09-.143-.239-.073-.447l.27-.896-.729-.583c-.194-.153-.264-.299-.208-.438.056-.139.201-.208.438-.208h.854l.291-.875a1 1 0 0 1 .136-.26.217.217 0 0 1 .177-.094c.021 0 .06.03.113.093.052.06.118.146.2.261l.291.875h.854c.236 0 .379.07.428.208.048.14-.018.285-.199.438l-.729.583.271.896c.07.208.045.357-.072.447-.119.091-.268.067-.449-.072L23 12.333l-.709.542Z"
    />
    <defs>
      <linearGradient
        id="map-marker-photo-gradient"
        x1="27.44"
        x2="-1.476"
        y1="6.126"
        y2="29.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1FC5D8" />
        <stop offset="1" stopColor="#168A97" />
      </linearGradient>
      <filter
        id="map-marker-photo-filter"
        width="34.666"
        height="18.667"
        x=".667"
        y="38.667"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2540_33204" stdDeviation="2" />
      </filter>
    </defs>
  </svg>
)

export default svg
