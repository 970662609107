import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { LngLatBoundsArray } from 'shared/util-geo'
import { RouteEntity, UserEntity } from 'shared/data-access-core'
import { StateWithIntlSlice } from 'shared/util-intl'
import { StateWithMapSlice } from 'web-app/feature-map'
import { StateWithUserSlice } from 'web-app/feature-user'
import { StateWithEntitiesSlice } from 'web-app/data-access-entities'

export const DISCOVER_SLICE_KEY = 'discover'

export type DiscoverState = {
  isSearching: boolean
  searchRequestCounter: number
  results: number[] | null
  count: number | null
  isLoadingMore: boolean
  lastMapBounds: LngLatBoundsArray | null
  areResultsOffScreen: boolean
}

export type ResultsListItemState = {
  key: string
  route: RouteEntity
  creator?: UserEntity
}

export interface StateWithDiscoverSlice {
  [DISCOVER_SLICE_KEY]: DiscoverState
}

export type DiscoverSliceDispatch = ThunkDispatch<
  StateWithDiscoverSlice & StateWithMapSlice & StateWithIntlSlice & StateWithUserSlice & StateWithEntitiesSlice,
  undefined,
  UnknownAction
>
