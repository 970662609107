import { ReactNode } from 'react'

import styles from './web-app-header.module.scss'

interface WebAppHeaderProps {
  children: ReactNode
}

export const WebAppHeader = ({ children }: WebAppHeaderProps) => {
  return <header className={styles['container']}>{children}</header>
}

export const WebAppHeaderItems = ({ children }: { children: ReactNode }) => (
  <div className={styles['items']}>{children}</div>
)

export const WebAppHeaderExtraItems = ({ children }: { children: ReactNode }) => (
  <div className={styles['extra-items']}>{children}</div>
)
