import nearestPointOnLine from '@turf/nearest-point-on-line'
import { LineString, MultiPoint } from 'geojson'
import { Waypoints, positionToLngLat } from 'shared/util-geo'
import { UserEntity } from '../../entities'
import { ResponseParser } from 'shared/util-network'

/**
 * Up to this number, waypoints of existing routes are treated like actual waypoints. Reason: Older routes can have
 * ridiculous amounts of waypoints, which have actually just been used as control points.
 */
export const MAX_REASONABLE_WAYPOINTS = 20

export function getWaypointsAndControlPointIndexes(
  geometry: LineString,
  resWaypoints: MultiPoint | null,
  resControlPointIndexes: number[] | null,
): {
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
} {
  if (resWaypoints && areWaypointsValid(resWaypoints)) {
    if (areControlPointIndexesValid(resControlPointIndexes, geometry, resWaypoints)) {
      return {
        waypoints: resWaypoints.coordinates.map(([lng, lat, controlPointIndex]) => ({
          lng,
          lat,
          controlPointIndex,
        })) as Waypoints,
        controlPointIndexes: resControlPointIndexes,
      }
    }
    const waypointCoordinates = resWaypoints.coordinates
    return {
      waypoints: waypointCoordinates.map(([lng, lat], i) => ({ lng, lat, controlPointIndex: i })) as Waypoints,
      controlPointIndexes: waypointCoordinates.map((waypoint, i) => {
        if (i === 0) return 0
        if (i === waypointCoordinates.length - 1) return geometry.coordinates.length - 1
        return nearestPointOnLine(geometry, waypoint).properties.index ?? i
      }) as [number, number, ...number[]],
    }
  }
  return {
    waypoints: [
      { ...positionToLngLat(geometry.coordinates[0]), controlPointIndex: 0 },
      { ...positionToLngLat(geometry.coordinates[geometry.coordinates.length - 1]), controlPointIndex: 1 },
    ],
    controlPointIndexes: [0, geometry.coordinates.length - 1],
  }
}

function areWaypointsValid(resWaypoints: MultiPoint): boolean {
  const waypointsLength = resWaypoints.coordinates.length
  return waypointsLength >= 2 && waypointsLength <= MAX_REASONABLE_WAYPOINTS
}

function areControlPointIndexesValid(
  resIndexes: number[] | null,
  geometry: LineString,
  resWaypoints: MultiPoint,
): resIndexes is [number, number, ...number[]] {
  return !!(
    resIndexes &&
    resIndexes.length >= 2 &&
    resIndexes[0] === 0 &&
    resIndexes[resIndexes.length - 1] === geometry.coordinates.length - 1 &&
    resIndexes.length >= resWaypoints.coordinates.length
  )
}

type ValidUserResponse = {
  id: number
  displayname: string
  is_subscribed: boolean
  image: {
    fallback: string
    small: string | null
  } | null
  slug: string
}

/**
 * Parse the inlined user object that comes with some route responses.
 * It can for some reason be an object with empty properties, in case the user doesn't exist anymore.
 */
export function parseUserEntity(
  res:
    | ValidUserResponse
    | {
        displayname: ''
        slug: ''
      }
    | null,
): UserEntity | null {
  if (!res || !res.displayname || !res.slug) return null
  const parser = new ResponseParser(res as ValidUserResponse)
  return {
    id: parser.requireNumber('id'),
    name: parser.requireString('displayname'),
    isPremium: parser.requireBoolean('is_subscribed'),
    avatar: parser.takeImageSizes('image', {
      small: 'small',
    }),
    slug: parser.requireString('slug'),
  }
}
