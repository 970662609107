import {
  RouteEntity,
  RouteEntityAnalyticsData,
  RouteEntityDetails,
  RouteEntityMapData,
  UserEntity,
} from 'shared/data-access-core'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { StateWithUserSlice } from 'web-app/feature-user'
import { StateWithEntitiesSlice } from 'web-app/data-access-entities'

export const ROUTE_SLICE_KEY = 'route'

export type RouteState = {
  /** When this is false, the route state is either empty or cached and can be outdated / inconsistent */
  isRouteLoaded: boolean

  /** Whether the route is either private or can't be accessed for another reason */
  isRouteUnavailable: boolean

  /** The main route entity of the app's global state */
  route?: RouteEntity & RouteEntityDetails & RouteEntityMapData & RouteEntityAnalyticsData

  /** User who created the main route entity of the app's global state */
  creator?: UserEntity | null

  /** IDs of route collections assigned to the main route entity of the app's global state */
  assignedRouteCollectionIds?: number[]
}

export interface StateWithRouteSlice {
  [ROUTE_SLICE_KEY]: RouteState
}

export type RouteSliceDispatch = ThunkDispatch<
  StateWithRouteSlice & StateWithUserSlice & StateWithEntitiesSlice,
  undefined,
  UnknownAction
>
