import { LngLat, lngLatToPosition2d } from 'shared/util-geo'
import { API_PATH_ROUTE_POIS } from '../../config'
import { RoutePoiEntity, RoutePoiType } from '../../entities'
import { postToCoreApi } from '../../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import { RoutePoiResponse, convertToRoutePoiEntity } from './shared-response'

export type RoutePoiForm = {
  routeId: number
  position: LngLat
  type: RoutePoiType
  description: string
  image?: File
}

type RequestBody = {
  route: number
  lng_lat: string
  text: string
  poi_class: string
  image: File | ''
}

export async function createRoutePoi(form: RoutePoiForm): ApiResult<RoutePoiEntity> {
  try {
    const { routeId, position, type, description, image } = form

    const body: RequestBody = {
      route: routeId,
      lng_lat: JSON.stringify({
        type: 'Point',
        coordinates: lngLatToPosition2d(position),
      }),
      text: description,
      poi_class: 'poi-' + type,
      image: image || '',
    }

    const res: RoutePoiResponse = await postToCoreApi(API_PATH_ROUTE_POIS, {
      body,
    })
    try {
      return createSuccessResult(convertToRoutePoiEntity(res))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { form })
  }
}
