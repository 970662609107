import { ElevationCurveProvider } from 'shared/feature-elevation-curve'
import { ErrorBoundary, ErrorBoundaryFallback, ViewNotFound } from 'web-app/utils-error-handling'
import { useUserState } from 'web-app/feature-user'
import { RouteSliceDispatch, useRouteById, useRouteState, useRouteTitle } from 'web-app/feature-route'
import { reset } from '../state'
import { useDispatch } from 'react-redux'
import { RouteDetailsAdditionalContent } from './route-details-additional-content'
import { RouteDetailsSheet } from './route-details-sheet'
import { RouteDetailsMap } from './route-details-map'
import { RoutePoisProvider } from 'shared/feature-route-pois'
import { DefaultHeader, RouteDetailsPreviousView } from 'web-app/feature-navigation'
import { RouteDetailsMainActions } from './route-details-main-actions'
import { WebAppMapControls, WebAppMapFooter, WebAppMapLayout } from 'web-app/feature-map'
import { useRouteCollectionById } from 'web-app/feature-route-collection'
import { useMemo } from 'react'
import { getRouteCollectionPath } from 'shared/util-navigation'

interface CollectionRouteDetailsProps {
  routeId: number
  routeCollectionId: number
}

export function CollectionRouteDetails({ routeId, routeCollectionId }: CollectionRouteDetailsProps) {
  const dispatch = useDispatch() as RouteSliceDispatch
  const { unitPreference } = useUserState()
  const route = useRouteById(routeId)
  const { isRouteUnavailable } = useRouteState()

  const routeCollection = useRouteCollectionById(routeCollectionId)

  const previousView = useMemo<RouteDetailsPreviousView | undefined>(
    () =>
      routeCollection && {
        path: getRouteCollectionPath(routeCollectionId),
        label: routeCollection.title,
      },
    [routeCollection, routeCollectionId],
  )

  useRouteTitle()

  return isRouteUnavailable ? (
    <ViewNotFound />
  ) : (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          beforeReload={async () => {
            dispatch(reset())
          }}
        />
      }
    >
      <ElevationCurveProvider geometry={route?.geometry} unitPreference={unitPreference}>
        <RoutePoisProvider routeId={routeId}>
          <WebAppMapLayout>
            <DefaultHeader />
            <RouteDetailsSheet previousView={previousView} contextRouteCollectionId={routeCollectionId} />
            {route && <RouteDetailsMainActions routeId={route.id} />}
            <RouteDetailsAdditionalContent />
            <RouteDetailsMap routeId={routeId} withCollectionContext />
            <WebAppMapControls />
            <WebAppMapFooter />
          </WebAppMapLayout>
        </RoutePoisProvider>
      </ElevationCurveProvider>
    </ErrorBoundary>
  )
}
