import { Polygon } from 'geojson'
import { LngLat, Waypoints, LngLatBoundsArray, RichLineString, richDummyGeometry } from 'shared/util-geo'

export const ROUTE_TITLE_MIN_LENGTH = 5
export const ROUTE_TITLE_MAX_LENGTH = 160

export enum RouteSurface {
  Paved = 1,
  Unpaved = 2,
  Gravel = 3,
  Ground = 4,
}

export enum RouteBikeType {
  RoadBike = 1,
  Mtb = 2,
  CityBike = 3,
}

export const ROUTE_SURFACE_TAGS = ['paved_all', 'unpaved_all', 'gravel', 'ground'] as const

export type RouteSurfaceTag = (typeof ROUTE_SURFACE_TAGS)[number]

type RouteImageSizes = {
  item: string
  tile: string
  original: string
}

type StaticMapSizes = {
  item: string
  tile: string
  tileSecondary: string
  overview: string
  medium: string
}

export type RouteEntity<ImageSizes extends Record<string, string> = RouteImageSizes> = {
  ascentMeters: number | null
  averageSpeedMetersPerSecond: number | null
  bikeTypes: RouteBikeType[]
  created: number
  creatorId: number | null
  descentMeters: number | null
  distanceMeters: number
  durationSeconds: number | null
  favoriteCount: number
  id: number
  images: ImageSizes[]
  isFavorite: boolean
  isLoop: boolean
  isPrivate: boolean
  location: {
    geonameId: number
    name: string
  } | null
  maximumElevationMeters: number | null
  start: LngLat
  staticMap: StaticMapSizes | null
  surfaces: RouteSurface[]
  title: string
}

export const dummyRouteEntity: RouteEntity = {
  id: 2692507,
  title: 'Nibelungengasse 5, Vienna to Große Sperlgasse 37A, Vienna',
  location: {
    geonameId: 32843,
    name: 'Wien, Wien, Österreich',
  },
  bikeTypes: [RouteBikeType.RoadBike, RouteBikeType.CityBike],
  surfaces: [RouteSurface.Paved],
  distanceMeters: 577.4454388991489,
  ascentMeters: 2662,
  descentMeters: 2512,
  durationSeconds: 122,
  averageSpeedMetersPerSecond: 4.733159335238926,
  maximumElevationMeters: 113,
  isPrivate: false,
  isLoop: false,
  start: { lng: 16.318814, lat: 48.234703 },
  staticMap: {
    item: '/preview',
    tile: '/bmx-big',
    tileSecondary: '/small',
    overview: '/bmx-big',
    medium: '/bmx-big',
  },
  isFavorite: false,
  favoriteCount: 6,
  created: 1679676291000,
  creatorId: 12,
  images: [
    {
      item: '/item1',
      tile: '/tile1',
      original: '/fallback1',
    },
    {
      item: '/item2',
      tile: '/tile2',
      original: '/fallback2',
    },
  ],
}

export const dummyRouteEntityCreatedString = '2023-03-24T16:44:51Z'

export type RouteEntityOverview = {
  descriptionExcerpt: string | null
}

export type RouteOverviewImageSizes = RouteImageSizes & {
  overviewSmall: string
  overviewLarge: string
}

export const dummyRouteEntityOverview: RouteEntity<RouteOverviewImageSizes> & RouteEntityOverview = {
  ...dummyRouteEntity,
  descriptionExcerpt: 'Lorem ipsum dolor',
  images: [
    {
      item: '/item1',
      tile: '/tile1',
      overviewSmall: '/overview-small1',
      overviewLarge: '/overview-large1',
      original: '/fallback1',
    },
    {
      item: '/item2',
      tile: '/tile2',
      overviewSmall: '/overview-small2',
      overviewLarge: '/overview-large2',
      original: '/fallback2',
    },
  ],
}

export type RouteEntityDetails = {
  description: string | null
  gpxUrl: string | null
  kmlUrl: string | null
  hasPois: boolean
  copiedFrom: number | null
}

export const dummyRouteEntityDetails: RouteEntityDetails = {
  description: 'Lorem ipsum dolor sit amet',
  gpxUrl: '/gpx',
  kmlUrl: '/kml',
  hasPois: false,
  copiedFrom: null,
}

export type RouteEntityAnalyticsData = {
  externalId: string | null
}

export const dummyRouteEntityAnalyticsData: RouteEntityAnalyticsData = {
  externalId: '69e77c75-7e51-46e4-83bf-0a215fea6492',
}

export type RouteEntityMapData = {
  geometry: RichLineString
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
  bounds: LngLatBoundsArray
}

export const dummyRouteEntityMapData: RouteEntityMapData = {
  geometry: richDummyGeometry,
  waypoints: [
    { lng: 17.121, lat: 49.112, controlPointIndex: 0 },
    { lng: 17.113, lat: 49.115, controlPointIndex: 1 },
  ],
  controlPointIndexes: [0, 2],
  bounds: [13.82176, 48.00401, 13.93778, 48.02431],
}

export const dummyRouteEntityBoundsPolygon: Polygon = {
  type: 'Polygon',
  coordinates: [
    [
      [13.82176, 48.00401],
      [13.93778, 48.00401],
      [13.93778, 48.02431],
      [13.82176, 48.02431],
      [13.82176, 48.00401],
    ],
  ],
}

export type RouteEntitySEOData = {
  shouldBeIndexed: boolean
}

export const dummyRouteEntitySEOData: RouteEntitySEOData = {
  shouldBeIndexed: true,
}
