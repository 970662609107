import { Campaign } from './types'

import christmasGift from './christmas-gift'
import christmasPremium from './christmas-premium'
import spring2025 from './spring-2025'

const CAMPAIGNS = [spring2025, christmasPremium, christmasGift]

const now = new Date()
const currentCampaigns: Campaign[] =
  CAMPAIGNS.filter(({ start, end }) => new Date(start) < now && new Date(end) > now) || null

export default currentCampaigns
