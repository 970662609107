import { has } from 'lodash'
import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { simplifyRouteGeometry } from 'shared/data-access-core'
import { useRouteImportState, routeSimplifySuccess, routeSimplifyFailure } from '../state'

/**
 * Fetches simplified route data if original route data is present - on simplify step.
 */
export const useRouteSimplificationSimplifyStep = (): void => {
  const dispatch = useDispatch()
  const { routeData, simplifiedRouteData } = useRouteImportState()

  const init = useCallback(async () => {
    if (routeData && has(routeData, 'geometry.coordinates') && !simplifiedRouteData) {
      const result = await simplifyRouteGeometry(routeData.geometry)
      if (result.success) {
        dispatch(routeSimplifySuccess(result.data))
      } else {
        dispatch(routeSimplifyFailure())
      }
    }
  }, [routeData, simplifiedRouteData, dispatch])

  useEffect(() => {
    init()
  }, [init])
}

/**
 * Fetches simplified route data if original route data is present - on matching step.
 */
export const useRouteSimplificationMatchingStep = (): [boolean] => {
  const dispatch = useDispatch()
  const { routeData, simplifiedRouteData, isMatchingSelected, isSimplifiedRouteMissing } = useRouteImportState()

  // When raw/original route is selected, and we didn't yet fetch simplified route data
  const shouldFetchGeometrySimplified = !isMatchingSelected && !simplifiedRouteData && !isSimplifiedRouteMissing

  const init = useCallback(async () => {
    if (routeData && has(routeData, 'geometry.coordinates') && shouldFetchGeometrySimplified) {
      const result = await simplifyRouteGeometry(routeData.geometry)
      if (result.success) {
        dispatch(routeSimplifySuccess(result.data))
      } else {
        dispatch(routeSimplifyFailure())
      }
    }
  }, [routeData, shouldFetchGeometrySimplified, dispatch])

  useEffect(() => {
    init()
  }, [init])

  return [shouldFetchGeometrySimplified]
}
