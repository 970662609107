import { useDispatch } from 'react-redux'
import { ErrorBoundary, ErrorBoundaryFallback } from 'web-app/utils-error-handling'
import { useRequireLogin } from 'web-app/feature-user'
import { useRouteImportState, reset } from '../state'
import StepUpload from './step-upload'
import StepMatching from './step-matching'
import StepSimplify from './step-simplify'
import StepSave from './step-save'
import { RouteImportMap } from './route-import-map'
import { WebAppMapControls, WebAppMapFooter, WebAppMapLayout } from 'web-app/feature-map'

const RouteImportStep = () => {
  const [isLoggedIn] = useRequireLogin()
  const { step } = useRouteImportState()

  return (
    <WebAppMapLayout initialFold={step === 'upload' ? 'full' : 'default'}>
      {isLoggedIn &&
        (step === 'upload' ? (
          <StepUpload />
        ) : step === 'matching' ? (
          <StepMatching />
        ) : step === 'simplify' ? (
          <StepSimplify />
        ) : step === 'save' ? (
          <StepSave />
        ) : null)}
      <RouteImportMap />
      <WebAppMapControls />
      <WebAppMapFooter />
    </WebAppMapLayout>
  )
}

const RouteImport = () => {
  const dispatch = useDispatch()
  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          beforeReload={async () => {
            dispatch(reset())
          }}
        />
      }
    >
      <RouteImportStep />
    </ErrorBoundary>
  )
}

export default RouteImport
