import { PremiumBadgeIcon } from 'shared/ui-design-system/icons/premium-badge-icon'

import styles from './premium-modal-header.module.scss'

interface PremiumModalHeaderProps {
  image: {
    fallback: string
    webp: string
    avif: string
    alt: string
  }
  badge?: string
  badgeColor?: string
}

export const PremiumModalHeader = ({ image, badge, badgeColor }: PremiumModalHeaderProps) => (
  <header className={styles['container']}>
    <picture>
      <source srcSet={image.avif} type="image/avif" />
      <source srcSet={image.webp} type="image/webp" />
      <img src={image.fallback} alt={image.alt} width="380" height="256" />
    </picture>
    {badge && (
      <div className={styles['badge']} style={badgeColor ? { backgroundColor: badgeColor } : undefined}>
        {badge}
      </div>
    )}
    <PremiumBadgeIcon className={styles['premium-badge']} />
  </header>
)
