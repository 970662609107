import React, { createContext, useContext, useState } from 'react'
import { logError } from 'shared/util-error-handling'

type EditableContentContextValues = {
  editingField: string | null
  onEditingStarted: (name: string) => void
  onEditingDone: () => void
}

const EditableContentContext = createContext<EditableContentContextValues>({
  editingField: null,
  onEditingStarted: () => logError('Editable content context handlers not yet available'),
  onEditingDone: () => logError('Editable content context handlers not yet available'),
})

interface EditableContentProviderProps {
  children: React.ReactNode
}

/**
 * Shared context for editable content fields. Only one field within this provider can be edited at a time.
 */
export function EditableContentProvider({ children }: EditableContentProviderProps) {
  const [editingField, setEditingField] = useState<string | null>(null)

  return (
    <EditableContentContext.Provider
      value={{
        editingField,
        onEditingStarted: setEditingField,
        onEditingDone: () => setEditingField(null),
      }}
    >
      {children}
    </EditableContentContext.Provider>
  )
}

/**
 * Get context for editable content.
 */
export const useEditableContentContext = (): EditableContentContextValues => {
  const context = useContext(EditableContentContext)
  if (!context) {
    logError('useEditableContentContext must be used inside EditableContentProvider')
  }
  return context
}

/**
 * Derived context flags for a certain editable content field
 */
export const useIsEditingFlags = (name: string) => {
  const { editingField } = useContext(EditableContentContext)
  const isEditing = editingField === name
  return {
    isEditing,
    isEditingAnotherField: !isEditing && editingField !== null,
  }
}
