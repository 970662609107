import { useDispatch } from 'react-redux'
import { PrintMap } from './print-map'
import { detailedMapRendered, useRoutePrintState } from '../state'

interface DetailedMapProps {
  mapKey: string
}

export const DetailedMap = ({ mapKey }: DetailedMapProps) => {
  const dispatch = useDispatch()
  const { detailedMapViewports, detailedMapImages } = useRoutePrintState()

  return (
    <PrintMap
      id={'detailed-map-' + mapKey}
      onRender={(image, viewport) => dispatch(detailedMapRendered({ mapKey, image, viewport }))}
      mapImage={detailedMapImages[mapKey]}
      initialViewport={detailedMapViewports[mapKey]}
      interactive
    />
  )
}
