import { useState } from 'react'
import { showNotification } from 'web-app/feature-notifications'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { Button } from 'shared/ui-components'
import { CopyIcon } from 'shared/ui-design-system/icons/copy-icon'
import { EmbedOptions, EmbedOptionsForm } from './embed-options-form'
import { EmbedPreview } from './embed-preview'
import { copyToClipboard } from 'shared/util-navigation'

import styles from './embed-configurator.module.scss'

export const EmbedConfigurator = () => {
  const { intl } = useLocale()

  const [options, setOptions] = useState<EmbedOptions>({
    extended: true,
    distanceMarkers: false,
    width: 640,
    height: 480,
  })
  const [html, setHtml] = useState<string>('')

  const handleCopyClick = () => {
    copyToClipboard(html)
    showNotification(
      {
        title: intl.formatMessage({
          id: 'embed_configurator_copied_to_clipboard',
          defaultMessage: 'HTML copied to clipboard',
        }),
        description: intl.formatMessage({
          id: 'embed_configurator_copied_to_clipboard_description',
          defaultMessage: 'You can now paste it into the source code of your website.',
        }),
        variant: 'success',
      },
      'success_route_embed_html_copied',
    )
  }

  return (
    <div className={styles['container']}>
      <div className={styles['controls']}>
        <Typography variant="h3" marginBottom={0}>
          {intl.formatMessage({
            id: 'embed_configurator_controls_heading',
            defaultMessage: 'Embed code',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'embed_configurator_controls_description',
            defaultMessage:
              'Embed this code to provide an interactive route preview on your website or blog, allowing your readers to access detailed information and customization options through Bikemap.',
          })}
        </Typography>
        <div className={styles['options']}>
          <Typography variant="h4">
            {intl.formatMessage({
              id: 'embed_configurator_options_heading',
              defaultMessage: 'Customization options',
            })}
          </Typography>
          <EmbedOptionsForm value={options} onChange={setOptions} />
        </div>
        <div className={styles['code']}>
          <code>{html}</code>
        </div>
        <Button block icon={<CopyIcon />} onClick={handleCopyClick}>
          {intl.formatMessage({
            id: 'embed_configurator_copy_code',
            defaultMessage: 'Copy embed code',
          })}
        </Button>
      </div>
      <div className={styles['preview']}>
        <Typography variant="h3" color="onColor">
          {intl.formatMessage({
            id: 'embed_configurator_preview_heading',
            defaultMessage: 'Embed preview',
          })}
        </Typography>
        <EmbedPreview options={options} onHtmlChange={setHtml} />
      </div>
    </div>
  )
}
