import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { formatAnalyticsData } from 'shared/util-analytics'
import { Button, FieldSet, MassiveRadioButton } from 'shared/ui-components'
import { WebAppContent, WebAppMainActions, WebAppSheet } from 'web-app/ui-layout'
import { useRouteImportState, mapMatchingChanged, stepPrev, stepNext, reset } from '../state'
import { useRouteSimplificationMatchingStep } from './use-route-simplification'
import ImageMatchingYes from './images/route-preview-matching-yes.svg'
import ImageMatchingYesCompact from './images/route-preview-matching-yes-compact.svg'
import ImageMatchingNo from './images/route-preview-matching-no.svg'
import ImageMatchingNoCompact from './images/route-preview-matching-no-compact.svg'
import { ToolHeader, useCancel } from 'web-app/feature-navigation'

const RouteImportStepMatching = () => {
  const { intl } = useLocale()
  const dispatch = useDispatch()
  const cancel = useCancel()
  const { simplifiedRouteData, isMatchingSelected } = useRouteImportState()
  const [shouldFetchGeometrySimplified] = useRouteSimplificationMatchingStep()

  const optionPropsYes = {
    name: 'route-import-matching-option',
    label: intl.formatMessage({
      id: 'route_import_label_match_option_yes',
      defaultMessage: 'For routes along roads and paths',
    }),
    description: intl.formatMessage({
      id: 'route_import_match_option_yes',
      defaultMessage:
        'Match your imported route to Bikemap to enable online and offline navigation instructions in the app.',
    }),
    checked: isMatchingSelected,
    onChange: () => dispatch(mapMatchingChanged(true)),
    analytics: formatAnalyticsData({
      event: 'map_matching_choice',
      'map-matching': 'true',
    }),
  }

  const optionPropsNo = {
    name: 'route-import-matching-option',
    label: intl.formatMessage({
      id: 'route_import_label_match_option_no',
      defaultMessage: 'For offroad routes',
    }),
    description: intl.formatMessage({
      id: 'route_import_match_option_no',
      defaultMessage:
        "Don't match the imported file to Bikemap. Your route will keep all original trackpoints but you won't be able to get online and offline navigation instructions for this route in the app.",
    }),
    checked: !isMatchingSelected,
    onChange: () => dispatch(mapMatchingChanged(false)),
    analytics: formatAnalyticsData({
      event: 'map_matching_choice',
      'map-matching': 'false',
    }),
  }

  const importButtonLabel = intl.formatMessage({
    id: 'route_import_import_button',
    defaultMessage: 'Import',
  })

  const actionButtonLabel =
    isMatchingSelected || !simplifiedRouteData
      ? importButtonLabel
      : intl.formatMessage({
          id: 'route_import_next_step_button',
          defaultMessage: 'Next step',
        })

  const onCancel = () => {
    dispatch(reset())
    cancel()
  }

  return (
    <>
      <ToolHeader
        title={intl.formatMessage({
          id: 'route_import_title_navigation_options',
          defaultMessage: 'Navigation options',
        })}
        onBack={() => dispatch(stepPrev())}
        onCancel={onCancel}
      />
      <WebAppSheet>
        <WebAppContent
          renderLarge={() => (
            <FieldSet role="radiogroup">
              <MassiveRadioButton {...optionPropsYes} image={<ImageMatchingYes />} />
              <MassiveRadioButton {...optionPropsNo} image={<ImageMatchingNo />} />
            </FieldSet>
          )}
          renderSmall={() => (
            <>
              <Typography variant="h3">
                {intl.formatMessage({
                  id: 'route_import_step_matching_label_choose',
                  defaultMessage: 'Choose between',
                })}
              </Typography>
              <FieldSet role="radiogroup">
                <MassiveRadioButton isCompact {...optionPropsYes} image={<ImageMatchingYesCompact />} />
                <MassiveRadioButton isCompact {...optionPropsNo} image={<ImageMatchingNoCompact />} />
              </FieldSet>
            </>
          )}
        />
      </WebAppSheet>
      <WebAppMainActions>
        <Button
          block
          onClick={() => dispatch(stepNext())}
          disabled={shouldFetchGeometrySimplified}
          ariaLabel={actionButtonLabel}
          data-testid="route-import-import-button"
          data-step="matching"
        >
          {actionButtonLabel}
        </Button>
      </WebAppMainActions>
    </>
  )
}

export default RouteImportStepMatching
