export enum RouteCollectionCategory {
  Garmin = 1,
  Wahoo = 2,
  Tour = 3,
}

type RouteCollectionPreviewImageSizes = {
  item: string
  tile: string
}

export type RouteCollectionEntity = {
  category: RouteCollectionCategory | null
  created: number
  description: string | null
  id: number
  image: RouteCollectionPreviewImageSizes | null
  isPrivate: boolean
  maximumElevationMeters: number | null
  owner: number
  routesCount: number
  title: string
  totalAscentMeters: number
  totalDescentMeters: number
  totalDistanceMeters: number
  totalDurationSeconds: number
}

export const dummyRouteCollectionEntityCreatedString = '2023-03-24T16:44:51Z'

export const dummyRouteCollectionEntity: RouteCollectionEntity = {
  category: RouteCollectionCategory.Tour,
  created: 1679676291000,
  description: 'Some description...',
  id: 115,
  image: { item: '/item', tile: '/tile' },
  isPrivate: false,
  maximumElevationMeters: 340,
  owner: 12,
  routesCount: 23,
  title: 'My Route Collection',
  totalAscentMeters: 3495,
  totalDescentMeters: 3590,
  totalDistanceMeters: 43298,
  totalDurationSeconds: 7398,
}

export type RouteCollectionEntityDetails = {
  hasPrivateRoutes: boolean
  image:
    | (RouteCollectionPreviewImageSizes & {
        small: string
        smallWide: string
        large: string
        largeWide: string
        openGraph: string
      })
    | null
  shouldBeIndexed: boolean
}

export const dummyRouteCollectionEntityDetails: RouteCollectionEntityDetails = {
  hasPrivateRoutes: true,
  image: {
    ...(dummyRouteCollectionEntity.image as RouteCollectionPreviewImageSizes),
    small: '/small',
    smallWide: '/small-wide',
    large: '/large',
    largeWide: '/large-wide',
    openGraph: '/open-graph',
  },
  shouldBeIndexed: false,
}
