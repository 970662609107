import { useMemo } from 'react'
import { Feature, FeatureCollection, Position } from 'geojson'
import { Layer, Source } from 'react-map-gl/maplibre'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { useHighlightedRouteLines } from './use-highlighted-route-lines'
import { useMapImage } from '../use-map-image'
import { RichLineString, RichMultiLineString } from 'shared/util-geo'

import selectionMarkerImg from '../img/selection-marker.png'

interface MapRouteSelectionProps {
  id: string
  geometry?: RichLineString | RichMultiLineString
}

/**
 * Highligted route line(s) representing a selected section of a geometry.
 */
export const MapRouteSelection = ({ id, geometry }: MapRouteSelectionProps) => {
  useMapImage(selectionMarkerImg, 'selection-marker')

  const routeLayerBeforeId = useRouteLayerBeforeId()
  const highlightedRouteLines = useHighlightedRouteLines(
    id,
    (geometry
      ? geometry.type === 'RichLineString'
        ? [geometry.coordinates]
        : geometry.coordinates
      : []) as Position[][],
  )

  const sourceData = useMemo<FeatureCollection>(() => {
    const features: Feature[] = [...highlightedRouteLines.features]

    const firstSegment =
      geometry && (geometry.type === 'RichLineString' ? geometry.coordinates : geometry.coordinates[0])
    const lastSegment =
      geometry &&
      (geometry.type === 'RichLineString'
        ? geometry.coordinates
        : geometry.coordinates[geometry.coordinates.length - 1])

    if (firstSegment && lastSegment) {
      features.push(
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: firstSegment[0] as Position,
          },
          properties: {
            type: 'selection-point',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lastSegment[lastSegment.length - 1] as Position,
          },
          properties: {
            type: 'selection-point',
          },
        },
      )
    }

    return {
      type: 'FeatureCollection',
      features,
    }
  }, [geometry, highlightedRouteLines.features])

  return (
    <Source id={`${id}-source`} type="geojson" data={sourceData}>
      <Layer {...highlightedRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...highlightedRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer
        id={`${id}-points`}
        type="symbol"
        layout={{
          'icon-image': 'selection-marker',
          'icon-size': 0.5,
        }}
        filter={['==', ['get', 'type'], 'selection-point']}
      />
    </Source>
  )
}
