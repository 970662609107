import { Feature, Position } from 'geojson'
import { colors } from 'shared/ui-design-system'
import { LineLayer, SymbolLayer } from 'react-map-gl/maplibre'

type RouteLineVariant = 'default' | 'inactive' | 'highlight'

type LineLayerBaseProps = Omit<LineLayer, 'id' | 'source'>
type SymbolLayerProps = Omit<SymbolLayer, 'id' | 'source'>

const ROUTE_LINE_COMMON_PROPS: LineLayerBaseProps = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
}

export const ROUTE_LINE_PROPS: LineLayerBaseProps = {
  ...ROUTE_LINE_COMMON_PROPS,
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      // zoom is <=10 -> 3px
      10,
      3,
      // zoom is >=22 -> 6px
      22,
      6,
    ],
    'line-color': colors.polylineComp.foreground,
  },
}

export const ROUTE_OUTLINE_PROPS: LineLayerBaseProps = {
  ...ROUTE_LINE_COMMON_PROPS,
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      // zoom is <=10 -> 6px
      10,
      6,
      // zoom is >=22 -> 12px
      22,
      12,
    ],
    'line-color': colors.polylineComp.background,
  },
}

export const getRouteLineLayerProps = (variant: RouteLineVariant = 'default'): LineLayerBaseProps => ({
  ...ROUTE_LINE_PROPS,
  paint: {
    ...ROUTE_LINE_PROPS.paint,
    'line-color':
      variant === 'inactive'
        ? colors.polylineComp.inactive.foreground
        : variant === 'highlight'
          ? colors.polylineComp.alternate.foreground
          : colors.polylineComp.foreground,
  },
})

export const getRouteOutlineLayerProps = (variant: RouteLineVariant = 'default'): LineLayerBaseProps => ({
  ...ROUTE_OUTLINE_PROPS,
  paint: {
    ...ROUTE_OUTLINE_PROPS.paint,
    'line-color':
      variant === 'inactive'
        ? colors.polylineComp.inactive.background
        : variant === 'highlight'
          ? colors.polylineComp.alternate.background
          : colors.polylineComp.background,
  },
})

export const getRouteLineFeatures = (identifier: string, segments: Position[][]): Feature[] =>
  segments.map((coordinates, segmentIndex) => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates,
    },
    properties: {
      type: identifier,
      segmentIndex,
    },
  }))

export const ORIGIN_MARKER_PROPS: SymbolLayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': 'route-marker-destination',
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-offset': [0, 15],
    'icon-size': 0.44,
  },
  filter: ['==', ['get', 'type'], 'destination'],
}

export const VIA_MARKER_PROPS: SymbolLayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': 'location-marker',
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-offset': [0, 13],
    'icon-size': 0.196,
    'text-field': ['get', 'label'],
    'text-font': ['Roboto Medium'],
    'text-size': 11.5,
    'text-offset': [0, -1.95],
  },
  paint: {
    'text-color': colors.onNeutral.primary,
  },
  filter: ['==', ['get', 'type'], 'via'],
}

export const DESTINATION_MARKER_PROPS: SymbolLayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': 'route-marker-origin',
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-offset': [0, 10],
    'icon-size': 0.47,
  },
  filter: ['==', ['get', 'type'], 'origin'],
}
