import { useLocale } from 'shared/util-intl'
import { useRouteCollectionContentEditing } from './use-route-collection-content-editing'
import { EditablePrivacy } from 'web-app/feature-editable-content'
import { WebAppContent } from 'web-app/ui-layout'
import { Alert } from 'shared/ui-components'
import { RouteCollectionEntity, RouteCollectionEntityDetails } from 'shared/data-access-core'

interface EditableRouteCollectionPrivacyProps {
  heading?: string
  routeCollection: RouteCollectionEntity & RouteCollectionEntityDetails
}

export const EditableRouteCollectionPrivacy = ({ heading, routeCollection }: EditableRouteCollectionPrivacyProps) => {
  const { intl } = useLocale()
  const contentEditingProps = useRouteCollectionContentEditing('isPrivate')

  return (
    <WebAppContent>
      <EditablePrivacy
        currentValue={routeCollection.isPrivate}
        heading={heading}
        privateDescription={intl.formatMessage({
          id: 'route_collection_privacy_private_description',
          defaultMessage: 'The collection is private and only visible to you.',
        })}
        publicDescription={intl.formatMessage({
          id: 'route_collection_privacy_public_description',
          defaultMessage: 'The collection is public and shared with the Bikemap community.',
        })}
        {...contentEditingProps}
      />
      {!routeCollection.isPrivate && routeCollection.hasPrivateRoutes && (
        <Alert style={{ marginTop: '1rem' }}>
          {intl.formatMessage({
            id: 'route_collection_private_routes_alert_owner',
            defaultMessage: "Some routes in this collection are private. If shared, other users won't see them.",
          })}
        </Alert>
      )}
    </WebAppContent>
  )
}
