import { WebAppContent } from 'web-app/ui-layout'
import { PLACE_DEFAULT_ZOOM_LEVEL, REGION_DEFAULT_ZOOM_LEVELS, Search } from 'web-app/feature-search'
import { useDispatch } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { viewportDesired } from 'web-app/feature-map'
import { lngLatToPosition2d } from 'shared/util-geo'
import { DiscoverFilters, useDiscoverParamsString, useDiscoverState } from 'web-app/feature-discover'
import { RegionEntity, RegionEntityMapData } from 'shared/data-access-core'
import { useNavigateToRegion, useNavigateToRouteDetails } from 'web-app/feature-navigation'

interface SearchSectionProps {
  selectedLocation: GeocoderLocation | null
  onSelect: (result: GeocoderLocation) => void
  onSelectionCancel: () => void
}

export const SearchSection = ({ selectedLocation, onSelect, onSelectionCancel }: SearchSectionProps) => {
  const dispatch = useDispatch()
  const navigateToRegion = useNavigateToRegion()
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const discoverParamsString = useDiscoverParamsString()
  const { count, isSearching } = useDiscoverState()

  const handlePlaceSelect = useCallback(
    (result: GeocoderLocation) => {
      dispatch(
        viewportDesired({
          center: lngLatToPosition2d(result.position),
          zoom: PLACE_DEFAULT_ZOOM_LEVEL,
        }),
      )
      onSelect(result)
    },
    [dispatch, onSelect],
  )

  const handleRegionSelect = useCallback(
    (result: RegionEntity & RegionEntityMapData) => {
      dispatch(
        viewportDesired({
          center: lngLatToPosition2d(result.center),
          zoom: REGION_DEFAULT_ZOOM_LEVELS[result.kind],
        }),
      )
      navigateToRegion(result.id, { regionPreview: result }, discoverParamsString)
    },
    [discoverParamsString, dispatch, navigateToRegion],
  )

  const defaultValue = useMemo(
    () =>
      selectedLocation
        ? selectedLocation.address
          ? selectedLocation.poiName || selectedLocation.address
          : `${selectedLocation.position.lng.toPrecision(7)}, ${selectedLocation.position.lat.toPrecision(7)}`
        : '',
    [selectedLocation],
  )

  return (
    <WebAppContent noPadding stickyOnTop zIndex={3}>
      <Search
        defaultValue={defaultValue}
        onPlaceSelect={handlePlaceSelect}
        onRegionSelect={handleRegionSelect}
        onRouteSelect={(result) => navigateToRouteDetails(result.id)}
        onDefaultReset={onSelectionCancel}
        after={<DiscoverFilters count={count} isSearching={isSearching} />}
      />
    </WebAppContent>
  )
}
