import { Skeleton, Typography } from '@mui/material'
import { RegionEntity, RegionEntityDetails } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'
import { CollapsedText, LAYOUT_BREAKPOINT_REM, WebAppContent, WebAppContentPaddinglessChild } from 'web-app/ui-layout'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { TrekkingBikeIcon } from 'shared/ui-design-system/icons/trekking-bike-icon'
import { formatLargeLength } from 'shared/util-formatting'
import { useUserState } from 'web-app/feature-user'

import styles from './region-content-section.module.scss'

interface RegionContentSectionProps {
  region: (RegionEntity & RegionEntityDetails) | null
}

export const RegionContentSection = ({ region }: RegionContentSectionProps) => {
  const { intl, language } = useLocale()
  const { unitPreference } = useUserState()

  return (
    <WebAppContent>
      {region?.image && (
        <WebAppContentPaddinglessChild>
          <picture>
            <source media={`(min-width: ${LAYOUT_BREAKPOINT_REM}rem)`} srcSet={region.image.small} />
            <img className={styles['image']} src={region.image.smallWide} alt={region.name} />
          </picture>
        </WebAppContentPaddinglessChild>
      )}
      <Typography variant="h2" component="h1">
        {region ? region.name : <Skeleton width="60%" />}
      </Typography>
      {region ? (
        region.introduction ? (
          <CollapsedText>{region.introduction}</CollapsedText>
        ) : null
      ) : (
        <Typography paragraph>
          <Skeleton />
          <Skeleton />
          <Skeleton width="30%" />
        </Typography>
      )}
      {region && (
        <div className={styles['stats']}>
          <div className={styles['stats-item']}>
            <TrekkingBikeIcon color="primary" />
            <Typography variant="body2" fontWeight={500}>
              {intl.formatNumber(region.routesCount)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {intl.formatMessage({
                id: 'region_content_stats_label_cycle_routes',
                defaultMessage: 'Cycle routes',
              })}
            </Typography>
          </div>
          <div className={styles['stats-item']}>
            <FlagRoundedIcon color="primary" />
            <Typography variant="body2" fontWeight={500}>
              {formatLargeLength(region.trackedDistanceKilometers * 1000, language, unitPreference === 'imperial')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {intl.formatMessage({
                id: 'region_content_stats_label_tracked_distance',
                defaultMessage: 'Tracked distance',
              })}
            </Typography>
          </div>
        </div>
      )}
    </WebAppContent>
  )
}
