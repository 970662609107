import { API_PATH_ROUTE_COLLECTION_ROUTES } from '../../config'
import { RouteCollectionCategory } from '../../entities'
import { deleteFromCoreApi, postToCoreApi } from '../../network'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'

type Response = {
  category: number | null
  previous_category: number | null
}

export type RouteCollectionAssignmentData = {
  category: RouteCollectionCategory | null
  previousCategory: RouteCollectionCategory | null
}

/**
 * Assign a route to a route collection.
 */
export async function assignRouteToCollection(
  routeId: number,
  routeCollectionId: number,
): ApiResult<RouteCollectionAssignmentData> {
  try {
    const res: Response = await postToCoreApi(API_PATH_ROUTE_COLLECTION_ROUTES, {
      params: { routeCollectionId },
      body: { route_id: routeId },
    })

    try {
      const parser = new ResponseParser(res)
      return createSuccessResult({
        category: getCategory(parser.takeNumber('category')),
        previousCategory: getCategory(parser.takeNumber('previous_category')),
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId, routeCollectionId })
  }
}

/**
 * Remove a route from a route collection it is assigned to.
 */
export async function unassignRouteFromCollection(
  routeId: number,
  routeCollectionId: number,
): ApiResult<RouteCollectionAssignmentData> {
  try {
    const res: Response = await deleteFromCoreApi(API_PATH_ROUTE_COLLECTION_ROUTES, {
      params: { routeCollectionId },
      body: { route_id: routeId },
    })

    try {
      const parser = new ResponseParser(res)
      return createSuccessResult({
        category: getCategory(parser.takeNumber('category')),
        previousCategory: getCategory(parser.takeNumber('previous_category')),
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId, routeCollectionId })
  }
}

function getCategory(categoryValue: number | null): RouteCollectionCategory | null {
  return categoryValue && categoryValue >= 1 && categoryValue <= 3 ? categoryValue : null
}
