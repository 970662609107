import { RangeSlider } from 'shared/ui-components'
import { FILTERS_CHANGE_DEBOUNCE, MAX_ASCENT_FILTER } from 'shared/util-discover'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useLocale } from 'shared/util-intl'
import { useRangeScaleConversion } from './use-range-scale-conversion'
import { UnitPreference, useUserState } from 'web-app/feature-user'
import { feetToMeters, metersToFeet } from 'shared/util-formatting'
import { FilterRange } from 'shared/data-access-core'
import { useDiscoverFilters } from '../use-discover-filters'

const NORMAL_SCALE_MAX = 1000
const INCREASED_SCALE_MAX = 1500

export const AscentField = () => {
  const { intl } = useLocale()
  const { unitPreference } = useUserState()
  const [filters, setFilters] = useDiscoverFilters()
  const [value, setValue] = useState<[number, number]>(getValueFromParam(filters.ascentMeters, unitPreference))

  useEffect(
    () => setValue(getValueFromParam(filters.ascentMeters, unitPreference)),
    [filters.ascentMeters, unitPreference],
  )

  const { convertValueToScale, convertScaleToValue } = useRangeScaleConversion(
    NORMAL_SCALE_MAX,
    INCREASED_SCALE_MAX,
    MAX_ASCENT_FILTER,
  )

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        ([min, max]: [number, number]) =>
          setFilters({
            ascentMeters: [
              unitPreference === 'imperial' ? feetToMeters(min) : min,
              max < MAX_ASCENT_FILTER ? (unitPreference === 'imperial' ? feetToMeters(max) : max) : null,
            ],
          }),
        FILTERS_CHANGE_DEBOUNCE,
      ),
    [setFilters, unitPreference],
  )

  const handleChange = (scale: [number, number]) => {
    const value = scale.map(convertScaleToValue) as [number, number]
    setValue(value)
    debouncedChangeHandler(value)
  }

  const unit = unitPreference === 'imperial' ? 'ft' : 'm'

  return (
    <RangeSlider
      name="discover-ascent"
      label={intl.formatMessage({
        id: 'discover_filter_ascent_label',
        defaultMessage: 'Ascent',
      })}
      max={INCREASED_SCALE_MAX}
      value={value.map(convertValueToScale) as [number, number]}
      markers={[
        { value: 0, label: `0 ${unit}` },
        { value: 500, label: `500 ${unit}` },
        { value: 1000, label: `1000 ${unit}` },
        { value: INCREASED_SCALE_MAX, label: `3000+ ${unit}` },
      ]}
      onChange={handleChange}
      renderCurrentValue={([min, max]) => {
        const minLabel = `${convertScaleToValue(min)} ${unit}`
        const maxLabel = `${max < INCREASED_SCALE_MAX ? convertScaleToValue(max) : `${MAX_ASCENT_FILTER}+`} ${unit}`
        return `${minLabel} - ${maxLabel}`
      }}
    />
  )
}

function getValueFromParam(ascentMeters: FilterRange, unitPreference: UnitPreference): [number, number] {
  const [min, max] = ascentMeters
  return [
    unitPreference === 'imperial' ? Math.round(metersToFeet(min)) : min,
    max ? (unitPreference === 'imperial' ? Math.round(metersToFeet(max)) : max) : MAX_ASCENT_FILTER,
  ]
}
