import React from 'react'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { theme } from './theme'

export interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
  </MuiThemeProvider>
)

export default ThemeProvider
