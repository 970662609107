import { Feature, FeatureCollection, Position } from 'geojson'
import { RouteType } from '../types'
import { useMap, Point, MapLayerMouseEvent, Source, Layer } from 'react-map-gl/maplibre'
import { useCallback, useMemo, useState } from 'react'
import { useMapClick, useMapMouseMove } from '../hooks'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { useInactiveRouteLines } from './use-inactive-route-lines'
import { useAlternatingRouteLines } from './use-alternating-route-lines'
import { logError } from 'shared/util-error-handling'
import { useAlternatingRouteMarkers } from './use-alternating-route-markers'
import { RichLineString, positionToLngLat } from 'shared/util-geo'

export type RouteGeometryPreview = {
  routeId: number
  routeType: RouteType
  geometry: RichLineString
}

interface MapTourRoutesProps {
  id: string
  mapId: string
  routes: RouteGeometryPreview[]
  interactive?: boolean
  selectedRouteId: number | null
  onClick: (routeId: number) => void
  onClickOutside: () => void
}

export const MapTourRoutes = ({
  id,
  mapId,
  routes,
  interactive = true,
  selectedRouteId,
  onClick,
  onClickOutside,
}: MapTourRoutesProps) => {
  const { [mapId]: map } = useMap()

  const [hoveredRouteId, setHoveredRouteId] = useState<number | null>(null)

  const routeLayerBeforeId = useRouteLayerBeforeId()
  const inactiveRouteLines = useInactiveRouteLines(
    id,
    routes.reduce((segments, { routeId, geometry }) => {
      if (routeId !== selectedRouteId) {
        segments.push(geometry.coordinates as Position[])
      }
      return segments
    }, [] as Position[][]),
  )
  const routeLines = useAlternatingRouteLines(id, routes, hoveredRouteId)
  const routeMarkers = useAlternatingRouteMarkers(
    id,
    routes.map(({ routeId, routeType, geometry }) => ({
      routeId,
      routeType,
      position: positionToLngLat(geometry.coordinates[0]),
    })),
    hoveredRouteId,
  )

  const sourceData = useMemo<FeatureCollection>(() => {
    const features = []

    if (selectedRouteId === null) {
      features.push(...routeLines.features, ...routeMarkers.features)
    } else {
      features.push(...inactiveRouteLines.features)
    }

    return {
      type: 'FeatureCollection',
      features,
    }
  }, [inactiveRouteLines.features, routeLines.features, routeMarkers.features, selectedRouteId])

  const clickableLayerIds = useMemo<string[]>(
    () => [routeMarkers.layerProps.id, routeLines.lineLayerProps.id, routeLines.outlineLayerProps.id],
    [routeLines.lineLayerProps.id, routeLines.outlineLayerProps.id, routeMarkers.layerProps.id],
  )

  const findClickableFeature = useCallback(
    (point: Point): Feature | null => {
      if (!map) return null
      try {
        const features = map.getMap().queryRenderedFeatures(point)
        for (const feature of features) {
          if (clickableLayerIds.includes(feature.layer.id)) {
            map.getCanvas().style.cursor = 'pointer'
            setHoveredRouteId(feature.properties['routeId'])
            return feature
          }
        }
      } catch (error) {
        logError('Error while querying clickable collection map features', error)
      }
      return null
    },
    [clickableLayerIds, map],
  )

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!map) return
      const feature = findClickableFeature(event.point)
      if (feature?.properties) {
        if (hoveredRouteId === null) {
          map.getCanvas().style.cursor = 'pointer'
        }
        if (hoveredRouteId !== feature.properties['routeId']) {
          setHoveredRouteId(feature.properties['routeId'])
        }
      } else {
        if (hoveredRouteId !== null) {
          map.getCanvas().style.cursor = ''
          setHoveredRouteId(null)
        }
      }
    },
    [findClickableFeature, hoveredRouteId, map],
  )
  useMapMouseMove(mapId, handleMouseMove, interactive)

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const feature = findClickableFeature(event.point)
      const routeId = feature?.properties && feature.properties['routeId']
      if (routeId) {
        onClick(routeId)
      } else {
        onClickOutside()
      }
    },
    [findClickableFeature, onClickOutside, onClick],
  )
  useMapClick(mapId, handleMapClick, interactive)

  return (
    <Source id={`${id}-source`} type="geojson" data={sourceData}>
      <Layer {...inactiveRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...inactiveRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeMarkers.layerProps} />
    </Source>
  )
}
