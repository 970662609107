import { useLocale } from 'shared/util-intl'
import { SavableRouteState, reset, useHasRouteBeenChanged } from '../../state'
import { useCallback } from 'react'
import { RouteGeometryChangeForm } from 'shared/data-access-core'
import { RouteSliceDispatch, saveRouteChanges } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { BaseRouteAction } from './base-route-action'
import { Waypoints } from 'shared/util-geo'
import { useNavigateToRouteDetails } from 'web-app/feature-navigation'

interface UpdateRouteActionProps {
  primary?: boolean
}

export const UpdateRouteAction = (props: UpdateRouteActionProps) => {
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()
  const dispatch = useDispatch() as RouteSliceDispatch
  const hasRouteBeenChanged = useHasRouteBeenChanged()

  const showRouteSaveErrorNotification = useCallback(() => {
    showCommonErrorNotification(
      intl.formatMessage({
        id: 'route_planner_update_route_action_error',
        defaultMessage: 'Your changes could not be saved.',
      }),
    )
  }, [intl, showCommonErrorNotification])

  const handleRouteSubmit = useCallback(
    async ({ geometry, distanceMeters, durationSeconds, waypoints, controlPointIndexes }: SavableRouteState) => {
      try {
        const geometryForm: RouteGeometryChangeForm = {
          geometry,
          distanceMeters,
          durationSeconds: durationSeconds || undefined,
          waypoints: waypoints as Waypoints,
          controlPointIndexes,
        }
        const { route } = await dispatch(saveRouteChanges({ geometryForm })).unwrap()
        if (route) {
          dispatch(reset())
          navigateToRouteDetails(route.id)
        }
      } catch {
        showRouteSaveErrorNotification()
      }
    },
    [dispatch, navigateToRouteDetails, showRouteSaveErrorNotification],
  )

  return (
    <BaseRouteAction {...props} onRouteSubmit={handleRouteSubmit} disabled={!hasRouteBeenChanged}>
      {intl.formatMessage({
        id: 'route_planner_update_route_action_label',
        defaultMessage: 'Save changes',
      })}
    </BaseRouteAction>
  )
}
