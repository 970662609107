import { useLocale, useTitle } from 'shared/util-intl'
import { useRouteCollectionState } from './state'

/**
 * Set the page title depending on loading and availability status of the main route collection.
 */
export const useRouteCollectionTitle = () => {
  const { intl } = useLocale()
  const { isRouteCollectionUnavailable, routeCollection, isRouteCollectionLoaded } = useRouteCollectionState()

  useTitle(
    isRouteCollectionUnavailable
      ? intl.formatMessage({
          id: 'route_collection_document_title_unavailable',
          defaultMessage: 'Route collection not available - Bikemap',
        })
      : isRouteCollectionLoaded && routeCollection?.title
        ? intl.formatMessage(
            {
              id: 'route_collection_document_title_route',
              defaultMessage: '{routeCollectionTitle} - Bikemap',
            },
            {
              routeCollectionTitle: routeCollection.title,
            },
          )
        : intl.formatMessage({
            id: 'route_collection_document_title_loading',
            defaultMessage: 'Loading route collection - Bikemap',
          }),
  )
}
