import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Button, useMessages } from 'shared/ui-components'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { useEditableContentContext, useIsEditingFlags } from './editable-content-context'

import styles from './editable-content.module.css'

interface EditContentButtonProps {
  name: string
}

export const EditContentButton = ({ name }: EditContentButtonProps) => {
  const { onEditingStarted } = useEditableContentContext()
  const { editLabel } = useMessages()

  return (
    <Button variant="secondary" size="small" icon={<EditRoundedIcon />} onClick={() => onEditingStarted(name)}>
      {editLabel}
    </Button>
  )
}

interface ContentFormButtonsProps {
  onSubmit: () => void
  onCancel: () => void
  isSubmitDisabled?: boolean
}

export const ContentFormButtons = ({ onSubmit, onCancel, isSubmitDisabled }: ContentFormButtonsProps) => {
  const { saveLabel, cancelLabel } = useMessages()

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <div className={styles['form-buttons']}>
      <Button variant="secondary" size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button type="submit" size="small" onClick={handleSubmit} disabled={isSubmitDisabled}>
        {saveLabel}
      </Button>
    </div>
  )
}

type RenderContentProps<ValueType> = {
  value: ValueType
  editButton?: ReactElement
}

type RenderFormProps<ValueType> = {
  name: string
  value: ValueType
  onChange: (value: ValueType, isValid?: boolean) => void
  buttons: ReactElement
}

export interface EditableContentProps<ValueType> {
  name: string
  currentValue?: ValueType
  heading?: string
  canEdit?: boolean
  renderContent: (props: RenderContentProps<ValueType>) => ReactElement
  renderForm: (props: RenderFormProps<ValueType>) => ReactElement
  renderLoading: () => ReactElement
  onSubmit: (value: ValueType) => Promise<void>
}

export function EditableContent<ValueType>({
  name,
  currentValue,
  heading,
  canEdit,
  renderContent,
  renderForm,
  renderLoading,
  onSubmit,
}: EditableContentProps<ValueType>) {
  const { isEditing, isEditingAnotherField } = useIsEditingFlags(name)
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()
  const { onEditingDone } = useEditableContentContext()

  const [value, setValue] = useState<ValueType | undefined>(currentValue)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const handleSubmit = async () => {
    if (value !== undefined && isValid) {
      setIsSaving(true)
      try {
        await onSubmit(value)
        onEditingDone()
      } catch {
        showCommonErrorNotification(
          intl.formatMessage({
            id: 'editable_content_save_changes_error_details',
            defaultMessage: 'Your changes could not be saved.',
          }),
        )
      }
      setIsSaving(false)
    }
  }

  const handleCancel = () => {
    setValue(currentValue)
    onEditingDone()
  }

  return (
    <>
      {heading && (
        <div className={styles['heading']}>
          <Typography variant="h4" component="h2" marginBottom={0}>
            {heading}
          </Typography>
          {canEdit &&
            !isSaving &&
            (isEditing ? (
              <ContentFormButtons
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isSubmitDisabled={value === currentValue || !isValid}
              />
            ) : (
              !isEditingAnotherField && <EditContentButton name={name} />
            ))}
        </div>
      )}
      {isSaving || currentValue === undefined
        ? renderLoading()
        : isEditing && value !== undefined
          ? renderForm({
              name,
              value,
              onChange: (value, isValid = true) => {
                setValue(value)
                setIsValid(isValid)
              },
              buttons: (
                <ContentFormButtons onSubmit={handleSubmit} onCancel={handleCancel} isSubmitDisabled={!isValid} />
              ),
            })
          : renderContent({
              value: currentValue,
              editButton: canEdit && !isEditingAnotherField ? <EditContentButton name={name} /> : undefined,
            })}
    </>
  )
}
