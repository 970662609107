import { useCallback } from 'react'
import { ElevationData } from './elevation-curve'

export const useSegmentedElevationData = (elevationData?: ElevationData[][] | null) => {
  const getElevationDataByIndex = useCallback(
    (index: number) => {
      if (!elevationData) return undefined
      for (const segment of elevationData) {
        if (segment.length > index) {
          return segment[index]
        }
        index -= segment.length
      }
      return undefined
    },
    [elevationData],
  )

  return { getElevationDataByIndex }
}
