import { useCallback } from 'react'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled'
import { LocationList } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUserGeolocation } from 'web-app/feature-user'
import { SearchResult } from './types'

interface SuggestionCurrentLocationProps {
  onSelect: (result: SearchResult) => void
}

export function SuggestionCurrentLocation({ onSelect }: SuggestionCurrentLocationProps) {
  const { intl } = useLocale()

  const { geolocation, isGeolocationLoading, isGeolocationDenied, geolocate } = useUserGeolocation()

  const handleClick = useCallback(() => {
    if (geolocation) {
      onSelect({ type: 'place', data: geolocation.geocoded })
    } else {
      geolocate()
    }
  }, [geolocate, geolocation, onSelect])

  return (
    <LocationList.Action
      title={intl.formatMessage({
        id: 'search_action_current_location',
        defaultMessage: 'Current location',
      })}
      subtitle={
        isGeolocationLoading
          ? intl.formatMessage({
              id: 'search_action_current_location_loading_subtitle',
              defaultMessage: 'Getting your current location...',
            })
          : geolocation?.geocoded.address
      }
      icon={isGeolocationDenied ? <LocationDisabledIcon /> : <MyLocationIcon />}
      onClick={handleClick}
    />
  )
}
