import { useLocale } from 'shared/util-intl'
import { RouteCollectionEntity } from 'shared/data-access-core'
import { EditableText } from 'web-app/feature-editable-content'
import { useRouteCollectionContentEditing } from '../use-route-collection-content-editing'

interface EditableRouteDescriptionProps {
  heading: string
  routeCollection?: RouteCollectionEntity
}

export const EditableRouteCollectionDescription = ({ heading, routeCollection }: EditableRouteDescriptionProps) => {
  const { intl } = useLocale()
  const contentEditingProps = useRouteCollectionContentEditing('description')

  return (
    <EditableText
      currentValue={routeCollection ? routeCollection.description || '' : undefined}
      heading={heading}
      collapsedLines={2}
      emptyPlaceholder={intl.formatMessage({
        id: 'route_collection_description_empty_placeholder',
        defaultMessage: 'No description yet.',
      })}
      label={intl.formatMessage({
        id: 'route_collection_description_form_label',
        defaultMessage: 'Description',
      })}
      placeholder={intl.formatMessage({
        id: 'route_collection_description_form_placeholder',
        defaultMessage: 'Add a description',
      })}
      {...contentEditingProps}
    />
  )
}
