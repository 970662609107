import React from 'react'
import { BaseButton } from '../../base/BaseButton'

import styles from './inline-tabs.module.scss'

export interface InlineTabsOption<ValueType> {
  value: ValueType
  label: React.ReactNode
}

export interface InlineTabsProps<ValueType> {
  options: InlineTabsOption<ValueType>[]
  value: ValueType
  onSelect: (value: ValueType) => void
}

export function InlineTabs<ValueType extends string>({ options, value, onSelect }: InlineTabsProps<ValueType>) {
  return (
    <div className={styles['root']} role="tablist">
      {options.map((option, i) =>
        option.value === value ? (
          <div className={styles['selected']} key={i} role="tab" aria-selected="true">
            {option.label}
          </div>
        ) : (
          <BaseButton
            variant="ghost-primary"
            className={styles['button']}
            key={i}
            onClick={() => onSelect(option.value)}
            role="tab"
            aria-selected="false"
          >
            {option.label}
          </BaseButton>
        ),
      )}
    </div>
  )
}
