import { fetchAccessToken } from './fetch'
import { AccessTokenDataType } from './types'

/**
 * Handle getting token for authorization header.
 */
class AuthToken {
  /** Header consisting of token type and the token (e.g. "Bearer abc...") */
  authorizationHeader: string | undefined

  refreshToken: string | undefined

  tokenPromise: Promise<void> | undefined

  /** Unix-timestamp in seconds */
  expiryTimestamp: number | undefined

  /** Timeout ID */
  refreshTokenTimeout: ReturnType<typeof setTimeout> | undefined

  /** Number of times we tried to fetch auth token */
  failedFetchCount = 0

  init = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('focus', this.recoverAuthentication)
    }
    return this
  }

  /**
   * Make sure we have a valid token by checking the expiry time and refreshing if necessary.
   * Useful if token expires in the background (while tab is not in focus),
   * so our setTimeout callback didn't run.
   */
  private recoverAuthentication = () => {
    if (this.expiryTimestamp && this.expiryTimestamp < Math.floor(Date.now() / 1000)) {
      // Current token expired
      this.triggerTokenPromise()
    }
  }

  /**
   * Fetch access token from API and attach additional data.
   */
  fetchToken = async (): Promise<AccessTokenDataType> => {
    try {
      const tokenData = await fetchAccessToken(this.refreshToken)

      return {
        ...tokenData,
        // Reduce by 30 sec, so we can fetch a new one before it expires in backend
        expiresIn: tokenData.expiresIn > 30 ? tokenData.expiresIn - 30 : tokenData.expiresIn,
      }
    } catch (e) {
      return {
        accessToken: '',
        // Will try to fetch again in 5, 10, 15... seconds
        expiresIn: ++this.failedFetchCount * 5,
        scope: '',
        tokenType: '',
        refreshToken: '',
        isLoggedIn: false,
      }
    }
  }

  /**
   * Format token for `Authorization` header (e.g. "Bearer abc...").
   */
  private formatAuthorizationHeader = (token: AccessTokenDataType): string => {
    if (token.tokenType && token.accessToken) {
      return token.tokenType + ' ' + token.accessToken
    }
    return ''
  }

  /**
   * Fetch the access token and refresh when it expires.
   */
  getToken = async (): Promise<void> => {
    const token = await this.fetchToken()

    // Hold token data in memory
    this.authorizationHeader = this.formatAuthorizationHeader(token)
    this.refreshToken = token.refreshToken
    this.expiryTimestamp = Math.floor(Date.now() / 1000 + token.expiresIn)

    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout)
    }
    // Refresh token when it expires
    this.refreshTokenTimeout = setTimeout(() => {
      this.triggerTokenPromise()
    }, token.expiresIn * 1000)
  }

  /**
   * Trigger access token request and save promise for coming calls.
   */
  private triggerTokenPromise = (): void => {
    this.tokenPromise = this.getToken()
  }

  /**
   * Returns the string to use as value of the authorization header after making an authentication
   * request if necessary.
   * If called multiple times (for same "new AuthToken"), it will just keep returning same token,
   * instead of fetching new one from API since it's keep in memory.
   */
  getAuthHeader = async (): Promise<string> => {
    // If no auth request has ever been triggered, trigger it now
    if (!this.tokenPromise) {
      this.triggerTokenPromise()
    }

    // Wait for an ongoing auth request to finish
    await this.tokenPromise

    return this.authorizationHeader || ''
  }
}

export default AuthToken

/**
 * Singleton service that provides the current auth token.
 */
const authToken = new AuthToken().init()
export { authToken }
