import { Point } from 'geojson'
import { API_PATH_ROUTE_COLLECTION_ROUTES } from '../../config'
import {
  EntityIndex,
  RouteBikeType,
  RouteEntity,
  RouteEntityOverview,
  RouteOverviewImageSizes,
  RouteSurface,
  UserEntity,
} from '../../entities'
import { getFromCoreApi } from '../../network'
import {
  ApiResult,
  AuthError,
  GetRequestOptions,
  NotFoundError,
  ResponseParser,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'
import { FilterRange } from '../../types'
import { mapSurfaceToTag, mapTagToSurface, mapToBikeType } from '../helpers'

export const COLLECTION_ROUTES_SORTING_OPTIONS = [
  'custom-asc',
  'relevance-desc',
  'rating-desc',
  'distance-asc',
  'distance-desc',
  'ascent-asc',
  'ascent-desc',
] as const

export type CollectionRoutesSorting = (typeof COLLECTION_ROUTES_SORTING_OPTIONS)[number]

type ListParams = {
  page?: number
  pageSize?: number
}

type CollectionRoutesOptions = {
  lang: 'en' | 'de'
  sorting: CollectionRoutesSorting
  onlyLoops?: boolean
  distanceKilometers?: FilterRange
  ascentMeters?: FilterRange
  title?: string
  bikeTypes?: RouteBikeType[]
  surfaces?: RouteSurface[]
}

type CollectionRoutesQueryParams = {
  page?: number
  page_size?: number
  sort?: string
  sort_order?: 'ASC' | 'DESC'
  bounds?: string
  location?: number
  no_location_filters?: true
  score_nearby?: 'false'
  route_type: 'user'
  only_loop?: boolean
  distance_min?: number
  distance_max?: number
  ascent?: number | [number, number]
  q?: string
  category?: RouteBikeType[]
  surfaces?: string
}

type Response = {
  ascent_total: number
  count: number
  descent_total: number
  distance_total: number
  duration_total: number
  next: string | null
  previous: string | null
  results:
    | {
        content_object: {
          admin_two_id: number | null
          altitude_difference: number | null
          altitude_difference_back: number | null
          average_speed: null // seems to be always null
          category: number[] | null
          created: string
          description: string | null
          distance: number
          duration: number | null
          id: number
          images:
            | {
                fallback: string
                item: string | null
                tile: string | null
              }[]
            | null
          is_favorite: boolean
          is_loop: boolean
          is_private: boolean
          location?: string
          max_elevation: number | null
          route_favorite_count: number
          start: Point
          staticmaps: {
            bmx_big: string | null
            preview: string | null
            small: string | null
          } | null
          surface_tags: string[] | null
          title: string
          tour_index: number | null
          user: {
            displayname: string
            id: number
            image: {
              fallback: string
              small: string | null
            } | null
            is_subscribed: boolean
            profile_url: string
            slug: string
          } | null
        }
      }[]
    | null
}

export type CollectionRoutesData = {
  results: number[]
  tourOrderResults: number[] | null
  routes: EntityIndex<RouteEntity<RouteOverviewImageSizes> & RouteEntityOverview>
  creators: EntityIndex<UserEntity>
  count: number
}

const UNLIMITED_ASCENT = 3001

/**
 * Fetch paginated, sorted and filtered routes that are assigned to a given route collection.
 */
export async function fetchRouteCollectionRoutes(
  routeCollectionId: number,
  options: CollectionRoutesOptions,
  listParams: ListParams = {
    page: 1,
    pageSize: 20,
  },
): ApiResult<CollectionRoutesData> {
  try {
    const [sort, order] = options.sorting.split('-')

    const requestOptions: GetRequestOptions = {
      params: { routeCollectionId },
      queryParams: {
        page: listParams.page,
        page_size: listParams.pageSize,
        sort,
        sort_order: order,
      } as CollectionRoutesQueryParams,
    }

    if (options.lang) {
      requestOptions.headers = { 'Accept-Language': options.lang }
    }

    if (requestOptions.queryParams) {
      if (options.onlyLoops) {
        requestOptions.queryParams['only_loop'] = true
      }

      if (options.distanceKilometers) {
        requestOptions.queryParams['distance_min'] = options.distanceKilometers[0] * 1000
        if (options.distanceKilometers[1]) {
          requestOptions.queryParams['distance_max'] = options.distanceKilometers[1] * 1000
        }
      }

      if (options.ascentMeters) {
        const [min, max] = options.ascentMeters
        if (min || (max && max !== UNLIMITED_ASCENT)) {
          // only add filter if really necessary, otherwise routes with null ascent will be excluded
          requestOptions.queryParams['ascent'] = [min, max || UNLIMITED_ASCENT]
        }
      }

      if (options.title) {
        requestOptions.queryParams['q'] = options.title
      }

      if (options.bikeTypes?.length) {
        requestOptions.queryParams['category'] = options.bikeTypes
      }

      if (options.surfaces?.length) {
        requestOptions.queryParams['surface_tags'] = options.surfaces.map(mapSurfaceToTag).join(',')
      }
    }

    const res: Response = await getFromCoreApi(API_PATH_ROUTE_COLLECTION_ROUTES, requestOptions)

    try {
      const tourIndexes: Record<number, number> = {}
      const routes: EntityIndex<RouteEntity<RouteOverviewImageSizes> & RouteEntityOverview> = {}
      const creators: EntityIndex<UserEntity> = {}

      const results: number[] = res.results
        ? res.results.map((result) => {
            const parser = new ResponseParser(result.content_object)
            const id = parser.requireNumber('id')
            const locationName = parser.takeString('location')
            const geonameId = parser.takeNumber('admin_two_id')
            const distanceMeters = parser.requireNumber('distance')
            const durationSeconds = parser.takeNumber('duration')
            const creator = parser.has('user')
              ? {
                  id: parser.in('user').requireNumber('id'),
                  name: parser.in('user').requireString('displayname'),
                  isPremium: parser.in('user').requireBoolean('is_subscribed'),
                  avatar: parser.in('user').takeImageSizes('image', {
                    small: 'small',
                  }),
                  slug: parser.in('user').requireString('slug'),
                }
              : null
            routes[id] = {
              id,
              title: parser.requireString('title'),
              distanceMeters,
              ascentMeters: parser.takeNumber('altitude_difference'),
              descentMeters: parser.takeNumber('altitude_difference_back'),
              isPrivate: parser.requireBoolean('is_private'),
              location: geonameId && locationName ? { geonameId, name: locationName } : null,
              favoriteCount: parser.requireNumber('route_favorite_count'),
              created: parser.requireTimestamp('created'),
              descriptionExcerpt: parser.takeString('description'),
              surfaces: parser.takeArray('surface_tags', mapTagToSurface),
              bikeTypes: parser.takeArray('category', mapToBikeType),
              images: parser.takeAsImageSizesList('images', {
                item: 'item',
                tile: 'tile',
                overviewSmall: 'overview_small', // TODO WEB-1638 check if provided by API
                overviewLarge: 'overview_large', // TODO WEB-1638 check if provided by API
                original: 'fallback',
              }),
              isFavorite: parser.requireBoolean('is_favorite'),
              isLoop: parser.requireBoolean('is_loop'),
              staticMap: parser.takeImageSizesWithoutFallback('staticmaps', {
                item: 'preview',
                tile: 'bmx_big',
                tileSecondary: 'small',
                overview: 'bmx_big',
                medium: 'bmx_big',
              }),
              start: parser.requireLngLat('start'),
              creatorId: creator?.id ?? null,
              durationSeconds,
              averageSpeedMetersPerSecond: durationSeconds ? distanceMeters / durationSeconds : null,
              maximumElevationMeters: parser.takeNumber('max_elevation'),
            }

            if (creator) {
              creators[creator.id] = creator
            }

            const tourIndex = parser.takeNumber('tour_index')
            if (typeof tourIndex === 'number') {
              tourIndexes[id] = tourIndex
            }

            return id
          })
        : []

      const parser = new ResponseParser(res)
      return createSuccessResult({
        results,
        tourOrderResults: getTourOrderResults(results, tourIndexes),
        routes,
        creators,
        count: parser.requireNumber('count'),
      })
    } catch (error) {
      if (error instanceof AuthError) {
        return createFailureResult({ unauthorized: true }, { routeCollectionId })
      }
      if (error instanceof NotFoundError) {
        return createFailureResult({ notFound: true }, { routeCollectionId })
      }
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

function getTourOrderResults(results: number[], tourIndexes: Record<number, number>): number[] | null {
  if (Object.keys(tourIndexes).length !== results.length) return null

  const tourOrderResults: number[] = [...results]
  tourOrderResults.sort((idA, idB) => tourIndexes[idA] - tourIndexes[idB])
  return tourOrderResults
}
