import { useLocale } from 'shared/util-intl'
import { AvatarMenuButton } from 'shared/ui-components'
import { useUser } from 'web-app/feature-user'

interface NavigationMenuToggleProps {
  isOpen: boolean
  compact?: boolean
  onToggle: (isOpen: boolean) => void
}

export const NavigationMenuToggle = ({ isOpen, compact, onToggle }: NavigationMenuToggleProps) => {
  const { intl } = useLocale()
  const [user, isUserLoaded] = useUser()

  return (
    <AvatarMenuButton
      isMenuOpen={isOpen}
      compact={compact}
      onClick={() => onToggle(!isOpen)}
      name={
        isUserLoaded && user
          ? user.name
          : intl.formatMessage({
              id: 'user_navigation_accessible_name_anonymous',
              defaultMessage: 'Anonymous',
            })
      }
      image={user?.avatar?.small}
      isPremium={user?.isPremium}
      isLoading={!isUserLoaded}
      ariaLabel={intl.formatMessage({
        id: 'user_navigation_menu_button_label',
        defaultMessage: 'Show menu',
      })}
    />
  )
}
