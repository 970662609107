import { WebAppSheet } from 'web-app/ui-layout'
import { PrintTips } from './components/print-tips'
import { PrintingOptions } from './components/printing-options'
import { DetailedMapsOptions } from './components/detailed-maps-options'
import { useRoute } from 'web-app/feature-route'
import { ImagesOptions } from './components/images-options'

export const RoutePrintSheet = () => {
  const route = useRoute()

  return (
    <WebAppSheet>
      <PrintingOptions />
      {!!route?.images.length && <ImagesOptions />}
      <DetailedMapsOptions />
      <PrintTips />
    </WebAppSheet>
  )
}
