import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DISCOVER_SLICE_KEY, DiscoverState } from './types'
import { loadMoreDiscoverResults, updateDiscoverResults } from './thunks'
import { LngLatBoundsArray, getPolygonFromBounds } from 'shared/util-geo'
import { intersect } from '@turf/intersect'
import { area } from '@turf/area'

export const initialState: DiscoverState = {
  isSearching: false,
  searchRequestCounter: 0,
  results: null,
  count: null,
  isLoadingMore: false,
  lastMapBounds: null,
  areResultsOffScreen: false,
}

const slice = createSlice({
  name: DISCOVER_SLICE_KEY,
  initialState,
  reducers: {
    discoverMapBoundsUpdated(state, action: PayloadAction<LngLatBoundsArray>) {
      if (!state.lastMapBounds) return
      const mapBoundsPolygon = getPolygonFromBounds(action.payload)
      const lastMapBoundsPolygon = getPolygonFromBounds(state.lastMapBounds)
      const intersection = intersect({
        type: 'FeatureCollection',
        features: [
          { type: 'Feature', geometry: mapBoundsPolygon, properties: {} },
          { type: 'Feature', geometry: lastMapBoundsPolygon, properties: {} },
        ],
      })
      const intersectionArea = intersection ? area(intersection) : 0
      state.areResultsOffScreen =
        !intersectionArea ||
        intersectionArea < area(mapBoundsPolygon) / 3 ||
        intersectionArea < area(lastMapBoundsPolygon) / 2
    },
  },
  extraReducers(builder) {
    builder.addCase(updateDiscoverResults.pending, (state, action) => {
      state.isSearching = true
      state.searchRequestCounter++
      state.isLoadingMore = false
      state.lastMapBounds = action.meta.arg.bounds
      state.areResultsOffScreen = false
    })
    builder.addCase(updateDiscoverResults.fulfilled, (state, action) => {
      if (!action.payload) return
      state.results = action.payload.results
      state.count = action.payload.count
      state.isSearching = false
    })
    builder.addCase(updateDiscoverResults.rejected, (state) => {
      state.results = []
      state.count = 0
      state.isSearching = false
    })

    builder.addCase(loadMoreDiscoverResults.pending, (state) => {
      state.isLoadingMore = true
    })
    builder.addCase(loadMoreDiscoverResults.fulfilled, (state, action) => {
      if (!state.isLoadingMore) return
      state.isLoadingMore = false
      if (!action.payload || !state.results) return
      state.results.push(...action.payload)
    })
    builder.addCase(loadMoreDiscoverResults.rejected, (state) => {
      state.isLoadingMore = false
    })
  },
})

export const { discoverMapBoundsUpdated } = slice.actions

export const discoverReducer = slice.reducer
