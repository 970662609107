import { useMemo } from 'react'
import { MapRouteSelection } from 'shared/ui-map'
import { useElevationCurveContext } from '../context'
import { SelectionIndexes } from '../types'
import { RichLineString, RichMultiLineString, RichPosition } from 'shared/util-geo'

interface SelectionMapRouteSectionProps {
  geometry: RichLineString | RichMultiLineString
  selectionIndexes: SelectionIndexes
}

export const SelectionMapRouteSection = ({ geometry, selectionIndexes }: SelectionMapRouteSectionProps) => {
  const { elevationPointIndex } = useElevationCurveContext()

  const indexes = useMemo<{ start: number; end: number } | null>(
    () => getSelectedSectionIndexes(selectionIndexes, elevationPointIndex),
    [selectionIndexes, elevationPointIndex],
  )
  const selectedGeometry = useMemo<RichLineString | RichMultiLineString | undefined>(() => {
    if (!indexes) return undefined
    const { start, end } = indexes

    if (geometry.type === 'RichLineString') {
      return {
        type: 'RichLineString',
        coordinates: geometry?.coordinates.slice(start, end),
      }
    }

    const coordinates: RichPosition[][] = []
    let indexOffset = 0
    geometry.coordinates.forEach((segment) => {
      if (indexOffset + segment.length <= start || indexOffset >= end) {
        indexOffset += segment.length
        return
      }
      coordinates.push(segment.slice(Math.max(0, start - indexOffset), Math.min(segment.length, end - indexOffset)))
      indexOffset += segment.length
    })
    return {
      type: 'RichMultiLineString',
      coordinates,
    }
  }, [indexes, geometry.type, geometry.coordinates])

  return indexes ? <MapRouteSelection id="elevation-curve-selection" geometry={selectedGeometry} /> : null
}

/**
 * Get the indexes of the start and end geometry coordinates that define a selected section. The selection can be
 * explicitly defined by `selectionIndexes` or a preview consisting of one fixed selection index and the
 * `elevationPointIndex` (while hovering).
 */
export function getSelectedSectionIndexes(
  selectionIndexes: SelectionIndexes,
  elevationPointIndex?: number,
): { start: number; end: number } | null {
  if (selectionIndexes.length === 2) {
    return {
      start: selectionIndexes[0],
      end: selectionIndexes[1] + 1,
    }
  }
  if (elevationPointIndex !== undefined) {
    return {
      start: Math.min(selectionIndexes[0], elevationPointIndex),
      end: Math.max(selectionIndexes[0], elevationPointIndex) + 1,
    }
  }
  return null
}
