import { LngLat, LngLatBoundsArray } from 'shared/util-geo'
import { API_PATH_ROUTE_SEARCH } from '../../config'
import { EntityIndex, RouteBikeType, RouteEntity, RouteSurface, UserEntity } from '../../entities'
import { getFromCoreApi } from '../../network'
import {
  ApiResult,
  GetRequestOptions,
  ResponseParser,
  createFailureResult,
  createSuccessResult,
} from 'shared/util-network'
import { Point } from 'geojson'
import { parseUserEntity } from './helpers'
import { FilterRange } from '../../types'
import { mapSurfaceToTag, mapTagToSurface, mapToBikeType } from '../helpers'

export const ROUTE_SEARCH_SORTING_OPTIONS = [
  'relevance-desc',
  'rating-desc',
  'distance-asc',
  'distance-desc',
  'ascent-asc',
  'ascent-desc',
] as const

export type RouteSearchSorting = (typeof ROUTE_SEARCH_SORTING_OPTIONS)[number]

type ListParams = {
  page?: number
  pageSize?: number
}

type CommonRouteSearchOptions = {
  lang: 'en' | 'de'
  sorting: RouteSearchSorting
  onlyLoops?: boolean
  distanceKilometers?: FilterRange
  ascentMeters?: FilterRange
  title?: string
  bikeTypes?: RouteBikeType[]
  surfaces?: RouteSurface[]
}

type RouteSearchOptionsWithCenter = CommonRouteSearchOptions & {
  center: LngLat
}

type RouteSearchOptionsInBounds = CommonRouteSearchOptions & {
  bounds: LngLatBoundsArray
}

type RouteSearchOptionsInGeoname = CommonRouteSearchOptions & {
  geonameId: number
  bounds?: LngLatBoundsArray
}

type RouteSearchOptions = RouteSearchOptionsWithCenter | RouteSearchOptionsInBounds | RouteSearchOptionsInGeoname

type RouteSearchQueryParams = {
  ascent?: number | [number, number]
  bounds?: string
  category?: RouteBikeType[]
  center?: string
  distance_max?: number
  distance_min?: number
  location?: number
  no_location_filters?: true
  only_loop?: boolean
  page_size?: number
  page?: number
  q?: string
  route_type: 'user'
  score_nearby?: 'false'
  sort_order?: 'ASC' | 'DESC'
  sort?: string
  surfaces?: string
}

type Response = {
  ascent_total: number
  bounds: [number, number, number, number]
  count: number
  descent_total: number
  distance_total: number
  next: string | null
  previous: string | null
  results: {
    content_object: {
      altitude_difference_back: number | null
      altitude_difference: number | null
      category: number[] | null
      created: string
      distance: number
      surface_tags: string[] | null
      id: number
      is_favorite: boolean
      is_loop: boolean
      is_private: boolean
      location?: string
      route_favorite_count: number
      images:
        | {
            fallback: string
            item: string | null
            tile: string | null
          }[]
        | null
      start: Point
      staticmaps: {
        bmx_big: string | null
        preview: string | null
        small: string | null
      } | null
      title: string
      user: {
        id: number
        displayname: string
        is_subscribed: boolean
        image: {
          fallback: string
          small: string | null
        } | null
        slug: string
        profile_url: string
      } | null
      views: number
      admin_two_id: number
      duration: number | null
      average_speed: null // seems to be always null
      max_elevation: number | null
    }
    content_type: string
    title: string
  }[]
}

export type SearchRoutesData = {
  results: number[]
  routes: EntityIndex<RouteEntity>
  creators: EntityIndex<UserEntity>
  count: number
}

const UNLIMITED_ASCENT = 3001

export async function fetchRoutes(
  options: RouteSearchOptions,
  listParams: ListParams = {
    page: 1,
    pageSize: 20,
  },
): ApiResult<SearchRoutesData> {
  try {
    const [sort, order] = options.sorting.split('-')

    const requestOptions: GetRequestOptions = {
      queryParams: {
        page: listParams.page,
        page_size: listParams.pageSize,
        sort,
        sort_order: order,
        route_type: 'user',
      } as RouteSearchQueryParams,
    }

    if (options.lang) {
      requestOptions.headers = { 'Accept-Language': options.lang }
    }

    if (requestOptions.queryParams) {
      const center = (options as RouteSearchOptionsWithCenter).center
      if (center) {
        requestOptions.queryParams['center'] = `${center.lat},${center.lng}`
      }

      const bounds = (options as RouteSearchOptionsInBounds | RouteSearchOptionsInGeoname).bounds
      if (bounds) {
        requestOptions.queryParams['bounds'] = bounds.join(',')
        requestOptions.queryParams['score_nearby'] = 'false'
      }

      const geonameId = (options as RouteSearchOptionsInGeoname).geonameId
      if (geonameId) {
        requestOptions.queryParams['location'] = geonameId
      }

      if (options.onlyLoops) {
        requestOptions.queryParams['only_loop'] = true
      }

      if (options.distanceKilometers) {
        requestOptions.queryParams['distance_min'] = options.distanceKilometers[0] * 1000
        if (options.distanceKilometers[1]) {
          requestOptions.queryParams['distance_max'] = options.distanceKilometers[1] * 1000
        }
      }

      if (options.ascentMeters) {
        const [min, max] = options.ascentMeters
        requestOptions.queryParams['ascent'] = [min, max || UNLIMITED_ASCENT]
      }

      if (options.title) {
        requestOptions.queryParams['q'] = options.title
      }

      if (options.bikeTypes?.length) {
        requestOptions.queryParams['category'] = options.bikeTypes
      }

      if (options.surfaces?.length) {
        requestOptions.queryParams['surface_tags'] = options.surfaces.map(mapSurfaceToTag).join(',')
      }
    }

    const res: Response = await getFromCoreApi(API_PATH_ROUTE_SEARCH, requestOptions)

    try {
      return createSuccessResult(parseResults(res))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

function parseResults(res: Response): {
  results: number[]
  routes: EntityIndex<RouteEntity>
  creators: EntityIndex<UserEntity>
  count: number
} {
  const routes: EntityIndex<RouteEntity> = {}
  const creators: EntityIndex<UserEntity> = {}

  const results: number[] = res.results.map((result) => {
    const parser = new ResponseParser(result.content_object)
    const id = parser.requireNumber('id')
    const locationName = parser.takeString('location')
    const geonameId = parser.takeNumber('admin_two_id')
    const creator = result.content_object.user ? parseUserEntity(result.content_object.user) : null
    const distanceMeters = parser.requireNumber('distance')
    const durationSeconds = parser.takeNumber('duration')
    routes[id] = {
      id,
      title: parser.requireString('title'),
      distanceMeters,
      ascentMeters: parser.takeNumber('altitude_difference'),
      descentMeters: parser.takeNumber('altitude_difference_back'),
      isPrivate: parser.requireBoolean('is_private'),
      location: geonameId && locationName ? { geonameId, name: locationName } : null,
      favoriteCount: parser.requireNumber('route_favorite_count'),
      created: parser.requireTimestamp('created'),
      surfaces: parser.takeArray('surface_tags', mapTagToSurface),
      bikeTypes: parser.takeArray('category', mapToBikeType),
      images: parser.takeAsImageSizesList('images', {
        item: 'item',
        tile: 'tile',
        original: 'fallback',
      }),
      isFavorite: parser.requireBoolean('is_favorite'),
      isLoop: parser.requireBoolean('is_loop'),
      staticMap: parser.takeImageSizesWithoutFallback('staticmaps', {
        item: 'preview',
        tile: 'bmx_big',
        tileSecondary: 'small',
        overview: 'bmx_big',
        medium: 'bmx_big',
      }),
      start: parser.requireLngLat('start'),
      creatorId: creator?.id ?? null,
      durationSeconds,
      averageSpeedMetersPerSecond: durationSeconds ? distanceMeters / durationSeconds : null,
      maximumElevationMeters: parser.takeNumber('max_elevation'),
    }
    if (creator) {
      creators[creator.id] = creator
    }
    return id
  })

  return { results, routes, creators, count: res.count }
}
