import { API_PATH_ROUTE_FAVOR, API_PATH_ROUTE_UNFAVOR } from '../../config'
import { postToCoreApi } from '../../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export async function addRouteToFavorites(routeId: number): ApiResult<null> {
  try {
    await postToCoreApi(API_PATH_ROUTE_FAVOR, {
      params: { routeId },
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

export async function removeRouteFromFavorites(routeId: number): ApiResult<null> {
  try {
    await postToCoreApi(API_PATH_ROUTE_UNFAVOR, {
      params: { routeId },
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}
