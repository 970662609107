import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  RouteCollectionCategory,
  RouteCollectionChangeForm,
  RouteCollectionEntity,
  RouteCollectionEntityDetails,
  RouteCollectionForm,
  changeRouteCollection,
  createRouteCollection,
  fetchRouteCollection,
  fetchRouteCollectionRoutes,
  fetchRouteGeometry,
} from 'shared/data-access-core'
import {
  CollectionRoutesParams,
  ROUTE_COLLECTION_SLICE_KEY,
  RouteCollectionSliceDispatch,
  StateWithRouteCollectionSlice,
} from './types'
import { INTL_SLICE_KEY, StateWithIntlSlice } from 'shared/util-intl'
import { COLLECTION_ROUTES_PAGE_SIZE } from '../definitions'
import { routeCollectionSliceSelector } from './selectors'
import { routesFetched, userFetched, usersFetched } from 'web-app/data-access-entities'
import { routeSelected } from './state'
import { logError } from 'shared/util-error-handling'
import { RichLineString } from 'shared/util-geo'

/**
 * Save a new route collection to the server and get the result as global state.
 */
export const createGlobalRouteCollection = createAsyncThunk<
  RouteCollectionEntity & RouteCollectionEntityDetails,
  RouteCollectionForm,
  { dispatch: RouteCollectionSliceDispatch; state: StateWithRouteCollectionSlice }
>(ROUTE_COLLECTION_SLICE_KEY + '/create', async (form, { dispatch }) => {
  const res = await createRouteCollection(form)
  if (res.success) {
    dispatch(userFetched(res.data.owner))
    return res.data.collection
  }
  throw Error('Failed to create route collection')
})

/**
 * Fetch a route collection from the server and get the result as global state.
 */
export const fetchGlobalRouteCollection = createAsyncThunk<
  RouteCollectionEntity & RouteCollectionEntityDetails,
  number,
  { dispatch: RouteCollectionSliceDispatch; state: StateWithRouteCollectionSlice }
>(ROUTE_COLLECTION_SLICE_KEY + '/fetch', async (routeCollectionId, { dispatch }) => {
  const res = await fetchRouteCollection(routeCollectionId)
  if (res.success) {
    dispatch(usersFetched(res.data.owner))
    return res.data.collection
  }
  throw Error('Failed to fetch route collection')
})

/**
 * Save patch changes for the current main route collection to the server and get the result as global state.
 */
export const saveGlobalRouteCollectionChanges = createAsyncThunk<
  RouteCollectionEntity & RouteCollectionEntityDetails,
  RouteCollectionChangeForm,
  { dispatch: RouteCollectionSliceDispatch; state: StateWithRouteCollectionSlice }
>(ROUTE_COLLECTION_SLICE_KEY + '/saveChanges', async (form, { dispatch, getState }) => {
  const { routeCollection } = getState()[ROUTE_COLLECTION_SLICE_KEY]
  if (routeCollection) {
    const res = await changeRouteCollection(routeCollection.id, form || {})
    if (res.success) {
      dispatch(userFetched(res.data.owner))
      return res.data.collection
    }
  }
  throw Error('Failed to save route collection changes')
})

/**
 * Fetch route collection routes from the server and get them as global state.
 */
export const fetchGlobalRouteCollectionRoutes = createAsyncThunk<
  { assignedRouteIds: number[]; routeIdsInTourOrder: number[] | null; filteredRoutesCount: number },
  { routeCollectionId: number; params: CollectionRoutesParams },
  { dispatch: RouteCollectionSliceDispatch; state: StateWithRouteCollectionSlice & StateWithIntlSlice }
>(ROUTE_COLLECTION_SLICE_KEY + '/fetchRoutes', async ({ routeCollectionId, params }, { dispatch, getState }) => {
  const state = getState()
  const { language } = state[INTL_SLICE_KEY]
  const res = await fetchRouteCollectionRoutes(
    routeCollectionId,
    {
      lang: language,
      ...params,
    },
    {
      pageSize: COLLECTION_ROUTES_PAGE_SIZE,
    },
  )
  if (res.success) {
    dispatch(routesFetched(res.data.routes))
    dispatch(usersFetched(res.data.creators))
    return {
      assignedRouteIds: res.data.results,
      routeIdsInTourOrder: res.data.tourOrderResults,
      filteredRoutesCount: res.data.count,
    }
  }
  throw Error('Failed to fetch route collection routes')
})

/**
 * Fetch more route collection routes from the server and append them to global state.
 */
export const fetchMoreGlobalRouteCollectionRoutes = createAsyncThunk<
  { assignedRouteIds: number[]; filteredRoutesCount: number },
  CollectionRoutesParams,
  { dispatch: RouteCollectionSliceDispatch; state: StateWithRouteCollectionSlice & StateWithIntlSlice }
>(ROUTE_COLLECTION_SLICE_KEY + '/fetchMoreRoutes', async (params, { dispatch, getState }) => {
  const state = getState()
  const { language } = state[INTL_SLICE_KEY]
  const { assignedRouteIds, filteredRoutesCount, routeCollection } = routeCollectionSliceSelector(state)
  if (!routeCollection || !assignedRouteIds || assignedRouteIds.length === filteredRoutesCount) {
    throw Error('Cannot fetch more collection routes')
  }
  const res = await fetchRouteCollectionRoutes(
    routeCollection.id,
    {
      lang: language,
      ...params,
    },
    {
      pageSize: COLLECTION_ROUTES_PAGE_SIZE,
      page: assignedRouteIds.length / COLLECTION_ROUTES_PAGE_SIZE + 1,
    },
  )
  if (res.success) {
    dispatch(routesFetched(res.data.routes))
    dispatch(usersFetched(res.data.creators))
    return {
      assignedRouteIds: res.data.results,
      filteredRoutesCount: res.data.count,
    }
  }
  throw Error('Failed to fetch more route collection routes')
})

/**
 * Fetch geometry to preview a collection route on the map.
 */
export const fetchCollectionRouteGeometry = createAsyncThunk<RichLineString, number>(
  ROUTE_COLLECTION_SLICE_KEY + '/fetchRouteGeometry',
  async (routeId) => {
    const res = await fetchRouteGeometry(routeId)
    if (res.success) {
      return res.data
    }
    throw Error('Failed to fetch route collection route geometry')
  },
)

/**
 * Select a route for preview and fetch missing data if necessary.
 */
export function selectRoute(routeId: number) {
  return async (dispatch: RouteCollectionSliceDispatch, getState: () => StateWithRouteCollectionSlice) => {
    const { geometries } = routeCollectionSliceSelector(getState())
    if (!geometries[routeId]) {
      dispatch(fetchCollectionRouteGeometry(routeId))
    }
    dispatch(routeSelected(routeId))
  }
}

/**
 * Fetch a route collection, first page of assigned routes and, if necessary, geometries from the server and get
 * the result as global state.
 */
export function fetchGlobalRouteCollectionAndRoutes(routeCollectionId: number, params: CollectionRoutesParams) {
  return async (dispatch: RouteCollectionSliceDispatch) => {
    try {
      const [routeCollection, { assignedRouteIds }] = await Promise.all([
        dispatch(fetchGlobalRouteCollection(routeCollectionId)).unwrap(),
        dispatch(fetchGlobalRouteCollectionRoutes({ routeCollectionId, params })).unwrap(),
      ])
      if (routeCollection.category === RouteCollectionCategory.Tour) {
        assignedRouteIds.forEach((routeId) => dispatch(fetchCollectionRouteGeometry(routeId)))
      }
    } catch {
      logError('Route collection with routes cuold not be found.')
    }
  }
}

/**
 * Fetch geometry for given route IDs, if not already fetched.
 */
export function fetchMissingPreviewGeometries(routeIds: number[]) {
  return async (dispatch: RouteCollectionSliceDispatch, getState: () => StateWithRouteCollectionSlice) => {
    const { geometries } = routeCollectionSliceSelector(getState())
    for (const routeId of routeIds) {
      if (!geometries[routeId]) {
        dispatch(fetchCollectionRouteGeometry(routeId))
      }
    }
  }
}
