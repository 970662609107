import { WebAppContent } from 'web-app/ui-layout'
import { PLACE_DEFAULT_ZOOM_LEVEL, REGION_DEFAULT_ZOOM_LEVELS, Search } from 'web-app/feature-search'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { viewportDesired } from 'web-app/feature-map'
import { lngLatToPosition2d } from 'shared/util-geo'
import { DiscoverFilters, useDiscoverParamsString, useDiscoverState } from 'web-app/feature-discover'
import { RegionEntity, RegionEntityMapData } from 'shared/data-access-core'
import { useNavigateToDiscover, useNavigateToRegion, useNavigateToRouteDetails } from 'web-app/feature-navigation'

interface SearchSectionProps {
  regionName: string
}

export const SearchSection = ({ regionName }: SearchSectionProps) => {
  const dispatch = useDispatch()
  const navigateToDiscover = useNavigateToDiscover()
  const navigateToRegion = useNavigateToRegion()
  const navigateToRouteDetails = useNavigateToRouteDetails()
  const discoverParamsString = useDiscoverParamsString()
  const { count, isSearching } = useDiscoverState()

  const handlePlaceSelect = useCallback(
    (result: GeocoderLocation) => {
      dispatch(
        viewportDesired({
          center: lngLatToPosition2d(result.position),
          zoom: PLACE_DEFAULT_ZOOM_LEVEL,
        }),
      )
      navigateToDiscover({ selectedLocation: result })
    },
    [dispatch, navigateToDiscover],
  )

  const handleRegionSelect = useCallback(
    (result: RegionEntity & RegionEntityMapData) => {
      dispatch(
        viewportDesired({
          center: lngLatToPosition2d(result.center),
          zoom: REGION_DEFAULT_ZOOM_LEVELS[result.kind],
        }),
      )
      navigateToRegion(result.id, { regionPreview: result }, discoverParamsString)
    },
    [discoverParamsString, dispatch, navigateToRegion],
  )

  return (
    <WebAppContent noPadding stickyOnTop zIndex={3}>
      <Search
        defaultValue={regionName}
        onPlaceSelect={handlePlaceSelect}
        onRegionSelect={handleRegionSelect}
        onRouteSelect={(result) => navigateToRouteDetails(result.id)}
        onDefaultReset={() => navigateToDiscover({ keepMapViewport: true })}
        after={<DiscoverFilters count={count} isSearching={isSearching} />}
      />
    </WebAppContent>
  )
}
