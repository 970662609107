import { DISCOVER_SLICE_KEY, DiscoverSliceDispatch, StateWithDiscoverSlice } from './types'
import { discoverSliceSelector } from './selectors'
import { fetchRoutes } from 'shared/data-access-core'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { StateWithMapSlice } from 'web-app/feature-map'
import { INTL_SLICE_KEY, StateWithIntlSlice } from 'shared/util-intl'
import { DiscoverParams } from '../use-discover-params'
import { LngLatBoundsArray } from 'shared/util-geo'
import { NUM_ROUTES_PER_PAGE } from '../definitions'
import { routesFetched, usersFetched } from 'web-app/data-access-entities'

/**
 * Fetch discover results from the server and get them as global state.
 */
export const updateDiscoverResults = createAsyncThunk<
  { results: number[]; count: number } | undefined,
  { params: DiscoverParams; bounds: LngLatBoundsArray; geonameId?: number },
  { dispatch: DiscoverSliceDispatch; state: StateWithDiscoverSlice & StateWithIntlSlice }
>(DISCOVER_SLICE_KEY + '/update', async ({ params, bounds, geonameId }, { dispatch, getState }) => {
  const state = getState()
  const { language } = state[INTL_SLICE_KEY]
  const counter = state[DISCOVER_SLICE_KEY].searchRequestCounter
  const res = await fetchRoutes(
    {
      bounds,
      geonameId,
      lang: language,
      ...params,
    },
    {
      pageSize: NUM_ROUTES_PER_PAGE,
    },
  )
  if (getState()[DISCOVER_SLICE_KEY].searchRequestCounter !== counter) return
  if (res.success) {
    dispatch(routesFetched(res.data.routes))
    dispatch(usersFetched(res.data.creators))
    return res.data
  }
  throw Error('Failed to fetch discover results')
})

/**
 * Fetch more discover results from the server and append them to global state.
 */
export const loadMoreDiscoverResults = createAsyncThunk<
  number[] | undefined,
  { params: DiscoverParams; bounds: LngLatBoundsArray; geonameId?: number },
  { dispatch: DiscoverSliceDispatch; state: StateWithDiscoverSlice & StateWithMapSlice & StateWithIntlSlice }
>(DISCOVER_SLICE_KEY + '/loadMore', async ({ params, bounds, geonameId }, { dispatch, getState }) => {
  const state = getState()
  const { language } = state[INTL_SLICE_KEY]
  const { isSearching, results, count } = discoverSliceSelector(state)
  if (!bounds || isSearching || !results || results.length === count) return
  const res = await fetchRoutes(
    {
      bounds,
      lang: language,
      geonameId,
      ...params,
    },
    {
      pageSize: NUM_ROUTES_PER_PAGE,
      page: results.length / NUM_ROUTES_PER_PAGE + 1,
    },
  )
  if (res.success) {
    dispatch(routesFetched(res.data.routes))
    dispatch(usersFetched(res.data.creators))
    return res.data.results
  }
  throw Error('Failed to load more discover results')
})
