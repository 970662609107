import { useMemo } from 'react'
import { FeatureCollection, Position } from 'geojson'
import { Layer, Source } from 'react-map-gl/maplibre'
import { useMapImage } from '../use-map-image'
import { ROUTE_DESTINATION_MARKER_LAYER_ID, ROUTE_GEOMETRY_SOURCE_ID, ROUTE_ORIGIN_MARKER_LAYER_ID } from '../settings'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { useRouteLines } from './use-route-lines'
import { DESTINATION_MARKER_PROPS, ORIGIN_MARKER_PROPS } from './helpers'
import { RichLineString, reduceToPosition } from 'shared/util-geo'

import originMarkerImg from '../img/route-marker-origin.png'
import locationMarkerImg from '../img/location-marker.png'
import destinationMarkerImg from '../img/route-marker-destination.png'

interface MapRoutePreviewProps {
  id: string
  geometry: RichLineString
}

/**
 * Minimal representation of a route on the map for preview.
 */
export const MapRoutePreview = ({ id, geometry }: MapRoutePreviewProps) => {
  useMapImage(originMarkerImg, 'route-marker-origin')
  useMapImage(locationMarkerImg, 'location-marker')
  useMapImage(destinationMarkerImg, 'route-marker-destination')

  const routeLayerBeforeId = useRouteLayerBeforeId()
  const routeLines = useRouteLines(id, [geometry.coordinates as Position[]])

  const sourceData = useMemo<FeatureCollection>(
    () => ({
      type: 'FeatureCollection',
      features: [
        ...routeLines.features,
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: reduceToPosition(geometry.coordinates[0]),
          },
          properties: {
            type: 'origin',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: reduceToPosition(geometry.coordinates[geometry.coordinates.length - 1]),
          },
          properties: {
            type: 'destination',
          },
        },
      ],
    }),
    [geometry.coordinates, routeLines.features],
  )

  const sourceId = useMemo<string>(() => ROUTE_GEOMETRY_SOURCE_ID.replace('{baseId}', id), [id])

  return (
    <Source id={sourceId} type="geojson" data={sourceData}>
      <Layer {...routeLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...ORIGIN_MARKER_PROPS} id={ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', id)} />
      <Layer {...DESTINATION_MARKER_PROPS} id={ROUTE_ORIGIN_MARKER_LAYER_ID.replace('{baseId}', id)} />
    </Source>
  )
}
