import { SortingField } from './sorting-field'
import { OnlyLoopsField } from './only-loops-field'
import { DistanceField } from './distance-field'
import { AscentField } from './ascent-field'
import { TitleField } from './title-field'
import { BikeTypesField } from './bike-types-field'
import { SurfacesField } from './surfaces-field'
import React from 'react'
import { RouteSearchSorting } from 'shared/data-access-core'

import styles from './index.module.scss'

interface FiltersFormProps<ExtraSortingOptionType> {
  extraSortingOptions?: { value: ExtraSortingOptionType; label: string }[]
  defaultSorting?: RouteSearchSorting | ExtraSortingOptionType
  onSubmit: () => void
}

export function FiltersForm<ExtraSortingOptionType extends string = RouteSearchSorting>({
  extraSortingOptions,
  defaultSorting,
  onSubmit,
}: FiltersFormProps<ExtraSortingOptionType>) {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form className={styles['form']} onSubmit={handleSubmit}>
      <SortingField extraSortingOptions={extraSortingOptions} defaultSorting={defaultSorting} />
      <OnlyLoopsField />
      <DistanceField />
      <AscentField />
      <TitleField />
      <BikeTypesField />
      <SurfacesField />
    </form>
  )
}
