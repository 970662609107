import { addRouteToFavorites, fetchUserRouteCollections, removeRouteFromFavorites } from 'shared/data-access-core'
import { StateWithUserSlice, UserSliceDispatch, favoriteRoutesCountUpdated, userRouteCollectionsFetched } from './state'
import {
  StateWithEntitiesSlice,
  entitiesSliceSelector,
  routeAddedToFavorites,
  routeCollectionsFetched,
  routeRemovedFromFavorites,
} from 'web-app/data-access-entities'
import { userSliceSelector } from './selectors'

export const fetchGlobalUserRouteCollections =
  () => async (dispatch: UserSliceDispatch, getState: () => StateWithUserSlice) => {
    const { user } = userSliceSelector(getState())
    if (!user) throw Error('Cannot fetch global user route collections - global user not available')
    const res = await fetchUserRouteCollections(user.id)
    if (res.success) {
      dispatch(routeCollectionsFetched(res.data.routeCollections))
      dispatch(userRouteCollectionsFetched(res.data.userRouteCollectionIds))
    }
  }

/** Add a route to or remove it from the favorites of the global user. */
export function toggleFavoriteRoute(routeId: number) {
  return async (dispatch: UserSliceDispatch, getState: () => StateWithUserSlice & StateWithEntitiesSlice) => {
    const state = getState()
    const { routes } = entitiesSliceSelector(state)
    const route = routes[routeId]
    const { user } = userSliceSelector(state)
    if (!route || !user) return
    if (route.isFavorite) {
      dispatch(routeRemovedFromFavorites({ routeId }))
      const result = await removeRouteFromFavorites(route.id)
      if (result.success) {
        dispatch(favoriteRoutesCountUpdated(user.favoriteRoutesCount - 1))
      } else {
        dispatch(routeAddedToFavorites({ routeId }))
      }
    } else {
      dispatch(routeAddedToFavorites({ routeId }))
      const result = await addRouteToFavorites(route.id)
      if (result.success) {
        dispatch(favoriteRoutesCountUpdated(user.favoriteRoutesCount + 1))
      } else {
        dispatch(routeRemovedFromFavorites({ routeId }))
      }
    }
  }
}
