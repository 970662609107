import { API_PATH_ROUTE_COLLECTION } from '../../config'
import { RouteCollectionEntity, RouteCollectionEntityDetails, UserEntity } from '../../entities'
import { patchToCoreApi } from '../../network'
import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'

export type RouteCollectionChangeForm = {
  description?: string
  title?: string
  isPrivate?: boolean
  image?: File | null
}

type RequestBody = {
  description?: string
  title?: string
  visibility?: boolean
  cover_image?: File | ''
}

type Response = {
  ascent_total: number
  category: number | null
  created: string
  descent_total: number
  description: string
  distance_total: number
  duration_total: number
  has_hidden_routes?: boolean
  id: number
  image: {
    fallback: string
    item: string | null
    large: string | null
    large_wide: string | null
    open_graph: string | null
    small: string | null
    small_wide: string | null
    tile: string | null
  } | null
  max_elevation: number | null
  modified: string
  route_count: number
  should_seo_index: boolean
  title: string
  user: {
    id: number
    displayname: string
    is_subscribed: boolean
    slug: string
    image: {
      fallback: string
      small: string | null
    } | null
  }
  visibility: boolean
}

export async function changeRouteCollection(
  routeCollectionId: number,
  form: RouteCollectionChangeForm,
): ApiResult<{
  collection: RouteCollectionEntity & RouteCollectionEntityDetails
  owner: UserEntity
}> {
  try {
    const { image, isPrivate, ...changes } = form

    const body: RequestBody = { ...changes }

    if (isPrivate !== undefined) {
      body.visibility = !isPrivate
    }
    if (image !== undefined) {
      body.cover_image = image || ''
    }

    const res: Response = await patchToCoreApi(API_PATH_ROUTE_COLLECTION, {
      params: {
        routeCollectionId,
      },
      body,
    })

    try {
      const parser = new ResponseParser(res)
      const categoryValue = parser.takeNumber('category')

      const owner: UserEntity = {
        id: parser.in('user').requireNumber('id'),
        slug: parser.in('user').requireString('slug'),
        name: parser.in('user').requireString('displayname'),
        avatar: parser.in('user').takeImageSizes('image', {
          small: 'small',
        }),
        isPremium: parser.in('user').requireBoolean('is_subscribed'),
      }

      return createSuccessResult({
        collection: {
          category: categoryValue && categoryValue >= 1 && categoryValue <= 3 ? categoryValue : null,
          created: parser.requireTimestamp('created'),
          description: parser.takeString('description'),
          hasPrivateRoutes: parser.takeAsBoolean('has_hidden_routes'),
          id: parser.requireNumber('id'),
          image: parser.takeImageSizes('image', {
            item: 'item',
            tile: 'tile',
            small: 'small',
            smallWide: 'small_wide',
            large: 'large',
            largeWide: 'large_wide',
            openGraph: 'open_graph',
          }),
          isPrivate: !parser.requireBoolean('visibility'),
          maximumElevationMeters: parser.takeNumber('max_elevation'),
          owner: owner.id,
          routesCount: parser.requireNumber('route_count'),
          shouldBeIndexed: parser.requireBoolean('should_seo_index'),
          title: parser.requireString('title'),
          totalAscentMeters: parser.requireNumber('ascent_total'),
          totalDescentMeters: parser.requireNumber('descent_total'),
          totalDistanceMeters: parser.requireNumber('distance_total'),
          totalDurationSeconds: parser.requireNumber('duration_total'),
        },
        owner,
      })
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch (error) {
    return createFailureResult(
      {
        unexpectedError: true,
      },
      { routeCollectionId, form },
    )
  }
}
