import clsx from 'clsx'
import { Skeleton } from '@mui/material'
import { styleDefinitions } from '../theme'
import { ForwardedRef, ReactNode, forwardRef } from 'react'

import styles from './tag.module.scss'

export interface TagProps {
  children: ReactNode
  variant?: 'default' | 'primary' | 'dark'
  className?: string
  onClick?: () => void
}

export const Tag = forwardRef(
  (
    { children, variant = 'default', className, onClick }: TagProps,
    ref: ForwardedRef<HTMLButtonElement | HTMLSpanElement>,
  ) => {
    const props = {
      className: clsx(className, styles['tag'], [styles[variant]]),
      onClick,
    }

    return onClick ? (
      <button ref={ref as ForwardedRef<HTMLButtonElement>} type="button" {...props}>
        {children}
      </button>
    ) : (
      <span ref={ref as ForwardedRef<HTMLSpanElement>} {...props}>
        {children}
      </span>
    )
  },
)

export const TagSkeleton = () => (
  <Skeleton
    width="5rem"
    height="1.625rem"
    variant="rectangular"
    style={{ borderRadius: styleDefinitions.borderRadiusSmall }}
  />
)
