import { useLocale } from 'shared/util-intl'
import { useRouteContentEditing } from './use-route-content-editing'
import { EditablePrivacy } from 'web-app/feature-editable-content'

interface EditableRoutePrivacyProps {
  heading?: string
  isPrivate?: boolean
}

export const EditableRoutePrivacy = ({ heading, isPrivate }: EditableRoutePrivacyProps) => {
  const { intl } = useLocale()
  const contentEditingProps = useRouteContentEditing('isPrivate')

  return (
    <EditablePrivacy
      currentValue={isPrivate}
      heading={heading}
      privateDescription={intl.formatMessage({
        id: 'route_privacy_private_description',
        defaultMessage: 'The route is private and only visible to you.',
      })}
      publicDescription={intl.formatMessage({
        id: 'route_privacy_public_description',
        defaultMessage: 'The route is public and shared with the Bikemap community.',
      })}
      {...contentEditingProps}
    />
  )
}
