import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ASCENT_KEY, BIKE_TYPES_KEY, DISTANCE_KEY, ONLY_LOOPS_KEY, SURFACES_KEY, TITLE_KEY } from 'shared/util-discover'
import { SORTING_KEY } from './definitions'

export const useDiscoverReset = (): (() => void) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(() => {
    searchParams.delete(SORTING_KEY)
    searchParams.delete(ONLY_LOOPS_KEY)
    searchParams.delete(DISTANCE_KEY)
    searchParams.delete(ASCENT_KEY)
    searchParams.delete(TITLE_KEY)
    searchParams.delete(BIKE_TYPES_KEY)
    searchParams.delete(SURFACES_KEY)
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])
}
