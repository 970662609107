import { FieldSet, RadioButton, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

interface PrivacyFieldSetProps {
  value: boolean
  onChange: (value: boolean) => void
  publicDescription: string
  privateDescription: string
  noLabel?: boolean
}

export const PrivacyFieldSet = ({
  value,
  onChange,
  publicDescription,
  privateDescription,
  noLabel,
}: PrivacyFieldSetProps) => {
  const { intl } = useLocale()
  const { privateLabel, publicLabel } = useMessages()

  return (
    <FieldSet
      label={
        noLabel
          ? undefined
          : intl.formatMessage({
              id: 'privacy_field_set_label',
              defaultMessage: 'Privacy',
            })
      }
      role="radiogroup"
      bordered
    >
      <RadioButton
        name="isPrivate"
        checked={!value}
        label={publicLabel}
        description={publicDescription}
        onChange={() => onChange(false)}
      />
      <RadioButton
        name="isPrivate"
        checked={value}
        label={privateLabel}
        description={privateDescription}
        onChange={() => onChange(true)}
      />
    </FieldSet>
  )
}
