import {
  controlPointsSelector,
  hasRouteDistanceSelector,
  routePlannerSliceSelector,
  waypointsSelector,
} from '../selectors'
import {
  freehandCalculated,
  freehandSegmentsCalculated,
  routeDataOutdated,
  routingFailure,
  routingRequest,
  routingSuccess,
  segmentRoutingSuccess,
} from '../state'
import { RoutePlannerSliceDispatch, StateWithRoutePlannerSlice } from '../types'
import { INTL_SLICE_KEY, StateWithIntlSlice } from 'shared/util-intl'
import { LngLat, LngLatElevation } from 'shared/util-geo'
import { logError } from 'web-app/utils-error-handling'
import { fetchCalculatedRoute, fetchNearestPoint } from 'shared/data-access-routing'
import { calculateFreehandSegments } from '../helpers'

/**
 * Make a routing request for the entire route based on current waypoints.
 */
export function calculateRoute() {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithIntlSlice,
  ) => {
    const state = getState()

    if (routePlannerSliceSelector(state).isCalculatingRoute) {
      logError('Still waiting for previous route calculation')
      return
    }

    const { isFullRoute, waypoints } = waypointsSelector(state)
    const controlPoints = controlPointsSelector(state)

    if (isFullRoute && hasRouteDistanceSelector(state)) {
      const { routingMode, routingProfile, routingLevel } = routePlannerSliceSelector(state)

      dispatch(routingRequest())
      if (routingMode === 'routing') {
        const language = state[INTL_SLICE_KEY].language
        const res = await fetchCalculatedRoute(controlPoints || waypoints, language, routingProfile, routingLevel)
        if (res.success) {
          dispatch(
            routingSuccess({
              geometry: res.data.geometry,
              snappedWaypoints: res.data.snappedWaypoints,
            }),
          )
        } else {
          dispatch(routingFailure(res.errors))
        }
      } else if (routingMode === 'freehand') {
        const geometry = calculateFreehandSegments(controlPoints || (await getPointsWithElevation(waypoints)))
        dispatch(freehandCalculated({ geometry }))
      }
    } else if (routePlannerSliceSelector(getState()).geometry) {
      dispatch(routeDataOutdated())
    }
  }
}

/**
 * Calculate new segments between given locations and insert them at a given segment index, overwriting
 * a given number of existing segments.
 */
export function calculateSegments(requestWaypoints: LngLat[], segmentIndex: number, numSegments: number) {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithIntlSlice,
  ) => {
    if (requestWaypoints.length < 2) return

    const state = getState()
    const { routingMode, routingProfile, routingLevel, isCalculatingRoute } = routePlannerSliceSelector(state)

    if (isCalculatingRoute) {
      logError('Still waiting for previous route calculation')
      return
    }

    dispatch(routingRequest())
    if (routingMode === 'routing') {
      const language = state[INTL_SLICE_KEY].language
      const res = await fetchCalculatedRoute(requestWaypoints, language, routingProfile, routingLevel)
      if (res.success) {
        const { geometry, snappedWaypoints } = res.data
        dispatch(
          segmentRoutingSuccess({
            geometry,
            segmentIndex,
            numSegments,
            snappedWaypoints,
          }),
        )
      } else {
        dispatch(routingFailure(res.errors))
      }
    } else if (routingMode === 'freehand') {
      dispatch(
        freehandSegmentsCalculated({
          controlPoints: await getPointsWithElevation(requestWaypoints),
          segmentIndex,
          numSegments,
        }),
      )
    }
  }
}

/**
 * Make parallel nearest point requests for each point without elevation, then return a list where all points
 * have elevation defined.
 */
async function getPointsWithElevation(points: LngLat[]): Promise<LngLatElevation[]> {
  return Promise.all(
    points.map(async (point): Promise<LngLatElevation> => {
      if (point.elevation) {
        return point as LngLatElevation
      } else {
        try {
          const res = await fetchNearestPoint(point)
          if (res.success) {
            return { ...point, elevation: res.data.point.elevation }
          }
        } catch {}
        return { ...point, elevation: 0 }
      }
    }),
  )
}
