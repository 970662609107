import { MarkerProps } from 'react-map-gl/maplibre'
import MapMarkerInteractionSvg from './svgs/map-marker-interaction.svg'
import SvgMapMarker from './shared/svg-map-marker'
import { Tooltip } from '@mui/material'
import { colors } from 'shared/ui-design-system'
import { useEffect, useState } from 'react'

interface MapMarkerInteractionProps extends MarkerProps {
  tooltip?: string
}

/**
 * Map marker for dragging Polyline.
 */
export const MapMarkerInteraction = ({ tooltip, ...markerProps }: MapMarkerInteractionProps) => {
  const [currentTooltip, setCurrentTooltip] = useState<string>(tooltip || '')
  useEffect(() => {
    if (tooltip) {
      setCurrentTooltip(tooltip)
    }
  }, [tooltip])

  return (
    <SvgMapMarker
      {...markerProps}
      svg={MapMarkerInteractionSvg}
      anchor="center"
      style={{ width: 26, height: 26, ...markerProps.style }}
      offset={[0, 0]}
    >
      <Tooltip
        open={!!tooltip}
        title={currentTooltip}
        placement="top"
        arrow
        disableInteractive
        componentsProps={{
          popper: {
            modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
          },
          tooltip: {
            sx: { backgroundColor: colors.actionColor.primary },
          },
          arrow: {
            sx: { color: colors.actionColor.primary },
          },
        }}
      >
        <div style={{ position: 'absolute', inset: 0 }} />
      </Tooltip>
    </SvgMapMarker>
  )
}

export default MapMarkerInteraction
