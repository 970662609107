import { useDispatch } from 'react-redux'
import {
  RoutePlannerSliceDispatch,
  drawRoute,
  locationSelected,
  selectLocation,
  selectionCanceled,
  useRoutePlannerState,
} from '../state'
import { MapLayerMouseEvent } from 'react-map-gl/maplibre'
import { useMapLocationSelection, useWebAppMap } from 'web-app/feature-map'
import { useElevationCurveContext } from 'shared/feature-elevation-curve'
import { useCallback, useMemo } from 'react'
import { useBreakpoints } from 'web-app/ui-layout'

export const useMapInteraction = () => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const map = useWebAppMap()
  const { selectionIndexes, onSelectionIndexesChange } = useElevationCurveContext()
  const { selectedLocation, selectedWaypoint, isCalculatingRoute } = useRoutePlannerState()
  const { layoutBreakpoint } = useBreakpoints()

  const isLocationSelectionActive = useMemo<boolean>(
    () => !(selectedLocation || selectedWaypoint !== null),
    [selectedLocation, selectedWaypoint],
  )

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const currentMap = map?.getMap()

      if (selectionIndexes) {
        onSelectionIndexesChange()
      }

      if (!currentMap || selectedLocation || selectedWaypoint !== null) {
        dispatch(selectionCanceled())
      } else if (layoutBreakpoint && !isCalculatingRoute) {
        dispatch(drawRoute(event.lngLat))
      }
    },
    [
      map,
      selectionIndexes,
      selectedLocation,
      selectedWaypoint,
      layoutBreakpoint,
      isCalculatingRoute,
      onSelectionIndexesChange,
      dispatch,
    ],
  )

  useMapLocationSelection(
    ({ position: { lng, lat }, address, poiName }) => {
      if (selectionIndexes) {
        onSelectionIndexesChange()
      }
      if (address) {
        dispatch(
          locationSelected({
            lng,
            lat,
            poiName,
            address,
          }),
        )
      } else {
        dispatch(selectLocation({ lng, lat }, poiName))
      }
    },
    handleMapClick,
    isLocationSelectionActive,
  )
}
