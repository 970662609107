import { ReactNode, useMemo } from 'react'
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone'
import { uniqueId } from 'lodash'
import clsx from 'clsx'
import { CircularProgress } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Button, ButtonProps } from '../Button'
import { useMessages } from '../MessagesProvider'
import { ButtonGroup } from '../ButtonGroup'

import styles from './Uploader.module.scss'

export type UploaderVariant = 'default' | 'primary'

interface UploaderContainerProps {
  variant?: UploaderVariant
  label: string
  dropzoneRootProps: DropzoneRootProps
  dropzoneInputProps: DropzoneInputProps
  loading?: boolean
  hideLabel?: boolean
  children?: ReactNode
  inputDataTestId?: string
  messages: {
    dropFilesHere: string
  }
  isDragActive: boolean
  renderUploadButton: (props: ButtonProps) => ReactNode
  renderCancelButton?: () => ReactNode
  renderActions?: () => ReactNode
}

export const UploaderContainer = ({
  variant = 'default',
  label,
  dropzoneRootProps,
  dropzoneInputProps,
  loading,
  hideLabel,
  children,
  inputDataTestId,
  messages,
  isDragActive,
  renderUploadButton,
  renderCancelButton,
  renderActions,
}: UploaderContainerProps) => {
  const inputId = useMemo(() => uniqueId('uploader-'), [])

  return (
    <>
      <label htmlFor={inputId} className={hideLabel ? styles['invisible-label'] : styles['label']}>
        {label}
      </label>
      <div
        className={clsx(styles['container'], styles[variant], { [styles['loading']]: loading })}
        {...dropzoneRootProps}
        tabIndex={-1}
      >
        <input id={inputId} {...dropzoneInputProps} data-testid={inputDataTestId || 'uploader'} />
        <div className={styles['content']}>
          <div className={clsx(styles['content-grid'], { [styles['invisible']]: isDragActive || loading })}>
            {children}
          </div>
          {loading && (
            <div className={styles['overlay']}>
              <CircularProgress size={'1.5rem'} />
            </div>
          )}
        </div>
        {(!loading || renderCancelButton) && (
          <div className={clsx(styles['actions'], { [styles['invisible']]: isDragActive })}>
            <ButtonGroup>
              {!loading && renderUploadButton({ variant: variant === 'primary' ? 'primary' : 'secondary' })}
              {loading && renderCancelButton && renderCancelButton()}
            </ButtonGroup>
            {renderActions && <div onClick={(e) => e.stopPropagation()}>{renderActions()}</div>}
          </div>
        )}
        {isDragActive && <div className={styles['overlay']}>{messages.dropFilesHere}</div>}
      </div>
    </>
  )
}

interface UploaderFilePreviewProps {
  imageUrl?: string
  fileType: string
  wide?: boolean
  onRemove: () => void
}

export const UploaderFilePreview = ({ imageUrl, fileType, wide, onRemove }: UploaderFilePreviewProps) => {
  const { deleteLabel } = useMessages()

  return (
    <div
      className={clsx(styles['preview-file'], { [styles['preview-file-wide']]: wide })}
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        className={styles['delete-button']}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onRemove()
        }}
        icon={<DeleteForeverIcon />}
        variant="danger-secondary"
        size="small"
        ariaLabel={deleteLabel}
      />
      {imageUrl ? (
        <img className={styles['preview-image']} src={imageUrl} alt="" />
      ) : (
        <div className={styles['preview-text']}>{fileType}</div>
      )}
    </div>
  )
}
