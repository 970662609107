import clsx from 'clsx'
import { useEffect } from 'react'
import { getCookieDeclarationUrl, getPrivacyPolicyUrl } from 'shared/util-navigation'
import { useLocale, AvailableLocale } from 'shared/util-intl'
import { enableAnalyticsCookies } from 'shared/util-analytics'
import { CookieBanner, Link } from 'shared/ui-components'
import { CookieConsentSettings, CookiePreferences } from './types'

import styles from './cookie-consent.module.scss'

export interface CookieConsentProps {
  unitPreference: 'metric' | 'imperial'
  settings: CookieConsentSettings
  hidden: boolean
  onSave: (settings: CookiePreferences) => void
  onLanguageSelect: (lang: AvailableLocale) => void
}

export function CookieConsent({ unitPreference, settings, hidden, onSave, onLanguageSelect }: CookieConsentProps) {
  const { intl, language } = useLocale()

  const onAcceptAll = (): void => {
    onSave({
      statistics: true,
    })
  }

  const tabIndex = hidden ? -1 : 0

  useEffect(() => {
    if (settings.statistics) {
      enableAnalyticsCookies()
    }
  }, [settings.statistics])

  const privacyPolicyMessage = intl.formatMessage({
    id: 'cookie_consent_link_privacy_policy',
    defaultMessage: 'Privacy policy',
  })
  const cookieDeclarationMessage = intl.formatMessage({
    id: 'cookie_consent_link_cookie_declaration',
    defaultMessage: 'Cookie declaration',
  })

  const messages = {
    explanation: [
      intl.formatMessage({
        id: 'cookie_consent_explanation_paragraph_1',
        defaultMessage:
          'Bikemap values its users privacy, and therefore request your permission to use Cookies to process personal ' +
          'data like IP-address or browser information in order to personalize the advertisement you see. Cookies are ' +
          "small text files that can be used by websites to make a user's experience more efficient.",
      }),
      intl.formatMessage({
        id: 'cookie_consent_explanation_paragraph_2',
        defaultMessage:
          'This site uses different types of cookies. Some cookies are placed by third party services that appear ' +
          'on our pages. These technologies may access your device and help us to show you more relevant ads and ' +
          'improve your internet experience. We also use it in order to measure results or align our website content.',
      }),
      intl.formatMessage({
        id: 'cookie_consent_explanation_paragraph_3',
        defaultMessage:
          'According to the GDPR and the EDBP Guidelines, we can store cookies on your device if they are strictly ' +
          'necessary for the operation of this site. You can at any time change or withdraw your consent from the Cookie ' +
          'Declaration on our website by clicking on "Privacy Controls" at the bottom of the page.',
      }),
      intl.formatMessage(
        {
          id: 'cookie_consent_explanation_paragraph_4',
          defaultMessage:
            'If you have any questions regarding our cookies, you can contact {privacyLink} stating your consent ID and date.',
        },
        {
          privacyLink: (
            <Link href={'mailto:privacy@bikemap.net'} tabIndex={tabIndex}>
              privacy@bikemap.net
            </Link>
          ),
        },
      ),
    ],
    title: intl.formatMessage({
      id: 'cookie_consent_title',
      defaultMessage: 'Privacy controls',
    }),
    necessaryLabel: intl.formatMessage({
      id: 'cookie_consent_label_necessary',
      defaultMessage: 'Necessary',
    }),
    requiredAddition: intl.formatMessage({
      id: 'cookie_consent_label_required',
      defaultMessage: '(required)',
    }),
    statisticsLabel: intl.formatMessage({
      id: 'cookie_consent_label_statistics',
      defaultMessage: 'Statistics',
    }),
    learnMore: intl.formatMessage(
      {
        id: 'cookie_banner_learn_more',
        defaultMessage:
          'Learn more about who we are, how you can contact us, how we process your data, and detailed information on all our cookies by visiting our {privacyLink} and {cookieLink}.',
      },
      {
        privacyLink: (
          <Link
            href={getPrivacyPolicyUrl({
              language,
              unitPreference,
              cookieConsentStatistics: settings.statistics || null,
            })}
            tabIndex={tabIndex}
          >
            {privacyPolicyMessage}
          </Link>
        ),
        cookieLink: (
          <Link
            href={getCookieDeclarationUrl({
              language,
              unitPreference,
              cookieConsentStatistics: settings.statistics || null,
            })}
            tabIndex={tabIndex}
          >
            {cookieDeclarationMessage}
          </Link>
        ),
      },
    ),
    saveSelectionLabel: intl.formatMessage({
      id: 'cookie_consent_label_save_selection',
      defaultMessage: 'Save selection',
    }),
    acceptAllLabel: intl.formatMessage({
      id: 'cookie_consent_label_accept_all',
      defaultMessage: 'Accept all',
    }),
  }

  const cookieBannerProps = {
    locale: intl.locale as AvailableLocale,
    onLanguageSelect,
    onSave,
    onAcceptAll,
    messages,
    initialPreferences: settings,
    isHidden: hidden,
  }

  return (
    <div className={clsx(styles['root'], { [styles['hidden']]: hidden })}>
      <CookieBanner {...cookieBannerProps} />
    </div>
  )
}
