import Directions from '@mui/icons-material/Directions'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { Button, ButtonGroup } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { GeocoderLocation, reverseGeocode } from 'shared/data-access-geocoding'
import { MapMarkerLocation } from 'shared/ui-map'
import { SelectedMapLocation, useMapLocationSelection } from './use-map-location-selection'
import { LocationMapPopup } from './popups'
import { useNavigateToRoutePlanner } from 'web-app/feature-navigation'

interface MapLocationSelectionProps {
  selectedLocation: SelectedMapLocation | null
  interactive?: boolean
  onSelect: (selectedLocation: SelectedMapLocation) => void
  onGeocoded: (selectedLocation: GeocoderLocation) => void
  onCancel: () => void
}

export const MapLocationSelection = ({
  selectedLocation,
  interactive = true,
  onSelect,
  onGeocoded,
  onCancel,
}: MapLocationSelectionProps) => {
  const navigateToRoutePlanner = useNavigateToRoutePlanner()
  const { intl, language } = useLocale()

  useMapLocationSelection(
    async (location) => {
      onCancel()
      onSelect(location)
      if (!location.address) {
        const position = location.position
        const res = await reverseGeocode(position, language)
        if (res.success) {
          onGeocoded({
            ...res.data,
            position: location.position,
            poiName: location.poiName,
            type: location.poiName ? 'poi' : res.data.type,
          })
        } else {
          onGeocoded({
            ...location,
            address: intl.formatMessage({
              id: 'reverse_geocoding_unknown_address',
              defaultMessage: 'Unknown address',
            }),
            poiName: location.poiName,
            type: 'address',
          })
        }
      }
    },
    () => {
      if (selectedLocation) {
        onCancel()
      }
    },
    !selectedLocation && interactive,
  )

  if (!selectedLocation) return null
  const { position, address, poiName } = selectedLocation
  return (
    <>
      <MapMarkerLocation longitude={position.lng} latitude={position.lat} />
      <LocationMapPopup
        longitude={position.lng}
        latitude={position.lat}
        geocoded={address ? (selectedLocation as GeocoderLocation) : undefined}
      >
        <ButtonGroup>
          <Button
            icon={<Directions />}
            onClick={() => {
              navigateToRoutePlanner({
                destination: {
                  ...position,
                  address,
                  poiName,
                },
              })
            }}
          >
            {intl.formatMessage({
              id: 'planner_entry_action_destination',
              defaultMessage: 'Set destination',
            })}
          </Button>
          <Button
            variant="secondary"
            icon={<AddCircleRoundedIcon />}
            onClick={() => {
              navigateToRoutePlanner({
                origin: {
                  ...position,
                  address,
                  poiName,
                },
              })
            }}
          >
            {intl.formatMessage({
              id: 'planner_entry_action_start',
              defaultMessage: 'Start here',
            })}
          </Button>
        </ButtonGroup>
      </LocationMapPopup>
    </>
  )
}
