import { ChipSelectField, ChipSelectFieldOption } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useBikeTypeIcons, useBikeTypeLabels } from 'shared/feature-routes'
import { RouteBikeType } from 'shared/data-access-core'
import { useMemo } from 'react'
import { useDiscoverFilters } from '../use-discover-filters'

export const BikeTypesField = () => {
  const { intl } = useLocale()
  const [filters, setFilters] = useDiscoverFilters()
  const bikeTypeLabels = useBikeTypeLabels()
  const bikeTypeIcons = useBikeTypeIcons()

  const options = useMemo<Record<1 | 2 | 3, ChipSelectFieldOption>>(
    () => ({
      1: { label: bikeTypeLabels[1], icon: bikeTypeIcons[1] },
      2: { label: bikeTypeLabels[2], icon: bikeTypeIcons[2] },
      3: { label: bikeTypeLabels[3], icon: bikeTypeIcons[3] },
    }),
    [bikeTypeIcons, bikeTypeLabels],
  )

  return (
    <ChipSelectField
      name="discover-bike-types"
      label={intl.formatMessage({
        id: 'discover_filter_bike_types_label',
        defaultMessage: 'Bike type',
      })}
      options={options}
      value={filters.bikeTypes.map((v) => v.toString())}
      onChange={(value) =>
        setFilters({
          bikeTypes: value.map((v) => Number.parseInt(v)) as RouteBikeType[],
        })
      }
    />
  )
}
