/**
 * Format provided Date to ISO 8601 format needed for backend.
 * e.g. '2022-08-31T20:13:09+02:00'
 */
export function formatDateToISO(d: Date): string {
  const pad = (n: number) => (n < 10 ? '0' + n : n)

  const date = d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate())
  const time = pad(d.getHours()) + ':' + pad(d.getMinutes()) + ':' + pad(d.getSeconds())

  const tzOffset = d.getTimezoneOffset()
  // e.g. tzOffset=300 => timeZone='-05:00'
  const tzPlusMinus = tzOffset <= 0 ? '+' : '-'
  const tzHours = ('0' + Math.floor(Math.abs(tzOffset) / 60)).slice(-2)
  const tzMinutes = ('0' + (tzOffset % 60)).slice(-2)
  const timeZone = `${tzPlusMinus}${tzHours}:${tzMinutes}`

  return `${date}T${time}${timeZone}`
}

const YEAR_IN_MILLISECONDS = 3.154e10
const MONTH_IN_MILLISECONDS = 2.628e9
const DAY_IN_MILLISECONDS = 8.64e7
const HOUR_IN_MILLISECONDS = 3.6e6
const MIN_IN_MILLISECONDS = 6e4

export function formatCreatedTimeAgo(
  routeCreatedTimestamp: number,
  formatRelativeTime: (value: number, unit?: Intl.RelativeTimeFormatUnit) => string,
  shortTimeString: string,
): string {
  const diff = new Date().getTime() - routeCreatedTimestamp

  if (diff < MIN_IN_MILLISECONDS) {
    return shortTimeString
  }

  if (diff < HOUR_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / MIN_IN_MILLISECONDS), 'minute')
  }
  if (diff < DAY_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / HOUR_IN_MILLISECONDS), 'hour')
  }
  if (diff < MONTH_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / DAY_IN_MILLISECONDS), 'day')
  }
  if (diff < YEAR_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / MONTH_IN_MILLISECONDS), 'month')
  }
  return formatRelativeTime(-Math.trunc(diff / YEAR_IN_MILLISECONDS), 'year')
}
