import { ChipSelectField, ChipSelectFieldOption } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useSurfaceIcons, useSurfaceLabels } from 'shared/feature-routes'
import { useMemo } from 'react'
import { RouteSurface } from 'shared/data-access-core'
import { useDiscoverFilters } from '../use-discover-filters'

export const SurfacesField = () => {
  const { intl } = useLocale()
  const [filters, setFilters] = useDiscoverFilters()
  const surfaceLabels = useSurfaceLabels()
  const surfaceIcons = useSurfaceIcons()

  const options = useMemo<Record<RouteSurface, ChipSelectFieldOption>>(
    () => ({
      [RouteSurface.Paved]: { label: surfaceLabels[RouteSurface.Paved], icon: surfaceIcons[RouteSurface.Paved] },
      [RouteSurface.Unpaved]: { label: surfaceLabels[RouteSurface.Unpaved], icon: surfaceIcons[RouteSurface.Unpaved] },
      [RouteSurface.Gravel]: { label: surfaceLabels[RouteSurface.Gravel], icon: surfaceIcons[RouteSurface.Gravel] },
      [RouteSurface.Ground]: { label: surfaceLabels[RouteSurface.Ground], icon: surfaceIcons[RouteSurface.Ground] },
    }),
    [surfaceIcons, surfaceLabels],
  )

  return (
    <ChipSelectField
      name="discover-surfaces"
      label={intl.formatMessage({
        id: 'discover_filter_surfaces_label',
        defaultMessage: 'Surface type',
      })}
      options={options}
      value={filters.surfaces.map((v) => v.toString())}
      onChange={(value) => setFilters({ surfaces: value.map((v) => Number.parseInt(v)) as RouteSurface[] })}
    />
  )
}
