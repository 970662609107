import { ElevationCurveProvider } from 'shared/feature-elevation-curve'
import { useUserState } from 'web-app/feature-user'
import { useFinalGeometry, useWaypoints } from '../state'
import { useRoutingErrorNotifications } from './use-routing-error-notifications'
import { RoutePlannerMap } from '../map'
import { RoutePlannerSheet } from '../content'
import { RoutePlannerHeader } from '../content/route-planner-header'
import { RoutePlannerMainActions } from '../content/route-planner-main-actions'
import { WebAppMapControls, WebAppMapFooter, WebAppMapLayout } from 'web-app/feature-map'

export const RoutePlannerBase = () => {
  const { unitPreference } = useUserState()
  const finalGeometry = useFinalGeometry()
  const { isFullRoute } = useWaypoints()

  useRoutingErrorNotifications()

  return (
    <ElevationCurveProvider geometry={finalGeometry || undefined} unitPreference={unitPreference}>
      <WebAppMapLayout>
        <RoutePlannerHeader />
        <RoutePlannerSheet />
        {isFullRoute && <RoutePlannerMainActions />}
        <RoutePlannerMap />
        <WebAppMapControls />
        <WebAppMapFooter />
      </WebAppMapLayout>
    </ElevationCurveProvider>
  )
}
