import { useLocale } from 'shared/util-intl'
import { Campaign, CampaignPopupProps } from '../types'
import { Typography } from '@mui/material'
import { Button, Modal } from 'shared/ui-components'
import { PremiumModalHeader } from '../../premium-modal/premium-modal-header'
import { pushModalEvent } from 'shared/util-analytics'
import { ReactNode } from 'react'
import { getPremiumGiftUrl } from 'shared/util-navigation'
import { useUserState } from '../../state'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'

import avif from './christmas-gift.avif'
import webp from './christmas-gift.webp'
import fallback from './christmas-gift.jpg'

const DISCOUNT = 0.3
const VOUCHER_CODE = 'xmas24'

const Popup = ({ isOpen, onClose }: CampaignPopupProps) => {
  const { intl, language } = useLocale()
  const { unitPreference } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  const label = intl.formatMessage({
    id: 'campaign_popup_badge_christmas_24_gift',
    defaultMessage: 'Bikemap Premium as a christmas present',
  })

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-label={label}
      onOpen={() => pushModalEvent('premium_campaign')}
      closeButtonOnColor
    >
      <PremiumModalHeader image={{ avif, webp, fallback, alt: label }} />
      <div style={{ padding: '0 1rem 1rem' }}>
        <Typography variant="h2" textAlign="center" p="2rem 1rem 0.75rem">
          {intl.formatMessage({
            id: 'campaign_popup_heading_christmas_24_gift',
            defaultMessage: 'Give the gift of cycling',
          })}
        </Typography>
        <Typography paragraph textAlign="center">
          {intl.formatMessage(
            {
              id: 'campaign_popup_text_christmas_24_gift',
              defaultMessage: `
Treat your cycling buddies to a gift voucher for a whole year of Bikemap Premium and save
{discount, number, ::percent} by redeeming the voucher code <b>{voucher}</b> during checkout. Give them
access to all exclusive features this season and beyond.
              `,
            },
            {
              voucher: VOUCHER_CODE.toUpperCase(),
              discount: DISCOUNT,
              b: (chunks: ReactNode) => <strong key={chunks?.toString()}>{chunks}</strong>,
            },
          )}
        </Typography>
        <Typography paragraph textAlign="center" variant="body2" color="textSecondary" marginBottom="1rem">
          {intl.formatMessage(
            {
              id: 'campaign_popup_fineprint_christmas_24_gift',
              defaultMessage: 'The voucher code {voucher} is valid until 26th of December, 2024.',
            },
            {
              voucher: VOUCHER_CODE.toUpperCase(),
            },
          )}
        </Typography>
        <Button
          block
          variant="accent"
          href={getPremiumGiftUrl({
            language,
            unitPreference,
            cookieConsentStatistics,
            trackingParameters: {
              utmMedium: 'popup',
              utmCampaign: 'xmas24',
            },
          })}
        >
          {intl.formatMessage(
            {
              id: 'campaign_popup_cta_christmas_24_gift',
              defaultMessage: 'Give Premium and save {discount, number, ::percent}',
            },
            {
              discount: DISCOUNT,
            },
          )}
        </Button>
      </div>
    </Modal>
  )
}

const campaign: Campaign = {
  start: '2024-12-15',
  end: '2024-12-26 23:55',
  isUserTarget: (isLoggedIn, isPremium) => isPremium,
  popup: Popup,
}

export default campaign
