import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist'
import { analyticsMiddleware } from 'shared/util-analytics'
import { INTL_SLICE_KEY, intlReducer } from 'shared/util-intl'
import { USER_SLICE_KEY, userReducer } from 'web-app/feature-user'
import { ROUTE_SLICE_KEY, routeReducer } from 'web-app/feature-route'
import { COOKIE_CONSENT_SLICE_KEY, cookieConsentReducer } from 'web-app/feature-cookie-consent'
import { MAP_SLICE_KEY, mapReducer } from 'web-app/feature-map'
import { IMPORT_SLICE_KEY, routeImportReducer } from 'web-app/view-route-import'
import { PLANNER_SLICE_KEY, undoablePlannerReducer, undoRedoMiddleware } from 'web-app/view-route-planner'
import { ROUTE_DETAILS_SLICE_KEY, routeDetailsReducer } from 'web-app/view-route-details'
import { ROUTE_PRINT_SLICE_KEY, routePrintReducer } from 'web-app/view-route-print'
import { SEARCH_SLICE_KEY, searchReducer } from 'web-app/feature-search'
import { DISCOVER_SLICE_KEY, discoverReducer } from 'web-app/feature-discover'
import { ROUTE_COLLECTION_SLICE_KEY, routeCollectionReducer } from 'web-app/feature-route-collection'
import { ENTITIES_SLICE_KEY, entitiesReducer } from 'web-app/data-access-entities'

export const store = configureStore({
  reducer: {
    [COOKIE_CONSENT_SLICE_KEY]: cookieConsentReducer,
    [ENTITIES_SLICE_KEY]: entitiesReducer,
    [IMPORT_SLICE_KEY]: routeImportReducer,
    [INTL_SLICE_KEY]: intlReducer,
    [MAP_SLICE_KEY]: mapReducer,
    [PLANNER_SLICE_KEY]: undoablePlannerReducer,
    [ROUTE_DETAILS_SLICE_KEY]: routeDetailsReducer,
    [ROUTE_PRINT_SLICE_KEY]: routePrintReducer,
    [ROUTE_SLICE_KEY]: routeReducer,
    [ROUTE_COLLECTION_SLICE_KEY]: routeCollectionReducer,
    [USER_SLICE_KEY]: userReducer,
    [SEARCH_SLICE_KEY]: searchReducer,
    [DISCOVER_SLICE_KEY]: discoverReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(analyticsMiddleware, undoRedoMiddleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
