import React, { createContext, useContext } from 'react'

export interface CommonMessages {
  backLabel: string
  cancelLabel: string
  closeLabel: string
  collapseLabel: string
  deleteLabel: string
  errorLabel: string
  expandLabel: string
  resetLabel: string
  removeLabel: string
  editLabel: string
  saveLabel: string
  showMoreLabel: string
  privateLabel: string
  publicLabel: string
  shareLabel: string
  undoLabel: string
  learnMoreLabel: string
}

export const defaultCommonMessages: CommonMessages = {
  backLabel: 'Back',
  cancelLabel: 'Cancel',
  closeLabel: 'Close',
  collapseLabel: 'Collapse',
  deleteLabel: 'Delete',
  errorLabel: 'Error',
  expandLabel: 'Expand',
  resetLabel: 'Reset',
  removeLabel: 'Remove',
  editLabel: 'Edit',
  saveLabel: 'Save',
  showMoreLabel: 'Show more',
  privateLabel: 'Private',
  publicLabel: 'Public',
  shareLabel: 'Share',
  undoLabel: 'Undo',
  learnMoreLabel: 'Learn more',
}

const MessagesContext = createContext<CommonMessages | null>(null)

interface MessagesProviderProps {
  messages?: CommonMessages
  children: React.ReactNode
}

/**
 * Context wrapper which passes common messages/texts to its children.
 */
export const MessagesProvider = ({ messages = defaultCommonMessages, children }: MessagesProviderProps) => {
  return <MessagesContext.Provider value={messages}>{children}</MessagesContext.Provider>
}

/**
 * Can be used to get common messages in child components of MessagesProvider context provider.
 */
export function useMessages(): CommonMessages {
  const messages = useContext(MessagesContext)
  if (!messages) {
    throw new Error('useMessages must be used inside MessagesContext')
  }
  return messages
}
