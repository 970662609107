import { throttle } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLayoutContext } from './layout-provider'
import { BASE_SIZE_PX } from './definitions'

export const useScrollPagination = (
  isLoading: boolean,
  resultsCount: number | undefined,
  totalCount: number | undefined,
  onLoadMore: () => Promise<void>,
) => {
  const { scrollContainerRef, scrollToInitialFold } = useLayoutContext()

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  const loadMoreButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (
      isLoading && // Scroll up when doing a new search
      scrollContainerRef.current?.scrollTop !== 0 // but only if it won't annoy the user by expanding the sheet
    ) {
      scrollToInitialFold()
    }
  }, [isLoading, scrollContainerRef, scrollToInitialFold])

  const canLoadMore =
    !isLoading && // Don't load more if already doing a different search
    !isLoadingMore && // Don't load same results page multiple times
    resultsCount && // Don't load more if not even initial results are there yet
    resultsCount < (totalCount || 0) // Not all results loaded yet

  const loadMore = useCallback(async () => {
    setIsLoadingMore(true)
    await onLoadMore()
    setIsLoadingMore(false)
  }, [onLoadMore])

  useEffect(() => {
    if (!canLoadMore) return
    const handler = throttle(() => {
      const scrollContainer = scrollContainerRef.current
      const loadMoreButton = loadMoreButtonRef.current
      if (!scrollContainer || !loadMoreButton) return
      if (
        loadMoreButton.getBoundingClientRect().top <
        scrollContainer.getBoundingClientRect().bottom + 10 * BASE_SIZE_PX
      ) {
        loadMore()
      }
    }, 300)
    window.addEventListener('scroll', handler, true)
    return () => {
      window.removeEventListener('scroll', handler, true)
      handler.cancel()
    }
  }, [canLoadMore, loadMore, scrollContainerRef])

  return { canLoadMore, isLoadingMore, loadMoreButtonRef, loadMore }
}
