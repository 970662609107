import { deleteFromCoreApi } from '../../network'
import { API_PATH_ROUTE_COLLECTION } from '../../config'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export async function deleteRouteCollection(routeCollectionId: number): ApiResult<number> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE_COLLECTION, {
      params: { routeCollectionId },
    })
    return createSuccessResult(routeCollectionId)
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
