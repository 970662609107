import { useState, useEffect, useCallback, RefObject } from 'react'
import { Button } from 'shared/ui-components'
import { Fade } from '@mui/material'
import { debounce } from 'lodash'
import { useBreakpoints } from './use-breakpoints'
import { useLayoutContext } from './layout-provider'
import { BASE_SIZE_PX, HEADER_HEIGHT_REM, SHEET_DEFAULT_FOLD_REM } from './definitions'

import styles from './web-app-scroll-tooltip.module.scss'

interface WebAppScrollTooltipProps {
  scrollToRef: RefObject<HTMLDivElement>
  children: string
}

export const WebAppScrollTooltip = ({ scrollToRef, children }: WebAppScrollTooltipProps) => {
  const { layoutBreakpoint } = useBreakpoints()
  const { mainActionsRef, scrollContainerRef, expandBottomSheet } = useLayoutContext()

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [scrollParentHeight, setScrollParentHeight] = useState<number>(0)

  /**
   * Show only when target content is below / behind main content footer.
   */
  const handleVisibility = useCallback(() => {
    if (scrollToRef.current && scrollContainerRef.current) {
      const scrollToRect = scrollToRef.current.getBoundingClientRect()
      const scrollParentRect = scrollContainerRef.current.getBoundingClientRect()
      setScrollParentHeight(scrollParentRect.height)
      setIsVisible(scrollToRect.top > scrollParentRect.bottom)
    } else {
      setIsVisible(false)
    }
  }, [scrollContainerRef, scrollToRef])

  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current || !scrollToRef.current) return
    expandBottomSheet()
    const scrollToRect = scrollToRef.current.getBoundingClientRect()
    const scrollParentRect = scrollContainerRef.current.getBoundingClientRect()
    const scrollContainerHeightPx = window.innerHeight - HEADER_HEIGHT_REM * BASE_SIZE_PX
    const defaultSpacingHeightPx = scrollContainerHeightPx - SHEET_DEFAULT_FOLD_REM * BASE_SIZE_PX
    scrollContainerRef.current.scrollTo({
      top: scrollToRect.top - scrollParentRect.top - (layoutBreakpoint ? 0 : defaultSpacingHeightPx),
      behavior: 'smooth',
    })
  }, [expandBottomSheet, layoutBreakpoint, scrollContainerRef, scrollToRef])

  /**
   * Update visibility on: init, scroll, and resize.
   */
  useEffect(() => {
    handleVisibility()

    const debouncedHandler = debounce(handleVisibility, 50)
    window.addEventListener('scroll', debouncedHandler, true)
    window.addEventListener('resize', debouncedHandler)

    return () => {
      window.removeEventListener('scroll', debouncedHandler, true)
      window.removeEventListener('resize', debouncedHandler)
      debouncedHandler.cancel()
    }
  }, [handleVisibility, scrollContainerRef])

  return (
    <Fade in={isVisible && !!scrollToRef.current && !!scrollContainerRef.current}>
      <div
        className={styles['container']}
        style={{
          bottom: layoutBreakpoint
            ? `calc(100% - var(--web-app-header-height) - ${scrollParentHeight}px)`
            : mainActionsRef.current
              ? `${mainActionsRef.current.clientHeight}px`
              : '0',
        }}
      >
        <Button size="medium" onClick={handleScroll}>
          {children}
        </Button>
      </div>
    </Fade>
  )
}
