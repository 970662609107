import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#route-marker-dest-filter)">
      <path
        fill="#000"
        fillOpacity=".15"
        d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"
      />
    </g>
    <path
      fill="url(#route-marker-dest-gradient)"
      stroke="#fff"
      strokeWidth="1.5"
      d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"
    />
    <path fill="#fff" d="M18 25.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Z" />
    <path
      fill="#019C75"
      d="M14.078 10.476a.7.7 0 0 1 .56-.664c2.844-.45 5.689.586 8.534.274a.382.382 0 0 1 .37.172.366.366 0 0 1-.006.403 90.16 90.16 0 0 1-1.84 3.297.138.138 0 0 0 0 .137 77.75 77.75 0 0 0 1.84 3.09.46.46 0 0 1-.007.427.477.477 0 0 1-.357.244c-2.845.312-5.69-.725-8.534-.274a.46.46 0 0 1-.393-.087.441.441 0 0 1-.167-.359.7.7 0 0 1 .56-.663v-5.55a.46.46 0 0 1-.393-.088.441.441 0 0 1-.167-.359Zm-.56-.51v12.067c0 .303-.25.549-.559.549a.554.554 0 0 1-.56-.549V9.967c0-.303.251-.549.56-.549.31 0 .56.246.56.549Z"
    />
    <defs>
      <linearGradient id="route-marker-dest-gradient" x1="18" x2="18" y1="1" y2="47" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00BA8C" />
        <stop offset="1" stopColor="#008765" />
      </linearGradient>
      <filter
        id="route-marker-dest-filter"
        width="34.666"
        height="18.667"
        x=".667"
        y="38.667"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2488_57493" stdDeviation="2" />
      </filter>
    </defs>
  </svg>
)

export default svg
