import { useCallback, useEffect, useMemo } from 'react'
import { Skeleton } from '@mui/material'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled'
import { GeolocateControl as MaplibreGeolocateControl } from 'maplibre-gl'
import { useMap } from 'react-map-gl/maplibre'
import { Control, ToolButton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUserGeolocation } from 'web-app/feature-user'
import { logError } from 'shared/util-error-handling'
import { MAP_ID } from '../settings'

export const GeolocateControl = () => {
  const { intl } = useLocale()
  const maps = useMap()
  const map = maps[MAP_ID]

  const geolocateControl = useMemo(() => new MaplibreGeolocateControl({}), [])

  useEffect(() => {
    if (map && !map.hasControl(geolocateControl)) {
      map.addControl(geolocateControl)
      geolocateControl.on('error', (error) => {
        logError('Geolocate control could not geolocate', error)
      })
    }
    return () => {
      if (map?.hasControl(geolocateControl)) {
        map.removeControl(geolocateControl)
      }
    }
  }, [geolocateControl, map])

  const { geolocation, isGeolocationLoading, isGeolocationDenied, geolocate } = useUserGeolocation()

  const canGeolocate = !!geolocation
  const onClick = useCallback(async () => {
    if (canGeolocate) {
      geolocateControl.trigger()
    } else {
      await geolocate()
      setTimeout(() => {
        geolocateControl.trigger() // unreliable when called right afterwards
      }, 100)
    }
  }, [geolocate, geolocateControl, canGeolocate])

  const label = intl.formatMessage({
    id: 'geolocate_control_label',
    defaultMessage: 'My location',
  })

  const icon = isGeolocationLoading ? (
    <Skeleton variant="circular" width="1.5rem" height="1.5rem" />
  ) : isGeolocationDenied ? (
    <LocationDisabledIcon />
  ) : (
    <MyLocationIcon />
  )

  return (
    <Control>
      <ToolButton variant="ghost-primary" ariaLabel={label} icon={icon} onClick={onClick} />
    </Control>
  )
}
