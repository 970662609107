import { RouteEntity } from 'shared/data-access-core'
import { AddToCollectionAction } from './add-to-collection-action'
import { AssignedCollectionsList } from './assigned-collections-list'

interface RouteDetailCollectionsProps {
  route: RouteEntity
  assignedRouteCollectionIds: number[]
  sheetEl: HTMLDivElement | null
  contextRouteCollectionId?: number
}

export const RouteDetailCollections = ({
  route,
  assignedRouteCollectionIds,
  sheetEl,
  contextRouteCollectionId,
}: RouteDetailCollectionsProps) => (
  <>
    {(route.isFavorite || !!assignedRouteCollectionIds?.length) && (
      <AssignedCollectionsList
        route={route}
        assignedRouteCollectionIds={assignedRouteCollectionIds}
        contextRouteCollectionId={contextRouteCollectionId}
      />
    )}
    <AddToCollectionAction sheetEl={sheetEl} />
  </>
)
