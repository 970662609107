import { RouteEntity, UserEntity } from 'shared/data-access-core'
import { RouteTile, RouteTileProps, useMessages } from 'shared/ui-components'
import { useMemo } from 'react'
import { useRouteMessages } from './use-route-messages'
import { useRouteStatsFormatters } from './use-route-stats-formatters'
import { useBikeTypes } from './use-bike-types'
import { useSurfaces } from './use-surfaces'

type OmittedProps =
  | 'title'
  | 'distance'
  | 'ascent'
  | 'descent'
  | 'location'
  | 'favoriteCount'
  | 'tags'
  | 'image'
  | 'staticMap'
  | 'isFavorite'
  | 'isPrivate'
  | 'avatarProps'
  | 'href'
  | 'messages'

export interface RouteEntityTileProps extends Omit<RouteTileProps, OmittedProps> {
  route: RouteEntity
  creator: UserEntity | null
  imperial?: boolean
  href: string
  creatorHref?: string
}

/**
 * Renders a route tile based on a given route entity (and its optional creator entity).
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const RouteEntityTile = ({
  route,
  creator,
  imperial,
  href,
  creatorHref,
  ...routeTileProps
}: RouteEntityTileProps) => {
  const { distanceLabel, ascentLabel, descentLabel, locationLabel } = useRouteMessages()
  const { privateLabel } = useMessages()
  const { formatRouteDistance, formatRouteElevation } = useRouteStatsFormatters(!!imperial)
  const bikeTypes = useBikeTypes(route.bikeTypes)
  const surfaces = useSurfaces(route.surfaces)

  const tags = useMemo<string[]>(() => bikeTypes.concat(surfaces), [bikeTypes, surfaces])

  return (
    <RouteTile
      title={route.title}
      distance={formatRouteDistance(route.distanceMeters)}
      ascent={formatRouteElevation(route.ascentMeters)}
      descent={formatRouteElevation(route.descentMeters)}
      location={route.location?.name}
      favoriteCount={route.favoriteCount}
      tags={tags}
      image={route.images.length ? route.images[0].tile : undefined}
      staticMap={
        route.staticMap
          ? {
              main: route.staticMap.tile,
              additional: route.staticMap.tileSecondary,
            }
          : undefined
      }
      isFavorite={route.isFavorite}
      isPrivate={route.isPrivate}
      avatarProps={
        creator
          ? {
              name: creator.name,
              image: creator.avatar?.small,
              isPremium: creator.isPremium,
            }
          : undefined
      }
      href={href}
      creatorHref={creatorHref}
      messages={{ distanceLabel, ascentLabel, descentLabel, locationLabel, privateLabel }}
      {...routeTileProps}
    />
  )
}
