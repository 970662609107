import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  CollectionRoutesParams,
  RouteCollectionSliceDispatch,
  fetchGlobalRouteCollectionAndRoutes,
  useRouteCollection,
} from './state'
import { COLLECTION_ROUTES_DEFAULT_PARAMS } from './definitions'

export const useRouteCollectionById = (
  routeCollectionId: number,
  params: CollectionRoutesParams = COLLECTION_ROUTES_DEFAULT_PARAMS,
) => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const routeCollection = useRouteCollection()

  useEffect(() => {
    if (routeCollectionId) {
      dispatch(fetchGlobalRouteCollectionAndRoutes(routeCollectionId, params))
    }
  }, [dispatch, params, routeCollectionId])

  return routeCollection?.id === routeCollectionId ? routeCollection : undefined
}
