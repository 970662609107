export const BASE_SIZE_PX = 16

export const LAYOUT_BREAKPOINT_REM = 48
export const NAVIGATION_BREAKPOINT_REM = 65
export const HEIGHT_BREAKPOINT_REM = 35

export const HEADER_HEIGHT_REM = 3.5

export const SHEET_MINIMAL_FOLD_REM = 5
export const SHEET_DEFAULT_FOLD_REM = 19
export const SHEET_HANDLE_HEIGHT_REM = 2

export const MEDIA_PARALLAX_THROTTLING_MS = 50
