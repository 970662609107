import { useDispatch } from 'react-redux'
import Directions from '@mui/icons-material/Directions'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { useLocale } from 'shared/util-intl'
import { Button, ButtonGroup } from 'shared/ui-components'
import {
  useWaypoints,
  RoutePlannerSliceDispatch,
  insertWaypoint,
  insertSelectedLocationAlongRoute,
  GeocodedWaypoint,
  WaypointTemplate,
  useRoutePlannerState,
} from '../../state'
import { LocationMapPopup } from 'web-app/feature-map'

interface PopupCreateProps {
  selectedLocation: WaypointTemplate
}

export const PopupCreate = ({ selectedLocation }: PopupCreateProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { waypoints, start, end } = useWaypoints()
  const { isCalculatingRoute } = useRoutePlannerState()

  const showDestinationButton = !!(!end || (start && end))

  return (
    <LocationMapPopup
      longitude={selectedLocation.lng}
      latitude={selectedLocation.lat}
      geocoded={selectedLocation.address ? (selectedLocation as GeocodedWaypoint) : undefined}
    >
      <ButtonGroup>
        {showDestinationButton && (
          <Button
            icon={<Directions />}
            onClick={() => dispatch(insertWaypoint(waypoints.length, selectedLocation))}
            disabled={isCalculatingRoute}
          >
            {intl.formatMessage({
              id: 'set_destination_label',
              defaultMessage: 'Set destination',
            })}
          </Button>
        )}
        {!start && (
          <Button
            variant={!showDestinationButton ? 'primary' : 'secondary'}
            icon={<AddCircleRoundedIcon />}
            onClick={() => dispatch(insertWaypoint(0, selectedLocation))}
            disabled={isCalculatingRoute}
          >
            {intl.formatMessage({
              id: 'start_here_label',
              defaultMessage: 'Start here',
            })}
          </Button>
        )}
        {start && end && (
          <Button
            variant="secondary"
            icon={<AddCircleRoundedIcon />}
            onClick={() => dispatch(insertSelectedLocationAlongRoute())}
            disabled={isCalculatingRoute}
          >
            {intl.formatMessage({
              id: 'add_to_route_label',
              defaultMessage: 'Add to route',
            })}
          </Button>
        )}
      </ButtonGroup>
    </LocationMapPopup>
  )
}
