import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { StateWithHistory } from './undo-redo'
import { StateWithIntlSlice } from 'shared/util-intl'
import { StateWithMapSlice } from 'web-app/feature-map'
import { StateWithRouteSlice } from 'web-app/feature-route'
import { LngLat, Waypoints, RichMultiLineString, RichLineString } from 'shared/util-geo'
import { StateWithUserSlice } from 'web-app/feature-user'
import { RouteCalculationErrors, RoutingProfile, RoutingProfileCyclingPathsLevel } from 'shared/data-access-routing'
import { StateWithEntitiesSlice } from 'web-app/data-access-entities'

export const PLANNER_SLICE_KEY = 'planner'

export type Waypoint = LngLat & {
  /** ID to find the waypoint again in an updated list */
  id: string

  /** Index of the control point that represents the snapped version of this waypoint */
  controlPointIndex?: number

  /** Location strings from reverse geocoding */
  address?: string
  poiName?: string
  localName?: string

  /** Only derived from control point, can disappear again */
  isImplicit?: boolean
}

export type RoutedWaypoint = Waypoint & {
  controlPointIndex: number
}

export type GeocodedWaypoint = Waypoint & {
  address: string
}

export type IncompleteWaypoints = [null, null] | [Waypoint, null] | [null, Waypoint]

export type CompleteWaypoints = [RoutedWaypoint, RoutedWaypoint, ...RoutedWaypoint[]]

export type WaypointTemplate = Omit<Waypoint, 'id'>

export type GeocodedWaypointTemplate = Omit<GeocodedWaypoint, 'id'>

export type RoutingMode = 'routing' | 'freehand'

export type RoutePlannerStats = {
  distanceMeters: number
  durationSeconds: number
  // ascentMeters: number TODO WEB-1659
  // descentMeters: number TODO WEB-1659
  maximumElevationMeters: number
}

export type RoutePlannerState = {
  isInitialized: boolean

  /** ID of a route that's being edited or copied */
  basedOnRouteId: number | null

  /** Geometry of the calculated route in segments */
  geometry: RichMultiLineString | null

  /** List of all waypoints (origin, via, destination) in order */
  waypoints: IncompleteWaypoints | CompleteWaypoints

  /** How changes to the route are handled */
  routingMode: RoutingMode

  /** Currently selected routing profile */
  routingProfile: RoutingProfile

  /** Level of the cycling path routing profile */
  routingLevel: RoutingProfileCyclingPathsLevel

  /** Whether the routing profile should be applied to the whole route */
  isWholeRouteRouted: boolean

  /** Location currently selected on the map to perform actions on */
  selectedLocation: WaypointTemplate | null

  /** Waypoint currently selected on the map to perform actions on */
  selectedWaypoint: number | null

  /** Whether there is an ongoing routing request */
  isCalculatingRoute: boolean

  /** Most recent errors when calculating a route failed */
  routingErrors: RouteCalculationErrors | null
}

export interface StateWithRoutePlannerSlice {
  [PLANNER_SLICE_KEY]: StateWithHistory<RoutePlannerState>
}

/**
 * Derived state of waypoints with explicit typing and decomposed into start, via and end.
 */
export type WaypointsState =
  | {
      isFullRoute: false
      waypoints: IncompleteWaypoints
      start: Waypoint | null
      via: []
      end: Waypoint | null
    }
  | {
      isFullRoute: true
      waypoints: CompleteWaypoints
      start: RoutedWaypoint
      via: RoutedWaypoint[]
      end: RoutedWaypoint
    }

export type IndexWaypointState = {
  isStart: boolean
  isEnd: boolean
  isVia: boolean
  viaIndex: number
}

export type SelectedWaypointState = {
  index: number
  waypoint: Waypoint
}

export type RoutePlannerSliceDispatch = ThunkDispatch<
  StateWithRoutePlannerSlice &
    StateWithIntlSlice &
    StateWithMapSlice &
    StateWithRouteSlice &
    StateWithUserSlice &
    StateWithEntitiesSlice,
  undefined,
  UnknownAction
>

export type SavableRouteState = {
  geometry: RichLineString
  distanceMeters: number
  durationSeconds: number
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
  start: Waypoint
  via: Waypoint[]
  end: Waypoint
}
