import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
import { FormControl, MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material'
import { PremiumLockIcon } from 'shared/ui-design-system/icons/premium-lock-icon'
import { colors } from 'shared/ui-design-system'
import { uniqueId } from 'lodash'

import styles from './select.module.scss'

export interface SelectOption<ValueType> {
  value: ValueType
  label: React.ReactNode
  icon?: React.ReactNode
  hasPremiumIndicator?: boolean
}

export interface SelectProps<ValueType> {
  variant?: 'default' | 'onColor'
  options: SelectOption<ValueType>[]
  name: string
  label: string
  value: ValueType
  disabled?: boolean
  hiddenLabel?: boolean
  onSelect: (value: ValueType, name?: string) => void
}

export function Select<ValueType extends string>({
  variant = 'default',
  options,
  name,
  label,
  value,
  disabled,
  hiddenLabel,
  onSelect,
}: SelectProps<ValueType>) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as ValueType, name)
  }

  const id = useMemo(() => uniqueId(name + '-'), [name])

  return (
    <FormControl fullWidth>
      <label className={clsx(styles['label'], { [styles['invisible-label']]: hiddenLabel })} htmlFor={id}>
        {label}
      </label>
      <MuiSelect
        open={isOpen}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        className={styles['select']}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        MenuProps={{
          className: styles['menu'],
        }}
        SelectDisplayProps={{
          className: clsx(styles['select-trigger'], {
            [styles['onColor']]: variant === 'onColor',
            [styles['open']]: isOpen,
          }),
        }}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            className={styles['select-item']}
            value={option.value}
            style={option.value === value ? { display: 'none' } : {}}
          >
            {option.icon}
            <span className={styles['select-item-label']}>{option.label}</span>
            {option.hasPremiumIndicator && (
              <PremiumLockIcon
                style={{
                  color: colors.premiumComp.premiumIndicator,
                  fontSize: '0.8rem',
                }}
              />
            )}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
