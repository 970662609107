import { API_BASE_URL, API_BASE_URL_CACHED } from './config'
import {
  DeleteRequestOptions,
  GetRequestOptions,
  PatchRequestOptions,
  PostRequestOptions,
  PutRequestOptions,
  addApiHeaders,
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'shared/util-network'

export async function getFromCoreApi(path: string, options: GetRequestOptions = {}) {
  return getRequest(API_BASE_URL_CACHED + path, {
    ...options,
    headers: { ...(await addApiHeaders()), ...options.headers },
  })
}

export async function postToCoreApi(path: string, options: PostRequestOptions = {}) {
  return postRequest(API_BASE_URL + path, {
    ...options,
    headers: { ...(await addApiHeaders()), ...options.headers },
  })
}

export async function putToCoreApi(path: string, options: PutRequestOptions = {}) {
  return putRequest(API_BASE_URL + path, {
    ...options,
    headers: { ...(await addApiHeaders()), ...options.headers },
  })
}

export async function patchToCoreApi(path: string, options: PatchRequestOptions = {}) {
  return patchRequest(API_BASE_URL + path, {
    ...options,
    headers: { ...(await addApiHeaders()), ...options.headers },
  })
}

export async function deleteFromCoreApi(path: string, options: DeleteRequestOptions = {}) {
  return deleteRequest(API_BASE_URL + path, {
    ...options,
    headers: { ...(await addApiHeaders()), ...options.headers },
  })
}
