import { Popover } from '@mui/material'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { Button, ButtonGroup, Dialog, ToolButton, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useMemo, useRef, useState } from 'react'
import { useUser } from 'web-app/feature-user'
import { getUserProfileUrl } from 'shared/util-navigation'
import { UserEntity } from 'shared/data-access-core'

import styles from './user-content-reporting.module.css'

const REPORT_EMAIL_ADDRESS = 'support@bikemap.net'

interface UserContentReportingProps {
  creator: UserEntity | null
  entityId: number
  entityUrl: string
  reportLabel: string
  entityIdentifier: string
}

export const UserContentReporting = ({
  creator,
  entityId,
  entityUrl,
  reportLabel,
  entityIdentifier,
}: UserContentReportingProps) => {
  const { intl } = useLocale()
  const { cancelLabel } = useMessages()
  const [user, isUserLoaded] = useUser()

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [entityTypeToBeReported, setEntityTypeToBeReported] = useState<'entity' | 'user' | null>(null)

  const anchorEl = useRef<HTMLDivElement>(null)

  const emailHref = useMemo<string | null>(() => {
    if (!entityTypeToBeReported || !isUserLoaded) return null
    const subject = `Report for ${entityTypeToBeReported}`

    let reportedId: number | null = null
    let reportedUrl = ''
    if (entityTypeToBeReported === 'user') {
      if (!creator) return null
      reportedId = creator.id
      reportedUrl = getUserProfileUrl({
        language: 'en',
        unitPreference: 'metric',
        cookieConsentStatistics: false,
        slug: creator.slug,
      }).split('?')[0]
    } else {
      reportedId = entityId
      reportedUrl = entityUrl
    }

    const text = `
Dear Bikemap team,%0D%0A
%0D%0A
I would like to report ${entityTypeToBeReported === 'user' ? entityTypeToBeReported : entityIdentifier} ${reportedId} (${reportedUrl}) as I think it violates the platform's Terms
 of Service.%0D%0A
%0D%0A
Sincerely yours,%0D%0A
${user?.name || '<name>'}%0D%0A
___________%0D%0A
User: ${user?.id || 'Anonymous'}
    `

    return `mailto:${REPORT_EMAIL_ADDRESS}?subject=${subject}&body=${text}`
  }, [creator, entityId, entityIdentifier, entityTypeToBeReported, entityUrl, isUserLoaded, user?.id, user?.name])

  return (
    <div ref={anchorEl}>
      <ToolButton
        variant="ghost-primary"
        icon={<MoreHorizRoundedIcon />}
        ariaLabel={intl.formatMessage({
          id: 'reporting_additional_actions',
          defaultMessage: 'Additional actions',
        })}
        onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
      />
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{ paper: { classes: { root: styles['popover'] } } }}
      >
        <Button
          size="medium"
          variant="danger-secondary"
          icon={<FlagRoundedIcon />}
          onClick={() => {
            setIsPopoverOpen(false)
            setEntityTypeToBeReported('entity')
          }}
        >
          {reportLabel}
        </Button>
        {creator && (
          <Button
            size="medium"
            variant="danger-secondary"
            icon={<FlagRoundedIcon />}
            onClick={() => {
              setIsPopoverOpen(false)
              setEntityTypeToBeReported('user')
            }}
          >
            {intl.formatMessage({
              id: 'route_details_report_user',
              defaultMessage: 'Report user',
            })}
          </Button>
        )}
      </Popover>
      <Dialog
        open={!!entityTypeToBeReported}
        title={intl.formatMessage({
          id: 'report_content_dialog_title',
          defaultMessage: 'Are you sure you want to report this content?',
        })}
        text={intl.formatMessage({
          id: 'report_content_dialog_text',
          defaultMessage:
            'Your report will be reviewed by our team to ensure it complies with our community guidelines. Please make sure to include some context to your report to help us better understand the situation.',
        })}
        buttons={
          <ButtonGroup>
            <Button
              variant="danger-primary"
              href={emailHref || undefined}
              disabled={!isUserLoaded}
              onClick={() => setEntityTypeToBeReported(null)}
            >
              {intl.formatMessage({
                id: 'report_content_dialog_action',
                defaultMessage: 'Write a report',
              })}
            </Button>
            <Button variant="secondary" onClick={() => setEntityTypeToBeReported(null)}>
              {cancelLabel}
            </Button>
          </ButtonGroup>
        }
      />
    </div>
  )
}
