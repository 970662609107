export type AlongTheRouteAttribute<Category> = [
  startPositionIndex: number,
  endPositionIndex: number,
  category: Category,
][]

export const SURFACE_CATEGORIES = [
  'missing',
  'paving_stones',
  'concrete',
  'asphalt',
  'paved',
  'gravel',
  'unpaved',
  'snow',
  'metal',
  'rock',
  'metal_grid',
  'pebblestone',
  'mud',
  'wood',
  'ground',
  'woodchips',
  'cobblestones',
  'sand',
  'loose_gravel',
  'shells',
] as const

export type SurfaceCategory = (typeof SURFACE_CATEGORIES)[number]

export enum Surface {
  PavingStones,
  Concrete,
  Asphalt,
  Paved,
  Gravel,
  Unpaved,
  Snow,
  Metal,
  Rock,
  MetalGrid,
  Pebblestone,
  Mud,
  Wood,
  Ground,
  Woodchips,
  Cobblestones,
  Sand,
  LooseGravel,
  Shells,
}

const SURFACE_CATEGORIES_MAP: Record<SurfaceCategory, Surface | null> = {
  missing: null,
  paving_stones: Surface.PavingStones,
  concrete: Surface.Concrete,
  asphalt: Surface.Asphalt,
  paved: Surface.Paved,
  gravel: Surface.Gravel,
  unpaved: Surface.Unpaved,
  snow: Surface.Snow,
  metal: Surface.Metal,
  rock: Surface.Rock,
  metal_grid: Surface.MetalGrid,
  pebblestone: Surface.Pebblestone,
  mud: Surface.Mud,
  wood: Surface.Wood,
  ground: Surface.Ground,
  woodchips: Surface.Woodchips,
  cobblestones: Surface.Cobblestones,
  sand: Surface.Sand,
  loose_gravel: Surface.LooseGravel,
  shells: Surface.Shells,
}

export const WAY_TYPE_CATEGORIES = [
  'missing',
  'busy_road',
  'road',
  'quiet_road',
  'living_street',
  'access_road',
  'pedestrian_area',
  'steps',
  'track',
  'path',
  'cycleway',
] as const

export type WayTypeCategory = (typeof WAY_TYPE_CATEGORIES)[number]

export enum WayType {
  BusyRoad,
  Road,
  QuietRoad,
  LivingStreet,
  AccessRoad,
  PedestrianArea,
  Steps,
  Track,
  Path,
  Cycleway,
}

const WAY_TYPE_CATEGORIES_MAP: Record<WayTypeCategory, WayType | null> = {
  missing: null,
  busy_road: WayType.BusyRoad,
  road: WayType.Road,
  quiet_road: WayType.QuietRoad,
  living_street: WayType.LivingStreet,
  access_road: WayType.AccessRoad,
  pedestrian_area: WayType.PedestrianArea,
  steps: WayType.Steps,
  track: WayType.Track,
  path: WayType.Path,
  cycleway: WayType.Cycleway,
}

export const BIKE_NETWORK_CATEGORIES = ['missing', 'international', 'national', 'regional', 'local'] as const

export type BikeNetworkCategory = (typeof BIKE_NETWORK_CATEGORIES)[number]

export enum BikeNetwork {
  International,
  National,
  Regional,
  Local,
}

const BIKE_NETWORK_CATEGORIES_MAP: Record<BikeNetworkCategory, BikeNetwork | null> = {
  missing: null,
  international: BikeNetwork.International,
  national: BikeNetwork.National,
  regional: BikeNetwork.Regional,
  local: BikeNetwork.Local,
}

export function getValidAlongTheRouteAttribute<ValidCategory>(
  alongTheRouteRes: AlongTheRouteAttribute<string> | null,
  validCategories: (ValidCategory | 'missing')[],
): AlongTheRouteAttribute<ValidCategory | 'missing'> | null {
  if (!alongTheRouteRes?.length || !alongTheRouteRes[0].length) return null
  let hasData = false
  const validAttribute: AlongTheRouteAttribute<ValidCategory> = alongTheRouteRes.map((section) => {
    const [startPositionIndex, endPositionIndex, category] = section
    const validCategory: ValidCategory = (
      (validCategories as unknown as string[]).includes(category) ? category : 'missing'
    ) as ValidCategory
    if (validCategory !== 'missing') {
      hasData = true
    }
    return [startPositionIndex, endPositionIndex, validCategory]
  })
  return hasData ? validAttribute : null
}

export function getSurface(categoryTag: string): Surface | null {
  return SURFACE_CATEGORIES_MAP[categoryTag as SurfaceCategory] ?? null
}

export function getWayType(categoryTag: string): WayType | null {
  return WAY_TYPE_CATEGORIES_MAP[categoryTag as WayTypeCategory] ?? null
}

export function getBikeNetwork(categoryTag: string): BikeNetwork | null {
  return BIKE_NETWORK_CATEGORIES_MAP[categoryTag as BikeNetworkCategory] ?? null
}
