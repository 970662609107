import { useRoutePois } from './route-pois-context'

/**
 * Provides props to pass to a map popup the reoute POI content is shown in. Can also be used as an indicator
 * whether route POI content will render or not (based on internal state).
 */
export const useRoutePoiPopupProps = (): { longitude: number; latitude: number; hidden?: boolean } | null => {
  const { selectedRoutePoi, editingPosition, isDragging } = useRoutePois()

  if (editingPosition) {
    return {
      longitude: editingPosition.lng,
      latitude: editingPosition.lat,
      hidden: isDragging,
    }
  }

  if (selectedRoutePoi) {
    return {
      longitude: selectedRoutePoi.position.lng,
      latitude: selectedRoutePoi.position.lat,
    }
  }

  return null
}
