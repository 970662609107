import React, { useMemo, useState } from 'react'
import { useLayoutContext } from './layout-provider'
import { useBreakpoints } from './use-breakpoints'
import { throttle } from 'lodash'
import { MEDIA_PARALLAX_THROTTLING_MS } from './definitions'

export const useMediaParallax = (
  mediaHeightPx: number,
  active: boolean,
): {
  mediaOffset: string
  handleParallaxOnScroll: React.UIEventHandler<HTMLDivElement> | undefined
} => {
  const { layoutBreakpoint } = useBreakpoints()
  const { snapScrollContainerRef } = useLayoutContext()

  const isCurrentlyActive = active && !layoutBreakpoint

  const [mediaOffsetPx, setMediaOffsetPx] = useState<number>(0)

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const scrollTop = snapScrollContainerRef.current?.scrollTop || 0
        const isMediaVisible = scrollTop < mediaHeightPx
        if (isMediaVisible) {
          setMediaOffsetPx(scrollTop * -0.5)
        }
      }, MEDIA_PARALLAX_THROTTLING_MS),
    [mediaHeightPx, snapScrollContainerRef],
  )

  return {
    mediaOffset: isCurrentlyActive ? mediaOffsetPx + 'px' : '0',
    handleParallaxOnScroll: isCurrentlyActive ? handleScroll : undefined,
  }
}
