import { Typography } from '@mui/material'
import { Modal } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import QRCode from 'react-qr-code'
import { getDiscoverDynamicLink } from 'shared/util-navigation'

import styles from './loop-planner-modal.module.scss'

interface LoopPlannerModalProps {
  open: boolean
  onClose: () => void
  png: string
  webp: string
  avif: string
}

export const LoopPlannerModal = ({ open, onClose, png, webp, avif }: LoopPlannerModalProps) => {
  const { intl } = useLocale()

  const modalHeading = intl.formatMessage({
    id: 'loop_planner_modal_heading',
    defaultMessage: 'Open the loop planner in the Bikemap app',
  })

  return (
    <Modal open={open} onClose={onClose} aria-label={modalHeading}>
      <header className={styles['header']}>
        <picture>
          <source srcSet={avif} type="image/avif" />
          <source srcSet={webp} type="image/webp" />
          <source srcSet={png} type="image/png" />
          <img src={png} alt={modalHeading} width="380" height="168" />
        </picture>
      </header>
      <div className={styles['content']}>
        <Typography variant="h2">{modalHeading}</Typography>
        <Typography paragraph>
          {intl.formatMessage({
            id: 'loop_planner_modal_instructions',
            defaultMessage: "Scan this QR code with your phone's camera to open or download the app:",
          })}
        </Typography>
        <div className={styles['qr-code-holder']}>
          <QRCode
            value={getDiscoverDynamicLink({
              utmSource: 'web_app',
              utmMedium: 'home_features_gallery',
              utmCampaign: 'loop_planner_qr',
            })}
            width={100}
            height={100}
            className={styles['qr-code']}
            bgColor="var(--accentColor-primary)"
            fgColor="var(--onColor-primary)"
          />
        </div>
      </div>
    </Modal>
  )
}
