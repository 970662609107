import { CSSProperties, ReactNode } from 'react'

import styles from './web-app-media.module.scss'
import { MEDIA_PARALLAX_THROTTLING_MS } from './definitions'

interface WebAppMediaProps {
  children: ReactNode
}

export const WebAppMedia = ({ children }: WebAppMediaProps) => {
  return (
    <div
      className={styles['container']}
      style={
        { '--web-app-media-parallax-transition-time': MEDIA_PARALLAX_THROTTLING_MS * 0.002 + 's' } as CSSProperties
      }
    >
      {children}
    </div>
  )
}
