import DirectionsRoundedIcon from '@mui/icons-material/Directions'
import { useLocale } from 'shared/util-intl'
import { IllustratedButton } from 'shared/ui-components'
import { WEB_APP_PATH_ROUTE_PLANNER } from 'shared/util-navigation'
import { useInternalLink } from '../use-internal-link'

import png from './assets/route-planner-illustrated-link.png'
import webp from './assets/route-planner-illustrated-link.webp'
import avif from './assets/route-planner-illustrated-link.avif'

export const RoutePlannerIllustratedLink = () => {
  const getInternalLinkProps = useInternalLink()
  const { intl } = useLocale()

  return (
    <IllustratedButton
      icon={<DirectionsRoundedIcon />}
      image={{ png, webp, avif }}
      {...getInternalLinkProps(WEB_APP_PATH_ROUTE_PLANNER)}
    >
      {intl.formatMessage({
        id: 'route_planner_illustrated_link_label',
        defaultMessage: 'Plan your route',
      })}
    </IllustratedButton>
  )
}
