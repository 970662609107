import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#map-marker-repair-filter)">
      <path
        fill="#000"
        fillOpacity=".15"
        d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"
      />
    </g>
    <path
      fill="url(#map-marker-repair-gradient)"
      stroke="#fff"
      strokeWidth="1.5"
      d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"
    />
    <path
      fill="#fff"
      d="M22.896 22.98a2.042 2.042 0 0 1-1.48-.626l-4.166-4.187a4.691 4.691 0 0 1-.843.25 4.74 4.74 0 0 1-.907.083c-1.389 0-2.57-.486-3.542-1.458-.972-.973-1.458-2.153-1.458-3.542 0-.5.07-.976.208-1.428.14-.45.334-.878.584-1.28l3.041 3.041 1.5-1.5-3.041-3.041c.402-.25.83-.445 1.28-.584A4.83 4.83 0 0 1 15.5 8.5c1.389 0 2.57.486 3.542 1.458.972.973 1.458 2.153 1.458 3.542 0 .32-.028.621-.083.906a4.686 4.686 0 0 1-.25.844l4.208 4.167c.208.208.365.437.47.687a2.042 2.042 0 0 1-.012 1.594 2.16 2.16 0 0 1-.458.677 1.99 1.99 0 0 1-.688.458c-.25.098-.513.146-.791.146Z"
    />
    <defs>
      <linearGradient
        id="map-marker-repair-gradient"
        x1="27.44"
        x2="-1.476"
        y1="6.126"
        y2="29.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B3B3F1" />
        <stop offset="1" stopColor="#8584E9" />
      </linearGradient>
      <filter
        id="map-marker-repair-filter"
        width="34.666"
        height="18.667"
        x=".667"
        y="38.667"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2540_33178" stdDeviation="2" />
      </filter>
    </defs>
  </svg>
)

export default svg
