import { ApiResult, ResponseParser, createFailureResult, createSuccessResult } from 'shared/util-network'
import { getFromCoreApi } from '../../network'
import { API_PATH_REGIONS_SEARCH } from '../../config'
import { LngLat } from 'shared/util-geo'
import { Point } from 'geojson'
import { RegionEntity, RegionEntityMapData, RegionEntityParents } from '../../entities'

type ResponseItem = {
  id: number
  name: string
  country: {
    id: number
    name: string
  } | null
  admin_1: {
    id: number
    name: string
  } | null
  kind: string
  center: Point
  bounds: number[] | null
}

type Response = {
  results: ResponseItem[]
}

export async function searchRegions(
  query: string,
  location: LngLat,
  language: 'en' | 'de',
): ApiResult<(RegionEntity & RegionEntityParents & RegionEntityMapData)[]> {
  try {
    const res: Response = await getFromCoreApi(API_PATH_REGIONS_SEARCH, {
      headers: {
        'Accept-Language': language,
      },
      queryParams: {
        query,
        location: `${location.lng},${location.lat}`,
      },
    })
    try {
      return createSuccessResult(res.results.map(parseRegionResult))
    } catch (error) {
      return createFailureResult({ unexpectedResponse: true }, { error })
    }
  } catch {
    return createFailureResult({ unexpectedError: true })
  }
}

class UnexpectedRegionSearchResultFormatError extends Error {}

function parseRegionResult(res: ResponseItem): RegionEntity & RegionEntityMapData & RegionEntityParents {
  const parser = new ResponseParser(res)

  const entity = {
    id: parser.requireNumber('id'),
    name: parser.requireString('name'),
    center: parser.requireLngLat('center'),
    bounds: parser.takeBoundsArray('bounds'),
  }

  const kind = parser.requireString('kind')
  if (kind === 'country') {
    return {
      ...entity,
      kind: 'country',
    }
  }

  if (kind === 'admin_1') {
    return {
      ...entity,
      kind: 'admin_1',
      country: {
        id: parser.in('country').requireNumber('id'),
        name: parser.in('country').requireString('name'),
      },
    }
  }

  if (kind === 'admin_2') {
    return {
      ...entity,
      kind: 'admin_2',
      country: {
        id: parser.in('country').requireNumber('id'),
        name: parser.in('country').requireString('name'),
      },
      admin1: {
        id: parser.in('admin_1').requireNumber('id'),
        name: parser.in('admin_1').requireString('name'),
      },
    }
  }

  throw new UnexpectedRegionSearchResultFormatError()
}
