import clsx from 'clsx'
import React from 'react'
import { Typography } from '@mui/material'

import styles from './LocationListButton.module.scss'

export interface LocationListButtonProps {
  title: string
  subtitle?: string
  icon: React.ReactNode
  highlighted?: boolean
  onClick?: () => void
  analyticsId?: string
}

export function LocationListButton({
  title,
  subtitle,
  icon,
  highlighted,
  onClick,
  analyticsId,
}: LocationListButtonProps) {
  return (
    <button
      className={clsx(styles['button'], {
        [styles['highlighted']]: highlighted,
        [styles['with-subtitle']]: subtitle,
      })}
      aria-label={title}
      onClick={onClick}
      data-aid={analyticsId}
    >
      {icon}
      <Typography fontWeight="bold" lineHeight={1.25}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body2" fontSize="textSecondary">
          {subtitle}
        </Typography>
      )}
    </button>
  )
}
