import { ReactNode, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Map, { MapProps, MapViewport, VisibleMapPadding } from 'shared/ui-map'
import { viewportChanged, useMapState, globalHeatmapSupportDetected, boundsFit, viewportMatched } from './state'
import { MAP_ID } from './settings'
import {
  BASE_SIZE_PX,
  SHEET_DEFAULT_FOLD_REM,
  SHEET_HANDLE_HEIGHT_REM,
  WebAppMedia,
  useBreakpoints,
} from 'web-app/ui-layout'

const START_MARKER_HEIGHT_PX = 40
const START_MARKER_WIDTH_PX = 28
const CONTROLS_WIDTH_PX = 40
const SHEET_DEFAULT_FOLD_PX = SHEET_DEFAULT_FOLD_REM * BASE_SIZE_PX
const SHEET_HANDLE_HEIGHT_PX = SHEET_HANDLE_HEIGHT_REM * BASE_SIZE_PX

const MAP_PADDING_SMALL_VIEWPORT = {
  top: SHEET_DEFAULT_FOLD_PX / 2 + BASE_SIZE_PX + START_MARKER_HEIGHT_PX,
  right: CONTROLS_WIDTH_PX + START_MARKER_WIDTH_PX / 2 + 2 * BASE_SIZE_PX,
  bottom: SHEET_DEFAULT_FOLD_PX / 2 + BASE_SIZE_PX + SHEET_HANDLE_HEIGHT_PX,
  left: START_MARKER_WIDTH_PX / 2 + BASE_SIZE_PX,
}

const MAP_PADDING_LARGE_VIEWPORT = {
  top: 209,
  right: 166,
  bottom: 218,
  left: 502,
}

export interface WebAppMapProps {
  children?: ReactNode
  padding?: VisibleMapPadding
  initialViewport?: MapViewport
  onClick?: MapProps['onClick']
}

/**
 * Renders a stateful map as Web App media, related controls and a footer with attributions. This contains Web App
 * layout components, so it needs to be inside a `WebAppLayout`.
 */
export const WebAppMap = ({ initialViewport, ...props }: WebAppMapProps) => {
  const dispatch = useDispatch()
  const { layoutBreakpoint } = useBreakpoints()
  const { mapStyle, isGlobalHeatmapEnabled, isGlobalHeatmapSupported, desiredViewport, desiredBounds, viewport } =
    useMapState()

  const mapPadding = useMemo(
    () => (layoutBreakpoint ? MAP_PADDING_LARGE_VIEWPORT : MAP_PADDING_SMALL_VIEWPORT),
    [layoutBreakpoint],
  )

  return (
    <WebAppMedia>
      <Map
        {...props}
        id={MAP_ID}
        mapStyle={mapStyle}
        initialViewport={initialViewport || viewport}
        onViewportChanged={(viewport) => dispatch(viewportChanged(viewport))}
        padding={mapPadding}
        isGlobalHeatmapEnabled={isGlobalHeatmapSupported && isGlobalHeatmapEnabled}
        onGlobalHeatmapSupportDetection={(isSupported) => {
          if (isGlobalHeatmapSupported !== isSupported) {
            dispatch(globalHeatmapSupportDetected(isSupported))
          }
        }}
        desiredViewport={desiredViewport}
        onMatchViewport={(desiredViewportString: string) => dispatch(viewportMatched(desiredViewportString))}
        desiredBounds={desiredBounds}
        onFitBounds={(desiredBoundsString: string) => dispatch(boundsFit(desiredBoundsString))}
      />
    </WebAppMedia>
  )
}
