import { colors } from 'shared/ui-design-system'

export const XAxisTick = (props: {
  x?: number
  y?: number
  payload?: { value?: number }
  tickFormatter?: (value: number) => string
  index?: number
  visibleTicksCount?: number
}) => {
  const { x, y, payload, tickFormatter, index, visibleTicksCount } = props

  const first = index === 0
  const last = index === (visibleTicksCount && visibleTicksCount - 1)

  const label = tickFormatter && payload?.value !== undefined ? tickFormatter(payload?.value) : payload?.value

  return (
    <svg data-testid="x-axis-tick">
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={10}
          textAnchor={first ? 'start' : last ? 'end' : 'middle'}
          fill={colors.onNeutral.secondary}
          fontSize={12}
        >
          {label}
        </text>
      </g>
    </svg>
  )
}

export const YAxisTick = (props: {
  x?: number
  y?: number
  payload?: { value: number }
  tickFormatter?: (value: number) => string
}) => {
  const { x, y, payload, tickFormatter } = props

  const label = tickFormatter && payload?.value !== undefined ? tickFormatter(payload.value) : payload?.value

  return (
    <svg data-testid="y-axis-tick">
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={-8} textAnchor="start" fill={colors.onNeutral.secondary} fontSize={12}>
          {label}
        </text>
      </g>
    </svg>
  )
}
