import { FloatingButton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { Fade } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  CollectionRoutesParams,
  RouteCollectionSliceDispatch,
  fetchMoreGlobalRouteCollectionRoutes,
  useRouteCollectionState,
} from '../state'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

interface LoadMoreRoutesButtonProps {
  params: CollectionRoutesParams
}

export const LoadMoreRoutesButton = ({ params }: LoadMoreRoutesButtonProps) => {
  const dispatch = useDispatch() as RouteCollectionSliceDispatch
  const { intl } = useLocale()
  const { assignedRouteIds, filteredRoutesCount, isLoadingMoreRoutes } = useRouteCollectionState()

  const handleClick = useCallback(() => {
    dispatch(fetchMoreGlobalRouteCollectionRoutes(params))
  }, [dispatch, params])

  return (
    <Fade
      in={
        !isLoadingMoreRoutes &&
        !!filteredRoutesCount &&
        !!assignedRouteIds &&
        filteredRoutesCount > assignedRouteIds.length
      }
    >
      <FloatingButton icon={<AddRoundedIcon />} onClick={handleClick}>
        {intl.formatMessage({
          id: 'route_collection_load_more_routes_button',
          defaultMessage: 'Load more routes',
        })}
      </FloatingButton>
    </Fade>
  )
}
