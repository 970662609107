import { RefObject, useCallback, useMemo, useRef } from 'react'
import {
  RoutePlannerSliceDispatch,
  Waypoint,
  WaypointTemplate,
  insertWaypoint,
  removeWaypoint,
  updateWaypoint,
  useIsRoundtrip,
  useRoutePlannerState,
  useWaypoints,
} from '../../state'
import { Search, SearchResult } from 'web-app/feature-search'
import { SuggestionRoundTrip } from './suggestion-round-trip'
import { SortableHandleProps, ToolButton, useMessages, SortableDragHandle } from 'shared/ui-components'
import { DestinationIcon } from 'shared/ui-design-system/icons/destination-icon'
import AdjustIcon from '@mui/icons-material/Adjust'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useLocale } from 'shared/util-intl'
import { useDispatch } from 'react-redux'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { viewportDesired } from 'web-app/feature-map'
import IconVia from './icons/IconVia'
import { getLetterFromViaPointIndex } from 'shared/ui-map'

import styles from './waypoints-list-item.module.scss'

interface WaypointsListItemProps {
  waypointsListIndex: number
  waypointIndex: number | null
  beforeIndex: number | null
  isLast: boolean
  item: Waypoint | null
  isSearchActive: boolean
  isSorting: boolean
  sortableHandleProps?: SortableHandleProps
  onInsertedItemObsolete: (waypointsListIndex: number, willTurnIntoWaypoint: boolean) => void
  onFocus: (waypointsListIndex: number, ref: RefObject<HTMLDivElement>) => void
  onBlur: (waypointsListIndex: number) => void
}

export const WaypointsListItem = ({
  waypointsListIndex,
  waypointIndex,
  beforeIndex,
  isLast,
  item,
  isSearchActive,
  isSorting,
  sortableHandleProps,
  onInsertedItemObsolete,
  onFocus,
  onBlur,
}: WaypointsListItemProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { isFullRoute, start, end } = useWaypoints()
  const { isCalculatingRoute } = useRoutePlannerState()
  const { deleteLabel } = useMessages()
  const isRoundtrip = useIsRoundtrip()

  const ref = useRef<HTMLDivElement>(null)

  const defaultValue = item
    ? item.address
      ? item.poiName || item.address
      : `${item.lng.toPrecision(7)}, ${item.lat.toPrecision(7)}`
    : ''

  const handleResultSelect = useCallback(
    (result: GeocoderLocation) => {
      if (isCalculatingRoute) return
      const { position, type, ...rest } = result
      const waypoint: WaypointTemplate = { ...position, ...rest }
      if (!start && !end) {
        dispatch(viewportDesired({ center: [waypoint.lng, waypoint.lat], zoom: 12 }))
      }
      if (waypointIndex !== null) {
        dispatch(updateWaypoint(waypointIndex, waypoint))
      } else if (beforeIndex !== null) {
        onInsertedItemObsolete(waypointsListIndex, true)
        dispatch(insertWaypoint(beforeIndex, waypoint))
      }
    },
    [isCalculatingRoute, start, end, waypointIndex, beforeIndex, dispatch, onInsertedItemObsolete, waypointsListIndex],
  )

  const isStart = waypointsListIndex === 0

  const inputIcon = useMemo(
    () =>
      sortableHandleProps ? (
        <SortableDragHandle
          {...sortableHandleProps}
          label={intl.formatMessage({
            id: 'route_planner_grab_to_drag_and_sort',
            defaultMessage: 'Grab to drag and sort',
          })}
          isSorting={isSorting}
        />
      ) : null,
    [intl, isSorting, sortableHandleProps],
  )

  const before = useMemo(
    () => (
      <div className={styles['waypoint-icon']}>
        {isSorting ? (
          <IconVia label="" />
        ) : isStart ? (
          <AdjustIcon />
        ) : isLast ? (
          <DestinationIcon />
        ) : (
          <IconVia label={getLetterFromViaPointIndex(waypointsListIndex - 1)} />
        )}
      </div>
    ),
    [isLast, isSorting, isStart, waypointsListIndex],
  )

  const after = useMemo(
    () =>
      !!(start || end) && (
        <ToolButton
          className={styles['delete-button']}
          variant="ghost-primary"
          onClick={() => {
            if (waypointIndex !== null) {
              dispatch(removeWaypoint(waypointIndex))
            } else {
              onInsertedItemObsolete(waypointsListIndex, false)
            }
          }}
          icon={<RemoveCircleOutlineIcon />}
          ariaLabel={deleteLabel}
          disabled={isCalculatingRoute || (!isFullRoute && waypointIndex === null)}
        />
      ),
    [
      deleteLabel,
      dispatch,
      end,
      isCalculatingRoute,
      isFullRoute,
      onInsertedItemObsolete,
      start,
      waypointIndex,
      waypointsListIndex,
    ],
  )

  const renderSuggestions = useMemo(
    () =>
      start && // there is a start to route back to
      !isRoundtrip && // would make a difference for the route
      isLast && // is last item in the waypoints list
      waypointsListIndex > 1 && // route wouldn't just collapse to two waypoints at the same position
      ((onSelect: (result: SearchResult) => void) => {
        const { lng, lat, address, ...rest } = start
        return (
          <SuggestionRoundTrip
            onClick={() =>
              onSelect({
                type: 'place',
                data: { position: { lng, lat }, ...rest, address: address || '', type: 'address' },
              })
            }
          />
        )
      }),
    [isLast, isRoundtrip, start, waypointsListIndex],
  )

  return (
    <div ref={ref} className={styles['container']}>
      {!isLast && !isSorting && !isSearchActive && (
        <div className={styles['divider']}>
          <MoreVertIcon />
        </div>
      )}
      <Search
        defaultValue={defaultValue}
        paddingBlock="0.5rem 0"
        onPlaceSelect={handleResultSelect}
        icon={inputIcon}
        label={
          isStart
            ? intl.formatMessage({
                id: 'route_planner_starting_point',
                defaultMessage: 'Starting point',
              })
            : intl.formatMessage({
                id: 'route_planner_destination',
                defaultMessage: 'Destination',
              })
        }
        before={before}
        after={after}
        autoFocus={isSearchActive}
        renderSuggestions={renderSuggestions || undefined}
        onFocus={() => onFocus(waypointsListIndex, ref)}
        onBlur={() => onBlur(waypointsListIndex)}
      />
    </div>
  )
}
