export const ROUTING_API_BASE_URL = process.env['NX_PUBLIC_ROUTING_API_URL']

export const API_PATH_ROUTING = '/route'
export const API_PATH_MATCHING = '/match'
export const API_PATH_NEAREST = '/nearest'

export const ROUTING_PROFILES = [
  'bike_networks', // could be labeled "Balanced"
  'cycling_paths', // not a real profile (bike_networks + high/medium/low)
  'bike_fastest',
  'e_bike',
  'heatmap',
  'smooth_ride',
  'racingbike',
  'mtb',
] as const

export type RoutingProfile = (typeof ROUTING_PROFILES)[number]

export const ROUTING_PROFILE_CYCLING_PATHS_LEVELS = ['high', 'medium', 'low'] as const

export type RoutingProfileCyclingPathsLevel = (typeof ROUTING_PROFILE_CYCLING_PATHS_LEVELS)[number]
