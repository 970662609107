import { useEffect } from 'react'
import { MapLayerMouseEvent, useMap } from 'react-map-gl/maplibre'

export const useMapMouseMove = (mapId: string, onMouseMove: (event: MapLayerMouseEvent) => void, active = true) => {
  const { [mapId]: map } = useMap()

  useEffect(() => {
    if (!active) return
    if (map) {
      map.on('mousemove', onMouseMove)
    }
    return () => {
      map?.off('mousemove', onMouseMove)
    }
  }, [active, map, onMouseMove])
}
