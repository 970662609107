import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ROUTE_DETAILS_SLICE_KEY, RouteDetailsState } from './types'
import { LngLat } from 'shared/ui-map'

export const initialState: RouteDetailsState = {
  selectedLocation: null,
}

const slice = createSlice({
  name: ROUTE_DETAILS_SLICE_KEY,
  initialState,
  reducers: {
    locationSelected(state, action: PayloadAction<LngLat>) {
      state.selectedLocation = action.payload
    },
    mapPopupClosed(state) {
      state.selectedLocation = null
    },
    reset() {
      return initialState
    },
  },
})

export const { locationSelected, mapPopupClosed, reset } = slice.actions

export const routeDetailsReducer = slice.reducer
