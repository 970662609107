import { WebAppContent, WebAppMapHint, WebAppSheet, WebAppTopControl } from 'web-app/ui-layout'
import { ErrorBoundary, ErrorBoundaryFallback } from 'web-app/utils-error-handling'
import { DiscoverRoutesSection } from './discover-routes-section'
import { DefaultHeader, RouteDetailsPreviousView } from 'web-app/feature-navigation'
import {
  SelectedMapLocation,
  WebAppMap,
  WebAppMapControls,
  WebAppMapFooter,
  WebAppMapLayout,
} from 'web-app/feature-map'
import { HomeMapFeatures } from './home-map-features'
import { SearchSection } from './search-section'
import { SearchHereButton, useDiscoverFiltersCount } from 'web-app/feature-discover'
import { useEffect, useMemo, useState } from 'react'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { useInitialization } from './use-initialization'
import { FeaturesGallery } from './features-gallery'
import { useLocale } from 'shared/util-intl'
import { useLocation } from 'react-router-dom'
import { areLngLatsEqual } from 'shared/util-geo'

interface HomeProps {
  initialState?: {
    selectedLocation?: GeocoderLocation
    keepMapViewport?: boolean
  }
}

export const Home = ({ initialState }: HomeProps) => {
  const location = useLocation()
  const { intl } = useLocale()
  useInitialization(initialState?.keepMapViewport)
  const filtersCount = useDiscoverFiltersCount()

  const [selectedLocation, setSelectedLocation] = useState<GeocoderLocation | null>(
    initialState?.selectedLocation || null,
  )
  const [selectedLocationOnMap, setSelectedLocationOnMap] = useState<SelectedMapLocation | null>(selectedLocation)

  useEffect(() => {
    if (initialState?.selectedLocation) {
      setSelectedLocation(initialState.selectedLocation)
    }
  }, [initialState])

  const isInitialState = filtersCount === 0 && !selectedLocation

  const routeDetailsPreviousView = useMemo<RouteDetailsPreviousView>(
    () => ({
      path: location.pathname + location.search,
      label: intl.formatMessage({
        id: 'route_details_previous_view_discover',
        defaultMessage: 'Discover routes',
      }),
    }),
    [intl, location.pathname, location.search],
  )

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <WebAppMapLayout initialFold="full">
        <DefaultHeader />
        <WebAppSheet>
          <SearchSection
            selectedLocation={selectedLocation}
            onSelect={(location) => {
              setSelectedLocation(location)
              setSelectedLocationOnMap(location)
            }}
            onSelectionCancel={() => {
              setSelectedLocation(null)
              setSelectedLocationOnMap(null)
            }}
          />
          {isInitialState && (
            <WebAppContent noPadding>
              <FeaturesGallery />
            </WebAppContent>
          )}
          <DiscoverRoutesSection
            withHeading={isInitialState}
            routeDetailsPreviousView={routeDetailsPreviousView}
            onRouteClick={() => {
              setSelectedLocation(null)
              setSelectedLocationOnMap(null)
            }}
          />
        </WebAppSheet>
        <WebAppMap>
          <HomeMapFeatures
            selectedLocation={selectedLocation}
            selectedMapLocation={selectedLocationOnMap}
            routeDetailsPreviousView={routeDetailsPreviousView}
            onMapLocationSelect={(location) => {
              setSelectedLocation(null)
              setSelectedLocationOnMap(location)
            }}
            onMapLocationGeocoded={(location) => {
              setSelectedLocationOnMap((current) =>
                current && areLngLatsEqual(current.position, location.position) ? location : current,
              )
            }}
            onMapLocationSelectionCancel={() => setSelectedLocationOnMap(null)}
            onLocationOutOfView={() => {
              setSelectedLocation(null)
              setSelectedLocationOnMap(null)
            }}
          />
        </WebAppMap>
        <WebAppMapControls />
        <WebAppMapFooter />
        <WebAppMapHint />
        <WebAppTopControl>
          <SearchHereButton />
        </WebAppTopControl>
      </WebAppMapLayout>
    </ErrorBoundary>
  )
}
