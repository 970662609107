import { Skeleton } from '@mui/material'
import { GeocoderLocation } from 'shared/data-access-geocoding'
import { useMemo } from 'react'
import { formatLocationDisplayValues } from 'shared/util-formatting'

import styles from './location-details.module.css'

export const LocationDetails = (props: Omit<GeocoderLocation, 'position' | 'type'>) => {
  const displayValues = useMemo(() => formatLocationDisplayValues(props), [props])

  return (
    <div className={styles['location-details']}>
      <div className={styles['title']}>{displayValues.displayValueTitle}</div>
      {displayValues.displayValueSubtitle && (
        <div className={styles['subtitle']}>{displayValues.displayValueSubtitle}</div>
      )}
    </div>
  )
}

export const LocationDetailsSkeleton = () => (
  <div className={styles['location-details']} data-testid="location-details-loading">
    <Skeleton animation="wave" width="60%" />
    <Skeleton animation="wave" width="40%" sx={{ fontSize: '0.75rem' }} />
  </div>
)
